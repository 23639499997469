import { PAGE_ROUTES } from '../../Managers/RouteManager';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { checkUserPermission } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { REPORT_TYPE } from '../../Constants/Enum';
import { quotationListReportTemplate } from './Templates/Sales/QuotationListReport';
import { invoiceListReportTemplate } from './Templates/Sales/InvoiceListReport';
import { receiptListReportTemplate } from './Templates/Sales/ReceiptListReport';
import { creditNotesReportTemplate } from './Templates/Sales/CreditNotesReport';
import { billListReportTemplate } from './Templates/Purchase/BillListReport';
import { orderListReportTemplate } from './Templates/Purchase/OrderList';
import { paymentListReportTemplate } from './Templates/Purchase/PaymentListReport';
import { debitNotesReportTemplate } from './Templates/Purchase/DebitNotesReport';
import { stockMovementReportTemplate } from './Templates/Inventory/StockMovementReport';
import { batchwiseStockTrackingReportTemplate } from './Templates/Inventory/BatchwiseStockTrackingReport';
import { MODULES_NAME } from '../../Constants/Constant';
import { Store } from '../../Redux/Store';
import { isRevRecVisible } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { localizedText } from '../../Services/Localization/Localization';
import Utility, { getCapitalized } from '../../Utility/Utility';

const amortizationReportsWithPermissions = [
  {
    label: 'Amortization Forecast Report',
    route: PAGE_ROUTES.AMORTIZATION_FORECAST_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.AMOR_FORECAST
  },
  {
    label: 'Deferred Expense Waterfall Report',
    route: PAGE_ROUTES.DEFERRED_EXPENSE_WATERFALL_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DEF_EXP_WATERFALL
  },
  {
    label: 'Deferred Expense Rollforward Summary Report',
    route: PAGE_ROUTES.DEFERRED_EXPENSE_ROLLFORWARD_SUMMARY_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DEF_EXP_ROLL_FORWARD_SUM
  }
];

const businessReportsWithPermissions = [
  {
    label: 'Profit and Loss',
    route: PAGE_ROUTES.REPORT_PNL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  },
  {
    label: 'Cash Flow Statement',
    route: PAGE_ROUTES.REPORT_CASHFLOW,
    permission: PERMISSIONS_BY_MODULE.REPORTS.CASH_FLOW_STATEMENT
  },
  {
    label: 'Profit and Loss By Year',
    route: PAGE_ROUTES.REPORT_PNL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    type: REPORT_TYPE.PROFIT_AND_LOSS_BY_YEAR
  },
  {
    label: 'Bank Reconciliation Summary',
    route: PAGE_ROUTES.REPORT_BANK_REC_SUMMARY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_RECONCILIATION
  },
  {
    label: 'Profit and Loss By Month',
    route: PAGE_ROUTES.REPORT_PNL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    type: REPORT_TYPE.PROFIT_AND_LOSS_BY_MONTH
  },
  {
    label: 'Reconciliation Log Report',
    route: PAGE_ROUTES.REPORT_RECONCILIATION_LOG,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_RECONCILIATION
  },
  {
    label: 'Profit and Loss By Quarter',
    route: PAGE_ROUTES.REPORT_PNL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    type: REPORT_TYPE.PROFIT_AND_LOSS_BY_QUARTER
  },
  {
    label: 'Balance Sheet',
    route: PAGE_ROUTES.REPORT_BS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET
  },
  {
    label: 'Profit and Loss Details',
    route: PAGE_ROUTES.REPORT_PNL_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  },
  {
    label: 'Balance Sheet By Month',
    route: PAGE_ROUTES.REPORT_BS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    type: REPORT_TYPE.BALANCE_SHEET_BY_MONTH
  },
  {
    label: 'Statement of Accounts - Vendor',
    route: PAGE_ROUTES.REPORT_SOA_VENDOR,
    permission: PERMISSIONS_BY_MODULE.REPORTS.STATEMENT_OF_ACCOUNTS_VENDORS
  },
  {
    label: 'Balance Sheet By Quarter',
    route: PAGE_ROUTES.REPORT_BS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    type: REPORT_TYPE.BALANCE_SHEET_BY_QUARTER
  },
  {
    label: 'Statement of Accounts',
    route: PAGE_ROUTES.REPORT_SOA_CUSTOMER,
    permission: PERMISSIONS_BY_MODULE.REPORTS.STATEMENT_OF_ACCOUNTS
  },
  {
    label: 'Balance Sheet By Year',
    route: PAGE_ROUTES.REPORT_BS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    type: REPORT_TYPE.BALANCE_SHEET_BY_YEAR
  },
  {
    label: 'Trial Balance',
    route: PAGE_ROUTES.REPORT_TB,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE
  },
  {
    label: 'Balance Sheet Details',
    route: PAGE_ROUTES.REPORT_BS_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET
  },
  {
    label: 'Trial Balance Details',
    route: PAGE_ROUTES.REPORT_TBD,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE
  },
  {
    label: 'Dpl Report',
    route: PAGE_ROUTES.DPL_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    country: 'US',
    enabled: Utility.isDPLSettingEnabled()
  },
  {
    label: 'General Ledger',
    route: PAGE_ROUTES.REPORT_GL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GENERAL_LEDGER
  },
  {
    label: 'Documents Emailed',
    route: PAGE_ROUTES.DOCUMENTS_EMAILED,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DOCUMENTS_EMAILED
  },
  {
    label: 'Profit and Loss By Department',
    route: PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  },
  {
    label: 'Trial Balance By Department',
    route: PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE
  },
  {
    label: 'Trial Balance By Department Details',
    route: PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE
  },
  {
    label: 'Profit and Loss By Department Details',
    route: PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  },
  {
    label: 'Bank Book Report',
    route: PAGE_ROUTES.BANK_BOOK_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_BOOK
  }
];

const budgetingReportsWithPermissions = [
  {
    label: 'Profit and Loss',
    route: PAGE_ROUTES.BR_REPORT_PNL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  },
  {
    label: 'Balance Sheet',
    route: PAGE_ROUTES.BR_REPORT_BS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET
  },
  {
    label: 'General Ledger',
    route: PAGE_ROUTES.BR_REPORT_GL,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GENERAL_LEDGER
  }
];

const taxReportsWithPermissions = [
  {
    label: 'GSTR-1',
    route: PAGE_ROUTES.GSTR1,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    country: 'IN'
  },
  {
    label: 'SST02 Report',
    route: PAGE_ROUTES.SSTO2_REPORT_MY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SST02,
    country: 'MY'
  },
  {
    label: 'GSTR-2B Reconciliation',
    route: PAGE_ROUTES.GSTR2B_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_2B_RECONCILIATION,
    country: 'IN'
  },
  {
    label: 'VAT Return',
    route: PAGE_ROUTES.VAT_RETURN,
    permission: PERMISSIONS_BY_MODULE.REPORTS.VAT_RETURN_UK,
    country: 'UK'
  },
  // {
  //   label: 'GSTR-2B Report New',
  //   route: PAGE_ROUTES.GSTR2B_REPORT_NEW,
  //   permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_2B_RECONCILIATION,
  //   country: 'IN'
  // },
  {
    label: 'GSTR-3B Report',
    route: PAGE_ROUTES.GSTR3B_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    country: 'IN'
  },
  {
    label: 'TDS Details Report',
    route: PAGE_ROUTES.TDS_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_DETAILS,
    country: 'IN'
  },
  {
    label: 'TDS Not Deducted',
    route: PAGE_ROUTES.TDS_NOT_DED,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_NOT_DEDUCTED,
    country: 'IN'
  },
  {
    label: 'TDS Customer Report',
    route: PAGE_ROUTES.TDS_CUSTOMER,
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_CUSTOMER,
    country: 'IN'
  },
  {
    label: 'Failed E-Invoices Log',
    route: PAGE_ROUTES.FAILED_INVOICES,
    permission: PERMISSIONS_BY_MODULE.REPORTS.FAILED_E_INVOICE_LOG,
    country: 'IN'
  },
  {
    label: 'GSTR1 Return Summary',
    route: PAGE_ROUTES.GST_RETURN,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR1_RETURN_SUMMARY,
    country: 'IN'
  },
  {
    label: 'Sales Tax Liability',
    route: PAGE_ROUTES.SALES_TAX_US,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX_LIABILITY,
    country: 'US'
  },
  {
    label: 'Sales Tax Report',
    route: PAGE_ROUTES.SALES_TAX_AGENCY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX,
    country: 'US'
  },
  {
    label: 'GST Report Log',
    route: PAGE_ROUTES.GST_REPORT_LOG,
    permission: PERMISSIONS_BY_MODULE.REPORTS.GST_REPORT_LOG_SG,
    country: 'SG'
  },
  {
    label: 'IRAS Audit File',
    route: PAGE_ROUTES.REPORTS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.IRAS_AUDIT_FILE,
    country: 'SG'
  },
  {
    label: 'Sales Tax Return',
    route: PAGE_ROUTES.SALES_TAX_RETURN,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX_RETURN_MY,
    country: 'MY'
  },
  {
    label: 'Service Tax Return',
    route: PAGE_ROUTES.SERVICE_TAX_RETURN,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SERVICE_TAX_RETURN_MY,
    country: 'MY'
  },
  {
    label: 'E-Way Bill Report',
    route: PAGE_ROUTES.REPORT_SALES_EWB_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.E_WAY_BILL,
    country: 'IN'
  }
];

const advancedFinanceReportsWithPermissions = [
  {
    label: 'Receivable Report',
    route: PAGE_ROUTES.TALLY_RECEIVABLE,
    permission: PERMISSIONS_BY_MODULE.REPORTS.RECEIVABLE
  },
  {
    label: 'Day Book',
    route: PAGE_ROUTES.TALLY_DAY_BOOK,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DAY_BOOK
  },
  {
    label: 'Receipt Summary',
    route: PAGE_ROUTES.TALLY_RECEIPT_SUMMARY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.RECEIPT_SUMMARY
  },
  {
    label: 'Cashflow',
    route: PAGE_ROUTES.TALLY_CASHFLOW,
    permission: PERMISSIONS_BY_MODULE.REPORTS.CASHFLOW
  },
  {
    label: 'Detailed Sales Report',
    route: PAGE_ROUTES.TALLY_DETAILED_SALES_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DETAILED_SALES
  },
  {
    label: 'Vendor - Statement of Account',
    route: PAGE_ROUTES.TALLY_VENDOR_SOA,
    permission: PERMISSIONS_BY_MODULE.REPORTS.VENDOR_STATEMENT_OF_ACCOUNT
  },
  {
    label: 'Detailed Purchase Report',
    route: PAGE_ROUTES.TALLY_DETAILED_PURCHASE_REPORT,
    permission: PERMISSIONS_BY_MODULE.REPORTS.DETAILED_PURCHASE
  },
  {
    label: 'Customer - Statement of Account',
    route: PAGE_ROUTES.TALLY_CUSTOMER_SOA,
    permission: PERMISSIONS_BY_MODULE.REPORTS.CUSTOMER_STATEMENT_OF_ACCOUNT
  },
  {
    label: 'Stock Summary Report',
    route: PAGE_ROUTES.TALLY_STOCK_SUMMARY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_SUMMARY
  },
  {
    label: 'Sales Register - Summary Report',
    route: PAGE_ROUTES.TALLY_SALES_REGISTER_SUMMARY,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_REGISTER_SUMMARY
  },
  {
    label: MODULES_NAME.VALUATION_BY_WAREHOUSE,
    route: PAGE_ROUTES.VALUATION_BY_WAREHOUSE,
    permission: PERMISSIONS_BY_MODULE.REPORTS.VALUATION_BY_WAREHOUSE
  },
  {
    label: 'Consolidated Financial Report',
    route: PAGE_ROUTES.CONSOLIDATED_FINANCIAL_REPORT_LIST,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS
  }
];

const additionalChargesReportsWithPermissions = [
  {
    label: 'Purchase Report',
    route: PAGE_ROUTES.ADDITIONAL_PURCHASE,
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_REPORT
  },
  {
    label: 'Sales Report',
    route: PAGE_ROUTES.ADDITIONAL_SALES,
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_REPORT
  }
];

export const getAmortizationReportData = () => {
  return getPermissionListAsPerModule(amortizationReportsWithPermissions);
};

export const getBusinessReportData = () => {
  return getPermissionListAsPerModule(businessReportsWithPermissions);
};

export const getBudgetingReportData = () => {
  return getPermissionListAsPerModule(budgetingReportsWithPermissions);
};

export const getSalesReportData = () => {
  let tenantCountry =
    Store.getState().authInfo?.currentTenantInfo?.data?.country;
  let salesReportsWithPermissions = [
    {
      label: 'Aged Receivables',
      route: PAGE_ROUTES.AGED_RECEIVABLES_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.AGED_RECEIVABLES
    },
    {
      label: 'Create Revenue Recognition JE',
      route: PAGE_ROUTES.REV_REC_JE,
      permission: PERMISSIONS_BY_MODULE.REPORTS.CREATE_REVENUE_RECOGNITION_JE
    },
    {
      label: localizedText('Outstanding Sales Fulfillment Report'),
      route: PAGE_ROUTES.OUTSTANDING_SALES_FULLFILLMENT_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.OUTSTANDING_DELIVERY_ORDER
    },
    {
      label: 'Sales By Contact',
      route: PAGE_ROUTES.SALES_BY_CONTACT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_BY_CONTACT,
    },
    {
      label: `${getCapitalized(localizedText('quotation'))} Report`,
      route: PAGE_ROUTES.QUOTATION_LIST_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.QUOTATION,
      callback: quotationListReportTemplate,
    },
    {
      label: 'Invoices',
      route: PAGE_ROUTES.REPORT_SALES_INVOICE,
      permission: PERMISSIONS_BY_MODULE.REPORTS.INVOICES,
      callback: invoiceListReportTemplate,
    },
    {
      label: 'Sales By Product',
      route: PAGE_ROUTES.SALES_BY_PRODUCT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_BY_PRODUCT,
    },
    {
      label: 'Receipt List',
      route: PAGE_ROUTES.RECEIPT_LIST,
      permission: PERMISSIONS_BY_MODULE.REPORTS.RECEIPT_LISTt,
      callback: receiptListReportTemplate,
    },
    {
      label: 'Credit Notes',
      route: PAGE_ROUTES.REPORT_PURCHASE_CN,
      permission: PERMISSIONS_BY_MODULE.REPORTS.CREDIT_NOTES,
      callback: creditNotesReportTemplate,
    },
    {
      label:
        tenantCountry === 'IN' ? 'Delivery Order Report' : 'Fulfillment Report',
      route: PAGE_ROUTES.REPORT_FULFILLMENT_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT,
    },
    {
      label: 'Rate Analysis Report',
      route: PAGE_ROUTES.RATE_ANALYSIS_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.RATE_ANALYSIS
    },
    {
      label: 'Sales Order Details',
      route: PAGE_ROUTES.REPORT_SALES_ORDER_DETAILS,
      permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_ORDER_DETAILS
    },
    {
      label:
        tenantCountry === 'IN'
          ? 'Delivery Order - Tolerance Level report '
          : 'Fulfillment - Tolerance Level report ',
      route: PAGE_ROUTES.FULFILLMENT_TOLERANCE_LEVEL_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT
    }
  ];
  return getPermissionListAsPerModule(salesReportsWithPermissions);
};

export const getPurchaseReportData = () => {
  const isEnableRFQ =
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
      ?.RFQ_OPEN_FORM_CONFIG?.enableRFQ;
  const purchaseReportsWithPermissions = [
    {
      label: 'Aged Payables',
      route: PAGE_ROUTES.AGED_PAYABLES_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.AGED_PAYABLES
    },
    {
      label: 'Outstanding Purchases Good Received Report',
      route: PAGE_ROUTES.REPORT_OPGR,
      permission:
      PERMISSIONS_BY_MODULE.REPORTS.OUTSTANDING_PURCHASES_GOOD_RECEIVED
    },
    {
      label: 'Purchase By Contact',
      route: PAGE_ROUTES.PURCHASE_BY_CONTACT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_BY_CONTACT,
    },
    {
      label: 'Bill List',
      route: PAGE_ROUTES.REPORT_PURCHASE_BILL_LIST,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BILL_LIST,
      callback: billListReportTemplate,
    },
    {
      label: 'PO List',
      route: PAGE_ROUTES.REPORT_PURCHASE_ORDER_LIST,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PO_LIST,
      callback: orderListReportTemplate,
    },
    {
      label: 'Purchase By Product',
      route: PAGE_ROUTES.PURCHASE_BY_PRODUCT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_BY_PRODUCT
    },
    {
      label: 'Payment List',
      route: PAGE_ROUTES.PAYMENT_LIST,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PAYMENT_LIST,
      callback: paymentListReportTemplate,
    },
    {
      label: 'Debit Notes',
      route: PAGE_ROUTES.REPORT_PURCHASE_DN,
      permission: PERMISSIONS_BY_MODULE.REPORTS.DEBIT_NOTES,
      callback: debitNotesReportTemplate,
    },
    {
      label: 'Item Landed Cost Report',
      route: PAGE_ROUTES.REPORT_ITEM_LANDED_COST,
      permission: PERMISSIONS_BY_MODULE.REPORTS.ITEM_LANDED_COST
    },
    {
      label: 'Payment Milestone Tracker',
      route: PAGE_ROUTES.PAYMENT_MILESTONE_TRACKER,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PAYMENT_MILESTONE_TRACKER
    },
    {
      label: 'Goods Received Report',
      route: PAGE_ROUTES.GOODS_RECEIVED_NOTES,
      permission: PERMISSIONS_BY_MODULE.REPORTS.GOODS_RECEIVED
    },
    {
      label: 'Purchase Order Detail Report',
      route: PAGE_ROUTES.PURCHASE_ORDER_DETAIL_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_ORDER_DETAIL
    },
    {
      label: 'Goods Receipt - Tolerance Level report',
      route: PAGE_ROUTES.GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.GOODS_RECEIVED
    },
    {
      label: 'Goods Receipt - Tolerance Level Rejection Stock Report',
      route: PAGE_ROUTES.TOLERANCE_LEVEL_REJECTION_STOCK_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.GOODS_RECEIVED
    },
    {
      label: 'Purchase Quotation Comparison',
      route: PAGE_ROUTES.PURCHASE_QUOTATION_COMPARISON_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BILL_LIST,
      enabled: isEnableRFQ
    }
  ];
  return getPermissionListAsPerModule(purchaseReportsWithPermissions);
};

export const getTaxReportData = () => {
  return getPermissionListAsPerModule(taxReportsWithPermissions);
};

export const getInventoryReportData = () => {
  let inventoryReportsWithPermissions = [
    {
      label: 'Stock Availability By Warehouse',
      route: PAGE_ROUTES.STOCK_WAREHOUSE_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_AVAILABILITY_BY_WAREHOUSE
    },
    {
      label: 'Reserved Stock Report',
      route: PAGE_ROUTES.RESERVED_STOCK_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.RESERVED_STOCK
    },
    {
      label: 'Batch Status Report',
      route: PAGE_ROUTES.BATCH_STATUS,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BATCH_STATUS
    },
    {
      label: 'BOM Assembly',
      route: PAGE_ROUTES.BOM_ASSEMBLY,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BOM
    },
    {
      label: 'Stock Ledger',
      route: PAGE_ROUTES.STOCK_LEDGER_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_LEDGER
    },
    {
      label: 'Stock Ageing Report',
      route: PAGE_ROUTES.STOCK_AGEING,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_AGEING
    },
    {
      label: 'Sales Return Report',
      route: PAGE_ROUTES.SALES_RETURN_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_RETURN
    },
    {
      label: 'Purchase Return Report',
      route: PAGE_ROUTES.PURCHASE_RETURN_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_RETURN
    },
    {
      label: 'Stock Movement',
      route: PAGE_ROUTES.STOCK_MOVEMENT_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_MOVEMENT_REPORT,
      callback: stockMovementReportTemplate,
    },
    {
      label: 'Batch Serial Ledger Report',
      route: PAGE_ROUTES.BATCH_SERIAL_LADGER_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BATCH_SERIAL_LEDGER
    },
    {
      label: 'Batchwise Stock Tracking Report',
      route: '',
      permission: PERMISSIONS_BY_MODULE.REPORTS.BATCHWISE_STOCK_TRACKING,
      callback: batchwiseStockTrackingReportTemplate,
    },
    {
      label: 'Stock Valuation Report',
      route: PAGE_ROUTES.STOCK_VALUATION,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_VALUATION
    },
    {
      label: 'Rev Rec Waterfall',
      route: PAGE_ROUTES.REV_REC_WATERFALL,
      permission: PERMISSIONS_BY_MODULE.REPORTS.REV_REC_WATERFALL,
      enabled: isRevRecVisible()
    },
    {
      label: 'Deferred Revenue By Item',
      route: PAGE_ROUTES.REV_REC_DEFERRED_REV_BY_ITEM,
      permission: PERMISSIONS_BY_MODULE.REPORTS.DEFERRED_REVENUE_BY_ITEM,
      enabled: isRevRecVisible()
    },
    {
      label: 'Deferred Revenue By Customer',
      route: PAGE_ROUTES.REV_REC_DEFERRED_REV_BY_CUSTOMER,
      permission: PERMISSIONS_BY_MODULE.REPORTS.DEFERRED_REVENUE_BY_CUSTOMER,
      enabled: isRevRecVisible()
    },
    {
      label: 'BOM Report',
      route: PAGE_ROUTES.BOM_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.BOM
    },
    {
      label: 'Reorder Level Report',
      route: PAGE_ROUTES.REORDER_LEVEL_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.REORDER_LEVEL
    },
    {
      label: 'Stock Status Report',
      route: PAGE_ROUTES.STOCK_STATUS_REPORT,
      permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_STATUS_REPORT
    }
  ];
  return getPermissionListAsPerModule(inventoryReportsWithPermissions);
};

export const getAdvancedFinanceReportData = () => {
  return getPermissionListAsPerModule(advancedFinanceReportsWithPermissions);
};

export const getAdditionalChargesReportData = () => {
  return getPermissionListAsPerModule(additionalChargesReportsWithPermissions);
};

export const getMRPReportsData = () => {
  const mrpReportsWithPermissions = [
    {
      label: 'Production Report',
      route: PAGE_ROUTES.MRP_REPORT_PRODUCTION,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION
    },
    {
      label: 'Production Summary Report',
      route: PAGE_ROUTES.MRP_REPORT_PRODUCTION_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION_SUMMARY
    },
    {
      label: 'FG Component Tracker Report',
      route: PAGE_ROUTES.MRP_REPORT_FG_COMPONENT_TRACKER,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.FG_COMPONENT_TRACKER
    },
    {
      label: 'Job Card Time Tracking Report',
      route: PAGE_ROUTES.JOB_CARD_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.JOB_CARD_TIME_TRACKING
    },
    {
      label: 'Production Costing Report',
      route: PAGE_ROUTES.MRP_REPORT_PRODUCTION_COSTING,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION_COSTING
    },
    {
      label: 'Yield Summary Report',
      route: PAGE_ROUTES.MRP_REPORT_YIELD_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.YIELD_SUMMARY
    },
    {
      label: 'Yield Summary Detailed Report',
      route: PAGE_ROUTES.MRP_REPORT_YIELD_SUMMARY_DETAILED,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.YIELD_SUMMARY_DETAILED
    },
    {
      label: 'Daily Consumption Detailed Report',
      route: PAGE_ROUTES.MRP_REPORT_DAILY_CONSUMPTION,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.DAILY_CONSUMPTION_DETAILED
    },
    {
      label: 'Daily Consumption Summary Report',
      route: PAGE_ROUTES.MRP_REPORT_DAILY_CONSUMPTION_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.DAILY_CONSUMPTION_SUMMARY
    },
    {
      label: 'Work Order Warehouse Shortfall Report Summary',
      route: PAGE_ROUTES.MRP_REPORT_SHORTFALL_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_WAREHOUSE_SHORTFALL_SUMMARY,
      enabled: Utility.isWarehouseTaggingEnabled() || false
    },
    {
      label: 'Work Order Warehouse Shortfall Detail Report',
      route: PAGE_ROUTES.MRP_REPORT_SHORTFALL_DETAIL,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_WAREHOUSE_SHORTFALL_DETAILED,
      enabled: Utility.isWarehouseTaggingEnabled() || false
    },
    {
      label: 'Bin Allocation Report',
      route: PAGE_ROUTES.MRP_REPORT_BIN_ALLOCATION_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.BIN_ALLOCATION,
      enabled: Utility.isRRBTaggingEnabled() || false
    },
    {
      label: 'QC Summary',
      route: PAGE_ROUTES.QC_SUMMARY,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.QC_SUMMARY
    },
    {
      label: 'QC Detailed Report',
      route: PAGE_ROUTES.QC_DETAILED_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.QC_DETAILED
    },
    {
      label: 'Product Wise QC Report',
      route: PAGE_ROUTES.PRODUCTWISE_QC_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCT_WISE_QC
    },
    {
      label: 'Vendor Wise QC Report',
      route: PAGE_ROUTES.VENDORWISE_QC_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.VENDOR_WISE_QC
    },
    {
      label: 'SKU Wise Order Tracking',
      route: PAGE_ROUTES.SKU_WISE_ORDER_TRACKING,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.SKU_WISE_ORDER_TRACKING
    },
    {
      label: 'Operation Wise Summary',
      route: PAGE_ROUTES.MRP_OPERATIONWISE_SUMMARY_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_PLANNING,
      enabled: Utility.isLinkedInventoryEnabled() || false
    },
    {
      label: 'Work Order Wise Operation Details',
      route: PAGE_ROUTES.MRP_REPORT_WOWISE_OPERATION_DETAILS_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_WISE_OPERATION_DETAILS,
      enabled: Utility.isLinkedInventoryEnabled() || false
    },
    {
      label: 'Restocking Levels Report',
      route: PAGE_ROUTES.MRP_REPORT_RESTOCKING_LEVELS_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.RESTOCKING_LEVELS
    },
    {
      label: 'Job Workout Report',
      route: PAGE_ROUTES.MRP_REPORT_JOB_WORKOUT_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.JOB_WORKOUT
    },
    {
      label: 'Work Order Planning Report',
      route: PAGE_ROUTES.MRP_REPORT_SO_TO_WO_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_PLANNING
    },
    {
      label: 'Order Levels Report',
      route: PAGE_ROUTES.ORDER_LEVEL_REPORT,
      permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.ORDER_LEVELS,
    },
    {
      label: 'Forecast Driven Purchase Order Report',
      route: PAGE_ROUTES.FORECAST_DRIVEN_PO_REPORT,
      permission:
        PERMISSIONS_BY_MODULE.MRP_REPORTS.FORECAST_DRIVEN_PURCHASE_ORDER
    }
  ];
  return getPermissionListAsPerModule(mrpReportsWithPermissions);
};

const getPermissionListAsPerModule = (
  reportsWithPermissions: any[],
  module?: any
) => {
  let tenantCountry =
    Store.getState().authInfo?.currentTenantInfo?.data?.country;
  return reportsWithPermissions.filter((item) => {
    const { permission, country, enabled } = item;
    if (country && country !== tenantCountry) return false;
    if (enabled !== undefined && !enabled) return false;
    return checkUserPermission(permission);
  });
};
