import {
  DKSpinner,
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import ic_barcode_green from '../../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../../Assets/Icons/ic_barcode_red.svg';
import ApiConstants from '../../../../../Constants/ApiConstants';
import {
  DOC_TYPE,
  LABELS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import useScreenResize from '../../../../../Hooks/useScreenResize';
import { DraftTypes } from '../../../../../Models/Drafts';
import { CallBackPayloadType } from '../../../../../Models/Interfaces';
import { OrderInitialState } from '../../../../../Models/Order';
import { IColumn } from '../../../../../Models/Table';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../../../../Redux/Slices/DraftsSlice';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import { addBulkWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../../../Services/NumberFormat';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import AddStockTransferPopup from '../../../../StockManagement/StockTransfer/AddStockTransfer';
import BomSelector from '../../../BomExplosion/BomSelector';
import { WORK_ORDER_STATUS } from '../../../Constants/MRPColumnConfigs';
import {
  WorkOrderHelper,
  calculateTotalNonSubstitutesAlloted,
  calculateTotalSubstituteAlloted,
  evaluateAllInventoryInTaggedWarehouse,
  getTaggedWHQtyMessage,
  getTotalCostOfLineItem,
  populateProductDetailWithSelectedOrDefaultBom
} from '../../WorkOrderHelper';
import RawMaterialHelper from './RawMaterialHelper';
import { IRawMaterial, IRawMaterialListProps } from './RawMaterialModel';
import TrackingPopupHelper from './TrackingPopupHelper';
import { REQUIRED_ITEM_TABLE } from '../../../Constants/TableConstant';
import GetAssignmentPopupForTargetWarehouseWO from './GetAssignmentPopupForTargetWarehouseWO';
import { checkUserPermission } from '../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../../Constants/Permission';
import { selectUOMs } from '../../../../../Redux/Slices/CommonDataSlice';
import AttachmentService from '../../../../../Services/Attachment';

const RawMaterialList = (props: IRawMaterialListProps) => {
  const [showStockTransferPopup, setShowStockTransferPopup] = useState(false);
  const [stockTransferItem, setStockTransferItem] = useState<any>({});
  const [assignmentState, setAssignmentState] = useState<{
    showAssignmentPopup: boolean;
    needSubstituteAllocationPopup: boolean;
    needAvailableSubstituteListPopup: boolean;
    selectedRawMaterialFromContext: any;
  }>({
    showAssignmentPopup: false,
    needSubstituteAllocationPopup: false,
    needAvailableSubstituteListPopup: false,
    selectedRawMaterialFromContext: null
  });
  const [showBomSelector, setShowBomSelector] = useState(false);
  const [taggedWH, setTaggedWH] = useState<any>();

  //selectors
  const dispatch = useAppDispatch();
  const tableId = useAppSelector(draftTableId);
  const columnConfigDraft = useAppSelector(selectDraftsColumnConfig);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);
  const uomList = useAppSelector(selectUOMs);
  const selectedRowDataForCreateWorkOrder = useRef<{
    product: any;
    rowData: any;
  } | null>(null);

  const allProductWarehouseStocks =
    props.workOrderData?.allProductsWarehouseData || [];
  const activeWorkOrderTargetWarehouse = props.workOrderData?.targetWarehouse;
  var productsArray = useRef<any>([]);

  let hasWOCreateEditPermission = props.isEditMode
    ? checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)
    : checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE);

  useEffect(() => {
    if (Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()) {
      setTaggedWH(activeWorkOrderTargetWarehouse);
    }
  }, [activeWorkOrderTargetWarehouse]);

  const getShowAllocateBtn = (taggedWHInventoryDetails: any) => {
    let showAllocateBtn = true;
    if (
      Utility.isBinAllocationMandatory() ||
      Utility.isWarehouseTaggingEnabled()
    ) {
      const totalAlloted = taggedWHInventoryDetails?.totalAlloted ?? 0;
      const totalAvailableStockInTaggedWH =
        taggedWHInventoryDetails?.totalAvailableStockInTaggedWH ?? 0;
      showAllocateBtn =
        totalAlloted > 0 ||
        (totalAlloted === 0 && totalAvailableStockInTaggedWH > 0);
    }
    return showAllocateBtn;
  };

  const openAllocationPopupAllowed = (item: any) => {
    const taggedWHInventoryDetails =
      Utility.isBinAllocationMandatory() || Utility.isWarehouseTaggingEnabled()
        ? evaluateAllInventoryInTaggedWarehouse(
            taggedWH,
            item,
            allProductWarehouseStocks
          )
        : null;
    let allowAllocation = getShowAllocateBtn(taggedWHInventoryDetails);
    return allowAllocation;
  };

  const onAdvanceTrackingButtonClicked = (rowData: any) => {
    setAssignmentState({
      showAssignmentPopup: true,
      needSubstituteAllocationPopup: false,
      needAvailableSubstituteListPopup: false,
      selectedRawMaterialFromContext: rowData
    });
    TrackingPopupHelper.apiCallsforAdvanceTracking(rowData, true);
  };

  const createWOWithBomSelection = async (product: any, selectedRow: any) => {
    try {
      if (Utility.isEmpty(RawMaterialHelper.getDefaultActiveWarehouse())) {
        showAlert(
          'Cannot proceed.',
          'No suitable warehouses are active currently for creating this WorkOrder.'
        );
        return;
      }

      showLoader('Please wait...');
      const taggedWHInventoryDetails: any =
        Utility.isBinAllocationMandatory() ||
        Utility.isWarehouseTaggingEnabled()
          ? evaluateAllInventoryInTaggedWarehouse(
              props.workOrderData.targetWarehouse,
              selectedRow,
              allProductWarehouseStocks
            )
          : null;

      let payload = await RawMaterialHelper.createWorkOrderPayload(
        selectedRow,
        product,
        taggedWHInventoryDetails,
        props.workOrderData
      );
      const res: any = await addBulkWorkOrder(payload);

      const selectedRowObj = {
        isSubstituteProduct: false,
        productCodeForSubstituteLink: selectedRow?.itemName?.pid
      };
      selectedRowDataForCreateWorkOrder.current = null;
      props.woCreateCallback?.(res, selectedRowObj);
      removeLoader();
      showAlert(
        'Work Order Created!',
        `A new WO has been created for <span class="fw-b"> ${res?.[0]?.productName} (${res?.[0]?.productCode}) </span>. Please save current WO to link the created WO.`
      );
    } catch (err) {
      removeLoader();
    }
  };

  const onClickCreatePOForProduct = (rowData: any) => {
    const taggedWHInventoryDetails =
      Utility.isBinAllocationMandatory() || Utility.isWarehouseTaggingEnabled()
        ? evaluateAllInventoryInTaggedWarehouse(
            taggedWH,
            rowData,
            allProductWarehouseStocks
          )
        : null;

    let payloadData: any = RawMaterialHelper.createPOPayload(
      rowData,
      taggedWHInventoryDetails,
      props,
      rowData,
      tableId,
      columnConfigDraft
    );
    fetchProductAttachmentsAndOpenDocumentForm(
      rowData?.itemName?.id,
      payloadData
    );
  };

  const fetchProductAttachmentsAndOpenDocumentForm = (
    productID: any,
    payloadData: any
  ) => {
    let entityId = productID;
    if (!entityId) return;
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: DOC_TYPE.PRODUCT_DOCUMENT,
      EntityId: entityId
    };
    showLoader('Fetching attachments...');
    AttachmentService.getAllAttachments()
      .then((attachmentList: any[]) => {
        removeLoader();
        payloadData.attachmentList = attachmentList;
        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.NEW
          })
        );
      })
      .catch((err: any) => {
        removeLoader();
        console.log('Failed to fetch attachments');
        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.NEW
          })
        );
      });
  };

  const onRequestStockTransferForProduct = (rowData: any) => {
    setShowStockTransferPopup(true);
    let selectedRowData = { ...rowData };

    if (
      props.isEditMode &&
      !Utility.isEmpty(props.workOrderData?.workOrderCode) &&
      !Utility.isEmpty(props.workOrderData?.documentSequenceCode)
    ) {
      selectedRowData = {
        ...selectedRowData,
        requiredQty: Utility.getUomWarehouseQuantity(
          selectedRowData?.actualRequiredQty,
          selectedRowData?.documentUOMSchemaDefinition
        ),
        actualRequiredQty: Utility.getUomWarehouseQuantity(
          selectedRowData?.actualRequiredQty,
          selectedRowData?.documentUOMSchemaDefinition
        ),
        linkedDocuments: [
          {
            documentCode: props.workOrderData?.workOrderCode,
            documentSequenceCode: props.workOrderData?.documentSequenceCode,
            documentType: DOC_TYPE.WORK_ORDER
          }
        ],
        documentUOMSchemaDefinition:
          selectedRowData?.documentUOMSchemaDefinition
      };
    }
    setStockTransferItem(selectedRowData);
  };

  const onClickCreateWOForProduct = async (selectedRowObj: any) => {
    let product = await RawMaterialHelper.callFetchProductAPI(
      selectedRowObj?.itemName?.pid
    );
    let firstProduct = product?.[0];
    if (firstProduct?.bomMetaDetailsList?.length > 1) {
      selectedRowDataForCreateWorkOrder.current = {
        product: firstProduct,
        rowData: selectedRowObj
      };
      setShowBomSelector(true);
    } else {
      createWOWithBomSelection(firstProduct, selectedRowObj);
    }
  };

  /************* GRID UTILS *************
   *************************************
   *************************************
   */

  const getShortFormForWarehouseName = (warehouseName: string) => {
    let shortName = '';
    try {
      let wordsInWarehouseName = warehouseName?.trim().split(' ');

      if (wordsInWarehouseName.length === 1) {
        shortName = wordsInWarehouseName[0].substring(0, 2);
      } else if (wordsInWarehouseName.length > 1) {
        shortName = `${wordsInWarehouseName[0][0]}${wordsInWarehouseName[1][0]}`;
      }
    } catch (err) {}

    return shortName;
  };

  const taggedWHShortfallInfo = (
    nonTaggedWHStockSummary: any[] | undefined
  ) => {
    let nonTaggedWHSummaryStr = '';
    if (!Utility.isEmpty(nonTaggedWHStockSummary)) {
      nonTaggedWHSummaryStr +=
        '<br><br>Quantities in other warehouse(s):<br><ul style="list-style-type: disc; margin-left: 15px; margin-top: 5px;">';
      nonTaggedWHStockSummary?.forEach((detail: any) => {
        nonTaggedWHSummaryStr += `<li><span class="fw-m">${detail.wh}</span>: ${detail.availableQuantity}</li>`;
      });
      nonTaggedWHSummaryStr += '</ul>';
    }
    let taggedWHMessage: any = (
      <DKTooltipWrapper
        content={
          getTaggedWHQtyMessage(taggedWH ? taggedWH.name : '') +
          (nonTaggedWHSummaryStr ? `${nonTaggedWHSummaryStr}` : '')
        }
        tooltipClassName="bg-deskera-secondary width-auto"
        tooltipStyle={{ minWidth: 350 }}
      >
        <div className="row">
          <DKIcon
            src={DKIcons.ic_warning_red}
            className="ic-s ml-s"
            onClick={() => {}}
          />
        </div>
      </DKTooltipWrapper>
    );
    return taggedWHMessage;
  };

  const getSelectedWarehouseChips = (rowData: any) => {
    const taggedWHInventoryDetails =
      Utility.isBinAllocationMandatory() || Utility.isWarehouseTaggingEnabled()
        ? evaluateAllInventoryInTaggedWarehouse(
            taggedWH,
            rowData,
            allProductWarehouseStocks
          )
        : null;
    let openAllocation = getShowAllocateBtn(taggedWHInventoryDetails);

    let warehouseInventoryData: any =
      WorkOrderHelper.parseFlatArrayOfWarehouseInventoryData(
        rowData,
        rowData?.itemName?.advancedTracking
      );

    return (
      <div
        className="row flex-wrap gap-2"
        style={{
          justifyContent:
            !Utility.isEmpty(taggedWHInventoryDetails) &&
            !taggedWHInventoryDetails?.allItemsAvailableInTaggedWH
              ? 'space-between'
              : ''
        }}
        onClick={() =>
          !props.isReadOnlyMode && onAdvanceTrackingButtonClicked(rowData)
        }
      >
        {Utility.isEmpty(warehouseInventoryData) ? (
          <DKTooltipWrapper
            content={
              openAllocation
                ? ''
                : 'Allocation cannot be done, as there is no quantity in Target Warehouse'
            }
            tooltipStyle={{ marginTop: 10 }}
          >
            <DKButton
              title="Select location"
              className="row fs-m border-radius-xs width-auto border"
              onClick={() => {}}
              style={{
                padding: '0px 6px',
                borderRadius: 4
              }}
            />
          </DKTooltipWrapper>
        ) : (
          warehouseInventoryData.map((current: any) => {
            return (
              <DKTooltipWrapper
                content={`${
                  current?.warehouseName
                } (${NumberFormatService.getNumber(current?.quantity)})`}
                tooltipClassName=""
              >
                <DKLabel
                  className="row fs-m border-radius-s width-auto py-0.5 px-1.5 data-grid-badge-color-1"
                  text={getShortFormForWarehouseName(current?.warehouseName)}
                />
              </DKTooltipWrapper>
            );
          })
        )}
        {!Utility.isEmpty(taggedWHInventoryDetails) &&
          !taggedWHInventoryDetails?.allItemsAvailableInTaggedWH &&
          taggedWHShortfallInfo(
            taggedWHInventoryDetails?.nonTaggedWHStockSummary
          )}
      </div>
    );
  };

  type RowDataFormatterConfig = {
    rowData: IRawMaterial;
    rowIndex: number;
  };

  const pickFormatter = (column: Partial<IColumn>) => {
    switch (column.key) {
      case REQUIRED_ITEM_TABLE.ITEM_NAME:
        return ({ rowData }: RowDataFormatterConfig) =>
          rowData.itemName?.['name'] || '-';
      case REQUIRED_ITEM_TABLE.AVAILABLE_QTY:
        return ({ rowData }: RowDataFormatterConfig) =>
          RawMaterialHelper.isNonServiceProduct(rowData)
            ? `${NumberFormatService.getNumber(rowData.availableQty ?? 0)}`
            : `-`;
      case REQUIRED_ITEM_TABLE.AVAILABLE_QUANTITY_IN_TARGET_WH:
        return ({ rowData }: RowDataFormatterConfig) => {
          const taggedWHInventoryDetails =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? evaluateAllInventoryInTaggedWarehouse(
                  taggedWH,
                  rowData,
                  allProductWarehouseStocks
                )
              : null;
          return taggedWHInventoryDetails?.totalAvailableStockInTaggedWH ?? 0;
        };
      case REQUIRED_ITEM_TABLE.PURCHASE_PRICE:
      case REQUIRED_ITEM_TABLE.TOTAL_AMOUNT:
        return ({ rowData }: RowDataFormatterConfig) => {
          // If work order is completed show price which we saved at the of completion.
          let priceValue = rowData.costPerUnit;
          const isNonServiceProduct =
            RawMaterialHelper.isNonServiceProduct(rowData);
          if (isNonServiceProduct) {
            const totalAlloted =
              calculateTotalNonSubstitutesAlloted(rowData) +
              calculateTotalSubstituteAlloted(rowData);
            const totalActualMaterialCost = getTotalCostOfLineItem(
              rowData,
              (props.workOrderData?.status ??
                WORK_ORDER_STATUS.OPEN) as WORK_ORDER_STATUS
            );
            let averageCost = totalActualMaterialCost / totalAlloted;
            priceValue =
              totalAlloted === 0
                ? rowData.itemName?.inventoryPrice
                : averageCost;
          }

          priceValue = Number(priceValue) || 0;

          if (column.key === REQUIRED_ITEM_TABLE.TOTAL_AMOUNT) {
            let requiredQty = isNonServiceProduct
              ? rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY] ??
                rowData[REQUIRED_ITEM_TABLE.PLANNED_QTY]
              : props.workOrderData.manufactureQuantity;
            requiredQty = Number(requiredQty) || 0;
            priceValue = requiredQty * priceValue;
          }

          return Utility.amountFormatter(priceValue, tenantInfo.currency);
        };
      case REQUIRED_ITEM_TABLE.WORK_ORDER_CHILD_DETAILS:
        return ({ rowData }: RowDataFormatterConfig) => {
          return rowData?.workOrderChildDetails ?? '-';
        };
      // case REQUIRED_ITEM_TABLE.UOM:
      //   return ({ rowData }: RowDataFormatterConfig) => {
      //     const stockUOMName = `${
      //       Utility.getUOMForStockUOMId(rowData.stockUom)?.name
      //     }`;
      //     return stockUOMName;
      //   };
      default:
        return null;
    }
  };

  const pickRenderer = (column: Partial<IColumn>) => {
    switch (column.key) {
      case REQUIRED_ITEM_TABLE.ITEM_NAME:
        return ({ rowData }: RowDataFormatterConfig) =>
          rowData.callingProductShortInfo ? (
            <div className="row justify-content-between">
              <DKLabel text={rowData.itemName?.['name'] || '-'} />
              <DKSpinner iconClassName="ic-s" />
            </div>
          ) : (
            <DKLabel text={rowData.itemName?.['name'] || '-'} />
          );
      case REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE:
        return ({ rowData }: RowDataFormatterConfig) =>
          rowData.requiredQty > 0 &&
          RawMaterialHelper.isNonServiceProduct(rowData)
            ? getSelectedWarehouseChips(rowData)
            : '-';

      case REQUIRED_ITEM_TABLE.REQUIRED_QTY:
        return ({ rowData }: RowDataFormatterConfig) => {
          const isNonServiceType =
            RawMaterialHelper.isNonServiceProduct(rowData);
          if (!isNonServiceType) {
            return <DKLabel text="-" className="row justify-content-end" />;
          }
          const totalAlloted =
            calculateTotalNonSubstitutesAlloted(rowData) +
            calculateTotalSubstituteAlloted(rowData);
          let formatterTotalAlloted: any = '';
          formatterTotalAlloted = NumberFormatService.getNumber(totalAlloted);
          const requiredQuantity =
            NumberFormatService.getNumber(rowData.requiredQty) || 0;
          const stockUOMName = `${
            Utility.getUOMForStockUOMId(rowData.stockUom)?.name
          }`;
          return (
            <div className="row align-items-center justify-content-end gap-1">
              <DKLabel text={`${formatterTotalAlloted}/${requiredQuantity}`} />
              <DKLabel text={stockUOMName} className="fs-s fw-m text-gray" />
            </div>
          );
        };
      case REQUIRED_ITEM_TABLE.WORK_ORDER_CHILD_DETAILS:
        return ({ rowData }: RowDataFormatterConfig) => (
          <DKLabel text={rowData.workOrderChildDetails ?? '-'} />
        );
      default:
    }
  };

  const getRawMaterialColumnConfigs = () => {
    let copyOfColumnConfig: Partial<IColumn>[] = deepClone(
      RawMaterialHelper.getRawMaterialColumns()
    );

    copyOfColumnConfig.forEach((column: any) => {
      column.columnCode = column.key ?? '';
      column.editable = false;

      switch (column.key) {
        case REQUIRED_ITEM_TABLE.ITEM_NAME:
          column.editable = true;
          column.dropdownConfig.data = RawMaterialHelper.getFilteredProducts(
            productsArray.current,
            props.workOrderData,
            true
          );
          column.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=${
                isAdhocEnabled ? '' : 'type!NONTRACKED,'
              }active=true,hasVariants=false`;
            return endPoint;
          };

          column.dropdownConfig.searchApiConfig.dataParser = (
            response: any
          ) => {
            let filteredData = RawMaterialHelper.getFilteredProducts(
              response?.content,
              props.workOrderData,
              true
            );
            productsArray.current = filteredData;
            return filteredData;
          };
          column.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <div className="column parent-width">
                <DKLabel className="row text-left" text={obj.name} />
                <DKLabel
                  className="text-left text-gray fs-s"
                  text={`Number: ${
                    obj.documentSequenceCode ? obj.documentSequenceCode : ''
                  }`}
                />
                {obj.hsnOrSacCode && (
                  <DKLabel
                    className="text-left text-gray fs-s"
                    text={`HSN/SAC: ${
                      obj.hsnOrSacCode ? obj.hsnOrSacCode : ''
                    }`}
                  />
                )}
              </div>
            );
          };

          column.dropdownConfig.onSelect = (index: number, value: any) => {};
          break;
        case REQUIRED_ITEM_TABLE.AVAILABLE_QUANTITY_IN_TARGET_WH:
          column.hidden = !(
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
          );
          break;
        case REQUIRED_ITEM_TABLE.AVAILABLE_QTY:
          column.name =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? `${column.name} (all WH)`
              : column.name;
          column.width =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? 150
              : 100;
          break;
        case REQUIRED_ITEM_TABLE.PURCHASE_PRICE:
          column.editable = true;
          break;
        case REQUIRED_ITEM_TABLE.REQUIRED_QTY:
          column.editable = true;
          break;
        // case REQUIRED_ITEM_TABLE.UOM:
        //   if (isAdhocEnabled) {
        //     column.editable = true;
        //     column.editable = true;
        //     column.dropdownConfig = {
        //       title: '',
        //       allowSearch: false,
        //       searchableKey: '',
        //       className: 'shadow-m',
        //       searchApiConfig: {},
        //       data: [],
        //       renderer: null,
        //       onSelect: (index: any, obj: any, rowIndex: any) => {}
        //     };
        //     column.dropdownConfig.renderer = (index: any, obj: any) => {
        //       return (
        //         <DKLabel className="row text-left" text={obj?.name ?? ''} />
        //       );
        //     };
        //   }
        //   break;

        case 'actions':
          column.options = [];
          break;
      }

      column.formatter = pickFormatter(column);
      column.renderer = pickRenderer(column);
    });

    if (!checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.VIEW_PRICE)) {
      copyOfColumnConfig = copyOfColumnConfig.filter(
        (col: any) => !['purchasePrice', 'amount'].includes(col.key)
      );
    }
    return copyOfColumnConfig.filter((column) => !column.hidden);
  };

  const getContextOptions = (rowData: any) => {
    let options: {
      title: string;
      icon: string;
      style?: any;
      onClick: (data: any) => void;
    }[] = [];

    const isServiceProduct = !RawMaterialHelper.isNonServiceProduct(rowData);
    if (isServiceProduct) return [];

    //subsitute
    if (
      isAdhocEnabled &&
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)
    ) {
      options.push({
        title: `Select Substitutes (${
          rowData.bomProductSubstitutesDetails?.length || 0
        })`,
        icon: DKIcons.ic_product,
        onClick: () =>
          setAssignmentState({
            needAvailableSubstituteListPopup: true,
            needSubstituteAllocationPopup: false,
            showAssignmentPopup: true,
            selectedRawMaterialFromContext: rowData
          })
      });
    }

    if (
      !Utility.isEmpty(rowData.bomProductSubstitutesDetails) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)
    ) {
      let pickedSubstitutes = rowData.bomProductSubstitutesDetails.filter(
        (substitute: any) => substitute?.isProductPicked
      );
      options.push({
        title: `Allocate Substitutes (${pickedSubstitutes?.length ?? 0})`,
        icon: DKIcons.ic_repeat,
        onClick: () =>
          setAssignmentState({
            showAssignmentPopup: true,
            needSubstituteAllocationPopup: true,
            needAvailableSubstituteListPopup: false,
            selectedRawMaterialFromContext: rowData
          })
      });
    }

    //po
    if (checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)) {
      options.push({
        title: 'Create PO',
        icon: DKIcons.ic_add,
        onClick: () => onClickCreatePOForProduct(rowData)
      });
    }

    // Don't show stock trasfer when warehouse tagging is ON
    //stock
    if (
      !(
        Utility.isBinAllocationMandatory() ||
        Utility.isWarehouseTaggingEnabled()
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.CREATE)
    ) {
      options.push({
        title: `Stock Transfer`,
        icon: DKIcons.ic_delivery,
        onClick: () => onRequestStockTransferForProduct(rowData)
      });
    }

    //wo
    if (
      rowData.itemName?.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
      Utility.isEmpty(rowData.workOrderChildDetails) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE)
    ) {
      options.push({
        title: 'Create WO',
        icon: DKIcons.ic_add,
        onClick: () => onClickCreateWOForProduct(rowData)
      });
    }

    return options;
  };

  const getRawMaterialRows = (allColumns: Partial<IColumn>[]) => {
    const rows =
      props.workOrderData.workOrderItems?.filter(
        (row) =>
          ![
            PRODUCE_PRODUCT_TYPE.SCRAP,
            PRODUCE_PRODUCT_TYPE.CO_PRODUCT
          ].includes(row.produceProductType)
      ) || [];

    return rows.map((row, index) => {
      row = deepClone(row);
      let invlidFields = [];
      // update the row data or add views based on row data here;
      let canShowAllocationBtn =
        !props.isReadOnlyMode &&
        RawMaterialHelper.isNonServiceProduct(row) &&
        openAllocationPopupAllowed(row);

      const linkedChildWorkOrderForRawMaterial =
        props.workOrderData?.workOrderChildDetails?.find((woChild: any) => {
          return woChild.productCode === row?.itemName?.productId;
        });
      row['workOrderChildDetails'] =
        linkedChildWorkOrderForRawMaterial?.workOrderSeqCode;

      row['rowButtons'] = [];
      row['rowContextMenu'] =
        row?.itemName?.name && !props.isReadOnlyMode
          ? getContextOptions(row)
          : null;

      const isServiceProduct = !RawMaterialHelper.isNonServiceProduct(row);

      const isQuantityAllocated = TrackingPopupHelper.isItemAssigned(row);
      let requiredQty = row?.requiredQty || 0;
      if (requiredQty > 0 && canShowAllocationBtn && !isServiceProduct) {
        row['rowButtons'].push({
          title: '',
          className: 'padding-button-custom',
          icon: isQuantityAllocated ? ic_barcode_green : ic_barcode_red,
          onClick: () => onAdvanceTrackingButtonClicked(row)
        });
      }
      let reservedQuantity = row?.reservedQuantitiesData?.reduce(
        (prev: number, current: any) =>
          prev + Number(current?.reservedQuantity || 0),
        0
      );
      const hasConsumptionEntry = props.checkIfProductConsumed(
        row?.productCode || ''
      );
      if (isAdhocEnabled) {
        if (Utility.isEmpty(row.itemName) || isServiceProduct) {
          row.nonEditableColumns = [REQUIRED_ITEM_TABLE.REQUIRED_QTY];
        }

        if (!isServiceProduct) {
          row.nonEditableColumns = (row.nonEditableColumns || []).concat(
            REQUIRED_ITEM_TABLE.PURCHASE_PRICE
          );
        }

        if (
          !props.isReadOnlyMode &&
          !reservedQuantity &&
          !hasConsumptionEntry &&
          Utility.isEmpty(row?.workOrderChildDetails)
        ) {
          row['rowButtons'].push({
            title: '',
            className: 'padding-button-custom',
            icon: DKIcons.ic_delete,
            onClick: () => props.onDeleteMaterialRow?.(row?.sequenceNumber)
          });
        } else {
          row.nonEditableColumns = [
            REQUIRED_ITEM_TABLE.ITEM_NAME,
            REQUIRED_ITEM_TABLE.REQUIRED_QTY,
            REQUIRED_ITEM_TABLE.PURCHASE_PRICE
          ];
        }
      } else {
        row.nonEditableColumns = [
          REQUIRED_ITEM_TABLE.ITEM_NAME,
          REQUIRED_ITEM_TABLE.REQUIRED_QTY,
          REQUIRED_ITEM_TABLE.PURCHASE_PRICE
        ];
      }

      row['purchasePrice'] = row?.costPerUnit;

      if (
        RawMaterialHelper.isNonServiceProduct(row) &&
        !isQuantityAllocated &&
        props.isCompleteWorkOrderClicked
      ) {
        invlidFields.push('sourceWarehouse');
      }
      if (Utility.isEmpty(row?.itemName)) {
        invlidFields.push(REQUIRED_ITEM_TABLE.ITEM_NAME);
      }
      if (
        Utility.isEmptyValue(row?.requiredQty) &&
        !Utility.isEmpty(row?.itemName)
      ) {
        invlidFields.push(REQUIRED_ITEM_TABLE.REQUIRED_QTY);
      }

      row['invalidFields'] = invlidFields;
      return row;
    });
  };

  /************* RENDERERS *************
   *************************************
   *************************************
   */
  const renderEmptyState = () => {
    return (
      <div className="column parent-size justify-content-between">
        <DKLabel
          text="BOM Materials Details"
          className="text-app-color fw-m pt-s pb-m"
        />
        <div className="column parent-size align-items-center justify-content-center">
          <div className="align-items-center justify-content-center text-gray">
            No component products found for this work order.
          </div>
          {isAdhocEnabled &&
            !props.isReadOnlyMode &&
            hasWOCreateEditPermission &&
            !props.hasProductionEntry && (
              <DKButton
                title="+ Add Material"
                className="text-app-color fw-m"
                onClick={() => props.onClickNewMaterial?.()}
                style={{}}
              />
            )}
        </div>
      </div>
    );
  };

  const getAllocationsAndSubstitutePopup = () => {
    const selectedRowForTracking =
      assignmentState.selectedRawMaterialFromContext;

    const selectedTrackingType =
      selectedRowForTracking?.itemName?.advancedTracking;

    const createProductItemForTrackingForAutoAllocate = () => {
      switch (selectedTrackingType) {
        case ADVANCE_TRACKING.SERIAL:
          return RawMaterialHelper.createProductItemForTracking(
            selectedRowForTracking,
            ADVANCE_TRACKING.SERIAL
          );

        case ADVANCE_TRACKING.BATCH:
          return RawMaterialHelper.createProductItemForTracking(
            selectedRowForTracking,
            ADVANCE_TRACKING.BATCH
          );

        case ADVANCE_TRACKING.NONE:
          return {
            ...RawMaterialHelper.createProductItemForTracking(
              selectedRowForTracking,
              ADVANCE_TRACKING.NONE
            ),
            parentQuantityToFulfill:
              selectedRowForTracking.requiredQty -
              calculateTotalSubstituteAlloted(selectedRowForTracking),
            pendingQuantity:
              selectedRowForTracking.requiredQty -
              calculateTotalSubstituteAlloted(selectedRowForTracking),
            warehouseInventoryData:
              props.workOrderData?.workOrderItems?.find(
                (item: any) =>
                  item?.itemName?.pid === selectedRowForTracking?.itemName?.pid
              )?.warehouseInventoryData ?? []
          };
      }
    };

    return selectedRowForTracking ? (
      <GetAssignmentPopupForTargetWarehouseWO
        isEditMode={props.isEditMode}
        workOrderProductId={props.workOrderData?.productCode as string}
        activeWOtargetWarehouse={taggedWH}
        existingItems={props.existingRows}
        needSubstituteAllocation={assignmentState.needSubstituteAllocationPopup}
        needAvailableSubstituteListPopup={
          assignmentState.needAvailableSubstituteListPopup
        }
        selectedProductRowForTracking={selectedRowForTracking}
        trackingItemParser={createProductItemForTrackingForAutoAllocate}
        onAssignmentSave={(
          type: ADVANCE_TRACKING | 'SUBSTITUTE',
          data: any
        ) => {
          switch (type) {
            case ADVANCE_TRACKING.BATCH:
              props.onBatchSave(
                data,
                selectedRowForTracking?.itemName?.pid,
                true
              );
              break;
            case ADVANCE_TRACKING.SERIAL:
              props.onSerialSave(
                data,
                selectedRowForTracking?.itemName?.pid,
                true
              );
              break;
            case ADVANCE_TRACKING.NONE:
              props.onNormalProductSave(
                data,
                selectedRowForTracking?.itemName?.pid
              );
              break;
            case 'SUBSTITUTE':
              props.onSubstituteSave(
                data?.[REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS],
                assignmentState.selectedRawMaterialFromContext?.sequenceNumber
              );
              break;
          }

          setAssignmentState({
            showAssignmentPopup: false,
            needSubstituteAllocationPopup: false,
            needAvailableSubstituteListPopup: false,
            selectedRawMaterialFromContext: null
          });
        }}
        onAvailableSubstituteListSave={(selectedSubstitutes: any) => {
          props.onSubstituteSave(
            selectedSubstitutes,
            assignmentState.selectedRawMaterialFromContext?.sequenceNumber
          );
          setAssignmentState({
            showAssignmentPopup: true,
            needSubstituteAllocationPopup: true,
            needAvailableSubstituteListPopup: false,
            selectedRawMaterialFromContext: {
              ...assignmentState.selectedRawMaterialFromContext,
              [REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS]:
                selectedSubstitutes
            }
          });
        }}
        onClose={() =>
          setAssignmentState({
            showAssignmentPopup: false,
            needSubstituteAllocationPopup: false,
            needAvailableSubstituteListPopup: false,
            selectedRawMaterialFromContext: null
          })
        }
      />
    ) : null;
  };

  const getHeaderButtons = () => {
    let buttons = [];
    if (
      !props.isReadOnlyMode &&
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)
    ) {
      buttons?.push({
        title: '+ Create PO',
        className: 'fw-m text-app-color p-0',
        onClick: () => {
          let payloadData: any = {};
          let po: any = {
            ...OrderInitialState,
            jobWorkOutOrderItems: [],
            showCustomAlert: true
          };
          po = getUpdatedPurchaseOrderObject(po);
          payloadData.title = LABELS.PURCHASE_ORDERS;
          payloadData.type = LABELS.PURCHASE_ORDERS;
          payloadData.populateFormData = po;
          payloadData.isCenterAlign = true;
          payloadData.tableId = tableId;
          payloadData.columnConfig = columnConfigDraft;
          payloadData.hideMinimizer = true;
          dispatch(
            createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
          );
        }
      });
    }
    buttons.push({
      title: 'BOM Explosion',
      className: 'fw-m text-app-color p-0 ',
      onClick: () => props.onRequestBomExplosionView()
    });

    if (
      isAdhocEnabled &&
      !props.isReadOnlyMode &&
      hasWOCreateEditPermission &&
      !props.hasProductionEntry
    ) {
      buttons.push({
        title: '+ Add Material',
        className: 'fw-m text-app-color p-0 ',
        onClick: () => props.onClickNewMaterial?.()
      });
    }
    return buttons;
  };

  const onRowClick = (data: any) => {
    // if (
    //   data.columnData.columnCode === REQUIRED_ITEM_TABLE.UOM &&
    //   isAdhocEnabled
    // ) {
    //   let productCopy = { ...data?.rowData };
    //   let uoms = [];
    //   let filtered = uomList?.filter((uom: any) => {
    //     const keyToCompare = productCopy?.oldStockUOM ?? productCopy?.stockUom;
    //     return uom.id === keyToCompare;
    //   });
    //   if (!Utility.isEmpty(filtered)) {
    //     uoms.push({ ...filtered[0] });
    //   }
    //   if (
    //     !Utility.isEmpty(productCopy) &&
    //     !Utility.isEmpty(productCopy.uomSchemaDto)
    //   ) {
    //     let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
    //       (uomSchema: any) => {
    //         let filteredFromList = uomList?.filter(
    //           (uom: any) => uom.id === uomSchema.sinkUOM
    //         );
    //         let name = '';
    //         if (filteredFromList && filteredFromList.length > 0) {
    //           name = filteredFromList[0].name;
    //         }
    //         return {
    //           ...uomSchema,
    //           name: name,
    //           id: uomSchema?.sinkUOM
    //         };
    //       }
    //     );
    //     uoms = uoms.concat(processedUoms);
    //   }
    //   data.columnData.dropdownConfig.data = uoms ?? [];
    // }
  };

  const gridColumns = getRawMaterialColumnConfigs();
  const gridRows = getRawMaterialRows(gridColumns);
  const rawMaterialListContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(rawMaterialListContainerRef);
  const containerMaxHeight = 640;
  const gridMaxHeight = containerMaxHeight - 70;

  return (
    <div
      className="column bg-white border-m border-radius-m p-l parent-width"
      ref={rawMaterialListContainerRef}
      style={{
        height: 'auto',
        width: props.isEditMode ? '49.5%' : '100%',
        maxHeight: containerMaxHeight
      }}
    >
      {!gridRows?.length ? (
        renderEmptyState()
      ) : (
        <DKDataGrid
          width={(width || 500) - 36}
          title={`<span class="text-app-color">${'BOM Materials Details'}</span>`}
          styles={{
            mainGridHolder: { marginBottom: -18 },
            gridScrollHolder: { maxHeight: gridMaxHeight, overflowX: 'scroll' },
            shadowHolder: { maxHeight: gridMaxHeight }
          }}
          buttons={getHeaderButtons()}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowBulkOperation={false}
          columns={gridColumns}
          rows={gridRows}
          onRowUpdate={props.onRowChange}
          onRowClick={onRowClick}
        />
      )}
      {assignmentState.showAssignmentPopup &&
        getAllocationsAndSubstitutePopup()}
      {showStockTransferPopup && (
        <AddStockTransferPopup
          records={[]}
          selectedRecord={null}
          detail={stockTransferItem}
          targetWarehouse={
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? taggedWH
              : null
          }
          document={DOC_TYPE.WORK_ORDER}
          passingInteraction={(callback: CallBackPayloadType) => {}}
          onSave={() => {
            setShowStockTransferPopup(false);
            setStockTransferItem({});
            props.onStockTransfer?.();
          }}
          onCancel={() => {
            setShowStockTransferPopup(false);
            setStockTransferItem({});
          }}
          allowFullScreen
        />
      )}
      {showBomSelector && (
        <BomSelector
          selectedProduct={selectedRowDataForCreateWorkOrder.current?.product}
          onCancel={() => setShowBomSelector(false)}
          onSelect={(bomConfig: any) => {
            setShowBomSelector(false);
            const productCopy = populateProductDetailWithSelectedOrDefaultBom(
              selectedRowDataForCreateWorkOrder.current?.product,
              bomConfig
            );
            createWOWithBomSelection(
              productCopy,
              selectedRowDataForCreateWorkOrder.current?.rowData
            );
          }}
        />
      )}
    </div>
  );
};

export default RawMaterialList;
