import { useEffect, useState } from 'react';
import { DKButton, DKLabel, DKSearchBar, DKTable } from 'deskera-ui-library';
import { FORMULA_FUNCTIONS, FormulaFunction } from './formula-functions';
import useDebounce from '../../Hooks/useDebounce';
import { getColumnConfigForFormula } from './formula-helper';
import Utility from '../../Utility/Utility';
import { MODULES_NAME } from '../../Constants/Constant';

interface FormulaHelpGuideProps {
  fieldName: string;
  moduleName: MODULES_NAME;
  onClose: () => void;
}

const paramTableConfig: any[] = [
  {
    key: 'paramName',
    title: 'Name',
    width: 200,
    editable: false,
    renderer: ({ cell }: any) => {
      return <DKLabel text={cell} className="whitespace-pre-wrap break-all" />;
    }
  },
  {
    key: 'paramKey',
    title: 'Key',
    width: 235,
    editable: false,
    renderer: ({ cell }: any) => {
      return (
        <pre>
          <code style={{ whiteSpace: 'normal' }}>
            {/* <DKLabel text={cell} className="whitespace-pre-wrap break-all" /> */}
            {cell}
          </code>
        </pre>
      );
    }
  }
];

const FormulaHelpGuide = (props: FormulaHelpGuideProps) => {
  const TAB = { FUNCTIONS: 'functions', PARAMETERS: 'parameters' };
  const [allFunctions, setAllFunctions] =
    useState<FormulaFunction[]>(FORMULA_FUNCTIONS);
  const [tableData, setTableData] = useState<{ columns: any[]; rows: any[] }>({
    columns: paramTableConfig,
    rows: []
  });
  const [currentTab, setCurrentTab] = useState(TAB.FUNCTIONS);
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const setSearchResults = (searchTxt: string) => {
    const filteredResults = FORMULA_FUNCTIONS.filter((func) =>
      func.name?.toLowerCase()?.startsWith(searchTxt?.toLowerCase())
    );

    setAllFunctions(filteredResults);
  };

  useEffect(() => {
    const formulaColumnConfig = getColumnConfigForFormula(
      props?.fieldName,
      props?.moduleName,
      props?.moduleName !== MODULES_NAME.PRODUCT
    );

    // console.log('formulaColumnConfig help:', formulaColumnConfig);

    if (!Utility.isEmpty(formulaColumnConfig)) {
      const rows = formulaColumnConfig
        .sort((c1: any, c2: any) => c1.isLineItem - c2.isLineItem)
        .map((config: any) => ({
          paramName:
            config?.name +
            (config?.isLineItem
              ? ' <span class="text-gray fs-s">(line item)<span/>'
              : ''),
          paramKey: config?.key
        }));
      setTableData({ ...tableData, rows });
    }
  }, []);

  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== 'undefined' &&
      debouncedSearchTerm !== null
    ) {
      setSearchResults(debouncedSearchTerm);
    } else {
      setSearchResults('');
    }
  }, [debouncedSearchTerm]);

  const getTabItem = (
    title: string,
    classname: string,
    selected: boolean,
    onclick: () => void
  ) => {
    return (
      <div
        className={classname}
        style={{
          padding: '12px 12px 10px',
          borderBottom: selected
            ? '2px solid var(--btn-bg-color)'
            : '2px solid transparent',
          fontWeight: selected ? 700 : 400,
          color: selected ? 'var(--btn-bg-color)' : 'var(--books-text-color)'
        }}
        onClick={onclick}
      >
        {title}
      </div>
    );
  };

  const getAllTabs = () => {
    return (
      <div className="row row-responsive parent-width justify-content-between tab-block-bg border-radius-s border-m">
        <div className="row row-responsive parent-width p-h-xs">
          {getTabItem(
            'Functions',
            'cursor-hand fs-r mr-r',
            currentTab === TAB.FUNCTIONS,
            () => {
              setCurrentTab(TAB.FUNCTIONS);
            }
          )}
          {getTabItem(
            'Parameters',
            'cursor-hand fs-r mr-r',
            currentTab === TAB.PARAMETERS,
            () => {
              setCurrentTab(TAB.PARAMETERS);
            }
          )}
        </div>
        {currentTab === TAB.FUNCTIONS && (
          <DKSearchBar
            onSearch={(value: string) => {
              setSearchTerm(value);
            }}
            placeholder="Search..."
            searchText={searchTerm}
            style={{ marginRight: 0 }}
          />
        )}
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row pop-header-drag-handle">
          <DKLabel text="Formula Help" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              props.onClose();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          padding: 0,
          maxHeight: '90%',
          width: 550,
          overflow: 'hidden'
        }}
      >
        <div className="column parent-width flex-1" style={{ minHeight: 0 }}>
          {getHeader()}
          <div
            className="column parent-width p-r"
            style={{ lineHeight: 1.15, minHeight: 0 }}
          >
            <div className="column parent-width">
              <DKLabel
                text="Start typing a function or select a parameter from the suggestions."
                className="mb-r"
              />
              <DKLabel
                text="Use autocomplete to quickly insert functions and module parameters."
                className="mb-r"
              />
              <DKLabel text="Example usage: " />
              <DKLabel
                text="SUM(unitPrice, quantity)"
                className="text-gray ml-l mt-xs"
              />
              <DKLabel text="SUM(10, 20)" className="text-gray ml-l mt-xs" />
            </div>
            <div className="column parent-width mt-r" style={{ minHeight: 0 }}>
              {getAllTabs()}
              {currentTab === TAB.FUNCTIONS && (
                <div
                  className="column parent-width overflow-y-auto mt-r flex-1 border-m border-radius-s show-scroll-bar"
                  style={{ minHeight: 0 }}
                >
                  {allFunctions.length === 0 && (
                    <DKLabel
                      text="No functions found"
                      className="row justify-content-center text-gray pt-xxl pb-xxl"
                    />
                  )}
                  {allFunctions.map((func) => {
                    return (
                      <div className="column parent-width p-v-s border-b p-h-s">
                        <div className="row align-items-start">
                          <DKLabel
                            text="Name:"
                            className="fw-m"
                            style={{ marginRight: 37 }}
                          />
                          <DKLabel text={func.name} className="ml-r" />
                        </div>
                        <div className="row align-items-start mt-s">
                          <DKLabel text="Description:" className="fw-m" />
                          <DKLabel text={func.description} className="ml-r" />
                        </div>
                        <div className="row align-items-start mt-s">
                          <DKLabel
                            text="Example:"
                            className="fw-m"
                            style={{ marginRight: 20 }}
                          />
                          <div className="ml-r">
                            <pre>
                              <code
                                className="text-gray"
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  fontSize: '13px'
                                }}
                              >
                                {func.example}
                              </code>
                            </pre>
                          </div>
                        </div>
                        <div className="row align-items-start mt-s">
                          <DKLabel
                            text="Result:"
                            className="fw-m"
                            style={{ marginRight: 34 }}
                          />
                          <div className="ml-r">
                            <pre>
                              <code
                                className="text-gray"
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  fontSize: '13px'
                                }}
                              >
                                {func.result}
                              </code>
                            </pre>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {currentTab === TAB.PARAMETERS && (
                <div className="column parent-width overflow-y-auto border-m border-radius-s mt-r">
                  <div className="column parent-width p-r">
                    <DKTable
                      rows={tableData.rows}
                      columns={tableData?.columns}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormulaHelpGuide;
