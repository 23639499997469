import { APP_NAME, DOC_TYPE, STOCK_URL_API } from './Constant';
import { FINANCIAL_REPORT, NON_FINANCIAL_REPORT } from './Enum';

export default class ApiConstants {
  static URL = {
    BASE: process.env.REACT_APP_BASE_URL + 'v1/',
    STOCK_BASE: process.env.REACT_APP_STOCK_BASE_URL + 'v1/',
    STOCK_BASE_API: STOCK_URL_API + 'v1/',
    ASSET_BASE: process.env.REACT_APP_ASSET_URL + 'v1/',
    APP_ERP_URL: process.env.REACT_APP_ERP_URL,
    APP_URL_DOCUMENT_BUILDER: process.env.REACT_APP_URL_DOCUMENT_BUILDER,
    APP_STORE_URL: process.env.REACT_APP_STORE_URL,
    APP_MRP_URL: process.env.REACT_APP_URL_MRP,
    APP_INTEGRATION: {
      GET_AUTH_URL: 'crm/app-integration/onedrive/get-authorize-url',
      CONNECT: 'crm/app-integration/connect',
      DISCONNECT: 'crm/app-integration/disconnect',
      MAPPING: 'crm/app-integration/mapping'
    },
    TABLE: {
      GET_ALL: `/crm/core/table?databaseName=books`,
      GET: (tableId: string) => `crm/core/table/${tableId}`,
      ADD_COLUMN: (tableId: string) => `crm/core/table/${tableId}/column`,
      ADD_RECORD: (tableId: string) => `crm/core/table/${tableId}/record`,
      GET_RECORD: (tableId: string) => `crm/core/table/${tableId}/record`,
      GET_RECORD_BY_PAGE: (tableId: string) =>
        `crm/core/table/${tableId}/paged-record`,
      UPDATE_RECORD: (recordId: string, tableId: string) =>
        `crm/core/table/${tableId}/record/${recordId}`,
      GET_RECORD_BY_ID: (recordId: string, tableId: string) =>
        `crm/core/table/${tableId}/record/${recordId}`,
      DELETE_RECORD: (recordId: string, tableId: string) =>
        `crm/core/table/${tableId}/record/${recordId}`,
      DELETE_BULK_RECORD: (tableId: string) =>
        `crm/core/table/${tableId}/record`,
      DELETE_COLUMN: (columnId: string, tableId: string) =>
        `crm/core/table/${tableId}/column/${columnId}`,
      UPDATE_COLUMN: (columnId: string, tableId: string) =>
        `crm/core/table/${tableId}/column/${columnId}`,
      SHIFT_COLUMN: (tableId: string) =>
        `crm/core/table/${tableId}/save-col-order`,
      ADD_BULK_RECORD: (tableId: string) =>
        `crm/core/table/${tableId}/record-bulk`,
      UPDATE_BULK_RECORD: (tableId: string) =>
        `crm/core/table/${tableId}/record-bulk`,
      ADD_TABLE: `crm/core/table`,
      UPDATE_APPROVAL_STATUS: (tableId: string) =>
        `crm/core/table/${tableId}/approval`
    },
    IAM: {
      REDIRECT: process.env.REACT_APP_LOGIN_URL,
      LOG_OUT: `iam/auth/logout`,
      LOG_OUT_V2: `iam/auth/logout2`,
      STATUS: `iam/auth/sign-in/login/status`,
      SWITCH_TENANT: `iam/users/web/switch-tenant`,
      SWITCH_CLIENT_TENANT: `iam/users/switch-client`,
      TFA_STATUS: `iam/auth/sign-in/web/mfa-status`,
      ENABLE_TFA: `iam/auth/sign-in/web/mfa-enable`,
      DELETE_TFA: `iam/auth/sign-in/web/mfa`,
      ADD_USER: `iam/users/tenants/roles`,
      UPDATE_USER: `users/update/info`,
      CHANGE_PASSWORD: `iam/users/change-password`,
      CODE_TFA: `iam/auth/sign-in/web/mfa`,
      RESEND_VERIFICATION_MAIL: `iam/auth/email/regenerate`,
      REFRESH_TOKEN: `iam/auth/web/getrefreshtoken`,
      IDLE_TIMEOUT_TOKEN_EXPIRY: `iam/token/idletimeouttokenexpiry`
    },
    USER: {
      ROLES: `users/roles`,
      DELETE_REASON: `iam/users/account/`,
      DETAIL: `users/users/count`,
      USER_INFO: `users/userinfo`,
      USER_INFO_BY_ID: (userId: number) => `users/userinfo?query=id=${userId}`,
      TENANTS: `users/tenants`,
      USERS: `users`,
      USERS_SHORT_INFO: `/users/userinfo/short`,
      PREFERENCE: `/crm/core/settings/user`
    },
    TENANT: {
      GET_DETAILS: (countryCode: string) => `tenants${countryCode}/details`,
      GET_TENANT_DETAILS: `tenants/details`,
      CLIENT_DETAILS: `tenants/bookkeeper/client-tenants`,
      COMPANY_NAME: `tenants/TENANT_ID/settings/name`,
      RESET_COMPANY: `tenants/reset`,
      DELETE_COMPANY: `tenants/`,
      PATCH_TENANT: `tenants`,
      PAYMENT: `tenants/bookkeeper/payment`,
      ORG_WITHOUT_SETUP: `tenants/**/organisationwithoutsetup`,
      TENANTS: `users/tenants`,
      TENANTS_DETAILED: (userId: string) =>
        `tenants/user/${userId}/tenants-info`,
      GET_PERMISSSIONS: `tenants/permissions`,
      GET_USER_PERMISSIONS: `tenants/permissions/users`,
      GET_FEATURE_PERMISSIONS: `subengine/planfeatures`,
      UPDATE_ORG: (countryCode: string) => `tenants${countryCode}`,
      APP_CUSTOMIZATION: `/tenants/apps-customization`,
      DEMO_ORG: (countryCode: string) =>
        `tenants/demo-organization?country=${countryCode}`,
      ORG_WITH_SETUP: (countryCode: string) =>
        `tenants/${countryCode ? countryCode + '/' : ''}organisation`,
      UPDATE_ORG_STATUS: `tenants/setup/status`,
      FETCH_ATTACHMENT: `attachment/file-download`,
      UPDATE_CONTROL_ACC_SETTING: (tenantId: any) =>
        `tenants/${tenantId}/settings/controlaccount`,
      BARCODE_SETTINGS: (tenantId: any) =>
        `tenants/${tenantId}/settings/barcode`,
      TENANT_CONTACT_INFO: 'tenants/contact-info',
      CHECK_UEN_NO: (uen: any) => `contact/sg-uen/${uen}`,
      GET_PARENT_TENANTS: `tenants/**/get-tenants-by-nature?nature=PARENT`,
      GET_SUBSIDIARY_TENANTS: (tenantId: string) =>
        `tenants/**/get-tenants-by-nature?nature=SUBSIDIARY&tenantId=${tenantId}`,
      GET_TENANT_BY_EMAIL: (email: string) =>
        `subengine/admin/tenants/${email}`,
      GET_TENANT_SUBSCRIPTION_PLAN_DETAILS: (tenantId: string) =>
        `subengine/admin/sub/${tenantId}`,
      GET_TENANT_GROUP: (tenantId: string | number) =>
        `tenants/user/${tenantId}/tenants-info`
    },
    SUBSCRIPTIONS: {
      GET_SUBSCRIPTION: `subengine/subscription`,
      GET_BOOKS_SUBSCRIPTION_INFO: `subengine/subscription/books`
    },
    PUBLIC_PAGE: {
      GET_JSON_DATA: (id: any) => `crm/core/deskera/open/${id}`
    },
    SMTP: {
      SETUP: `crm/emails/campaign/setup`,
      GET_ALL_SETUPS: `crm/emails/campaign/setup?app_name=${APP_NAME}`,
      SETUP_WITH_ID: (id: any) => `crm/emails/campaign/setup/${id}`
    },
    IMPORT_EXPORT: {
      UPLOAD_FILE: `attachment/file-upload`,
      UPLOAD_RECORDS: `exim/crm3/import`,
      AUDIT_LOG: `exim/audit`,
      AUTOMATION_LOGS: `/crm/core/workflow/automation-log`,
      EXPORT_AUTOMATION_LOGS: `/crm/core/workflow/automation-log/export`
    },
    PRODUCT: {
      FETCH_PRODUCTS: `/products`,
      FETCH_STOCK_TRANSFER: `/stock/stockTransfers`,
      FETCH_STOCK_ISSUE: `/products/stockissue/`,
      FETCH_STOCK_REQUEST: `/products/stockrequest/`,
      STOCK_REQUEST_APPROVAL: `/products/stockrequest/approvestatus/`,
      FETCH_STOCK_TRANSFER_PRODUCT: `/stock/products`,
      FETCH_PRODUCT_BREIF_SEARCH: '/products/brief-search',
      FETCH_STOCK_TRANSFER_PRODUCT_BRIEF: `/stock/products/allproductbrief`,
      FETCH_STOCK_TRANSFER_PRODUCT_SEQ_CODE: '/products/doc-seq-codes',
      CREATE_ASSEMBLY: `/products/assemble/bomassemble`,
      POST_UOM: `/products/uom`,
      EDIT_UOM: (id: any) => `/products/uom/${id}`,
      DELETE_UOM: (id: any) => `/products/uom/${id}`,
      FETCH_UOM: `/products/uom/search`,
      FETCH_SUMMARY: `/products/summary?query=isVariant=false`,
      ATTRIBUTE: `/products/attribute`,
      VARIANTS: `/products/variants`,
      UPDATE_INVENTORY: `/in/inventory`,
      FETCH_WAREHOUSE_INVENTORY: `products/inventories/warehouses/warehouse-products`,
      POST_WAREHOUSE: `products/inventories/warehouses`,
      FETCH_WAREHOUSE: `products/inventories/warehouses/search`,
      EDIT_WAREHOUSE: (id: any) => `products/inventories/warehouses/${id}`,
      DELETE_WAREHOUSE: (id: any) => `products/inventories/warehouses/${id}`,
      GET_PRIMARY_WAREHOUSE: `products/inventories/warehouses/primary_details`,
      UPDATE_STATUS_WAREHOUSE: (id: any) =>
        `products/inventories/warehouses/${id}`,
      // FETCH_PRODUCT_ADVANCED_TRACKING_WAREHOUSE: `/products/inventories/warehouses/warehouse-products-code-advanced-track`, // replace to following api for optimization
      FETCH_PRODUCT_ADVANCED_TRACKING_WAREHOUSE: `/products/inventories/warehouses/warehouse-products-code-advanced-track-new`,
      FETCH_PRODUCT_WAREHOUSE_CODES: `/products/inventories/warehouses/warehouse-products-code`,
      FETCH_REJECTED_WAREHOUSES: `products/inventories/warehouses/rejected`,
      FETCH_ADVANCED_TRACKING_DATA: (code: any) =>
        `/products/inventories/advanced-tracking/search?query=productVariantCode=${code}&isCombined=true`,
      FETCH_UOM_SCHEMA: `/products/uom-schema/search`,
      DELETE_UOM_SCHEMA: (id: any) => `/products/uom-schema/${id}`,
      POST_UOM_SCHEMA: `/products/uom-schema`,
      EDIT_UOM_SCHEMA: (id: any) => `/products/uom-schema/${id}`,
      CHANGE_PRODUCT_STATUS: `products/activate?status=`,
      ARCHIVE_PRODUCT: `products/archive/true`,
      UNARCHIVE_PRODUCT: `products/archive/false`,
      DELETE_VARIANT: (id: number) => `products/variants/${id}`,
      GET_VARIANT: (id: number) => `products/variants/${id}`,
      ADVANCED_TRACKING_META_DATA: `products/inventories/advanced-tracking/search`,
      VALIDATE_PRODUCT: `products/is-used`,
      DEFAULT_TAXCODE: `taxes/codes`,
      UPDATE_INVENTORY_SETTING: (id: number) =>
        `/tenants/${id}/settings/inventory`,
      getBomDetails: (id: number) =>
        `products/assemble/bomdetails?productId=${id}`,
      getBomDetailsWithQty: (id: number, qty: number) =>
        `products/assemble/bomdetails?productId=${id}&quantityToBuild=${qty}`,
      getBomDetailsWithQtyForJWO: (id: number, qty: number) =>
        `products/assemble/bom_details_new?productId=${id}&quantityToBuild=${qty}`,
      FETCH_PRODUCT_BY_PRODUCT_IDS: 'products/code/details',
      PATCH_TAX_PREFERENCE_SG: 'taxes/set-default',
      RESERVED_QUANTITY_INFO: 'products/inventories/reserved-quantity-combined',
      GET_WAREHOUSE_PRODUCTS_DATA:
        'products/inventories/warehouses/warehouse-products',
      FETCH_PRODUCT_DETAILS_BY_IDS: '/products/ids',
      FETCH_BOM_EXLPOSION_PRODUCT_DETAILS: (
        productCode: string,
        bomMetaCode: string
      ) =>
        `products/assemble/bomexplosiondetails?productCode=${productCode}&bomMetaCode=${bomMetaCode}`,
      FETCH_ADHOC_BOM_EXLPOSION_PRODUCT_DETAILS: (
        productCode: string,
        workOrderCode: string
      ) =>
        `products/assemble/adhoc_bom_explosion_details?productCode=${productCode}&workOrderCode=${workOrderCode}`,
      FETCH_ADDITIONAL_BOM_EXPLOSIONS: `products/assemble/bomexplosiondetails`,
      FETCH_ADDITIONAL_ADHOC_BOM_EXPLOSIONS: `products/assemble/adhoc_bom_explosion_details`,
      MRP_PRODUCT_FETCH: 'products',
      MRP_PRODUCT_SHORT_INFO: 'products/productshortinfo',
      ASSIGN_USERS_TO_WAREHOUSE: (id: number) =>
        `products/inventories/warehouses/assignusers/${id}`,
      MRP_GET_USED_BOM_LIST_FOR_PID: (productId: string) =>
        `products/getusedboms?pid=${productId}`,
      FETCH_BULK_UPDATE_PRODUCT_LIST: 'products/assemble/allproductbomdetails',
      BULK_UPDATE_PRODUCT_LIST: 'products/assemble/bom-bulk-update',
      BULK_REPLACE_PRODUCT_LIST: 'products/assemble/bom-bulk-remove-replace',
      GET_BOM_VERSION_HISTORY: 'products/assemble/bom-version-details?',
      GET_DETAILS_FOR_BULK_GENERATE_BARCODE:
        '/products/barcode-generate/product-details',
      GET_UNASSIGNED_QUANTITY: (productCode: string) =>
        `products/getunassignedquantity/${productCode}`,
      PATCH_PRODUCT_IMAGES: 'products/product-images-by-product-id',
      PRODUCT_COMPONENT_GROUP_DETAILS: `/products/assemble/bom-component-group-details`,
      GET_PRODUCTS_BY_VENDOR: 'products/product-by-vendors',
      GET_PRODUCT_PRICE_BY_VENDOR: (contactCode: string, productCode: string) =>
        `invoices/purchase/avg-price-last-price/${contactCode}/${productCode}`,
      GET_WAREHOUSE_BY_CODE: `/products/inventories/warehouses/search`
    },
    STOCK_REQUEST: {
      DELETE_BY_CODE: (code: string) => `products/stockrequest/code/${code}`
    },
    PRODUCT_GROUP: {
      GET_ALL: `products/product-group/search`,
      CREATE: `products/product-group`,
      UPDATE: (id: any) => `products/product-group/${id}`
    },
    DASHBOARD: {
      BUY_SELL: `/reports/common/buy_sell_dashboard`,
      AGED_DASHBOARD: `/reports/aged/aged-dashboard`,
      CASH_FLOW_DASHBOARD: `account/reports/cash-flow-dashboard`,
      BACK_ACCOUNT_DASHBOARD: `account/reports/dashboard/bank`,
      PROFIT_AND_LOST: `account/reports/dashboard/pnl`
    },
    CUSTOM_FIELD: {
      CREATE_CUSTOM_FIELD: `dimension`,
      UPDATE_CUSTOM_FIELD: (id: number) => `dimension/${id}`,
      DELETE_CUSTOM_FIELD: (id: number) => `dimension/${id}`,
      FETCH: 'dimension',
      UPDATE_CF_ORDER: 'dimension/module',
      FETCH_BY_MODULES: 'dimension/custom-fields-by-modules',
      GET_CF_ID_NAME_BY_TENANT_IDS: 'dimension/name-code-by-tenant'
    },
    ORDERS: {
      FETCH_ORDERS: `orders/purchase`,
      DELETE_ORDER: (id: number) => `orders/purchase/${id}`,
      DELETE_ORDER_BY_CODE: (code: string) =>
        `orders/purchase/deleteByCode?purchaseOrderCode=${code}`,
      CREATE_ORDER: `orders/purchase/`,
      UPDATE_ORDER: (country: string, id: string) =>
        `orders/purchase/${country}/${id}`,
      FETCH_ORDER_DETAILS: (orderCode: string) =>
        `orders/purchase/code/${orderCode}/details`,
      BULK_BELETE: '/orders/purchase/bulk-delete',
      BULK_CREATE_ORDER: (country: string) => `orders/purchase/${country}/bulk`
    },
    RATE_ANALYSIS: {
      FETCH_RATE_ANALYSIS: `reports/rate-analysis/get-rate-analysis`,
      SAVE_RATE_ANALYSIS: `reports/rate-analysis/save-rate-analysis`,
      GET_PRODUCT_PURCHASING_METHOD: `reports/rate-analysis/get-product-purchase-price`,
      UPDATE_DRAFT_RATE_ANALYSIS: `reports/rate-analysis/update-draft-rate-analysis`,
      DELETE_DRAFT_RATE_ANALYSIS: (id: string) =>
        `/reports/rate-analysis/delete-draft-rate-analysis/code/${id}`
    },
    WORK_OUT: {
      WORK_OUTS: `orders/jobworkout`,
      UPDATE_WORK_OUTS: (country: string, id: string) =>
        `orders/jobworkout/${country}/${id}`,
      DELTE_WORK_OUT: (id: number) => `orders/jobworkout/${id}`,
      FETCH_WORK_OUT_DETAILS: (jwoCode: string) =>
        `orders/jobworkout/code/${jwoCode}/details`,
      FETCH_WORK_OUT_DETAILS_BY_WO: (jwoCode: string) =>
        `orders/jobworkout/code/${jwoCode}/detailsbyworkorder`,
      BULK_BELETE: 'orders/jobworkout',
      MARK_AS_CONSUMED: (documentCode: any) =>
        `products/inventories/mark-jwo-closed/${documentCode}`
    },
    REQUISITION: {
      CREATE_ORDER: `orders/purchaserequest`,
      REQUISITION: `orders/purchaserequest/`,
      DELETE_REQUISITION: (id: number) => `orders/purchaserequest/${id}`,
      DELETE_REQUISITION_BY_CODE: (code: string) =>
        `orders/purchaserequest?purchaseRequestCode=${code}`,
      UPDATE_REQUISITION: (orderCode: any) =>
        `orders/purchaserequest/${orderCode}`,
      BULK_DELETE_REQUISITION: 'orders/purchaserequest/bulk-delete',
      UPDATE_REQUISITION_MEMO: (orderCode: any) =>
        `orders/purchaserequest/${orderCode}/memo`
    },
    FILES: {
      UPLOAD: `attachment/file-upload/public`
    },
    CLIENT: {
      INVITE: `client-portal/register`,
      RESEND_INVITE: `client-portal/resend-invite`
    },
    //RFQ: do not user this (old apis)
    RFQ: {
      CREATE_RFQ: `orders/purchaserequestforquotes`,
      FETCH_RFQ: `orders/purchaserequestforquotes`,
      DELETE_RFQ: (id: number) => `orders/purchaserequestforquotes/${id}`,
      FETCH_RFQ_BY_ID: (rfqCode: any) =>
        `orders/purchaserequestforquotes/${rfqCode}`,
      PUBLIC_FORM_URL: (rfqCode: any) =>
        `public/quotation-form/${btoa(rfqCode)}`
    },
    //RFQ: do not user this (old apis)

    REQUEST_FOR_QUOTATION_RFQ: {
      FETCH_ALL_RFQS: '/orders/requestforquotation',
      CREATE: '/orders/requestforquotation',
      DELETE: (code: any) => `/orders/requestforquotation?rfqCode=${code}`,
      GET_RFQ_DETAIL_BY_CODE: (code: any) =>
        `/orders/requestforquotation/${code}`,
      UPDATE_RFQ_DOC: (id: any) => `/orders/requestforquotation/${id}`,
      OPEN: {
        GET: (id: string, tid: string, cid: string) =>
          `/orders/requestforquotation/external/${id}?tenantId=${tid}&contactCode=${cid}`,
        POST: `/orders/requestforquotation/external/piqsubmittedemail`
      },
      FETCH_QUOTES_COMPARISON: (docSeqCode: any) =>
        `/orders/requestforquotation/quotescomparisonreport/${docSeqCode}`,
      CLOSE_MANUALLY: (code: any) =>
        `/orders/requestforquotation/closeRFQ?rfqCode=${code}`
    },

    PURCHASE_INWARD_QUOTATION: {
      FETCH_ALL_PIQ: '/orders/purchaseinwardquotation',
      GET_PIQ_DETAIL_BY_CODE: (code: any) =>
        `/orders/purchaseinwardquotation/${code}`
    },

    SUPPLIER_QUOTES: {
      CREATE_SPQ: `orders/supplierquotes`,
      FETCH_SPQ: `orders/supplierquotes`,
      DELETE_SPQ: (id: number) => `orders/supplierquotes/${id}`,
      FETCH_SPQ_BY_ID: (rfqCode: any) => `orders/supplierquotes/${rfqCode}`
    },

    ASSET_GROUP: {
      FETCH_ASSET_GROUP: `asset-integration/group`,
      UPDATE_ASSET_GROUP: (id: any) => `asset-integration/group/${id}`,
      CHANGE_ASSET_GROUP_STATUS: (id: any) =>
        `asset-integration/group/status/${id}`,
      DELETE_ASSET_GROUP_BY_ID: (id: any) => `asset-integration/group/${id}`
    },

    INVOICES: {
      FETCH_INVOICES: `/invoices/sales`,
      CREATE_INVOICE: `/invoices/sales/`,
      UPDATE_INVOICE: `/invoices/sales/`,
      FETCH_INVOICE: `/invoices/sales`,
      FETCH_PURCHASE_INVOICE: `/invoices/purchase`,
      GET_PURCHASE_INVOICE_WITH_COUNTRY_AND_CODE: (
        country: string,
        code: string
      ) => `invoices/purchase${country}/${code}`,
      FETCH_TAXES: `/taxes`,
      FETCH_CONFIGURED_PAYMENT_OPTIONS: `/payments/integrations/configured-payment-accounts`,
      DELETE_INVOICE: (id: number, country: string) =>
        `/invoices/sales${country}/${id}`,
      DELETE_INVOICE_BY_CODE: (code: string) =>
        `/invoices/sales?salesInvoiceCode=${code}`,
      DELETE_INVOICE_PAYMENT_RECORD: (
        salesInvoiceCode: any,
        paymentCode: any
      ) =>
        `invoices/sales/receive?paymentCode=${paymentCode}&salesInvoiceCode=${salesInvoiceCode}`,
      DELETE_CREDIT_NOTE_PAYMENT_RECORD: (code: any, invoicecode: any) =>
        `invoices/sales/notes?code=${code}&invoicecode=${invoicecode}`,
      DELETE_DEBIT_NOTE_PAYMENT_RECORD: (code: any, invoicecode: any) =>
        `invoices/purchase/notes?code=${code}&invoicecode=${invoicecode}`,
      REMINDER_SETTINGS: `/invoices/reminder/settings`,
      REMINDER_CONFIGURATION: `/invoices/reminder/configuration`,
      UPDATE_INVOICE_CONFIGURATION: `/invoices/reminder/configuration`,
      UPDATE_INVOICE_SETTINGS: `/invoices/reminder/settings`,
      INVOICES: `invoices/`,
      INVOICE_SUMMARY: 'reports/document/sales/summary',
      BULK_DELETE: '/invoices/sales/bulk-delete',
      CONTACT_INVOICE_AMOUNT_DUE: '/invoices/sales/contact-invoice-amountdue',
      RECORD_PAYMENT: '/invoices/sales/us/record-payment',
      DELETE_PAYMENT: (id: number) =>
        `/invoices/sales/us/delete-sale-tax-payment/${id}`,
      DEACTIVATE_RECURRING_INVOICE: (type: any, code: any) =>
        `recur/schedule?documentType=${type}&documentCode=${code}`,
      UPDATE_MEMO: '/invoices/sales/memo',
      ADD_PAYMENT_MILESTONE: `invoices/paymentmilestone`,
      UPDATE_PAYMENT_MILESTONE: (documentCode: string) =>
        `invoices/paymentmilestone/${documentCode}`,
      MILESTONE_SUMMARY: `invoices/paymentmilestone/report/tracker/summary`,
      MILESTONE_DETAILS: `invoices/paymentmilestone/report/tracker/details`,
      FETCH_PAYMENT_MILESTONES: `invoices/paymentmilestone/search`,
      DELETE_PAYMENT_MILESTONE: (documentCode: string) =>
        `invoices/paymentmilestone/delete/${documentCode}`,
      DELETE_OB_INVOICE: (id: number) => `/invoices/sales/${id}`,
      GET_BANK_FILE_INVOICES: 'invoices/sales/invoices-pending-for-bank-file',
      GET_BANK_FILE_BILLS: 'invoices/purchase/bills-pending-for-bank-file',
      GENERATE_BANK_FILE: 'exim/payment-bank-file/download',
      UNLINK_BANK_FILE_INVOICES: 'invoices/sales/unlink-bankfile',
      UNLINK_BANK_FILE_BILLS: 'invoices/purchase/unlink-bankfile'
    },
    SALES_ORDER: {
      FETCH_SALES_ORDERS: `/invoices/salesorder`,
      CREATE_SALES_ORDERS: `/invoices/salesorder`,
      UPDATE_SALES_ORDERS: `/invoices/salesorder`,
      DELETE_SALES_ORDER: (id: number, country: string) =>
        `/invoices/salesorder${country}/${id}`,
      DELETE_SALES_ORDER_BY_CODE: (code: string) =>
        `/invoices/salesorder?salesOrderCode=${code}`,
      BULK_DELETE: '/invoices/salesorder/bulk-delete',
      FETCH_SALES_ORDER_DETAILS: (soCode: string, country: string) =>
        `/invoices/salesorder${country}/code/${soCode}/details`,
      GET_SO_BY_PRODUCT_CODE: (
        productCode: string,
        excludeConveredWOtoSO: boolean
      ) =>
        `/invoices/salesorder/getbyproductcode/${productCode}?excludeConvertedFromWorkOrder=${excludeConveredWOtoSO}`,
      FETCH_CONSOLIDATED_PRODUCT_QUANTITY_BY_SO_CODES: `/invoices/salesorder/consolidated_product_quantity_by_so_codes`
    },
    REPORT: {
      FINANCIAL: {
        DPL_REPORT: '/reports/common/dplReport',
        DPL_REPORT_EXPORT: '/reports/common/dplReport/export',
        DPL_REPORT_EMAIl: '/reports/common/dplReport/email',
        EXPORT_TRAIAL_BALANCE: `/reports/financial/trial-balance/export/excel`,
        EXPORT_BANK_RECONCILIATION_REPORT: `/bankintegration/exportreconciliationsummary`,
        EXPORT_RECONCILIATION_LOG_REPORT: `/bankintegration/exportreconciliationlog`,
        CASHFLOW_MAP: `/account/reports/cash-flow/mapping`,
        CUSTOM_FIELD_BOM: `/dimension`,
        CONSOLIDATED_FINANCIAL_REPORT: (type: string) =>
          `reports/financial/account-auto-map?report=${type}`,
        POST_CONSOLIDATED_ACCOUNTS_MAPPINGS:
          '/reports/financial/consolidate-mapping',
        EXECUTE_CONSOLIDATED_REPORT: (type: string) =>
          `/reports/financial/consolidation/${type}`,
        CONSOLIDATE_REPORTS_WITH_TYPE: (type: string) =>
          `/reports/financial/consolidation/${type}`,
        FETCH_ALL_REPORTS: (type: string, multiCompanyReport: boolean) =>
          `reports/financial/consolidate-mapping?multicompanyReport=${multiCompanyReport}&report=${type}`,
        DELETE_CONSOLIDATED_REPORT: (id: string) =>
          `reports/financial/consolidate-mapping/${id}`,
        GET_INTER_COMPANY_JOURNAL_ENTRIES: `reports/financial/get-inter-company-journal-entries`,
        GET_INTER_COMPANY_JOURNAL_ENTRY_LINES: `reports/financial/get-journal-entry-lines`,
        SKIP_JE_FROM_ELIMINATION: `reports/financial/skip-je-from-elimination`,
        UNSKIP_JE_FROM_ELIMINATION: `reports/financial/unskip-je-from-elimination`,
        CONSOLIDATED_REPORTS_WITH_TYPE: (type: string) =>
          `/reports/financial/consolidated/${type}`,
        DELETE_MULTI_CONSOLIDATE_REPORT: (id: any) =>
          `reports/financial/consolidate-mapping/${id}`
      },
      TAX: {
        TAX_AGENCY: `tax-calc/tax-agency/codes`,
        SALES_TAX: `/invoices/sales/us/sale-tax-payment`,
        SALES_TAX_EXPORT: `/invoices/sales/us/sale-tax-payment-export`,
        GSTR_ONE: `/reports/in/form-one/export/data`,
        GSTR_THREE: `/reports/in/form-three/export/data`,
        B2B_DETAILS: `/reports/in/form-one/details/data`,
        GST_RETURN: `/reports/india-gst/get-gst-return-log`,
        OTP: `/reports/india-gst/otp-status`,
        GST_RETURN_FORM_ONE: `/reports/india-gst/form-one/details/data`,
        TDS_CUSTOMER: `/reports/in/tds-deducted`,
        FAILED_INVOICES: `/invoices/in/e-invoice/failure-log-report`,
        GST_THREE_DETAILS_EXPORT: `reports/in/form-three/details/export`,
        GST_THREE_DETAILS: `/reports/in/form-three/details/data`,
        TDS_DETAILS: `/reports/in/tds-detail-report`,
        TDS_NOT_DED: `/reports/in/tds-not-deducted-report`,
        POST_TDS_NOT_DED: `/reports/in/post-tds-je`,
        EMAIL_SOA: `/reports/financial/soa-email`,
        EMAIL_SOA_COUNT: `/reports/financial/soa-email-count`,
        VALIDATE_TDS: `/reports/in/validate-tds-payment-status`,
        EXPORT_TDS_DETAILS: `/reports/in/tds-report/export`,
        EXPORT_IRAS: `reports/sg/iras/xml`,
        EXPORT_TDS_NOT_DED: `/reports/in/tds-non-deducted-report/export`,
        EXPORT_BOM_ASSEMBLY: `/reports/product/bom-report-export`,
        EXPORT_GST3B_DETAILS: `/reports/product/bom-report-export`,
        EXPORT_STOCKWH: `/reports/common/stock-avail-export`,
        EXPORT_OUTSTANDING_PGR: `/reports/common/export-outstanding-purchase-grn`,
        EXPORT_GST3B: `/reports/in/form-three/export/excel`,
        EXPORT_CASHFLOW: `/reports/common/cashflow/export`,
        EXPORT_TDS_CUSTOMER: `/reports/in/tds-customer-report/export`,
        EXPORT_STOCK_VALUATION: `/report-engine/export/stock-valuation`,
        EXPORT_AGEING: `/report-engine/stock-reports/stock-ageing-report/export/xls`,
        EXPORT_BATCH_STATUS: `/reports/common/export-batch-status-report`,
        EXPORT_BATCH_SERIAL_LEDGER_REPORT: `/reports/common/export-batch-serial-ledger`,
        EXPORT_STOCKLEDGER: `/report-engine/stock-reports/stock-ledger-export`,
        EXPORT_CREDIT_NOTES: `/report-engine/export/credit-debit-notes`,
        EXPORT_SALES_BY_PROD: `/report-engine/export/product-sale-purchase`,
        EXPORT_PAYMENT_LIST: `/report-engine/export/make-receive-payment`,
        EXPORT_PURCHASE_BY_PROD: `/report-engine/export/product-sale-purchase`,
        EXPORT_PURCHASE_BY_CONTACT: `/report-engine/export/sale-purchase`,
        EXPORT_ORDER_LIST: `/report-engine/export/purchase-order`,
        EXPORT_BILL_LIST: `/report-engine/export/invoice`,
        EXPORT_LANDED_COST: `/reports/common/export-landed-cost-report`,
        EXPORT_RECEIPT_LIST: `/report-engine/export/make-receive-payment`,
        EXPORT_SALES_BY_CONTACT: `/report-engine/export/sale-purchase`,
        EXPORT_INVOICES: `/report-engine/export/invoice`,
        EXPORT_QUOTE: `/report-engine/export/quote`,
        EXPORT_SALES_RETURN: `/reports/return/sales/export`,
        EXPORT_SALES_RETURN_MY: `/reports/my/sales-tax-return/export`,
        EXPORT_SERVICE_RETURN_MY: `/reports/my/service-tax-return/export`,
        EXPORT_OUTSTANDING_SALES: `/reports/common/export-outstanding-sales-do`,
        EXPORT_PURCHASE_RETURN: `/reports/return/purchase/export`,
        EXPORT_GSTR1: `reports/in/form-one/export/excel`,
        EXPORT_GSTR1_E_FILLING: `reports/in/form_one_e_filing/export/excel`,
        EXPORT_GSTR1_DETAILS: `reports/in/form-one/details/export`,
        SALES_TAX_USA: `/invoices/sales/us/tax-report`,
        FULLFILLMENT: `/products/inventories/advanced-tracking/fulfillment/search`,
        GR: `/products/inventories/advanced-tracking/goods-received/search`,
        SAVE_GSTR: `/reports/india-gst/save-gstr1-data`,
        EXPORT_PNL: `/reports/common/export-grouping-profit-and-loss`,
        EXPORT_BALANCE_SHEET: `/reports/common/export-grouping-balance-sheet-v2`,
        EXPORT_GENERAL_LEDGER: `reports/common/export-general-ledger`,
        EXPORT_SOA: `reports/financial/soa-customer-export`,
        EXPORT_SOA_VENDOR: `reports/financial/soa-vendor-export`,
        SALES_TAX_MALAYSIA: `/reports/my/sales-tax-return`,
        SERVICE_TAX_MALAYSIA: `/reports/my/service-tax-return`,
        GENERATE_GSTR2B_OTP: `gstrecon/gstn/otprequest `,
        VERIFY_GSTR2B_OTP: `gstrecon/gstn/otpverify`,
        CHECK_GSTR2B_SESSION: `gstrecon/gstn/session`,
        UPLOAD_GSTR2B_FILE: `gstrecon/upload/2b`,
        DELETE_GSTR2B_FILE: `gstrecon/2b/`,
        GET_UPLOADED_GSTR2B_FILES: `gstrecon/2b/files`,
        GET_MATCH_SUMMARY: `gstrecon/2b/match_summary`,
        GSTR2B_ACTIONS: `gstrecon/2b/action`,
        GET_GSTR2B_FILE_DETAIL: (uuid: number) => `gstrecon/2b/${uuid}`,
        SALES_TAX_DETAIL_USA: `/invoices/sales/us/tax-detail-report`
      },
      ADDITIONAL: {
        PURCHASE: `/reports/common/additionalcharges/details`,
        EXPORT: `/reports/basic/additional/export`,
        VALUATION_BY_WAREHOUSE:
          '/reports/product-sale-purchase/stock-valuation-by-wh'
      },
      TALLY: {
        RECEIVABLE: `/reports/basic/receivables`,
        VENDOR_SOA: `reports/basic/soa-vendor-details`,
        CUSTOMER_SOA: `reports/basic/soa-details`,
        DAY_BOOK: `reports/basic/get-day-book-report`,
        STOCK_SUMMARY: `reports/basic/stock-movement-summary-report			`,
        RECEIPT_SUMMARY: `reports/basic/receipt-summary`,
        CASHFLOW: `reports/basic/cash-net-flow`,
        DETAILED_SALES: `reports/basic/detail-transaction`,
        TRANSACTION_TYPE: `reports/basic/get-day-book-document-type-for-filter`,
        DETAILED_PURCHASE_REPORT: `reports/basic/detail-transaction`,
        SALES_REGISTERED_SUMMARY_REPORT: 'reports/basic/sales-register-summary',
        SALES_REGISTERED_SUMMARY_DETAILED_REPORT:
          'reports/basic/sales-register-detailed',
        DOCS_EMAILED: `/notification/report/email-status`
      },

      PURCHASE: {
        GET_REPORT_DATA: `reports-builder/reports/execute`,
        AGED_PAYABLES_SUMMARY: `/reports/aged/payable/summary`,
        AGED_PAYABLES_DETAILS: `/reports/aged/payable/detail`,
        LANDED_COST_REPORT: `/reports/common/landedcost/details`,
        PURCHASE_ORDER_DETAIL_REPORT: `/reports/common/purchase-order-detail`,
        PURCHASE_ORDER_DETAIL_REPORT_EXPORT: `/reports/common/purchase-order-detail/export`,
        ORDER_LEVEL_REPORT: `/reports/common/product-order-level`
      },
      SALES: {
        AGED_RECEIVABLES_SUMMARY: `/reports/aged/receivable/summary`,
        AGED_RECEIVABLES_DETAILS: `/reports/aged/receivable/detail`,
        AGED_RECEIVABLES_EXPORT: `/reports/aged/receivable-payable/export`,
        SALES_BY_PRODUCT: `/reports/product-sale-purchase`,
        FULFILLMENT_REPORT: '/reports/common/fulfillment-summary-report',
        FULFILLMENT_REPORT_EXPORT: 'reports/common/fulfillment-report-export',
        FULFILLMENT_REPORT_DETAILS: '/reports/common/fulfillment-detail-report',
        RATE_ANALYSIS_REPORT: '/reports/rate-analysis/get-rate-analysis-report',
        GOODS_RECEIVED_NOTES: '/reports/common/goods-receipt-summary-report',
        GOODS_RECEIVED_NOTES_EXPORT:
          '/reports/common/goods-receipt-report-export',
        GOODS_RECEIVED_NOTES_DETAILS:
          '/reports/common/goods-receipt-detail-report'
      },
      INVENTORY: {
        PURCHASE_RETURN: `/reports/return/purchase`,
        SALES_RETURN: `/reports/return/sales`,
        STOCK_AGEING: `/report-engine/stock-reports/stock-ageing-report`,
        BOM_ASSEMBLY: `/reports/product/bom-report`,
        BATCH_STATUS: `/reports/common/batch-status-report`,
        BATCH_SEARIAL_LEDGER: `/report-engine/stock-reports/batch-stock-ledger`,
        STOCK_VALUATION: 'reports/product-sale-purchase/stock-valuation',
        RESERVED_STOCK: 'reports/common/reserve-stock-report/summary',
        RESERVED_STOCK_DETAILS: '/reports/common/reserve-stock-report/details',
        UNRESERVE: '/reports/common/unreserve-quantity',
        EXPORT_RESERVE_STOCK: `/reports/common/export-reserve-stock`,
        BOM_ASSEMBLY_DELETE: `/products/assemble/`
      },
      BUILDER: {
        GET_TABLE_DATA: 'reports-builder/data-source/report-tables',
        EXECUTE: 'reports-builder/reports/books/execute',
        REPORT_BUILDER: 'reports-builder/reports',
        EXPORT: 'reports-builder/reports/books/export',
        GET_ONE_DRIVE_FILES:
          'reports-builder/custom/get-file-list?appName=ONEDRIVE',
        SAVE_ONE_DRIVE_FILES: 'reports-builder/onedrive/save-file-meta',
        GET_DROPBOX_FILES:
          'reports-builder/custom/get-file-list?appName=DROPBOX',
        SAVE_DROPBOX_FILES: 'reports-builder/dropbox/save-file-meta',
        UPDATE_ONE_DRIVE_FILES_COLUMN_MAPPING: (id: number) =>
          `reports-builder/custom/update-column-mapping/${id}`
      },
      SST02REPORT: {
        GET_SST_TWO_REPORT: '/reports/my/sst-two-report'
      },
      CUSTOM: {
        FETCH_PRONTO_DASHBOARD: `reports-builder/reports/revenue-report`
      },
      COMMON: {
        GET_SKU_WISE_ORDER: `/reports/common/mrp/sku-wise-order`,
        SEND_EMAIL: '/reports/common/send-email',
        EXPORT_JC_SUMMARY: '/reports/common/export-job-card-time-tracker-report'
      }
    },
    CONTACTS: {
      FETCH_CONTACTS: `/contacts`,
      DELETE_CONTACT: (id: number, countryCode: string) =>
        `contacts${countryCode}/${id}`,
      UPDATE_CONTACT: (id: number, countryCode: string) =>
        `contacts${countryCode}/${id}`,
      FETCH_PAYABLE_ACCOUNT_INFO: `account/accountnature/Liability`,
      FETCH_RECEIVABLE_ACCOUNT_INFO: `/account/accountnature/Asset`,
      FETCH_PAYMENT_TERM_INFO: `/contacts/paymentTerms`,
      GET_CONTACT_OPENING_SUMMARY: `/reports/common/contact-opening-summary`,
      GET_CONTACT_OPENING_DETAILS: `/reports/common/contact-opening-details`,
      DELETE_PAYMENT_TERM_INFO: (id: number) =>
        `contacts/paymentTerms?id=${id}`,
      EDIT_PAYMENT_TERM_INFO: (id: number) => `contacts/paymentTerms?id=${id}`,
      CREATE_CONTACT: `contacts/`,
      FETCH_ACTIVE_DOCUMENT_SEQUENCE: `sequenceformat/fetchActive`,
      UPDATE_ADDRESS: (id: number) => `contacts/${id}`,
      FETCH_CONTACT_SUMMARY: `/contacts/summary`,
      CHANGE_CONTACT_STATUS: `contacts/codes/status?status=`,
      FETCH_BUSINESS_UNIT: 'contacts/businessunits',
      UPDATE_CONTACT_REMAINDER_STATUS: `invoices/reminder/contact`,
      BULK_STATUS_UPDATE: (countryCode: string) =>
        `contacts${countryCode}/codes/status`,
      BULK_CONTACT_DELETE: `contacts/bulk-delete`,
      UPDATE_FINANCE_SETTING: (id: number) => `/tenants/${id}/settings/finance`,
      GET_CONTACTS_BY_CODES: 'contacts/info'
    },
    CURRENCY: {
      FETCH_CURRENCY: `/multicurrency/getCurrencyEnum`,
      FETCH_CURRENCY_WITH_EXCHANGE_RATE: `multicurrency/getCurrencyConfigurations`,
      UPDATE_CURRENCY_SYNC_CONFIG: `multicurrency/updateCurrencySyncConfig`,
      UPDATE_CURRENCY_STATUS: `multicurrency/updateCurrencyStatus`,
      GET_CURRENCY_SYNC_CONFIG: `multicurrency/getCurrencySyncConfig`,
      GET_CURRENCY_HISTORY: `multicurrency/getCurrencyHistory`,
      UPDATE_EXCHANGE_RATE: `multicurrency/updateExchangeRate`,
      UPDATE_BULK_STATUS: `multicurrency/bulkUpdateCurrencyStatus`
    },
    COA: {
      FETCH_COA: `/account`,
      DELETE_COA: (id: number) => `account/${id}`,
      CREATE_COA_IND: `/account`,
      UPDATE_STATUS: `/account/activate`,
      BULK_STATUS_UPDATE: `/account/bulkactivate`,
      UNDEPOSITED: `/account/accountgroup/names`,
      POST_UDEPOSITED: `/account/undeposited-funds`,
      TYPE_COA: `/account/group`,
      UPDATE_COA: (id: number) => `account/${id}`,
      FETCH_MULTI_LEVEL_SUBTYPES: (code: any) =>
        `/account/group/subgroup/${code}`,
      SAVE_MULTI_LEVEL_SUBTYPE: `/account/group`,
      EDIT_MULTI_LEVEL_SUBTYPE: (id: any) => `/account/group/${id}`,
      DELTE_MULTI_LEVEL_SUBTYPE: (id: any, accountId: any) =>
        `/account/group/${id}/${accountId}`,
      FETCH_MULTU_LEVEL_PARENT_LIST: `/account/accountgroupandsubgroup`,
      IS_ACC_USED: `/account/isaccountused`,
      BUDGET_LIMIT_CHECK: '/account/budgeting/check-budget-limit-exceed',
      FETCH_MULTI_LEVEL_SUBTYPES_BY_ID: (id: number) =>
        `account/group/subgroupbytype/${id}`
    },
    COMMON: {
      FETCH_BOM_REPORT: `reports/common/bom-component-report`,
      EXPORT_BOM_REPORT: `reports/common/export-bom-component-report`
    },
    TAX: {
      UPDATE_TAX: (id: number) => `/taxes/${id}`,
      DELETE_TAX: `/taxes/`,
      CALCULATE_US_TAX: '/tax-calc/tax/calculate',
      CREATE_TAX: `/taxes/create-tax`,
      TAX: `/taxes`
    },
    PRICE_LIST: {
      FETCH_PRICE_LIST: `products/price-list/search`,
      EXPORT_PRICE_LIST: `/reports/common/price-list/export`,
      CREATE_PRICE_LIST: `products/price-list`,
      ASSIGN_PRICE_LIST: (id: number) =>
        `products/price-list/assign-price-list/${id}`,
      UPDATE_PL_PERCENT: `/products/price-list/change`,
      SET_ACTIVE: (id: number) => `products/price-list/active/${id}`,
      FETCH_PRODUCTS: `products/in`,
      FETCH_PRODUCTS_UNIT_PRICES: 'reports/document/productsUnitPrices',
      PRICING: 'products/price-list/document-prices',
      PRICE_LIST_BY_ID: `/products/price-list/ids`,
      PURCHASE_SALES_PRICE_LIST: (contactCode: string) =>
        `products/price-list/purchase-sales-price-list/${contactCode}`,
      FETCH_PRODUCTS_BY_ID: `products/details`
    },
    PRICE_BOOK: {
      CREATE: `products/price-list`,
      GET_ALL: 'products/price-list/search',
      FETCH_PRODUCTS_BY_ID: `products/details`,
      SET_ACTIVE: (id: number) => `products/price-list/active/${id}`,
      EXPORT_PRICE_BOOK: `reports/common/price-list/export`,
      GET_VERSIONS: (priceBookId: any) =>
        `products/price-list/version/${priceBookId}`,
      CREATE_VERSION: `products/price-list/version`
    },
    DEBIT_NOTE: {
      FETCH_DEBIT_NOTE: `/notes/debit`,
      CREATE_DEBIT_NOTE: `/notes/debit`,
      UPDATE_DEBIT_NOTE: (id: number) => `notes/debit/${id}`,
      DELETE_DEBIT_NOTE: (id: number) => `notes/debit/${id}`
    },
    QUOTES: {
      FETCH_QUOTES: (country: string) => `quotations${country}`,
      CREATE_QUOTE: (country: string) => `quotations${country}`,
      UPDATE_QUOTE: (id: number, country: string) =>
        `quotations${country}/${id}`,
      UPDATE_QUOTE_BY_KEYS: (id: number) => `quotations/${id}`,
      FETCH_QUOTE_BY_CODE: (code: string) => `quotations/code/${code}/details`,
      DELETE_QUOTES: (id: number, countryCode: string) =>
        `quotations${countryCode}/${id}`,
      BULK_DELETE: 'quotations/bulk-delete'
    },
    BILLS: {
      FETCH_BILLS: `invoices/purchase`,
      DELETE_BILL: (id: number, country: string) =>
        `invoices/purchase${country ? country : ''}/${id}`,
      CREATE_BILL: `invoices/purchase`,
      CREATE_OPENINGBILL: `invoices/purchase`,
      FETCH_BILL_DETAILS: (billCode: string, country: string) =>
        `invoices/purchase${country ? country : ''}/code/${billCode}/details`,
      UPDATE_BILL: `invoices/purchase`,
      BULK_DELETE: '/invoices/purchase/bulk-delete',
      BILLS_SUMMARY: '/reports/document/purchase/summary',
      DELETE_OB_BILL: (id: number) => `invoices/purchase/${id}`,
      PEPPOL_INVOICE_RESPONSE: `invoices/purchase/sg-peppol-inv-response`
    },
    PICK_PACK_SHIP: {
      ALL: `reports/document/sales/details`,
      PICKING_LIST: `products/inventories/pick-list`,
      SHIPING_LIST: `products/inventories/ship-list`,
      GENERAL_PICK_PACK_LIST: `products/inventories/pick-list/sales-orders`,
      POST_GENERAL_PICK_PACK_LIST: `products/inventories/pick-list`,
      PACK_DETAILS: 'products/inventories/ship-list',
      FETCH_PICKLIST: (query: string) =>
        `products/inventories/pick-list?${query}`,
      UPDATE_PICKLIST: (code: any) => `products/inventories/pick-list/${code}`,
      FETCH_SHIPPING_LIST: `products/inventories/ship-list`,
      VOID_SHIPPING_LIST: (list: any) => `products/inventories/${list}/void`,
      GO_ACTION: `inventories/documents/pick-pack-ship`,
      PRINT_LABELS: `products/inventories/ship-list/print`,
      INVOICE_SHIP_LIST: (documentCode: string, documentType: string) =>
        `products/inventories/ship-list/allShipRecords/documentCode/${documentCode}?documentType=${documentType}`,
      CHECK_PPS_DOCUMENT: `products/inventories/documents/pickPackShip/ppsflow`
    },

    ACCOUNTS: {
      FETCH_ACCOUNTS: `account`,
      ADD_OPENING_BALANCE: `/add-opening-balance`,
      HSN: `products/in/hsn`,
      SAC: `products/in/sac`,
      NATURE_OF_INCOME_PAYMENT: `products/in/tds-nature-of-payment`,
      TAXES: `taxes?query=status=ACTIVE`,
      UOM: `products/uom/active`,
      WAREHOUSES: `products/inventories/warehouses/search`,
      UOM_SCHEMES: `products/uom-schema/active/base?search=`,
      FETCH_ACCOUNT_GROUPS: (currencyCode: string) =>
        `account/accountgroup/names?currencyCode=${currencyCode}`,
      FETCH_ACCOUNT_NATURE: `account/accountnature/Equity`,
      DELETE_ACCOUNT: (id: string) => `account/${id}`,
      BUDGETING: `account/budgeting`,
      FETCH_BY_CODES: 'account/codes',
      FETCH_HSN_SAC_ALL: 'products/in/all-hsn-sac',
      BULK_BUDGETING: 'account/budgeting/bulk-create',
      ASSIGN_USERS_TO_BUDGET: (budgetid: string) =>
        `account/budgeting/assignusers/${budgetid}`,
      FETCH_BY_NATURE: `account/accNature`,
      DEFAULT_ACCOUNTS: '/account/default-accounts',
      ACCOUNT_TENANT: 'account/tenants'
    },
    JOURNAL_ENTRY: {
      FETCH_JOURNAL_ENTRY: `/journal`,
      SAVE_JOURNAL_BANK_TRANSFER: '/journal_bank_transfer',
      FETCH_JOURNAL_ENTRY_BY_PERSPECTIVE: `/journal/by-perspective`,
      REALTIME_EXCHANGE_RATE: '/journal/revaluation/get-exchange-rates',
      PAYMENT_FF_RG_JE: 'reports/common/linked-documents'
    },
    DEPOSIT: {
      FETCH_DEPOSITS: `payments/receive/deposits`,
      UPDATE_DEPOSIT: `payments/receive/taxgroupdeposit`,
      DELETE_BANK_DEPOSIT: (params: string) => `payments/receive/${params}`,
      DELETE_DEPOSIT: (id: string) => `payments/receive/${id}`,
      NEW_DELETE_DEPOSIT: (paymentCode: string) =>
        `payments/receive/payment?paymentCode=${paymentCode}`,
      DELETE_DEPOSIT_IN: (id: string) => `payments/receive/in/${id}`,
      FETCH_BANK_ACCOUNT_LIST: `account/accountgroup/names`,
      FETCH_UNDEPOSITS: `payments/receive/undeposits`,
      FETCH_UNDEPOSITS_PAYMENTS: `payments/receive/undeposits-payments`,
      CREATE_BANK_DEPOSIT: `payments/receive/deposit`,
      EXPORT_DEPOSIT: 'reports/common/deposit/export',
      FETCH_LINKED_UNDEPOSITS_PAYMENTS: `payments/receive/linked-undeposits-payments`,
      SAVE_IMPORT_MAPPINGS: `payments/import-map/save`,
      FETCH_IMPORT_MAPPINGS: `payments/import-map`
    },
    EXPENSE: {
      FETCH_EXPENSE: `payments/make/expenses`,
      UPDATE_EXPENSE: `payments/make/expensetaxgroup`,
      DELETE_EXPENSE: (id: string) => `payments/make/${id}`,
      NEW_DELETE_EXPENSE: (paymentCode: string) =>
        `payments/make/payment?paymentCode=${paymentCode}`,
      EXPORT_EXPENSE: 'reports/common/expense/export',
      EXPORT_CHECK_GRID: 'reports/common/expense/check/export'
    },
    FYCLOSING: {
      FETCH_FYCLOSING: `reports/fyclosinglog`,
      FETCH_FYCLOSING_JE_DATA: `reports/jedata`,
      UPDATE_SETTINGS: `reports/updateclosingsettings`,
      GET_SETTINGS: `reports/closingsettings`
    },
    CREDIT_NOTES: {
      FETCH_CREDIT_NOTES: `notes/credit`,
      CREATE_CREDIT_NOTE: `/notes/credit`,
      UPDATE_CREDIT_NOTES: (id: number) => `notes/credit/${id}`,
      DELETE_CREDIT_NOTE: (id: number) => `notes/credit/${id}`
    },
    PAYMENT: {
      CUSTOM_CHECK_EXPORT: `reports/cheque/custom/export`,
      MAKE_PAYMENT: `payments/make`,
      CREATE_OCN: `/notes/credit`,
      CREATE_ODN: `/notes/debit`,
      RECEIVE_PAYMENT: `payments/receive`,
      FETCH_DEBIT_INFO: `notes/debit`,
      FETCH_CREDIT_INFO: `notes/credit`,
      FETCH_ADVANCED_PAYMENT_INFO: (countryCode = '') =>
        `payments/receive${countryCode}/deposits`,
      FETCH_PREPAYMENT_INFO: `payments/make/expenses`,
      PAY_DEBIT_NOTE: `notes/debit/knock-off`,
      PAY_ADVANCED_PAYMENT: `payments/receive/knock-off`,
      PAY_CREDIT_NOTE: `notes/credit/knock-off`,
      PAY_PRE_PAYMENT: `payments/make/knock-off`,
      PP_DETAILS: (id: any) => `payments/make/expense/code/${id}/details`,
      AP_DETAILS: (id: any) => `payments/receive/deposit/code/${id}/details`,
      CN_DETAILS: (id: any) => `/notes/credit/code/${id}/details`,
      DN_DETAILS: (id: any) => `/notes/debit/code/${id}/details`,
      DELETE_DEBIT_NOTE: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `invoices/purchase/notes?code=${paymentCode}&invoicecode=${documentCode}&uid=${uid}`,
      DELETE_ADVANCED_PAYMENT: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `invoices/sales/receive?paymentCode=${paymentCode}&salesInvoiceCode=${documentCode}&uid=${uid}`,
      NEW_DELETE_ADVANCED_PAYMENT: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `payments/receive/payment?paymentCode=${paymentCode}&salesInvoiceCode=${documentCode}&uid=${uid}`,
      DELETE_CREDIT_NOTE: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `invoices/sales/notes?code=${paymentCode}&invoicecode=${documentCode}&uid=${uid}`,
      DELETE_PRE_PAYMENT: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `invoices/purchase/payment?paymentCode=${paymentCode}&purchaseInvoiceCode=${documentCode}&uid=${uid}`,
      NEW_DELETE_PRE_PAYMENT: (
        paymentCode: string,
        documentCode: string,
        uid: string
      ) =>
        `payments/make/payment?paymentCode=${paymentCode}&purchaseInvoiceCode=${documentCode}&uid=${uid}`,
      DELETE_OFFSET_INVOICE: (paymentCode: string, documentCode: string) =>
        `invoices/sales/contrainvoice?contraCode=${paymentCode}&purchaseInvoiceCode=${documentCode}`,
      DELETE_OFFSET_BILL: (paymentCode: string, documentCode: string) =>
        `invoices/purchase/contrabill?contraCode=${paymentCode}&salesInvoiceCode=${documentCode}`,
      DELETE_INVOICE_PAYMENT_RECORD: (paymentCode: any, documentCode: any) =>
        `invoices/sales/receive?paymentCode=${paymentCode}&salesInvoiceCode=${documentCode}`,
      NEW_DELETE_INVOICE_PAYMENT_RECORD: (
        paymentCode: any,
        documentCode: any,
        countryCode: string
      ) =>
        `payments/receive${countryCode}/payment?paymentCode=${paymentCode}&salesInvoiceCode=${documentCode}`,
      DELETE_BILL_PAYMENT_RECORD: (paymentCode: any, documentCode: any) =>
        `invoices/purchase/payment?paymentCode=${paymentCode}&purchaseInvoiceCode=${documentCode}`,
      NEW_DELETE_BILL_PAYMENT_RECORD: (paymentCode: any, documentCode: any) =>
        `payments/make/payment?paymentCode=${paymentCode}&purchaseInvoiceCode=${documentCode}`,
      OFFSET_WITH_BILLS: `invoices/sales/contrainvoice`,
      OFFSET_WITH_INVOICES: `invoices/purchase/contrabill`,
      BULK_RECEIVE_PAYMENT: `payments/receive/bulk-pay`,
      BULK_MAKE_PAYMENT: `payments/make/bulk-pay`,
      IL_BULK_MAKE_PAYMENT: `payments/make/il/bulk-pay`,
      IL_BULK_RECEIVE_PAYMENT: `payments/receive/il/bulk-pay`,
      EMAIL_PAYMENT: `payments/journal/receive-payment-receipt/email`,
      EMAIL_PAYMENT_RECORD: `template/sendemail`
    },
    FULFILLMENT: {
      // SAVE_FULFILLMENT: `/sales/fulfillments`,
      SAVE_FULFILLMENT: `/products/fulfillment`,
      // SAVE_BULK_FULFILLMENT: `/sales/fulfillments/bulk`,
      SAVE_BULK_FULFILLMENT: `/products/fulfillment/bulk`,
      // GET_FULFILLMENT_RECORDS: (documentCode: any) =>
      //   `/sales/${documentCode}/fulfillments`,
      GET_FULFILLMENT_RECORDS: (documentCode: any) =>
        `/products/fulfillment/allRecords/documentCode/${documentCode}`,
      GET_PPS_FULFILLMENT_RECORDS: () => `/products/inventories/ship-list`,
      GET_INVOICE_BULK_FULFILLMENT_DETAILS: `/invoices/sales/bulk-details`,
      GET_QUOTE_BULK_FULFILLMENT_DETAILS: `/quotations/bulk-details`,
      DELETE_INVOICE_FULFILLMENT: (
        fulfillmentCode: any,
        salesInvoiceCode: any,
        isBulk: boolean
      ) =>
        `/invoices/sales/fulfillments?fulfillmentCode=${fulfillmentCode}&salesInvoiceCode=${salesInvoiceCode}&isBulkDelete=${isBulk}`,
      DELETE_QUOTE_FULFILLMENT: (
        fulfillmentCode: any,
        quotationCode: any,
        documentSequenceCode: any,
        isBulk: boolean
      ) =>
        `/products/fulfillment/quotation?fulfillmentCode=${fulfillmentCode}&quotationCode=${quotationCode}&documentSequenceCode=${documentSequenceCode}&isBulkDelete=${isBulk}`,
      DELETE_SALES_ORDER_FULFILLMENT: (
        fulfillmentCode: any,
        salesOrderCode: any,
        documentSequenceCode: any,
        isBulk: boolean
      ) =>
        `/products/fulfillment/salesorder?fulfillmentCode=${fulfillmentCode}&salesOrderCode=${salesOrderCode}&documentSequenceCode=${documentSequenceCode}&isConvertedFromQuotation=false&isBulkDelete=${isBulk}`,
      DELETE_SALES_ORDER_PENDING_FULFILLMENT: (fulfillmentCode: any) =>
        `/products/fulfillment/sales-order/pending-approval/${fulfillmentCode}`,
      GET_ALL_FULFILLMENTS: `/products/fulfillment`,
      GET_FF_TOLERANCE_REPORT: `reports/common/tolerance-fulfillment/summary-report`,
      GET_ALL_PENDING_FULFILLMENTS: `/products/fulfillment/pending/search`,
      APPROVE_REJECT: `/products/fulfillment/approval-status`
    },
    RECEIVED_GOODS: {
      SAVE_RECEIVED_GOODS: `/products/goodsreceipt`,
      // SAVE_RECEIVED_GOODS: `/orders/purchase/receipt`,
      // SAVE_BULK_RECEIVED_GOODS: `/orders/purchase/receipt/bulk`,
      SAVE_BULK_RECEIVED_GOODS: `/products/goodsreceipt/receipt/bulk`,
      // GET_RECEIVE_GOODS_RECORD: (documentCode: string, documentType: string) =>
      //   `orders/purchase/goodsreceipt?documentCode=${documentCode}&documentType=${documentType}`,
      GET_RECEIVE_GOODS_RECORD: (documentCode: string, documentType: string) =>
        `orders/purchase/receipt?documentCode=${documentCode}&documentType=${documentType}`,
      GET_RECEIVE_GOODS_RECORD_JWO: (
        documentCode: string,
        documentType: string
      ) =>
        `/products/goodsreceipt/documentCode/${documentCode}?documentType=${documentType}`,
      GET_ORDER_BULK_RECEIVED_GOODS_DETAILS: `/orders/purchase/bulk-details`,
      GET_BILL_BULK_RECEIVED_GOODS_DETAILS: `/invoices/purchase/bulk-details`,
      DELETE_PENDING_RECEIVED_GOODS: (id: any) =>
        `/products/goodsreceipt/pending-approval/${id}`,
      DELETE_BILL_RG: (rgCode: any, billCode: any, isBulk: boolean) =>
        `/invoices/purchase/purchase/receipt?goodsReceiptCode=${rgCode}&purchaseInvoiceCode=${billCode}&isBulkDeleted=${isBulk}`,
      DELETE_ORDER_RG: (rgCode: any, poCode: any, isBulk: boolean) =>
        `/products/goodsreceipt/receipt/order?goodsReceiptCode=${rgCode}&purchaseOrderCode=${poCode}&isBulkDeleted=${isBulk}`,
      DELETE_JWO_RG: (jwoCode: any) =>
        `/products/goodsreceipt/receipt/${jwoCode}`
    },
    CUSTOM_NUMBER_FORMAT: {
      SAVE: `/sequenceformat/save`,
      ALTER: `/sequenceformat/toggleIsDefault`,
      GET_FORMAT: `/sequenceformat/fetchActive`,
      GET_FORMAT_ALL: `/sequenceformat/fetchAllActiveForModules`,
      DOES_EXIST: `/documentcode/doesExist`,
      TOGGLE_DEFAULT: `/sequenceformat/toggleIsDefault`,
      DELETE: `/sequenceformat/delete`,
      DELETE_CUSTOM: (id: number) => `/sequenceformat/delete/${id}`,
      UPDATE_SEPARATE_SEQUENCE_FORMAT: `/tenants`
    },
    PRINT: {
      GET_DESIGN_TEMPLATE_LIST: (category: any, moduleName: any) =>
        `template/document/${moduleName}/${category}`,
      DEFAULT_MAPPING: `template/document/default-mapping`,
      PRINTINFO: `template/print-info`,
      PRINT_RECORDS: `/template/template-print-info`,
      PRINT_CUSTOM_TEMPLATE_BY_UUID: (uuid: string) =>
        `template/print-info/print-design-doc-by-uid/${uuid}`,
      PRINT_CUSTOM_THERMAL_TEMPLATE_BY_UUID: (uuid: string) =>
        `template/print-info/print-doc-base64-by-uid/${uuid}&papersize=three_five`,
      BULK_PRINT_CUSTOM_TEMPLATE_BY_UUID: (uuid: string) =>
        `template/print-info/print-design-doc-by-bulk-uid/?uid=${uuid}`,
      TEMPLATE_PRINT_INFO: `template/template-print-info`,
      TEMPLATE_BULK_PRINT_INFO: `template/bulk/template-print-info`,
      GET_TEMPLATE_LIST: (moduleName: any, category: any) =>
        `template?moduleName=${moduleName}&category=${category}`,
      EXPORT_DOCUMENT_TEMPLATE: `template/exportDocument`,
      SOA_PRINT_TEMPLATE_EMAIL: `template/soa-print-email`,
      REPORT_PRINT_TEMPLATE_EMAIL: `report-engine/report-print-email`,
      SET_DEFAULT_TEMPLATE: `template/document/set-default`,
      SET_CONTACT_TEMPLATE: `template/document/set-contact-default-template`,
      TEMPLATE_DOCUMENT: `template/document/`,
      PRINT_TEMPLATE: `template/print`,
      UPLOAD_WORD_TEMPLATE: `template/initiate`,
      ADD_WORD_TEMPALTE: 'template/add',
      UPDATE_WORD_TEMPALTE: 'template/edit',
      DOWNLOAD_WORD_TEMPALTE: (id: number, category: any, moduleName: any) =>
        `template/download?category=${category}&moduleName=${moduleName}&id=${id}`,
      SET_AS_DEFAULT_TEMPLATE: (id: number) => `template/set-default/${id}`,
      TEMPLATE_WITH_ID: (id: number) => `template/${id}`,
      PREVIEW_TEMPLATE: (id: number, category: any, moduleName: any) =>
        `template/preview?category=${category}&moduleName=${moduleName}&id=${id}`
    },
    REPORTS: {
      PRODUCT_SUPPLIER: `reports/product/supplier`,
      PRODUCT_SALES_PURCHASE: `reports/product/sales-purchase`,
      PRODUCT_CUSTOMER: `reports/product/customer`,
      PRODUCT_STOCK_AGEING: `reports/product/stock-ageing`,
      PRODUCT_STOCK_AVAILABILITY: `reports/product/stock-availability`,
      PRODUCT_SALES_GROWTH: `reports/product/salesgrowth`,
      PRODUCT_PURCHASE: `reports/product/purchase`,
      PRODUCT_SALES: `reports/product/sales`,
      PRODUCT_INVOICE: `reports/product/invoice`,
      UNRESERVE_STOCK: `/reports/common/unreserve-quantities`
    },
    BANKS: {
      SERVICE_PROVIDER: 'bankintegration/serviceProvider?countryCode=',
      FETCH_BANKS: `account/bankaccounts`,
      FETCH_BANKS_DASHBOARD: `account/bankaccounts-dashboard`,
      FETCH_BALANCE_SUMMARY: `bankintegration/bank-balance-summary`,
      FETCH_BANK_RULES: `bankintegration/rules`,
      CREATE_BANK_RULE: 'bankintegration/rules',
      PREVIEW_BANK_RULE: `bankintegration/rules/preview`,
      FETCH_BANK_TRANSACTION: `bankintegration/transactions/all`,
      FETCH_BANK_TRANSACTIONS_BY_STATEMENT: `bankintegration/importedstatements/all`,
      DOCUMENTS: `bankintegration/bankdocuments`,
      BANK_CONNECT: 'bankintegration/connect',
      SOCKET_NAME: `ws/bankintegration/register-ws`,
      BANK_CALLBACK: '/bankcallback/connectsession',
      PLAID_DATA: 'bankintegration/plaid/connect',
      SOURCE_DOWNLOAD: (path: string) => `file/upload?relativeFilePath=${path}`,
      CONNECT_PLAID: (query: string) =>
        `bankintegration/plaid/get/transactions?${query}`,
      GET_BANK_ACCOUNTS: 'bankintegration/saltedge/unlinkedaccount',
      GET_PLAID_ACCOUNTS: (query: string) =>
        `bankintegration/plaid/get/accounts?limit=100&page=0&${query}`,
      GET_PLAID_ACCESS_TOKEN: 'bankintegration/plaid/get/access-token',
      CONNECT_SALTEDGE_ACCOUNT: 'bankintegration/saveconnection',
      DISCONNECT_BANK_ACCOUNNT: 'bankintegration/removeLogin',
      RECONNECT_BANK: 'bankintegration/reconnect',
      SYNC_TRANSACTIONS_PLAID: (id: string) =>
        `bankintegration/plaid/sync/transactions?deskeraAccountId=${id}`,
      UNRECONCILED_SUMMARY: (query: any) =>
        `bankintegration/bankreconcile/unreconciled/summary?${query}`,
      TRANSACTIONS_RECONCILIATION: (query: string) =>
        `bankintegration/bankreconcile/unreconciled/details/${query}`,
      MATCH_TRANSACTION: 'bankintegration/matchBankTransaction',
      MATCH_TRANSACTION_ID: 'bankintegration/matchBankTransactionById/bulk',
      UNMATCH_TRANSACTION_BY_ID:
        'bankintegration/unmatchBankTransactionById/bulk',
      UNMATCH_TRANSACTION: 'bankintegration/unmatchBankTransaction',
      GET_RULE_DETAILS: (transactionId: string) =>
        `bankintegration/rules/applicable?transactionid=${transactionId}`,
      PAYMENT_RECEIVE_DEPOSIT_GROUP: 'payments/receive/taxgroupdeposit',
      PAYMENT_MAKE_EXPENSE_GROUP: 'payments/make/expensetaxgroup',
      RECONCILE: 'bankintegration/bankreconcile/reconcile',
      UNRECONCIL: 'bankintegration/bankreconcile/unreconcile',
      UPDATE_STATUS: `/account/activate`,
      DELETE_RULE: `bankintegration/rules/bulk/delete`,
      BANK_TRANSACTION_SUMMARY: (query: any) =>
        `bankintegration/transactionsummary?${query}`,
      UPDATE_RULE_STATUS: `bankintegration/rules/bulk/status`,
      DK_BULK_DELTE: `bankintegration/bankdocuments/bulk-delete`,
      BANK_RECEIVE_PAYMENT: `payments/receive/in`,
      CONTACT_US: 'template/contact-us/sendemail',
      BULK_TRANSACTION_DELETE: `bankintegration/updatestatus/archive?archiveFlag=true`
    },
    EMAIL: {
      SMTP_SETTINGS: `notification/smtp`,
      GET_CUSTOM_TEMPLATE: (category: any, moduleName: any) =>
        `template/mail-template-download?category=${category}&id=&moduleName=${moduleName}`,
      ADD_CUSTOM_TEMPLATE: 'template/add-mail-template',
      SEND_EMAIL_SALE: 'invoices/email',
      SEND_EMAIL_PURCHASE: 'orders/purchase/email',
      SEND_EMAIL_SO: 'invoices/salesorder/email',
      SEND_EMAIL_RFQ: '/orders/requestforquotation/email',
      SMTP_SETTINGS_TEST_MAIL: `notification/smtp/testmail`
    },
    SALES_ORDER_DETAILS: {
      SO_DETAILS: 'reports/common/so-details'
    },
    GENERIC_ENDPOINT: {
      SAVE_RECURRING_DOCUMENT: `/recur/schedule`,
      SAVE_NATURE_INCOME_PAYMENT: `/products/in/tds-nature-of-payment`,
      SAVE_SAC_CODE: `/products/in/sac`,
      SAVE_HSN_CODE: `/products/in/hsn`,
      UPDATE_HSN_CODE: (id: number) => `/products/in/hsn/${id}`,
      UPDATE_SAC_CODE: (id: number) => `/products/in/sac/${id}`,
      DELETE_HSN_CODE: (id: number) => `products/in/hsn/${id}`,
      DELETE_SAC_CODE: (id: number) => `products/in/sac/${id}`,
      SCHEDULE_DETAILS: `recur/schedule-details`
    },
    INDIA_TDS: {
      NATURE_OF_INCOME: `/products/in/tds-nature-of-payment`,
      DEDUCTEE_TYPE: `/contacts/in/tds-deductee`,
      TDS_RATES: `/contacts/in/tds-rate`,
      ACCOUNT_CODES: `/account/accountcodes`,
      TDS_RATES_DETAIL: `/contacts/in/tds-rate-detail`
      //  NATURE_OF_INCOME :`${MODULE_URLS.PRODUCT}/in/tds-nature-of-payment`
    },
    TCS_SETTINGS: {
      GET_ALL_TCS: (tenanatId: number) => `/tenants/${tenanatId}/settings/tcs`,
      UPDATE_TCS_RATE: (tenanatId: number, id: any) =>
        `/tenants/${tenanatId}/settings/tcs/${id}`,
      UPDATE_TCS_STATUS: (tenanatId: number, id: any) =>
        `/tenants/${tenanatId}/settings/tcs/${id}`,
      GET_ALL_NATURE: (tenanatId: number) =>
        `/tenants/${tenanatId}/settings/tcs/nature`,
      GET_ALL_RECEIVABLE_PAYABLE: ``
    },
    EXIM: {
      EXPORT: `exim/export`,
      EXPORT_BANK: `exim/bankdocuments/export`
    },
    REPORTS_URLS: {
      VIEW_OPTIONS: (type: FINANCIAL_REPORT | NON_FINANCIAL_REPORT) =>
        `/reports/view?financialReport=${type}`,
      CUSTOM_FIELDS_BY_MODULES: '/dimension/custom-fields-by-modules',
      DELETE_VIEW: (viewId: number) => `/reports/view/${viewId}`,
      PNL: '/reports/financial/pnl-v4',
      PNL_V6: '/reports/financial/pnl-v6',
      PNL_V6_BY_DEP: '/reports/financial/pnl-v6-dept',
      JOB_CARD_SUMMARY: '/reports/common/job-card-time-tracker-summary',
      JOB_CARD_DETAIL: '/reports/common/job-card-time-tracker-detail',
      PNL_IN_BOTH_CURRENCY: '/reports/financial/pnl-v2-both-currency',
      CUSTOMIZED_REPORT: `/reports/custom-view`,
      OSFR: 'reports/common/outstanding-sales-do-summary',
      OSFR_ITEMS: 'reports/common/outstanding-sales-do-detail',
      BALANCE_SHEET: '/reports/financial/balance-sheet-v4',
      BALANCE_SHEET_V6: '/reports/financial/balance-sheet-v6',
      BALANCE_SHEET_IN_BOTH_CURRENCY:
        '/reports/financial/balance-sheet-v2-both-currency',
      TRIAL_BALANCE: '/reports/financial/trial-balance',
      TRIAL_BALANCE_BY_DEPARTMENT:
        '/reports/financial/trial-balance-by-department',
      EXPORT_TRAIAL_BALANCE_BY_DEPARTMENT: `/reports/financial/trial-balance-by-department/export/excel`,
      CONTACT_LIST: '/reports/financial/contactlist',
      CASHFLOW: '/account/reports/cash-flow',
      DETAILS: '/reports/financial/journal_entry',
      DETAILS_GL: '/reports/financial/consolidation/journal_entry',
      DETAILS_CASH: '/reports/financial/gl',
      CASHFLOW_MAPPING: '/account/reports/cash-flow/mapping',
      GENERAL_LEDGER: '/reports/financial/gl',
      CONSOLIDATE_CF_MAPPING: '/reports/financial/consolidate-cf-mapping',
      EXPORT_CONSOLIDATED_GL_REPORT:
        '/reports/common/export-consolidated-gl-report',
      CONSOLIDATE_CF_AUTO_MAP: '/reports/financial/custom-field-auto-map',
      JE: '/reports/financial/journal_entry',
      RECONCILIATION_SUMMARY: '/bankintegration/reconciliationsummary',
      RECONCILIATION_LOG: '/bankintegration/reconciliationlog',
      GST_RETURN_DATA: '/reports/sg/gstreturndata',
      DELETE_GST_RETURN_DATA: (id: number) => `/reports/sg/gstreturn/${id}`,
      GST_LOG_REPORT: '/reports/sg/gstreturnreport',
      IRAS_STATUS: '/reports/sg/iras/status',
      SUBMIT_TO_IRAS: (id: number) => `/reports/sg/iras/submit/${id}`,
      TRANSACTION_DETAIL_UNSAVEDREPORT: (id: number) =>
        `/reports/sg/gstreturntransactiondetails/unsavedreport/${id}`,
      TRANSACTION_DETAIL_REPORT: (id: number) =>
        `/reports/sg/gstreturntransactiondetails/${id}`,
      UPDATE_GST_RETURN_STATUS: (id: number, status: string) =>
        `/reports/sg/gstreturn/${id}/${status}`,
      GST_RETURN_SETTINGS: '/reports/sg/gstreturnsettings',
      SAVE_GST_RETURN: `/reports/sg/gstreturn`,
      SAVE_GST_RETURN_FORM7: `/reports/sg/gstreturn/form7`,
      UPDATE_MAPPING: `/account/reports/cash-flow/mapping`,
      UPDATE_GST_RETURN: (id: number) => `/reports/sg/gstreturn/${id}`,
      EXPORT_GST_RETURN: `/reports/sg/gstreturntransaction/export/xls`,
      UPDATE_GST_FILING: (id: number) =>
        `/reports/india-gst/update-gst-return-log/${id}`,
      UPDATE_GST_RETURN_FORM7: (id: number) =>
        `/reports/sg/gstreturn/form7/${id}`,
      OPGR: 'reports/common/outstanding-purchase-grn-summary',
      OPGR_ITEMS: 'reports/common/outstanding-purchase-grn-detail',
      STOCK_WAREHOUSE: 'reports/common/stock-availability-wh',
      STOCK_WAREHOUSE_DETAIL: 'reports/common/stock-availability-wh/detail',
      SOA_CUSTOMER: 'reports/financial/soa-customer',
      SOA_VENDOR: 'reports/financial/soa-vendor',
      SOA_CUSTOMER_GROUP_BY_CURRENCY:
        'reports/financial/soa-customer-group-by-currency',
      SOA_VENDOR_GROUP_BY_CURRENCY:
        'reports/financial/soa-vendor-group-by-currency',
      STOCK_LEDGER: '/report-engine/stock-reports/stock-ledger',
      STOCK_STATUS_SUMMARY: '/reports/common/stock-status-report-summary',
      STOCK_STATUS_DETAILS: '/reports/common/stock-status-report-details',
      STOCK_STATUS_EXPORT: '/reports/common/export-stock-status-report-details',
      EXPORT: `reports/common/note`,
      TRIAL_BALANCE_DETAIL: '/reports/financial/grouping-trial-balance',
      TRIAL_BALANCE_DETAIL_EXPORT:
        '/reports/financial/grouping-trial-balance/export/excel',
      DATE_EXPORT: '/reports/data-export',
      REPORT_PDF_EXPORT: {
        PNL: '/reports/data-export/pnl-v6/pdf-export',
        BALANCE_SHEET: '/reports/data-export/balance-sheet-v6/pdf-export',
        PNL_BY_DEPT: '/reports/data-export/pnl-v6-dept/pdf-export'
      },
      MRP_REPORTS: {
        PRODUCTION_REPORT: {
          PRODUCTION_REPORT_DETAIL: `/report-engine/production-reports/details`,
          PRODUCTION_REPORT_PAGED_DETAIL: `/report-engine/production-reports/paged-details`,
          PRODUCTION_REPORT_SUMMARY: `/report-engine/production-reports/summary`,
          PRODUCTION_REPORT_FINISHED_GOODS: `/report-engine/production-reports/finishedgoods`,
          PRODUCTION_COSTING_REPORT: `/report-engine/production-reports/finishedgoods`
        },
        DAILY_CONSUMPTION_REPORT: `/report-engine/production-reports/dailyconsumption`,
        DAILY_CONSUMPTION_SUMMARY_REPORT: `/report-engine/production-reports/dailyconsumptionsummary`,
        YIELD_SUMMARY_REPORTS: `/report-engine/production-reports/yield/summary`,
        YIELD_SUMMSRY_DETAILED_REPORTS: `/report-engine/production-reports/yield/details`,
        WO_WH_SHORTFALL_REPORT: `/reports/work_order_shortfall/summary`,
        WO_WH_SHORTFALL_DETAILED: `/reports/work_order_shortfall/detail`,
        BIN_ALLOCATION_REPORT: `/reports/work_order/binallocation`,
        WO_WISE_OPERATIONS_DETAILED: `/report-engine/production-reports/workorderlinkinv`,
        RESTOCKING_LEVELS_REPORT: `/products/restock-level-report`,
        REORDER_LEVEL_REPORT: `/reports/common/product-report-level`,
        JOB_WORKOUT_REPORT: `/reports/jwoReport`,
        JOB_WORKOUT_REPORT_EXPORT: `/reports/data-export`,
        OPERATIONS_SUMMARY_DETAILED: `/report-engine/production-reports/operationlinkinv`,
        SO_TO_WO_REPORT: 'reports/common/sales-order-work-order-details-report',
        FORECAST_DRIVEN_PO_REPORT: '/mrp/forecast/forecast-driven-po'
      },
      REVALUATION: '/reports/financial/get-revaluation-accounts',
      REVALUATION_ITEM: '/journal/revaluation',
      CLOSING_ACCOUNT_BALANCE: '/reports/financial/get-account-closing-balance',
      REVALUATION_BULK: '/journal/revaluation/bulk',
      IRAS__GST_STATUS: '/reports/iras/gst/status',
      IRAS_GST_POST_CODE: '/reports/iras/gst/connect',
      SUBMIT_TO_IRAS_GST: (id: number) => `/reports/iras/gst/submit/${id}`,
      FETCH_VAT_RETURN: `/reports/uk/search`,
      EXPORT_VAT_RETURN: (id: number) => `/reports/uk/${id}/export?format=XLS`,
      PATCH_VAT_RETURN: (id: number, type: string) =>
        `/reports/uk/logs/${type}/${id}`,
      PUT_VAT_RETURN: `/reports/uk/logs`,
      DELETE_VAT_RETURN: (id: number) => `/reports/uk/logs/${id}`,
      CREATE_VAT_RETURN: `/reports/uk/logs`,
      SAVE_VAT_RETURN: `/reports/uk/save-log`,
      SETTINGS_VAT_RETURN: (countryCode: number) =>
        `/reports/${countryCode}/settings`,
      UK_SETTING_REPORT: `/reports/uk/settings`,
      AMORTIZATION_FORECAST_REPORT_URL: `reports/amortization/forecast`,
      DEFERRED_EXPENSE_WATERFALL_REPORT_URL: `reports/amortization/waterfall-summary`,
      DEFERRED_EXPENSE_ROLL_FORWARD_SUMMARY_REPORT_URL: `reports/amortization/roll-forward`,
      PNL_BY_DEPARTMENT_DETAILS:
        '/reports/financial/pnl-by-department/journal_entry',
      BANK_BOOK_REPORT: 'reports/financial/bank_book',
      BANK_BOOK_REPORT_EXPORT: 'exim/export/bank_book',
      CONSOLIDATE_BANK_BOOK_REPORT_EXPORT: 'exim/export/bank_book/consolidated',
      CONSOLIDATE_BANK_BOOK_DETAIL_REPORT_EXPORT:
        'exim/export/bank_book_detail/consolidated'
    },
    ATTACHMENT: {
      UPLOAD: `/attachment/upload`,
      GET_ALL: (entityId: any, entityName: string) =>
        `/attachment/getAttachments?entityId=${entityId}&entityName=${entityName}`,
      GET_BY_ENTITY: (entityName: string) =>
        `attachment/getAttachmentsWithLink/ids?entityName=${entityName}`,
      DOWNLOAD: (attachmentId: any) =>
        `attachment/download?attachmentId=${attachmentId}`,
      DELETE: (
        attachmentId: any,
        updatePrint: boolean = false,
        productCode: string = ''
      ) =>
        `attachment/delete?attachmentId=${attachmentId}&updatePrintInfo=${updatePrint}&productCode=${productCode}`,
      UPDATE: (entityId: Number, entityName: String, attachmentId: Number) =>
        `/attachment/update?entityId=${entityId}&entityName=${entityName}&attachmentId=${attachmentId}`
    },
    EINVOICE: {
      CONNECT_E_INVOICE: `/invoices/in/e-invoice/auth-token`,
      FETCH_USERNAME_E_INVOICE: `/invoices/in/e-invoice/einvoice-username`,
      GENERATE_E_INVOICE: `/invoices/in/e-invoice/irn-data`,
      CANCEL_E_INVOICE: `/invoices/in/e-invoice/cancel-irn-data`,
      IS_INVOICE_CANCEL: `/invoices/in/e-invoice/is-cancel-valid`,
      FAILURE_REPORT: `/invoices/in/e-invoice/failure-log-report`,
      BULK_E_INVOICE_GENERATION: `/invoices/in/e-invoice/irn-data-bulk`,
      BULK_E_INVOICE_CANCEL: `/invoices/in/e-invoice/irn-data-bulk-cancel`,
      OUTBOUND: `/e-invoice/outbound`,
      OUTBOUND_PURCHASE_ORDER: `/e-invoice/purchase-order/outbound`
    },
    INDIA_GST_SUBMISSION: {
      AUTH_TOKEN: `/reports/india-gst/auth-token`,
      CONNECT: `/reports/india-gst/connect`,
      GET_OTP: `/reports/india-gst/otp-status`,
      SAVE_GSTR1: `/reports/india-gst/save-gstr1-data`,
      CHECK_STATUS: `reports/india-gst/check-status-gstr1-data`,
      RESET_GSTRI: `/reports/india-gst/reset-gstr1-data`,
      // FILE_GSTR: `reports/india-gst/update-gst-return-log/25`,
      FILE_GSTR: (id: number) => `reports/india-gst/update-gst-return-log/${id}`
    },
    EWAY_BILL: {
      EWAY_BILLS: `/invoices/in/eway`,
      EXPORT_EWAY_BILLS: `/invoices/in/eway/download`,
      SEARCH_EWAY_BILLS: `/invoices/in/eway/search`,
      UPDATE_EWAY_BILL: `/invoices/in/eway/update`,
      CANCEL_EWAY_BILLS: `/invoices/in/eway/cancel`,
      IN_CANCEL_EWAY_BILL: '/invoices/in/eway/cancel-ewb',
      GET_TRANSPORTERS: `/invoices/transporter/in/search`,
      SAVE_TRANSPORTERS: `/invoices/transporter/in`,
      UPDATE_TRANSPORTERS: (recordId: number) =>
        `/invoices/transporter/in/${recordId}`,
      DELETE_TRANSPORTERS: (recordId: number) =>
        `/invoices/transporter/in/${recordId}`,
      GET_INVOICE_DATA: (country: string, code: string) =>
        `/invoices/sales/${country}/list/${code}`,
      AUTH_TOKEN: 'invoices/in/eway/auth-token',
      GENERATE_EWB: 'invoices/in/eway/generate-ewb',
      GENERATE_EWB_BULK: 'invoices/in/eway/bulk-generate-ewb',
      REPORTS: 'invoices/in/eway/ewb-report'
    },
    PREPARE_1099: {
      EXPORT_FORM_1099_MISC: `/people/compliance/export/form-1099-misc`,
      EXPORT_FORM_1099_NEC: `/people/compliance/export/form-1099-nec`,
      EXPORT_FORM_1099_W9: `/people/compliance/export/w9`
    },
    PEOPLE: {
      FETCH: `/people/users`
    },
    CLASSES: {
      GET_CLASS: () => `/dimension/class`,
      DELETE_CLASS: (classId: any) => `/dimension/class/${classId}`,
      UPDATE_CLASS: (classId: any) => `/dimension/class/${classId}`,
      CREATE_CLASS: `/dimension/class`
    },
    CLIENT_PORTAL: {
      GET_CLIENT_PORTAL: `/client-portal/user`,
      UPDATE_USER_STATUS: `client-portal/user/active-status`,
      DELETE_USER: (id: string) => `client-portal/user/${id}`,
      DELETE_CLIENT_PORTAL: (classId: any) => `client-portal/cp-user/${classId}`
    },
    KREDX: {
      SUBMIT_DATA: `/contacts/proxyzapier`,
      GET_LOAN_AMOUNT: `/invoices/sales/calculateLoanAmount`
    },
    WORKFLOW: {
      GET_CONNECTIONS: `workflow/connection`,
      CREATE_CONNECTION: `workflow/connection-internal`,
      EMAIL_VERIFICATION: `v1/crm/campaign/email-verification`,
      APPROVAL_CONDITIONS: `workflow/flow/node/books_plus_approval`,
      SALES_INVOICE_TRIGGER: `/invoices/sales/draft/trigger`,
      SALES_ORDER_TRIGGER: `/invoices/salesorder/draft/trigger`,
      SALES_INVOICE_BULK_TRIGGER: `/invoices/sales/bulk/draft/trigger`,
      PURCHASE_INVOICE_TRIGGER: `/invoices/purchase/draft/trigger`,
      PURCHASE_ORDER_TRIGGER: `/orders/purchase/draft/trigger`,
      PURCHASE_ORDER_BULK_TRIGGER: `/orders/purchase/bulk/draft/trigger`,
      PURCHASE_REQUEST_TRIGGER: `/orders/purchaserequest/draft/trigger`,
      QUOTE_TRIGGER: `/quotations/draft/trigger`,
      WORKFLOW_PLACEHOLDERS: `/workflow-engine-books/placeholders`,
      GET_APPROVAL_HISTORY: `/crm/core/table`
    },
    LOCATIONS: {
      GET_LOCATION_CLASS_DIMENSIONS: `dimension`,
      CREATE_LOCATION: `/dimension/location`,
      GET_LOCATION: () => `/dimension/location`,
      GET_LOCATION_BY_LABEL: `/dimension/location/label`,
      DELETE_LOCATION: (storeId: any) => `/dimension/location/${storeId}`,
      UPDATE_LOCATION: (storeId: any) => `/dimension/location/${storeId}`
    },
    PRINT_CHECK: {
      GET_PRINT_CHECK: `/payments/make/expenses`,
      SET_PRINT_STATUS: `/payments/make/update-cheque-status`,
      GENERATE_CHECK_NUMBER: `/payments/make/generate-cheque-no`
    },
    WHATSAPP: {
      WHATSAPP_URL: (whatsapp: any) => `https://wa.me/${whatsapp}`,
      LINK_ACCOUNT: `/crm/app-integration/whatsapp/account`,
      UNLINK_ACCOUNT: (accountId: number) =>
        `/crm/app-integration/whatsapp/account/${accountId}`,
      GET_CONNECTIONS: '/crm/app-integration/whatsapp/account',
      GET_TEMPLATES: (accountId: number) =>
        `/crm/app-integration/whatsapp/account/${accountId}/templates`,
      WHATSAPP_ACCOUNT_PROVIDER: 'https://www.wa.team/',
      WHATSAPP_PROVIDER_ACCOUNT_DETAIL_GUIDE:
        'https://docs.wa.team/waba-platform/how-to-get-your-api-key-domain-for-whatsapp-business-api/',
      WHATSAPP_PROVIDER_TEMPLATE_GUIDE:
        'https://helpdocumentation.gitbook.io/user/messaging-channels/whatsapp-api/managing-your-whatsapp-api/how-to-create-whatsapp-templates',
      WHATSAPP_PROVIDER_TEMPLATE_VIDEO_GUIDE:
        'https://www.youtube.com/watch?v=HfpS4hsRgmY&t=10s'
    },
    CORE_SEND_EMAIL: `/crm/core/campaign/send-email`,
    BOOKKEEPER: {
      GET_CLIENT_TEAM_LIST: `/tenants/bookkeeper`,
      CLIENT: {
        ADD_CLIENT: '/tenants/in/bookkeeper/client',
        UPDATE_CLIENT: '/tenants/in/bookkeeper/client',
        MAKE_SUBSCRIPTION: `/subengine/subscription/bookkeeper`,
        RESEND_INVITE: `/tenants/bookkeeper/invite`,
        STATUS_CHANGE: '/tenants/bookkeeper/client-status',
        VERIFY_CONTACT: '/auth/checkcontact',
        CHECK_CLIENT_SUBSCRIPTION: `subengine/subscription`,
        CANCEL_SUBSCRIPTION: `/tenants/bookkeeper/subscription/cancel`
      },
      TEAM: {
        GET_TEAM_MEMBERS: '/tenants/bookkeeper/client/team-members',
        GET_TEAM_USER_DETAIL: `/tenants/bookkeeper/team-user-detail`
      }
    },
    AGENCIES: {
      GET_ALL_AGENCIES: `/tax-calc/tax-agency`,
      SAVE_AGENCY: `/tax-calc/tax-agency`,
      UPDATE_AGENCY: (id: number) => `tax-calc/tax-agency/${id}`,
      CHANGE_AGENCIES_STATUS: (id: number) =>
        `/tax-calc/tax-agency/activation/${id}`
    },
    RETURNS: {
      DETAILS: (documentCode: any, documentType: any, checkLinkedDocs: any) =>
        `/returns/sales/details?documentCode=${documentCode}&documentType=${documentType}&checkLinkedDocs=${checkLinkedDocs}`,
      CHECK_FULFILLMENTS: (documentCode: any) =>
        `/products/fulfillment/documentCode/${documentCode}`, //dev
      // CHECK_FULFILLMENTS: (documentCode: any) =>
      //   `/sales/${documentCode}/fulfillments`   //other env
      CREATE_RETURN: `/returns/sales`,
      DELETE_SALES_RETURNS: (salesReturnCode: any) =>
        `returns/sales?salesReturnCode=${salesReturnCode}`,
      GET_ADVANCE_TRACKING: (
        documentCode: any,
        documentType: any,
        checkLinkedDocs: any
      ) =>
        `/products/inventories/advanced-tracking/document/salesreturn?document_code=${documentCode}&document_type=${documentType}&checkLinkedDocs=${checkLinkedDocs}`,
      GET_ADVANCE_RECORD: `/products/inventories/advanced-tracking/goods-received/search`,
      GET_REASONS: `/returns/sales/reasons`
    },
    PURCHASE_RETURN: {
      DETAILS: (
        documentCode: any,
        documentType: any,
        forRejectedGr: any = false
      ) =>
        `/returns/purchase/details?documentCode=${documentCode}&documentType=${documentType}&forRejectedGr=${forRejectedGr}`,
      // CHECK_FULFILLMENTS: (documentCode: any) =>
      //   `/products/fulfillment/documentCode/${documentCode}`, //dev
      CHECK_RECEIVED_GOODS: (documentCode: any) =>
        `products/goodsreceipt/documentCode/${documentCode}`, //dev
      // CHECK_RECEIVED_GOODS: (documentCode: any) =>
      //   `/orders/purchase/${documentCode}/receipt`, //other env
      CHECK_RECEIVED_GOODS_RETURN: (documentCode: any) =>
        `products/goodsreceipt/pending-approval/by-code-status?documentCode=${documentCode}&status=REJECTED`,
      CREATE_RETURN: `/returns/purchase`,
      DELETE_PURCHASE_RETURNS: (purchaseReturnCode: any) =>
        `returns/purchase?purchaseReturnCode=${purchaseReturnCode}`,
      GET_ADVANCE_TRACKING: (documentCode: any, documentType: any) =>
        `/products/inventories/advanced-tracking/document/purchasereturn?document_code=${documentCode}&document_type=${documentType}`,
      GET_CONSOLIDATED_PURCHASE_RETURN_INFO: (
        documentCode: any,
        documentType: any
      ) =>
        `/products/inventories/purchase-return/details?documentCode=${documentCode}&documentType=${documentType}`,
      GET_CONSOLIDATED_PURCHASE_RETURN_REPORT_INFO: (documentCode: any) =>
        `/products/inventories/purchase-return/rejected-gr/details?documentCode=${documentCode}`,
      GET_ADVANCE_RECORD: `/products/inventories/advanced-tracking/goods-received/search`,
      GET_ADVANCE_RECORD_PURCHASE_RETURN: `/products/inventories/advanced-tracking/fulfillment/search`,
      GET_REASONS: `/returns/purchase/reasons`
    },
    REQUISITION_RETURN: {
      DETAILS: (purchaseRequestCode: any) =>
        `orders/purchaserequest/${purchaseRequestCode}`
      // CHECK_FULFILLMENTS: (documentCode: any) =>
      //   `/products/fulfillment/documentCode/${documentCode}`, //dev
    },
    FVF: {
      FAIR_VALUE_FORMULA: `/revrec/fvf`,
      FAIR_VALUE_SEARCH: `/revrec/fvf/search`
    },
    REV_REC_RULES: {
      GET_RULES: `/revrec/rc-rule/search`,
      GET_RULES_DROPDOWN: `revrec/rc-rule/search`,
      CREATE_RULE: `/revrec/rc-rule`,
      GET_ARRANGEMENT: `/revrec/ra/search`,
      WATERFALL_REPORT: `/reports/revrec/waterfall-summary`,
      DEFERRED_REVENUE_BY_ITEM: `/reports/revrec/deferred-revenue-product`,
      DEFERRED_REV_BY_CUSTOMER: `/reports/revrec/deferred-revenue-customer`,
      UPDATE_REV_ARRANGEMENT: `/revrec/ra/update-rev-arrangement`,
      REFRESH_REV_ARRANGEMENT: `/revrec/ra/revenue-job-status`,
      PROCEED_REV_ARRANGEMENT: `/revrec/ra/proceed`
    },

    ADDITIONAL_COST: {
      GET: '/products/additional-charge',
      POST: '/products/additional-charge',
      PUT: (id: string) => `/products/additional-charge/${id}`,
      DELETE: (id: any) => `/products/additional-charge/${id}`
    },
    FIXED_ASSET: {
      GET_ASSETS: 'asset-integration',
      UPDATE_ASSET: (id: number) => `asset-integration/${id}`,
      DELETE_FIXED_ASSET_BY_ID: (id: number) => `asset-integration/${id}`,
      GET_ASSET_GROUPS: 'asset-integration/group'
    },
    REVENUE_RECOGNITION: {
      GET_CATEGORY: 'revrec/rc/search',
      CREATE_CATEGORY: 'revrec/rc/',
      GET_REV_REC_JE: 'revrec/ra/rp/je/search',
      POST_REV_REC_JE: 'revrec/ra/rp/je',
      GET_REV_REC_JE_LOGS: 'revrec/ra/process-status',
      CREATE_RECLASSIFICATION_JE: 'revrec/ra/rp/schedule-je',
      GET_PROCESSED_RECLASSIFICATION_JE: (code: any) =>
        `revrec/ra/schedule-je-status?processCode=${code}`,
      PLAN_DETAILS: `revrec/ra/plan`,
      UPDATE_RELEASE_PLAN: (id: any) => `revrec/ra/release-plan/${id}`,
      REV_ARRANGEMENT_LINKED_RECORD: (id: number) => `/revrec/ra/${id}/je`
    },
    DEPRECIATION_SCHEDULE: {
      DEPRECIATION_SCHEDULE: '/asset-integration/depreciation-schedule',
      ROLL_BACK_PREVIEW:
        '/asset-integration/depreciation-schedule/rollback-preview',
      ROLL_BACK: '/asset-integration/depreciation-schedule/rollback',
      POST_DEPRECIATION_PREVIEW:
        '/asset-integration/depreciation-schedule/post-preview',
      POST_DEPRECIATION: '/asset-integration/depreciation-schedule/post',
      FISCAL_PERIOD: '/asset-integration/depreciation-schedule/fiscal-period'
    },
    GRANULAR_PERMISSIONS: {
      PERMISSION_METADATA: `/tenants/permissions/metadata`,
      GET_PERMISSIONS: '/crm/core/permission/role/user/me',
      GET_USERS_LIST: '/crm/core/user/invite',
      GET_ROLES_LIST: '/crm/core/permission/role',
      DELETE_ROLE: (roleId: any) => `/crm/core/permission/role/${roleId}`,
      UPDATE_USER_PERMISSION: '/crm/core/permission',
      INVITE_NEW_USER: '/users/books/invite',
      DELETE_USER: (userId: any) => `/users/books/invite/cancel/${userId}`,
      CREATE_UPDATE_DELETE_ROLE: `/rolegroups`,
      CHANGE_ROLE: `/users/update/info-role`,
      GET_LIST_OF_INVITED_USERS: (appName: any) =>
        `/users/books/invite?appName=${appName}`,
      GET_POWER_USER: `/subengine/subscription/userlimits`
    },
    MRP: {
      OPERATIONS: {
        GET_OPERATIONS: `/mrp/operation/search`,
        ADD_OPERATION: '/mrp/operation',
        EDIT_OPERATION: (id: any) => `/mrp/operation/${id}`,
        DELETE_OPERATION: `/mrp/operation`,
        GET_OPERATION_BY_ID: `/mrp/operation/ids`
      },
      MACHINE_TOOLS_MASTER: {
        MACHINE: `/mrp/machine`,
        GET_MACHINE: `/mrp/machine/search`,
        MACHINE_BY_IDS: `/mrp/machine/by-ids`,
        GET_SOPS: `mrp/machine/sop`,
        GET_MACHINE_BY_ASSET_GROUP: 'mrp/machine/by-asset-group',
        GET_MACHINE_SCHEDULER: '/mrp/machine/scheduler/search',
        MACHINE_SCHEDULER: '/mrp/machine/scheduler',
        DELETE_MACHINE_SCHEDULER_SCHEDHULES:
          '/mrp/machine/scheduler/delete-schedules',
        GET_SCHEDULER_SCHEDULES: '/mrp/machine/scheduler/schedules',
        CREATE_SCHEDULER_SCHEDULES: '/mrp/machine/scheduler/schedule',
        MATERIAL_CONSUMPTION: '/mrp/machine/consumption',
        GET_CONSUMPTION_BY_SCHEDULES: '/mrp/machine/consumption/by-schedules',
        CONSUME_MATERIAL: '/mrp/machine/consumption/consume',
        CONSUME_MULTIPLE: '/mrp/machine/consumption/consume/by-id',
        SHORT_FALL_LINK: (id: any) =>
          `/mrp/machine/scheduler/schedule/${id}/link-info`
      },
      WORK_STATIONS: {
        GET_WORK_STATIONS: `/mrp/master/workstation/search`,
        ADD_WORK_STATION: '/mrp/master/workstation',
        EDIT_WORK_STATION: (id: any) => `/mrp/master/workstation/${id}`,
        DELETE_WORK_STATION: (id: any) => `/mrp/master/workstation/${id}`,
        BULK_DELETE: ''
      },
      HOLIDAY_LIST: {
        FETCH: `/mrp/master/holidaylist/search`,
        ADD_LIST: `mrp/master/holidaylist`,
        UPDATE_LIST: (id: any) => `/mrp/master/holidaylist/${id}`,
        DELETE_LIST: (id: any) => `/mrp/master/holidaylist/${id}`
      },
      BOM_ASSEMBLY: {
        GET_BOM_ASSEMBLY: `/products`,
        ADD_BOM_ASSEMBLY: '/mrp/operation',
        EDIT_BOM_ASSEMBLY: (id: any) => `/products/${id}`,
        GET_WIP_PRODUCT_QTY: (pId: any, woId: any, bomCode: any) =>
          `/products/assemble/wipProducedQty?productCode=${pId}&workOrderCode=${woId}&bomMetaCode=${bomCode}`,
        GET_AUTO_PRODUCIBLE_QTY: (workOrderCode: any) =>
          `/mrp/workorderconsumption/${workOrderCode}/autoproduceableQty`,
        AUTO_PRODUCE: `/mrp/workorderconsumption/auto`
      },
      PRODUCTION_PLAN: {
        GET: `/mrp/productionplan/search`,
        ADD: '/mrp/productionplan',
        EDIT: (id: any) => `/mrp/productionplan/${id}`,
        DELETE: `/mrp/productionplan`
      },
      WORK_ORDER: {
        GET_ALL_WORK_ORDER: '/mrp/workorder/search',
        DELETE_WORK_ORDER: '/mrp/workorder',
        ADD_WORK_ORDER: '/mrp/workorder',
        BULK_ADD_WORK_ORDER: '/mrp/workorder/bulk',
        EDIT_WORK_ORDER: (id: any) => `/mrp/workorder/${id}`,
        WO_CONSUMPTION_SUMMARY: `/mrp/workorderconsumption/workOrderCode`,
        WO_CONSUMPTION_HISTORY: `/mrp/workorderconsumption/search`,
        WO_CONSUMPTION_SAVE: `/mrp/workorderconsumption`,
        WIP_COMPLETE_ORDER: (woId: any, status: any) =>
          `/mrp/workorder/${woId}/${status}`,
        EDIT_PATCH_WORK_ORDER: (id: any) => `/mrp/workorder/${id}`,
        ALL_WO_COMPLETED: '/mrp/workorder/is_all_completed',
        GET_LINKED_RECORDS: (id: any) => `/mrp/workorder/linkedRecord/${id}`,
        GET_PRODUCIBLE_QTY: (code: any) =>
          `/mrp/workorderconsumption/${code}/produceableQty `,
        GET_WO_SOURCE_DETAILS_BY_DOC_CODE_AND_TYPE: (docType: DOC_TYPE) =>
          `/mrp/workorder/workordersourcedetails?workOrderSource=${docType}`,
        DEFAULT_WAREHOUSE_FOR_WO: (tenantId: any) =>
          `/tenants/${tenantId}/settings/work_order`,
        UPDATE_WORKORDER_CONTACT: `/mrp/workorder/update_work_order_contact`,

        GET_DEFAULT_WAREHOUSE_FOR_WO: (tenantId: any) =>
          `/tenants/${tenantId}/settings?section=WORK_ORDER`,
        UNTAGGED_BIN: `mrp/workorder/bin-untag`,
        WORK_ORDER_EXPORT: `/reports/data-export`,
        WORK_ORDER_ARCHIVE: '/mrp/workorder/archive/'
      },
      DELIVERY_SCHEDULE: {
        CREATE: '/mrp/deliveryschedule',
        UPDATE: (id: string) => `/mrp/deliveryschedule/${id}`,
        GET_BY_TYPE_CODE: (type: string, code: string) =>
          `/mrp/deliveryschedule?documentType=${type}&documentCode=${code}`
      },
      JOB_CARD: {
        GET_ALL_JOB_CARD: '/mrp/jobcard/search',
        DELETE_JOB_CARD: '/mrp/jobcard',
        ADD_WORK_ORDER: '/mrp/jobcard',
        EDIT_JOB_CARD: (id: any) => `/mrp/jobcard/${id}`,
        EDIT_PATCH_JOB_CARD: (id: any) => `/mrp/jobcard/${id}`,
        UPDATE_BULK_STATUS: (status: string) =>
          `mrp/jobcard/updatestatus/${status}`,
        GET_WO_JOB_CARD: (woId: any) => `/mrp/jobcard/workorder/${woId}`,
        BULK_UPDATE_JC: '/mrp/jobcard/bulk',
        UPDATE_BULK_DATA: `mrp/jobcard/updateattrs`,
        UPDATE_JC_QC: 'mrp/jobcard/jobcarddetails/qc',
        JC_DETAILS_COMPLETION: 'mrp/jobcard/jobcarddetails/completion'
      },
      PROCESS_MANUFACTURE: {
        ADD_UPDATE_DETAILS: `/mrp/jobcard/addjobcarddetails`,
        GET_JC_DETAILS_LIST_BY_JCCODE: (code: any) =>
          `/mrp/jobcard/jobcarddetails/${code}`,
        DELETE_JC_DETAILS: `/mrp/jobcard/deletejobcarddetails/`,
        GET_AVAILABLE_TO_ASSIGN_DETAILS: (produceCode: any) =>
          `/mrp/jobcard/jobcarddetails/available-to-assign/${produceCode}`,
        GET_WO_DETAILS_BY_JC_CODE: (jcCode: any) =>
          `/mrp/workorder/jobcard/${jcCode}`,
        ASSIGN_QUANTITIES: `/mrp/jobcard/jobcarddetails/assignment`,
        GET_JC_QC: 'mrp/jobcard/jobcarddetails/available-to-qc/',
        AUTO_CONSUMPTION: (workOrderCode: any) =>
          `/mrp/jobcard/jobcarddetails/autoconsumption?workOrderCode=${workOrderCode}`
      },
      OPERATORS: {
        GET_ALL_OPERATORS: '/mrp/operator/search',
        DELETE_OPERATOR: '/mrp/operator',
        ADD_OPERATOR: '/mrp/operator',
        EDIT_OPERATOR: (id: any) => `/mrp/operator/${id}`,
        GET_OPERATOR_BY_ID: `/mrp/operator/ids`
      },
      QUALITY_CHECK: {
        GET_ALL_QUALITY_CHECK: '/mrp/qualitycontroltemplate/search',
        DELETE_QUALITY_CHECK: '/mrp/qualitycontroltemplate',
        ADD_QUALITY_CHECK: '/mrp/qualitycontroltemplate',
        EDIT_QUALITY_CHECK: (id: any) => `/mrp/qualitycontroltemplate/${id}`,
        GET_QUALITY_CHECK_BY_IDS: `/mrp/qualitycontroltemplate/template-ids`,
        FETCH_PRODUCT_QC_DETAILS: (id: any) =>
          `/mrp/qualitycontroltemplate/template-product-code/${id}`,
        FETCH_TEMPLATE_USAGE: `/mrp/qualitycontroltemplate/template-usage-by-product`,
        UPDATE_QC_STATUS_NORMAL_WO: `mrp/workorder/updateWorkOrderQCStatus`,
        UPDATE_QC_STATUS_WIP_PRODUCTION: (transactionCode: any) =>
          `mrp/workorderconsumption/updateQCStatus/${transactionCode}`,
        QC_SUMMARY: '/report-engine/quality-check/summary',
        QC_DETAILS: '/report-engine/quality-check/details',
        VENDOR_WISE_QC: '/report-engine/quality-check/vendor-wise',
        PRODUCT_WISE_QC: '/report-engine/quality-check/product-wise',
        QC_SUMMARY_EXPORT: '/report-engine/quality-check/export/summary',
        QC_DETAILS_EXPORT: '/report-engine/quality-check/export/details',
        VENDOR_WISE_QC_EXPORT:
          '/report-engine/quality-check/export/vendor-wise',
        PRODUCT_WISE_QC_EXPORT:
          '/report-engine/quality-check/export/product-wise'
      },
      FORECAST: {
        FETCH: '/mrp/forecast',
        SEARCH: `/mrp/forecast/search`,
        BASE: '/mrp/forecast',
        BY_ID: (id: any) => `/mrp/forecast/${id}`,
        DETAIL_BY_ID: (id: any) =>
          `/mrp/forecast/forecastdetails?forecastId=${id}`,
        FORECAST_DETAIL: `/mrp/forecast/saveforecastdetails`,
        MASTER_FORECAST_DETAIL_BY_ID: (id: any) =>
          `/mrp/forecast/productRawMaterialForecastDetails?forecastId=${id}`,
        MASTER_FORECAST_DOWNLOAD_SAMPLE:
          '/mrp/forecast/generateSampleForecastFile',
        SEARCH_ENDPOINT: `mrp/forecast/search`
      },
      DASHBOARD: {
        GET_ORDER_COMPLETION: `/mrp/dashboard/overview`,
        GET_BACKLOG_COMPLETION: `/mrp/dashboard/backlog`,
        GET_OPERATIONS: `/mrp/dashboard/operations`
      },
      SCHEDULING: {
        GET_RANGE_SCHEDULE: `/mrp/workorder/schedule-plan`
      },
      CONVERT: {
        QUOTATION: `/quotations/workorder`,
        SALES_ORDER: `/invoices/salesorder/workorder`,
        INVOICE: `/invoices/sales/workorder`
      }
    },
    STOCK_MANAGEMENT: {
      GET_STOCK_ADJUSTMENTS: `stock/stockAdjustments/search`,
      STOCK_ADJUSTMENTS: `stock/stockAdjustments`,
      DELETE_STOCK_ADJUSTMENTS: (id: string) => `stock/stockAdjustment/${id}`,
      DELETE_STOCK_TRANSFER: (id: string) =>
        `/products/inventories/stockTransfer/${id}`,
      CREATE_BULK_STOCK_TRANSFER: `/products/inventories/stockTransfer/bulk`,
      BULK_STOCK_TRANSFER_LOGS: `/products/inventories/stockTransfer/bulk/status`,
      DELETE_BULK_STOCK_TRANSFER: (id: string) =>
        `/products/inventories/stockTransfer/${id}/bulk`,
      FETCH_ADVANCED_TRACKING_SERIAL_BATCH_COUNT: (code: any) =>
        `/products/inventories/advanced-tracking/serial-batch-count?productVariantCode=${code}`,
      GET_VALUATION: (fromDate: any) => `/stock/valuation?fromDate=${fromDate}`,
      WAREHOUSE_LIST: (warehouseCode: string) =>
        `/stock/warehouse-products?warehouseCode=${warehouseCode}`,
      PRODUCT_LIST_BY_WAREHOUSE: `/products/brief-search/warehouse`,
      PRODUCT_LIST: (search: any) =>
        `/stock/products/brief-search?search=${search}`,
      PRODUCTS_LIST: (search: any) => `/stock/products/brief?search=${search}`,
      EXPORT_STOCK: (moduleName: string) =>
        `products/inventories/stockExport?moduleName=${moduleName}`,
      ADD_STOCK_ADJUSTMENT_REASON: `products/inventories/stockAdjustment/reason`,
      UPDATE_STOCK_ADJUSTMENT_REASON: (id: any) =>
        `products/inventories/stockAdjustment/reason/code/${id}`,
      ADJUSTMENT_REASON: (type: any) =>
        `products/inventories/stockAdjustment/reason/search?type=${type}`,
      GENERATE_WAY_BILL: '/invoices/in/eway/bulk-generate-ewb-st',
      STOCK_TRANSFER: `products/inventories/advanced-tracking/stk-transfer`,
      JWO_STOCK_TRANSFER: (documentCode: any) =>
        `products/inventories/jwo-stk-transfers?documentCode=${documentCode}`
    },
    STOCK_ADJUSTMENT_APPROVAL: {
      GET_STOCK_ADJUSTMENT_PENDING_APPROVAL:
        '/products/inventories/stock-adjustment-draft/stockAdjustment/search',
      DELETE_STOCK_ADJUSTMENT_APPROVAL: (id: string) =>
        `/products/inventories/stock-adjustment-draft/code/${id}`,
      APPROVE_STOCK_ADJUSTMENT_APPROVAL: (id: string) =>
        `/products/inventories/stock-adjustment-draft/approve/${id}`,
      REJECT_STOCK_ADJUSTMENT_APPROVAL: (id: string) =>
        `/products/inventories/stock-adjustment-draft/reject/${id}`
    },
    STOCK_REQUEST_APPROVAL: {
      GET_STOCK_REQUEST_PENDING_APPROVAL: '/products/stockrequestdraft',
      DELETE_STOCK_REQUEST_APPROVAL: (id: string) =>
        `/products/stockrequestdraft/code/${id}`,
      APPROVE_STOCK_REQUEST_APPROVAL: (id: string) =>
        `/products/stockrequestdraft/approve/${id}`,
      REJECT_STOCK_REQUEST_APPROVAL: (id: string) =>
        `/products/stockrequestdraft/reject/${id}`,
      GET_STOCK_REQUEST_APPROVAL_BY_ID: `/products/stockrequestdraft/`
    },
    STOCK_TRANSFER_APPROVAL: {
      GET_STOCK_TRANSFER_PENDING_APPROVAL:
        '/products/inventories/stock-transfer-draft/search',
      DELETE_STOCK_TRANSFER_APPROVAL: (id: string) =>
        `/products/inventories/stock-transfer-draft/code/${id}`,
      APPROVE_STOCK_TRANSFER_APPROVAL: (id: string) =>
        `/products/inventories/stock-transfer-draft/approve/${id}`,
      REJECT_STOCK_TRANSFER_APPROVAL: (id: string) =>
        `/products/inventories/stock-transfer-draft/reject/${id}`,
      GET_STOCK_TRANSFER_APPROVAL_BY_ID: `/products/inventories/stock-transfer-draft`
    },
    QUALITY_CHECK: {
      FETCH_QUALITY_CHECK: `/products/qctransaction/search`,
      ADD_QC_DETAILS: `/products/qctransaction`,
      GET_QC_DETAILS_BY_ID: (id: any) =>
        `/products/qctransaction/qcdetailsbyid/${id}`
    },
    WITH_HOLDING_TAX: {
      FETCH_STANDARD_WHT: `taxes/il/standardwht`,
      FETCH_WHT: `taxes/il/wht`,
      CREATE_WHT: `taxes/il/wht`,
      UPDATE_WHT: (whtId: any) => `taxes/il/wht/${whtId}`,
      DELETE_WHT: (whtId: any) => `taxes/il/wht/${whtId}`,
      FETCH_WHT_GROUP: `contacts/il/withholdingTaxGroup`,
      CREATE_WHT_GROUP: `contacts/il/withholdingTaxGroup`,
      UPDATE_WHT_GROUP: (whtGroupId: any) =>
        `contacts/il/withholdingTaxGroup/${whtGroupId}`,
      DELETE_WHT_GROUP: (whtGroupId: any) =>
        `contacts/il/withholdingTaxGroup/${whtGroupId}`
    },
    AMORTIZATION: {
      CREATE_AMORTIZATION_TEMPLATE: 'revrec/amortization/template',
      UPDATE_AMORTIZATION_TEMPLATE: (id: string) =>
        `revrec/amortization/template/${id}`,
      GET_AMORTIZATION_TEMPLATES: 'revrec/amortization/template/search',
      GET_AMORTIZATION_TEMPLATE_BY_CODE: (templateCode: String) =>
        `revrec/amortization/template/details/${templateCode}`,
      GET_AMORTIZATION_SCHEDULES: 'revrec/amortization/schedule/search',
      UPDATE_AMORTIZATION_SCHEDULES: (id: any) =>
        `revrec/amortization/schedule/${id}`,
      GET_AMORTIZATION_SCHEDULE_BY_CODE: (scheduleCode: String) =>
        `revrec/amortization/schedule/details/${scheduleCode}`,
      GET_AMORTIZATION_JE: 'revrec/amortization/schedule/je/search',
      POST_AMORTIZATION_JE: 'revrec/amortization/schedule/je',
      POST_AMORTIZATION_SCHEDULE: `revrec/amortization/schedule`
    },
    EXPENSE_ALLOCATION: {
      EXPENSE_ALLOCATION_TEMPLATES: 'journal/expense-allocation',
      UPDATE_EXPENSE_ALLOCATION: (id: any) =>
        `journal/expense-allocation/${id}`,
      UPDATE_EXPENSE_ALLOCATION_STATUS: (id: any) =>
        `journal/expense-allocation/${id}/update-active-status`,
      RUN_EXPENSE_ALLOCATION: (id: any) =>
        `journal/expense-allocation/${id}/run-allocation`,
      UPDATE_AND_RUN_EXPENSE_ALLOCATION: (id: any) =>
        `journal/expense-allocation/${id}/update-and-run-allocation`
    },
    LABEL_TEMPLATE: {
      FETCH_LABEL_TEMPLATE: `/template/label`,
      FETCH_LABEL_TEMPLATE_BY_TYPE: (type: string) =>
        `/template/label/ERP/${type}`,
      SAVE_LABEL_TEMPLATE: `/template/label`
    },
    CRM_TENANT: {
      TENANT: `crm/core/settings/tenant`
    },
    AUDITTRAIL: {
      TRANSACTION_LOG: `audittrail/document`
    },
    ROUTING_TEMPLATE: {
      FETCH_ROUTING_TEMPLATE: `/mrp/routingtemplate/search`,
      FETCH_ROUTING_TEMPLATE_BY_PRODUCT: (productCode: string) =>
        `/mrp/routingtemplate/product/${productCode}`,
      ADD_ROUTING_TEMPLATE: `/mrp/routingtemplate`,
      UPDATE_ROUTING_TEMPLATE: `/mrp/routingtemplate`,
      DELETE_ROUTING_TEMPLATE: `/mrp/routingtemplate`
    },
    SECURITY_GATE_ENTRY: {
      FETCH: `/orders/gateentry`,
      CREATE: `/orders/gateentry`,
      UPDATE: (id: number) => `/orders/gateentry/${id}`,
      DELETE: (code: string) => `/orders/gateentry/code/${code}`
    },
    GOODS_RECEIPT: {
      SEARCH: `/products/goodsreceipt/search`,
      APPROVAL_SEARCH: `products/goodsreceipt/pending-approval/search`,
      APPROVE_REJECT: `products/goodsreceipt/approval-status`,
      GOODS_RECEIVED_REJECTION: '/reports/common/gr-tolerance-rejection'
    },
    SALES_PERSON_MASTER: {
      FETCH_SALES_PERSON: '/contacts/salesperson',
      CREATE_SALES_PERSON: '/contacts/salesperson',
      DELETE_SALES_PERSON: '/contacts/salesperson/',
      UPDATE_SALES_PERSON: '/contacts/salesperson/'
    },
    ANONYMOUS: {
      LOCATION: `anonymous/location`
    },
    AI: {
      API: process.env.REACT_APP_BASE_URL + 'v1/deskera-qor-ai/'
    }
  };

  static ACCESS_TOKEN = null;
  static LOCALHOST_URL = 'localhost:4200';

  static PRODUCT_URL_GO = process.env.REACT_APP_URL_GO;
  static PRODUCT_URL_ERP = process.env.REACT_APP_URL_ERP;
  static PRODUCT_URL_CRM = process.env.REACT_APP_URL_CRM;
  static PRODUCT_URL_PEOPLE = process.env.REACT_APP_URL_PEOPLE;
  static PRODUCT_URL_WEBSITE_BUILDER =
    process.env.REACT_APP_URL_WEBSITE_BUILDER;
  static PRODUCT_URL_SHOP = process.env.REACT_APP_URL_SHOP;
  static PRODUCT_URL_REPORT_BUILDER = process.env.REACT_APP_URL_REPORT_BUILDER;
  static PRODUCT_URL_DOCUMENT_BUILDER =
    process.env.REACT_APP_URL_DOCUMENT_BUILDER;
  static PRODUCT_URL_WORKFLOW_ENGINE =
    process.env.REACT_APP_URL_WORKFLOW_ENGINE;
  static BOOKS_SUBSCRIPTION_PLANS = process.env.REACT_APP_BOOKS_PLAN;
  static PRODUCT_URL_WEBSITE = process.env.REACT_APP_URL_WEBSITE;
  static AUTO_LOGOUT_TIME = eval(
    String(process.env.REACT_APP_AUTO_LOGOUT_TIME)
  );
  static IDLE_TIMER_ID = process.env.REACT_APP_IDLE_TIMER_ID;
  static COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

  static URL_MOBILE_IOS =
    'https://apps.apple.com/app/desk-business-and-accounting/id1463523833?ls=1';
  static URL_MOBILE_ANDROID =
    'https://play.google.com/store/apps/details?id=com.deskera.desk';

  static FORM_EMBEDDED_URL =
    'https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/embed.js';
  static FORM_SUBMISSION_URL =
    'https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/FormSubmission.js';
  static LOAD_SCRIPTS_URL =
    'https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/LoadScript.js';
  static BARCODE_FIELD_PRODUCT_ID = 'PRODUCT_ID';
  static BARCODE_FIELD_BARCODE = 'BARCODE';
  static BARCODE_FIELD_BATCH_NUMBER = 'BATCH_NUMBER';
  static BARCODE_FIELD_SERIAL_NUMBER = 'SERIAL_NUMBER';
  static CONNECTION_ABORT = 'ECONNABORTED';
}
export interface IDefaultAPIConfig {
  Limit?: number;
  Search?: string;
  Query?: string;
  Page?: number;
}
export const defaultParamsSerializer = (
  apiConfig: IDefaultAPIConfig
): string => {
  let queryParam = ``;
  if (apiConfig.Search) {
    queryParam += `serch=${apiConfig.Search}&`;
  }
  if (apiConfig.Page !== undefined || apiConfig.Page !== null) {
    queryParam += `page=${apiConfig.Page}&`;
  }
  if (apiConfig.Query) {
    queryParam += `query=${apiConfig.Query}&`;
  }
  if (apiConfig.Limit) {
    queryParam += `limit=${apiConfig.Limit}`;
  }
  return queryParam;
};
