import PayslipUtility from "../Templates/PayslipTemplates/PayslipUtility";
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate } from "../Utilities/Utility";

class PeopleCustomFieldManager {
    static customFields = [];
    static sectionCustomFields = [];

    static setMasterCustomFields(customFields) {
        if (Array.isArray(customFields)) {
            PeopleCustomFieldManager.customFields = customFields;
        }
    }

    static setMasterSectionCustomFields(fieldConfigs) {
        let sectionCustomFields = [];
        if (Array.isArray(fieldConfigs)) {
            fieldConfigs.forEach((c) => {
                if (c.configName === "EMPLOYEE_CONFIG" && Array.isArray(c.sections)) {
                    const sections = c.sections.sort((s1, s2) => s1.position - s2.position)
                    sections.forEach(section => {
                        if (Array.isArray(section.fields)) {
                            const fields = section.fields.sort((f1, f2) => f1.index - f2.index);
                            fields.forEach(field => {
                                if (!field.systemField) {
                                    sectionCustomFields.push(field);
                                }
                            });
                        }
                    });
                }
            });
        }

        PeopleCustomFieldManager.sectionCustomFields = sectionCustomFields;
    }

    static getCustomFieldValueByDataType(customField, value) {
        switch (customField.type) {
            case "multiselect":
                
                break;
            default:
                break;
        }
    }

    static getCustomFieldValue(type, customFieldData = {}, sectionCustomFieldData = {}, data) {
        let value = customFieldData[type] || sectionCustomFieldData[type];
        if (Utility.isEmpty(value)) {
            return "NA";
        }

        let customField = PeopleCustomFieldManager.customFields.find(cf => cf.id === type);
        if (Utility.isEmpty(customField)) {
            customField = PeopleCustomFieldManager.sectionCustomFields.find(cf => cf.columnCode === type);
        }

        if (Utility.isEmpty(customField)) {
            return value;
        }

        switch (customField.type) {
            case "multiselect":
                if (Array.isArray(value) && value.length > 1) {
                    value = value.join(", ");
                }
                break;
            case "dropdown":
                if (Array.isArray(customField.options)) {
                    const option = customField.options.find(o => o.id === value);
                    if (option) {
                        value = option.name;
                    }
                }
                break;
            case "date":
                if (Array.isArray(value)) {
                    value = value[0];
                }

                if (value.includes('T')) {
                    const newDate = value.split('T')
                    if (newDate.length > 0) {
                        value = newDate[0]
                    }
                }

                if (value.includes('-')) {
                    const rawDate = value.split('-')
                    if (rawDate.length > 2) {
                        value = rawDate[2] + '/' + rawDate[1] + '/' + rawDate[0]
                    }
                }
                value = getConvertedDate(convertDateFromERPFormatToDocFormat(value), PayslipUtility.getDateFormat(data))
                break;
            default:
                if (Array.isArray(value)) {
                    value = value[0];
                }
                break;
        }

        if (Utility.isEmpty(value)) {
            value = "NA";
        }

        return value;
    }
}

export default PeopleCustomFieldManager;
