import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { DynamicPopupWrapper } from '../PopupWrapper';
import { useEffect, useState } from 'react';

const CopyRow = (props: any) => {
  const [rows, setRows] = useState<any>([]);

  const columnsNotToUpdate = [
    'Batch Number',
    'Assigned Unit',
    'Serial Number',
    'Quantity in Batch',
    'Batch Number',
    ''
  ];
  const columns = [
    {
      id: 'columns',
      key: 'columns',
      name: 'Select Columns To Copy',
      type: INPUT_TYPE.TEXT,
      width: 500,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    }
  ];

  useEffect(() => {
    let copyRows = props.rows.map((item: any) => {
      return { columns: item, selected: false };
    });
    copyRows = copyRows.filter(
      (row: any) => !columnsNotToUpdate.includes(row.columns)
    );
    setRows([...copyRows]);
  }, [props]);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle gap-2">
          <DKLabel text={`Select Rows to copy`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          {/* {props.allowFullScreen && getShrinkExpandButton()} */}
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div
            className={`row border-radius-m ${
              //  apiCallInProgress
              //    ? ' bg-gray1 text-gray border-m pr-2' :
              ' bg-button text-white'
            }`}
          >
            <DKButton title={'Save'} onClick={() => props.onSave(rows)} />
            {/* {apiCallInProgress && <DKSpinner iconClassName="ic-s" />} */}
          </div>
        </div>
      </div>
    );
  };

  const onRowSelect = (selectedRow: any) => {
    let copyRows = [...rows];
    if (selectedRow?.rowData?.selected) {
      copyRows[selectedRow.rowIndex].selected = true;
    } else {
      copyRows[selectedRow.rowIndex].selected = false;
    }

    setRows([...copyRows]);
  };

  const getDataGrid = () => (
    <div className="column parent-width">
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={true}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        // isAllRowSelected={productsArray?.every((item: any) => item.selected)}
        onRowSelect={onRowSelect}
        onAllRowSelect={(data: any) => {}}
        // width={960}
        rows={rows}
        columns={columns}
        // onRowUpdate={onRowUpdate}
        // onRowClick={onRowClick}
        // width={gridWidth}
      />
    </div>
  );

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 1000,
            width: '30%',
            maxHeight: '50%',
            height: '50%',
            padding: 0,
            paddingBottom: 30
          }}
        >
          {getHeader()}
          <div
            className="column parent-width parent-height p-r"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="m-v-r parent-width">
                <div className="m-v-r parent-width">{getDataGrid()}</div>
              </div>
            </div>
            {/* batch product  */}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default CopyRow;
