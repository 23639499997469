
import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { FooterRow } from "../FooterRow"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableOption } from "../TableOption"
import { JobWorkOutOrderTableWidth } from "./JobWorkOutOrderTableWidth"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType } from "../../../Constants/Constants"
import { TableHeader } from "../TableHeader"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { getIsSelectTotalColumn, getSortedHeader, getSortedLineItems, getTablePadding } from "../TableUtility"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"
import { JobWorkOutOrderTableItem } from "./JobWorkOutOrderTableItem"
import { JobWorkOutOrderTableTotalColumn } from "./JobWorkOutOrderTableTotalColumn"
import TableFooterUtility from "../../../Utilities/TableFooterUtility"
import ComponentManager from "../../../Manager/ComponentManager"

export class JobWorkOutOrderTable {
    static footerColumnList: HeaderColumn[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = canUserEdit
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        newHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }
    
    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }

        var sortedHeader = getSortedHeader()
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForJobWorkOutOrder()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        var footerList = TableFooterUtility.getFooterListByType('job_work_out_order')
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, rawData.printScale)
                    newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                    headerColumnList.push(newHeaderColumn)
                }
            });
        }

        var width = columnOption[0].width

        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var JobWorkOutOrderTableWidth = this.getHeaderColumnWidth(headerColumnList)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, JobWorkOutOrderTableWidth, element.code) + '%'
            });

            newColumnOption.forEach((element: TableHeader) => {
                element.width = this.getHeaderItemColumnWidth(element.type, JobWorkOutOrderTableWidth, element.code) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name)
                if (items !== undefined && items !== null) {
                    if (items.length > 0) {
                        var width = items[0].width
                        if (width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }

        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, [])
        return headerColumnList
    }

    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%', ''))
                if (num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }
    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, defaultTableColumnAlignmentList: any,tableColumnAlignmentList: any,tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.code = tableHeader.code
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if (columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (TableColumnPopupType.productCustomField === tableHeader.type) {
            if (tableHeader.code === undefined) {
                headerColumn.style.alignment = 'left'
            }
        }

        if (tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if (tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAlignment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAlignment.alignment
                }
            }
        }

        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[]) {
        var showWidthConfig = new JobWorkOutOrderTableWidth()
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, jobWorkOutOrderWidth: JobWorkOutOrderTableWidth, code?: string) {
        var width = 0
        var itemWidth = 0
        switch (type) {
            case TableColumnPopupType.lineNumber:
                width = jobWorkOutOrderWidth.lineNumber
                break;
            case TableColumnPopupType.code:
                width = jobWorkOutOrderWidth.code
                break;
            case TableColumnPopupType.productName:
                width = jobWorkOutOrderWidth.name
                break;
            case TableColumnPopupType.description:
                width = jobWorkOutOrderWidth.description
                break;
            case TableColumnPopupType.productNameWithDescription:
                width = jobWorkOutOrderWidth.nameWithDescription
                break;
            // case TableColumnPopupType.dueOn:
            //     width = jobWorkOutOrderWidth.dueOn
            //     break;
            case TableColumnPopupType.quantity:
                width = jobWorkOutOrderWidth.quantity
                break;
            case TableColumnPopupType.uom:
                width = jobWorkOutOrderWidth.uom
                break;
            case TableColumnPopupType.quantityWithUom:
                width = jobWorkOutOrderWidth.quantityWithUom
                break;
            case TableColumnPopupType.unitPrice:
                width = jobWorkOutOrderWidth.unitPrice
                break;
            case TableColumnPopupType.productDiscount:
                width = jobWorkOutOrderWidth.productDiscount
                break;
            // case TableColumnPopupType.rate:
            //     width = jobWorkOutOrderWidth.rate
            //     break;
            // case TableColumnPopupType.per:
            //     width = jobWorkOutOrderWidth.per
            //     break;
            case TableColumnPopupType.amount:
                width = jobWorkOutOrderWidth.amount
                break;
            case TableColumnPopupType.productCustomField:
                width = jobWorkOutOrderWidth.productCustomField
                if (code !== undefined) {
                    itemWidth = jobWorkOutOrderWidth.getProductCustomFieldWidthByCode(code)
                    if (itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            default:
                break;
        }

        return width
    }

    static getIsShowColumn(headerColumnList: HeaderColumn[], name: string) {
        var filterValue = headerColumnList.filter(x => x.name === name)
        if (filterValue === undefined || filterValue === null) {
            return false
        }
        if (filterValue.length === 0) {
            return false
        }
        return filterValue[0].columnOption.isShowColumn
    }

    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            ['1/1/2021', 'JE-00001', 'ACCT_CODE_1', 'ACCT_NAME_1', 'JE Description', '100', '100'],
            ['1/1/2021', 'JE-00001', 'ACCT_CODE_2', 'ACCT_NAME_2', 'JE Description', '100', '100'],
            ['1/1/2021', 'JE-00001', 'ACCT_CODE_3', 'ACCT_NAME_3', 'JE Description', '100', '100'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }
            var sortedItems = getSortedLineItems(documentData.lineItems)
            sortedItems.forEach((element: any) => {
                lineItems.push(new JobWorkOutOrderTableItem(element, documentData, currency, documentData.dateFormat).toString())
            });

            //add total section
            if (getIsSelectTotalColumn()) {
                lineItems.push(new JobWorkOutOrderTableTotalColumn(sortedItems, currency).toString())
            }
            return lineItems
            // return []
        }
    }

    static getDefaultItemColumn(tableStyle: any, index: number, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.fontSize = FONT_SIZE.regular
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            column.forEach(element => {
                var newItemColumn = this.getDefaultItemColumn(tableStyle, index, rawData.printScale)
                newItemColumn.value = element
                rowList.push(newItemColumn)
            });
            itemColumnList.push(rowList)
        });

        return itemColumnList
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if (data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any, index: number) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length - 1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.lineNumber = index + 1
        });
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        var dummyData = ['0.00', '0.00', '0.00', '$ 0.00']
        var currency = ''
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return dummyData
            }
            var roundingOffValue = documentData.roundOff ? documentData.roundOff : 0

            this.footerColumnList.forEach(element => {
                footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
            });

            return footerValue
        }
    }

    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any) {
        var keyValue: FooterRow[] = []
        var footerColumn = this.getFooterColumn(data, tableColumnOptions, tableStyle)
        var footerData = this.getFooterData(data)

        if (footerData === undefined || footerData === null) {
            return []
        }
        if (footerData.length === 0) {
            return []
        }
        if (footerColumn === undefined || footerColumn === null) {
            return []
        }
        var maxSize = footerColumn.length > footerData.length ? footerColumn.length : footerData.length

        for (let index = 0; index < maxSize; index++) {
            if (footerColumn.length > index) {
                var key = footerColumn[index]
                if (key !== undefined && key !== null) {
                    var newFooterRow = new FooterRow()
                    newFooterRow.header = key
                    newFooterRow.isSelected = key.isShowRecord
                    if (footerData.length > index) {
                        var value = footerData[index]
                        if (value !== undefined && value !== null) {
                            newFooterRow.value = value
                        }
                        keyValue.push(newFooterRow)
                    }
                }
            }
        }
        if (data.footerRow !== undefined) {
            var newArray: FooterRow[] = []
            data.footerRow.forEach((newFooter: FooterRow) => {
                keyValue.forEach(element => {
                    if (newFooter.header.name === element.header.name) {
                        var footer = new FooterRow()
                        footer.header = element.header
                        footer.isSelected = element.isSelected
                        footer.value = element.value
                        newArray.push(footer)
                    }
                });
            });
            keyValue = newArray
        }
        return keyValue
    }

    static getFooterColumn(data: any, columnOption: any, tableStyle: any) {
        var newFooterColumn: HeaderColumn[] = []

        if (columnOption === undefined || columnOption === null) {
            return
        }

        let subTotalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.subTotalAmount)
        if (subTotalRow) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.subTotalAmount, subTotalRow.name, subTotalRow.isSelected, undefined, data.printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, 'subtotal', 'subtotal', true, undefined, data.printScale))
        }

        let discount_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.discount)
        if (discount_column_data) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.discount, discount_column_data.name, discount_column_data.isSelected, undefined, data.printScale))
        }

        let before_tax_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.beforeTax)
        if (before_tax_column_data) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.beforeTax, before_tax_column_data.name, before_tax_column_data.isSelected, undefined, data.printScale))
        }

        let tax_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.tax)
        if (tax_column_data) {
            if (tax_column_data.type === TableColumnPopupType.tax) {
                if (!ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.tax, tax_column_data.name, tax_column_data.isSelected, undefined, data.printScale))
                } else if (tax_column_data.isSelected && ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn = this.parseTaxBreakdown(newFooterColumn, data, columnOption, tableStyle)
                }
            }
        }

        let additional_charges_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.additionalCharge)
        if (additional_charges_column_data) {
            if (additional_charges_column_data.isSelected) {
                if (this.getIsContainsAdditionalCharge(data)) {
                    var isGroup = TemplateSettingsManager.getIsGroupAdditionalChargesTax()
                    data.additionalCharge.forEach((tax: { taxId: string, taxName: string, taxType: string | undefined }) => {
                        if (!tax.taxId.includes('globalDiscount')) {
                            if (tax.taxType !== undefined) {
                                if (tax.taxType === 'parent') {
                                    newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                                }

                                if (isGroup) {
                                    if (tax.taxType === 'parent_tax') {
                                        newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                                    }
                                }
                                else {
                                    if (tax.taxType === 'gst') {
                                        newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                                    }
                                }
                            }
                            else {
                                newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                            }
                        }
                    });
                }
            }
        }

        let global_discount_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.globalDiscount)
        if (global_discount_column_data) {
            if (global_discount_column_data.isSelected) {
                if (this.getIsContainsAdditionalCharge(data)) {
                    data.additionalCharge.forEach((tax: { taxId: string, taxName: string, taxType: string | undefined }) => {
                        if (tax.taxType !== undefined) {
                            if (tax.taxId === 'globalDiscount') {
                                newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                            }
                        }
                        else {
                            newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                        }
                    });
                }
            }
        }

        let rounding_off_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.roundingOff)
        if (rounding_off_column_data) {
            if (rounding_off_column_data.isSelected) {
                newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.roundingOff, rounding_off_column_data.name, rounding_off_column_data.isSelected, undefined, data.printScale))
            }
        }

        let totalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.totalAmount)
        if (totalRow !== undefined) {
            if (totalRow.isSelected) {
                if (this.getIsShowTotal(tableStyle, TableColumnPopupType.totalAmount)) {
                    newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.totalAmount, totalRow.name, data.printScale))
                }
            }
        }
        else {
            if (this.getIsShowTotal(tableStyle, TableColumnPopupType.totalAmount)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.totalAmount, 'total', data.printScale))
            }
        }

        let amount_in_words_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.amountInWords)
        if (amount_in_words_column_data) {
            if (amount_in_words_column_data.isSelected) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amountInWords, amount_in_words_column_data.name, data.printScale))
            }
        }

        this.footerColumnList = newFooterColumn;
        return newFooterColumn;
    }


    static parseTaxBreakdown(footerColumnList: HeaderColumn[], data: any, columnOption: any, tableStyle: any) {
        var newFooterColumnList = footerColumnList
        data.taxBreakdown.forEach((tax: { taxName: string }) => {
            newFooterColumnList.push(this.setFooterHeader(tableStyle, tax.taxName, tax.taxName, true, undefined, data.printScale))
        });

        return newFooterColumnList
    }
    
    static getIsShowTotal(tableStyle: any, type: string) {
        if (type === TableColumnPopupType.amount) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    return false
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    return false
                }
            }
        }
        return true
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any, callback: any) {
        if (columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if (element.isSelected) {
                var item = element
                var width = element.width
                if (element.type === header.name) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        TemplateSettingsManager.updateTablePopupInfo(newColumnOptions)
        var newData = data
        newData.tableColumnPopup = newColumnOptions
        callback(newData)
    }

    static getFooterValue(element: HeaderColumn, data: any, roundingOffValue: number, currency: string) {
        switch (element.name) {
            case TableColumnPopupType.subTotalAmount:
                var defaultSubtotal = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0), currency)
                if (data.subTotal !== undefined && data.subTotal !== null) {
                    defaultSubtotal = Utility.toCurrencyFormat(data.subTotal, currency)
                }
                return defaultSubtotal
            case TableColumnPopupType.discount:
                var defaultDiscount = Utility.toCurrencyFormat(ComponentManager.getDiscountOnLineItems(data.lineItems), currency)
                if (data.discount !== undefined && data.discount !== null) {
                    defaultDiscount = Utility.toCurrencyFormat(data.discount, currency)
                }
                return defaultDiscount
            case TableColumnPopupType.tax:
                var defaultTax = Utility.toCurrencyFormat(ComponentManager.getTaxOnLineItems(data.lineItems), currency)
                if (data.taxAmount !== undefined && data.taxAmount !== null) {
                    defaultTax = Utility.toCurrencyFormat(data.taxAmount, currency)
                }
                return defaultTax
            case TableColumnPopupType.beforeTax:
                var beforeTax = ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0) - ComponentManager.getDiscountOnLineItems(data.lineItems)
                var defaultBeforeTax = Utility.toCurrencyFormat(beforeTax, currency)
                if (data.amountBeforeTax !== undefined && data.amountBeforeTax !== null) {
                    defaultBeforeTax = Utility.toCurrencyFormat(data.amountBeforeTax, currency)
                }
                return defaultBeforeTax
            case TableColumnPopupType.amountInWords:
                return data.amountInWords ? data.amountInWords : ''
            case TableColumnPopupType.roundingOff:
                var roundingOff = 0
                if (data.roundOff !== undefined) {
                    roundingOff = data.roundOff 
                }
                return Utility.toCurrencyFormat(roundingOff, currency)
            case TableColumnPopupType.totalAmount:
                var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue), currency)
                if (data.total !== undefined) {
                    total = Utility.toCurrencyFormat(data.total, currency)
                }
                return total   
            case TableColumnPopupType.globalDiscount:
                let globalDiscount = 0
                if (this.getIsContainsAdditionalCharge(data)) {
                    const dis = data.additionalCharge.find((x: any) => x.taxName === element.label)
                    if (dis) {
                        globalDiscount = dis.taxAmount
                    }
                }
                return Utility.toCurrencyFormat(globalDiscount, currency)
            default:
                if (this.getIsContainsAdditionalCharge(data)) {
                    let additionalCharge = 0
                    const charge = data.additionalCharge.find((x: any) => x.taxName === element.label)
                    if (charge) {
                        additionalCharge = charge.taxAmount
                    }
                    return Utility.toCurrencyFormat(additionalCharge, currency)
                }
                else {
                    return ''
                }
        }
    }

    static setFooterHeader(tableStyle: any, type: string, name: string, isShowRecord: boolean, index?: number, printScale?: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = name
        newFooterColumn.isShowRecord = isShowRecord
        newFooterColumn.index = index
        return newFooterColumn
    }

    static getDefaultFooterHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newFooterHeaderColumn = new HeaderColumn()
        newFooterHeaderColumn.type = 'string'
        newFooterHeaderColumn.style = new ColumnStyle()
        if (tableStyle.subTotalBorderColor !== undefined && tableStyle.subTotalBorderColor !== null) {
            newFooterHeaderColumn.style.borderColor = tableStyle.subTotalBorderColor
        }
        else {
            newFooterHeaderColumn.style.borderColor = tableStyle.itemBorderColor
        }

        newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor
        newFooterHeaderColumn.style.borderStyle = 'solid'
        newFooterHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newFooterHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        // newFooterHeaderColumn.style.alignment = 'right'
        return newFooterHeaderColumn
    }

    static setLastFooterHeader(tableStyle: any, type: string, name: string, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.style.backgroundColor = tableStyle.footerBackgroundColor
        newFooterColumn.style.fontColor = tableStyle.footerTextColor
        newFooterColumn.style.borderColor = tableStyle.footerBorderColor
        newFooterColumn.style.fontWeight = 'bold'
        if (type === TableColumnPopupType.outstandingAmount || type === TableColumnPopupType.amountInWords) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    newFooterColumn.style.fontColor = undefined
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    newFooterColumn.style.fontColor = undefined
                }
            }
        }

        if (tableStyle.lastRowTopColor !== undefined) {
            newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor
            newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth
        }
        if (tableStyle.lastRowBottomColor !== undefined) {
            newFooterColumn.style.borderBottomColor = tableStyle.lastRowBottomColor
            newFooterColumn.style.borderBottomWidth = tableStyle.lastRowBottomWidth
        }

        return newFooterColumn
    }

    static getIsContainsAdditionalCharge(documentData: any) {
        if (documentData.additionalCharge !== undefined && documentData.additionalCharge !== null) {
            if (documentData.additionalCharge.length > 0) {
                return true
            }
        }

        return false
    }
}
