import { useState, useEffect, useCallback } from 'react';
import LabelTemplateService from '../../Services/LabelTemplates';
import { LabelTemplateData } from './LabelTemplateHelper';

const useLabelTemplate = (documentType: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [labelTemplate, setLabelTemplate] = useState<LabelTemplateData>();

  const loadLabelTemplate = useCallback(() => {
    setIsLoading(true);
    LabelTemplateService.getLabelTemplateByType(documentType)
      .then((res: any) => {
        if (res.length) {
          const parsedData = res.map((item: any) => ({
            ...item,
            templateData: JSON.parse(item.templateData),
            labelSettings: JSON.parse(item.labelSettings)
          }));
          setTemplateList(parsedData);
          setLabelTemplate(parsedData[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [documentType]);

  useEffect(() => {
    if (documentType) {
      loadLabelTemplate();
    }
  }, [documentType, loadLabelTemplate]);

  return {
    isLoading,
    templateList,
    labelTemplate,
    setLabelTemplate,
    reloadLabelTemplate: loadLabelTemplate
  };
};

export default useLabelTemplate;
