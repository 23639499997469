import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getDateFrom, getDateString } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class SalesReturnTableItem {
    lineNumber: number;
    code: string;
    name: string;
    description: string;
    productCustomField: string;
    fulfilledQuantity: string;
    returnedQuantity: string;

    data: any;
    rawItemValue: any;

    constructor(lineItem: any, data:any, currency: string) {
        this.lineNumber = lineItem.lineNumber ? lineItem.lineNumber : ''
        this.code = lineItem.code ? lineItem.code : ''
        this.name = this.getProductName(data, lineItem)
        this.description = lineItem.description ? lineItem.description : ''
        this.productCustomField = ''
        this.fulfilledQuantity = lineItem.fulfilledQuantity ? lineItem.fulfilledQuantity : ''
        this.returnedQuantity = lineItem.returnedQuantity ? lineItem.returnedQuantity : ''

        this.rawItemValue = lineItem
        this.data = data
    }

    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.productNameWithDescription:
                return '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description)
            case TableColumnPopupType.fulfilledQty:
                return this.fulfilledQuantity
            case TableColumnPopupType.returnedQty:
                return this.returnedQuantity
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        if (cfType === "DATE") {
                            value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                        } else {
                            value = field.value
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            '0',
            '0',
        ]
    }
}
