import { INPUT_TYPE } from 'deskera-ui-library';
import { DOC_TYPE, MODULES_NAME } from '../../Constants/Constant';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { IColumnType } from '../../Models/Table';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import { FORMULA_FUNCTIONS } from './formula-functions';

export interface FormulaParameter {
  key: string;
  name: string;
  value: number; // Assuming value is a number for evaluation
  isCustomField?: boolean; // New optional key
  isFromParent?: boolean; // New optional key
  customFieldId?: string; // New optional key
  isFormula?: boolean;
  isLineItem?: boolean;
}

export const modulesUsingFormulaCF = [
  MODULES_NAME.PRODUCT,
  MODULES_NAME.MRP_WORK_ORDER,
  MODULES_NAME.INVOICE,
  MODULES_NAME.QUOTATION,
  MODULES_NAME.SALESORDER,
  MODULES_NAME.ORDER,
  MODULES_NAME.BILL
];

export const extractUsedParameters = (
  expression: string,
  allParameters: FormulaParameter[]
): FormulaParameter[] => {
  const keys = allParameters.map((param) => param.key);
  const foundKeys = new Set<string>();

  // For each key in the list
  keys.forEach((key) => {
    // Escape special characters in the key for regex
    const escapedKey = key.replace(/[.*+?^${}()|[\]\\%]/g, '\\$&');
    // Create a pattern that matches the key when it's surrounded by non-word chars or string boundaries
    const pattern = new RegExp(
      `(?:^|[^a-zA-Z0-9_])(${escapedKey})(?:$|[^a-zA-Z0-9_])`,
      'g'
    );
    if (pattern.test(expression)) {
      foundKeys.add(key);
    }
  });

  const allFoundKeys = Array.from(foundKeys);

  const extractedParams = allParameters.filter((param) =>
    allFoundKeys.includes(param.key)
  );
  return extractedParams;
};

export const getLineItemsKeyForModule = (
  selectedModule: MODULES_NAME | string
) => {
  switch (selectedModule) {
    case MODULES_NAME.INVOICE:
      return 'salesInvoiceItems';
    case MODULES_NAME.QUOTATION:
      return 'quotationItemDtoList';
    case MODULES_NAME.SALESORDER:
      return 'salesOrderItems';
    case MODULES_NAME.BILL:
      return 'purchaseInvoiceProducts';
    case MODULES_NAME.ORDER:
      return 'purchaseOrderItems';
  }
  return '';
};

export const getCustomFieldValue = (obj: any[], path: string[]) => {
  // const allCustomFields = obj?.['customField'];
  const cf = obj?.find((field: any) => field.code === path?.[0]);
  if (Utility.isNotEmpty(cf)) {
    return cf?.value;
  }
  return '0';
};

export const getVariableValue = (
  fieldPath: string[],
  object: any,
  parent: any
) => {
  let currentObj = object;
  for (let i = 0; i < fieldPath.length; i++) {
    const field = fieldPath[i];
    if ('parent' === field) {
      if (Utility.isEmptyObject(parent)) {
        console.log('Parent not found.');
      }
      currentObj = parent;
      continue;
    }

    parent = currentObj;
    currentObj = currentObj?.[field];

    if (Utility.isEmptyObject(currentObj)) {
      // console.log('Field not found: ', field);
    }

    if ('customField' === field) {
      currentObj = getCustomFieldValue(
        currentObj,
        fieldPath.slice(i + 1, fieldPath.length)
      );
    }

    const parentObj = { ...parent };

    if (Array.isArray(currentObj) && i < fieldPath.length - 1) {
      let values: string[] = [];
      currentObj?.forEach((obj: any) => {
        values.push(
          getVariableValue(
            fieldPath.slice(i + 1, fieldPath.length),
            obj,
            parentObj
          )
        );
      });

      currentObj = values;
    }

    return currentObj;
  }
};

export const getExtraKeysForLineItem = (
  selectedModule: MODULES_NAME,
  config: any,
  currentDoc: any
) => {
  const lineItemsKey = getLineItemsKeyForModule(selectedModule);
  let extraKeys: any = {};
  if (config.isLineItem) {
    if (config.isCustomField) {
      let path: any[] = [];
      let tempPath: any[] = [];
      if (config.isFromParent) {
        path.push('parent');
        tempPath.push('parent');
      }
      path = [...path, lineItemsKey, 'customField', config.customFieldId];
      tempPath = [...tempPath, 'items', 'customField', config.customFieldId];
      extraKeys = {
        ...extraKeys,
        [config.key + '_line_item']: {
          path: path,
          value: currentDoc
            ? getVariableValue(tempPath, currentDoc, currentDoc)
            : 1
        }
      };
    } else {
      let path = [lineItemsKey, config.key];
      const tempPath = ['items', config.key];
      extraKeys = {
        ...extraKeys,
        [config.key + '_line_item']: {
          path: path,
          value: currentDoc
            ? getVariableValue(tempPath, currentDoc, currentDoc)
            : 1
        }
      };
    }
  }

  return extraKeys;
};

export const getPathAndValuesForExtraKeys = (
  selectedModule: MODULES_NAME,
  configs: any[],
  currentDoc: any
) => {
  let extraKeys: any = {};
  configs.sort(
    (config1: any, config2: any) => config1.isLineItem - config2.isLineItem
  );
  configs.forEach((config: any) => {
    if (config.isLineItem) {
      let tempExtraKeys = getExtraKeysForLineItem(
        selectedModule,
        config,
        currentDoc
      );

      currentDoc = {
        ...currentDoc,
        items: currentDoc?.items?.map((item: any, index: number) => {
          if (item) {
            if (config.isCustomField) {
              const cfIndex = item.customField?.findIndex(
                (cf: any) => cf.code === config.customFieldId
              );
              if (cfIndex !== -1) {
                item[item.customField[cfIndex].id] =
                  tempExtraKeys[config.key + '_line_item']?.value?.[index];
                item.customField[cfIndex] = {
                  ...item.customField[cfIndex],
                  value:
                    tempExtraKeys[config.key + '_line_item']?.value?.[index]
                };
              }
            } else {
              item[config.key] =
                tempExtraKeys?.[config?.key + '_line_item']?.value?.[index];
            }
          }
          return item;
        })
      };

      extraKeys = {
        ...extraKeys,
        ...tempExtraKeys
      };
    } else {
      if (config.isCustomField) {
        let path: any[] = [];
        if (config.isFromParent) {
          path.push('parent');
        }
        path = [...path, 'customField', config.customFieldId];

        path = [...path];
        extraKeys = {
          ...extraKeys,
          [config.key]: {
            path: path,
            value: currentDoc
              ? getVariableValue(path, currentDoc, currentDoc)
              : 1
          }
        };
      } else {
        let path = [config.key];
        if (config.readFromInventory) {
          path = ['inventory', config.key];
        }
        extraKeys = {
          ...extraKeys,
          [config.key]: {
            path: path,
            value: currentDoc
              ? getVariableValue(path, currentDoc, currentDoc)
              : 1
          }
        };
      }
    }
  });
  return extraKeys;
};

export const getNonCFKeysValueForFormula = (
  selectedModule: MODULES_NAME | string,
  currentDoc?: any
) => {
  let extraKeys: any = {};
  switch (selectedModule) {
    // Update this if any new number type field is
    // added in column config of any module using formula CF
    case MODULES_NAME.PRODUCT:
      const productConfig = getColumnConfigForFormula(
        '',
        MODULES_NAME.PRODUCT,
        false
      );
      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        productConfig,
        currentDoc
      );
      break;
    case MODULES_NAME.MRP_WORK_ORDER:
      extraKeys = {
        manufactureQuantity: {
          value:
            getVariableValue(['manufactureQuantity'], currentDoc, currentDoc) ||
            0,
          path: ['manufactureQuantity']
        },
        actualQuantity: {
          value:
            getVariableValue(['actualQuantity'], currentDoc, currentDoc) || 0,
          path: ['actualQuantity']
        },
        plannedOperatingCost: {
          value:
            getVariableValue(
              ['operationCostDetails', 'plannedOperatingCost'],
              currentDoc,
              currentDoc
            ) || 0,
          path: ['operationCostDetails', 'plannedOperatingCost']
        },
        actualOperatingCost: {
          value:
            getVariableValue(
              ['operationCostDetails', 'actualOperatingCost'],
              currentDoc,
              currentDoc
            ) || 0,
          path: ['operationCostDetails', 'actualOperatingCost']
        },
        totalOperatingCost: {
          value:
            getVariableValue(
              ['operationCostDetails', 'totalOperatingCost'],
              currentDoc,
              currentDoc
            ) || 0,
          path: ['operationCostDetails', 'totalOperatingCost']
        }
      };
      break;
    case MODULES_NAME.INVOICE:
      const invoiceConfig = getColumnConfigForFormula(
        '',
        MODULES_NAME.INVOICE,
        true
      );

      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        invoiceConfig,
        currentDoc
      );
      break;
    case MODULES_NAME.QUOTATION:
      const quoteConfig = getColumnConfigForFormula(
        '',
        MODULES_NAME.INVOICE,
        true
      );

      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        quoteConfig,
        currentDoc
      );
      break;
    case MODULES_NAME.SALESORDER:
      const soConfig = getColumnConfigForFormula(
        '',
        MODULES_NAME.SALESORDER,
        true
      );

      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        soConfig,
        currentDoc
      );
      break;

    case MODULES_NAME.ORDER:
      const poConfig = getColumnConfigForFormula('', MODULES_NAME.ORDER, true);

      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        poConfig,
        currentDoc
      );
      break;

    case MODULES_NAME.BILL:
      const billConfig = getColumnConfigForFormula('', MODULES_NAME.BILL, true);

      extraKeys = getPathAndValuesForExtraKeys(
        selectedModule,
        billConfig,
        currentDoc
      );
      break;
    default:
      break;
  }
  return extraKeys;
};

// Configs For product in document line items
export const getStaticConfigForLineItemProducts = (
  selectedModule: MODULES_NAME
) => {
  let tempConfig: any[] = [
    {
      id: 'quantity',
      key: 'productQuantity',
      name: 'Quantity',
      type: INPUT_TYPE.NUMBER
    },
    {
      id: 'price',
      key: 'unitPrice',
      name: 'Price',
      type: INPUT_TYPE.NUMBER
    },
    {
      id: 'taxAmount',
      key: 'taxAmount',
      name: 'Tax',
      type: INPUT_TYPE.NUMBER
    }
    // {
    //   id: 'landedCostWeight',
    //   key: 'landedCostWeight',
    //   name: 'Weight in KG',
    //   type: INPUT_TYPE.NUMBER
    // },
    // {
    //   id: 'totalAmount',
    //   key: 'totalAmount',
    //   name: 'Amount',
    //   type: INPUT_TYPE.NUMBER
    // }
  ];

  let productFilteredConfig: any[] = [];
  const productColumnConfig = Store.getState()?.products?.columnConfig || [];
  // Get all product CF of Number Type
  let filteredProdConfig =
    productColumnConfig?.filter(
      (config) =>
        config.type === IColumnType.NUMBER &&
        (config.columnCode as string).startsWith('custom_D-')
    ) || [];
  const allFormulaTypeDimensionsForProductModule: any[] =
    Store.getState().commonData?.data?.productCustomFields?.content?.filter(
      (field: any) =>
        field?.fieldType === 'FORMULA' &&
        field?.modules?.includes(MODULES_NAME.PRODUCT)
    ) || [];
  // Get all product CF of Formula Type
  productColumnConfig?.forEach((config: any) => {
    const isCustomField = (config.columnCode as string).startsWith('custom_D-');
    if (isCustomField) {
      const customFieldCode = (config.columnCode as string).split('_')?.[1];
      const customFieldIndex =
        allFormulaTypeDimensionsForProductModule.findIndex(
          (field: any) => field.code === customFieldCode
        );
      if (customFieldIndex !== -1) {
        productFilteredConfig.push({ ...config, isFormula: true });
      }
    }
  });

  filteredProdConfig = [
    ...tempConfig,
    ...filteredProdConfig,
    ...productFilteredConfig
  ];
  return filteredProdConfig;
};

export const getStaticDocConfigsForModuleType = (
  selectedModule: MODULES_NAME,
  moduleConfigs: any[]
) => {
  let lineConfigs: any[] = [];
  moduleConfigs = moduleConfigs ? moduleConfigs : [];

  switch (selectedModule) {
    case MODULES_NAME.QUOTATION:
      let quoteConfig = DocumentConfigManager.get(DOC_TYPE.QUOTE);
      lineConfigs = quoteConfig.filter(
        (config: any) => config.type === INPUT_TYPE.NUMBER
      );
      break;
    case MODULES_NAME.INVOICE:
      let invoiceLineConfig = DocumentConfigManager.get(DOC_TYPE.INVOICE);
      lineConfigs = invoiceLineConfig.filter(
        (config: any) => config.type === INPUT_TYPE.NUMBER
      );
      break;
    case MODULES_NAME.SALESORDER:
      let salesOrderConfig = DocumentConfigManager.get(DOC_TYPE.SALES_ORDER);
      lineConfigs = salesOrderConfig.filter(
        (config: any) => config.type === INPUT_TYPE.NUMBER
      );
      break;
    case MODULES_NAME.ORDER:
      let poConfig = DocumentConfigManager.get(DOC_TYPE.ORDER);
      lineConfigs = poConfig.filter(
        (config: any) => config.type === INPUT_TYPE.NUMBER
      );
      break;
    case MODULES_NAME.BILL:
      let billConfig = DocumentConfigManager.get(DOC_TYPE.BILL);
      lineConfigs = billConfig.filter(
        (config: any) => config.type === INPUT_TYPE.NUMBER
      );
      break;

    default:
      break;
  }

  moduleConfigs = moduleConfigs?.filter(
    (config: any) => config.type === INPUT_TYPE.NUMBER
  );
  moduleConfigs = moduleConfigs?.map((config: any) => ({
    ...config,
    isLineItem: false
  }));

  lineConfigs = lineConfigs?.map((config: any) => ({
    ...config,
    isLineItem: true
  }));
  return [...moduleConfigs, ...lineConfigs];
};

const getFormulaFieldsForModule = (
  moduleName: MODULES_NAME,
  moduleConfigs: any[],
  allFormulaTypeDimensionsForSelectedModule: any[]
) => {
  let formulaFieldsForModule: any[] = [];
  moduleConfigs?.forEach((config: any) => {
    const isCustomField = (config.columnCode as string).startsWith('custom_D-');
    if (isCustomField) {
      const customFieldCode = (config.columnCode as string).split('_')?.[1];
      const customFieldIndex =
        allFormulaTypeDimensionsForSelectedModule.findIndex(
          (field: any) => field.code === customFieldCode
        );
      if (customFieldIndex !== -1) {
        formulaFieldsForModule.push({ ...config, isFormula: true });
      }
    }
  });
  return formulaFieldsForModule;
};

export const getColumnConfigForFormula = (
  fieldName: string,
  selectedModule: MODULES_NAME,
  processProductAsLineItem?: boolean,
  moduleColumnConfig?: any[]
) => {
  let filteredConfig: any[] = [];
  const allFormulaTypeDimensionsForSelectedModule: any =
    Store.getState().commonData?.data?.customFields?.content?.filter(
      (field: any) =>
        field?.label !== fieldName &&
        field?.fieldType === 'FORMULA' &&
        field?.modules?.includes(selectedModule)
    ) || [];
  switch (selectedModule) {
    case MODULES_NAME.MRP_WORK_ORDER:
      const workOrderConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().mrpWorkOrder.columnConfig) || [];
      filteredConfig =
        workOrderConfig?.filter(
          (config) => config.type === IColumnType.NUMBER
        ) || [];
      let formulaFieldsForWO: any[] = getFormulaFieldsForModule(
        selectedModule,
        workOrderConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForWO];
      break;
    case MODULES_NAME.INVOICE:
      const invoiceConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().invoices.columnConfig) || [];
      filteredConfig =
        getStaticDocConfigsForModuleType(selectedModule, invoiceConfig) || [];
      let formulaFieldsForInvoice: any[] = getFormulaFieldsForModule(
        selectedModule,
        invoiceConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForInvoice];
      break;
    case MODULES_NAME.SALESORDER:
      const soConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().salesOrder.columnConfig) || [];
      filteredConfig =
        getStaticDocConfigsForModuleType(selectedModule, soConfig) || [];
      let formulaFieldsForSO: any[] = getFormulaFieldsForModule(
        selectedModule,
        soConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForSO];
      break;
    case MODULES_NAME.QUOTATION:
      const quoteConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().quotes.columnConfig) || [];
      filteredConfig =
        getStaticDocConfigsForModuleType(selectedModule, quoteConfig) || [];
      let formulaFieldsForQuote: any[] = getFormulaFieldsForModule(
        selectedModule,
        quoteConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForQuote];
      break;
    case MODULES_NAME.ORDER:
      const poConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().purchaseOrders.columnConfig) || [];
      filteredConfig =
        getStaticDocConfigsForModuleType(selectedModule, poConfig) || [];
      let formulaFieldsForPO: any[] = getFormulaFieldsForModule(
        selectedModule,
        poConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForPO];
      break;
    case MODULES_NAME.BILL:
      const billConfig =
        (Utility.isNotEmpty(moduleColumnConfig)
          ? moduleColumnConfig
          : Store.getState().bills.columnConfig) || [];
      filteredConfig =
        getStaticDocConfigsForModuleType(selectedModule, billConfig) || [];
      let formulaFieldsForBill: any[] = getFormulaFieldsForModule(
        selectedModule,
        billConfig,
        allFormulaTypeDimensionsForSelectedModule
      );
      filteredConfig = [...filteredConfig, ...formulaFieldsForBill];
      break;
    default:
      break;
  }

  if (
    [
      MODULES_NAME.PRODUCT,
      MODULES_NAME.INVOICE,
      MODULES_NAME.QUOTATION,
      MODULES_NAME.SALESORDER,
      MODULES_NAME.ORDER,
      MODULES_NAME.BILL
    ].includes(selectedModule)
  ) {
    let filteredProdConfig = getStaticConfigForLineItemProducts(selectedModule);
    filteredProdConfig = filteredProdConfig.map((config: any) => {
      return {
        ...config,
        isLineItem: processProductAsLineItem
      };
    });
    filteredConfig = [...filteredConfig, ...filteredProdConfig];
    let unique = [];
    let distinctConfigs = [];
    for (let i = 0; i < filteredConfig.length; i++) {
      if (!unique[filteredConfig[i].id]) {
        distinctConfigs.push(filteredConfig[i]);
        unique[filteredConfig[i].id] = 1;
      }
    }
    filteredConfig = distinctConfigs;
  }

  let tempColumnConfig: any[] = [];
  filteredConfig.forEach((config) => {
    const isCustomField = (config.columnCode as string)?.startsWith(
      'custom_D-'
    );
    let customFieldId = null;
    if (isCustomField) {
      customFieldId = (config.columnCode as string).split('_')?.[1];
    }
    tempColumnConfig.push({
      key: isCustomField
        ? (config.name as string).replace(/\s+/g, '_')
        : config.columnCode || config.key,
      name: config.name,
      value: 1, // Keep the value 1 for temporary evaluation
      isCustomField: isCustomField,
      isFromParent: false,
      customFieldId: customFieldId,
      isLineItem: !!config.isLineItem,
      isFormula: !!config.isFormula,
      readFromInventory: !!config.readFromInventory // Only in case of product key, if applicatble
    });
  });
  // console.log('tempColumnConfig: ', tempColumnConfig);
  return tempColumnConfig;
};

// Function to evaluate the expression
export const evaluateExpression = (
  expression: string,
  parameters: FormulaParameter[]
) => {
  // Replace keys in the expression with their corresponding values
  const evaluatedExpression = parameters.reduce((expr, param) => {
    let value = '0';
    if (param.value !== undefined && param.value !== null) {
      value = param.value.toString();
    }
    return expr.replace(new RegExp(`\\b${param.key}(?!\\s*\\()`, 'g'), value);
  }, expression);
  // Prepend "formulajs." to all function names in the evaluated expression
  const functionNames = FORMULA_FUNCTIONS.map((func) => func.name);
  const functionRegex = new RegExp(`\\b(${functionNames.join('|')})\\b`, 'g');
  const expressionWithFormulajs = evaluatedExpression.replace(
    functionRegex,
    (match) => {
      // Check if the match is followed by an opening parenthesis
      if (
        evaluatedExpression.charAt(
          evaluatedExpression.indexOf(match) + match.length
        ) === '('
      ) {
        return `formulajs.${match}`; // Prepend "formulajs." if followed by '('
      }
      return match; // Return the match unchanged if not followed by '('
    }
  );

  try {
    // Evaluate the expression using formulajs
    // console.log('expressionWithFormulajs: ', expressionWithFormulajs);
    let result = eval(expressionWithFormulajs);
    if (isNaN(result)) {
      result = '';
    }
    return { value: result, isValid: true, errorMessage: '' }; // Return the result for the callback
  } catch (error: any) {
    // console.error('Error: ', error);
    return { value: null, isValid: false, errorMessage: 'Invalid formula' }; // Return null on error
  }
};
