import {
  DKButton,
  DKLabel,
  DKDataGrid,
  DKTooltipWrapper,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { IWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../../../Services/NumberFormat';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import ConsumeAndProduceSummaryPopup from '../../../WIPConsumption/ConsumeAndProduceSummaryPopup';
import { hasWorkOrderItemsChanged } from '../../WorkOrderHelper';
import ConsumeProduceHelper from './ConsumeProduceHelper';
import { IColumn } from '../../../../../Models/Table';
import useScreenResize from '../../../../../Hooks/useScreenResize';

interface IWIPConsumeProduceListProps {
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  workOrderData: IWorkOrder;
  consumptionSummaryResponse: any[];
  refreshConsumptionSummary: () => void;
}

const WIPConsumeProduceList = (props: IWIPConsumeProduceListProps) => {
  const [showConsumptionPopup, setShowConsumptionPopup] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (
      !props.workOrderData?.workOrderItems ||
      !props.consumptionSummaryResponse
    )
      return;

    let consumptionRows = ConsumeProduceHelper.getConsumptionRows(
      props.consumptionSummaryResponse,
      props.workOrderData,
      props.workOrderData.workOrderItems
    );
    if (JSON.stringify(rows) !== JSON.stringify(consumptionRows)) {
      setRows(consumptionRows);
    }
  }, [props.consumptionSummaryResponse, props.workOrderData]);

  const getColumnConfig = () => {
    let copyOfColumnConfig = deepClone(
      ConsumeProduceHelper.CONSUME_PRODUCT_GRID_COLUMNS
    );

    copyOfColumnConfig.forEach((column: Partial<IColumn>) => {
      column.editable = false;

      column['formatter'] = pickFormatter(column);
      column['renderer'] = pickRenderer(column);
    });

    return copyOfColumnConfig;
  };

  type WIPFormatterData = {
    rowData: any;
    rowIndex: number;
    column: Partial<IColumn>;
    columnKey: string;
  };

  const pickRenderer = (column: Partial<IColumn>) => {
    switch (column.key) {
      case 'productName':
        return (data: WIPFormatterData) => (
          <DKTooltipWrapper
            content={data.rowData.productName || '-'}
            tooltipClassName="bg-deskera-secondary "
          >
            <DKLabel
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 120
              }}
              text={data.rowData.productName || '-'}
              className="text-align-left white-space-nowrap fs-m"
            />
          </DKTooltipWrapper>
        );
    }
  };

  const pickFormatter = (column: Partial<IColumn>) => {
    switch (column.key) {
      case 'type':
        return (data: WIPFormatterData) =>
          ConsumeProduceHelper.getTypeOfProduct(data.rowData.type);
      case 'allocatedQty':
        return (data: WIPFormatterData) =>
          NumberFormatService.getNumber(data.rowData.allocatedQty);
      case 'consumedQty':
        return (data: WIPFormatterData) =>
          NumberFormatService.getNumber(data.rowData.consumedQty);
      case 'producedQty':
        return (data: WIPFormatterData) =>
          NumberFormatService.getNumber(data.rowData.producedQty);
      case 'wastageQty':
        return (data: WIPFormatterData) =>
          NumberFormatService.getNumber(data.rowData.wastageQty);
      default:
    }
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center">
        No WIP consumption/produciton details available
      </div>
    );
  };

  const getConsumptionView = () => {
    return (
      <ConsumeAndProduceSummaryPopup
        onCancel={() => {
          setShowConsumptionPopup(false);
        }}
        workOrderData={props.workOrderData}
        consumptionSummary={props.consumptionSummaryResponse}
        refreshConsumptionSummary={() => props.refreshConsumptionSummary()}
      />
    );
  };

  const hasObjChanged = hasWorkOrderItemsChanged(
    props.workOrderData?.workOrderItems,
    props.workOrderData?.workOrderItemsClonedCopy
  );

  const isWipInventoryAccountAdded = !Utility.isEmpty(
    props.workOrderData?.product?.inventory?.wipInventoryAccountCode
  );

  const gridColumns = getColumnConfig();
  const gridRows = deepClone(rows);
  const wipListContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(wipListContainerRef);
  const containerMaxHeight = 640;
  const gridWidth = (width || 500) - 36;
  const gridMaxHeight = containerMaxHeight - 70;

  return (
    <div
      className="column bg-white border-m border-radius-m p-l parent-width"
      ref={wipListContainerRef}
      style={{
        width: props.isEditMode ? '49.5%' : '100%',
        height: 'auto',
        minHeight: 200,
        maxHeight: containerMaxHeight,
        flexShrink: 0
      }}
    >
      <div className="row justify-content-between mb-s">
        <DKLabel
          text="WIP Consumption And Production"
          className="fw-m text-app-color"
        />
        {!props.isReadOnlyMode ? (
          <DKButton
            title={`+ Add Consumption / Production`}
            style={{
              padding: 4,
              paddingLeft: 6,
              paddingRight: 6,
              borderRadius: 5
            }}
            className="fw-m text-app-color"
            onClick={() => {
              if (hasObjChanged) {
                showAlert(
                  'Warning !!!',
                  'There has been an update in your current Work Order, Please save your current Work Order to update WIP consumption'
                );
              } else if (!isWipInventoryAccountAdded) {
                showAlert(
                  'Warning !!!',
                  `You have not provided <b> WIP Inventory Account </b> for this product.`
                );
              } else {
                setShowConsumptionPopup(!showConsumptionPopup);
              }
            }}
          />
        ) : null}
      </div>
      {Utility.isEmpty(gridRows) ? (
        renderEmptyState()
      ) : (
        <DKDataGrid
          width={gridWidth}
          styles={{
            mainGridHolder: { marginBottom: -18 },
            gridScrollHolder: { maxHeight: gridMaxHeight },
            shadowHolder: { maxHeight: gridMaxHeight }
          }}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowBulkOperation={false}
          columns={gridColumns}
          rows={gridRows}
        />
      )}
      {showConsumptionPopup && getConsumptionView()}
    </div>
  );
};

export default WIPConsumeProduceList;
