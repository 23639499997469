import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager"
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { getDateFrom, getDateString, getIsInvoice, getIsSalesOrder } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";
export class StockRequestTableItem {
    lineNumber: number;
    code: string;
    name: string;
    description: string;
    quantity: string;
    targetWarehouseName: string;
    targetWarehouseCode: string;
    selectedRow: string;
    selectedRack: string;
    selectedBin: string;
    requestedQty: string;
    uom: string;
    productCustomField: string;

    currency: string;
    rawItemValue: any;
    data: any;
    constructor(lineItem: any, data: any, currency: string) {
        this.currency = currency
        this.lineNumber = lineItem.lineNumber
        this.code = lineItem.code
        this.name = this.getProductName(data, lineItem)
        this.description = this.getProductDescription(lineItem)
        this.quantity = this.getProductQty(lineItem)
        this.targetWarehouseName = lineItem.targetWarehouseName
        this.targetWarehouseCode = lineItem.targetWarehouseCode
        this.selectedRow = lineItem.selectedRow
        this.selectedRack = lineItem.selectedRack
        this.selectedBin = lineItem.selectedBin

        this.requestedQty = this.getRequestedQty(lineItem)
        this.uom = lineItem.uom ?? ''
        this.productCustomField = ''

        this.rawItemValue = lineItem;
        this.data = data
    }

    getProductName(data: any, lineItem: any): string {
        var name = ''
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    name = getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        if (lineItem.name !== undefined && lineItem.name !== null) {
            name = getLocalisedText(lineItem.name)
        }

        if(lineItem.isParent) {
            name = name + ' (Parent)'
        } 
        return name
    }

    getProductDescription(lineItem: any): string {
        var desc = ''
        if (lineItem.description !== undefined && lineItem.description !== null) {
            desc = getLocalisedText(lineItem.description) 
        }
        return desc
    }

    getProductQty(lineItem: any): string {
        return isNaN(parseFloat(lineItem.quantity)) ? '' : Utility.toDecimalFormat(parseFloat(lineItem.quantity), TemplateSettingsManager.getQuantityDecimalScale())
    }

    getRequestedQty(lineItem: any): string {
        return isNaN(parseFloat(lineItem.requestedQty)) ? '' : Utility.toDecimalFormat(parseFloat(lineItem.requestedQty), TemplateSettingsManager.getQuantityDecimalScale())
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        const isCogsColumn = getIsInvoice(this.data?.type) || getIsSalesOrder(this.data?.type);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var footerList = TableFooterUtility.getFooterListByType('product')
                if (isCogsColumn) {
                    var index = footerList.indexOf(TableColumnPopupType.totalAmount);
                    footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
                }
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.productNameWithDescription:
                return '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description);
            case TableColumnPopupType.quantity:
                return this.quantity
            case TableColumnPopupType.targetWarehouseName:
                return this.targetWarehouseName ?? ''
            case TableColumnPopupType.targetWarehouseCode:
                return this.targetWarehouseCode ?? ''
            case TableColumnPopupType.selectedRow:
                return this.selectedRow ?? ''
            case TableColumnPopupType.selectedRack:
                return this.selectedRack ?? ''
            case TableColumnPopupType.selectedBin:
                return this.selectedBin ?? ''
            case TableColumnPopupType.requestedQty:
                return this.requestedQty ?? ''
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        if (cfType === "DATE") {
                            value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                        } else {
                            value = field.value
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
        
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description),
            Utility.toDecimalFormat(parseFloat(this.quantity), TemplateSettingsManager.getQuantityDecimalScale()),
            this.targetWarehouseName ?? '',
            this.selectedRow ?? '',
            this.selectedRack ?? '',
            this.selectedBin ?? '',
            this.uom ?? '',
            this.productCustomField,
        ]
    }
}
