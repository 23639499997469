import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { subDays } from 'date-fns';
import { DKTooltipWrapper } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  GST_TYPE,
  LABELS,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  STATUS_TYPE,
  TAX_SYSTEM,
  UOM_NA_ID
} from '../../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import { billInitialState } from '../../Models/Bill';
import { DraftTypes } from '../../Models/Drafts';
import { RequisitionOrder } from '../../Models/RequisitionOrder';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { fetchProductsWithVariants } from '../../Redux/Slices/ProductsSlice';
import { fetchOrders } from '../../Redux/Slices/PurchaseOrdersSlice';
import DateFormatService from '../../Services/DateFormat';
import PriceListService from '../../Services/PriceList';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import RequisitionService from '../../Services/Requisition';
import { DocumentItem } from '../../Models/DocumentItem';
import {
  fetchContacts,
  selectContacts
} from '../../Redux/Slices/ContactsSlice';
import {
  getRequisitionBody,
  getUpdatedRequisitonOrderObject,
  showConversionErrorMessage
} from './RequisitionHelper';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { BtnType, PopupClickActionType } from '../../Models/Interfaces';
import {
  fetchTaxes,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  selectProductCustomFields,
  selectPurchaseTax,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import { fetchOrders as fetchRequisition } from '../../Redux/Slices/RequisitionSlice';
import AttachmentService from '../../Services/Attachment';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import {
  default as OrderService,
  default as PurchaseOrderService
} from '../../Services/PurchaseOrder';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { ItemTaxCalculator } from './../../SharedComponents/DocumentForm/ItemTaxCalculator';
import {
  convertToCurrenctExchangeRate,
  getNewCFItem,
  getTenantTaxSystem,
  updateRowDataWithParentCFValues
} from './../../SharedComponents/DocumentForm/NewDocumentHelper';

interface RequisitionToPurchaseOrderProps {
  poDocument: RequisitionOrder;
  closePopup: () => void;
  closeParentDoc?: () => void;
  documentMode?: DOCUMENT_MODE;
  gridSize: number;
}

enum POConversionAction {
  PARTIAL_BILL = 'partial_bill',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_RECEIVED = 'convert_and_received'
}

export default function RequisitionToPurchaseOrderPopup(
  props: RequisitionToPurchaseOrderProps
) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const uomsData = useAppSelector(selectUOMs);

  const { t } = useTranslation();
  const [po, setPo] = useState(props.poDocument);
  const requisitionDate = DateFormatService.getDateFromStr(
    props.poDocument.purchaseRequestDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const requisitionReceiveByDate = DateFormatService.getDateFromStr(
    props.poDocument.receiveByDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const [documentDate, setDocumentDate] = useState<Date>(requisitionDate);
  const [receiveByDate, setreceiveByDate] = useState<Date>(
    requisitionReceiveByDate
  );

  const reqDueDate = DateFormatService.getDateFromStr(
    props.poDocument.shipByDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const [dueDate, setDueDate] = useState<Date>(reqDueDate);
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const [billDate, setBillDate] = useState<Date>(
    DateFormatService.getDateFromStr(
      props.poDocument.purchaseRequestDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    )
  );
  const [showMultiCurrecyWarn, setShowMultiCurrecyWarn] = useState(false);
  const dispatch = useAppDispatch();
  const [convertedDetails, setconvertedDetails] = useState<any[]>([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState<any[]>([]);
  const [isEnable, setIsEnable] = useState(false);
  const [isAllRowSelected, setIsAllRowSelected] = useState(false);

  const [productRows, setProductRows] = useState<any[]>([]);
  const [contactData, setContactData] = useState<DocumentItem[]>([]);

  //const [productRows, setProductRows] = useState<DocumentItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const contactsData = useAppSelector(selectContacts);
  const [contact, setContact] = useState<any>();
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const [ConvertPoScreenOpen, setconvertPoScreenOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [attachmentIds, setAttachmentsIds] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [showDefaultOption, setShowDefaultOption] = useState(false);
  const [defaultContactValue, setDefaultContactValue] = useState<any>({});
  const productCFfromStore = useAppSelector(selectProductCustomFields);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const availableCustomFields = useAppSelector(selectCustomFields);
  const [globalCFCustomFields, setGlobalCFCustomFields] = useState<any[]>([]);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);

  useEffect(() => {
    loadRequisitionDetails(po.purchaseRequestCode);
    if (!contactsData?.content) {
      dispatch(fetchContacts());
    }
    fetchProductList(po.items);
  }, []);
  useEffect(() => {
    if (!purchaseTaxes?.length) {
      dispatch(fetchTaxes());
    }
  }, []);
  useEffect(() => {
    dispatch(
      fetchProductsWithVariants({
        searchTerm: '',
        query: 'active=true,hasVariants=false'
      })
    );
  }, []);
  useEffect(() => {
    const unitPrice = productRows.filter((x: any, index: any) => {
      if (index === 0) {
        return x.unitPrice;
      }
    });
    setPo((prevState: any) => ({
      ...prevState,
      items: productRows,
      contact: productRows.filter((x: any, index: any) => {
        if (index === 0) {
          return x.contact;
        }
      }),
      unitPrice: unitPrice,
      uomUnitPrice: unitPrice,
      purchaseRequestItems: productRows
    }));
  }, [productRows]);

  useEffect(() => {
    if (!Utility.isEmpty(po?.attachments)) {
      AttachmentService.attachmentConfig = {
        ...AttachmentService.attachmentConfig,
        Module: MODULES_NAME.PURCHASE_REQUISITION,
        EntityId: po.id
      };
      AttachmentService.getAllAttachments()
        .then((res: any) => {
          let attachmentIds: any[] = [];
          res &&
            res.length &&
            res.forEach((attachment: any) => {
              attachmentIds.push(attachment.attachmentId);
            });
          setAttachmentsIds(attachmentIds);
          setAttachments(attachmentIds.map(String));
        })
        .catch((err: any) => {
          console.error(err);
        });
      loadRequisitionDetails(po.purchaseRequestCode);
    }
  }, []);

  useEffect(() => {
    let order = {
      ...po,
      purchaseOrderItems: po.purchaseRequestItems?.map((item: any) => {
        return {
          ...item,
          advancedTracking: item?.product?.advancedTracking,
          receivedQuantityInBills: item.receivedQuantityInBills || 0,
          receivedQuantityInOrder: item.receivedQuantityInOrder || 0
        };
      })
    };
    const updatedPO = {
      ...po,
      purchaseOrderItems: order.purchaseOrderItems,
      items: order.purchaseOrderItems
    };
    if (
      updatedPO.dropShip ||
      (updatedPO.receiveGoodsStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
        !updatedPO.hasPartialBill)
    ) {
      preparePOForConversion(POConversionAction.CONVERT_ONLY);
    }
    setPo(updatedPO);
  }, []);

  useEffect(() => {
    if (po.currencyCode !== tenantInfo.currency) {
      setShowMultiCurrecyWarn(true);
    }
  }, [po.currencyCode, tenantInfo.currency]);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  useEffect(() => {
    if (
      !Utility.isEmpty(availableCustomFields) &&
      availableCustomFields?.content?.length
    ) {
      let tempGlobalCustomField = [...availableCustomFields?.content];
      tempGlobalCustomField = tempGlobalCustomField.filter(
        (field) =>
          field.modules.includes('REQUISITION') &&
          field.modules.includes('ORDER')
      );
      setGlobalCFCustomFields(tempGlobalCustomField);
    }
  }, [availableCustomFields]);

  const getCustomFieldRowConfig = (
    tempProductRow: any,
    requisitionCustomField: any
  ) => {
    let tempCustomFieldList: any[] = [];
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];

      if (!Utility.isClassRowType()) {
        prodCFs = prodCFs.filter((field) => field.label !== 'CLASS');
      }

      let customFieldList: any[] = [];
      prodCFs?.forEach((currCustomField: any) => {
        const customfieldRow = {
          label: currCustomField?.label,
          shortName: currCustomField?.shortName,
          code: currCustomField?.code,
          module: currCustomField?.module,
          value: '',
          id: currCustomField?.id,
          fieldType: currCustomField?.fieldType
        };
        customFieldList.push(customfieldRow);
      });

      tempCustomFieldList = customFieldList.map((fieldData) => {
        let val =
          tempProductRow?.customField?.find(
            (fdata: any) => fdata.id === fieldData.id
          )?.value || '';
        return { ...fieldData, value: val };
      });

      tempCustomFieldList?.forEach((field: any) => {
        const colName: any = field.id + '' || '';
        if (colName !== '') {
          let tempRow: any = {};
          tempRow[colName] =
            field?.fieldType === 'DATE'
              ? DateFormatService.getDateFromStr(
                  field.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                )
              : field.value;

          tempProductRow = {
            ...tempProductRow,
            ...tempRow,
            customField: tempCustomFieldList
          };
        }
      });
    }

    if (
      !Utility.isEmpty(availableCustomFields) &&
      availableCustomFields?.content?.length
    ) {
      let globalCFs = [...availableCustomFields?.content];
      globalCFs = globalCFs.filter(
        (field) =>
          field.modules.includes('REQUISITION') &&
          field.modules.includes('ORDER')
      );

      if (Utility.isClassRowType()) {
        globalCFs = globalCFs.filter((field) => field.label !== 'CLASS');
      }

      let customFieldList: any[] = [];
      globalCFs?.forEach((currCustomField: any) => {
        const customfieldRow = {
          label: currCustomField?.label,
          shortName: currCustomField?.shortName,
          code: currCustomField?.code,
          module: currCustomField?.module,
          value: '',
          id: currCustomField?.id,
          fieldType: currCustomField?.fieldType
        };
        customFieldList.push(customfieldRow);
      });

      customFieldList.forEach((fieldData) => {
        let val =
          requisitionCustomField?.find(
            (fdata: any) => fdata.id === fieldData.id
          )?.value || '';

        let filteredCF: any = selectCustomFieldsData?.content?.find(
          (filteredCf: any) => filteredCf.code === fieldData.code
        );

        if (filteredCF.fieldType === 'USER') {
          val = filteredCF?.attributes?.find(
            (value: any) => value.code === val
          )?.value;
        }

        tempCustomFieldList.push({ ...fieldData, value: val });
      });

      tempCustomFieldList?.forEach((field: any) => {
        const colName: any = field.id + '' || '';
        if (colName !== '') {
          let tempRow: any = {};
          tempRow[colName] =
            field?.fieldType === 'DATE'
              ? DateFormatService.getDateFromStr(
                  field.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                )
              : field.value;
          tempProductRow = {
            ...tempProductRow,
            ...tempRow,
            customField: tempCustomFieldList
          };
        }
      });
    }
    return tempProductRow;
  };
  const getCustomFieldColumnConfig = (columnConfigData: any) => {
    const tempColumnConfig = [...columnConfigData];
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];

      if (!Utility.isClassRowType()) {
        prodCFs = prodCFs.filter((field) => field.label !== 'CLASS');
      }
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      prodCFs = prodCFs.filter((item) =>
        tenantInfo?.additionalSettings?.REQUISITION_TO_PO_PRODUCT_CUSTOM_FIELD_SETTING?.includes(
          item.id
        )
      );
      prodCFs?.forEach((currCustomField: any) => {
        if (!Utility.isEmpty(currCustomField)) {
          const tempColumn = getNewCFItem(
            currCustomField,
            currCustomField,
            true
          );
          tempColumnConfig.push(tempColumn);
        }
      });
    }

    if (
      !Utility.isEmpty(availableCustomFields) &&
      availableCustomFields?.content?.length
    ) {
      let tempGlobalCustomField = [...availableCustomFields?.content];
      tempGlobalCustomField = tempGlobalCustomField.filter(
        (field) =>
          field.modules.includes('REQUISITION') &&
          field.modules.includes('ORDER')
      );

      if (Utility.isClassRowType()) {
        tempGlobalCustomField = tempGlobalCustomField.filter(
          (field: any) => field.label !== 'CLASS'
        );
      }

      tempGlobalCustomField?.forEach((currCustomField: any) => {
        if (!Utility.isEmpty(currCustomField)) {
          currCustomField = {
            ...currCustomField,
            label: `${currCustomField.label}(Requisition)`
          };
          const tempColumn = getNewCFItem(
            currCustomField,
            currCustomField,
            true
          );
          tempColumnConfig.push(tempColumn);
        }
      });
    }
    return tempColumnConfig;
  };

  const getDataGridColumns = () => {
    let columns = [...defaultColumns];
    columns = getCustomFieldColumnConfig(columns);
    return columns;
  };

  const updateTaxList = () => {
    let taxData = purchaseTaxes;
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          documentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          documentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          documentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  const getDefaultTax = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG || taxSystem === TAX_SYSTEM.MALAYSIA) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return true;
          } else {
            return false;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };
  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = null;
      taxCode = product.purchaseTaxCode;
      tax = purchaseTaxes.find((tax: any) => tax.code === taxCode);
      tax = getDefaultTax(tax);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
    return tax;
  };

  const multiFetchPurchaseOrderDetails = () => {
    if (!isEmpty(purchaseOrderList)) {
      purchaseOrderList.map((code: any) => {
        fetchPurchaseOrderDetails(code);
      });
    }
  };
  const addConvertedDetails = (
    productCode: any,
    price: any,
    contact: any,
    documentCode: any,
    currencyCode: any,
    productCustomField: any,
    poCustomField: any,
    taxPreference?: any,
    tax?: any
  ) => {
    setconvertedDetails([
      productCode,
      price,
      contact,
      documentCode,
      currencyCode,
      productCustomField,
      poCustomField,
      taxPreference,
      tax
    ]);
  };
  const fetchPurchaseOrderDetails = (orderCode: any) => {
    PurchaseOrderService.fetchOrderDetails(orderCode).then(
      (data: any) => {
        const contactName = data.contactDto;
        data.purchaseOrderItems?.map(async (item: any) => {
          await addConvertedDetails(
            item.productCode,
            item.unitPrice,
            contactName,
            data.documentSequenceCode,
            data.currencyCode,
            item.customField,
            data.customField,
            item.taxPreference,
            item.tax
          );
        });
      },
      (err: any) => {
        console.error('Error while fetching document details: ', err);
      }
    );
  };

  const listOfConvertedLines = (linkedPurchaseOrders: any) => {
    linkedPurchaseOrders?.map((order: any) => {
      purchaseOrderList.push(order.purchaseOrderCode);
    });
  };

  const loadRequisitionDetails = async (code: any) => {
    showLoader('Fetching requisition details...');
    try {
      const data = await RequisitionService.fetchOrderDetails(code);

      const pODetailsData: any = getUpdatedRequisitonOrderObject(data);
      if (!isEmpty(pODetailsData.linkedPurchaseOrders)) {
        listOfConvertedLines(pODetailsData.linkedPurchaseOrders);
        multiFetchPurchaseOrderDetails();
      }

      const rData: DocumentItem[] = pODetailsData.items;
      setDocumentDate(
        DateFormatService.getDateFromStr(
          pODetailsData.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
      let pdata = rData.map((item: any) => {
        const df: any = uomsData.filter((d: any) => {
          return d.id === item.documentUom;
        });

        const unitPrice = getUomPrice(
          item.product.purchasePrice,
          item.documentUOMSchemaDefinition
        );

        let receiptStatus = RECEIVED_GOODS_STATUS.NOT_RECEIVED;
        if (item.productConvertedQuantity === item.productQuantity) {
          receiptStatus = RECEIVED_GOODS_STATUS.FULLY_RECEIVED;
        } else if (
          item.productConvertedQuantity > 0 &&
          item.productConvertedQuantity < item.productQuantity
        ) {
          receiptStatus = RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED;
        }

        if (item?.documentUOMSchemaDefinition) {
          item.productQuantity = item?.uomQuantity || 0;
        }

        if (
          item.productConvertedQuantity &&
          item.productConvertedQuantity > 0
        ) {
          item.productQuantity =
            item.productQuantity - item.productConvertedQuantity;
          item.productQuantity = Utility.roundOffToTenantDecimalScale(
            item.productQuantity
          );
        }
        return {
          ...item,
          symbol: pODetailsData.currencyCode,
          unitPrice: unitPrice,
          uomUnitPrice: unitPrice,
          documentUmoName: df[0]?.name,
          receipt_status: [receiptStatus],
          allowRowEdit:
            receiptStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED
              ? false
              : true
        };
      });

      pdata = pdata?.map((pdataItem: any) =>
        getCustomFieldRowConfig(pdataItem, pODetailsData.customField)
      );

      setProductRows(pdata);
      setPo(pODetailsData);
    } catch (err) {
      console.error('Error while fetching requisition details: ', err);
      showAlert('Error!', 'Failed to load requisition details');
    } finally {
      removeLoader();
    }
  };

  const processUpdatePricingValue = (
    productsToUpdate: any[],
    exchangeRate: number,
    priceListResponse: any
  ) => {
    let tmpProductRows = productRows;
    const previousExchangeRate = 1;
    const currentExchangeRate = tmpProductRows[currentIndex].exchangeRate;

    const price = isEmpty(priceListResponse)
      ? 0
      : convertToCurrenctExchangeRate(
          currentExchangeRate,
          previousExchangeRate,
          priceListResponse[0].price
        );

    tmpProductRows[currentIndex].unitPrice = price;
    tmpProductRows[currentIndex].uomUnitPrice = price;

    const columnKey = 'unitPrice';
    const rowData = tmpProductRows[currentIndex];
    const rowIndex = currentIndex;
    onRowUpdate({ columnKey, rowData, rowIndex });
  };
  const showPriceListAlert = (
    productsPayload: any[],
    exchangeRate: number,
    priceListResponse: any
  ) => {
    const alertButtonConfig = [
      {
        title: "Don't update",
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Update',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          processUpdatePricingValue(
            productsPayload,
            exchangeRate,
            priceListResponse
          );
        }
      }
    ];
    let tmpProductRows = productRows;
    const previousPrice = +tmpProductRows[currentIndex].unitPrice;
    const currentPrice =
      +priceListResponse[0].price === 0
        ? previousPrice
        : priceListResponse[0].price;
    if (previousPrice !== currentPrice) {
      showAlert(
        'Update Unit price',
        'Do you want to update all unit prices to follow the Price List of the new Requisition date?',
        alertButtonConfig
      );
    }
  };
  const getPricing = async (
    productsPayload: any[],
    // lineItems: any[],
    contactCode: string,
    //updateExistingItem = true,

    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any
  ) => {
    if (
      productsPayload.length &&
      po.currencyCode &&
      documentDate
      //&&isPriceListEnabled()
    ) {
      if (Utility.isEmpty(contactCode)) {
        return;
      }
      let payload = {
        contactCode: contactCode ? contactCode : '',
        priceListPricingItems: productsPayload,
        type: MODULE_NAME_FOR_STORAGE_UTILITY.BUY,
        currency: po.currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );

        if (productsPayload.length && po.currencyCode && documentDate) {
          showPriceListAlert(
            productsPayload,

            exchangeRate as number,
            priceListResponse
          );
        }
        // else {
        //   processUpdatePricingValue(
        //     productsPayload,
        //     lineItems,
        //     exchangeRate as number,
        //     priceListResponse,
        //     updateExistingItem,
        //     shippingInfo
        //   );
        // }
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };
  const convertToPartialBill = (selectedOrder: RequisitionOrder) => {
    let bill: any = {
      ...billInitialState,
      ...selectedOrder
    };

    let itemsList;
    if (bill.buyType === BUY_TYPE.EXPENSE) {
      itemsList = Utility.getValue(bill.items, bill.purchaseInvoiceAccounts);
    } else {
      itemsList = Utility.getValue(
        bill.items,
        bill.purchaseInvoiceItems,
        bill.purchaseInvoiceProducts
      );
    }
    itemsList = itemsList ? itemsList : [];

    bill = {
      ...bill,
      documentType: DOC_TYPE.BILL,
      isPartialBill: true,
      isPartialInvoice: true,
      roundOffAmountInDocumentCurrency: 0,
      documentCode: Utility.getValue(
        bill.documentCode,
        bill.purchaseInvoiceCode
      ),
      receivedComplete: bill.receivedComplete || false,
      billDue: Utility.getValue(
        bill.dueDate,
        bill.purchaseInvoiceDueDate,
        bill.validTillDate
      ),
      documentDate: Utility.getValue(
        bill.documentDate,
        bill.purchaseInvoiceDate
      ),
      validTillDate: Utility.getValue(
        bill.validTillDate,
        bill.purchaseInvoiceDueDate
      ),
      fulfillmentDate: Utility.getValue(
        bill.fulfillmentDate,
        bill.receiveByDate
      ),
      receiveByDate: bill.fulfillmentDate,
      purchaseInvoiceDueDate: bill.validTillDate,
      purchaseInvoiceType: bill.buyType,
      supplierInvoiceNo: bill.taxInvoiceNo,
      purchaseInvoiceDate: bill.documentDate,
      attachments: bill.entityId ? bill.attachments : [],
      attachmentIds: bill.entityId ? bill.attachments?.map(Number) : [],
      isConverting: true,
      contact: {
        ...bill.contact,
        ...bill.contactDto,
        address: Utility.getStringAddress(bill.contactDto)
      },
      contactCode: bill.contactDto.code,
      currency: bill.currency,
      receiveGoodsStatus:
        bill.receiptStatus ||
        bill.receiveGoodsStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      buyType: Utility.getValue(bill.buyType, bill.purchaseInvoiceType),
      status: !bill.id ? DOCUMENT_STATUS.OPEN : bill.status,
      gstin: bill.gstin,
      dropShip: bill.dropShip ? bill.dropShip : false,
      placeOfSupply: bill.placeOfSupply,
      isCreditable: bill.isCreditable,
      taxInvoiceNo: bill.taxInvoiceNo,
      customerType: bill.customerType,
      vendorType: bill.vendorType,
      openingInvoice: bill.openingInvoice,
      customField: bill.customField,
      paymentStatus: bill.paymentStatus,
      whtRate: bill.whtRate && bill.whtRate !== null ? bill.whtRate : 0,
      purchaseInvoiceProducts: itemsList
        .filter(
          (item: any) =>
            (item.pendingQuantity !== undefined && item.pendingQuantity > 0) ||
            (item.product?.type === PRODUCT_TYPE.NON_TRACKED &&
              item.product.stockUom === UOM_NA_ID)
        )
        .map((item: any) => {
          return {
            ...item,
            id: null,
            productName: item.product.name, // productName does not in PO detail API response, so adding it
            isPartialBill: true,
            isPartialInvoice: true,
            documentItemCode: null,
            fulfillmentByDoc: null,
            linkedOrderItem: item.id,
            productQuantity: item.pendingQuantity,
            unitPriceGstInclusive: bill.unitPriceGstInclusive,
            exchangeRate: bill.exchangeRate,
            unitPrice:
              bill.buyType === BUY_TYPE.EXPENSE
                ? item.amount || item.unitPrice
                : item.unitPrice,
            taxDetails: item.taxDetails.map((taxDetail: any) => {
              return {
                ...taxDetail,
                id: null,
                additionalTaxIn: null
              };
            })
          };
        }),
      linkedDocuments: [
        {
          documentCode: bill.documentCode as string,
          documentType: bill.documentType,
          documentSequenceCode: bill.documentSequenceCode as string
        }
      ],
      tdsInfoIndia: bill.tdsInfoIndia,
      tdsProcessedFlag: bill.tdsProcessedFlag,
      approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
      multiApprovalDetails: null
    };

    const {
      contactDto,
      poCode,
      purchaseOrderItems,
      documentSequenceCode,
      ...billToSave
    } = bill;

    let payloadData: any = {
      type: LABELS.BILLS,
      title: LABELS.BILLS,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: billToSave,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(billToSave)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const multiConversion = async (orders: any[]) => {
    OrderService.createBulkOrder(
      orders,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    )
      .then((res) => {
        dispatch(fetchRequisition());
        dispatch(fetchOrders());

        removeLoader();
        const buttons = [
          {
            title: 'Ok',
            className: 'bg-button, border-m',
            onClick: () => {}
          },
          {
            title: 'Goto Purchase Orders',
            className: ' bg-blue text-white ml-r',
            onClick: () => {
              RouteManager.navigateToPage(PAGE_ROUTES.ORDERS);
            }
          }
        ];
        if (res?.failure?.length > 0) {
          showConversionErrorMessage(res);
        } else {
          if (res.success && res.success.length > 0 && res.success[0].draft) {
            showAlert('Purchase order sent for approval!', '', buttons);
          } else {
            showAlert(
              'Requisition converted!',
              'Requisition has been converted to Purchase Orders successfully.',
              buttons
            );
          }
        }

        if (typeof props.closePopup !== 'undefined') {
          props.closePopup();
        }
      })
      .catch((err) => {
        showAlert('Error!', err?.data?.errorMessage || '');
        removeLoader();
        console.log('getting error', err);
        return false;
      });
  };

  const convertToPO = (
    selectedOrder: RequisitionOrder,
    autoReceive: boolean
  ) => {
    let selectedItems = po.items?.filter((x: any) => {
      return x.selected && x?.allowRowEdit !== false;
    });

    if (Utility.isEmpty(selectedItems)) {
      let tmpRows = productRows
        .filter((x: any) => {
          return x.selected;
        })
        .map((item: any) => {
          return item.id;
        });
      if (tmpRows && tmpRows.length > 0) {
        selectedItems = po.items?.filter((item: any) =>
          tmpRows.includes(item.id)
        );
      }
    }

    selectedItems?.forEach((item: any) => {
      if (Utility.isEmpty(item.contact)) {
        let row = productRows.filter((x: any) => {
          return x.id === item.id;
        });
        if (row && row.length > 0) {
          item.contact = row[0].contact;
        }
      }
    });

    if (selectedItems && selectedItems?.length > 0) {
      selectedItems?.forEach((document: any) => {
        if (document.product) {
          if (
            document.product.customField &&
            document.product.customField !== null
          ) {
            document.customField = document.product.customField;
          }
        }
      });
    }

    const group = selectedItems?.reduce((acc: any, item: any) => {
      if (!acc[item.contact?.code]) {
        acc[item.contact?.code] = [];
      }

      acc[item.contact?.code].push(item);
      return acc;
    }, {});

    const ordersPayload: any[] = [];
    Object.entries(group).map((item: any) => {
      const supplierAddress = item[1][0]?.contact.shippingAddress[0];
      const contactCurrency = activeMultiCurrencyList.find(
        (currency: any) =>
          currency.currencyCode === item[1][0]?.contact.currencyCode
      );
      function amount(item: any) {
        return item.totalAmount;
      }

      function sum(prev: any, next: any) {
        return prev + next;
      }

      const totalAmount = item[1].map(amount).reduce(sum);
      const currItem = item[1][0];
      let tempProductCustomFields = productCustomFields;
      let payload = {
        attachments: attachments,
        attachmentIds: attachmentIds,
        receiptStatus: RECEIVED_GOODS_STATUS.NOT_RECEIVED,
        currencyCode: item[1][0]?.contact.currencyCode,
        exchangeRate: contactCurrency.currencyExchangeRate,
        deliveryDate: DateFormatService.getDateStrFromDate(
          dueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        validTillDate: DateFormatService.getDateStrFromDate(
          dueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        dueDate: DateFormatService.getDateStrFromDate(
          dueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        documentType: DOC_TYPE.ORDER,
        status: 'OPEN',
        memo: po.memo,
        shipByDate: DateFormatService.getDateStrFromDate(
          dueDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        shipFrom: supplierAddress,
        shipTo: po.shipTo,
        billTo: po.billTo,
        linkedDocuments: [
          {
            documentCode: po.documentCode,
            documentSequenceCode: po.documentSequenceCode,
            documentType: 'PURCHASE_REQUEST'
          }
        ],
        purchaseOrderItems: item[1].map((i: any) => {
          let item = {
            ...i,
            unitPrice: +i.unitPrice,
            linkedPRItem: i.id,
            customField: tempProductCustomFields?.map((field: any) => {
              return {
                ...field,
                value:
                  field.fieldType === 'DATE'
                    ? DateFormatService.getDateStrFromDate(
                        new Date(i[field.id + '']),
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      )
                    : i[field.id + ''] || field.value
              };
            })
          };
          delete item.id;

          return item;
        }),
        totalAmount: totalAmount,
        receiveByDate: DateFormatService.getDateStrFromDate(
          receiveByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        orderType: 'Inventory',
        unitPrice: 0,
        vendorCode: item[1][0]?.contact.code,
        vendorType: item[1][0]?.contact.vendorType,
        contact: item[1][0]?.contact,
        unitPriceGstInclusive: false,
        taxSystem: getTenantTaxSystem(),
        customField: globalCFCustomFields?.map((field: any) => {
          return {
            ...field,
            value:
              field.fieldType === 'DATE'
                ? DateFormatService.getDateStrFromDate(
                    new Date(currItem[field.id + '']),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : currItem[field.id + ''] || field.value
          };
        })
      };

      ordersPayload.push(payload);
    });
    multiConversion(ordersPayload);
  };

  const preparePOForConversion = (action: POConversionAction) => {
    let selectedPO = {
      ...po,
      entityId: po.id ? po.id : undefined,
      documentType: DOC_TYPE.ORDER,
      documentCode: po.poCode,
      vendorCode: Utility.getValue(po.vendorCode, po.contactCode),
      buyType: Utility.getValue(po.buyType, po.orderType),
      orderType: po.buyType,
      status: !po.id ? DOCUMENT_STATUS.OPEN : po.status,
      placeOfSupply: po.placeOfSupply,
      reorder: po.reorder || false,
      dropShip: po.dropShip ? po.dropShip : false,
      backOrder: po.backOrder ? po.backOrder : false,
      linkedPurchaseInvoices: po.linkedPurchaseInvoices,
      orderDueDate: Utility.getValue(po.dueDate, po.validTillDate),
      fulfillmentDate: Utility.getValue(po.fulfillmentDate, po.receiveByDate),
      validTillDate: po.dueDate,
      receiveGoodsStatus:
        po.receiveGoodsStatus ||
        po.receiptStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      currency: Utility.getValue(po.currency, po.currencyCode),
      items: po.purchaseRequestItems?.map((item) => {
        return {
          ...item,
          documentItemCode: Utility.getValue(
            item.documentItemCode,
            item.purchaseOrderItemCode,
            item.purchaseInvoiceItemCode
          )
        };
      }),
      hasPartialBill:
        po.linkedPurchaseInvoices && po.linkedPurchaseInvoices.length > 0
    };

    if (po.contact === 'undefined') {
      showAlert('please select contact');
    }
    const nonPendingProducts = Utility.getNonPendingsItems(
      selectedPO.items || []
    );
    if (selectedPO.dropShip) {
      convertToPO(selectedPO, false);
    } else if (
      selectedPO.receiveGoodsStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
      !selectedPO.hasPartialBill
    ) {
      convertToPO(selectedPO, false);
    } else if (
      selectedPO.hasPartialBill &&
      nonPendingProducts.length === selectedPO.items?.length
    ) {
      showAlert(
        '',
        'Sorry, all products in this Order have been billed in full. You cannot convert this Order to Bill anymore'
      );
    } else {
      selectedPO = {
        ...selectedPO,
        documentDate: DateFormatService.getDateStrFromDate(
          billDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
      switch (action) {
        case POConversionAction.CONVERT_AND_RECEIVED:
          convertToPO(selectedPO, true);
          break;
        case POConversionAction.CONVERT_ONLY:
          convertToPO(selectedPO, false);
          break;
        case POConversionAction.PARTIAL_BILL:
          convertToPartialBill(selectedPO);
          break;
        default:
          break;
      }
    }
  };

  const contactList =
    contactsData?.content?.filter(
      (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
    ) || null;

  const [defaultColumns, setdefaultColumns] = useState([
    {
      name: 'Status',
      type: INPUT_TYPE.SELECT,
      index: 3,
      options: [
        {
          id: 'UNRECEIVED',
          name: 'Not Converted',
          color: 'data-grid-badge-color-10'
        },
        {
          id: 'PARTIAL_RECEIVED',
          name: 'Partial Converted',
          color: 'data-grid-badge-color-5'
        },
        {
          id: 'FULLY_RECEIVED',
          name: 'Fully Converted',
          color: 'data-grid-badge-color-6'
        }
        // {
        //   id: 'NOT_APPLICABLE',
        //   name: 'Not Applicable',
        //   color: 'data-grid-badge-color-1'
        // }
      ],
      required: true,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      //systemField: false,
      columnCode: 'receipt_status',
      id: 'receipt_status',
      key: 'receipt_status',
      allowAddOption: false,
      allowFilter: false
    },
    {
      id: 'purchaseDocumentCode',
      key: 'purchaseDocumentCode',
      name: 'PO #',
      index: 5,
      type: INPUT_TYPE.TEXT,
      width: 110,
      systemField: false,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Product',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: true,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'product',
      key: 'product',
      id: 'product',
      formatter: DocumentConfigUtility.productFormatterDocument
    },
    {
      id: 'description',
      key: 'productDescription',
      name: 'Description',
      index: 1,
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: false,
      editable: true,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Quantity',
      type: INPUT_TYPE.NUMBER,
      index: 2,
      options: null,
      required: true,
      width: 100,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'productQuantity',
      id: 'productQuantity',
      key: 'productQuantity',
      textAlign: 'right',
      renderer: DocumentConfigUtility.quantityRenderer
    },

    {
      name: 'Converted Quantity',
      type: INPUT_TYPE.NUMBER,
      index: 2,
      options: null,
      required: true,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'productConvertedQuantity',
      id: 'productConvertedQuantity',
      key: 'productConvertedQuantity',
      textAlign: 'right',
      renderer: DocumentConfigUtility.quantityRenderer
    },
    {
      name: 'Supplier',
      type: INPUT_TYPE.DROPDOWN,
      index: 4,
      options: null,
      required: true,
      width: 120,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'contact',
      id: 'contact',
      key: 'contact',
      formatter: DocumentConfigUtility.productFormatterDocument,
      dropdownConfig: {
        title: 'Select Contact',
        className: 'shadow-m width-auto',
        style: { minWidth: 150 },
        allowSearch: true,
        searchableKey: 'name',
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };
            ContactService.apiConfig = config;
            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        },
        data: contactList ? contactList : [],

        renderer: (index: any, obj: any) => {
          return <DKLabel text={obj.name} />;
        },
        // onSelect: (index: any, obj: any, rowIndex: any) => {}
        onSelect: (index: any, contactObj: any, rowIndex: any) => {
          setContact(contactObj);
        }
      }
    },

    {
      id: 'price',
      key: 'unitPrice',
      name: 'Price',
      type: INPUT_TYPE.NUMBER,
      index: 3,
      options: null,
      required: true,
      width: 120,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      formatter: DocumentConfigUtility.unitPriceFormatterDocument,
      dropdownConfig: {
        title: 'Price History',
        data: [],
        renderer: DocumentConfigUtility.unitPriceRenderer
      }
    },

    // {
    //   id: 'price',
    //   key: 'unitPrice',
    //   name: 'Price',
    //   type: INPUT_TYPE.NUMBER,
    //   width: 141,
    //   systemField: true,
    //   editable: true,
    //   hidden: false,
    //   uiVisible: true,
    //   textAlign: 'right',
    //   formatter: ({ value }: any) => value,
    //   dropdownConfig: {
    //     title: 'Price History',
    //     data: [],
    //     renderer: DocumentConfigUtility.unitPriceRenderer
    //   }
    // },

    {
      id: 'documentUmoName',
      key: 'documentUmoName',
      name: 'UOM',
      index: 4,
      type: INPUT_TYPE.NUMBER,
      width: 90,
      systemField: false,
      editable: false,
      hidden: false,
      uiVisible: true
    }
  ]);

  useEffect(() => {
    updateConfig();
  }, [contactData, selectedProductPriceList]);

  useEffect(() => {
    if (!Utility.isEmpty(productRows)) {
      let tmpProductRows = productRows;
      const convertedProductDetails = tmpProductRows.find((item: any) => {
        return item.productCode === convertedDetails[0];
      });
      const indexNumber = tmpProductRows.findIndex((item: any) => {
        return (
          item.productCode === convertedDetails[0] &&
          !Utility.isEmpty(item.linkedPoCode) &&
          Utility.isEmpty(item?.contact)
        );
      });

      if (!Utility.isEmpty(convertedProductDetails) && indexNumber >= 0) {
        const price = convertedDetails[1];

        tmpProductRows[indexNumber].unitPrice = price;
        tmpProductRows[indexNumber].contact = convertedDetails[2];
        tmpProductRows[indexNumber].customField = convertedDetails[5];
        tmpProductRows[indexNumber] = getCustomFieldRowConfig(
          tmpProductRows[indexNumber],
          convertedDetails[6]
        );
        tmpProductRows[indexNumber].taxPreference = convertedDetails[7]
          ? convertedDetails[7]
          : false;
        tmpProductRows[indexNumber].tax = convertedDetails[8]
          ? convertedDetails[8]
          : null;

        setProductRows(tmpProductRows);
      }
    }
    if (!Utility.isEmpty(convertedDetails)) {
      removeLoading();
    }
  }, [convertedDetails]);

  const updateConfig = () => {
    let config = defaultColumns;
    config.forEach((conf: any) => {
      switch (conf.key) {
        // case 'contact':
        //   let contactDataArray =
        //     contactsData?.content?.length > 0 ? contactData : [];
        //   conf.editable =
        //     (Utility.isDropship(props.poDocument) &&
        //       props.poDocument.documentType === DOC_TYPE.ORDER) ||
        //     props.documentMode === DOCUMENT_MODE.VIEW;
        //   conf.dropdownConfig.data =
        //     contactDataArray.length > 0
        //       ? DocumentConfigUtility.contactDataParser(
        //           { content: contactDataArray },
        //           props.poDocument.documentType
        //         )
        //       : [];
        //   conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
        //     DocumentConfigManager.getProductURL(search, tenantInfo);
        //   conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
        //     DocumentConfigUtility.contactDataParser(
        //       data,
        //       props.poDocument.documentType
        //     );
        //   if (
        //     GranularPermissionsHelper.hasPermissionFor(
        //       PERMISSIONS_BY_MODULE.PRODUCTS.CREATE
        //     )
        //   ) {
        //     conf.dropdownConfig.button.onClick = () =>
        //       setShowProductPopup(true);
        //   } else {
        //     conf.dropdownConfig.button = null;
        //   }
        //   break;
        case 'purchaseDocumentCode':
          conf.renderer = (obj: any) => {
            return !Utility.isEmpty(obj.rowData.linkedPoCode) ? (
              <DKTooltipWrapper
                content={getTootltipContentForRows(obj.rowData)}
                tooltipClassName="bg-deskera-secondary w-80"
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_url}
                    className="mt-s ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel
                    text={obj.rowData.productDescription}
                    className={'fs-m ml-xs'}
                  />
                </div>
              </DKTooltipWrapper>
            ) : null;
          };
          break;
        case 'unitPrice':
          conf.editable = true;

          conf.dropdownConfig.data = selectedProductPriceList;
          break;

        //   case 'customField':
        //     const data = getClassDimensionData();
        //     conf.hidden = hideClassColumn();
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY;
        //     conf.dropdownConfig.data = data?.length ? data : [];
        //     conf.dropdownConfig.button.onClick = () => setShowAddClassPopup(true);
        //     break;
        //   case 'productDescription':
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY;
        //     break;
        //   case 'product':
        //     let productDataArray =
        //       productsData?.content?.length > 0 ? productsData.content : [];
        //     conf.editable =
        //       (Utility.isDropship(props.booksDocument) &&
        //         props.booksDocument.documentType === DOC_TYPE.ORDER) ||
        //       props.documentMode === DOCUMENT_MODE.VIEW ||
        //       props.draftType === DraftTypes.READONLY ||
        //       props.booksDocument.isPartialInvoice ||
        //       props.booksDocument.isPartialBill
        //         ? false
        //         : true;
        //     conf.dropdownConfig.data =
        //       productDataArray.length > 0
        //         ? DocumentConfigUtility.productDataParser(
        //             { content: productDataArray },
        //             props.booksDocument.documentType
        //           )
        //         : [];
        //     conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
        //       DocumentConfigManager.getProductURL(search, tenantInfo);
        //     conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
        //       DocumentConfigUtility.productDataParser(
        //         data,
        //         props.booksDocument.documentType
        //       );
        //     if (
        //       GranularPermissionsHelper.hasPermissionFor(
        //         PERMISSIONS_BY_MODULE.PRODUCTS.CREATE
        //       )
        //     ) {
        //       conf.dropdownConfig.button.onClick = () =>
        //         setShowProductPopup(true);
        //     } else {
        //       conf.dropdownConfig.button = null;
        //     }
        //     break;
        //   case 'tax':
        //     const taxData = Utility.isSalesDocument(props.booksDocument)
        //       ? salesTaxes
        //       : purchaseTaxes;
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY &&
        //       (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
        //         ? true
        //         : GranularPermissionsHelper.hasPermissionFor(
        //             props.permissionKeys.EDIT_TAX
        //           ));
        //     conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
        //     conf.dropdownConfig.data = taxData?.length
        //       ? DocumentConfigUtility.taxDataParser(
        //           { content: taxData },
        //           props.booksDocument.documentType
        //         )
        //       : [];
        //     conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
        //       DocumentConfigManager.getTaxURL(search);
        //     conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
        //       DocumentConfigUtility.taxDataParser(
        //         data,
        //         props.booksDocument.documentType
        //       );
        //     if (
        //       !GranularPermissionsHelper.hasPermissionFor(
        //         PERMISSIONS_BY_MODULE.SETTINGS.TAX
        //       )
        //     ) {
        //       conf.dropdownConfig.button = null;
        //     } else {
        //       conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true);
        //     }

        //     break;
        //   case 'uom':
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY;
        //     conf.dropdownConfig.data = selectedProductUOMS || [];
        //     break;
        //   case 'taxAmount':
        //     conf.hidden = hideTaxAmountColumn();
        //     conf.name =
        //       getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
        //     conf.editable =
        //       !(!hideTaxAmountColumn() && contact && contact.taxExempted) &&
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY &&
        //       (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
        //         ? true
        //         : GranularPermissionsHelper.hasPermissionFor(
        //             props.permissionKeys.EDIT_TAX
        //           ));
        //     conf.type =
        //       !hideTaxAmountColumn() && contact && contact.taxExempted
        //         ? INPUT_TYPE.TEXT
        //         : INPUT_TYPE.NUMBER;
        //     conf.formatter = (data: any) => {
        //       if (!hideTaxAmountColumn() && contact && contact.taxExempted) {
        //         // Tax exempted contact for US
        //         return 'Exempted';
        //       } else {
        //         return DocumentConfigUtility.amountFormatter(
        //           data.value,
        //           booksDocument.currency
        //         );
        //       }
        //     };
        //     break;
        //   case 'totalAmount':
        //     conf.formatter = (data: any) =>
        //       DocumentConfigUtility.amountFormatter(
        //         data.value,
        //         booksDocument.currency
        //       );
        //     break;
        //   case 'discount':
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY &&
        //       (Utility.isEmpty(props.permissionKeys?.EDIT_DISCOUNT)
        //         ? true
        //         : GranularPermissionsHelper.hasPermissionFor(
        //             props.permissionKeys.EDIT_DISCOUNT
        //           ));
        //     conf.formatter = (data: any) => {
        //       const row = productRows[data.rowIndex];
        //       return row?.discountInPercent
        //         ? data.value + '%'
        //         : DocumentConfigUtility.amountFormatter(
        //             data.value,
        //             booksDocument.currency
        //           );
        //     };
        //     break;
        //   case 'unitPrice':
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY &&
        //       (Utility.isEmpty(props.permissionKeys?.EDIT_PRICE)
        //         ? true
        //         : GranularPermissionsHelper.hasPermissionFor(
        //             props.permissionKeys.EDIT_PRICE
        //           ));
        //     conf.formatter = (data: any) =>
        //       DocumentConfigUtility.amountFormatter(
        //         data.value,
        //         booksDocument.currency
        //       );
        //     conf.dropdownConfig.data = selectedProductPriceList;
        //     break;
        //   case 'productQuantity':
        //     conf.editable =
        //       (Utility.isDropship(props.booksDocument) &&
        //         props.booksDocument.documentType === DOC_TYPE.ORDER) ||
        //       props.documentMode === DOCUMENT_MODE.VIEW ||
        //       props.draftType === DraftTypes.READONLY
        //         ? false
        //         : true;
        //     break;
        //   default:
        //     conf.editable =
        //       props.documentMode !== DOCUMENT_MODE.VIEW &&
        //       props.draftType !== DraftTypes.READONLY;
        //     break;
      }
    });
    setdefaultColumns(config.filter((col: any) => !col.hidden));
  };
  const removeLoading = () => {
    setTimeout(() => {
      removeLoader();
    }, 5);
  };

  const getTootltipContentForRows = (rowData: any) => {
    let htmlString = '<div class="column">';
    let total = 0;
    htmlString +=
      '<div class="row parent-width font-semibold">Linked Purchase Order</div>';
    rowData.linkedPoCode?.forEach((item: any) => {
      htmlString += `<div class="row parent-width justify-content-between mt-s"><div class="column">${item}</div>


      </div>`;
    });

    return htmlString;
  };

  const fetchProductList = async (rows?: DocumentItem[]) => {
    const inlineProductsList = rows?.length ? [...rows] : [...productRows];

    let productsIdList: any[] | undefined = [];
    inlineProductsList.forEach((item) => {
      if (!Utility.isEmpty(item.product)) {
        const code = item.product.productId || item.productCode;
        productsIdList?.push(code);
      }
    });
    productsIdList = rows?.map(
      (row: any) => row.product.productId || row.productCode
    );
    // @ts-ignore
    PriceListService.getProductsById(productsIdList).then(
      (productList: any) => {
        const combinedProductList = productList
          .map((item1: any) => {
            const item2 = rows?.find((item) => item.productCode === item1.pid);
            return item2 ? { ...item1, ...item2 } : null;
          })
          .filter(Boolean);

        const purchasePriceList = combinedProductList.map((item: any) => {
          return { id: item.productCode, cost: item.purchasePrice };
        });
        fetchProductUnitPrice(po?.items, purchasePriceList);
      }
    );
  };

  const fetchProductUnitPrice = async (
    rows: DocumentItem[] | undefined,
    purchasePriceList: any[]
  ) => {
    const inlineProductsList = rows?.length ? [...rows] : [...productRows];
    let productCodes: string[] = [];
    inlineProductsList.forEach((item) => {
      if (!Utility.isEmpty(item.product)) {
        const code = item.product.productId || item.productCode;
        productCodes.push(code);
      }
    });

    const contactCode = Utility.isObject(contact) ? contact.code : '';
    if (productCodes.length > 0) {
      try {
        const unitePriceData = await PriceListService.fetchLastPricesOfProduct(
          productCodes,
          DOC_TYPE.ORDER,
          contactCode
        );
        const unitPricesList = unitePriceData.unitPrices;
        const priceList = unitPricesList.map((product: any) => {
          const item2 = purchasePriceList?.find(
            (item) => item.id === product.productCode
          );
          return item2
            ? {
                productCode: product.productCode,
                lastUnitPrices: [...product.lastUnitPrices, item2.cost]
              }
            : null;
        });

        priceList.forEach((product: any) => {
          setProductsLastUnitPrice((prevState: any) => {
            return {
              ...prevState,
              [product.productCode]: product.lastUnitPrices
            };
          });
        });
      } catch (err) {
        console.error('Error fetchin last 3 prices of product: ', err);
      }
    }
  };
  const setPricesForSelectedProduct = (lineItem: any) => {
    if (lineItem && !Utility.isEmpty(productsLastUnitPrice)) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.productCode]
      ]);
    }
  };
  const onRowClick = ({ columnKey, rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    setPricesForSelectedProduct(lineitem);
    let state = false;
    productRows.map((item: any) => {
      if (item.selected) {
        state = true;
      }
    });
    setIsEnable(state);
    setCurrentIndex(rowIndex);
  };
  const onAllRowSelect = ({ selected }: any) => {
    setIsEnable(selected);
    setIsAllRowSelected(selected);
  };

  const onRowUpdate = ({ columnData, columnKey, rowData, rowIndex }: any) => {
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    const tax = getTax(rowData.product);
    switch (columnKey) {
      case 'productDescription':
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'contact':
        if (dataToUpdate?.shippingAddress === null) {
          showAlert('', 'Shipping address is not present on contact.');
          rows[rowIndex][columnKey] = null;
          return;
        }
        if (productRows?.length > 1) {
          setShowDefaultOption(true);
          setDefaultContactValue(rowData.contact);
        }
        const contactCurrency = activeMultiCurrencyList.find(
          (currency: any) =>
            currency.currencyCode === rowData.contact.currencyCode
        );
        const previousExchangeRate = rows[rowIndex].exchangeRate;
        const currentExchangeRate = contactCurrency.currencyExchangeRate;
        const unitPrice = convertToCurrenctExchangeRate(
          currentExchangeRate,
          previousExchangeRate,
          rows[rowIndex].unitPrice
        );
        rows[rowIndex].unitPrice = unitPrice;
        rows[rowIndex].uomUnitPrice = unitPrice;

        rows[rowIndex].previousExchangeRate = previousExchangeRate;
        rows[rowIndex].exchangeRate = currentExchangeRate;

        const priceListPricingItems = [
          {
            productId: rowData.productCode,
            uomId: rowData.documentUom,
            quantity: rowData.productQuantity
          }
        ];
        const contactCode = rowData.contact.code;
        getPricing(priceListPricingItems, contactCode, 1);
        rows[rowIndex][columnKey] = dataToUpdate;

        let gstType: GST_TYPE | undefined = 1;
        let placeOfSupply: any = !Utility.isEmpty(
          rowData.contact.shippingAddress[0]?.placeOfSupply
        )
          ? rowData.contact.shippingAddress[0]?.placeOfSupply
          : rowData.contact.shippingAddress[0]?.state;
        let DestinationState: any = po.shipTo?.state;
        if (Utility.isEmpty(po.shipTo?.destinationOfSupply)) {
          DestinationState = po.shipTo?.state;
        } else {
          DestinationState = po.shipTo?.destinationOfSupply;
        }
        rows[rowIndex].gstType = gstType;
        rows[rowIndex].symbol = rowData.contact.currencyCode;

        if (!isEmpty(tax)) {
          rowData?.nonEditableColumns?.push('tax');
          const percent = tax.percent * 0.01;
          const price = +rowData.unitPrice * 1;
          const quantity = rowData.productQuantity;
          const taxAmount = Number((percent * price).toFixed(2));
          const totalAmount = (taxAmount + price) * quantity;
          rows[rowIndex] = { ...rowData, tax };
          rows[rowIndex].taxAmount = taxAmount;
          rows[rowIndex].taxName = tax.name;
          rows[rowIndex].taxCode = tax.code;
          rows[rowIndex].totalAmount = totalAmount;
          rows[rowIndex].taxSystem = getTenantTaxSystem();
          rows[rowIndex].taxPreference = rowData.product.taxPreference;
        }
        ItemTaxCalculator.item = rows[rowIndex];
        if (!ItemTaxCalculator.item.taxAmount) {
          ItemTaxCalculator.item.taxAmount = 0;
        }
        ItemTaxCalculator.tenantInfo = tenantInfo;
        ItemTaxCalculator.setInitialValues();
        const taxAmount = ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item.taxAmount = taxAmount;
        ItemTaxCalculator.updateCalculatedValues();
        // for landed cost calculation for india only
        // setTotalAmountforLandedCost(rowIndex);
        ItemTaxCalculator.item.amount = ItemTaxCalculator.item.totalAmount || 0;
        rows[rowIndex] = { ...ItemTaxCalculator.item };
        break;
      case 'unitPrice':
        if (!isEmpty(tax)) {
          rowData?.nonEditableColumns?.push('tax');
          const percent = tax.percent * 0.01;
          const price = +rowData.unitPrice * 1;
          const quantity = rowData.productQuantity;
          const taxAmount = Number((percent * price).toFixed(2));
          const totalAmount = (taxAmount + price) * quantity;
          rows[rowIndex] = { ...rowData, tax };
          rows[rowIndex].taxAmount = taxAmount;
          rows[rowIndex].taxName = tax.name;
          rows[rowIndex].taxCode = tax.code;
          rows[rowIndex].totalAmount = totalAmount;
          rows[rowIndex].taxSystem = getTenantTaxSystem();
          rows[rowIndex].taxPreference = rowData.product.taxPreference;
        }
        if (!Utility.isEmpty(rowData.contact)) {
          ItemTaxCalculator.item = rows[rowIndex];
          if (!ItemTaxCalculator.item.taxAmount) {
            ItemTaxCalculator.item.taxAmount = 0;
          }
          ItemTaxCalculator.tenantInfo = tenantInfo;
          ItemTaxCalculator.setInitialValues();
          const taxAmount = ItemTaxCalculator.calculateTaxAmount();
          ItemTaxCalculator.item.taxAmount = taxAmount;
          ItemTaxCalculator.updateCalculatedValues();
          // for landed cost calculation for india only
          // setTotalAmountforLandedCost(rowIndex);
          ItemTaxCalculator.item.amount =
            ItemTaxCalculator.item.totalAmount || 0;
          rows[rowIndex] = { ...ItemTaxCalculator.item };
        }
        break;
      default:
        const customFieldIndex = selectedRows?.customField?.findIndex(
          (field: any) => field.id === +columnKey
        );

        if (customFieldIndex >= 0) {
          if (
            columnData?.type?.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            rows[rowIndex][columnKey] = new Date(dataToUpdate);
            selectedRows.customField[customFieldIndex].value = dataToUpdate;
          } else if (columnData.type === 'dropdown') {
            selectedRows[columnKey] =
              dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
            const filteredCF: any = productCustomFields?.find(
              (field: any) => field.id === +columnKey
            );
            const { rowData } = updateRowDataWithParentCFValues(
              dataToUpdate.value,
              { ...rows[rowIndex] },
              filteredCF,
              productCustomFields
            );
            selectedRows = rowData;
          } else {
            selectedRows[columnKey] = dataToUpdate;
            selectedRows.customField[customFieldIndex].value = dataToUpdate;
          }
        } else {
          const globalCfIndex = globalCFCustomFields.findIndex(
            (field: any) => field.id === +columnKey
          );

          if (globalCfIndex >= 0) {
            if (
              columnData?.type?.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              rows[rowIndex][columnKey] = new Date(dataToUpdate);
            } else if (columnData.type === 'dropdown') {
              selectedRows[columnKey] =
                dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
              const filteredCF: any = globalCFCustomFields?.find(
                (field: any) => field.id === +columnKey
              );
              const { rowData } = updateRowDataWithParentCFValues(
                dataToUpdate.value,
                { ...rows[rowIndex] },
                filteredCF,
                globalCFCustomFields
              );
              selectedRows = rowData;
            } else {
              selectedRows[columnKey] = dataToUpdate;
            }
          }
        }

        rows[rowIndex] = selectedRows;
    }
    setProductRows(rows);
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setconvertPoScreenOpen(false);
        break;
    }
  };
  const convertPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const ConvertPoScreen = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        // title={t(`REQUISITION.CONVERT_TO_BILL.TITLE`)}
        title={t(`Convert to Purchase Order`)}
        btnList={convertPopupBtnConfig}
        width={!isDesktop ? '80%' : '870px'}
        height={'350'}
        disableClickOutside={true}
        overflowVisible={false}
      >
        <div
          className="flex flex-col w-full px-1 pb-3 text-sm p-1  overflow-x-hide overflow-y-auto hide-scroll-bar"
          style={{
            maxWidth: 850,
            width: 850,
            height: 350,
            maxHeight: '95%',
            padding: 0,
            paddingBottom: 30,
            overflow: 'scroll'
          }}
        >
          <div className="flex flex-row w-full">
            Your requisition has not been converted, are you sure you want to
            convert this requisition into purchase order?
          </div>
          <div className="flex  mt-6 ">
            <div
              className="flex flex-row ml-xl web-ml-r mobile-mt-m"
              style={{ width: '30%' }}
            >
              <DKInput
                title="Order Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={documentDate}
                readOnly={false}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                onChange={(date: Date) => {
                  const booksBeginningDate = DateFormatService.getDateFromStr(
                    tenantInfo.bookBeginningStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  );
                  if (date >= booksBeginningDate) {
                    if (
                      Utility.checkActiveDateRangeValidation(
                        date,
                        tenantInfo,
                        'Order Date',
                        DOC_TYPE.ORDER
                      )
                    ) {
                      setDocumentDate(date);
                    } else {
                      setDocumentDate(new Date());
                    }
                  } else {
                    showAlert(
                      'Invalid Date',
                      `Order date cannot be before books beginning date i.e. ${DateFormatService.getDateStrFromDate(
                        booksBeginningDate
                      )}`
                    );
                    setDocumentDate(new Date(documentDate));
                  }
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                datePickerConfig={{
                  isDateRange: false
                }}
              />
            </div>
            <div
              className="flex flex-row ml-xxl web-ml-r mobile-mt-m"
              style={{ width: '30%' }}
            >
              <DKInput
                title="Due Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={dueDate}
                readOnly={false}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                onChange={(date: Date) => {
                  if (
                    Utility.checkActiveDateRangeValidation(
                      date,
                      tenantInfo,
                      'Due date',
                      DOC_TYPE.ORDER
                    )
                  ) {
                    if (date < documentDate) {
                      setDueDate(reqDueDate);
                      showAlert(
                        'Invalid Date',
                        `Due date should be greater than order date i.e. ${DateFormatService.getDateStrFromDate(
                          documentDate
                        )}`,
                        [
                          {
                            title: 'Ok',
                            className: 'bg-button text-white',
                            onClick: () => {}
                          }
                        ]
                      );
                      return;
                    } else {
                      setDueDate(date);
                    }
                  } else {
                    setDueDate(new Date());
                  }
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                datePickerConfig={{
                  isDateRange: false
                }}
              />
            </div>
            <div
              className="flex flex-row ml-xxl  web-ml-r mobile-mt-m"
              style={{ width: '30%' }}
            >
              <DKInput
                title="Receive Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={receiveByDate}
                readOnly={false}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                onChange={(date: Date) => {
                  if (
                    Utility.checkActiveDateRangeValidation(
                      date,
                      tenantInfo,
                      'Receive Date',
                      DOC_TYPE.ORDER
                    )
                  ) {
                    if (date < documentDate) {
                      setreceiveByDate(requisitionReceiveByDate);
                      showAlert(
                        'Invalid Date',
                        `Receive date should be greater than order date i.e. ${DateFormatService.getDateStrFromDate(
                          documentDate
                        )}`,
                        [
                          {
                            title: 'Ok',
                            className: 'bg-button text-white',
                            onClick: () => {}
                          }
                        ]
                      );
                      return;
                    } else {
                      setreceiveByDate(date);
                    }
                  } else {
                    setreceiveByDate(new Date());
                  }
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                datePickerConfig={{
                  isDateRange: false
                }}
              />
            </div>
          </div>
          <div className="flex flex-col mt-8 ">
            <div
              className={
                'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
              }
              onClick={() => {
                showLoader('Creating Purchase Order…');
                preparePOForConversion(POConversionAction.CONVERT_ONLY);
                if (typeof props.closePopup !== 'undefined') {
                  props.closePopup();
                }
                if (props.closeParentDoc) {
                  props.closeParentDoc();
                }
              }}
            >
              <div className="flex rounded-l rounded-bl" style={{ height: 80 }}>
                <img
                  src={ic_convert_only}
                  alt="convert only"
                  style={{ width: 60 }}
                />
              </div>
              <div className="flex flex-col items-start px-4 leading-5 text-left">
                <span className="fw-m text-blue">Convert</span>
                <span className="text-gray">
                  Convert this Requisition into a Purchase Order.
                </span>
              </div>
            </div>
          </div>
        </div>
      </PopupWrapper>
    );
  };

  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    callback: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.poDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date()),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `Requisition date should not be less than back date : ${formatedDate}.`
          );
          return;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        callback(newDate);
      } else {
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      callback(newDate);
    }
  };
  const validateData = (selectedItems: any[]) => {
    let validate = true;
    if (isEmpty(selectedItems)) {
      showAlert('For PO conversion', 'Atleast on row must be selected');
      validate = false;
    } else {
      let emptyContactRows = 0;
      let emptyPriceRows = 0;
      let emptyQtyRows = 0;
      let invalidSerialRows = 0;
      productRows?.forEach((item: any) => {
        if (item.selected && item.contact === undefined) {
          validate = false;
          emptyContactRows += 1;
        }

        if (item.selected && item.unitPrice <= 0) {
          validate = false;
          emptyPriceRows += 1;
        }
        let pendingQuantity = item.uomQuantity - item.productConvertedQuantity;
        if (item.productQuantity > pendingQuantity) {
          validate = false;
          emptyQtyRows += 1;
        }

        if (item.advancedTracking === 'SERIAL') {
          if (!Number.isInteger(Number(item.productQuantity))) {
            validate = false;
            invalidSerialRows += 1;
          }
        }
      });

      if (emptyContactRows > 0 && !validate) {
        showAlert('For PO conversion', 'Supplier Must not be Empty');
        return;
      }

      if (emptyPriceRows > 0 && !validate) {
        showAlert('For PO conversion', 'Price Must not be Empty');
        return;
      }
      if (emptyQtyRows > 0 && !validate) {
        showAlert(
          'For PO conversion',
          'Cannot Convert more than Pending Quantity'
        );
        return;
      }
      if (invalidSerialRows > 0 && !validate) {
        showAlert(
          'For PO conversion',
          'Unable To Convert Serial Products With Decimal Quantities'
        );
        return;
      }

      const globalCfKeys = globalCFCustomFields?.map((gCfs: any) => gCfs?.id);

      const globalDateCFCustomFields = globalCFCustomFields.filter(
        (fieldData) => fieldData.fieldType === 'DATE'
      );

      const globalDateCfKeys = globalDateCFCustomFields?.map(
        (gCfs: any) => gCfs?.id
      );

      const group = selectedItems?.reduce((acc: any, item: any) => {
        if (!acc[item.contact.code]) {
          acc[item.contact.code] = [];
        }

        acc[item.contact.code].push(item);
        return acc;
      }, {});

      let isCustomFieldValid = Object.entries(group)?.map((item: any) => {
        const itemsData = item[1];
        const isvalid = globalCfKeys.map((key: any) => {
          const invalidData: any = itemsData.map((idata: any) => {
            if (globalDateCfKeys.includes(key)) {
              const date1 = DateFormatService.getDateStrFromDate(
                new Date(itemsData[0][key + '']),
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
              const date2 = DateFormatService.getDateStrFromDate(
                new Date(idata[key]),
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
              if (date1 !== date2) {
                return true;
              } else {
                return false;
              }
            } else {
              if (idata[key + ''] !== itemsData[0][key + '']) {
                return true;
              } else {
                return false;
              }
            }
          });
          if (invalidData.includes(true)) {
            return true;
          } else {
            return false;
          }
        });
        const isValidFieldKeyValue: any = isvalid && isvalid.includes(true);
        return isValidFieldKeyValue;
      });
      const isValidField = isCustomFieldValid.includes(true);
      if (isValidField) {
        showAlert(
          'Error',
          'Requisition custom field should be valid for same supplier'
        );
        return;
      }
    }

    const taxSystem = getTenantTaxSystem();
    const selectedProds = productRows?.filter((x: any) => {
      return x.selected;
    });

    const hasProductWithNoPurchaseTax = selectedProds.some((item: any) => {
      if (taxSystem === TAX_SYSTEM.INDIA_GST && item.taxPreference === false) {
        return false;
      } else if (
        taxSystem === TAX_SYSTEM.MALAYSIA &&
        item.exemptedMalaysia === false
      ) {
        return false;
      } else if (
        taxSystem === TAX_SYSTEM.US ||
        tenantInfo?.country === COUNTRY_CODES.US
      ) {
        return false;
      }
      return Utility.isEmpty(item?.tax) || typeof item?.tax === 'string';
    });
    if (hasProductWithNoPurchaseTax) {
      validate = false;
      showAlert(
        'For PO conversion',
        `One or more selected products in Purchase Requisition(${po.documentSequenceCode}), does not have the default purchase tax set`
      );
    }
    return validate;
  };

  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };

  const applyContactToEntireColumn = () => {
    let tmpProductRows: any = [...productRows];
    const contactCurrency = activeMultiCurrencyList.find(
      (currency: any) =>
        currency.currencyCode === defaultContactValue?.currencyCode
    );
    productRows.forEach((row: any, rowIndex) => {
      if (
        Utility.isEmpty(row.linkedPoCode) &&
        'UNRECEIVED' === row?.receipt_status?.[0]
      ) {
        const previousExchangeRate = tmpProductRows[rowIndex]?.exchangeRate;
        const currentExchangeRate = contactCurrency?.currencyExchangeRate;
        const unitPrice = convertToCurrenctExchangeRate(
          currentExchangeRate,
          previousExchangeRate,
          tmpProductRows[rowIndex].unitPrice
        );
        tmpProductRows[rowIndex].unitPrice = unitPrice;
        tmpProductRows[rowIndex].uomUnitPrice = unitPrice;

        tmpProductRows[rowIndex].previousExchangeRate = previousExchangeRate;
        tmpProductRows[rowIndex].exchangeRate = currentExchangeRate;
        tmpProductRows[rowIndex].contact = defaultContactValue;

        let gstType: GST_TYPE | undefined = 1;
        tmpProductRows[rowIndex].gstType = gstType;
        tmpProductRows[rowIndex].symbol =
          tmpProductRows[rowIndex]?.contact?.currencyCode;
        const tax = getTax(tmpProductRows[rowIndex]?.product);
        if (!isEmpty(tax)) {
          tmpProductRows[rowIndex]?.nonEditableColumns?.push('tax');
          const percent = tax.percent * 0.01;
          const price = +tmpProductRows[rowIndex]?.unitPrice * 1;
          const quantity = tmpProductRows[rowIndex]?.productQuantity;
          const taxAmount = Number((percent * price).toFixed(2));
          const totalAmount = (taxAmount + price) * quantity;
          tmpProductRows[rowIndex] = { ...tmpProductRows[rowIndex], tax };
          tmpProductRows[rowIndex].taxAmount = taxAmount;
          tmpProductRows[rowIndex].taxName = tax?.name;
          tmpProductRows[rowIndex].taxCode = tax?.code;
          tmpProductRows[rowIndex].totalAmount = totalAmount;
          tmpProductRows[rowIndex].taxSystem = getTenantTaxSystem();
          tmpProductRows[rowIndex].taxPreference =
            tmpProductRows[rowIndex]?.product?.taxPreference || false;
        }
        ItemTaxCalculator.item = tmpProductRows[rowIndex];
        if (!ItemTaxCalculator?.item?.taxAmount) {
          ItemTaxCalculator.item.taxAmount = 0;
        }
        ItemTaxCalculator.tenantInfo = tenantInfo;
        ItemTaxCalculator.setInitialValues();
        const taxAmount = ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item.taxAmount = taxAmount;
        ItemTaxCalculator.updateCalculatedValues();
        ItemTaxCalculator.item.amount =
          ItemTaxCalculator?.item?.totalAmount || 0;
        tmpProductRows[rowIndex] = { ...ItemTaxCalculator.item };
      }
    });
    setProductRows(tmpProductRows);
  };

  return (
    <>
      <div
        className="column p-h-l  overflow-x-hide overflow-y-auto hide-scroll-bar"
        style={{
          maxWidth: 1000,
          width: 990,
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 30,
          overflow: 'scroll'
        }}
      >
        <div
          className=" p-h-l"
          style={{
            maxWidth: 1000,
            width: 990,
            maxHeight: '95%',
            padding: 0,
            paddingBottom: 30,
            overflow: 'scroll'
          }}
        >
          <div className="mb-xl flex flex-row w-full">
            Select the Contact you want to send the purchase request.
          </div>
          {/* {!Utility.isAdvancedTracking(po) && (
            <div className="flex flex-row w-full mt-3">
              Alternatively, You can auto-receive this purchase order before
              proceeding to convert it into a bill.
            </div>
          )} */}
          {showMultiCurrecyWarn && (
            <div className="flex flex-row w-full mt-3">
              Note - Goods valuation will be calculated based on exchange rate
              of Bill
            </div>
          )}

          {/* <div className="flex flex-row width-auto justify-content-between">
            <div className="flex flex-row">
              <div className="position-relative m-s mr-xl">
                <div
                  className={`row width-auto mb-xs justify-content-between`}
                  style={{
                    width: 150
                  }}
                >
                  <div className={`row width-auto  cursor-pointer`}>
                    <DKIcon
                      src={DKIcons.data_type.ic_number}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />

                    <div>
                      <DKLabel text="Number" className={'fw-m ml-r'} />
                    </div>
                  </div>
                  <DKLabel
                    text={po.documentSequenceCode}
                    className="ml-r mr-xl "
                  />
                </div>
              </div>

              <div className="position-relative m-s ml-xxl">
                <div
                  className={`row width-auto mb-xs justify-content-between`}
                  style={{
                    width: 220
                  }}
                  onClick={() => {
                    setrequisitionDateOpen(!requisitionDateOpen);
                  }}
                >
                  <div className={`row width-auto  cursor-pointer`}>
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />

                    <div>
                      <DKLabel
                        text="Requisition Date"
                        className={'fw-m ml-r mr-xl'}
                      />
                    </div>
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(documentDate)}
                  />
                </div>
                {requisitionDateOpen &&
                  getCalendarView(
                    documentDate,
                    (newDate: any) => {
                      validateAndUpdateDate(
                        newDate,
                        DateFormatService.getDateFromStr(
                          tenantInfo.bookBeginningStartDate,
                          BOOKS_DATE_FORMAT['YYYY-MM-DD']
                        ),
                        setDocumentDate,
                        `Requisition Date cannot be before books beginning date.`,
                        true
                      );
                    },
                    setrequisitionDateOpen
                  )}
              </div>

              <div className="position-relative m-s ml-xxl">
                <div
                  className={`row width-auto mb-xs justify-content-between`}
                  style={{
                    width: 170
                  }}
                  onClick={() => {
                    setDueDateOpen(!dueDateOpen);
                  }}
                >
                  <div className={`row width-auto  cursor-pointer`}>
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />

                    <div>
                      <DKLabel text="Due Date" className={'fw-m ml-r mr-xl'} />
                    </div>
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(dueDate)}
                  />
                </div>
                {dueDateOpen &&
                  getCalendarView(
                    dueDate,
                    (newDate: any) =>
                      validateAndUpdateDate(
                        newDate,
                        documentDate,
                        setDueDate,
                        `${t(
                          `DOCUMENT.DUE_DATE`
                        )} cannot be before Requsition date.`,
                        false
                      ),
                    setDueDateOpen
                  )}
              </div>

              <div className="position-relative m-s ml-xxl">
                <div
                  className={`row width-auto mb-xs justify-content-between`}
                  style={{
                    width: 180
                  }}
                  onClick={() => {
                    setreceiveByDateOpen(!receiveByDateOpen);
                  }}
                >
                  <div className={`row width-auto  cursor-pointer`}>
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />

                    <div>
                      <DKLabel
                        text="Receive By"
                        className={'fw-m ml-r mr-xl'}
                      />
                    </div>
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(receiveByDate)}
                  />
                </div>
                {receiveByDateOpen &&
                  getCalendarView(
                    receiveByDate,
                    (newDate: any) =>
                      validateAndUpdateDate(
                        newDate,
                        documentDate,
                        setreceiveByDate,
                        `${receiveByDate} date cannot be before Requsition date.`,
                        false
                      ),
                    setreceiveByDateOpen
                  )}
              </div>
            </div>
          </div> */}
          <div className="flex  mt-6 m-s mb-l ">
            <div
              className="flex flex-row  web-ml-r mobile-mt-m"
              style={{ width: '25%' }}
            >
              <DKInput
                title="Number"
                icon={DKIcons.data_type.ic_number}
                className={'text-gray-800'}
                value={po.documentSequenceCode}
                readOnly={true}
                canValidate={false}
                type={INPUT_TYPE.NUMBER}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
              />
            </div>
            <div
              className="flex flex-row ml-xxl  web-ml-r mobile-mt-m"
              style={{ width: '25%' }}
            >
              <DKInput
                title="Requisition Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={requisitionDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                readOnly={true}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
              />
            </div>
            <div
              className="flex flex-row ml-xxl web-ml-r mobile-mt-m"
              style={{ width: '25%' }}
            >
              <DKInput
                title="Due Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={reqDueDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                readOnly={true}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
              />
            </div>
            <div
              className="flex flex-row mr-l ml-xxl web-ml-r mobile-mt-m"
              style={{ width: '25%' }}
            >
              <DKInput
                title="Receive Date"
                icon={DKIcons.ic_calendar}
                className={'text-gray-800'}
                value={requisitionReceiveByDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                readOnly={true}
                canValidate={false}
                type={INPUT_TYPE.DATE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
              />
            </div>
          </div>
          <div className="position-relative m-s">
            <div
              className={`row mb-xs justify-content-between`}
              style={{
                height: 35.5,
                width: 310
              }}
            >
              <div>
                {isEnable &&
                  checkUserPermission(
                    PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE
                  ) && (
                    <DKButton
                      className="bg-blue border-l text-white"
                      onClick={() => {
                        const selectedItems = productRows?.filter((x: any) => {
                          return x.selected;
                        });
                        const data = selectedItems ? selectedItems : [];
                        if (validateData(data)) {
                          // showLoader('Creating Purchase Order…');
                          // setIsOpeningForm(true);
                          // setIsLoading(true);
                          // preparePOForConversion(POConversionAction.CONVERT_ONLY);
                          // if (typeof props.closePopup !== 'undefined') {
                          //   props.closePopup();
                          // }
                          // if (props.closeParentDoc) {
                          //   props.closeParentDoc();
                          // }
                          setconvertPoScreenOpen(true);
                        }
                      }}
                      style={{
                        border: '1px'
                      }}
                      title="Convert to PO"
                    />
                  )}
              </div>
              <div>
                {showDefaultOption && (
                  <DKButton
                    className="text-blue bg-white fw-m underline"
                    title="Apply edit to entire column"
                    onClick={() => {
                      if (!Utility.isEmpty(productRows)) {
                        applyContactToEntireColumn();
                      }
                      setShowDefaultOption(false);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="justify-content-center m-s">
            <div>
              <DKDataGrid
                className={'m-r'}
                width={970}
                allowColumnEdit={false}
                // allowRowEdit={productRows.map((row) => getDataRows(row))}
                //rows={productRows.map((row) => getDataRows(row))}
                rows={productRows}
                //rows={getDataRows(productRows)}
                columns={getDataGridColumns()}
                onRowUpdate={onRowUpdate}
                onRowClick={onRowClick}
                onRowSelect={onRowClick}
                isAllRowSelected={isAllRowSelected}
                onAllRowSelect={onAllRowSelect}
              />
            </div>
          </div>
        </div>
        {ConvertPoScreenOpen && ConvertPoScreen()}
      </div>
    </>
  );
}
