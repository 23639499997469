import { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import {
  DKLabel,
  DKInput,
  INPUT_TYPE,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  DKIcons,
  DKButton,
  DKRadioButton,
  DKListPicker2,
  DKListPicker,
  removeLoader,
  showLoader,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import {
  ALL_WATERMARK_OPTIONS_INDEX,
  DESIGNER_TEMPLATE,
  DESIGNER_THERMAL_TEMPLATE,
  DOC_TYPE,
  MODULES_NAME,
  PAGE_WATERMARK_OPTIONS,
  PAGE_WATERMARK_TYPE,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY,
  MOBILE_APP_ACTIONS,
  APP_NAME
} from '../../Constants/Constant';
import PrintService from '../../Services/Print';
import Utility, { getCapitalized } from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  getSelectedPrintTemplate,
  selectCustomPrintTemplates,
  setCustomPrintTemplates,
  setSelectedPrintTemplate,
  fetchTemplatePrintInfo,
  selectTemplatePrintInfo,
  setTemplatePrintInfo,
  fetchTemplateBulkPrintInfo,
  selectTemplateBulkPrintInfo,
  setTemplateBulkPrintInfo,
  selectWordTemplateList,
  fetchWordTemplateList
} from '../../Redux/Slices/CommonDataSlice';
import printJS from 'print-js-updated';
import ExpenseService from '../../Services/Expense';
import { populateScondaryOverlayContainer } from '../DocumentForm/NewDocumentHelper';
import { useReactToPrint } from 'react-to-print';
import AppManager from '../../Managers/AppManager';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import { localizedText } from '../../Services/Localization/Localization';
import DocPreview from '../../Components/DocTemplate/DocPreview';
import { PREVIEW_SCALE, PRINT_SCALE } from 'deskera-doc-builder-lib';
import axios, { CancelTokenSource } from 'axios';
import { GranularPermissionsHelper } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';

interface PrintProps {
  document?: any;
  documentType?: any;
  parentDocumentType?: any;
  parentDocumentCode?: any;
  uid?: any;
  isBulkPrint?: boolean;
  bulkPrintDocumentCodes?: any[];
  bulkDocumentCodesData?: any;
  closePreview?: (val: boolean) => void;
}

export default function PrintPreview(props: PrintProps) {
  const dispatch = useAppDispatch();
  const customTemplateList = useAppSelector(selectCustomPrintTemplates);
  const wordTemplateList = useAppSelector(selectWordTemplateList);
  const selectedTemplate = useAppSelector(getSelectedPrintTemplate);
  const templatePrintInfo = useAppSelector(selectTemplatePrintInfo);
  const templateBulkPrintInfo = useAppSelector(selectTemplateBulkPrintInfo);

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [savedTemplate, setSavedTemplate] = useState<any>(null);
  const [documentPrintUUID, setDocumentPrintUUID] = useState('');
  const [tempFileBuffer, setTempFileBuffer] = useState<any>(null);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isSettingDefault, setIsSettingDefault] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [preview, setPreview] = useState(true);
  const [showPrintCheck, setShowPrintCheck] = useState(false);
  const [printCheckOption, setPrintCheckOption] = useState<any>({
    status: 'SUCCESS'
  });
  const [wmark, setWmark] = useState<number[]>([]);
  const [wmarkOption, setWmarkOption] = useState<string[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const docDesignerOpened = useRef<boolean>(false);
  const ref = useRef<any>(null);

  const file = useMemo(() => ({ data: tempFileBuffer }), [tempFileBuffer]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [showDefOptions, setShowDefOptions] = useState<boolean>(false);
  const [cancelTokenSources, setCancelTokenSources] = useState<
    CancelTokenSource[]
  >([]);
  const [isThermalPrint, setIsThermalPrint] = useState<boolean>(false);
  const [isWordTemplatePrint, setIsWordTemplatePrint] = useState<boolean>(false);
  const [isPrintError, setIsPrintError] = useState<boolean>(false);

  const downloadPDF = () => {
    if (pdfBlob !== '') {
      const responseBlob = new Blob([pdfBlob], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(responseBlob);
      setPreview(false);

      /* If documentSequenceCode not found, then look for documentCode.
      If docuemntCode is undefined then append 'Document' to document name*/
      let docName =
        props.document?.documentSequenceCode ?? props.document?.documentCode;
      docName = docName ?? 'Document';

      const downloadAnchor = document.createElement('a');
      downloadAnchor.setAttribute(
        'download',
        `${props.documentType}-${docName}.pdf`
      );

      downloadAnchor.href = fileURL;
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      document.body.removeChild(downloadAnchor);
    }
  };

  const handlePrint = () => {
    if (
      pdfBlob &&
      pdfBlob !== ''
      // && selectedTemplate.id !== DESIGNER_THERMAL_TEMPLATE.id
    ) {
      const responseBlob = new Blob([pdfBlob], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(responseBlob);
      setPreview(false);
      // printJS(fileURL);
      printJS({
        type: 'pdf',
        printable: fileURL,
        onPrintDialogClose: () => {
          if (props.parentDocumentType === DOC_TYPE.CHEQUE) {
            openPopupAfterCheckPrint();
          }
        }
      });
    } else if (selectedTemplate.id === DESIGNER_THERMAL_TEMPLATE.id) {
      handleThermalPrint();
    }
  };

  const handleThermalPrint = useReactToPrint({
    content: () => ref.current,
    copyStyles: true
  });

  const getTemplatePrintInfo = async () => {
    let payload;
    if (
      props.parentDocumentType === DOC_TYPE.CHEQUE &&
      props.parentDocumentCode?.length
    ) {
      payload = {
        category: props.parentDocumentType,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        paymentDocumentCodes: props.parentDocumentCode,
        bulkPaymentDocumentCodes: []
      };
      if (props.bulkDocumentCodesData) {
        payload['paymentDocumentCodes'] =
          props.bulkDocumentCodesData.paymentDocumentCodes;
        payload['bulkPaymentDocumentCodes'] =
          props.bulkDocumentCodesData.bulkPaymentDocumentCodes;
      }
    } else {
      let category = props.documentType
      if (props.documentType === DOC_TYPE.QC_INSPECTION ||
        props.documentType === DOC_TYPE.INSPECTION_REPORT) {
        category = DOC_TYPE.WORK_ORDER
      }
      else if (props.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
                props.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES) {
        category = TEMPLATE_CATEGORY.ORDER
      }
      else if (props.documentType === DOC_TYPE.MAKE_PAYMENT ||
        props.documentType === DOC_TYPE.RECEIVE_PAYMENT ||
        props.documentType === DOC_TYPE.PREPAYMENT ||
        props.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT) {
        category = TEMPLATE_CATEGORY.PAYMENT
      }
      payload = {
        category: category,
        contactCode: props.document?.contactCode
          ? props.document?.contactCode
          : '',
        documentCode: props.document?.documentCode,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        parentDocumentType: null,
        parentDocumentCode: null,
        appName:
          props.documentType === DOC_TYPE.WORK_ORDER ||
          props.documentType === DOC_TYPE.QC_INSPECTION ||
          props.documentType === DOC_TYPE.INSPECTION_REPORT ||
          props.documentType === MODULES_NAME.MRP_MACHINE ||
          props.documentType === DOC_TYPE.QC_DOCUMENT ||
          props.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
          props.documentType === DOC_TYPE.PRODUCT ||
          props.documentType === DOC_TYPE.STOCK_REQUEST ||
          props.documentType === DOC_TYPE.STOCK_ISSUE
            ? 'MRP'
            : 'ERP'
      };

      if (
        props.documentType === DOC_TYPE.PREPAYMENT ||
        props.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        payload['parentDocumentType'] = props.parentDocumentType;
        payload['parentDocumentCode'] = props.parentDocumentCode;
        payload['documentCode'] = props.uid;
      }
      if (props.documentType === DOC_TYPE.EWAY_BILL) {
        payload = {
          contactCode: props.document.customerName,
          documentCode: props.document.ewayBillNo,
          documentType: props.document.category,
          parentDocumentType: props.document.parentDocumentType,
          category: props.document.category,
          event: TemplateMappingEvent.PRINT,
          parentDocumentCode: props.document.parentDocumentCode
        };
      }
      if (props.documentType === TEMPLATE_CATEGORY.EXPENSE_BILL) {
        payload['category'] = TEMPLATE_CATEGORY.BILLS;
      }

      if (props.documentType === TEMPLATE_CATEGORY.REQUISITION) {
        payload.documentType = props.document.documentType
      }

      if (
        props.documentType === TEMPLATE_CATEGORY.SOA_CUSTOMER ||
        props.documentType === TEMPLATE_CATEGORY.SOA_VENDOR ||
        props.documentType === TEMPLATE_CATEGORY.AGED_RECEIVABLE
      ) {
        payload = props.document;
      }
    }
    try {
      if (Utility.isEmbedPrintEnabled()) {
        setIsPDFLoading(true);
      }
      await dispatch(fetchTemplatePrintInfo(payload));
    } catch (err) {
      console.error('error loding template list: ', err);
    }
  };
  const getTemplateBulkPrintInfo = async () => {
    let payload;
    if (isBulkDocumentPrint()) {
      payload = {
        category: props.parentDocumentType,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        documentCodes: props.bulkPrintDocumentCodes
      };
    }
    try {
      if (Utility.isEmbedPrintEnabled()) {
        setIsPDFLoading(true);
      }
      await dispatch(fetchTemplateBulkPrintInfo(payload));
    } catch (err) {
      console.error('error loding template list: ', err);
    }
  };

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    if (isBulkDocumentPrint()) {
      getTemplateBulkPrintInfo();
    } else {
      getTemplatePrintInfo();
    }
    dispatch(fetchWordTemplateList())
    setShowPreview(true);
    window.addEventListener('focus', onFocus);
    return () => {
      cleanup();
      docDesignerOpened.current = false;
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = (callback?: any) => {
    if (docDesignerOpened.current) {
      cleanup();
      if (isBulkDocumentPrint()) {
        getTemplateBulkPrintInfo();
      } else {
        getTemplatePrintInfo();
      }
    }
  };

  useEffect(() => {
    if (templatePrintInfo) {
      dispatch(setCustomPrintTemplates(templatePrintInfo.documentTemplates));
      setIsSettingDefault(false);
      if (Utility.isEmbedPrintEnabled()) {
        setIsPDFLoading(false);
      }
    }
  }, [templatePrintInfo]);

  useEffect(() => {
    if (templateBulkPrintInfo) {
      dispatch(
        setCustomPrintTemplates(templateBulkPrintInfo.documentTemplates)
      );
      if (Utility.isEmbedPrintEnabled()) {
        setIsPDFLoading(false);
      }
    }
  }, [templateBulkPrintInfo]);

  useEffect(() => {
    if (templatePrintInfo && customTemplateList) {
      setDocumentPrintUUID(templatePrintInfo.code);
      getSavedTemplate();
    } else if (templateBulkPrintInfo && customTemplateList) {
      setDocumentPrintUUID(templateBulkPrintInfo.codes);
      setSavedTemplate({ ...DESIGNER_TEMPLATE });
    }
  }, [customTemplateList]);

  const cleanup = () => {
    setTempFileBuffer(null);
    dispatch(setSelectedPrintTemplate(undefined));
    dispatch(setCustomPrintTemplates(null));
    dispatch(setTemplatePrintInfo(null));
    dispatch(setTemplateBulkPrintInfo(null));
  };

  async function printCustomInvoice(templateId: any, thermalPrint = false) {
    if (!documentPrintUUID) {
      return;
    }
    setIsPDFLoading(preview);
    try {
      let responseBody;

      let pageOrientation = 'PORTRAIT'
      let descriptionString = selectedTemplate.description ?? ''
      if (descriptionString.includes('[LANDSCAPE]')) {
        pageOrientation = 'LANDSCAPE'
      }
      pageOrientation = pageOrientation.toLowerCase()

      if(selectedTemplate?.wordTemplate ===true){
          const payload = {
            documentCode: props.document?.documentCode,
            documentType: getDocumentType(props.documentType),
            templateId: selectedTemplate.id,
            preview: true
          };
          responseBody = await PrintService.printTemplate(payload)
        }
      else{
        if (isBulkDocumentPrint() && documentPrintUUID.length > 0) {
          const cancelToken = axios.CancelToken;
          const source = cancelToken.source();
          setCancelTokenSources([...cancelTokenSources, source]);
  
          responseBody = await PrintService.bulkPrintCustomTemplateInvoice(
            documentPrintUUID,
            templateId,
            preview,
            thermalPrint ? DESIGNER_THERMAL_TEMPLATE.pageFormat : '',
            pageOrientation,
            isInvoiceDocument() && !isBulkDocumentPrint() && wmarkOption
              ? wmarkOption.join(',')
              : '',
            source
          );
        } else {
          let waterMarkOption = '';
          if (
            isInvoiceDocument() &&
            !isBulkDocumentPrint() &&
            wmarkOption?.length
          ) {
            waterMarkOption = wmarkOption.join(',');
          }
  
          const cancelToken = axios.CancelToken;
          const source = cancelToken.source();
          setCancelTokenSources([...cancelTokenSources, source]);
  
          responseBody = await PrintService.printCustomTemplateInvoice(
            documentPrintUUID,
            templateId,
            preview,
            thermalPrint ? DESIGNER_THERMAL_TEMPLATE.pageFormat : '',
            pageOrientation,
            waterMarkOption,
            source
          );
        }
      }
     

      const responseBlob = new File([responseBody], 'preview.pdf');
      const fileReader: FileReader = new FileReader();
      fileReader.onloadend = (loadEvent: any) => {
        const bufferValue = new Uint8Array(loadEvent.target.result);
        setTempFileBuffer(bufferValue);
        setPdfBlob(bufferValue);
        setIsPDFLoading(false);
      };
      fileReader.readAsArrayBuffer(responseBlob);
    } catch (err: any) {
      if (err?.message !== 'request_cancelled') {
        setIsPrintError(true);
        setIsPDFLoading(false);
        showToast(
          'Failed to get document print, Please try again later.',
          TOAST_TYPE.FAILURE
        );
      }
    }
    if (!preview) {
      setPreview(true);
    }
  }

  useEffect(() => {
    if (!Utility.isEmpty(selectedTemplate)) {
      if (cancelTokenSources.length > 0) {
        cancelTokenSources.forEach((source: CancelTokenSource) => {
          source.cancel('request_cancelled');
        });

        setCancelTokenSources([]);
      }

      if (Utility.isEmbedPrintEnabled()) {
        setIsPreviewLoading(false);
      }

      if (selectedTemplate.id === DESIGNER_TEMPLATE.id) {
        printCustomInvoice(null);
      } else if (selectedTemplate.id === DESIGNER_THERMAL_TEMPLATE.id) {
        printCustomInvoice(null, true);
      } else {
        printCustomInvoice(selectedTemplate.id);
      }
    }
  }, [selectedTemplate, wmarkOption]);

  useEffect(() => {
    if (!preview) {
      dispatch(setSelectedPrintTemplate(selectedTemplate));
    }
  }, [preview]);

  useEffect(() => {
    function selectCustomDocument(template: any) {
      if (
        template &&
        template.id !== DESIGNER_TEMPLATE.id &&
        template.id !== DESIGNER_THERMAL_TEMPLATE.id &&
        template.templateName !== selectedTemplate?.templateName
      ) {
        dispatch(setSelectedPrintTemplate(template));
      } else if (template.id === DESIGNER_TEMPLATE.id) {
        dispatch(setSelectedPrintTemplate(DESIGNER_TEMPLATE));
      } else if (template.id === DESIGNER_THERMAL_TEMPLATE.id) {
        dispatch(setSelectedPrintTemplate(DESIGNER_THERMAL_TEMPLATE));
      }
    }

    async function getDocumentPrintUUID(template = '') {
      if (documentPrintUUID && documentPrintUUID.length > 0 && template) {
        selectCustomDocument(template);
      } else {
        try {
          if (props.documentType === MODULES_NAME.PRODUCT) {
            const printUUID = await PrintService.getDocumentPrintUUIDWithMetaCode(
                props.document?.documentCode,
                PrintService.getPrintDocumentCategory(props.documentType),
                props.document?.documentMetaCode
              );
            setDocumentPrintUUID(printUUID.code);
            if (template) {
              selectCustomDocument(template);
            }
          } else {
            const printUUID = await PrintService.getDocumentPrintUUID(
              props.document?.documentCode,
              PrintService.getPrintDocumentCategory(props.documentType),
              props.document?.documentType
            );
            setDocumentPrintUUID(printUUID.code);
            if (template) {
              selectCustomDocument(template);
            }
          }
        } catch (err) {
          console.error('error fetching print uuid: ', err);
        }
      }
    }

    if (!Utility.isEmpty(savedTemplate)) {
      getDocumentPrintUUID(savedTemplate);
    }

    if (
      props.documentType === MODULES_NAME.PACK_LIST ||
      props.documentType === MODULES_NAME.SHIP_LIST
    ) {
      getDocumentPrintUUID(savedTemplate);
    }
  }, [savedTemplate]);

  function getSavedTemplate() {
    try {
      const savedTemplate = templatePrintInfo['defaultTemplateMapping'];
      if (!Utility.isEmpty(savedTemplate)) {
        if (savedTemplate.templateId === 0) {
          setSavedTemplate({ ...DESIGNER_TEMPLATE });
        }
        if (savedTemplate.templateId && savedTemplate.custom) {
          const defaultTemp = customTemplateList.find(
            (obj: any) => obj.id === savedTemplate.templateId
          );
          if (defaultTemp) {
            setSavedTemplate(defaultTemp);
          }
        }
      } else {
        setSavedTemplate({ ...DESIGNER_TEMPLATE });
      }
    } catch (err) {
      console.error('error loding saved template: ', err);
      setSavedTemplate({ ...DESIGNER_TEMPLATE });
    }
  }

  // useEffect(() => {
  //   async function fetchTemplateList() {
  //     try {
  //       await dispatch(fetchCustomPrintTemplates(props.documentType));
  //     } catch (err) {
  //       console.error('error loding template list: ', err);
  //     }
  //   }
  //   fetchTemplateList();
  // }, [props.document, props.documentType]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const dispatchSelectedTemplate = (template: any) => {
    dispatch(setSelectedPrintTemplate(template));
  };


  const getDocumentType = (docType: any) => {
    switch (docType) {
      case 'QUOTE':
        return 'QUOTATION';
      case 'ORDER':
        return 'PURCHASE_ORDER';
      case 'BILLS':
        return 'PURCHASE_INVOICE'
      case 'INVOICE':
        return 'SALES_INVOICE'
      default:
        return docType;
    }
  };

  const getWordTemplateList = () => {
    if (!Utility.isEmpty(wordTemplateList)) {
      const wordTemplateDoc = wordTemplateList?.find(
        (doc) => doc?.category === props.documentType
      );
      if (!Utility.isEmpty(wordTemplateDoc?.templates)) {
        let wordDocTemplateList: any[] = [];
        wordTemplateDoc?.templates?.forEach((docTemp: any) => {
          const docRow = {
            id: docTemp?.id,
            templateName: docTemp?.name,
            appName: APP_NAME,
            templateData: '',
            wordTemplate: true
          };

          wordDocTemplateList.push(docRow);
        });
        return wordDocTemplateList;
      }
    }

    return [];
  };

  const getUpdatedOptions = () => {
    if (customTemplateList) {
      const designerTemplateIndex = customTemplateList.findIndex(
        (x: any) => x.id === 0
      );
      if (designerTemplateIndex !== -1) {
        return [...customTemplateList];
      }
      if (
        props.documentType === TEMPLATE_CATEGORY.BILLS ||
        props.documentType === TEMPLATE_CATEGORY.SALES_ORDER ||
        props.documentType === TEMPLATE_CATEGORY.INVOICE ||
        props.documentType === TEMPLATE_CATEGORY.ORDER ||
        props.documentType === TEMPLATE_CATEGORY.QUOTE
      ) {
        return [
          DESIGNER_TEMPLATE,
          DESIGNER_THERMAL_TEMPLATE,
          ...customTemplateList,
          ...getWordTemplateList()
        ];
      } else {
        return [DESIGNER_TEMPLATE, ...customTemplateList];
      }
    }
    return [];
  };

  const getWmarkUpdatedOptions = () => {
    return [
      PAGE_WATERMARK_TYPE.NONE,
      PAGE_WATERMARK_TYPE.ORIGINAL,
      PAGE_WATERMARK_TYPE.DUPLICATE,
      PAGE_WATERMARK_TYPE.TRIPLICATE,
      PAGE_WATERMARK_TYPE.QUADRUPLICATE,
      PAGE_WATERMARK_TYPE.CUSTOM,
      PAGE_WATERMARK_TYPE.ALL
    ];
  };

  const isInvoiceDocument = () => {
    return (
      props.documentType === TEMPLATE_CATEGORY.QUOTE ||
      props.documentType === TEMPLATE_CATEGORY.SALES_ORDER ||
      props.documentType === TEMPLATE_CATEGORY.INVOICE ||
      props.documentType === TEMPLATE_CATEGORY.ORDER ||
      props.documentType === TEMPLATE_CATEGORY.BILLS
    );
  };

  const isBulkDocumentPrint = () => {
    return (
      props.isBulkPrint &&
      props.bulkPrintDocumentCodes &&
      props.bulkPrintDocumentCodes.length > 1
    );
  };

  const openPopupAfterCheckPrint = () => {
    if (
      props.parentDocumentType === DOC_TYPE.CHEQUE &&
      props.parentDocumentCode?.length
    ) {
      setShowPrintCheck(true);
    }
  };
  const optionUpdated = (value: any) => {
    setPrintCheckOption({ status: value });
  };

  const setPrintCheckStatus = () => {
    let payload: any = [];
    let status: any = '';
    if (printCheckOption.status === 'SUCCESS') {
      status = 'PRINT_SUCCESS';
    } else {
      status = 'PRINT_FAILURE';
    }
    props.parentDocumentCode.forEach((ele: any) => {
      let obj: any = {};
      obj['documentCode'] = ele;
      obj['printStatus'] = status;
      payload.push(obj);
    });
    ExpenseService.setPrintCheckStatus(payload)
      .then((res: any) => {
        setShowPrintCheck(false);
        if (props.closePreview) {
          props.closePreview(false);
        }
      })
      .catch((err) => {
        setShowPrintCheck(false);
        console.log(err, 'error while getting the checks');
      });
  };

  const getHeader = () => {
    const modulesWithPrintPermissions = [
      TEMPLATE_CATEGORY.BILLS,
      TEMPLATE_CATEGORY.INVOICE,
      TEMPLATE_CATEGORY.ORDER
    ];

    let moduleNameForPermission = props.documentType?.toUpperCase();

    switch (props.documentType?.toUpperCase()) {
      case TEMPLATE_CATEGORY.BILLS:
        moduleNameForPermission = 'BILL';
        break;
      case TEMPLATE_CATEGORY.ORDER:
        moduleNameForPermission = 'PURCHASE_ORDER';
        break;
    }

    const showButtons: any = modulesWithPrintPermissions.includes(
      props.documentType?.toUpperCase()
    )
      ? GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE?.[moduleNameForPermission]?.PRINT
        )
      : true;

    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Preview" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              if (props.closePreview) {
                props.closePreview(false);
              } else {
                populateScondaryOverlayContainer(<></>);
              }
            }}
          />
          {showButtons && !isTabletView() && (
            <div
              className={`row border-radius-m dk-button-hover border-m ml-r ${
                isPDFLoading || isPrintError
                  ? 'bg-white'
                  : 'bg-button text-white'
              }`}
            >
              <DKButton
                icon={
                  isPDFLoading || isPrintError
                    ? DKIcons.ic_download
                    : DKIcons.white.ic_download
                }
                title={'Download'}
                disabled={isPDFLoading || isPrintError}
                style={{ paddingRight: isPDFLoading ? '6px' : '12px' }}
                onClick={() => {
                  downloadPDF();
                }}
              />
              {isPDFLoading && (
                <DKSpinner iconClassName="ic-s" className="mr-m" />
              )}
            </div>
          )}
          {showButtons && (!isTabletView() || isInvoiceDocument()) && (
            <div
              className={`row border-radius-m dk-button-hover border-m ml-r ${
                isPDFLoading || isPrintError
                  ? 'bg-white'
                  : 'bg-button text-white'
              }`}
            >
              <DKButton
                icon={
                  isPDFLoading || isPrintError
                    ? DKIcons.ic_printer
                    : DKIcons.white.ic_printer
                }
                title={'Print'}
                disabled={isPDFLoading || isPrintError}
                style={{ paddingRight: isPDFLoading ? '6px' : '12px' }}
                onClick={async () => {
                  handlePrint();
                }}
              />
              {isPDFLoading && (
                <DKSpinner iconClassName="ic-s" className="mr-m" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const setDefaultTemplate = () => {
    setIsSettingDefault(true);
    PrintService.setDefaultTemplate(selectedTemplate.id).then(
      (data: any) => {
        dispatch(setTemplatePrintInfo(null));
        getTemplatePrintInfo();
        setShowDefOptions(false);
        removeLoader();
      },
      (err: any) => {
        setIsSettingDefault(false);
        setShowDefOptions(false);
        removeLoader();
        console.log('Error setting default template: ', err);
      }
    );
  };

  const showDefaultButton = () => {
    const loadedTemplate = customTemplateList?.find(
      (template: any) => template.id === selectedTemplate?.id
    );
    let isContactSet = false;
    if (
      templatePrintInfo &&
      templatePrintInfo['defaultTemplateMapping']?.contactCode
    ) {
      isContactSet = true;
    }

    return (
      selectedTemplate?.id !== DESIGNER_TEMPLATE.id &&
      selectedTemplate?.id !== DESIGNER_THERMAL_TEMPLATE.id &&
      !isPDFLoading &&
      !isBulkDocumentPrint() &&
      ((!isContactSet &&
        !Utility.isEmpty(loadedTemplate) &&
        customTemplateList?.length &&
        !loadedTemplate?.defaultTemplate) ||
        (isContactSet &&
          customTemplateList?.length &&
          templatePrintInfo['defaultTemplateMapping']?.templateId !==
            selectedTemplate?.id))
    );
  };

  const showOptions = () => {
    let isContactSet = false;
    if (
      templatePrintInfo &&
      templatePrintInfo['defaultTemplateMapping']?.contactCode
    ) {
      isContactSet = true;
    }
    return (
      <DKListPicker
        data={
          isContactSet
            ? [`Set for Contact`]
            : [`Set for Contact`, `Set for Document`]
        }
        className="shadow-m absolute z-10"
        style={{ right: 0, top: 30, width: 150 }}
        onSelect={(index: any, value: any) => {
          if (index === 1) {
            showLoader();
            setDefaultTemplate();
          } else {
            let thermalPrint = false;
            if (selectedTemplate.id === DESIGNER_THERMAL_TEMPLATE.id) {
              thermalPrint = true;
            }
            let waterMarkOption = '';
            if (
              isInvoiceDocument() &&
              !isBulkDocumentPrint() &&
              wmarkOption?.length
            ) {
              waterMarkOption = wmarkOption.join(',');
            }
            showLoader();
            setShowDefOptions(false);
            let payload: any = {
              contactCode: props.document?.contactCode
                ? props.document?.contactCode
                : '',
              documentType: PrintService.getTemplateNameType(
                props.documentType.toString().toLowerCase()
              ),
              event: TemplateMappingEvent.PRINT,
              templateId: selectedTemplate.id,
              custom: true
            };
            PrintService.setContactTemplate(payload)
              .then((res: any) => {
                dispatch(setTemplatePrintInfo(null));
                getTemplatePrintInfo();
                removeLoader();
              })
              .catch((err) => {
                removeLoader();
                console.log(err);
              });
          }
        }}
        onClose={() => {
          setShowDefOptions(false);
        }}
      />
    );
  };

  const getPrintTemplate = (isPrint: boolean) => {
    if (
      !Utility.isEmptyObject(selectedTemplate) &&
      selectedTemplate?.wordTemplate !== true
    ) {
      if (isBulkDocumentPrint()) {
        return templateBulkPrintInfo?.codes?.map?.((code: any) => (
          <DocPreview
            printScale={isPrint ? PRINT_SCALE : PREVIEW_SCALE}
            templatePrintInfo={{
              documentTemplates: templateBulkPrintInfo?.documentTemplates,
              code
            }}
            selectedTemplate={selectedTemplate}
          />
        ));
      } else {
        let printInfo = { ...templatePrintInfo }
        if (printInfo.code === null && documentPrintUUID !== '') {
          printInfo.code = documentPrintUUID
        }
        if (wmarkOption?.length !== 0) {
          return wmarkOption.map((wmark: string) => (
            <DocPreview
              printScale={isPrint ? PRINT_SCALE : PREVIEW_SCALE}
              templatePrintInfo={printInfo}
              selectedTemplate={selectedTemplate}
              wmark={wmark}
            />
          ));
        } else {
          return (
            <DocPreview
              printScale={isPrint ? PRINT_SCALE : PREVIEW_SCALE}
              templatePrintInfo={printInfo}
              selectedTemplate={selectedTemplate}
              wmark={'none'}
            />
          );
        }
      }
    }
  };

  const renderPrintPreview = (isPrint: boolean) => {
    return (
      <div
        className={`row flex-wrap ${isPrint ? 'PrintPreviewHolder' : ''}`}
        style={{ gap: 10, display: isPrint ? 'none' : '' }}
      >
        {getPrintTemplate(isPrint)}
      </div>
    );
  };

  return (
    <>
      {showPreview && (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxHeight: '95%',
              height: '95%',
              width: '55%',
              maxWidth: '90%',
              padding: 0,
              paddingBottom: 0
            }}
          >
            <div className="column parent-width parent-height overflow-hidden">
              {getHeader()}
              <div
                className="column parent-width"
                style={{ height: 'calc(100% - 53px)' }}
              >
                <div className="row row-responsive">
                  <div
                    className="row ml-l web-wm-mr-r mt-l mb-r"
                    style={{
                      width: !isDesktop ? '100%' : 220,
                      height: 45,
                      paddingTop: 5
                    }}
                  >
                    {customTemplateList && (
                      <DKInput
                        className={''}
                        readOnly={
                          Utility.isEmpty(selectedTemplate) ||
                          (Utility.isEmbedPrintEnabled()
                            ? isPreviewLoading
                            : isPDFLoading)
                        }
                        value={selectedTemplate}
                        formatter={(obj: any) => {
                          return obj.templateName;
                        }}
                        title="Template"
                        titleStyle={{
                          color: 'gray'
                        }}
                        type={INPUT_TYPE.DROPDOWN}
                        required={false}
                        canValidate={false}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        onChange={(template: any) => {
                          if (template.id !== selectedTemplate.id) {
                            setIsThermalPrint(
                              template.id === DESIGNER_THERMAL_TEMPLATE.id
                            );

                            setIsWordTemplatePrint(
                              template?.wordTemplate ?? false
                            );

                            if (Utility.isEmbedPrintEnabled()) {
                              setIsPreviewLoading(true);
                              setIsPDFLoading(true);
                              setTimeout(() => {
                                dispatchSelectedTemplate(template);
                              }, 200);
                            } else {
                              dispatchSelectedTemplate(template);
                            }
                          }
                        }}
                        dropdownConfig={{
                          className: '',
                          style: {},
                          allowSearch: false,
                          searchableKey: 'templateName',
                          canEdit: false,
                          canDelete: false,
                          data: getUpdatedOptions(),
                          renderer: DocumentConfigUtility.rendererForTemplate,
                          button: isBulkDocumentPrint()
                            ? ''
                            : {
                                title: 'Edit Template',
                                icon: DKIcons.white.ic_edit,
                                className: 'bg-button text-white',
                                onClick: () => {
                                  if (documentPrintUUID) {
                                    const printExternalAppUrl = [
                                      process.env.REACT_APP_DOC_DESIGNER_URL,
                                      documentPrintUUID
                                    ].join('/');
                                    docDesignerOpened.current = true;
                                    window.open(printExternalAppUrl, '_blank');
                                  }
                                }
                              }
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="row web-wm-ml-r web-wm-mr-r mb-r mt-l"
                    style={{
                      width: !isDesktop
                        ? '100%'
                        : wmark?.length > 4
                        ? 250
                        : 220,
                      height: 45,
                      paddingTop: 3
                    }}
                  >
                    {customTemplateList &&
                      isInvoiceDocument() &&
                      !isBulkDocumentPrint() && (
                        <div className="parent-width justify-content-between">
                          <DKLabel
                            className="mb-s mt-0 text-gray"
                            text={'Watermark'}
                          />
                          <div
                            className={
                              'row ml-2x bg-gray1 mt-0 height-auto flex-wrap border-radius-s ' +
                              (!(
                                Utility.isEmpty(selectedTemplate) ||
                                (Utility.isEmbedPrintEnabled()
                                  ? isPreviewLoading
                                  : isPDFLoading)
                              )
                                ? 'cursor-pointer'
                                : '')
                            }
                            style={{
                              minHeight: 35,
                              border: '1px solid rgb(235, 235, 235)'
                            }}
                            onClick={() => {
                              if (
                                !(
                                  Utility.isEmpty(selectedTemplate) ||
                                  (Utility.isEmbedPrintEnabled()
                                    ? isPreviewLoading
                                    : isPDFLoading)
                                )
                              ) {
                                setShow(!show);
                              }
                            }}
                          >
                            {wmark.map((item: any, index: any) => {
                              return (
                                wmark && (
                                  <div className="m-xs bg-white p-h-s border-radius-s shadow-m">
                                    {PAGE_WATERMARK_OPTIONS[item]}
                                  </div>
                                )
                              );
                            })}
                          </div>
                          {show && (
                            <DKListPicker2
                              style={{
                                top: !isDesktop
                                  ? 200
                                  : wmark?.length > 2
                                  ? 135
                                  : 125,
                                left: !isDesktop ? 8 : 230
                              }}
                              title=""
                              className="position-absolute z-index-5 shadow-m ml-l"
                              allowSearch={false}
                              searchableKey="label"
                              multiSelect={true}
                              checkMarkColor="bg-blue"
                              selectedIndexes={wmark}
                              data={PAGE_WATERMARK_OPTIONS}
                              renderer={(index: any, obj: any) => {
                                return <DKLabel text={`${obj}`} />;
                              }}
                              onSelect={(index: any, value: any) => {
                                var selectedIndexes = wmark;
                                const allOption = selectedIndexes.includes(
                                  ALL_WATERMARK_OPTIONS_INDEX
                                );
                                let availabilitySelectedIndexesCopy: any[] = [];
                                if (value === PAGE_WATERMARK_TYPE.ALL) {
                                  if (allOption) {
                                    availabilitySelectedIndexesCopy =
                                      selectedIndexes.filter(
                                        (x) => x !== index
                                      );
                                  } else {
                                    availabilitySelectedIndexesCopy = [
                                      ALL_WATERMARK_OPTIONS_INDEX
                                    ];
                                  }
                                } else {
                                  availabilitySelectedIndexesCopy =
                                    selectedIndexes;
                                  if (allOption) {
                                    availabilitySelectedIndexesCopy =
                                      selectedIndexes.filter(
                                        (x) => x !== ALL_WATERMARK_OPTIONS_INDEX
                                      );
                                  }
                                  if (selectedIndexes.includes(index)) {
                                    availabilitySelectedIndexesCopy =
                                      availabilitySelectedIndexesCopy.filter(
                                        (x) => x !== index
                                      );
                                  } else {
                                    availabilitySelectedIndexesCopy = [
                                      ...availabilitySelectedIndexesCopy,
                                      ...[index]
                                    ];
                                  }
                                }
                                setWmark(availabilitySelectedIndexesCopy);
                              }}
                              onClear={() => {
                                setWmark([]);
                              }}
                              onClose={() => {
                                let wmarkArr = wmark.map((idx: any) => {
                                  return PAGE_WATERMARK_OPTIONS[idx];
                                });

                                if (wmarkArr.findIndex(i => i === PAGE_WATERMARK_TYPE.ALL) !== -1) {
                                  wmarkArr = [
                                    PAGE_WATERMARK_TYPE.ORIGINAL,
                                    PAGE_WATERMARK_TYPE.DUPLICATE,
                                    PAGE_WATERMARK_TYPE.TRIPLICATE,
                                    PAGE_WATERMARK_TYPE.QUADRUPLICATE
                                  ];
                                }

                                if (wmarkOption?.length !== 0 || wmarkArr?.length !== 0) {
                                  if (Utility.isEmbedPrintEnabled()) {
                                    setIsPDFLoading(true);
                                    setIsPreviewLoading(true);
                                    setTimeout(() => {
                                      setWmarkOption(wmarkArr);
                                    }, 200);
                                  } else {
                                    setWmarkOption(wmarkArr);
                                  }
                                }
                                setShow(false);
                              }}
                            />
                          )}
                        </div>
                      )}
                  </div>
                  {showDefaultButton() && (
                    <DKButton
                      title={'Set as default template'}
                      className={`bg-white ml-s fw-m ${
                        isSettingDefault ? 'text-gray' : 'text-blue'
                      }`}
                      style={{ marginTop: 35, textDecoration: 'underline' }}
                      disabled={isSettingDefault}
                      onClick={() => {
                        if (
                          props.documentType === DOC_TYPE.WORK_ORDER ||
                          props.documentType === DOC_TYPE.STOCK_TRANSFER ||
                          props.documentType === DOC_TYPE.STOCK_ADJUSTMENT
                        ) {
                          setDefaultTemplate();
                        } else {
                          setShowDefOptions(true);
                        }
                      }}
                    />
                  )}
                  {showDefOptions && (
                    <div className="position-relative">{showOptions()}</div>
                  )}
                </div>
                <div
                  className={`row parent-height mt-3 overflow-y-auto overflow-x-hidden p-h-l pb-l ${
                    Utility.isEmbedPrintEnabled() && isPreviewLoading
                      ? 'align-items-center'
                      : !Utility.isEmbedPrintEnabled() && isPDFLoading
                      ? 'align-items-center'
                      : 'align-items-start'
                  }`}
                >
                  {(!Utility.isEmbedPrintEnabled() ||
                    (Utility.isEmbedPrintEnabled() && isThermalPrint) ||
                    isWordTemplatePrint) &&
                    !isPDFLoading &&
                    tempFileBuffer && (
                      <SizeMe>
                        {({ size }) => (
                          <Document
                            inputRef={ref}
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={''}
                            className="parent-width"
                          >
                            {Array.from(new Array(numPages), (el, index) => (
                              <Page
                                width={size.width ? size.width : 1}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                loading={''}
                              />
                            ))}
                          </Document>
                        )}
                      </SizeMe>
                    )}
                  {Utility.isEmbedPrintEnabled() &&
                    !isThermalPrint &&
                    !isPreviewLoading &&
                    !isWordTemplatePrint &&
                    renderPrintPreview(false)}
                  {/* {Utility.isEmbedPrintEnabled() && !isPDFLoading && renderPrintPreview(true)} */}
                  {(Utility.isEmbedPrintEnabled() && !isThermalPrint
                    ? isPreviewLoading
                    : isPDFLoading) && (
                    <div className="flex parent-width parent-height items-center justify-center font-semibold">
                      <DKSpinner
                        iconClassName="ic-s-2"
                        className="ml-m"
                        title="loading document..."
                      />
                    </div>
                  )}
                  {showPrintCheck && (
                    <div className="transparent-background">
                      <div
                        className="popup-window overflow-hidden"
                        style={{
                          maxWidth: 520,
                          width: '80%',
                          maxHeight: '95%',
                          height: 150,
                          padding: 0,
                          position: 'fixed'
                        }}
                      >
                        <div className="row justify-content-between p-h-s p-v-s bg-gray1">
                          <div className="row width-auto">
                            <DKLabel
                              text="Did your checks print OK?"
                              className="fw-m fs-l"
                            />
                          </div>
                          <DKButton
                            title={'Done'}
                            className={'ml-r bg-button text-white'}
                            onClick={() => {
                              setPrintCheckStatus();
                            }}
                          />
                        </div>
                        <div className="column mt-5 ml-3">
                          <DKRadioButton
                            title="Yes,they all printed correctly"
                            isSelected={printCheckOption?.status === 'SUCCESS'}
                            onClick={() => optionUpdated('SUCCESS')}
                            className="mr-l fs-m"
                            color={'bg-app'}
                          />
                          <DKRadioButton
                            title={`No, keep all ${localizedText(
                              'cheque'
                            )}s in the Print ${getCapitalized(
                              localizedText('cheque')
                            )}s list`}
                            isSelected={printCheckOption?.status === 'FAILED'}
                            onClick={() => optionUpdated('FAILED')}
                            className="mr-s mt-2 fs-m"
                            color={'bg-app'}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
