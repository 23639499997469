export const getDefaultSerialBatchNumberForBatchTrackedProduct = (
  productDetailsData: any,
  isSameBatchSerialForJWOEnabled: boolean,
  batchData: any[]
) => {
  if (!isSameBatchSerialForJWOEnabled) {
    return '';
  } else {
    return batchData?.[0]?.serialBatchNumber ?? '';
  }
};
