import React, { Component } from "react";
import "../../index.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";

export default class LabelTemplate1 extends Component {

    arabicHeaderWidth = '85%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="ColumnDiv" style={{ color: this.state.data.themeColor, width: '100%' }}>
                <div className='ColumnDiv'
                    style={{
                        width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
                    }}>
                    {this.state.data.showDocumentType && this.getDocumentType()}
                    {this.state.data.showCompanyName && this.getCompanyName()}
                </div>

                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(100, this.state.data.printScale), {
                    right: 0,
                    // top: 0,
                })}
            </div>
        );
    }

    getDocumentType() {
        return <div className="RowDiv"
            style={{
                alignItems: 'center',
                marginBottom: getVW(20),
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "rgb(100,100,100)",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getPackageInfo() {
        return <div className="RowDiv"
            style={{
                marginBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                justifyContent: 'flex-end',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "rgb(100,100,100)",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                }}>
                {getLocalisedText("package").toUpperCase() + " : " + this.state.data.packageCount}
            </text>
        </div>;
    }

    getCompanyName() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: Utility.getIsArabicLang() ? '100%' : '70%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    addTermsNConditionsView() {
        return (
            <div className='' style={{}}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        getLocalisedText(this.state.data.termsAndCondition),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}

                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        getLocalisedText(this.state.data.notes),
                        "100%",
                        "gray",
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'center', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    {this.getPackageInfo()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(35, this.state.data.printScale))}
                    {this.addCompanyNameAndDocTypeLabels()}
                    {/* {this.state.data.showFrom && this.getCompanyAddressSection()} */}

                    {ComponentManager.addVerticalSpace(10)}
                    {
                        Utility.isShowLinkDocument(this.state.data) &&
                        this.getRowValueSection(
                            getLocalisedText('order_number'),
                            this.state.data.linkDocumentNumber,
                            this.state.data.themeColor,
                        )
                    }
                    {
                        Utility.isShowLinkDocument(this.state.data) &&
                        (this.state.data.customerOrderNumber
                            && this.state.data.customerOrderNumber !== null
                            && this.state.data.customerOrderNumber !== '') &&
                            this.getRowValueSection(
                                getLocalisedText('con_number'),
                                this.state.data.customerOrderNumber,
                                this.state.data.themeColor,
                            )
                    }
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(35, this.state.data.printScale))}
                    {/* section 2 */}
                    <div className='RowDiv parent-width' style={{alignItems: 'flex-start'}}>
                        {this.getShipToInfoSection()}
                        <div className='parent-width ColumnDiv'>
                            <div className='parent-width RowDiv'>
                                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                            </div>
                            {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(5)}
                            <div className='parent-width RowDiv' style={{
                                border: '1px solid black',
                                paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                            }}>
                                {this.getEditableDocumentTable()}
                            </div>
                        </div>
                    </div>
                    <div className='RowDiv parent-width' style={{ alignItems: 'flex-start' }}>
                        {this.getShipFromInfoSection()}
                        <div className='parent-width ColumnDiv' style={{
                            paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                            paddingBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                        }}>
                            <div className='parent-width RowDiv'>
                                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                            </div>
                            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(35, this.state.data.printScale))}
                            {this.getIsFragileSection()}
                        </div>
                    </div>
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                    {this.getProductTable()}
                    {/* section 3 */}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                </div>
                <div className='parent-width'>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} isThemeColor/>}
                    {this.addTermsNConditionsView()}
                </div>
            </div>
        );
    }

    getShipToInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getShipToAddressSection()}
            </div>
        )
    }

    getShipToAddressSection() {
        return <div className='ColumnDiv parent-width'>
            {this.state.data.showClientBillToAddress && this.getDescriptionSection(
                this.state.data.billToTitle,
                Utility.getClientBillToAddress(this.state.data),
                "70%",
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                Utility.getOwnerType(this.state.data, AddressType.billTo)
            )}
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(35)}
        </div>;
    }


    getShipFromInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    width: '100%'
                }}
            >
                {this.getShipFromAddressSection()}
            </div>
        )
    }
    getShipFromAddressSection() {
        return <div className='ColumnDiv parent-width'>
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addDivider({ backgroundColor: 'red', height: getVW(1), width: '80%' })}
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(35)}
            {this.state.data.showClientShipToAddress && this.getDescriptionSection(
                this.state.data.shipToTitle,
                Utility.getClientShipToAddress(this.state.data),
                "70%",
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                Utility.getOwnerType(this.state.data, AddressType.shipTo)
            )}
        </div>;
    }

    getCompanyAddressSection() {
        return (
            this.getDescriptionSection(
                "",
                Utility.getCompanyAddress(this.state.data),
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '50%',
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                Utility.getOwnerType(this.state.data, AddressType.from),
                this.state.data.printScale
            )
        )
    }

    getDescriptionSection(
        title,
        description,
        width = "20%",
        titleColor = "black",
        headerSeparator = false,
        textColor = "#000000"
    ) {
        var flexDirection = 'row'
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
        }
        return (
            <div className="ColumnDiv" style={{ width: width }}>
                {title && (
                    <div className="RowDiv" style={{ width: '100%', flexDirection: flexDirection }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                color: titleColor,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                            }}>
                            {getLocalisedText(title).toUpperCase()}
                        </text>
                    </div>
                )}
                {title && headerSeparator && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)), width: '100%' })}
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: '100%',
                        marginTop: getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        color: textColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                    }} dangerouslySetInnerHTML={{ __html: getLocalisedText(description) }}>
                </div>
            </div>
        );
    }

    getRowValueSection(title, value) {
        var flexDirection = 'row'
        var widthDiv = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
            widthDiv = '100%'
        }
        return (
            <div
                className="RowDiv"
                style={{
                    paddingTop: getVW(Utility.convertToPrintScale(4, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(4, this.state.data.printScale)),
                    flex: 1,
                }}
            >
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: this.state.data.themeColor,
                        // width: isValueLeftAligned ? getVW(150) : widthDiv,
                        flexDirection: flexDirection,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                    }}>
                    {title.toUpperCase()}
                </text>
                : &nbsp;
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        flexDirection: flexDirection,
                        width: widthDiv,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                    }}>
                    {value}
                </text>
            </div>
        );
    }
    getEditableDocumentTable() {
        return ComponentManager.getDKLabelTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked
        )
    }

    getProductTable() {
        if (Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKShipProductTable(
                Utility.getTableColumnForAccountProduct(this.state.data.type),
                this.getShipProductTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getIsFragileSection() {
        return <div className='RowDiv parent-width parent-height'
            style={{
                justifyContent: 'center',
                color: 'red',
                fontWeight: 'bold',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
            }}
            >
            {this.state.data.isFragile}
        </div>
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.headerTextSize = FONT_SIZE.medium
        style.itemTextSize = FONT_SIZE.medium
        style.headerBackgroundColor = "white";
        style.headerWidth = "45%";
        return style;
    }

    getShipProductTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['20%', '30%', '50%'] : ['29.5%', '40.5%', '30%']
        return style;
    }
}
