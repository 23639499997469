import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate, getDateFrom, getDateString } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class JobWorkOutOrderTableItem {
    lineNumber: number;
    name: string;
    code: string;
    // kindOfPacking: string;
    description: string;
    dueOn: string;
    quantity: string;
    rate: string;
    per: string;
    unitPrice: string;
    uom: string;
    quantityWithUom: string;
    discount: number;
    amount: string;
    currency: string;
    rawItemValue: any;
    data: any;
    constructor(lineItem: any, data: any, currency: string, dateFormat: any) {
        this.lineNumber = lineItem.lineNumber
        this.name = this.getProductName(data, lineItem)
        // this.kindOfPacking = lineItem.kindOfPacking ? lineItem.kindOfPacking : ''
        this.code = lineItem.code
        this.description = lineItem.description ? lineItem.description : ''
        this.dueOn = lineItem.dueOn ? getConvertedDate(convertDateFromERPFormatToDocFormat(lineItem.dueOn), dateFormat) : ''
        this.quantity = lineItem.quantity ? lineItem.quantity : ''
        this.unitPrice = lineItem.unitPrice
        this.uom = this.getProductUOM(lineItem)
        this.quantityWithUom = lineItem.quantity + ' x ' + this.getProductUOM(lineItem)
        this.per = lineItem.per ? lineItem.per : ''
        this.rate = lineItem.rate ? lineItem.rate : ''
        this.discount = lineItem.discount
        this.amount = lineItem.amount ? Utility.toCurrencyFormat(lineItem.amount, currency) : ''
        this.currency = currency
        this.rawItemValue = lineItem
        this.data = data
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var footerList = TableFooterUtility.getFooterListByType('job_work_out_order')
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        if (lineItem.name && lineItem.name !== null) {
            return getLocalisedText(lineItem.name)
        }
        return ''
    }

    getProductUOM(lineItem: any): string {
        var uom = lineItem.uom ? lineItem.uom : ''
        if (TemplateSettingsManager.getIsAlterUOMSelected(undefined)) {
            if (lineItem.alterUOM !== undefined && lineItem.alterUOM !== null) {
                return uom + ' ' + lineItem.alterUOM
            }
        }
        return uom
    }

    getProductHSN(lineItem: any): string {
        if (TemplateSettingsManager.getIsHSNSelected(undefined)) {
            if (lineItem.hsnOrSacCodeString !== undefined && lineItem.hsnOrSacCodeString !== null) {
                return lineItem.hsnOrSacCodeString
            }
        }
        return ''
    }

    getDiscount() {
        if (this.rawItemValue.discountInPercent !== undefined && this.rawItemValue.isDiscountInPercent !== null) {
            if (this.rawItemValue.discountInPercent) {
                return Utility.toCurrencyFormat(this.discount, '') + '%'
            }
        }

        return Utility.toCurrencyFormat(this.discount, this.currency)
    }


    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            // case TableColumnPopupType.kindOfPacking:
            //     return getLocalisedText(this.kindOfPacking)
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name) + this.getProductHSN(this.rawItemValue)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.productNameWithDescription:
                return '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description) + this.getProductHSN(this.rawItemValue)
            // case TableColumnPopupType.dueOn:
            //     return this.dueOn
            case TableColumnPopupType.quantity:
                return this.quantity
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.quantityWithUom:
                return this.quantityWithUom ? this.quantityWithUom : ''
            case TableColumnPopupType.unitPrice:
                return Utility.toCurrencyFormat(parseFloat(this.unitPrice) * 1, this.currency)
            case TableColumnPopupType.productDiscount:
                return this.getDiscount()
            // case TableColumnPopupType.rate:
            //     return this.rate
            // case TableColumnPopupType.per:
            //     return this.per
            case TableColumnPopupType.amount:
                return this.amount
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        if (cfType === "DATE") {
                            value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                        } else {
                            value = field.value
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.name),
            // getLocalisedText(this.kindOfPacking),
            getLocalisedText(this.description),
            this.dueOn,
            this.quantity,
            this.rate,
            this.per,
            this.amount
        ]
    }
}
