import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  BARCODE_FORMAT,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import Utility from '../../Utility/Utility';
import { defaultLabelTemplateSettings } from './LabelTemplateHelper';

const barcodeTypes = Object.keys(BARCODE_FORMAT).map((format) => ({
  name: BARCODE_FORMAT[format],
  value: format
}));

interface LabelTemplateSettingsProps {
  labelSettings: any;
  setLabelSettings: (value: any) => void;
  setShowPopup: (value: boolean) => void;
}

const LabelTemplateSettings: React.FC<LabelTemplateSettingsProps> = ({
  labelSettings,
  setLabelSettings,
  setShowPopup
}: LabelTemplateSettingsProps) => {
  const [templateSettings, setTemplateSettings] = useState<any>({});

  const onFormUpdate = (updatedValue: any) => {
    setTemplateSettings((oldValue: any) => ({ ...oldValue, ...updatedValue }));
  };

  const validateLabelSettings = () => {
    if (templateSettings.width === '' || isNaN(templateSettings.width)) {
      return false;
    } else if (Utility.isEmptyValue(templateSettings.barcodeType?.value)) {
      return false;
    } else if (
      templateSettings.barcodeHeight === '' ||
      isNaN(templateSettings.barcodeHeight)
    ) {
      return false;
    } else if (
      templateSettings.xDimension === '' ||
      isNaN(templateSettings.xDimension)
    ) {
      return false;
    } else if (
      templateSettings.quietZone === '' ||
      isNaN(templateSettings.quietZone)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    let barcodeType = {
      name: BARCODE_FORMAT[labelSettings.barcodeType],
      value: labelSettings.barcodeType
    };
    setTemplateSettings({ ...labelSettings, barcodeType });
  }, [labelSettings]);

  return (
    <PopupWrapper
      type={POPUP_TYPE.POPUP}
      title={`Label Template Settings`}
      btnList={[
        {
          title: 'Reset Values',
          class: 'text-blue mr-s',
          clickAction: POPUP_CLICK_TYPE.RESET_BARCODE_VALUES_BTN
        },
        {
          title: 'Cancel',
          class: 'bg-gray1 border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: 'Save',
          class: 'bg-app text-white',
          clickAction: POPUP_CLICK_TYPE.CONFIRM
        }
      ]}
      clickAction={({ type }: any) => {
        if (type === POPUP_CLICK_TYPE.CLOSE_POPUP) {
          setShowPopup(false);
        } else if (
          type === POPUP_CLICK_TYPE.CONFIRM &&
          validateLabelSettings()
        ) {
          setLabelSettings({
            ...templateSettings,
            barcodeType: templateSettings.barcodeType?.value
          });
          setShowPopup(false);
        } else if (type === POPUP_CLICK_TYPE.RESET_BARCODE_VALUES_BTN) {
          setLabelSettings(defaultLabelTemplateSettings);
        }
      }}
      height={'auto'}
      width={400}
      disableClickOutside={true}
    >
      <div className="parent-width p-s justify-content-between border-radius-m">
        <div
          className="row mt-xs  mb-5"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Label Width (in inch)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.width}
            required={true}
            onChange={(value: any) => onFormUpdate({ width: value })}
            canValidate={true}
            validator={(value: number) => {
              return value > 0;
            }}
            errorMessage="Please enter valid label width"
          />
          <DKInput
            title={'Label Height (in inch)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.height}
            required={false}
            onChange={(value: any) => onFormUpdate({ height: value })}
          />
        </div>
        <div
          className="row mt-xs  mb-5"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Barcode Type'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={templateSettings.barcodeType}
            formatter={(value: any) => value.name}
            required={true}
            onChange={(value: any) => onFormUpdate({ barcodeType: value })}
            dropdownConfig={{
              data: barcodeTypes,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj.name}`} />;
              }
            }}
            canValidate={true}
            validator={(option: any) => {
              return Utility.isNotEmpty(option?.value);
            }}
            errorMessage="Please select valid barcode type"
          />
          <DKInput
            title={'Barcode Height (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.barcodeHeight}
            required={true}
            onChange={(value: any) => onFormUpdate({ barcodeHeight: value })}
            canValidate={true}
            validator={(value: number) => {
              return value > 0;
            }}
            errorMessage="Please enter valid barcode height"
          />
        </div>
        <div
          className="row mt-r  mb-5"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'X Dimension (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.xDimension}
            required={true}
            onChange={(value: any) => onFormUpdate({ xDimension: value })}
            canValidate={true}
            validator={(value: number) => {
              return value > 0;
            }}
            errorMessage="Please enter valid x dimension"
          />
          <DKInput
            title={'Quiet Zone (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.quietZone}
            required={true}
            onChange={(value: any) => onFormUpdate({ quietZone: value })}
            canValidate={true}
            validator={(value: number) => {
              return value > 0;
            }}
            errorMessage="Please enter valid quiet zone"
          />
        </div>
        <div
          className="row mt-r  mb-5"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Row Height (in inch)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={templateSettings.rowHeight}
            required={false}
            onChange={(value: any) => onFormUpdate({ rowHeight: value })}
          />
        </div>
      </div>
    </PopupWrapper>
  );
};

export default LabelTemplateSettings;
