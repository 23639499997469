import React from 'react'
import { AccountTable } from '../DKUILibrary/dktable/account/AccountTable'
import DKDocumentTable from '../DKUILibrary/dktable/DKDocumentTable'
import { FulfillmentTable } from '../DKUILibrary/dktable/fulfillment/FulfillmentTable'
import { GoodsReceiptTable } from '../DKUILibrary/dktable/goodsReceipt/GoodsReceiptTable'
import { ProductTable } from '../DKUILibrary/dktable/product/ProductTable'
import { PaymentTable } from '../DKUILibrary/dktable/payment/PaymentTable'
import Utility, { getIsAccountModule, getIsAgedReceivable, getIsBOMModule, getIsExpenseBill, getIsFulfillment, getIsGoodsReceipt, getIsInspectionReport, getIsJobWorkOutOrder, getIsJournalEntry, getIsMachine, getIsMakePayment, getIsPackList, getIsPickList, getIsQCDocument, getIsQCInspection, getIsReceivePayment, getIsSOACustomer, getIsSOAVendor, getIsSalesReturn, getIsSecurityGateEntry, getIsShipList, getIsStockAdjustment, getIsStockIssue, getIsStockRequest, getIsStockTransfer, getIsWorkOrder } from '../Utilities/Utility'
import { JournalEntryTable } from '../DKUILibrary/dktable/journalEntry/JournalEntryTable'
import { PickingListTable } from '../DKUILibrary/dktable/pickingList/PickingListTable'
import { PackingListTable } from '../DKUILibrary/dktable/packingList/PackingListTable'
import { WorkOrderTable } from '../DKUILibrary/dktable/workOrder/WorkOrder'
import { StockTransferTable } from '../DKUILibrary/dktable/stockTransfer/StockTransferTable'
import { StockAdjustmentTable } from '../DKUILibrary/dktable/stockAdjustment/StockAdjustmentTable'
import { QCDocumentTable } from '../DKUILibrary/dktable/qcDocument/QCDocumentTable'
import { SalesReturnTable } from '../DKUILibrary/dktable/salesReturn/SalesReturnTable'
import { JobWorkOutOrderTable } from '../DKUILibrary/dktable/jobWorkOutOrder/JobWorkOutOrderTable'
import { BOMTable } from '../DKUILibrary/dktable/bom/BOMTable'
import { StockRequestTable } from '../DKUILibrary/dktable/stockRequest/StockRequestTable'
import { StockIssueTable } from '../DKUILibrary/dktable/stockIssue/StockIssueTable'
import TemplateSettingsManager from './TemplateSettingsManager'
import { CONSTANTS, TableColumnPopupType } from '../Constants/Constants'
import TableFooterUtility from '../Utilities/TableFooterUtility'
import ComponentManager from './ComponentManager'
import MachineSchedulerHelper from '../Templates/MachineSchedulerTemplates/MachineSchedulerHelper'
import { SecurityGateTable } from '../DKUILibrary/dktable/secutityGateEntry/SecurityGateTable'
import { QCInspectionTable } from '../DKUILibrary/dktable/qcInspection/QCInspectionTable'
import { SOACustomerTable } from '../DKUILibrary/dktable/soaCustomer/SOACustomerTable'
import { SOAVendorTable } from '../DKUILibrary/dktable/soaVendor/SOAVendorTable'
import { AgedReceivableTable } from '../DKUILibrary/dktable/agedReceivable/AgedReceivableTable'

interface Props {
    tableColumnOptions: any
    tableStyle: any
    data: any
    stockTableCallback: any
    onUpdateDataCallback: any
    paymentTableCallback: any
    additionalTableCallback: any
    productLineItems: any
    subType: string
}

interface States {
}

export default class TableComponentManger extends React.Component<Props, States> {
    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        )
    }

    renderTable = () => {
        if(this.isContainsProductLineItem()) {
            if (getIsShipList(this.props.data.type)) {
                return this.renderPickingListTable(this.isContainsProductLineItem())
            }
            return this.renderAccountProductTable()
        }
        else if(getIsAccountModule(this.props.data, undefined)) {
            return this.renderAccountTable()
        }
        else if (getIsExpenseBill(this.props.data.type, this.props.data.lineItems)) {
            return this.renderAccountTable()
        }
        else if (getIsFulfillment(this.props.data.type)) {
            return this.renderFulfillmentTable()
        }
        else if (getIsGoodsReceipt(this.props.data.type)) {
            return this.renderGoodsReceiptTable()
        }
        else if (getIsMakePayment(this.props.data.type)) {
            return this.renderReceiptTable()
        }
        else if (getIsReceivePayment(this.props.data.type)) {
            return this.renderReceiptTable()
        }
        else if (getIsJournalEntry(this.props.data.type)) {
            return this.renderJournalEntryTable()
        }
        else if (getIsPickList(this.props.data.type)) {
            return this.renderPickingListTable(false)
        }
        else if (getIsPackList(this.props.data.type)) {
            return this.renderPackingListTable()
        }
        else if (getIsShipList(this.props.data.type)) {
            return <></>
        }
        else if (getIsWorkOrder(this.props.data.type)) {
            return this.renderWorkOrderTable()
        }
        else if (getIsStockTransfer(this.props.data.type)) {
            return this.renderStockTransferTable()
        }
        else if (getIsStockAdjustment(this.props.data.type)) {
            return this.renderStockAdjustmentTable()
        }
        else if (getIsQCDocument(this.props.data.type)) {
            return this.renderQCDocumentTable()
        }
        else if (getIsQCInspection(this.props.data.type)) {
            return this.renderQCInspectionTable()
        }
        else if (getIsInspectionReport(this.props.data.type)) {
            return undefined
        }
        else if (getIsSalesReturn(this.props.data.type)) {
            return this.renderSalesReturnTable()
        }
        else if (getIsJobWorkOutOrder(this.props.data.type)) {
            return this.renderJobWorkOutOrderTable()
        }
        else if (getIsBOMModule(this.props.data.type)) {
            return this.renderBOMTable()
        }
        else if (getIsStockRequest(this.props.data.type)) {
            return this.renderStockRequestTable()
        }
        else if (getIsStockIssue(this.props.data.type)) {
            return this.renderStockIssueTable()
        }
        else if (getIsSecurityGateEntry(this.props.data.type)) {
            return this.renderSecurityGateTable()
        }
        else if (getIsSOACustomer(this.props.data.type)) {
            return this.renderSOACustomer()
        }
        else if (getIsSOAVendor(this.props.data.type)) {
            return this.renderSOAVendor()
        }
        else if (getIsAgedReceivable(this.props.data.type)) {
            return this.renderAgedReceivable()
        }

        else if (getIsMachine(this.props.data.type)) {
            if (this.props.subType === 'SOP') {
                return this.renderMachineSchedulerSOPTable();
            } else if (this.props.subType === 'Material') {
                return this.renderMachineScheduleMaterialTable();
            }
        }
        return this.renderProductTable()
    }

    isContainsProductLineItem = () => {
        var isContains = false
        if (
            this.props.productLineItems !== undefined &&
            this.props.productLineItems !== null
        ) {
            if (this.props.productLineItems.length > 0) {
                isContains = true
            }
        }
        return isContains
    }

    getIsMultiProductTable = () => {
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }

        let isMultiProductTable = false
        if (grouping === CONSTANTS.PRODUCT_GROUP) {
            isMultiProductTable = TemplateSettingsManager.getIsMultiProductTable()

            if (isMultiProductTable) {
                var productGroupLineItems = this.props.data.productGroupLineItems ?? []
                if (TemplateSettingsManager.getIsShowOptionalProductGroupTable()) {
                    productGroupLineItems = this.props.data.productGroupLineOptionalItems ?? []
                }
                if (productGroupLineItems.length <= 0) {
                    isMultiProductTable = false
                }
            }
        }
        
        return isMultiProductTable
    }

    updateTableColumnOptionsForMultiProductTable = () => {
        let tableColumnOptions = JSON.parse(JSON.stringify(this.props.tableColumnOptions))
        let newTableColumnOptions: any[] = []
        let footerList = TableFooterUtility.getFooterListByType('product')
        tableColumnOptions.forEach((element: any) => {
            if(!footerList.includes(element.type)) {
                newTableColumnOptions.push(element)
            }
            // console.log('newTableColumnOptions', newTableColumnOptions)
            // console.table(newTableColumnOptions)
            if (element.type === TableColumnPopupType.globalDiscount ||
                element.type === TableColumnPopupType.additionalCharge ||
                element.type === TableColumnPopupType.tax ||
                element.type === TableColumnPopupType.subTotalAmount ||
                element.type === TableColumnPopupType.totalAmount) {
                newTableColumnOptions.push(element)
            }
        });

        newTableColumnOptions.forEach((element: any) => {
            if (element.type === TableColumnPopupType.globalDiscount ||
                element.type === TableColumnPopupType.additionalCharge ||
                element.type === TableColumnPopupType.subTotalAmount ||
                element.type === TableColumnPopupType.tax) {
                element.isSelected = false
            }
            else if (element.type === TableColumnPopupType.totalAmount) {
                element.isSelected = true
            } 
        });
        return newTableColumnOptions
    }

    renderProductTable = () => {
        if(this.getIsMultiProductTable()) {
            return this.renderMultiProductTable()
        }
        return this.renderProductSingleTable(undefined)
    }

    renderProductSingleTable = (documentData: any) => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        let tableColumnOptions: any = [...this.props.tableColumnOptions]
        let isSkipAdditionalCharge = false
        if(documentData === undefined) {
            documentData = this.props.data
        }
        else {
            tableColumnOptions = this.updateTableColumnOptionsForMultiProductTable()
            isSkipAdditionalCharge = true
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={ProductTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={ProductTable.getItemColumn(ProductTable.getData(documentData), this.props.tableStyle, false, this.props.data)}
                footerRow={ProductTable.getFooterRow(documentData, tableColumnOptions, this.props.tableStyle, isSkipAdditionalCharge)}
                stockColumn={ProductTable.getStockHeaderList(this.props.tableStyle, this.props.data)}
                stockData={ProductTable.getItemColumn(ProductTable.getStockData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showStockTable={this.props.data.showStockTable}
                stockTableCallback={this.props.stockTableCallback}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    ProductTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                paymentColumn={ProductTable.getPaymentHeaderList(this.props.tableStyle, this.props.data)}
                paymentData={ProductTable.getItemColumn(ProductTable.getPaymentData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showPaymentTable={this.props.data.showPaymentTable}
                paymentTableCallback={this.props.paymentTableCallback}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    ProductTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, false, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={ProductTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={tableColumnOptions}
            />
        </>
    }

    renderGroupHeaderTable = (groupName: string) => {
        var header = ProductTable.getProductGroupHeader(groupName, this.props.tableStyle, this.props.data)
        return <DKDocumentTable
            rawData={this.props.data}
            headerColumn={header}
            tableOption={ProductTable.getTableOption(!this.props.data.isReadOnlyMode)}
            data={[]}
            footerRow={[]}
            onUpdateRowIndexCallback={(rowIndex, action) => {
                console.log('onUpdateRowIndexCallback')
            }}
            onDataUpdateCallback={(footerRow) => {
                console.log('onDataUpdateCallback')
            }}
            isReadOnlyMode={true}
            showStockTable={false}
            showPaymentTable={false}
            documentType={this.props.data.type}
            onUpdateTableWidth={(header, percentage) => {
                console.log('onUpdateTableWidth')
            }}
            tableStyle={this.props.tableStyle}
            additionalTableCallback={this.props.additionalTableCallback}
            tableColumnOptions={[]}
        />
    }

    renderFooterTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data) ?? []
        let tableOption = ProductTable.getTableOption(!this.props.data.isReadOnlyMode)
        tableOption.isShowHeader = false
        tableOption.isShowSortButton = false
        return <DKDocumentTable
            rawData={this.props.data}
            headerColumn={header}
            tableOption={tableOption}
            data={[]}
            footerRow={ProductTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle, false)}
            onUpdateRowIndexCallback={(rowIndex, action) => {
                console.log('onUpdateRowIndexCallback')
            }}
            onDataUpdateCallback={(footerRow) => {
                console.log('onDataUpdateCallback')
            }}
            isReadOnlyMode={true}
            showStockTable={false}
            showPaymentTable={false}
            documentType={this.props.data.type}
            onUpdateTableWidth={(header, percentage) => {
                console.log('onUpdateTableWidth')
            }}
            tableStyle={this.props.tableStyle}
            additionalTableCallback={this.props.additionalTableCallback}
            tableColumnOptions={[]}
            additionalTablePrefix={'footer'}
        />
    }

    renderMultiProductTable = () => {
        let productGroupLineItems = this.props.data.productGroupLineItems
        if (TemplateSettingsManager.getIsShowOptionalProductGroupTable()) {
            productGroupLineItems = this.props.data.productGroupLineOptionalItems ?? []

            const productTableGroup = productGroupLineItems.filter((x: any) => !x.isOptionalGroup).sort((a: any, b: any) => a.index - b.index)
            var productGroupTable = productTableGroup.map((element: any) => {
                var newDocumentData = {
                    ...this.props.data,
                    lineItems: element.items,
                    subTotal: element.subTotal,
                    total: element.total
                }
                return <>
                    {this.renderGroupHeaderTable(element.name)}
                    {this.renderProductSingleTable(newDocumentData)}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.props.data.printScale))}
                </>
            });

            const optionalProductTableGroup = productGroupLineItems.filter((x: any) => x.isOptionalGroup && x.name !== 'OPTIONAL').sort((a: any, b: any) => a.index - b.index)
            var optionalProductTable = optionalProductTableGroup.map((element: any) => {
                let crmTableSettings = TemplateSettingsManager.getCRMTableSettings()
                let optionalGroupTotalType = crmTableSettings?.optionalGroupTotal ?? 'show_zero'
                let isIncludedTotal = optionalGroupTotalType === 'show_zero' ? false : true
                var newDocumentData = {
                    ...this.props.data,
                    lineItems: element.items,
                    subTotal: isIncludedTotal ? element.subTotal: 0,
                    total: isIncludedTotal ? element.total: 0,
                }
                return <>
                    {this.renderGroupHeaderTable(element.name)}
                    {this.renderProductSingleTable(newDocumentData)}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.props.data.printScale))}
                </>
            });

            var optionalHeader = <>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.props.data.printScale))}
                {this.renderGroupHeaderTable('OPTIONAL')}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.props.data.printScale))}
            </>

            let isHideOptional = TemplateSettingsManager.getIsHideOptionalProduct() ?? false

            return <>
                {productGroupTable}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.props.data.printScale))}
                {this.renderFooterTable()}
                {!isHideOptional && optionalProductTable.length !== 0 && optionalHeader}
                {!isHideOptional && optionalProductTable}
            </>

        }
        else {
            if (productGroupLineItems.length > 0) {
                const multiTable = productGroupLineItems.sort((a: any, b: any) => a.index - b.index).map((element: any) => {
                    var newDocumentData = {
                        ...this.props.data,
                        lineItems: element.items,
                        subTotal: element.subTotal,
                        total: element.total
                    }
                    return <>
                        {this.renderGroupHeaderTable(element.name)}
                        {this.renderProductSingleTable(newDocumentData)}
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.props.data.printScale))}
                    </>
                });

                return <>
                    {multiTable}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.props.data.printScale))}
                    {this.renderFooterTable()}
                </>
            }
        }

        return <> no data </>
    }

    renderAccountTable = () => {
        var header = AccountTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={AccountTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={AccountTable.getItemColumn(AccountTable.getData(this.props.data, this.props.tableColumnOptions), this.props.tableStyle, this.props.data)}
                footerRow={AccountTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle, this.props.data)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    AccountTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    AccountTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderFulfillmentTable = () => {
        var header = FulfillmentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={FulfillmentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={FulfillmentTable.getItemColumn(FulfillmentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={FulfillmentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    FulfillmentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    FulfillmentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={FulfillmentTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderGoodsReceiptTable = () => {
        var header = GoodsReceiptTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={GoodsReceiptTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={GoodsReceiptTable.getItemColumn(GoodsReceiptTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={GoodsReceiptTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    GoodsReceiptTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    GoodsReceiptTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={GoodsReceiptTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderReceiptTable = () => {
        var header = PaymentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PaymentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PaymentTable.getItemColumn(PaymentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={PaymentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle, this.props.data)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PaymentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PaymentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderJournalEntryTable = () => {
        var header = JournalEntryTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={JournalEntryTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={JournalEntryTable.getItemColumn(JournalEntryTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={JournalEntryTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    JournalEntryTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    JournalEntryTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderPickingListTable = (isContainsProductLineItem: boolean) => {
        var header = PickingListTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }

        let lineItems = PickingListTable.getData(this.props.data)
        if (isContainsProductLineItem) {
            lineItems = PickingListTable.getProductLineItemData(this.props.data, this.props.tableColumnOptions)
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PickingListTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PickingListTable.getItemColumn(lineItems, this.props.tableStyle, this.props.data)}
                footerRow={PickingListTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PickingListTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PickingListTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, isContainsProductLineItem, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderPackingListTable = () => {
        var header = PackingListTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PackingListTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PackingListTable.getItemColumn(PackingListTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={PackingListTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PackingListTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PackingListTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderAccountProductTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }

        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={ProductTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={ProductTable.getItemColumn(ProductTable.getAccountProductData(this.props.data), this.props.tableStyle, false, this.props.data)}
                footerRow={[]}
                stockColumn={undefined}
                stockData={undefined}
                showStockTable={false}
                stockTableCallback={undefined}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    ProductTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                paymentColumn={ProductTable.getPaymentHeaderList(this.props.tableStyle, this.props.data)}
                paymentData={ProductTable.getItemColumn(ProductTable.getPaymentData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showPaymentTable={this.props.data.showPaymentTable}
                paymentTableCallback={this.props.paymentTableCallback}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                additionalTablePrefix={'product'}
                onUpdateTableWidth={(header, percentage) => {
                    ProductTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, true, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={ProductTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderWorkOrderTable = () => {
        const {
            tableColumnOptions,
            tableStyle,
            data,
            onUpdateDataCallback,
            additionalTableCallback,
        } = this.props;
    
        var header = WorkOrderTable.getHeaderColumnList(tableColumnOptions, tableStyle, data);
        if (header === undefined) {
            header = [];
        }
    
        const rawData = data;
        const headerColumn = header;
        const tableOption = WorkOrderTable.getTableOption(!data.isReadOnlyMode);
        const tableData = WorkOrderTable.getItemColumn(WorkOrderTable.getData(data), tableStyle, data);
        const footerRow = WorkOrderTable.getFooterRow(data, tableColumnOptions, tableStyle);
        const isReadOnlyMode = data.isReadOnlyMode;
        const showStockTable = false;
        const showPaymentTable = false;
        const documentType = data.type;
        const groupHeaderIndexList = WorkOrderTable.getGroupHeaderIndexList();
    
        return (
            <>
                <DKDocumentTable
                    rawData={rawData}
                    headerColumn={headerColumn}
                    tableOption={tableOption}
                    data={tableData}
                    footerRow={footerRow}
                    onUpdateRowIndexCallback={(rowIndex, action) => {
                        WorkOrderTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                    } }
                    onDataUpdateCallback={(updatedFooterRow) => {
                        const newData = { ...data, footerRow: updatedFooterRow }
                        onUpdateDataCallback(newData)
                    } }
                    isReadOnlyMode={isReadOnlyMode}
                    showStockTable={showStockTable}
                    showPaymentTable={showPaymentTable}
                    documentType={documentType}
                    groupHeaderIndexList={groupHeaderIndexList}
                    tableStyle={tableStyle}
                    additionalTableCallback={additionalTableCallback}
                    tableColumnOptions={this.props.tableColumnOptions}
                    onUpdateTableWidth={(header, percentage) => {
                        WorkOrderTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                            this.props.onUpdateDataCallback(data)
                        })
                    }}/>
            </>
        );
    };
    renderStockTransferTable = () => {
        var header = StockTransferTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockTransferTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockTransferTable.getItemColumn(StockTransferTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={StockTransferTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockTransferTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockTransferTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderStockAdjustmentTable = () => {
        var header = StockAdjustmentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockAdjustmentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockAdjustmentTable.getItemColumn(StockAdjustmentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={StockAdjustmentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockAdjustmentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockAdjustmentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderQCDocumentTable = () => {
        var header = QCDocumentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={QCDocumentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={QCDocumentTable.getItemColumn(QCDocumentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={QCDocumentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    QCDocumentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    QCDocumentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderQCInspectionTable = () => {
        var header = QCInspectionTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={QCInspectionTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={QCInspectionTable.getItemColumn(QCInspectionTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={QCInspectionTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    QCInspectionTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    QCInspectionTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderSalesReturnTable = () => {
        var header = SalesReturnTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={SalesReturnTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={SalesReturnTable.getItemColumn(SalesReturnTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={SalesReturnTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    SalesReturnTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    SalesReturnTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={SalesReturnTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderJobWorkOutOrderTable = () => {
        var header = JobWorkOutOrderTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={JobWorkOutOrderTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={JobWorkOutOrderTable.getItemColumn(JobWorkOutOrderTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={JobWorkOutOrderTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    JobWorkOutOrderTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    JobWorkOutOrderTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderBOMTable = () => {
        var header = BOMTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }

        let tableOptions = BOMTable.getTableOption(!this.props.data.isReadOnlyMode);
        
        let bomData = BOMTable.getData(this.props.data);
        
        let itemsColumns = BOMTable.getItemColumn(bomData, this.props.tableStyle, false, this.props.data);
        
        let footerRow = BOMTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle);
        
        let getHeaderIndexes = BOMTable.getGroupHeaderIndexList();
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={tableOptions}
                data={itemsColumns}
                footerRow={footerRow}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    BOMTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    BOMTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={getHeaderIndexes}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderMachineSchedulerSOPTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        let sopData = MachineSchedulerHelper.getData(this.props.data, this.props.subType as any);

        let data = MachineSchedulerHelper.getItemColumn(
            sopData,
            this.props.tableStyle,
            false,
            this.props.data,
            this.props.subType as any
        );

        let footerRow = MachineSchedulerHelper.getFooterRow(
            this.props.data,
            this.props.tableColumnOptions,
            this.props.tableStyle
        );

        let tableOptions = BOMTable.getTableOption(
            !this.props.data.isReadOnlyMode
        );

        let headerIndexes = BOMTable.getGroupHeaderIndexList();
        return <>
        <DKDocumentTable
            rawData={this.props.data}
            headerColumn={header}
            tableOption={tableOptions}
            data={data}
            footerRow={footerRow}
            onUpdateRowIndexCallback={(rowIndex, action) => {
                BOMTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
            }}
            onDataUpdateCallback={(footerRow) => {
                var data = this.props.data
                data.footerRow = footerRow
                this.props.onUpdateDataCallback(data)
            }}
            isReadOnlyMode={this.props.data.isReadOnlyMode}
            showStockTable={false}
            showPaymentTable={false}
            documentType={this.props.data.type}
            onUpdateTableWidth={(header, percentage) => {
                BOMTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                    this.props.onUpdateDataCallback(data)
                })
            }}
            groupHeaderIndexList={headerIndexes}
            tableStyle={this.props.tableStyle}
            additionalTableCallback={this.props.additionalTableCallback}
            tableColumnOptions={this.props.tableColumnOptions}
        />
    </>
    }

    renderMachineScheduleMaterialTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        let sopData = MachineSchedulerHelper.getData(this.props.data, this.props.subType as any);

        let data = MachineSchedulerHelper.getItemColumn(
            sopData,
            this.props.tableStyle,
            false,
            this.props.data,
            this.props.subType as any
        );

        let footerRow = MachineSchedulerHelper.getFooterRow(
            this.props.data,
            this.props.tableColumnOptions,
            this.props.tableStyle
        );

        let tableOptions = BOMTable.getTableOption(
            !this.props.data.isReadOnlyMode
        );

        let headerIndexes = BOMTable.getGroupHeaderIndexList();
        return <>
        <DKDocumentTable
            rawData={this.props.data}
            headerColumn={header}
            tableOption={tableOptions}
            data={data}
            footerRow={footerRow}
            onUpdateRowIndexCallback={(rowIndex, action) => {
                BOMTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
            }}
            onDataUpdateCallback={(footerRow) => {
                var data = this.props.data
                data.footerRow = footerRow
                this.props.onUpdateDataCallback(data)
            }}
            isReadOnlyMode={this.props.data.isReadOnlyMode}
            showStockTable={false}
            showPaymentTable={false}
            documentType={this.props.data.type}
            onUpdateTableWidth={(header, percentage) => {
                BOMTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                    this.props.onUpdateDataCallback(data)
                })
            }}
            groupHeaderIndexList={headerIndexes}
            tableStyle={this.props.tableStyle}
            additionalTableCallback={this.props.additionalTableCallback}
            tableColumnOptions={this.props.tableColumnOptions}
        />
    </>
    }
    renderStockRequestTable = () => {
        var header = StockRequestTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockRequestTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockRequestTable.getItemColumn(StockRequestTable.getData(this.props.data), this.props.tableStyle, false, this.props.data)}
                footerRow={StockRequestTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockRequestTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockRequestTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={StockRequestTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderStockIssueTable = () => {
        var header = StockIssueTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockIssueTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockIssueTable.getItemColumn(StockIssueTable.getData(this.props.data), this.props.tableStyle, false, this.props.data)}
                footerRow={StockIssueTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockIssueTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockIssueTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={StockIssueTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }
    renderSecurityGateTable = () => {
        var header = SecurityGateTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }

        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={SecurityGateTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={SecurityGateTable.getItemColumn(SecurityGateTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={[]}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    SecurityGateTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockIssueTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderSOACustomer = () => {
        var header = SOACustomerTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={SOACustomerTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={SOACustomerTable.getItemColumn(SOACustomerTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={[]}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    SOACustomerTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    SOACustomerTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderSOAVendor = () => {
        var header = SOAVendorTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={SOAVendorTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={SOAVendorTable.getItemColumn(SOAVendorTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={[]}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    SOAVendorTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    SOAVendorTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderAgedReceivable = () => {
        var header = AgedReceivableTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={AgedReceivableTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={AgedReceivableTable.getItemColumn(AgedReceivableTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={[]}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    AgedReceivableTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    AgedReceivableTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }
}
