export const APP_NAME = {
    BOOKS: "BOOKS",
    DOC_BUILDER: "DOC_BUILDER"
}

export const ExportType = {
    PRINT: "Print",
    EMAIL: "Email",
    DOWNLOAD_PDF: "Download Pdf",
    VIEW_PDF: "View Pdf",
    PREVIEW_PDF: "Preview Pdf"
}

export const COLOR_BLUE = "#3773e1";
export const COLOR_LIGHTGRAY = "#f4f4f4";
export const COLOR_LIGHTGRAY2 = "rgb(230,230,230)";
export const COLOR_LIGHTGRAY3 = "rgb(80, 80, 80)";

export const COLOR_GRAY = "rgb(174,174,174)";

export const LINE_ITEM_HEIGHT = "2.8vw";
export const LINE_ITEM_HEIGHT_2 = "1.64706vw";
export const PIXEL_TO_VW = 14.3;

export const PAGE_WIDTH = "55.45vw";
export const PAGE_HEIGHT = "78.63vw";
export const PAGE_WIDTH_LANDSCAPE = "55.45vw"; // /1.418 = 55.45
export const PAGE_HEIGHT_LANDSCAPE = "39.11vw"; // 1.418 = 39.11
// export const PAGE_WIDTH = "80vw";
// export const PAGE_HEIGHT = "113.44vw";
export const PAGE_PADDING = "2.8vw";
export const NEGATIVE_PAGE_PADDING = "-2.8vw";
export const PAGE_PADDING_DOUBLE = "5.6vw";
export const PAGE_BORDER_PADDING = "2.0vw";
export const PAGE_BORDER_PADDING_HALF = "1.0vw";
export const PAGE_BORDER_PADDING_DOUBLE = "4.0vw";
export const PAGE_PRINT_RATIO = 1.7024;
export const US_CHEQUE_HEIGHT = 310;
export const US_CHEQUE_DETAIL_HEIGHT = 290;

export const PRINT_SCALE = 1.7
export const PREVIEW_SCALE = 0.85

export const EDITOR_WIDTH = 270

export const IMG_MAX_UPLOAD_SIZE = 500 * 1024;

export const LANDING_PAGE_URL = "https://invoice-template.deskera.com/"
export const DESKERA_URL = "https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool"
export const DESKERA_GO_URL = "https://gous.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool"

export const USERDEFAULT_savedData = "savedDocdata"
export const MOBILE_WIDTH = 800

// templateId === 36 || templateId === 38 || templateId === 40 || templateId === 42 || templateId === 43 || templateId === 44
export const INDIA_TEMPLATES_ID = [36, 38, 40, 42, 43, 44, 45, 46, 47, 48, 51, 61, 62, 63]

export const REMOVE_REPEATABLE_TABLE_HEADER = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63]

export const REDUCE_SPACING_TEMPLATE_ID = [46, 47, 48, 49, 50, 51, 64]

export const TABLE_GRID_TEMPLATE_ID = [43, 48]

export const REPEATABLE_HEADER_FOOTER_TEMPLATE_ID = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63];

export const FONT_SIZE = {
    xSmall: "0.7vw",
    small: "0.76vw",
    regular: "0.91vw",
    medium: "1.12vw",
    xMedium: "1.39vw",
    large: "1.67vw",
    xLarge: "2.09vw",
    xxLarge: "2.45vw",
    xxxLarge: "3.5vw"
}

export const FONT_SIZE_PRINT = {
    xSmall: "1.19vw",
    small: "1.3vw",
    regular: "1.55vw",
    medium: "1.91vw",
    xMedium: "2.36vw",
    large: "2.84vw",
    xLarge: "3.56vw",
    xxLarge: "4.17vw",
    xxxLarge: "5.96vw"
}

export const FONT_FAMILY = [
    { label: 'Default', value: '' },
    { label: 'Arial', value: 'Arial' },
    { label: 'Arial Black', value: 'Arial Black' },
    { label: 'Arial narrow', value: 'Arial narrow' },
    { label: 'Arial Nova', value: 'Arial Nova' },
    { label: 'Calibri', value: 'Calibri' },
    // { label: 'Calibri Light', value: 'Calibri Light' },
    // { label: 'Comic Sans', value: 'Comic Sans' },
    { label: 'Comic Sans MS', value: 'Comic Sans MS' },
    // { label: 'Cursive', value: 'cursive' },
    // { label: 'Fantasy', value: 'fantasy' },
    { label: 'Helvetica', value: 'helvetica' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Japanese', value: 'Japanese' },
    // { label: 'Javanese Text', value: 'Javanese Text' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Lao', value: 'Lao' },
    { label: 'Monospace', value: 'monospace' },
    { label: 'Montserrat', value: 'montserrat' },
    { label: 'Sans-serif', value: 'sans-serif' },
    { label: 'Segoe UI', value: 'Segoe UI' },
    { label: 'Simplified Arabic', value: 'Simplified Arabic' },
    { label: 'Simplified Chinese', value: 'Simplified Chinese' },
    { label: 'Serif', value: 'serif' },
    { label: 'System UI', value: 'system-ui' },
    { label: 'Times', value: 'times' },
]

export const ERROR_MESSAGES = {
    EMAIL_API_ERROR: 'Error occurred while sending email, please try again after sometime.',
    EMAIL_API_SUCCESS: 'Email has been sent successfully, please check your inbox.',
}

// A5      2480 x 1748
// A4      3508 x 2480
// A3      4961 x 3605
// Legel   4205 x 2551
// letter  3295 x 2551

export const PAPER_ORIENTATION = {
    landscape: '[LANDSCAPE]',
    portrait: '[PORTRAIT]',
}

export const PAPER_FORMAT = {
    a3: {
        height: 4961,
        width: 3605,
        ratio: 1.37614424411,
        ratioLandscape: 0.72666801,
        heightInMM: 420,
        widthInMM: 297,
    },
    a4: {
        height: 3508,
        width: 2480,
        ratio: 1.41451612903,
        ratioLandscape: 0.7069553,
        heightInMM: 297,
        widthInMM: 210,
    },
    a5: {
        height: 2480,
        width: 1748,
        ratio: 1.41876430206,
        ratioLandscape: 0.72666801,
        heightInMM: 210,
        widthInMM: 148,
    },
    legal: {
        height: 3900,
        width: 2250,
        ratio: 1.73333333333,
        ratioLandscape: 0.576923077,
        heightInMM: 356,
        widthInMM: 216,
    },
    letter: {
        height: 3000,
        width: 2250,
        ratio: 1.33333333333,
        ratioLandscape: 0.75,
        heightInMM: 279,
        widthInMM: 216,
    },
    three_five: {
        height: 1983.33333333,
        width: 680,
        ratio: 2.916666666666667,
        ratioLandscape: 0.342857143,
        heightInMM: 0,
        widthInMM: 0,
    },
    four_six: {
        height: 1458.33333333,
        width: 500,
        ratio: 2.916666666666667,
        ratioLandscape: 0.342857143,
        heightInMM: 0,
        widthInMM: 0,
    }
}

export const INDIA_STATE_MAPPING = [
    {
        code: "Andaman & Nicobar Islands",
        name: "Andaman & Nicobar Islands",
        stateCode: "35"
    },
    { code: "Andhra Pradesh", name: "Andhra Pradesh", stateCode: "37" },
    { code: "Arunachal Pradesh", name: "Arunachal Pradesh", stateCode: "12" },
    { code: "Assam", name: "Assam", stateCode: "18" },
    { code: "Bihar", name: "Bihar", stateCode: "10" },
    { code: "Chandigarh", name: "Chandigarh", stateCode: "04" },
    { code: "Chhattisgarh", name: "Chhattisgarh", stateCode: "22" },
    {
        code: "Dadra & Nagar Haveli",
        name: "Dadra & Nagar Haveli",
        stateCode: "26"
    },
    { code: "Daman & Diu", name: "Daman & Diu", stateCode: "25" },
    { code: "Delhi", name: "Delhi", stateCode: "07" },
    { code: "Goa", name: "Goa", stateCode: "30" },
    { code: "Gujarat", name: "Gujarat", stateCode: "24" },
    { code: "Haryana", name: "Haryana", stateCode: "06" },
    { code: "Himachal Pradesh", name: "Himachal Pradesh", stateCode: "02" },
    { code: "Jammu & Kashmir", name: "Jammu & Kashmir", stateCode: "01" },
    { code: "Jharkhand", name: "Jharkhand", stateCode: "20" },
    { code: "Karnataka", name: "Karnataka", stateCode: "29" },
    { code: "Kerala", name: "Kerala", stateCode: "32" },
    { code: "Lakshadweep", name: "Lakshadweep", stateCode: "31" },
    { code: "Madhya Pradesh", name: "Madhya Pradesh", stateCode: "23" },
    { code: "Maharashtra", name: "Maharashtra", stateCode: "27" },
    { code: "Manipur", name: "Manipur", stateCode: "14" },
    { code: "Meghalaya", name: "Meghalaya", stateCode: "17" },
    { code: "Mizoram", name: "Mizoram", stateCode: "15" },
    { code: "Nagaland", name: "Nagaland", stateCode: "13" },
    { code: "Odisha", name: "Odisha", stateCode: "21" },
    { code: "Puducherry", name: "Puducherry", stateCode: "34" },
    { code: "Punjab", name: "Punjab", stateCode: "03" },
    { code: "Rajasthan", name: "Rajasthan", stateCode: "08" },
    { code: "Sikkim", name: "Sikkim", stateCode: "11" },
    { code: "Tamil Nadu", name: "Tamil Nadu", stateCode: "33" },
    { code: "Telangana", name: "Telangana", stateCode: "36" },
    { code: "Tripura", name: "Tripura", stateCode: "16" },
    { code: "Uttar Pradesh", name: "Uttar Pradesh", stateCode: "09" },
    { code: "Uttarakhand", name: "Uttarakhand", stateCode: "05" },
    { code: "West Bengal", name: "West Bengal", stateCode: "19" },
    { code: "Ladakh", name: "Ladakh", stateCode: "38" }
];

export const TEXT_PLACEHOLDER = [
    { label: 'none', value: '' },
    { label: 'company_name', value: 'companyName' },
    { label: 'contact_name', value: 'contactName' },
    { label: 'document_date', value: 'documentDate' },
    { label: 'document_type', value: 'documentType' },
    { label: 'current_date', value: 'currentDate' },
    { label: 'total', value: 'total' },
    { label: 'amount_in_words', value: 'amountInWords' },
];

export const CONSTANTS = {
    FIXED_ALLOWANCE : "Fixed Allowance",
    FIXED_ALLOWANCE_TYPE : "fixedAllowance",
    EMPTY_SPACE: "",
    PRODUCT_GROUP: "product_group"
};

export const CURRENCY = {
    ZWD: '$',
    ZMW: 'ZK',
    ZMK: 'ZK',
    ZAR: 'R',
    YER: '﷼',
    XPF: '₣',
    XOF: 'CFA',
    XCD: '$',
    XAF: 'FCFA',
    WST: 'WS$',
    VUV: 'VT',
    VND: '₫',
    VEF: 'Bs',
    UZS: 'лв',
    UYU: '$U',
    USD: '$',
    UGX: 'USh',
    UAH: '₴',
    TZS: 'TSh',
    TWD: 'NT$',
    TTD: 'TT$',
    TRY: '₺',
    TOP: 'T$',
    TND: 'د.ت',
    TMT: 'T',
    TMM: 'T',
    TJS: 'ЅM',
    THB: '฿',
    SZL: 'E',
    SYP: '£',
    STD: 'Db',
    SSP: '£',
    SRD: '$',
    SOS: 'S',
    SLL: 'Le',
    SKK: 'Sk',
    SHP: '£',
    SGD: '$',
    SEK: 'kr',
    SDG: 'SD',
    SCR: '₨',
    SBD: 'Si$',
    SAR: '﷼',
    RWF: 'FRw',
    RUB: '₽',
    RSD: 'Дин.',
    RON: 'lei',
    QAR: '﷼',
    PYG: '₲',
    PLN: 'zł',
    PKR: '₨',
    PHP: '₱',
    PGK: 'K',
    PEN: 'S/',
    PAB: 'B/.',
    OMR: '﷼',
    NZD: '$',
    NPR: '₨',
    NOK: 'kr',
    NIO: 'C$',
    NGN: '₦',
    NAD: '$',
    MZN: 'MT',
    MYR: 'RM',
    MXN: '$',
    MWK: 'MK',
    MVR: 'Rf',
    MUR: '₨',
    MTL: '₤',
    MRO: 'UM',
    MOP: 'MOP$',
    MNT: '₮',
    MMK: 'K',
    MKD: 'ден',
    MGA: 'Ar',
    MDL: 'L',
    MAD: 'DH',
    LYD: 'ل.د',
    LVL: 'Ls',
    LTL: 'Lt',
    LSL: 'L',
    LRD: '$',
    LKR: '₨',
    LBP: '£',
    LAK: '₭',
    KZT: 'лв',
    KYD: '$',
    KWD: 'د.ك',
    KRW: '₩',
    KPW: '₩',
    KMF: 'CF',
    KHR: '៛',
    KGS: 'лв',
    KES: 'KSh,',
    JPY: '¥',
    JOD: 'د.ا',
    JMD: 'J$',
    ISK: 'kr',
    IRR: '﷼',
    IQD: 'ع.د',
    INR: '₹',
    ILS: '₪',
    IDR: 'Rp',
    HUF: 'Ft',
    HTG: 'G',
    HRK: 'kn',
    HNL: 'L',
    HKD: '$',
    GYD: 'GY$',
    GTQ: 'Q',
    GNF: 'FG',
    GMD: 'D',
    GIP: '£',
    GHS: '₵',
    GHC: 'GH₵',
    GEL: 'ლ',
    GBP: '£',
    FKP: '£',
    FJD: 'FJ$',
    EUR: '€',
    ETB: 'ብር',
    ERN: 'ናቕፋ',
    EGP: '£',
    EEK: 'EEK',
    DZD: 'دج',
    DOP: 'RD$',
    DKK: 'kr.',
    DJF: 'Fdj',
    CZK: 'Kč',
    CYP: '£',
    CVE: '$',
    CUC: '$',
    CRC: '₡',
    COP: '$',
    CNY: '¥',
    CLP: '$',
    CHF: 'CHF',
    CDF: 'FC',
    CAD: '$',
    BZD: 'BZ$',
    BYR: 'Br',
    BWP: 'P',
    BTN: 'Nu.',
    BSD: '$',
    BRL: 'R$',
    BOB: 'b$',
    BND: '$',
    BMD: '$',
    BIF: 'FBu',
    BHD: '.د.ب',
    BGN: 'лв',
    BDT: '৳',
    BBD: '$',
    BAM: 'KM',
    AZN: '₼',
    AWG: 'Afl',
    AUD: '$',
    ARS: '$',
    AOA: 'Kz',
    ANG: 'Naf',
    AMD: 'դր',
    ALL: 'Lek',
    AFN: '؋',
    AED: 'د.إ'
};


export const BARCODE_FORMAT = {
    CODE_128: 'CODE128',
    CODE_39: 'CODE39',
    EAN_8: 'EAN8',
    EAN_13: 'EAN13',
    UPC_A: 'UPC'
};

export const BARCODE_FONT_SIZE = 8;

export const DateFormat = {
    DD_MM_YYYY_SLASH: 'dd/mm/yyyy',
    MM_DD_YYYY_SLASH: 'mm/dd/yyyy',
    MMDDYYYY: 'mmddyyyy',
    DDMMYYYY: 'ddmmyyyy',
    DDMMMMYYYY: 'ddmmmmyyyy',
    DD_MM_YYYY_DASH: 'dd-mm-yyyy',
    MM_DD_YYYY_DASH: 'mm-dd-yyyy',
    DDMMMYYYY: 'ddmmmyyyy',
    MMMDDYYYY: 'mmmddyyyy',
    YYYY_MM_DD_DASH: 'yyyy-mm-dd',
    DD_MM_YYYY_HH_MM_SS_SLASH: 'dd/MM/yyyy hh:mm:ss a',
    D_MMM_YYYY_SPACE: 'd mmm yyyy'
}

export const EditorInfoType = {
    documentType: 'documentType',
    companyLogo: 'companyLogo',
    companyName: 'companyName',
    from: 'from',
    clientBillToAddress: 'clientBillToAddress',
    clientShipToAddress: 'clientShipToAddress',
    clientShipFromAddress: 'clientShipFromAddress',
    currency: 'currency',
    refNumber: 'refNumber',
    termsAndCondition: 'termsAndCondition',
    notes: 'notes',
    startDate: 'startDate',
    dueDate: 'dueDate',
    additionalDate: 'additionalDate',

    billToTitle: 'billToTitle',
    shipToTitle: 'shipToTitle',
    shipFromTitle: 'shipFromTitle',

    signature: 'signature',
    logo: 'logo',

    eInvoiceSection: 'eInvoiceSection',
    linkDocumentNumber: 'linkDocumentNumber',
    repeatableHeader: 'repeat ableHeader',
    repeatableHeaderFooter: 'repeatableHeaderFooter',
    repeatableFooter: 'repeatableFooter',
    headerFooter: "headerFooter",
    payslipField: "payslipField",
    payslipTable: "payslipTable",
    payslipOvertime: "payslipOvertime",
    payslipLOP: "payslipLOP",
    payslipBasic: "payslipBasic",
    termsAndConditionTitle: 'termsAndConditionTitle',
    notesTitle: 'notesTitle',
    additionalLeftFooterTable: 'additionalLeftFooterTable',
    watermark: 'watermark',
    placeOfSupply: 'placeOfSupply',
    sourceOfSupply: 'sourceOfSupply',
    destinationOfSupply: 'destinationOfSupply',
    paymentFooter: "paymentFooter",
    generatedMessage: "generatedMessage",
    documentDetails: "documentDetails",
    topCustomFieldTable: 'topCustomFieldTable',
    bottomCustomFieldTable: 'bottomCustomFieldTable',
    childCustomFieldTable: 'childCustomFieldTable',
    topSectionAboveTable: 'topSectionAboveTable',
    tableColumnAlignment: 'tableColumnAlignment',
    tableColumnVerticalAlignment: 'tableColumnVerticalAlignment',
    payslipEarningsFields: "payslipEarningsFields",
    accountProductTable: 'accountProductTable',
    exchangeRate: 'exchangeRate',
    exchangeRateTitle: 'exchangeRateTitle',
    checkTable: 'checkTable',
    checkTableCurrency: 'checkTableCurrency',
    isIncludesBomProductTable: 'isIncludesBomProductTable',
    documentTitle: 'documentTitle',
    hsnSacTaxTable: 'hsnSacTaxTable',
    approvalSection: 'approvalSection',
    customApprovalSection: 'customApprovalSection',
    pageCountSettings: 'pageCountSettings',
    paperMargin: 'paperMargin',
    draftWatermark: 'draftWatermark',
}

export const CurrencyFormatType = {
    SYMBOL: "symbol",
    NAME: "name",
    SYMBOL_NAME: "symbol_name",
    CUSTOM: "custom"
}

export const TableDimensionType = {
    ROW_HEIGHT: "rowHeight",
    PADDING_HORIZONTAL: "paddingHorizontal",
    PADDING_VERTICAL: "paddingVertical",
}

export const AdditionalTableType = {
    amountInWords: 'amountInWords',
    qrCode: 'qrCode',
    customText: 'customText',
    qrCodeWithText: 'qrCodeWithText',
    image: 'image',
    imageWithText: 'imageWithText',
    customField: 'customField',
    total: 'total',
    outstandingBalance: 'outstandingBalance',
    outstandingBalanceIncludesCurrent: 'outstandingBalanceIncludeCurrent',
    subTotalInWords: 'subTotalInWords',
    cgstInWords: 'cgstInWords',
    igstInWords: 'igstInWords',
    sgstInWords: 'sgstInWords',
    roundOffInWords: 'roundOffInWords',
    outstandingBalanceInWords: 'outstandingBalanceInWords',
    outstandingBalanceIncludesCurrentInWords: 'outstandingBalanceIncludesCurrentInWords',
    totalTaxAmountInWords: 'totalTaxAmountInWords',
    beforeTaxInWords: 'beforeTaxInWords',
    globalDiscountInWords: 'globalDiscountInWords',
    additionalChargeInWords: 'additionalChargeInWords',
}

export const DocumentSectionType = {
    header: 'header',
    footer: 'footer',
    data: 'data',
}

export const TableColumnPopupType = {
    lineNumber: 'lineNumber',
    name: "name",
    status: "status",
    code: 'code',
    remark: "remark",
    productCode: "productCode",
    productName: 'productName',
    description: 'description',
    quantity: 'quantity',
    unitPrice: 'unitPrice',
    tax: 'tax',
    discount: 'discount',
    amount: 'amount',
    paymentAmount: 'paymentAmount',
    taxAmount: 'taxAmount',
    accountName: 'accountName',
    totalTaxAmount: 'totalTaxAmount',
    snNumber: 'snNumber',
    productTax: 'productTax',
    productTaxAmount: 'productTaxAmount',
    productDiscount: 'productDiscount',
    uom: 'uom',
    amountBeforeTax: 'amountBeforeTax',
    requiredQty: 'requiredQty',
    requiredQuantity: 'requiredQuantity',
    committedQuantity: 'committedQuantity',
    receivedQuantity: 'receivedQuantity',
    serialBatch: 'serialBatch',
    orderNo: 'orderNo',
    warehouseCode: 'warehouseCode',
    warehouseName: 'warehouseName',
    paymentFooter: 'paymentFooter',
    beforeTax: 'beforeTax',
    outstandingAmount: 'outstandingAmount',
    amountInWords: 'amountInWords',
    productNameWithDescription: 'productNameWithDescription',
    invoiceNumber: 'invoiceNumber',
    billNumber: 'billNumber',
    totalInvoiceAmount: 'totalInvoiceAmount',
    totalBillAmount: 'totalBillAmount',
    paymentMade: 'paymentMade',
    currentDueAmount: 'currentDueAmount',
    currentBalance: 'currentBalance',
    paymentMedium: 'paymentMedium',
    date : 'date',
    journalEntryNumber: 'journalEntryNumber',
    accountCode: 'accountCode',
    credit: 'credit',
    debit: 'debit',
    barcode: 'barcode',
    unit: 'unit',
    packedQuantity: 'packedQuantity',
    invoiceOrQuotation: 'invoiceOrQuotation',
    quantityToPick: 'quantityToPick',
    quantityRequiredForOrder: 'quantityRequiredForOrder',
    id: 'id',
    picked: 'picked',
    delivery: "delivery",
    carrier: "carrier",
    numberOfCarton: "numberOfCarton",
    weight: "weight",
    trackingNumber: "trackingNumber",
    image: "image",
    productCustomField: "productCustomField",
    quantityWithUom: "quantityWithUom",
    serialNumber: "serialNumber",
    batchNumber: "batchNumber",
    manufacturingDate: "manufacturingDate",
    expiryDate: "expiryDate",
    globalDiscount: "globalDiscount",
    additionalCharge: "additionalCharge",
    accountCustomField: "accountCustomField",
    totalAmount: 'totalAmount',
    subTotalAmount: 'subtotal',
    outstandingBalance: 'outstandingBalance',
    outstandingBalanceIncludesCurrent: 'outstandingBalanceIncludesCurrent',
    hsn: 'hsn',
    roundingOff: 'roundingOff',
    orderPrepCompleted: 'orderPrepCompleted',
    sourceWarehouseName: 'sourceWarehouseName',
    sourceWarehouseCode: 'sourceWarehouseCode',
    destinationWarehouseName: 'destinationWarehouseName',
    destinationWarehouseCode: 'destinationWarehouseCode',
    productGlobalDiscount: 'productGlobalDiscount',
    productUnitPriceAfterDiscount: 'productUnitPriceAfterDiscount',
    productDiscountPerUnit: 'productDiscountPerUnit',
    cogs: 'cogs',
    expectedDeliveryDate: 'expectedDeliveryDate',
    contactName: 'contactName',
    contactCode: 'contactCode',
    parameterName: 'parameterName',
    parameterValue: 'parameterValue',
    qcIncoming: 'qcIncoming',
    qcFinal: 'qcFinal',
    fulfilledQty: 'fulfilledQty',
    returnedQty: 'returnedQty',
    rate: 'rate',
    per: 'per',
    kindOfPacking: 'kindOfPacking',
    dueOn: 'dueOn',
    cost: 'cost',
    targetWarehouseName: 'targetWarehouseName',
    targetWarehouseCode: 'targetWarehouseCode',
    selectedRow: 'selectedRow',
    selectedRack: 'selectedRack',
    selectedBin: 'selectedBin',
    requestedQty: 'requestedQty',
    issuedQty: 'issuedQty',
    refDocument: 'refDocument',
    refDocumentDate: 'refDocumentDate',
    qtyConvertedToWo: 'qtyConvertedToWo',
    availableQuantity: 'availableQuantity',
    POBillNoDate: 'POBillNoDate',
    shortExcessRejectedQty: 'shortExcessRejectedQty',
    acceptedQty: 'acceptedQty',
    basicAmt: 'basicAmt',
    charges: 'charges',
    documentDate: 'documentDate',
    documentType: 'documentType',
    documentNumber: 'documentNumber',
    currency: 'currency',
    memo: 'memo',
    particular: 'particular',
    amountInBase: 'amountInBase',
    balanceInBase: 'balanceInBase',
    serialBatchCustomFields: 'serialBatchCustomFields',
    supplierPartDescription: "supplierPartDescription",
    supplierPartName: "supplierPartName",
    supplierPartNumber: "supplierPartNumber",
    documentDueDate: "documentDueDate",
    balance: "balance",
    slab1: "slab1",
    slab2: "slab2",
    slab3: "slab3",
    slab4: "slab4",
    batchQuantity: "batchQuantity",
}

export const SignatureColumnType = {
    name: 'name',
    signature: 'signature',
    preparedBy: 'preparedBy',
    approvedBy: 'approvedBy',
}

export const TableTotalColumnList = [
    TableColumnPopupType.quantity,
    TableColumnPopupType.productDiscount,
    TableColumnPopupType.productTax,
    TableColumnPopupType.amountBeforeTax,
    TableColumnPopupType.amount
]

export const TableTotalColumnListForJournalEntry = [
    TableColumnPopupType.credit,
    TableColumnPopupType.debit,
]

export const TableTotalColumnListForSOA = [
    TableColumnPopupType.credit,
    TableColumnPopupType.debit,
    TableColumnPopupType.amountInBase,
]

export const TableTotalColumnListForAgedReceivable = [
    TableColumnPopupType.amount,
    TableColumnPopupType.balance,
    TableColumnPopupType.slab1,
    TableColumnPopupType.slab2,
    TableColumnPopupType.slab3,
    TableColumnPopupType.slab4,
]

export const CUSTOM_FIELD_SHOW_OPTION = {
    NONE: 'NONE',
    SHOW_LABEL: 'SHOW_LABEL',
    SHOW_VALUE: 'SHOW_VALUE',
    SHOW_BOTH: 'SHOW_BOTH'
}

export const WeekdayList = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const LongMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const ShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const ShortMonth_3_char = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const USStateData = [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'DC', name: 'District of Columbia' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'GU', name: 'Guam' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VI', name: 'Virgin Islands, U.S.' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' }
];

export const SECURITY_GATE_DEFAULT_TEMPLATE_ID = 57;

export const COUNTRIES_LIST = [
    {
        name: 'Afghanistan',
        currencyCode: 'AFN',
        code: 'AF',
        dial_code: '+93',
        timezone: 'UTC+04:30'
    },
    {
        name: 'Åland Islands',
        currencyCode: 'EUR',
        code: 'AX',
        dial_code: '+358',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Albania',
        currencyCode: 'ALL',
        code: 'AL',
        dial_code: '+355',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Algeria',
        currencyCode: 'DZD',
        code: 'DZ',
        dial_code: '+213',
        timezone: 'UTC+01:00'
    },
    {
        name: 'American Samoa',
        currencyCode: 'USD',
        code: 'AS',
        dial_code: '+1684',
        timezone: 'GMT-11:00'
    },
    {
        name: 'Andorra',
        currencyCode: 'EUR',
        code: 'AD',
        dial_code: '+376',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Angola',
        currencyCode: 'AOA',
        code: 'AO',
        dial_code: '+244',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Anguilla',
        currencyCode: 'XCD',
        code: 'AI',
        dial_code: '+1264',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Antarctica',
        currencyCode: 'AUD',
        code: 'AQ',
        dial_code: '+672',
        timezone: 'GMT-03:00'
    },
    {
        name: 'Antigua and Barbuda',
        currencyCode: 'XCD',
        code: 'AG',
        dial_code: '+1268',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Argentina',
        currencyCode: 'ARS',
        code: 'AR',
        dial_code: '+54',
        timezone: 'UTC-03:00'
    },
    {
        name: 'Armenia',
        currencyCode: 'AMD',
        code: 'AM',
        dial_code: '+374',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Aruba',
        currencyCode: 'AWG',
        code: 'AW',
        dial_code: '+43',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Australia',
        currencyCode: 'AUD',
        code: 'AU',
        dial_code: '+61',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Austria',
        currencyCode: 'EUR',
        code: 'AT',
        dial_code: '+43',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Azerbaijan',
        currencyCode: 'AZN',
        code: 'AZ',
        dial_code: '+994',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Bahamas',
        currencyCode: 'BSD',
        code: 'BS',
        dial_code: '+1242',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Bahrain',
        currencyCode: 'BHD',
        code: 'BH',
        dial_code: '+973',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Bangladesh',
        currencyCode: 'BDT',
        code: 'BD',
        dial_code: '+880',
        timezone: 'UTC+06:00'
    },
    {
        name: 'Barbados',
        currencyCode: 'BBD',
        code: 'BB',
        dial_code: '+1246',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Belarus',
        currencyCode: 'BYN',
        code: 'BY',
        dial_code: '+375',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Belgium',
        currencyCode: 'EUR',
        code: 'BE',
        dial_code: '+32',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Belize',
        currencyCode: 'BZD',
        code: 'BZ',
        dial_code: '+501',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Benin',
        currencyCode: 'XOF',
        code: 'BJ',
        dial_code: '+229',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Bermuda',
        currencyCode: 'BMD',
        code: 'BM',
        dial_code: '+267',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Bhutan',
        currencyCode: 'BTN',
        code: 'BT',
        dial_code: '+975',
        timezone: 'UTC+06:00'
    },
    {
        name: 'Bolivia',
        currencyCode: 'BOB',
        code: 'BO',
        dial_code: '+246',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        currencyCode: 'USD',
        code: 'BQ',
        dial_code: '+5997',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Bosnia and Herzegovina',
        currencyCode: 'BAM',
        code: 'BA',
        dial_code: '+387',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Botswana',
        currencyCode: 'BWP',
        code: 'BW',
        dial_code: '+267',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Bouvet Island',
        currencyCode: 'NOK',
        code: 'BV',
        dial_code: '+',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Brazil',
        currencyCode: 'BRL',
        code: 'BR',
        dial_code: '+55',
        timezone: 'UTC-03:00'
    },
    {
        name: 'British Indian Ocean Territory',
        currencyCode: 'USD',
        code: 'IO',
        dial_code: '+246',
        timezone: 'UTC+06:00'
    },
    {
        name: 'United States Minor Outlying Islands',
        currencyCode: 'USD',
        code: 'UM',
        dial_code: '+',
        timezone: 'GMT-11:00'
    },
    {
        name: 'Uruguay',
        currencyCode: 'UYU',
        code: 'UY',
        dial_code: '+47',
        timezone: 'UTC-03:00'
    },
    {
        name: 'Virgin Islands (U.S.)',
        currencyCode: 'USD',
        code: 'VI',
        dial_code: '+1 340',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Brunei Darussalam',
        currencyCode: 'BND',
        code: 'BN',
        dial_code: '+673',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Bulgaria',
        currencyCode: 'BGN',
        code: 'BG',
        dial_code: '+359',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Burkina Faso',
        currencyCode: 'XOF',
        code: 'BF',
        dial_code: '+226',
        timezone: 'GMT'
    },
    {
        name: 'Burundi',
        currencyCode: 'BIF',
        code: 'BI',
        dial_code: '+257',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Cambodia',
        currencyCode: 'KHR',
        code: 'KH',
        dial_code: '+855',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Cameroon',
        currencyCode: 'XAF',
        code: 'CM',
        dial_code: '+237',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Canada',
        currencyCode: 'CAD',
        code: 'CA',
        dial_code: '+1',
        timezone: 'GMT-08:00'
    },
    {
        name: 'Cabo Verde',
        currencyCode: 'CVE',
        code: 'CV',
        dial_code: '+238',
        timezone: 'GMT-01:00'
    },
    {
        name: 'Cayman Islands',
        currencyCode: 'KYD',
        code: 'KY',
        dial_code: '+1664',
        timezone: 'UTC-05:00'
    },
    {
        name: 'Central African Republic',
        currencyCode: 'XAF',
        code: 'CF',
        dial_code: '+236',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Chad',
        currencyCode: 'XAF',
        code: 'TD',
        dial_code: '+235',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Chile',
        code: 'CL',
        currencyCode: 'CLP',
        dial_code: '+682',
        timezone: 'UTC-04:00'
    },
    {
        name: 'China',
        currencyCode: 'CNY',
        code: 'CN',
        dial_code: '+86',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Christmas Island',
        currencyCode: 'AUD',
        code: 'CX',
        dial_code: '+61',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Cocos (Keeling) Islands',
        currencyCode: 'AUD',
        code: 'CC',
        dial_code: '+61',
        timezone: 'UTC+06:30'
    },
    {
        name: 'Colombia',
        currencyCode: 'COP',
        code: 'CO',
        dial_code: '+57',
        timezone: 'UTC-05:00'
    },
    {
        name: 'Comoros',
        currencyCode: 'KMF',
        code: 'KM',
        dial_code: '+269',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Congo',
        currencyCode: 'XAF',
        code: 'CG',
        dial_code: '+242',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Congo (Democratic Republic of the)',
        currencyCode: 'CDF',
        code: 'CD',
        dial_code: '+243',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Cook Islands',
        currencyCode: 'NZD',
        code: 'CK',
        dial_code: '+242',
        timezone: 'UTC-10:00'
    },
    {
        name: 'Costa Rica',
        currencyCode: 'CRC',
        code: 'CR',
        dial_code: '+506',
        timezone: 'GMT-06:00'
    },
    {
        name: `Cote d'Ivoire`,
        currencyCode: 'CFA',
        code: 'CI',
        dial_code: '+225',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Croatia',
        currencyCode: 'HRK',
        code: 'HR',
        dial_code: '+385',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Cuba',
        currencyCode: 'CUP',
        code: 'CU',
        dial_code: '+53',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Curacao',
        currencyCode: 'ANG',
        code: 'CW',
        dial_code: '+253',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Cyprus',
        currencyCode: 'EUR',
        code: 'CY',
        dial_code: '+357',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Czech Republic',
        currencyCode: 'CZK',
        code: 'CZ',
        dial_code: '+420',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Denmark',
        currencyCode: 'DKK',
        code: 'DK',
        dial_code: '+45',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Djibouti',
        currencyCode: 'DJF',
        code: 'DJ',
        dial_code: '+253',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Dominica',
        currencyCode: 'XCD',
        code: 'DM',
        dial_code: '+1767',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Dominican Republic',
        currencyCode: 'DOP',
        code: 'DO',
        dial_code: '+1809',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Ecuador',
        currencyCode: 'USD',
        code: 'EC',
        dial_code: '+593',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Egypt',
        currencyCode: 'EGP',
        code: 'EG',
        dial_code: '+20',
        timezone: 'UTC+02:00'
    },
    {
        name: 'El Salvador',
        currencyCode: 'USD',
        code: 'SV',
        dial_code: '+503',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Equatorial Guinea',
        currencyCode: 'XAF',
        code: 'GQ',
        dial_code: '+240',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Eritrea',
        currencyCode: 'ERN',
        code: 'ER',
        dial_code: '+291',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Estonia',
        currencyCode: 'EUR',
        code: 'EE',
        dial_code: '+372',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Ethiopia',
        currencyCode: 'ETB',
        code: 'ET',
        dial_code: '+251',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Falkland Islands',
        currencyCode: 'FKP',
        code: 'FK',
        dial_code: '+49',
        timezone: 'UTC-03:00'
    },
    {
        name: 'Faroe Islands',
        currencyCode: 'DKK',
        code: 'FO',
        dial_code: '+298',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Fiji',
        currencyCode: 'FJD',
        code: 'FJ',
        dial_code: '+995',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Finland',
        currencyCode: 'EUR',
        code: 'FI',
        dial_code: '+358',
        timezone: 'UTC+02:00'
    },
    {
        name: 'France',
        currencyCode: 'EUR',
        code: 'FR',
        dial_code: '+33',
        timezone: 'GMT-10:00'
    },
    {
        name: 'French Guiana',
        currencyCode: 'EUR',
        code: 'GF',
        dial_code: '+594',
        timezone: 'GMT-03:00'
    },
    {
        name: 'French Polynesia',
        currencyCode: 'XPF',
        code: 'PF',
        dial_code: '+992',
        timezone: 'UTC-10:00'
    },
    {
        name: 'French Southern Territories',
        currencyCode: 'EUR',
        code: 'TF',
        dial_code: '+',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Gabon',
        currencyCode: 'XAF',
        code: 'GA',
        dial_code: '+241',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Gambia',
        currencyCode: 'GMD',
        code: 'GM',
        dial_code: '+220',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Georgia',
        currencyCode: 'GEL',
        code: 'GE',
        dial_code: '+995',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Germany',
        currencyCode: 'EUR',
        code: 'DE',
        dial_code: '+49',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Ghana',
        currencyCode: 'GHS',
        code: 'GH',
        dial_code: '+233',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Gibraltar',
        currencyCode: 'GIP',
        code: 'GI',
        dial_code: '+595',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Greece',
        currencyCode: 'EUR',
        code: 'GR',
        dial_code: '+30',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Greenland',
        currencyCode: 'DKK',
        code: 'GL',
        dial_code: '+299',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Grenada',
        currencyCode: 'XCD',
        code: 'GD',
        dial_code: '+1473',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Guadeloupe',
        currencyCode: 'EUR',
        code: 'GP',
        dial_code: '+590',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Guam',
        currencyCode: 'USD',
        code: 'GU',
        dial_code: '+1671',
        timezone: 'UTC+10:00'
    },
    {
        name: 'Guatemala',
        currencyCode: 'GTQ',
        code: 'GT',
        dial_code: '+502',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Guernsey',
        currencyCode: 'GBP',
        code: 'GG',
        dial_code: '+44',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Guinea',
        currencyCode: 'GNF',
        code: 'GN',
        dial_code: '+224',
        timezone: 'GMT'
    },
    {
        name: 'Guinea-Bissau',
        currencyCode: 'XOF',
        code: 'GW',
        dial_code: '+245',
        timezone: 'GMT'
    },
    {
        name: 'Guyana',
        currencyCode: 'GYD',
        code: 'GY',
        dial_code: '+1 876',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Haiti',
        currencyCode: 'HTG',
        code: 'HT',
        dial_code: '+509',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Heard Island and McDonald Islands',
        currencyCode: 'AUD',
        code: 'HM',
        dial_code: '+',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Holy See',
        currencyCode: 'EUR',
        code: 'VA',
        dial_code: '+379',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Honduras',
        currencyCode: 'HNL',
        code: 'HN',
        dial_code: '+504',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Hong kong',
        currencyCode: 'HKD',
        code: 'HK',
        dial_code: '+852',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Hungary',
        currencyCode: 'HUF',
        code: 'HU',
        dial_code: '+36',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Iceland',
        currencyCode: 'ISK',
        code: 'IS',
        dial_code: '+354',
        timezone: 'GMT'
    },
    {
        name: 'India',
        currencyCode: 'INR',
        code: 'IN',
        dial_code: '+91',
        timezone: 'UTC+05:30'
    },
    {
        name: 'Indonesia',
        currencyCode: 'IDR',
        code: 'ID',
        dial_code: '+62',
        timezone: 'UTC+07:00'
    },
    {
        name: "Côte d'Ivoire",
        currencyCode: 'XOF',
        code: 'CI',
        dial_code: '+225',
        timezone: 'GMT'
    },
    {
        name: 'Iran (Islamic Republic of)',
        currencyCode: 'IRR',
        code: 'IR',
        dial_code: '+98',
        timezone: 'UTC+03:30'
    },
    {
        name: 'Iraq',
        currencyCode: 'IQD',
        code: 'IQ',
        dial_code: '+964',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Ireland',
        currencyCode: 'EUR',
        code: 'IE',
        dial_code: '+353',
        timezone: 'GMT'
    },
    {
        name: 'Isle of Man',
        currencyCode: 'GBP',
        code: 'IM',
        dial_code: '+44',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Israel',
        currencyCode: 'ILS',
        code: 'IL',
        dial_code: '+972',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Italy',
        currencyCode: 'EUR',
        code: 'IT',
        dial_code: '+39',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Jamaica',
        currencyCode: 'JMD',
        code: 'JM',
        dial_code: '+1876',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Japan',
        currencyCode: 'JPY',
        code: 'JP',
        dial_code: '+81',
        timezone: 'UTC+09:00'
    },
    {
        name: 'Jersey',
        currencyCode: 'GBP',
        code: 'JE',
        dial_code: '+44',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Jordan',
        currencyCode: 'JOD',
        code: 'JO',
        dial_code: '+962',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Kazakhstan',
        currencyCode: 'KZT',
        code: 'KZ',
        dial_code: '+76',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Kenya',
        currencyCode: 'KES',
        code: 'KE',
        dial_code: '+254',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Kiribati',
        currencyCode: 'AUD',
        code: 'KI',
        dial_code: '+686',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Kuwait',
        currencyCode: 'KWD',
        code: 'KW',
        dial_code: '+965',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Kyrgyzstan',
        currencyCode: 'KGS',
        code: 'KG',
        dial_code: '+996',
        timezone: 'UTC+06:00'
    },
    {
        name: "Lao People's Democratic Republic",
        currencyCode: 'LAK',
        code: 'LA',
        dial_code: '+856',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Latvia',
        currencyCode: 'EUR',
        code: 'LV',
        dial_code: '+371',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Lebanon',
        currencyCode: 'LBP',
        code: 'LB',
        dial_code: '+961',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Lesotho',
        currencyCode: 'LSL',
        code: 'LS',
        dial_code: '+266',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Liberia',
        currencyCode: 'LRD',
        code: 'LR',
        dial_code: '+231',
        timezone: 'GMT'
    },
    {
        name: 'Libya',
        currencyCode: 'LYD',
        code: 'LY',
        dial_code: '+218',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Liechtenstein',
        currencyCode: 'CHF',
        code: 'LI',
        dial_code: '+423',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Lithuania',
        currencyCode: 'EUR',
        code: 'LT',
        dial_code: '+370',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Luxembourg',
        currencyCode: 'EUR',
        code: 'LU',
        dial_code: '+352',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Macao',
        currencyCode: 'MOP',
        code: 'MO',
        dial_code: '+63',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Macedonia (the former Yugoslav Republic of)',
        currencyCode: 'MKD',
        code: 'MK',
        dial_code: '+389',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Madagascar',
        currencyCode: 'MGA',
        code: 'MG',
        dial_code: '+261',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Malawi',
        currencyCode: 'MWK',
        code: 'MW',
        dial_code: '+265',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Malaysia',
        currencyCode: 'MYR',
        code: 'MY',
        dial_code: '+60',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Maldives',
        currencyCode: 'MVR',
        code: 'MV',
        dial_code: '+960',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Mali',
        currencyCode: 'XOF',
        code: 'ML',
        dial_code: '+223',
        timezone: 'GMT'
    },
    {
        name: 'Malta',
        currencyCode: 'EUR',
        code: 'MT',
        dial_code: '+356',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Marshall Islands',
        currencyCode: 'USD',
        code: 'MH',
        dial_code: '+692',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Martinique',
        currencyCode: 'EUR',
        code: 'MQ',
        dial_code: '+596',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Mauritania',
        currencyCode: 'MRU',
        code: 'MR',
        dial_code: '+222',
        timezone: 'GMT'
    },
    {
        name: 'Mauritius',
        currencyCode: 'MUR',
        code: 'MU',
        dial_code: '+230',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Mayotte',
        currencyCode: 'EUR',
        code: 'YT',
        dial_code: '+262',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Mexico',
        currencyCode: 'MXN',
        code: 'MX',
        dial_code: '+52',
        timezone: 'GMT-08:00'
    },
    {
        name: 'Micronesia',
        currencyCode: 'USD',
        code: 'FM',
        dial_code: '+233',
        timezone: 'UTC+11:00'
    },
    {
        name: 'Moldova (Republic of)',
        currencyCode: 'MDL',
        code: 'MD',
        dial_code: '+373',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Monaco',
        currencyCode: 'EUR',
        code: 'MC',
        dial_code: '+377',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Mongolia',
        currencyCode: 'MNT',
        code: 'MN',
        dial_code: '+976',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Montenegro',
        currencyCode: 'EUR',
        code: 'ME',
        dial_code: '+382',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Montserrat',
        currencyCode: 'XCD',
        code: 'MS',
        dial_code: '+1664',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Morocco',
        currencyCode: 'MAD',
        code: 'MA',
        dial_code: '+212',
        timezone: 'GMT'
    },
    {
        name: 'Mozambique',
        currencyCode: 'MZN',
        code: 'MZ',
        dial_code: '+258',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Myanmar',
        currencyCode: 'MMK',
        code: 'MM',
        dial_code: '+95',
        timezone: 'UTC+06:30'
    },
    {
        name: 'Namibia',
        currencyCode: 'NAD',
        code: 'NA',
        dial_code: '+264',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Nauru',
        currencyCode: 'AUD',
        code: 'NR',
        dial_code: '+674',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Nepal',
        currencyCode: 'NPR',
        code: 'NP',
        dial_code: '+977',
        timezone: 'UTC+05:45'
    },
    {
        name: 'Netherlands',
        currencyCode: 'EUR',
        code: 'NL',
        dial_code: '+31',
        timezone: 'GMT-04:00'
    },
    {
        name: 'New Caledonia',
        currencyCode: 'XPF',
        code: 'NC',
        dial_code: '+232',
        timezone: 'UTC+11:00'
    },
    {
        name: 'New Zealand',
        currencyCode: 'NZD',
        dial_code: '+64',
        code: 'NZ',
        timezone: 'UTC+13:00'
    },
    {
        name: 'Nicaragua',
        currencyCode: 'NIO',
        code: 'NI',
        dial_code: '+505',
        timezone: 'GMT-06:00'
    },
    {
        name: 'Niger',
        currencyCode: 'XOF',
        code: 'NE',
        dial_code: '+227',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Nigeria',
        currencyCode: 'NGN',
        code: 'NG',
        dial_code: '+234',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Niue',
        currencyCode: 'NZD',
        code: 'NU',
        dial_code: '+683',
        timezone: 'UTC-11:00'
    },
    {
        name: 'Norfolk Island',
        currencyCode: 'AUD',
        code: 'NF',
        dial_code: '+672',
        timezone: 'UTC+11:30'
    },
    {
        name: "Korea (Democratic People's Republic of)",
        currencyCode: 'KPW',
        code: 'KP',
        dial_code: '+850',
        timezone: 'UTC+09:00'
    },
    {
        name: 'Northern Mariana Islands',
        currencyCode: 'USD',
        code: 'MP',
        dial_code: '+1670',
        timezone: 'UTC+10:00'
    },
    {
        name: 'Norway',
        currencyCode: 'NOK',
        code: 'NO',
        dial_code: '+47',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Oman',
        currencyCode: 'OMR',
        code: 'OM',
        dial_code: '+968',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Pakistan',
        currencyCode: 'PKR',
        code: 'PK',
        dial_code: '+92',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Palau',
        currencyCode: 'USD',
        code: 'PW',
        dial_code: '+688',
        timezone: 'UTC+09:00'
    },
    {
        name: 'Palestine, State of',
        currencyCode: 'ILS',
        code: 'PS',
        dial_code: '+970',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Panama',
        currencyCode: 'PAB',
        code: 'PA',
        dial_code: '+507',
        timezone: 'GMT-05:00'
    },
    {
        name: 'Papua New Guinea',
        currencyCode: 'PGK',
        code: 'PG',
        dial_code: '+66',
        timezone: 'UTC+10:00'
    },
    {
        name: 'Paraguay',
        currencyCode: 'PYG',
        code: 'PY',
        dial_code: '+256',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Peru',
        currencyCode: 'PEN',
        code: 'PE',
        dial_code: '+41',
        timezone: 'UTC-05:00'
    },
    {
        name: 'Philippines',
        currencyCode: 'PHP',
        code: 'PH',
        dial_code: '+63',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Pitcairn',
        currencyCode: 'NZD',
        code: 'PN',
        dial_code: '+216',
        timezone: 'UTC-08:00'
    },
    {
        name: 'Poland',
        currencyCode: 'PLN',
        code: 'PL',
        dial_code: '+48',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Portugal',
        currencyCode: 'EUR',
        code: 'PT',
        dial_code: '+351',
        timezone: 'GMT-01:00'
    },
    {
        name: 'Puerto Rico',
        currencyCode: 'USD',
        code: 'PR',
        dial_code: '+1787',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Qatar',
        currencyCode: 'QAR',
        code: 'QA',
        dial_code: '+974',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Republic of Kosovo',
        currencyCode: 'EUR',
        code: 'XK',
        dial_code: '+383',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Réunion',
        currencyCode: 'EUR',
        code: 'RE',
        dial_code: '+262',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Romania',
        currencyCode: 'RON',
        code: 'RO',
        dial_code: '+40',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Russian Federation',
        currencyCode: 'RUB',
        code: 'RU',
        dial_code: '+7',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Rwanda',
        currencyCode: 'RWF',
        code: 'RW',
        dial_code: '+250',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        currencyCode: 'SHP',
        code: 'SH',
        dial_code: '+290',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Saint Barthélemy',
        currencyCode: 'EUR',
        code: 'BL',
        dial_code: '+590',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Saint Kitts and Nevis',
        currencyCode: 'XCD',
        code: 'KN',
        dial_code: '+1869',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Saint Lucia',
        currencyCode: 'XCD',
        code: 'LC',
        dial_code: '+1758',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Saint Martin (French part)',
        currencyCode: 'EUR',
        code: 'MF',
        dial_code: '+590',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Saint Pierre and Miquelon',
        currencyCode: 'EUR',
        code: 'PM',
        dial_code: '+508',
        timezone: 'GMT-03:00'
    },
    {
        name: 'Saint Vincent and the Grenadines',
        currencyCode: 'XCD',
        code: 'VC',
        dial_code: '+1784',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Samoa',
        currencyCode: 'WST',
        code: 'WS',
        dial_code: '+685',
        timezone: 'UTC+13:00'
    },
    {
        name: 'San Marino',
        currencyCode: 'EUR',
        code: 'SM',
        dial_code: '+378',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Sao Tome and Principe',
        currencyCode: 'STD',
        code: 'ST',
        dial_code: '+239',
        timezone: 'GMT'
    },
    {
        name: 'Saudi Arabia',
        currencyCode: 'SAR',
        code: 'SA',
        dial_code: '+966',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Senegal',
        currencyCode: 'XOF',
        code: 'SN',
        dial_code: '+221',
        timezone: 'GMT'
    },
    {
        name: 'Serbia',
        currencyCode: 'RSD',
        code: 'RS',
        dial_code: '+381',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Seychelles',
        currencyCode: 'SCR',
        code: 'SC',
        dial_code: '+248',
        timezone: 'UTC+04:00'
    },
    {
        name: 'Sierra Leone',
        currencyCode: 'SLL',
        code: 'SL',
        dial_code: '+232',
        timezone: 'GMT'
    },
    {
        name: 'Singapore',
        currencyCode: 'SGD',
        code: 'SG',
        dial_code: '+65',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Sint Maarten',
        currencyCode: 'ANG',
        code: 'SX',
        dial_code: '+44',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Slovakia',
        currencyCode: 'EUR',
        code: 'SK',
        dial_code: '+421',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Slovenia',
        currencyCode: 'EUR',
        code: 'SI',
        dial_code: '+386',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Solomon Islands',
        currencyCode: 'SBD',
        code: 'SB',
        dial_code: '+681',
        timezone: 'UTC+11:00'
    },
    {
        name: 'Somalia',
        currencyCode: 'SOS',
        code: 'SO',
        dial_code: '+252',
        timezone: 'UTC+03:00'
    },
    {
        name: 'South Africa',
        currencyCode: 'ZAR',
        code: 'ZA',
        dial_code: '+27',
        timezone: 'UTC+02:00'
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        currencyCode: 'GBP',
        code: 'GS',
        dial_code: '+500',
        timezone: 'GMT-02:00'
    },
    {
        name: 'Korea (Republic of)',
        currencyCode: 'KRW',
        code: 'KR',
        dial_code: '+82',
        timezone: 'UTC+09:00'
    },
    {
        name: 'South Sudan',
        currencyCode: 'SSP',
        code: 'SS',
        dial_code: '+211',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Spain',
        currencyCode: 'EUR',
        code: 'ES',
        dial_code: '+34',
        timezone: 'GMT'
    },
    {
        name: 'Sri Lanka',
        currencyCode: 'LKR',
        code: 'LK',
        dial_code: '+94',
        timezone: 'UTC+05:30'
    },
    {
        name: 'Sudan',
        currencyCode: 'SDG',
        code: 'SD',
        dial_code: '+249',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Suriname',
        currencyCode: 'SRD',
        dial_code: '+597',
        code: 'SR',
        timezone: 'SR UTC-03:00'
    },
    {
        name: 'Svalbard and Jan Mayen',
        currencyCode: 'NOK',
        code: 'SJ',
        dial_code: '+4779',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Swaziland',
        currencyCode: 'SZL',
        code: 'SZ',
        dial_code: '+268',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Sweden',
        currencyCode: 'SEK',
        code: 'SE',
        dial_code: '+46',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Switzerland',
        currencyCode: 'CHF',
        code: 'CH',
        dial_code: '+41',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Syrian Arab Republic',
        currencyCode: 'SYP',
        code: 'SY',
        dial_code: '+963',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Taiwan',
        currencyCode: 'TWD',
        code: 'TW',
        dial_code: '+886',
        timezone: 'UTC+08:00'
    },
    {
        name: 'Tajikistan',
        currencyCode: 'TJS',
        code: 'TJ',
        dial_code: '+992',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Tanzania, United Republic of',
        currencyCode: 'TZS',
        code: 'TZ',
        dial_code: '+255',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Thailand',
        currencyCode: 'THB',
        code: 'TH',
        dial_code: '+66',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Timor-Leste',
        currencyCode: 'USD',
        code: 'TL',
        dial_code: '+670',
        timezone: 'UTC+09:00'
    },
    {
        name: 'Togo',
        currencyCode: 'XOF',
        code: 'TG',
        dial_code: '+228',
        timezone: 'GMT'
    },
    {
        name: 'Tokelau',
        currencyCode: 'NZD',
        code: 'TK',
        dial_code: '+258',
        timezone: 'UTC+13:00'
    },
    {
        name: 'Tonga',
        currencyCode: 'TOP',
        code: 'TO',
        dial_code: '+7',
        timezone: 'UTC+13:00'
    },
    {
        name: 'Trinidad and Tobago',
        currencyCode: 'TTD',
        code: 'TT',
        dial_code: '+1868',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Tunisia',
        currencyCode: 'TND',
        code: 'TN',
        dial_code: '+216',
        timezone: 'UTC+01:00'
    },
    {
        name: 'Turkey',
        currencyCode: 'TRY',
        code: 'TR',
        dial_code: '+90',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Turkmenistan',
        currencyCode: 'TMT',
        code: 'TM',
        dial_code: '+872',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Turks and Caicos Islands',
        currencyCode: 'USD',
        code: 'TC',
        dial_code: '+1649',
        timezone: 'GMT-04:00'
    },
    {
        name: 'Tuvalu',
        currencyCode: 'AUD',
        code: 'TV',
        dial_code: '+688',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Uganda',
        currencyCode: 'UGX',
        code: 'UG',
        dial_code: '+256',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Ukraine',
        currencyCode: 'UAH',
        code: 'UA',
        dial_code: '+380',
        timezone: 'UTC+02:00'
    },
    {
        name: 'United Arab Emirates',
        currencyCode: 'AED',
        code: 'AE',
        dial_code: '+971',
        timezone: 'UTC+04'
    },
    {
        name: 'United Kingdom',
        currencyCode: 'GBP',
        code: 'UK',
        dial_code: '+44',
        timezone: 'GMT-08:00'
    },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        currencyCode: 'GBP',
        code: 'GB',
        dial_code: '+44',
        timezone: 'GMT-08:00'
    },
    {
        name: 'United States of America',
        currencyCode: 'USD',
        code: 'US',
        dial_code: '+1',
        timezone: 'GMT-12:00'
    },
    {
        name: 'Uzbekistan',
        currencyCode: 'UZS',
        code: 'UZ',
        dial_code: '+998',
        timezone: 'UTC+05:00'
    },
    {
        name: 'Vanuatu',
        currencyCode: 'VUV',
        code: 'VU',
        dial_code: '+1 340',
        timezone: 'UTC+11:00'
    },
    {
        name: 'Venezuela',
        currencyCode: 'VEF',
        code: 'VE',
        dial_code: '+670',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Virgin Islands (UK)',
        currencyCode: 'USD',
        code: 'VG',
        dial_code: '+58',
        timezone: 'UTC-04:00'
    },
    {
        name: 'Viet Nam',
        currencyCode: 'VND',
        code: 'VN',
        dial_code: '+84',
        timezone: 'UTC+07:00'
    },
    {
        name: 'Wallis and Futuna',
        currencyCode: 'XPF',
        code: 'WF',
        dial_code: '+681',
        timezone: 'UTC+12:00'
    },
    {
        name: 'Western Sahara',
        currencyCode: 'MAD',
        code: 'EH',
        dial_code: '+212',
        timezone: 'UTC+00:00'
    },
    {
        name: 'Yemen',
        currencyCode: 'YER',
        code: 'YE',
        dial_code: '+967',
        timezone: 'UTC+03:00'
    },
    {
        name: 'Zambia',
        currencyCode: 'ZMW',
        code: 'ZM',
        dial_code: '+260',
        timezone: 'UTC+02:00'
    },
    {
        name: 'Zimbabwe',
        currencyCode: 'BWP',
        code: 'ZW',
        dial_code: '+263',
        timezone: 'UTC+02:00'
    }
];