import {
  DKButton,
  DKIcons,
  DKInput,
  DKLabel,
  DKListPicker2,
  DKTooltipWrapper,
  INPUT_TYPE,
  TOAST_TYPE,
  showToast
} from 'deskera-ui-library';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CLASS_TITLE,
  SELECT_LABEL
} from '../../Components/Settings/Classes/ClassesConstant';
import { GranularPermissionsHelper } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DropdownSelectionType,
  FORM_ELEMENTS,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  STATUS_TYPE
} from '../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import {
  CustomField,
  CustomFieldResponse,
  CustomFieldsHolderProps,
  CustomFieldsHolderState,
  initialCustomFieldsHolderState
} from '../../Models/CustomField';
import { DraftTypes } from '../../Models/Drafts';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import { DropdownTag, InputTag } from '../../Models/NewContact';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo, statusData } from '../../Redux/Slices/AuthSlice';
import { locationsData } from '../../Redux/Slices/LocationSlice';
import CustomFieldService from '../../Services/CustomField';
import DateFormatService from '../../Services/DateFormat';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getSelectedLanguage
} from '../../Utility/Utility';
import { showAddCustomField } from '../CustomField/AddCustomField';
import {
  doesPrevChildExistsAndEmpty,
  getCFOption,
  getCurrentlyEditedCFFromFetchCF,
  getCustomFieldsToSetNone,
  getDropdownfieldDetailsFiltered,
  isOptionSelectedNoneOrEmpty
} from './CustomFieldHolderHelper';
import CustomFieldSettings from './CustomFieldSettings';
import {
  evaluateExpression,
  extractUsedParameters,
  getColumnConfigForFormula,
  getNonCFKeysValueForFormula
} from '../FormulaBuilder/formula-helper';
import NumberFormatService from '../../Services/NumberFormat';

export const CustomFieldsHolder: React.FC<CustomFieldsHolderProps> = (
  props
) => {
  const [state, setState] = useState<CustomFieldsHolderState>(
    deepClone(initialCustomFieldsHolderState)
  );
  const [tempInputState, setTempInputState] = useState<any>({});
  const [idsToUpdatedLater, setIdsToUpdatedLater] = useState<any[]>([]);
  type CustomFieldReactSelectOption = ReactSelectOptionsType<string, string>;

  const tenantInfo: any = useAppSelector(activeTenantInfo);
  const locations = useAppSelector(locationsData);
  const [showSettings, setShowSettings] = useState(false);
  const [customFieldAPIData, setcustomFieldAPIData] = useState<any>(null);
  const authDetails = useAppSelector(statusData);
  const transactionLevelCFsIdValueString = useRef<string>('');
  const existingLineItemsString = useRef<string>('');
  const stopReEvaluation = useRef(false);

  const modulesWithCFAlwaysEditable = [
    MODULES_NAME.INVOICE,
    MODULES_NAME.BILL,
    MODULES_NAME.QUOTATION,
    MODULES_NAME.ORDER,
    MODULES_NAME.SALESORDER,
    MODULES_NAME.REQUISITION
  ];

  useEffect(() => {
    // fetch custom fields
    fetchCustomFields();
    return () => {
      // null
    };
  }, []);

  const evaluateAndUpdateCfs = (currentDoc?: any) => {
    const customFields: CustomField[] = [];
    if (state.customFieldsList && state.customFieldsList.length > 0) {
      state.customFieldsList.forEach((inputField) => {
        let fieldValue: string = '';
        const customField = state.fetchedCustomFields.find(
          (c) => c.id.toString() === inputField.key
        );
        if (customField) {
          if (inputField.type === FORM_ELEMENTS.DROPDOWN) {
            const iField = inputField as DropdownTag<string>;
            if (customField.fieldType === CUSTOM_FIELD_TYPE.USER) {
              fieldValue = iField.value.value;
            } else {
              fieldValue = iField?.value?.label;
            }
          } else if (inputField.type === FORM_ELEMENTS.INPUT) {
            if (customField.fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA) {
              inputField.type = FORM_ELEMENTS.TEXT_AREA;
            } else if (
              customField.fieldType === CUSTOM_FIELD_TYPE.FORMULA &&
              Utility.isNotEmpty(currentDoc)
            ) {
              const expObj = customField?.formula?.expression
                ? JSON.parse(customField?.formula?.expression)
                : {};
              if (
                Utility.isNotEmpty(expObj) &&
                props.documentMode !== DOCUMENT_MODE.VIEW
              ) {
                let expressionStr = expObj?.expression || '';
                // Use regex to remove ${} from the variables
                let expression = expressionStr.replace(/\${(.*?)}/g, '$1');
                const formulaColumnConfig = getColumnConfigForFormula(
                  customField?.label,
                  props?.moduleName,
                  props?.moduleName !== MODULES_NAME.PRODUCT
                );
                // Extract used parameters in formula
                let extractedParameters = extractUsedParameters(
                  expression,
                  formulaColumnConfig
                );

                const extraKeys = getNonCFKeysValueForFormula(
                  props?.moduleName,
                  currentDoc
                );
                // Use existingCustomFields to set value for all extractedParameters
                extractedParameters = extractedParameters.map((param) => {
                  let value: number;
                  const keyToUse = param?.isLineItem
                    ? `${param.key}_line_item`
                    : param.key;
                  // console.log('IP FIELD: ', inputField);
                  if (param.isCustomField) {
                    const foundCF = state.customFieldsList?.find(
                      (cf: any) => cf.label === param.name
                    ) as any;
                    if (Utility.isNotEmpty(foundCF)) {
                      value = !isNaN(+foundCF.value) ? +foundCF.value : 0;
                      return {
                        ...param,
                        value: value
                      };
                    } else {
                      if (Object.hasOwn(extraKeys, keyToUse)) {
                        value = !isNaN(+extraKeys[`${keyToUse}`]?.value)
                          ? +extraKeys[`${keyToUse}`]?.value
                          : extraKeys[`${keyToUse}`]?.value || 0;
                        return {
                          ...param,
                          value: value
                        };
                      }
                    }
                  } else {
                    if (Object.hasOwn(extraKeys, keyToUse)) {
                      value = !isNaN(+extraKeys[`${keyToUse}`]?.value)
                        ? +extraKeys[`${keyToUse}`]?.value
                        : extraKeys[`${keyToUse}`]?.value || 0;
                      return {
                        ...param,
                        value: value
                      };
                    }
                  }
                  return param;
                });

                const result = evaluateExpression(
                  expression,
                  extractedParameters
                );

                if (result.isValid) {
                  const value =
                    result.value === Infinity
                      ? ''
                      : Utility.roundOffToTenantDecimalScale(
                          +result?.value
                        )?.toString();
                  fieldValue = value;
                } else {
                  fieldValue =
                    Utility.roundOffToTenantDecimalScale(0)?.toString();
                }
              } else {
                const value =
                  typeof inputField?.value !== 'undefined' &&
                  inputField?.value !== null
                    ? inputField?.value?.toString()?.replaceAll(',', '')
                    : null;
                fieldValue = Utility.roundOffToTenantDecimalScale(
                  value ? +value : 0
                )?.toString();
              }
            }

            const iField = inputField as InputTag<string>;
            if (!iField?.applyFormula) {
              fieldValue = iField.value?.toString();
            }
          } else {
            const iField = inputField as InputTag<string>;
            fieldValue = iField.value?.toString();
          }

          const customFieldObj: CustomField = {
            id: customField.id,
            code: customField.code,
            label: customField.label,
            value: fieldValue,
            shortName: customField.shortName,
            maxLength: customField.maxLength,
            fieldType: customField.fieldType,
            mandatory: customField.mandatory,
            module: props.moduleName
              ? props.moduleName
              : customField.modules[0],
            attributes: customField.attributes,
            status: customField.status,
            customFieldIndex: customField.customFieldIndex,
            formula: customField.formula
          };
          customFields.push(customFieldObj);
        }
      });
    }
    if (customFields?.length) {
      if (!stopReEvaluation.current) {
        stopReEvaluation.current = true;
        applyCustomField({ content: state.fetchedCustomFields });
      }

      let tempObjForInputState = { ...tempInputState };
      Object.keys(tempObjForInputState).forEach((k: any) => {
        const foundCF = customFields.find((cf: any) => cf.id?.toString() === k);
        if (foundCF) {
          tempObjForInputState[k] = {
            ...tempObjForInputState[k],
            value: foundCF.value
          };
        }
      });
      setTempInputState((prevState: any) => ({
        ...prevState,
        ...tempObjForInputState
      }));
      props.onUpdate(customFields);
    }
  };

  useEffect(() => {
    const cfValuesFromProps = props?.customFieldsList?.map((cf: any) => ({
      id: cf.id,
      value: cf.value ? cf.value : null
    }));
    const lineItemsString = props.currentDoc?.items;

    if (
      JSON.stringify(cfValuesFromProps) !==
      transactionLevelCFsIdValueString.current
    ) {
      transactionLevelCFsIdValueString.current =
        JSON.stringify(cfValuesFromProps);
      stopReEvaluation.current = false;
      // evaluateAndUpdateCfs(props.currentDoc);
      applyCustomField({ content: state.fetchedCustomFields });
    } else {
      stopReEvaluation.current = true;
    }

    if (JSON.stringify(lineItemsString) !== existingLineItemsString.current) {
      existingLineItemsString.current = JSON.stringify(lineItemsString);
      stopReEvaluation.current = false;
      applyCustomField({ content: state.fetchedCustomFields });
    } else {
      stopReEvaluation.current = true;
    }
  }, [props.currentDoc]);

  useEffect(() => {
    evaluateAndUpdateCfs(props.currentDoc);
  }, [state]);

  useEffect(() => {
    const contactRestrictedModules = [
      MODULES_NAME.INVOICE,
      MODULES_NAME.BILL,
      MODULES_NAME.QUOTATION,
      MODULES_NAME.ORDER,
      MODULES_NAME.SALESORDER,
      MODULES_NAME.CREDITNOTE,
      MODULES_NAME.DEBITNOTE,
      MODULES_NAME.EXPENSE,
      MODULES_NAME.DEPOSIT
    ];
    if (!contactRestrictedModules.includes(props.moduleName)) {
      return;
    }
    if (
      !Utility.isEmpty(props?.contact) &&
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY ||
        Utility.isEmpty(props.documentMode))
    ) {
      let updatedCustomFieldList: any = [...state.customFieldsList];
      const updatedState = state;
      let idArr: any[] = [];
      updatedCustomFieldList?.map((updatedCF: any, index: number) => {
        if (props.contact?.customField?.length > 0) {
          const customField = props.contact?.customField?.find((c: any) => {
            // update the custom field values if there is some other contact selected as per that contact
            if (idsToUpdatedLater.includes(updatedCF.key)) {
              if (updatedCF.type === FORM_ELEMENTS.DROPDOWN) {
                updatedCF.value = {
                  label: '',
                  value: '',
                  status: STATUS_TYPE.ACTIVE
                };
                updatedCustomFieldList[index].hasError =
                  updatedCF.isMandatory && isMandatoryFieldInValid('');
              } else {
                updatedCF.value = '';
              }
            }

            // as there is no way to trigger child dropdown without manually triggering to set its value according to parent dropdown,
            // this if condition unselects the child dropdown value and takes options as per parent dropdown value
            if (c.id === updatedCF?.parent?.id) {
              updatedCF.value = {
                label: '',
                value: '',
                status: STATUS_TYPE.ACTIVE
              };

              const found = updatedCF?.parent?.attributes?.find((i: any) => {
                return i.value.toLowerCase() === c.value.toLowerCase();
              });
              let found2 = updatedState.fetchedCustomFields.find((i: any) => {
                if (i?.parent?.id === c.id) {
                  return i?.attributes?.filter((attr: any) => {
                    return attr?.parentId?.toString() === found?.id?.toString();
                  });
                }
              });
              let newOptions: any[] = [];
              found2?.attributes?.map((i: any) => {
                if (i?.parentId?.toString() === found?.id?.toString()) {
                  newOptions.push({
                    label: i.value,
                    value: i.id,
                    status: i.status
                  });
                }
              });

              updatedCF.options = newOptions;
              updatedCustomFieldList[index].hasError =
                updatedCF.isMandatory && isMandatoryFieldInValid('');
            }

            return c?.id?.toString() === updatedCF?.key;
          });

          if (customField && updatedCF.type === FORM_ELEMENTS.DROPDOWN) {
            idArr.push(customField.id.toString());
            const fetchedCF = updatedState.fetchedCustomFields?.find(
              (field) => field.id === customField.id
            );
            if (fetchedCF && fetchedCF.fieldType === CUSTOM_FIELD_TYPE.USER) {
              let cf = fetchedCF?.attributes?.find(
                (field) => field.code === customField?.value
              );
              if (cf) {
                updatedCF.value = {
                  label:
                    fetchedCF?.attributes?.find(
                      (field) => field.code === customField?.value
                    )?.value || '',
                  value: customField?.value,
                  status: STATUS_TYPE.ACTIVE
                };
                updatedCF.hasError = false;
              } else {
                updatedCF.hasError = true;
              }
            } else {
              updatedCF.value = {
                label: customField?.value,
                value: customField?.id?.toString(),
                status: STATUS_TYPE.ACTIVE
              };
              updatedCF.hasError = false;
            }
          } else {
            if (customField) {
              idArr.push(customField?.id?.toString());
              updatedCF.value = customField.value;
              updatedCustomFieldList[index].hasError = false;
            }
          }
          setIdsToUpdatedLater([...idArr]);
        } else {
          updatedCustomFieldList?.map((updatedCF: any, index: number) => {
            if (!idsToUpdatedLater.includes(updatedCF.key)) {
              return;
            }
            if (updatedCF.type === FORM_ELEMENTS.DROPDOWN) {
              updatedCF.value = {
                label: '',
                value: '',
                status: STATUS_TYPE.ACTIVE
              };
              updatedCustomFieldList[index].hasError =
                updatedCF.isMandatory && isMandatoryFieldInValid('');
            } else {
              updatedCF.value = '';
            }
          });
        }
      });
      setState({
        ...state,
        customFieldsList: updatedCustomFieldList
      });
    }
  }, [props?.contact]);

  useEffect(() => {
    if (props?.addressUpdate) {
      if (customFieldAPIData) {
        applyCustomField(customFieldAPIData);
      }
      props?.updateAddressField();
    }
  }, [props?.addressUpdate]);

  useEffect(() => {
    if (
      props.moduleName !== MODULES_NAME.MRP_WORK_ORDER &&
      Utility.isNotEmpty(customFieldAPIData) &&
      typeof props?.valueToTriggerLocalChange !== 'undefined' &&
      props?.valueToTriggerLocalChange !== null
    ) {
      applyCustomField(customFieldAPIData);
    } else if (
      Utility.isNotEmpty(state.fetchedCustomFields) &&
      typeof props?.valueToTriggerLocalChange !== 'undefined' &&
      props?.valueToTriggerLocalChange !== null &&
      !stopReEvaluation.current
    ) {
      applyCustomField({ content: state.fetchedCustomFields });
    } else {
      if (
        customFieldAPIData &&
        props?.customFieldsList?.length &&
        props.moduleName !== MODULES_NAME.CONTACT_ADDRESS
      ) {
        const cfValuesFromProps = props?.customFieldsList?.map((cf: any) => ({
          id: cf.id,
          value: cf.value ? cf.value : null
        }));
        if (
          JSON.stringify(cfValuesFromProps) !==
          transactionLevelCFsIdValueString.current
        ) {
          transactionLevelCFsIdValueString.current =
            JSON.stringify(cfValuesFromProps);
          evaluateAndUpdateCfs(props.currentDoc);
          stopReEvaluation.current = false;
        }
      }
    }
  }, [props?.customFieldsList]);

  const fetchCustomFields = async () => {
    const moduleName = props.moduleName;
    if (!moduleName) {
      return;
    }
    const avoidCancelPrevAPIRequest =
      props.moduleName === MODULES_NAME.MRP_WORK_ORDER ? false : true;
    CustomFieldService.getCustomFields(
      {
        // status: 'ACTIVE',
        limit: '1000',
        module: moduleName
      },
      avoidCancelPrevAPIRequest
    ).then(
      (res: any) => {
        setcustomFieldAPIData(res);
        applyCustomField(res);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const applyCustomField = (customFieldResData: any = customFieldAPIData) => {
    const updatedState: CustomFieldsHolderState = {
      ...state,
      fetchedCustomFields: [],
      existingCustomFields: [],
      customFieldsList: [],
      availableCustomFields: [],
      showDropdown: false
    };
    let fetchedCustomFields = customFieldResData?.content?.sort(
      (field1: any, field2: any) =>
        (field1.customFieldIndex !== null
          ? field1.customFieldIndex
          : Infinity) -
        (field2.customFieldIndex !== null ? field2.customFieldIndex : Infinity)
    );
    fetchedCustomFields = fetchedCustomFields
      ?.filter(
        (field: any) =>
          field.status === 'ACTIVE' ||
          field.label === LOCATION_CLASS_ENUM.LOCATION ||
          field.label === LOCATION_CLASS_ENUM.CLASS
      )
      .map((cField: any) => {
        if (cField.label === LOCATION_CLASS_ENUM.CLASS) {
          cField.mandatory =
            Utility.isClassMandatory() && Utility.isClassTransactionType();
        }
        if (cField.label === LOCATION_CLASS_ENUM.LOCATION) {
          cField.mandatory = Utility.isLocationMandatory();
        }
        return cField;
      });
    const dropdownOptions = fetchedCustomFields?.map(
      (cf: CustomFieldResponse) => {
        const option: CustomFieldReactSelectOption = {
          label: cf.label,
          value: cf.id.toString(),
          status: cf.status
        };
        return option;
      }
    );
    updatedState.fetchedCustomFields = fetchedCustomFields;
    updatedState.availableCustomFields = dropdownOptions;

    let fetchedCustomFieldsObjArr: any[] = [];

    fetchedCustomFields?.forEach((fetchedCF: any) => {
      let found: any = props.customFieldsList?.find((i: any) => {
        return i.id === fetchedCF.id;
      });
      if (!found) {
        const customFieldObj: CustomField = {
          id: fetchedCF.id,
          code: fetchedCF.code,
          label: fetchedCF.label,
          value: fetchedCF.defaultValue,
          shortName: fetchedCF.shortName,
          maxLength: fetchedCF.maxLength,
          fieldType: fetchedCF.fieldType,
          mandatory: fetchedCF.mandatory,
          module: props.moduleName ? props.moduleName : fetchedCF.modules[0],
          attributes: fetchedCF.attributes,
          status: fetchedCF.status,
          customFieldIndex: fetchedCF.customFieldIndex,
          formula: fetchedCF.formula
        };
        fetchedCustomFieldsObjArr.push(customFieldObj);
      } else {
        found = {
          ...found,
          value: found?.value || found?.defaultValue
        };
        fetchedCustomFieldsObjArr.push(found);
      }
    });

    let existingFields = props.customFieldsList
      ? [...fetchedCustomFieldsObjArr]
      : [];

    updatedState.existingCustomFields =
      props.documentMode === DOCUMENT_MODE.VIEW || props?.isCFFiltered
        ? props.customFieldsList
        : existingFields;
    updatingExistingCustomFields(updatedState);
    setState((prevState) => ({ ...prevState, ...updatedState }));
  };

  const updatingExistingCustomFields = (
    updatedState: CustomFieldsHolderState
  ) => {
    if (
      props.customFieldsList?.length > 0 ||
      (!props.customFieldsList?.length &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          (props.draftType && props.draftType !== DraftTypes.NEW)))
    ) {
      updatedState?.existingCustomFields?.forEach((cf) => {
        const selectedCF = updatedState.fetchedCustomFields.find(
          (field: CustomFieldResponse) => field.id === cf.id
        );

        if (selectedCF) {
          if (selectedCF.fieldType === CUSTOM_FIELD_TYPE.USER) {
            const optionsList: CustomFieldReactSelectOption[] = [];
            let defaultOption: CustomFieldReactSelectOption = {
              label: '',
              value: '',
              status: STATUS_TYPE.ACTIVE
            };
            selectedCF.attributes?.forEach((attr) => {
              const option: CustomFieldReactSelectOption = {
                label: attr.value,
                value: attr.id.toString(),
                status: STATUS_TYPE.ACTIVE
              };
              if (attr.id.toString() === cf.value) {
                defaultOption = option;
              }
              optionsList.push(option);
            });

            const dropdown: DropdownTag<string> = {
              key: selectedCF.id.toString(),
              label: selectedCF.label,
              value: defaultOption,
              options: optionsList,
              type: FORM_ELEMENTS.DROPDOWN,
              selectionType: DropdownSelectionType.SINGLE,
              isMandatory: selectedCF.mandatory,
              hasError:
                selectedCF.mandatory &&
                isMandatoryFieldInValid(cf.value) &&
                props.documentMode !== DOCUMENT_MODE.VIEW,
              hidden: false
            };
            updatedState.customFieldsList.push(dropdown);
          } else if (selectedCF.fieldType === CUSTOM_FIELD_TYPE.DROPDOWN) {
            const optionsList: CustomFieldReactSelectOption[] = [];
            let defaultOption: CustomFieldReactSelectOption = {
              label: '',
              value: '',
              status: STATUS_TYPE.ACTIVE
            };
            selectedCF.attributes?.forEach((attr) => {
              const option: CustomFieldReactSelectOption = {
                label: attr.value,
                value: attr.id.toString(),
                status: attr.status
              };
              if (attr.value === cf.value) {
                defaultOption = option;
              }
              optionsList.push(option);
            });

            // Handle deleted location/class values on document side
            if (
              (selectedCF.label === LOCATION_CLASS_ENUM.LOCATION ||
                selectedCF.label === LOCATION_CLASS_ENUM.CLASS) &&
              (!selectedCF.attributes ||
                !selectedCF.attributes?.find(
                  (field: any) => field.label !== cf.value
                ))
            ) {
              if (cf.id) {
                defaultOption.label = cf.value;
                defaultOption.value = cf.id.toString();
              }
            }

            const dropdown: DropdownTag<string> = {
              key: selectedCF.id.toString(),
              label: selectedCF.label,
              value: defaultOption,
              options: optionsList,
              type: FORM_ELEMENTS.DROPDOWN,
              selectionType: DropdownSelectionType.SINGLE,
              isMandatory: selectedCF.mandatory,
              hasError:
                selectedCF.mandatory &&
                isMandatoryFieldInValid(cf.value) &&
                props.documentMode !== DOCUMENT_MODE.VIEW,
              hidden: false
            };
            updatedState.customFieldsList.push(dropdown);
          } else if (selectedCF.fieldType === CUSTOM_FIELD_TYPE.DATE) {
            const input: InputTag<string> = {
              key: selectedCF.id.toString(),
              label: selectedCF.label,
              hidden: false,
              value: cf.value,
              type: FORM_ELEMENTS.DATE,
              isMandatory: selectedCF.mandatory,
              hasError:
                selectedCF.mandatory &&
                isMandatoryFieldInValid(cf.value) &&
                props.documentMode !== DOCUMENT_MODE.VIEW,
              numberOnly: false
            };

            updatedState.customFieldsList.push(input);
            if (!Object.hasOwn(tempInputState, selectedCF.id.toString())) {
              setTempInputState((prevState: any) => ({
                ...prevState,
                [selectedCF.id.toString()]: {
                  key: selectedCF.id.toString(),
                  value: cf.value,
                  hasError:
                    selectedCF.mandatory &&
                    isMandatoryFieldInValid(cf.value) &&
                    props.documentMode !== DOCUMENT_MODE.VIEW
                }
              }));
            }
          } else if (selectedCF.fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA) {
            const input: InputTag<string> = {
              key: selectedCF.id.toString(),
              label: selectedCF.label,
              hidden: false,
              value: cf.value,
              type: FORM_ELEMENTS.TEXT_AREA,
              isMandatory: selectedCF.mandatory,
              hasError:
                selectedCF.mandatory &&
                isMandatoryFieldInValid(cf.value) &&
                props.documentMode !== DOCUMENT_MODE.VIEW,
              numberOnly: false,
              maxLength: selectedCF.maxLength
            };

            updatedState.customFieldsList.push(input);
            if (!Object.hasOwn(tempInputState, selectedCF.id.toString())) {
              setTempInputState((prevState: any) => ({
                ...prevState,
                [selectedCF.id.toString()]: {
                  key: selectedCF.id.toString(),
                  value: cf.value,
                  hasError:
                    selectedCF.mandatory &&
                    isMandatoryFieldInValid(cf.value) &&
                    props.documentMode !== DOCUMENT_MODE.VIEW
                }
              }));
            }
            // handle updating calculated cf value updates in tempinputstate
          } else {
            const input: InputTag<string> = {
              key: selectedCF.id.toString(),
              label: selectedCF.label,
              hidden: false,
              value: cf.value,
              type: FORM_ELEMENTS.INPUT,
              isMandatory: selectedCF.mandatory,
              hasError:
                selectedCF.mandatory &&
                isMandatoryFieldInValid(cf.value) &&
                props.documentMode !== DOCUMENT_MODE.VIEW,
              numberOnly:
                selectedCF.fieldType === CUSTOM_FIELD_TYPE.NUMBER
                  ? true
                  : false,
              maxLength: selectedCF.maxLength,
              applyFormula: Utility.isNotEmpty(selectedCF.formula)
            };

            updatedState.customFieldsList.push(input);
            if (!Object.hasOwn(tempInputState, selectedCF.id.toString())) {
              setTempInputState((prevState: any) => ({
                ...prevState,
                [selectedCF.id.toString()]: {
                  key: selectedCF.id.toString(),
                  value: cf.value,
                  hasError:
                    selectedCF.mandatory &&
                    isMandatoryFieldInValid(cf.value) &&
                    props.documentMode !== DOCUMENT_MODE.VIEW
                }
              }));
            }
          }
        }
        updatedState.availableCustomFields =
          updatedState.availableCustomFields.filter(
            (customField) => customField.value !== cf.id?.toString()
          );
      });
      updatedState.customFieldsList.forEach((field, index) => {
        if (field.type === FORM_ELEMENTS.DROPDOWN) {
          dropdownFieldUpdated(
            field.value as CustomFieldReactSelectOption,
            index,
            updatedState
          );
        }
      });
    } else {
      let newCustomFieldsList = [...updatedState.fetchedCustomFields];

      newCustomFieldsList.forEach((cf) => {
        addCustomField(cf, updatedState);
      });
    }
    // updateOptionsForEditMode();
  };

  const sdkInput = (
    key: any,
    title: string,
    value: string,
    placeholder: string,
    direction: string = INPUT_VIEW_DIRECTION.VERTICAL,
    index: number,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    readonly: boolean = false,
    maxLength: number | null = null,
    applyFormula: boolean = false
  ) => {
    return (
      <div
        className="position-relative"
        style={{
          width: 150,
          maxWidth: 200,
          wordBreak: 'break-word',
          alignSelf: 'baseline'
        }}
      >
        <DKInput
          className="custom-field-input"
          title={applyFormula ? `(ƒx) ${title}` : title}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          required={isMandatory}
          placeholder={placeholder}
          type={INPUT_TYPE.TEXT}
          direction={direction}
          value={tempInputState?.[key]?.value || ''}
          onChange={(newValue: any) =>
            handleInputChange(newValue as string, index)
          }
          readOnly={
            (!modulesWithCFAlwaysEditable.includes(props.moduleName) &&
              props.documentMode &&
              props.documentMode === DOCUMENT_MODE.VIEW) ||
            applyFormula
          }
          canValidate={hasError || maxLength}
          validator={(value: string) => {
            if (maxLength && value?.length > maxLength) return false;
            return !hasError;
          }}
          errorMessage={errMsg}
        />
      </div>
    );
  };

  const sdkTextAreaInput = (
    key: any,
    title: string,
    value: string,
    placeholder: string,
    direction: string = INPUT_VIEW_DIRECTION.VERTICAL,
    index: number,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    readonly: boolean = false,
    maxLength: number | null = null
  ) => {
    return (
      <div
        className="position-relative"
        style={{
          width: 150,
          maxWidth: 200,
          wordBreak: 'break-word',
          alignSelf: 'baseline'
        }}
      >
        <DKInput
          className="custom-field-input"
          title={title}
          titleStyle={{ color: 'gray' }}
          required={isMandatory}
          placeholder={placeholder}
          type={INPUT_TYPE.LONG_TEXT}
          direction={direction}
          value={tempInputState?.[key]?.value || ''}
          onChange={(newValue: any) =>
            handleInputChange(newValue as string, index)
          }
          readOnly={
            !modulesWithCFAlwaysEditable.includes(props.moduleName) &&
            props.documentMode &&
            props.documentMode === DOCUMENT_MODE.VIEW
          }
          canValidate={hasError || maxLength}
          validator={(value: string) => {
            if (maxLength && value?.length > maxLength) return false;
            return !hasError;
          }}
          errorMessage={errMsg}
        />
      </div>
    );
  };

  const sdkInputDate = (
    key: any,
    title: string,
    value: string,
    placeholder: string,
    direction: string = INPUT_VIEW_DIRECTION.VERTICAL,
    index: number,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    readonly: boolean = false
  ) => {
    return (
      <div
        className="position-relative"
        style={{
          width: 150,
          maxWidth: 200,
          alignSelf: 'baseline'
        }}
      >
        <DKInput
          className="custom-field-input"
          title={title}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          required={isMandatory}
          placeholder={placeholder}
          type={INPUT_TYPE.DATE}
          direction={direction}
          value={
            tempInputState?.[key]?.value &&
            tempInputState?.[key]?.value?.length > 0
              ? DateFormatService.getDateFromStr(
                  tempInputState?.[key]?.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                )
              : getSelectedLanguage() === 'zh-CN'
              ? new Date()
              : ''
          }
          onChange={(newValue: Date) =>
            handleInputChange(
              DateFormatService.getDateStrFromDate(
                newValue,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              ),
              index
            )
          }
          readOnly={
            !modulesWithCFAlwaysEditable.includes(props.moduleName) &&
            props.documentMode &&
            props.documentMode === DOCUMENT_MODE.VIEW
          }
          canValidate={hasError}
          validator={(value: string) => {
            return !hasError;
          }}
          errorMessage={errMsg}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const dropdownSDK = (
    title: string,
    value: CustomFieldReactSelectOption,
    dropdownOptions: CustomFieldReactSelectOption[] | undefined,
    direction: string = INPUT_VIEW_DIRECTION.VERTICAL,
    isMandatory: boolean = false,
    index: number,
    isSystemCF: boolean,
    isDisabled: boolean = false,
    hasError: boolean = false,
    isSearchable: boolean = false,
    searchableKey: string = 'label'
  ) => {
    const locationSettings = tenantInfo?.additionalSettings?.LOCATION;
    let locationObjForCF = locations?.find(
      (loc: any) => loc.label === value?.label
    );

    if (dropdownOptions) {
      dropdownOptions = [
        { label: SELECT_LABEL, value: '' },
        ...dropdownOptions
      ];
    }

    return (
      <div
        className="position-relative"
        style={{
          width: 150,
          maxWidth: 200,
          wordBreak: 'break-word',
          alignSelf: 'baseline'
        }}
      >
        <DKInput
          title={title}
          titleStyle={{ color: 'gray' }}
          valueStyle={{ minHeight: 33 }}
          className="custom-field-input"
          type={INPUT_TYPE.DROPDOWN}
          required={isMandatory}
          readOnly={
            (!modulesWithCFAlwaysEditable.includes(props.moduleName) &&
              props.documentMode &&
              props.documentMode === DOCUMENT_MODE.VIEW) ||
            isDisabled
          }
          value={value?.label}
          options={dropdownOptions}
          direction={direction}
          canValidate={hasError}
          validator={(value: string) => {
            return !hasError;
          }}
          errorMessage={' '}
          onChange={(newValue: any) => {
            if (newValue.label === SELECT_LABEL) {
              newValue.label = '';
              locationObjForCF = undefined;
              newValue.isNoneClicked = true;
            }
            dropdownFieldUpdated(newValue, index, state);
            if (
              isSystemCF &&
              title === locationSettings?.locationLabel &&
              props.onLocationUpdate
            ) {
              if (locationObjForCF) {
                props.onLocationUpdate(locationObjForCF);
              } else {
                props.onLocationUpdate(undefined);
              }
            }
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch:
              dropdownOptions && dropdownOptions.length > 5 ? true : false,
            searchableKey: searchableKey,
            data: dropdownOptions,
            renderer: (index: number, obj: any) => {
              return <DKLabel text={obj.label} />;
            }
          }}
        />
      </div>
    );
  };

  const isMandatoryFieldInValid = (value: any) => {
    const trimmedValue = typeof value !== 'number' ? value?.trim() : value;
    return (
      typeof trimmedValue === 'undefined' ||
      trimmedValue === null ||
      trimmedValue === ''
    );
  };

  // Handles updates of number, text, date and textarea type inputs
  const inputFieldUpdated = (value: string, index: number) => {
    const updatedState = state;
    const inputField = updatedState.customFieldsList[index] as InputTag<string>;

    if (updatedState.customFieldsList?.[index]) {
      updatedState.customFieldsList[index].hasError =
        inputField?.isMandatory && isMandatoryFieldInValid(value);

      if (inputField?.numberOnly && isNaN(Number(value))) {
        updatedState.customFieldsList[index].value = '';
        updatedState.customFieldsList[index].hasError = true;
      } else {
        updatedState.customFieldsList[index].value = value;
      }
      setState((prevState: any) => ({ ...prevState, ...updatedState }));
    }
  };

  // Debounce when changing input fields (except dropdowns)
  const onChangeDebounce = useCallback(
    Utility.debouncer(inputFieldUpdated, 500),
    [state]
  );

  // Handles updates of number, text, date and textarea type inputs
  const handleInputChange = (value: string, index: number) => {
    const updatedState = { ...tempInputState };
    const inputField = state?.customFieldsList[index] as InputTag<string>;
    if (inputField.numberOnly && isNaN(Number(value))) {
      updatedState[inputField.key].value = '';
      updatedState[inputField.key].hasError = true;
    } else {
      updatedState[inputField.key].value = value;
    }
    onChangeDebounce(value, index);
    setTempInputState((prevState: any) => ({ ...prevState, ...updatedState }));
  };

  const updateOptionsForEditMode = () => {
    let updatedState = { ...state };
    updatedState.customFieldsList.forEach((field, index) => {
      updatedState.customFieldsList.forEach((element: any, index1: number) => {
        const prevDropdown: any = updatedState.customFieldsList?.[index1];
        const prevDropdownFromFetched = updatedState?.fetchedCustomFields?.find(
          (fCF: any) => fCF.id?.toString() === prevDropdown?.key
        );
        const prevDropdownAndCurrentDropdownAreLinked =
          prevDropdownFromFetched?.parent?.id?.toString() ===
          updatedState.customFieldsList?.[index]?.key;
        let optionForSelectedParentsChild: any = [];
        updatedState.fetchedCustomFields[index1]?.attributes?.forEach(
          (attr: any) => {
            if (attr?.parentIdList?.includes(field?.value)) {
              optionForSelectedParentsChild.push(getCFOption(attr));
            }
          }
        );
        const childFormDropdownField: any =
          updatedState.customFieldsList?.[index1];
        if (childFormDropdownField) {
          childFormDropdownField.options = optionForSelectedParentsChild;
        }
      });
    });
  };

  const dropdownFieldUpdated = (
    option: CustomFieldReactSelectOption,
    index: number,
    tempState?: CustomFieldsHolderState
  ) => {
    try {
      const isOptionNoneOrEmpty = isOptionSelectedNoneOrEmpty(option);
      const optionNone = {
        label: '',
        value: '',
        status: STATUS_TYPE.ACTIVE
      };
      const updatedState = (
        !Utility.isEmpty(tempState) ? tempState : state
      ) as CustomFieldsHolderState;
      const currentEditingCF = getCurrentlyEditedCFFromFetchCF(
        updatedState,
        index
      );
      const currentEditingCFAttributeObjFromFetchedCF =
        currentEditingCF?.attributes?.find(
          (currAttr: any) => currAttr.id?.toString() === option?.value
        );
      const dropdownFieldDetalis = getDropdownfieldDetailsFiltered(
        updatedState,
        currentEditingCF
      );

      if (Utility.isNotEmpty(dropdownFieldDetalis)) {
        dropdownFieldDetalis?.forEach((dropdownFieldDetail: any) => {
          if (dropdownFieldDetail && dropdownFieldDetail.attributes) {
            let optionsListOfParent: CustomFieldReactSelectOption[] = [];
            dropdownFieldDetail?.attributes?.forEach((attr: any) => {
              if (
                Utility.isNotEmpty(currentEditingCFAttributeObjFromFetchedCF)
              ) {
                if (
                  currentEditingCFAttributeObjFromFetchedCF?.parentIdList?.includes(
                    attr?.id?.toString()
                  ) &&
                  attr.status === STATUS_TYPE.ACTIVE
                ) {
                  optionsListOfParent.push(getCFOption(attr));
                }
              } else if (attr.status === STATUS_TYPE.ACTIVE) {
                optionsListOfParent.push(getCFOption(attr));
              }
            });

            const parentFormDropdownField: any =
              updatedState.customFieldsList.find(
                (formDropdown) =>
                  formDropdown.key === dropdownFieldDetail.id.toString()
              );

            if (parentFormDropdownField) {
              if (optionsListOfParent.length === 0) {
                optionsListOfParent = [];
              }
              (parentFormDropdownField as DropdownTag<string>).options =
                optionsListOfParent;
              let valueOfParent: any =
                optionsListOfParent?.find(
                  (optionsParent: any) =>
                    optionsParent.value ===
                    parentFormDropdownField?.value?.value
                ) || optionsListOfParent?.[0];
              if (isOptionNoneOrEmpty) {
                valueOfParent = optionNone;
              }
              if (!Utility.isEmptyObject(updatedState?.existingCustomFields)) {
                const existingField: any =
                  updatedState?.existingCustomFields?.find(
                    (cf) => cf.id === dropdownFieldDetail.id
                  );
                if (Utility.isNotEmpty(existingField)) {
                  const selectedOption = optionsListOfParent?.find(
                    (option) => option.label === existingField.value
                  );
                  if (Utility.isNotEmpty(selectedOption)) {
                    valueOfParent = selectedOption;
                  }
                }
              }
              (parentFormDropdownField as DropdownTag<string>).value =
                valueOfParent;

              let newIndex = updatedState.fetchedCustomFields.findIndex(
                (option) => option.id === dropdownFieldDetail.id
              );
              if (index !== newIndex) {
                dropdownFieldUpdated(valueOfParent, newIndex, updatedState);
              }
            }
          }
        });
      }
      updatedState.customFieldsList.forEach((element: any, index1: number) => {
        const prevDropdown: any = updatedState.customFieldsList?.[index1];
        const prevDropdownFromFetched = updatedState?.fetchedCustomFields?.find(
          (fCF: any) => fCF.id?.toString() === prevDropdown?.key
        );
        const prevDropdownAndCurrentDropdownAreLinked =
          prevDropdownFromFetched?.parent?.id?.toString() ===
          updatedState.customFieldsList?.[index]?.key;
        const isPrevChildExistsAndEmpty = doesPrevChildExistsAndEmpty(
          prevDropdown,
          isOptionNoneOrEmpty,
          prevDropdownAndCurrentDropdownAreLinked
        );
        if (isPrevChildExistsAndEmpty) {
          let optionForSelectedParentsChild: any = [];
          updatedState.fetchedCustomFields[index1]?.attributes?.forEach(
            (attr: any) => {
              if (attr?.parentIdList?.includes(option?.value)) {
                optionForSelectedParentsChild.push(getCFOption(attr));
              }
            }
          );
          const childFormDropdownField: any =
            updatedState.customFieldsList?.[index1];
          if (childFormDropdownField) {
            childFormDropdownField.options = optionForSelectedParentsChild;
          }
        } else if (prevDropdownAndCurrentDropdownAreLinked) {
          let optionForSelectedParentsChild: any = [];
          updatedState.fetchedCustomFields[index1]?.attributes?.forEach(
            (attr: any) => {
              if (
                Utility.isEmpty(option?.value) ||
                attr?.parentIdList?.includes(option?.value)
              ) {
                optionForSelectedParentsChild.push(getCFOption(attr));
              }
            }
          );
          const childFormDropdownField: any =
            updatedState.customFieldsList?.[index1];
          if (childFormDropdownField) {
            childFormDropdownField.options = optionForSelectedParentsChild;

            //ZEN-13256: we need to check of the value is amongst the options generated, or else default value wont filter out from the options
            const valueDifferentFromOptions = Utility.isEmpty(
              optionForSelectedParentsChild?.find(
                (i: any) =>
                  i?.value?.toString() ===
                  childFormDropdownField?.value?.value?.toString()
              )
            );
            if (valueDifferentFromOptions) {
              childFormDropdownField.value = optionNone;
            }
          }
        }
      });
      //

      //
      //show all the options for all childs, when any NONE is clicked
      if (isOptionNoneOrEmpty && option?.isNoneClicked) {
        const cfsToUpdate = getCustomFieldsToSetNone(
          currentEditingCF,
          updatedState?.fetchedCustomFields
        );
        cfsToUpdate?.forEach((fetchCFItr: any, indexForFetchCF) => {
          let optionsForFirstChild: any = [];
          fetchCFItr?.attributes?.forEach((fetchedAttr: any) => {
            optionsForFirstChild.push(getCFOption(fetchedAttr));
          });
          const currentCFCopy: any = updatedState.customFieldsList?.find(
            (iCF: any) => iCF.key === fetchCFItr?.id?.toString()
          );
          if (currentCFCopy) {
            currentCFCopy.options = optionsForFirstChild;
            currentCFCopy.value = optionNone;
          }
        });
      }

      const customField = updatedState.customFieldsList[index];
      if (!Utility.isEmpty(customField)) {
        updatedState.customFieldsList[index].hasError =
          customField.isMandatory && isMandatoryFieldInValid(option.value);
        updatedState.customFieldsList[index].value = option;
        setState({ ...updatedState });
      }
    } catch (error) {
      console.log('error in dropdownFieldUpdated');
    }
  };

  const showAvailableCf = () => {
    const updatedState = state;
    if (
      !updatedState.availableCustomFields ||
      updatedState.availableCustomFields.length === 0
    ) {
      onAddCustomFieldClicked();
      return;
    }
    updatedState.showDropdown = true;
    setState({ ...updatedState });
  };

  const hideAvailableCf = () => {
    const updatedState = state;
    updatedState.showDropdown = false;
    setState({ ...updatedState });
  };

  const addNewCustomField = (id: string) => {
    const updatedState = state;
    const selectedCF = state.fetchedCustomFields.find(
      (cf) => cf.id.toString() === id
    );
    if (selectedCF) {
      addCustomField(selectedCF, updatedState);
    }
    updatedState.showDropdown = false;
    setState({ ...updatedState });
  };

  const addCustomField = (
    customField: CustomFieldResponse,
    updatedState: CustomFieldsHolderState
  ) => {
    if (customField.fieldType === CUSTOM_FIELD_TYPE.USER) {
      const optionsList: CustomFieldReactSelectOption[] = [];
      let defaultOption: CustomFieldReactSelectOption = {
        label: '',
        value: '',
        status: STATUS_TYPE.ACTIVE
      };
      customField.attributes?.forEach((attr) => {
        const option: CustomFieldReactSelectOption = {
          label: attr.value,
          value: attr.id.toString(),
          status: STATUS_TYPE.ACTIVE
        };
        if (attr.id.toString() === authDetails?.imID.toString()) {
          defaultOption = option;
        }
        optionsList.push(option);
      });

      const dropdown: DropdownTag<string> = {
        key: customField.id.toString(),
        label: customField.label,
        value: defaultOption,
        options: optionsList,
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        isMandatory: customField.mandatory,
        hasError:
          customField.mandatory &&
          isMandatoryFieldInValid(customField.defaultValue),
        hidden: false
      };
      updatedState.customFieldsList.push(dropdown);
    } else if (customField.fieldType === CUSTOM_FIELD_TYPE.DROPDOWN) {
      const optionsList: CustomFieldReactSelectOption[] = [];
      let defaultOption: CustomFieldReactSelectOption = {
        label: '',
        value: '',
        status: STATUS_TYPE.ACTIVE
      };
      customField.attributes?.forEach((attr) => {
        const option: CustomFieldReactSelectOption = {
          label: attr.value,
          value: attr.id.toString(),
          status: attr.status
        };
        if (attr.defaultOption) {
          defaultOption = option;
        }
        optionsList.push(option);
      });
      const dropdown: DropdownTag<string> = {
        key: customField.id.toString(),
        label: customField.label,
        value: defaultOption,
        options: optionsList,
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        isMandatory: customField.mandatory,
        hasError:
          customField.mandatory &&
          isMandatoryFieldInValid(defaultOption?.label),
        hidden: false,
        parent: customField?.parent
      };
      updatedState.customFieldsList.push(dropdown);
    } else if (customField.fieldType === CUSTOM_FIELD_TYPE.DATE) {
      const input: InputTag<string> = {
        key: customField.id.toString(),
        label: customField.label,
        hidden: false,
        value: customField.defaultValue,
        type: FORM_ELEMENTS.DATE,
        isMandatory: customField.mandatory,
        hasError:
          customField.mandatory &&
          isMandatoryFieldInValid(customField.defaultValue),
        numberOnly: false
      };

      updatedState.customFieldsList.push(input);
      if (!Object.hasOwn(tempInputState, customField.id.toString())) {
        setTempInputState((prevState: any) => ({
          ...prevState,
          [customField.id.toString()]: {
            key: customField.id.toString(),
            value: customField.defaultValue,
            hasError:
              customField.mandatory &&
              isMandatoryFieldInValid(customField.defaultValue)
          }
        }));
      }
    } else if (customField.fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA) {
      const input: InputTag<string> = {
        key: customField.id.toString(),
        label: customField.label,
        hidden: false,
        value: customField.defaultValue,
        type: FORM_ELEMENTS.TEXT_AREA,
        isMandatory: customField.mandatory,
        hasError:
          customField.mandatory &&
          isMandatoryFieldInValid(customField.defaultValue),
        numberOnly: false,
        maxLength: customField.maxLength
      };

      updatedState.customFieldsList.push(input);
      if (!Object.hasOwn(tempInputState, customField.id.toString())) {
        setTempInputState((prevState: any) => ({
          ...prevState,
          [customField.id.toString()]: {
            key: customField.id.toString(),
            value: customField.defaultValue,
            hasError:
              customField.mandatory &&
              isMandatoryFieldInValid(customField.defaultValue)
          }
        }));
      }
    } else {
      const input: InputTag<string> = {
        key: customField.id.toString(),
        label: customField.label,
        hidden: false,
        value: customField.defaultValue,
        type: FORM_ELEMENTS.INPUT,
        isMandatory: customField.mandatory,
        hasError:
          customField.mandatory &&
          isMandatoryFieldInValid(customField.defaultValue),
        numberOnly:
          customField.fieldType === CUSTOM_FIELD_TYPE.NUMBER ? true : false,
        maxLength: customField.maxLength
      };

      updatedState.customFieldsList.push(input);
      if (!Object.hasOwn(tempInputState, customField.id.toString())) {
        setTempInputState((prevState: any) => ({
          ...prevState,
          [customField.id.toString()]: {
            key: customField.id.toString(),
            value: customField.defaultValue,
            hasError:
              customField.mandatory &&
              isMandatoryFieldInValid(customField.defaultValue)
          }
        }));
      }
    }
    updatedState.availableCustomFields =
      updatedState.availableCustomFields.filter(
        (cf) => cf.value !== customField.id.toString()
      );
  };

  const editCustomField = (id: string) => {
    const selectedCF = state.fetchedCustomFields.find(
      (cf) => cf.id.toString() === id
    );
    if (selectedCF) {
      onAddCustomFieldClicked(selectedCF, true);
    }
  };

  const checkForNullNUndefined = (value: any) => {
    if (value === null || value === undefined) {
      return '';
    }
    return value;
  };

  const onAddCustomFieldClicked = (
    customField: CustomFieldResponse | null = null,
    isEdit: boolean = false
  ) => {
    const module = props.moduleName;
    const columnConfig = props.columnConfig;
    const columnConfigTableId = props.columnConfigTableId;
    if (
      module &&
      !(
        module === MODULES_NAME.BOM_ASSEMBLY ||
        module === MODULES_NAME.SECURITY_GATE_ENTRY ||
        module === MODULES_NAME.REQUISITION ||
        module === MODULES_NAME.REQUEST_FOR_QUOTATION
      )
    ) {
      if (!columnConfig || !columnConfigTableId) {
        showToast('Invalid columnConfig or tableId', TOAST_TYPE.FAILURE);
        return;
      }
    }

    showAddCustomField(
      {
        module: module,
        forDocument: true,
        columnConfig: columnConfig,
        tableId: columnConfigTableId,
        customFieldData: customField
      },
      (newCustomField: CustomFieldResponse) => {
        // push new custom field to the already existing custom fields
        const newField: CustomField = {
          code: newCustomField.code,
          label: newCustomField.label,
          value: '',
          module: module,
          customFieldIndex: newCustomField.customFieldIndex,
          status: newCustomField.status,
          isSelected: false,
          id: newCustomField.id,
          fieldType: newCustomField.fieldType,
          attributes: newCustomField.attributes,
          mandatory: newCustomField.mandatory,
          maxLength: newCustomField.maxLength,
          shortName: newCustomField.shortName,
          defaultValue: newCustomField.defaultValue,
          formula: newCustomField.formula
        };
        const updatedState = state;
        if (!isEdit) {
          updatedState.fetchedCustomFields.push(newCustomField);
          if (newField.fieldType === CUSTOM_FIELD_TYPE.USER) {
            const optionsList: CustomFieldReactSelectOption[] = [];
            let defaultOption: CustomFieldReactSelectOption = {
              label: '',
              value: '',
              status: STATUS_TYPE.ACTIVE
            };
            newField.attributes?.forEach((attr) => {
              const option: CustomFieldReactSelectOption = {
                label: attr.value,
                value: attr.id.toString(),
                status: STATUS_TYPE.ACTIVE
              };
              if (attr.id.toString() === authDetails?.imID.toString()) {
                defaultOption = option;
              }
              optionsList.push(option);
            });

            const dropdown: DropdownTag<string> = {
              key: newField.id?.toString() || '',
              label: newField.label,
              value: defaultOption,
              options: optionsList,
              type: FORM_ELEMENTS.DROPDOWN,
              selectionType: DropdownSelectionType.SINGLE,
              isMandatory: newField.mandatory,
              hasError:
                newField.mandatory &&
                isMandatoryFieldInValid(newField.defaultValue),
              hidden: false
            };
            updatedState.customFieldsList.push(dropdown);
          } else if (newField.fieldType === CUSTOM_FIELD_TYPE.DROPDOWN) {
            const optionsList: CustomFieldReactSelectOption[] = [];
            let defaultOption: CustomFieldReactSelectOption = {
              label: '',
              value: '',
              status: STATUS_TYPE.ACTIVE
            };
            newField.attributes?.forEach((attr) => {
              const option: CustomFieldReactSelectOption = {
                label: attr.value,
                value: attr.id.toString(),
                status: attr.status
              };
              if (attr.defaultOption) {
                defaultOption = option;
              }
              optionsList.push(option);
            });
            const dropdown: DropdownTag<string> = {
              key: newField.id ? newField.id.toString() : '',
              label: newField.label,
              value: defaultOption,
              options: optionsList,
              type: FORM_ELEMENTS.DROPDOWN,
              selectionType: DropdownSelectionType.SINGLE,
              isMandatory: newField.mandatory,
              hasError:
                newField.mandatory &&
                isMandatoryFieldInValid(
                  checkForNullNUndefined(newField.defaultValue)
                ),
              hidden: false
            };
            updatedState.customFieldsList.push(dropdown);
          } else {
            let fieldType = FORM_ELEMENTS.INPUT;
            if (newField.fieldType === CUSTOM_FIELD_TYPE.DATE) {
              fieldType = FORM_ELEMENTS.DATE;
            } else if (newField.fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA) {
              fieldType = FORM_ELEMENTS.TEXT_AREA;
            }
            const input: InputTag<string> = {
              key: newField.id ? newField.id.toString() : '',
              label: newField.label,
              hidden: false,
              value: checkForNullNUndefined(newField.defaultValue),
              type: fieldType,
              isMandatory: newField.mandatory,
              hasError: (newField.mandatory &&
                isMandatoryFieldInValid(
                  checkForNullNUndefined(newField.defaultValue)
                )) as boolean,
              maxLength: newCustomField.maxLength,
              numberOnly:
                newField.fieldType === CUSTOM_FIELD_TYPE.NUMBER ? true : false
            };
            updatedState.customFieldsList.push(input);
          }
        } else {
          updatedState.fetchedCustomFields =
            updatedState.fetchedCustomFields.map((cf) => {
              if (cf.id.toString() === newCustomField.id.toString()) {
                cf = { ...newCustomField };
              }
              return cf;
            });
        }
        setState({ ...updatedState });
      }
    );
  };

  const updateCFList = () => {
    fetchCustomFields();
  };

  return (
    <div
      className="row flex-wrap align-items-end mt-r"
      style={{ gap: 12, ...(props.style || {}) }}
    >
      {state.customFieldsList.map((customField, index) => {
        if (customField.type === FORM_ELEMENTS.INPUT) {
          const inputCF = customField as InputTag<string>;
          return sdkInput(
            inputCF?.key,
            inputCF.label ? inputCF.label : '',
            inputCF.value as string,
            '',
            INPUT_VIEW_DIRECTION.VERTICAL,
            index,
            inputCF.isMandatory,
            inputCF.hasError,
            inputCF.maxLength &&
              (inputCF.value as string)?.length > inputCF.maxLength
              ? `cannot exceed limit ${inputCF.maxLength}`
              : ' ',
            false,
            inputCF.maxLength,
            inputCF.applyFormula
          );
        } else if (customField.type === FORM_ELEMENTS.TEXT_AREA) {
          const inputCF = customField as InputTag<string>;
          return sdkTextAreaInput(
            inputCF?.key,
            inputCF.label ? inputCF.label : '',
            inputCF.value as string,
            '',
            INPUT_VIEW_DIRECTION.VERTICAL,
            index,
            inputCF.isMandatory,
            inputCF.hasError,
            inputCF.maxLength &&
              (inputCF.value as string)?.length > inputCF.maxLength
              ? `cannot exceed limit ${inputCF.maxLength}`
              : ' ',
            false,
            inputCF.maxLength
          );
        } else if (customField.type === FORM_ELEMENTS.DATE) {
          const inputCF = customField as InputTag<string>;
          return sdkInputDate(
            inputCF?.key,
            inputCF.label ? inputCF.label : '',
            inputCF.value as string,
            '',
            INPUT_VIEW_DIRECTION.VERTICAL,
            index,
            inputCF.isMandatory,
            inputCF.hasError,
            ' '
          );
        } else {
          const dropdownCF = customField as DropdownTag<string>;
          const locationSettings = tenantInfo?.additionalSettings?.LOCATION;
          const classSettings = tenantInfo?.additionalSettings?.CLASS;
          let title = dropdownCF.label;
          let value = dropdownCF.value;

          let isDisabled = false;
          if (title === LOCATION_CLASS_ENUM.LOCATION) {
            title = locationSettings?.locationLabel;
            isDisabled = !locationSettings.trackLocation;
            // Don't show location CF, if it is disabled in settings
            if (!locationSettings.trackLocation) {
              return '';
            }
          }

          if (title === LOCATION_CLASS_ENUM.CLASS) {
            title = CLASS_TITLE;
            isDisabled = !classSettings.trackClasses;
            // Don't show class CF, if it is disabled in settings
            if (!classSettings.trackClasses) {
              return '';
            }
          }

          if (
            title === CLASS_TITLE &&
            classSettings?.assignClasses === CLASS_ASSIGNMENT.ROW &&
            props.moduleName !== MODULES_NAME.PRODUCT &&
            props.moduleName !== MODULES_NAME.ACCOUNT
          ) {
            return '';
          }

          return dropdownSDK(
            title ? title : '',
            value as CustomFieldReactSelectOption,
            dropdownCF?.options?.filter(
              (option, index) =>
                option.status?.toUpperCase() === STATUS_TYPE.ACTIVE
            ),
            INPUT_VIEW_DIRECTION.VERTICAL,
            dropdownCF.isMandatory,
            index,
            dropdownCF.label === LOCATION_CLASS_ENUM.LOCATION ||
              dropdownCF.label === LOCATION_CLASS_ENUM.CLASS,
            isDisabled,
            dropdownCF.hasError
          );
        }
      })}
      <div
        className="row width-auto position-relative"
        style={{ alignSelf: 'flex-end' }}
      >
        {GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.CREATE
        ) &&
          props.documentMode !== DOCUMENT_MODE.VIEW &&
          !props?.hideAddfieldButton && (
            <>
              <DKButton
                title="+ Add field"
                className={`fw-m text-app-color`}
                onClick={showAvailableCf}
              />
              <DKButton
                title=""
                icon={DKIcons.ic_settings}
                className={`fw-m text-app-color opacity-5`}
                style={{
                  marginLeft: -10
                }}
                onClick={() => setShowSettings(true)}
              />
            </>
          )}
        {state.showDropdown && (
          <DKListPicker2
            title="Custom fields"
            style={{ width: 180 }}
            className="position-absolute z-index-3 shadow-m ml-l"
            allowSearch={true}
            searchableKey="label"
            data={state.availableCustomFields}
            renderer={(index: number, obj: any) => {
              return <DKLabel text={`${obj.label}`} />;
            }}
            onEdit={(index: number, obj: any) => editCustomField(obj.value)}
            canEdit={true}
            onSelect={(index: number, obj: any) => addNewCustomField(obj.value)}
            iconClassName="bg-blue text-white"
            button={{
              title: '+ Add New',
              className: 'bg-blue text-white',
              onClick: () => {
                onAddCustomFieldClicked();
              }
            }}
            onClose={() => hideAvailableCf()}
          />
        )}
        {showSettings && (
          <CustomFieldSettings
            fields={state.fetchedCustomFields}
            moduleName={props.moduleName}
            onSave={() => {
              updateCFList();
            }}
            onClose={() => setShowSettings(false)}
          />
        )}
      </div>
    </div>
  );
};
