import {
  DKButton,
  DKCheckMark,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKListPicker2,
  DKRadioButton,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  TOAST_TYPE,
  Toggle,
  showAlert,
  showToast
} from 'deskera-ui-library';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import {
  BANK_ACCOUNT_TYPE_OPTIONS,
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CONTACT_FIELD_KEY_NAMES,
  CONTACT_FIELD_SECTION_NAMES,
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  CUSTOM_NUMBER_INPUT_MODULES,
  DATE_FORMAT,
  DEFAULT_ACCOUNTS_FOR_CONTACT,
  DOCUMENT_MODE,
  GSTIN_REGEX_PATTERN,
  INDONESIA,
  MODULES_NAME,
  NONE,
  PAN_REGEX_PATTERN,
  PAYMENT_FILE_FORMAT_OPTIONS,
  PAYMENT_INFO_TYPE_OPTIONS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE,
  TAX_SYSTEM,
  UK_VAT_AND_BUSINESS_REG_NUMBER
} from '../../Constants/Constant';
import {
  CONTACT_FORM_TAB,
  CREDIT_LIMIT_DOC_TYPE,
  CREDIT_LIMIT_TYPE,
  PRICE_LIMIT_CONTACT_FORM
} from '../../Constants/Enum';
import {
  ASSESSING_OFFICER_TYPES,
  COUNTRIES_LIST,
  CUSTOMER_TAX_TYPES,
  CUSTOMER_TYPES_REGISTERED_BUSINESS,
  CUSTOMER_TYPES_REGISTERED_BUSINESS_COMP,
  CUSTOMER_TYPE_MALAYSIA,
  CUSTOMER_TYPE_MALAYSIA_EXEMPTED,
  EXEMPTION_CRITERIAL_MALAYSIA,
  EXEMPTION_CRITERIAL_MALAYSIA_KEY,
  GST_TREATMENT_VALUES,
  INDIAN_STATES_MOCK_DATA,
  NON_DEDUCTION_REASON_INDIA,
  TAX_TREATMENT_UAE,
  TYPE_OF_COMPANY,
  USstateMockData,
  VENDOR_TAX_TYPES,
  VENDOR_TYPES_REGISTERED_BUSINESS,
  VENDOR_TYPES_REGISTERED_BUSINESS_COMP,
  VENDOR_TYPE_MALAYSIA,
  customerTypesOverseas,
  customerTypesSEZ,
  taxExemptionReasons,
  vendorTypesOverseas,
  vendorTypesSEZ
} from '../../Constants/StaticData';
import {
  IL_CERTIFICATE_CONST,
  IL_GLOBAL_BANK_COL_CONFIG,
  IL_LOCAL_BANK_COL_CONFIG
} from '../../Helper/WHTaxHelper';
import useConfirm from '../../Hooks/useConfirm';
import ComponentManager from '../../Managers/ComponentManager';
import { CustomField } from '../../Models/CustomField';
import {
  BtnType,
  CallBackPayloadType,
  CountryInfo,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import {
  Accounting,
  AdditionalPaymentInfo,
  AddressInfo,
  ContactPayload,
  FUTURE_CONTACT_ACCOUNTS_APPLY,
  GeneralInfo,
  NewContactProps,
  NewContactState,
  futureContactAccountInitialState,
  initialContactFormValues
} from '../../Models/NewContact';
import { Information } from '../../Models/PaymentPopup';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchTenantSettings,
  tenantSettings,
  activeTenantInfoForBookkeeper
} from '../../Redux/Slices/AuthSlice';
import {
  selectCurrencyListWithExchangeRate,
  selectPurchaseTax,
  selectSalesTax
} from '../../Redux/Slices/CommonDataSlice';
import {
  businessUnitData,
  fetchContactSummary,
  fetchContacts,
  selectAssests,
  selectContactsColumnConfig,
  selectContactsColumnConfigTableId,
  selectLiabilities
} from '../../Redux/Slices/ContactsSlice';
import { selectTDSDeducteeType } from '../../Redux/Slices/TDSSetupSlice';
import {
  selectWithHoldTax,
  selectWithHoldTaxGroup
} from '../../Redux/Slices/WHTSlice';
import AuthService from '../../Services/Auth';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import DateFormatService from '../../Services/DateFormat';
import NumberFormatService from '../../Services/NumberFormat';
import PriceListService from '../../Services/PriceList';
import TenantService from '../../Services/Tenant';
import CurrencyDropdown from '../../SharedComponents/CurrencyDropdown/CurrencyDropdown';
import { CustomFieldsHolder } from '../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import CommonStateListPicker from '../../SharedComponents/DocumentForm/CommonStateListPicker';
import {
  customFieldsContainsErrors,
  isSalesOrderVisible,
  roundingOffStr
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import MultiEmailInputField from '../../SharedComponents/Email/MultiEmailInputField';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  formatNPWPNumber
} from '../../Utility/Utility';
import AddPaymentTermsPopUp from '../Settings/PaymentTerm/AddPaymentTermsPopUp';
import { DeducteePopup } from './DeducteePopup';
import { ILBankDetailsPopup } from './ILBankDetailsPopup';
import { ILCertificatePopup } from './ILCertificatePopup';
import { ILGlobalBankDetailsPopup } from './ILGlobalBankDetailsPopup';
import { selectPaymentTerms } from '../../Redux/Slices/PaymentTermsSlice';
import { MultiPriceListPopup } from './MultiPriceListPopup';
import { selectSalesPersonMaster } from '../../Redux/Slices/SalesPersonMaster';
import { selectApplySalesPersonFilter } from '../../Redux/Slices/BookFilterSlice';

const AddContact: React.FC<NewContactProps> = (props) => {
  const [formState, setFormState] = useState<NewContactState>(
    deepClone(initialContactFormValues)
  );
  const [futureAccountsApply, setFutureAccountApply] = useState(
    futureContactAccountInitialState
  );
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({});
  const priceListInfoUpatedState = useRef<any>({});
  const payableAccounts = useAppSelector(selectLiabilities);
  const receiveableAccounts = useAppSelector(selectAssests);
  const paymentTerms = useAppSelector(selectPaymentTerms);
  const purchaseTaxList = useAppSelector(selectPurchaseTax);
  const salesTaxList = useAppSelector(selectSalesTax);
  const whTaxes = useAppSelector(selectWithHoldTax);
  const whtTaxGrp = useAppSelector(selectWithHoldTaxGroup);
  const gstTreatmentValues = GST_TREATMENT_VALUES;
  const customerType = CUSTOMER_TYPES_REGISTERED_BUSINESS;
  const customerTaxType = CUSTOMER_TAX_TYPES;
  const vendorTaxType = VENDOR_TAX_TYPES;
  const vendorType = VENDOR_TYPES_REGISTERED_BUSINESS;
  const customerTypeMalaysia = CUSTOMER_TYPE_MALAYSIA;
  const customerTypeMalaysiaExempted = CUSTOMER_TYPE_MALAYSIA_EXEMPTED;
  const vendorTypeMalaysia = VENDOR_TYPE_MALAYSIA;
  const assessingOfficerNumberIsrael = ASSESSING_OFFICER_TYPES;
  const typeOfCompanyIsrael = TYPE_OF_COMPANY;
  const exemptionCriterialMalaysia = EXEMPTION_CRITERIAL_MALAYSIA;
  const taxTreatmentUae = TAX_TREATMENT_UAE;
  const indianStates = INDIAN_STATES_MOCK_DATA;
  const americaStates = USstateMockData;
  const nonDeductionReasonsIndia = NON_DEDUCTION_REASON_INDIA;
  const tenantInfo = useAppSelector(activeTenantInfo);
  const deducteeTypeData = useAppSelector(selectTDSDeducteeType);
  const deducteeTypeList: any[] = deducteeTypeData['content'];
  const countryList = COUNTRIES_LIST;
  const countryCode: string = tenantInfo.country;
  const multiCurrencyWithExchangeRateList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const businessUnits = useAppSelector(businessUnitData);
  const defaultAccountValues = useAppSelector(tenantSettings);
  const columnConfig = useAppSelector(selectContactsColumnConfig);
  const columnConfigTableId = useAppSelector(selectContactsColumnConfigTableId);
  const [ilCertificates, setILCertificates] = useState<any>([]);
  const [showIlCertificatePopup, setShowIlCertificatePopup] =
    useState<boolean>(false);
  const [iLLocalBankDetails, setILLocalBankDetails] = useState<any>([]);
  const [showILLocalBankPopup, setShowILLocalBankPopup] =
    useState<boolean>(false);
  const [iLGlobalBankDetails, setILGlobalBankDetails] = useState<any>([]);
  const [tmpCustomField, setTmpCustomField] = useState<any>([]);
  const [showILGlobalBankPopup, setShowILGlobalBankPopup] =
    useState<boolean>(false);
  const [
    currentCertificateToEditOrDelete,
    setCurrentCertificateToEditOrDelete
  ] = useState<any>();
  const { confirm } = useConfirm();
  const [alternativeEmails, setAlternativeEmails] = useState<any>(
    props.populateFormData?.otherEmails ?? null
  );
  const [showMultiPurchasePL, setShowMultiPurchasePL] =
    useState<boolean>(false);
  const [showMultiSalesPL, setShowMultiSalesPL] = useState<boolean>(false);
  const [multiPlData, setMultiPlData] = useState<any>([]);
  const [multiPlSalesData, setMultiPlSalesData] = useState<any>([]);
  const agentsData = useAppSelector(selectSalesPersonMaster);
  const [showAgentDropDown, setShowAgentDropDown] = useState(false);
  const [agentSelectedIndexes, setAgentSelectedIndexes] = useState<number[]>(
    []
  );

  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const contactMode = props.contactMode;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDefaultCanadaShippingCountry = useMemo(() => {
    return (
      formState.addressInfo[formState.activeShippingAddressIndex]?.country
        ?.value?.value === TAX_SYSTEM.CANADA
    );
  }, [formState]);
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };
  const popupBtnConfigForDeducteeType: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_DEDUCTEE_TYPE
    }
  ];

  const popupBtnConfigForAddPaymentTerm: BtnType[] = [
    {
      title: t(`SETTINGS.PAYMENT_TERM.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`SETTINGS.PAYMENT_TERM.BUTTON.SAVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.ADD_PAYMENT_TERMS
    }
  ];
  const addContactRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const apiCallInProgress: MutableRefObject<boolean> = useRef(false);
  const alternateTenantDetails = useAppSelector(activeTenantInfoForBookkeeper);
  const [isSalesPersonEnable, setIsSalesPersonEnable] = useState<any>(
    alternateTenantDetails?.additionalSettings?.SALES_PERSON_MASTER_SETTING
      ?.ENABLE_SALES_PERSON_MASTER &&
      alternateTenantDetails?.additionalSettings?.SALES_PERSON_MASTER_SETTING
        ?.ENABLE_ACCESS_CONTROL_AS_PER_CONTACT_MAPPING
      ? true
      : false
  );
  const canApplySalesPersonFilterOnContact = useAppSelector(
    selectApplySalesPersonFilter
  );

  useEffect(() => {
    const payableAccountsOptions: ReactOptionType[] = [];
    const receiveableAccountsOptions: ReactOptionType[] = [];
    const paymentTermsOptions: ReactOptionType[] = [];
    const gstTreatmentOptions: ReactOptionType[] = [];
    let customerTypeOptions: ReactOptionType[] = [];
    const customerTypeMalaysiaOptions: ReactOptionType[] = [];
    const vendorTypeMalaysiaOptions: ReactOptionType[] = [];
    const assessingOfficerOptions: ReactOptionType[] = [];
    const typeOfCompanyOptions: ReactOptionType[] = [];
    const whtPaymentGroupOptions: ReactOptionType[] = [];
    const whtTaxTypesOptions: ReactOptionType[] = [];
    const taxTreatmentUaeOptions: ReactOptionType[] = [];
    const exemptionCriterialMalaysiaOptions: ReactOptionType[] = [];
    const customerTaxTypeOptions: ReactOptionType[] = [];
    const vendorTaxTypeOptions: ReactOptionType[] = [];
    let vendorTypeOptions: ReactOptionType[] = [];
    const countryOptions: ReactOptionType[] = [];
    const indianStatesOptions: ReactOptionType[] = [];
    const americaStatesOptions: ReactOptionType[] = [];
    const taxExemptOptions: ReactOptionType[] = [];
    const businessUnitsOptions: ReactOptionType[] = [];
    const nonDeductionReasonOptions: ReactOptionType[] = [];
    const deducteeTypeOptions: ReactOptionType[] = [];
    const intercompanyTypeOptions: ReactOptionType[] = [];
    const tenantCurrency: string = tenantInfo.currency;
    const updatedState = deepClone(formState);
    const addressInfo = updatedState.addressInfo[0];
    const defaultPaymentTerm =
      tenantInfo?.defaultPaymentTerm ||
      DEFAULT_ACCOUNTS_FOR_CONTACT.PAYMENT_TERM;
    const defaultAccountPayable = defaultAccountValues?.financeSettings
      ?.payableAccountCode
      ? defaultAccountValues.financeSettings.payableAccountCode
      : DEFAULT_ACCOUNTS_FOR_CONTACT.PAYABLE_ACCOUNT;
    const defaultAccountReceivable = defaultAccountValues?.financeSettings
      ?.receivableAccountCode
      ? defaultAccountValues.financeSettings.receivableAccountCode
      : DEFAULT_ACCOUNTS_FOR_CONTACT.RECEIVEABLE_ACCOUNT;
    const salesTaxOptions: ReactOptionType[] = [];
    const purchaseTaxOptions: ReactOptionType[] = [];

    payableAccounts.forEach((account: any) => {
      if (account.status === STATUS_TYPE.ACTIVE) {
        let option: ReactOptionType = {
          label: account.name,
          value: account.code
        };
        payableAccountsOptions.push(option);
      }
    });

    receiveableAccounts.forEach((account: any) => {
      if (account.status === STATUS_TYPE.ACTIVE) {
        let option: ReactOptionType = {
          label: account.name,
          value: account.code
        };
        receiveableAccountsOptions.push(option);
      }
    });

    paymentTerms?.content?.forEach((term: any) => {
      let option: ReactOptionType = {
        label: term.termName,
        value: term.id
      };
      paymentTermsOptions.push(option);
    });

    whTaxes.forEach((type: any) => {
      let option: ReactOptionType = {
        label: type.sectionName,
        value: type.tdsId
      };
      whtTaxTypesOptions.push(option);
    });
    whtTaxGrp.forEach((type: any) => {
      let option: ReactOptionType = {
        label: type.name,
        value: type.id
      };
      whtPaymentGroupOptions.push(option);
    });

    gstTreatmentValues.forEach((treatment) => {
      let option: ReactOptionType = {
        label: treatment.value as string,
        value: treatment.key as string
      };
      gstTreatmentOptions.push(option);
    });

    customerType.forEach((customer) => {
      let option: ReactOptionType = {
        label: customer.value as string,
        value: customer.key as string
      };
      customerTypeOptions.push(option);
    });

    exemptionCriterialMalaysia.forEach((customer) => {
      if (
        !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected &&
        props?.populateFormData === null
      ) {
        if (customer.key === NONE) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          exemptionCriterialMalaysiaOptions.push(option);
        }
      } else {
        if (props?.populateFormData?.exemptionCertificateMalaysia) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          exemptionCriterialMalaysiaOptions.push(option);
        } else {
          if (customer.key === NONE) {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            exemptionCriterialMalaysiaOptions.push(option);
          }
        }
      }
    });

    //For UK compliance General info Sales and Purchase Taxes
    if (countryCode === COUNTRY_CODES.UK) {
      salesTaxList.forEach((tax: any) => {
        if (tax.status?.toLowerCase() === STATUS_TYPE.active) {
          let option: ReactOptionType = {
            label: tax.name,
            value: tax.code
          };
          salesTaxOptions.push(option);
        }
      });

      purchaseTaxList.forEach((tax: any) => {
        if (tax.status?.toLowerCase() === STATUS_TYPE.active) {
          let option: ReactOptionType = {
            label: tax.name,
            value: tax.code
          };
          purchaseTaxOptions.push(option);
        }
      });
      updatedState.generalInfo.ukBusinessRegistrationNo.value =
        props?.populateFormData?.ukBusinessRegistrationNo ?? '';
      updatedState.generalInfo.ukVatIdentificationNo.value =
        props?.populateFormData?.ukVatIdentificationNo ?? '';
      if (updatedState.generalInfo.ukDefaultSalesTaxRate) {
        let selectedSalesTax = salesTaxOptions?.find(
          (taxObj: any) =>
            taxObj?.value === props?.populateFormData?.ukDefaultSalesTaxRate
        ) ?? { label: '', value: '' };
        updatedState.generalInfo.ukDefaultSalesTaxRate.options = [
          ...salesTaxOptions
        ];
        updatedState.generalInfo.ukDefaultSalesTaxRate.value = selectedSalesTax;
      }
      if (updatedState.generalInfo.ukDefaultPurchaseTaxRate) {
        let selectedPurchaseTax = purchaseTaxOptions?.find(
          (taxObj: any) =>
            taxObj?.value === props?.populateFormData?.ukDefaultPurchaseTaxRate
        ) ?? { label: '', value: '' };
        updatedState.generalInfo.ukDefaultPurchaseTaxRate.options = [
          ...purchaseTaxOptions
        ];
        updatedState.generalInfo.ukDefaultPurchaseTaxRate.value =
          selectedPurchaseTax;
      }
    }

    let customerTypeList =
      props?.populateFormData?.exemptionCertificateMalaysia &&
      props?.populateFormData?.exemptionCriterialMalaysia ===
        EXEMPTION_CRITERIAL_MALAYSIA_KEY.EXEMPTED_ZERO
        ? customerTypeMalaysiaExempted
        : customerTypeMalaysia;

    customerTypeList.forEach((customer) => {
      if (
        !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected &&
        props?.populateFormData === null
      ) {
        if (customer.key === NONE) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          customerTypeMalaysiaOptions.push(option);
        }
      } else {
        if (
          props?.populateFormData?.exemptionCertificateMalaysia &&
          props?.populateFormData?.exemptionCriterialMalaysia !== NONE
        ) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          customerTypeMalaysiaOptions.push(option);
        } else {
          if (customer.key === NONE) {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            customerTypeMalaysiaOptions.push(option);
          }
        }
      }
    });

    vendorTypeMalaysia.forEach((customer) => {
      if (
        !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected &&
        props?.populateFormData === null
      ) {
        if (customer.key === NONE) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          vendorTypeMalaysiaOptions.push(option);
        }
      } else {
        if (props?.populateFormData?.exemptionCertificateMalaysia) {
          let option: ReactOptionType = {
            label: customer.value as string,
            value: customer.key as string
          };
          vendorTypeMalaysiaOptions.push(option);
        } else {
          if (customer.key === NONE) {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            vendorTypeMalaysiaOptions.push(option);
          }
        }
      }
    });

    assessingOfficerNumberIsrael.forEach((ele: any) => {
      let option: ReactOptionType = {
        label: ele.value as string,
        value: ele.key as string
      };
      assessingOfficerOptions.push(option);
    });

    typeOfCompanyIsrael.forEach((ele: any) => {
      let option: ReactOptionType = {
        label: ele.value as string,
        value: ele.key as string
      };
      typeOfCompanyOptions.push(option);
    });

    taxTreatmentUae.forEach((customer) => {
      let option: ReactOptionType = {
        label: customer.key as string,
        value: customer.value as string
      };
      taxTreatmentUaeOptions.push(option);
    });

    customerTaxType.forEach((customer) => {
      let option: ReactOptionType = {
        label: customer.key as string,
        value: customer.value as string
      };
      customerTaxTypeOptions.push(option);
    });

    vendorTaxType.forEach((vendor) => {
      let option: ReactOptionType = {
        label: vendor.key as string,
        value: vendor.value as string
      };
      vendorTaxTypeOptions.push(option);
    });

    vendorType.forEach((vendor) => {
      let option: ReactOptionType = {
        label: vendor.value as string,
        value: vendor.key as string
      };
      vendorTypeOptions.push(option);
    });

    indianStates.forEach((state) => {
      let option: ReactOptionType = {
        label: state.name,
        value: state.code,
        code: state.stateCode,
        searchableKey: `${state.name} ${state.stateCode}`
      };
      indianStatesOptions.push(option);
    });

    americaStates.forEach((state) => {
      let option: ReactOptionType = {
        label: state.name,
        value: state.code,
        searchableKey: `${state.name} ${state.code}`,
        code: state.code
      };
      americaStatesOptions.push(option);
    });

    taxExemptionReasons.forEach((reason) => {
      let option: ReactOptionType = {
        label: reason.name,
        value: reason.id
      };
      taxExemptOptions.push(option);
    });

    nonDeductionReasonsIndia.forEach((reason) => {
      let option: ReactOptionType = {
        label: reason.name,
        value: reason.id
      };
      nonDeductionReasonOptions.push(option);
    });

    deducteeTypeList?.forEach((deducteeType) => {
      if (deducteeType.active) {
        let option: ReactOptionType = {
          label: deducteeType.type,
          value: deducteeType.id
        };
        deducteeTypeOptions.push(option);
      }
    });

    if (businessUnits) {
      businessUnits.forEach((unit) => {
        let option: ReactOptionType = {
          label: unit.businessUnitName,
          value: unit.id
        };
        businessUnitsOptions.push(option);
      });
    }

    countryList.forEach((country) => {
      const option: ReactOptionType = {
        label: country.name,
        value: country.code
      };
      countryOptions.push(option);
    });

    if (
      tenantInfo?.additionalSettings?.MULTI_COMPANY?.organizationNature ===
      'PARENT'
    ) {
      intercompanyTypeOptions.push(
        { label: '-', value: '' },
        { label: 'Subsidiary Company', value: 'SUBSIDIARY' }
      );
    } else if (
      tenantInfo?.additionalSettings?.MULTI_COMPANY?.organizationNature ===
      'SUBSIDIARY'
    ) {
      intercompanyTypeOptions.push(
        { label: '-', value: '' },
        { label: 'Parent Company', value: 'PARENT' },
        { label: 'Subsidiary Company', value: 'SUBSIDIARY' }
      );
    } else {
      updatedState.generalInfo.intercompanyType.hidden = true;
    }

    // compliance specific fields
    complianceSpecificFields(updatedState);

    // filter active multicurrency
    const activeMultiCurrencyList = multiCurrencyWithExchangeRateList.filter(
      (currency) => currency.currencyStatus === STATUS_TYPE.ACTIVE
    );
    updatedState.activeMultiCurrencyList = activeMultiCurrencyList;

    // payable account
    if (updatedState.accounting.payableAccount) {
      let selectedOption: any = [];
      if (contactMode === DOCUMENT_MODE.NEW) {
        selectedOption = payableAccountsOptions.find(
          (option) => option.value === defaultAccountPayable
        );
      }

      if (props.populateFormData?.payableAccountCode) {
        const accountCode: string = props.populateFormData.payableAccountCode;
        selectedOption = payableAccountsOptions.find(
          (option) => option.value === accountCode
        );
      }
      updatedState.accounting.payableAccount = {
        ...updatedState.accounting.payableAccount,
        options: [...payableAccountsOptions],
        defaultValue: selectedOption,
        value: selectedOption ? selectedOption : { label: '', value: '' }
      };
    }

    // recieveable account
    if (updatedState.accounting.receiveableAccount) {
      let selectedOption: any = [];
      if (contactMode === DOCUMENT_MODE.NEW) {
        selectedOption = receiveableAccountsOptions.find(
          (option) => option.value === defaultAccountReceivable
        );
      }
      if (props.populateFormData?.receivableAccountCode) {
        const accountCode: string =
          props.populateFormData.receivableAccountCode;
        selectedOption = receiveableAccountsOptions.find(
          (option) => option.value === accountCode
        );
      }
      updatedState.accounting.receiveableAccount = {
        ...updatedState.accounting.receiveableAccount,
        options: [...receiveableAccountsOptions],
        defaultValue: selectedOption,
        value: selectedOption ? selectedOption : { label: '', value: '' }
      };
    }

    // payment term
    if (updatedState.accounting.paymentTerm) {
      const defaultTenantPaymentTerm = paymentTermsOptions.find(
        (term) => term.value === defaultPaymentTerm
      );
      updatedState.accounting.paymentTerm = {
        ...updatedState.accounting.paymentTerm,
        options: [...paymentTermsOptions],
        defaultValue: paymentTermsOptions[3],
        value: defaultTenantPaymentTerm
          ? defaultTenantPaymentTerm
          : paymentTermsOptions[3]
      };
      updatedState.accounting.paymentTerm.options?.splice(0, 0, {
        label: 'Add Payment Term',
        value: 'addPaymentTerm'
      });
      if (props.populateFormData?.paymentTermCode) {
        const paymentTermId: string =
          props.populateFormData.paymentTermCode.toString();
        const paymentTerm = paymentTermsOptions.find(
          (paymentTerm) => paymentTerm.value.toString() === paymentTermId
        );
        if (paymentTerm) {
          updatedState.accounting.paymentTerm.value = paymentTerm;
        }
      }
    }

    // gst treatment
    if (updatedState.accounting.gstTreatment) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.gstTreatment) {
        const gstTreatmentKey: string = props.populateFormData.gstTreatment;
        selectedOption = gstTreatmentOptions.find(
          (option) => option.value === gstTreatmentKey
        );
        // if GST Treatment is Overseas/Consumer/Unregistered Business/ hide GSTIN field
        if (
          gstTreatmentKey === GST_TREATMENT_VALUES[2].key ||
          gstTreatmentKey === GST_TREATMENT_VALUES[3].key ||
          gstTreatmentKey === GST_TREATMENT_VALUES[4].key
        ) {
          updatedState.accounting.gstIn.hidden = true;
        }
      }
      updatedState.accounting.gstTreatment = {
        ...updatedState.accounting.gstTreatment,
        options: [...gstTreatmentOptions],
        defaultValue: selectedOption ? selectedOption : gstTreatmentOptions[0],
        value: selectedOption ? selectedOption : gstTreatmentOptions[0]
      };

      // populate customerType and vendorType options
      const custVendorOptions = getCustomerVendorOptions(
        updatedState.accounting.gstTreatment.value?.value
      );
      if (custVendorOptions) {
        customerTypeOptions.splice(0, customerTypeOptions.length);
        customerTypeOptions = custVendorOptions.customerOptions?.map(
          (option) => ({
            label: option.value as string,
            value: option.key as string
          })
        );

        vendorTypeOptions.splice(0, vendorTypeOptions.length);
        vendorTypeOptions = custVendorOptions.vendorOptions?.map((option) => ({
          label: option.value as string,
          value: option.key as string
        }));
      }
    }

    // vendor type
    if (updatedState.accounting.vendorType) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.vendorType) {
        const vendorTypeKey: string = props.populateFormData.vendorType;
        selectedOption = vendorTypeOptions.find(
          (option) => option.value === vendorTypeKey
        );
      }
      updatedState.accounting.vendorType = {
        ...updatedState.accounting.vendorType,
        options: [...vendorTypeOptions],
        defaultValue: selectedOption ? selectedOption : vendorTypeOptions[0],
        value: selectedOption ? selectedOption : vendorTypeOptions[0]
      };
    }

    // customer type
    if (updatedState.accounting.customerType) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.customerType) {
        const customerTypeOption: string = props.populateFormData.customerType;
        selectedOption = customerTypeOptions.find(
          (option) => option.value === customerTypeOption
        );
      }
      updatedState.accounting.customerType.options = [...customerTypeOptions];
      updatedState.accounting.customerType.defaultValue = selectedOption
        ? selectedOption
        : customerTypeOptions[0];
      updatedState.accounting.customerType.value = selectedOption
        ? selectedOption
        : customerTypeOptions[0];
    }

    // customer tax type
    if (updatedState.accounting.customerTaxType) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.taxTypeCustomer) {
        const customerTaxTypeOption: string =
          props.populateFormData.taxTypeCustomer;
        selectedOption = customerTaxTypeOptions.find(
          (option) => option.value === customerTaxTypeOption
        );
      }
      updatedState.accounting.customerTaxType.options = [
        ...customerTaxTypeOptions
      ];
      updatedState.accounting.customerTaxType.defaultValue = selectedOption
        ? selectedOption
        : customerTaxTypeOptions[0];
      updatedState.accounting.customerTaxType.value = selectedOption
        ? selectedOption
        : customerTaxTypeOptions[0];
    }

    // vendor tax type
    if (updatedState.accounting.vendorTaxType) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.taxTypeVendor) {
        const vendorTaxTypeOption: string =
          props.populateFormData.taxTypeVendor;
        selectedOption = vendorTaxTypeOptions.find(
          (option) => option.value === vendorTaxTypeOption
        );
      }
      updatedState.accounting.vendorTaxType.options = [...vendorTaxTypeOptions];
      updatedState.accounting.vendorTaxType.defaultValue = selectedOption
        ? selectedOption
        : vendorTaxTypeOptions[0];
      updatedState.accounting.vendorTaxType.value = selectedOption
        ? selectedOption
        : vendorTaxTypeOptions[0];
    }

    // UAE

    if (updatedState.generalInfo.taxTreatmentUae) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.taxTreatment) {
        const taxTreatmentUaeKey: string = props.populateFormData.taxTreatment;
        selectedOption = taxTreatmentUaeOptions.find(
          (option) => option.label === taxTreatmentUaeKey
        );
      }
      updatedState.generalInfo.taxTreatmentUae = {
        ...updatedState.generalInfo.taxTreatmentUae,
        options: [...taxTreatmentUaeOptions],
        defaultValue: selectedOption
          ? selectedOption
          : taxTreatmentUaeOptions[0],
        value: selectedOption ? selectedOption : taxTreatmentUaeOptions[0]
      };
    }

    if (updatedState.generalInfo.vatRegistrationUae) {
      if (props.populateFormData?.vatRegistrationNo) {
        const vatRegistrationUae: string =
          props.populateFormData.vatRegistrationNo;
        updatedState.generalInfo.vatRegistrationUae.value = vatRegistrationUae;
      }
    }

    // INDONESIA

    //nik number
    if (updatedState.accounting.nikNumber) {
      if (props.populateFormData?.nikNumber) {
        const nikNumber: string = props.populateFormData.nikNumber;
        updatedState.accounting.nikNumber.value = nikNumber;
      }
    }

    //mpwp number
    if (updatedState.accounting.npwpNumber) {
      if (props.populateFormData?.npwpNumber) {
        const npwpNumber: string = props.populateFormData.npwpNumber;
        updatedState.accounting.npwpNumber.value = formatNPWPNumber(npwpNumber);
      }
    }

    // malaysia

    // vendor type malaysia
    if (updatedState.accounting.vendorTypeMalaysia) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.vendorTypeMalaysia) {
        const vendorTypeKey: string = props.populateFormData.vendorTypeMalaysia;
        selectedOption = vendorTypeMalaysiaOptions.find(
          (option) => option.label === vendorTypeKey
        );
      }
      updatedState.accounting.vendorTypeMalaysia = {
        ...updatedState.accounting.vendorTypeMalaysia,
        options: [...vendorTypeMalaysiaOptions],
        defaultValue: selectedOption
          ? selectedOption
          : vendorTypeMalaysiaOptions[0],
        value: selectedOption ? selectedOption : vendorTypeMalaysiaOptions[0]
      };
    }

    // customer type malaysia
    if (updatedState.accounting.customerTypeMalaysia) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.customerTypeMalaysia) {
        const customerTypeMalaysiaKey: string =
          props.populateFormData.customerTypeMalaysia;
        selectedOption = customerTypeMalaysiaOptions.find(
          (option) => option.label === customerTypeMalaysiaKey
        );
      }
      updatedState.accounting.customerTypeMalaysia = {
        ...updatedState.accounting.customerTypeMalaysia,
        options: [...customerTypeMalaysiaOptions],
        defaultValue: selectedOption
          ? selectedOption
          : customerTypeMalaysiaOptions[0],
        value: selectedOption ? selectedOption : customerTypeMalaysiaOptions[0]
      };
    }

    // exemption Criterial Malaysia
    if (updatedState.accounting.exemptionCriterialMalaysia) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.exemptionCriterialMalaysia) {
        const exemptionCriterialMalaysiaKey: string =
          props.populateFormData.exemptionCriterialMalaysia;
        selectedOption = exemptionCriterialMalaysiaOptions.find(
          (option) => option.label === exemptionCriterialMalaysiaKey
        );
      }
      updatedState.accounting.exemptionCriterialMalaysia = {
        ...updatedState.accounting.exemptionCriterialMalaysia,
        options: [...exemptionCriterialMalaysiaOptions],
        defaultValue: selectedOption
          ? selectedOption
          : exemptionCriterialMalaysiaOptions[0],
        value: selectedOption
          ? selectedOption
          : exemptionCriterialMalaysiaOptions[0]
      };
    }

    //salesTaxRegistrationNumber
    if (updatedState.accounting.salesTaxRegistrationNumber) {
      if (props.populateFormData?.salesTaxRegNo) {
        const salesTaxRegistrationNumber: string =
          props.populateFormData.salesTaxRegNo;
        updatedState.accounting.salesTaxRegistrationNumber.value =
          salesTaxRegistrationNumber;
      }
    }

    //serviceTaxRegistrationNumber
    if (updatedState.accounting.serviceTaxRegistrationNumber) {
      if (props.populateFormData?.serviceTaxRegNo) {
        const serviceTaxRegistrationNumber: string =
          props.populateFormData.serviceTaxRegNo;
        updatedState.accounting.serviceTaxRegistrationNumber.value =
          serviceTaxRegistrationNumber;
      }
    }

    //businessRegistrationNumber
    if (updatedState.accounting.businessRegistrationNumber) {
      if (props.populateFormData?.businessRegNo) {
        const businessRegistrationNumber: string =
          props.populateFormData.businessRegNo;
        updatedState.accounting.businessRegistrationNumber.value =
          businessRegistrationNumber;
      }
    }

    //exemptionCertificateMalaysia
    if (!updatedState.additonalInfo.exemptionCertificateMalaysia.hidden) {
      if (props.populateFormData) {
        updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected =
          props.populateFormData.exemptionCertificateMalaysia;
      }
    }

    // Vendor type
    if (!updatedState.additonalInfo.exemptionCertificateMalaysia.hidden) {
      if (props.populateFormData) {
        let vendorType: any =
          updatedState?.accounting?.vendorTypeMalaysia?.options?.filter(
            (exemption: any) =>
              exemption.value === props.populateFormData.vendorTypeMalaysia
          );

        updatedState.accounting.vendorTypeMalaysia.value = vendorType[0];

        updatedState.accounting.vendorTypeMalaysia = {
          ...updatedState.accounting.vendorTypeMalaysia,
          defaultValue: vendorType[0],
          value: vendorType[0]
        };
      }
    }

    // Customer type
    if (!updatedState.additonalInfo.exemptionCertificateMalaysia.hidden) {
      if (props.populateFormData) {
        let customerType: any =
          updatedState?.accounting?.customerTypeMalaysia?.options?.filter(
            (exemption: any) =>
              exemption.value === props.populateFormData.customerTypeMalaysia
          );

        updatedState.accounting.customerTypeMalaysia.value = customerType[0];

        updatedState.accounting.customerTypeMalaysia = {
          ...updatedState.accounting.customerTypeMalaysia,
          defaultValue: customerType[0],
          value: customerType[0]
        };
      }
    }

    // Exempt Type
    if (!updatedState.additonalInfo.exemptionCertificateMalaysia.hidden) {
      if (props.populateFormData) {
        let exemptionType: any =
          updatedState?.accounting?.exemptionCriterialMalaysia?.options?.filter(
            (exemption: any) =>
              exemption.value ===
              props.populateFormData.exemptionCriterialMalaysia
          );

        updatedState.accounting.exemptionCriterialMalaysia.value =
          exemptionType[0];

        updatedState.accounting.exemptionCriterialMalaysia = {
          ...updatedState.accounting.exemptionCriterialMalaysia,
          defaultValue: exemptionType[0],
          value: exemptionType[0]
        };
      }
    }

    // name
    if (updatedState.generalInfo.name) {
      if (props.populateFormData?.name) {
        const name: string = props.populateFormData.name;
        updatedState.generalInfo.name.value = name;
      }
    }

    // email
    if (updatedState.generalInfo.email) {
      if (props.populateFormData?.emailId) {
        const emailId: string = props.populateFormData.emailId;
        updatedState.generalInfo.email.value = emailId;
      }
    }

    // contact number
    if (updatedState.generalInfo.contactNumber) {
      updatedState.generalInfo.countryNumberOptions.options = [...countryList];

      // based on tenant info
      const phoneNumberFormatBasedOnTenant = countryList.find(
        (country) => country.code === countryCode
      );
      if (phoneNumberFormatBasedOnTenant) {
        updatedState.generalInfo.countryNumberOptions.value =
          phoneNumberFormatBasedOnTenant;
      }
      // based on props
      if (props.populateFormData?.contactNumber) {
        const contactNumberDetails = parsePhoneNumberFromString(
          props.populateFormData.contactNumber
        );
        if (contactNumberDetails) {
          if (contactNumberDetails?.country) {
            const phoneNumberFormatBasedOnProps = countryList.find(
              (country) => country.code === contactNumberDetails.country
            );

            if (phoneNumberFormatBasedOnProps) {
              updatedState.generalInfo.countryNumberOptions.value =
                phoneNumberFormatBasedOnProps;
              updatedState.generalInfo.contactNumber.value =
                contactNumberDetails.nationalNumber;
            }
          }
          if (props?.populateFormData?.contactNumber.startsWith('+1758')) {
            updatedState.generalInfo.countryNumberOptions.value =
              countryList.find((country: any) => country.code === 'LC') || null;
          }
          if (props?.populateFormData?.contactNumber.startsWith('+1')) {
            updatedState.generalInfo.countryNumberOptions.value =
              countryList.find((country: any) => country.code === 'US') || null;
          }
          if (props?.populateFormData?.contactNumber.startsWith('+7')) {
            updatedState.generalInfo.countryNumberOptions.value =
              countryList.find((country: any) => country.code === 'TO') || null;
          }
          updatedState.generalInfo.contactNumber.value =
            contactNumberDetails.nationalNumber;
        } else {
          const phoneNumberFormatBasedOnProps: any = countryList.find(
            (country) => country.code === countryCode
          );
          if (phoneNumberFormatBasedOnProps) {
            updatedState.generalInfo.countryNumberOptions.value =
              phoneNumberFormatBasedOnProps;
            updatedState.generalInfo.contactNumber.value =
              props.populateFormData.contactNumber;
          }
        }
      }
    }

    // panNumber
    if (updatedState.generalInfo.panNumber) {
      if (props.populateFormData?.pan) {
        const panNumber: string = props.populateFormData.pan;
        updatedState.generalInfo.panNumber.value = panNumber;
      }
    }

    // gstIn
    if (updatedState.accounting.gstIn) {
      if (props.populateFormData?.gstin) {
        const gstin: string = props.populateFormData.gstin;
        updatedState.accounting.gstIn.value = gstin;
      }
    }

    // address
    if (addressInfo) {
      // default country based on tenant
      const defaultCountryOption = countryOptions.find(
        (option) => option.value === countryCode
      );
      if (defaultCountryOption?.value === COUNTRY_CODES.IN) {
        addressInfo.state.options = indianStatesOptions;
        addressInfo.state.value = { label: '', value: '' };
      } else if (defaultCountryOption?.value === COUNTRY_CODES.US) {
        addressInfo.state.options = americaStatesOptions;
        addressInfo.state.value = { label: '', value: '' };
      }

      addressInfo.country = {
        ...addressInfo.country,
        options: [...countryOptions],
        defaultValue: defaultCountryOption,
        value: defaultCountryOption
          ? defaultCountryOption
          : { label: 'India', value: COUNTRY_CODES.IN }
      };

      // based on tenant info
      addressInfo.countryNumberOptions.options = [...countryList];
      const phoneNumberFormatBasedOnTenant = countryList.find(
        (country) => country.code === countryCode
      );
      if (phoneNumberFormatBasedOnTenant) {
        addressInfo.countryNumberOptions.value = phoneNumberFormatBasedOnTenant;
      }

      if (
        props.populateFormData?.billingAddress ||
        props.populateFormData?.shippingAddress
      ) {
        let tmpBillAddress: any = [];
        let tmpShipAddress: any = [];
        if (props.populateFormData.billingAddress) {
          tmpBillAddress = [...props.populateFormData.billingAddress];
          // tmpBillAddress = tmpBillAddress.reverse()
        }
        if (props.populateFormData.shippingAddress) {
          tmpShipAddress = [...props.populateFormData.shippingAddress];
          // tmpShipAddress = tmpShipAddress.reverse()
        }

        const activeBillingAddressIndex: number =
          tmpBillAddress?.findIndex((address: any) => address.preferred) | 0;
        const activeShippingAddressIndex: number =
          tmpShipAddress?.findIndex((address: any) => address.preferred) | 0;
        updatedState.activeBillingAddressIndex = activeBillingAddressIndex;
        updatedState.activeShippingAddressIndex = activeShippingAddressIndex;
        const billingAddressList: AddressInfo[] = [];
        let addressList: any[] = [];
        if (props.populateFormData.billingAddress?.length > 0) {
          addressList = props.populateFormData.billingAddress;
        } else {
          addressList = props.populateFormData.shippingAddress;
        }
        addressList.forEach((addressData: any) => {
          let billingAddress: AddressInfo = deepClone(
            updatedState.addressInfo[0]
          );
          // address
          if (addressData.contactName) {
            billingAddress.contactName.value = addressData.contactName;
          }

          if (addressData.address1) {
            billingAddress.address.value = addressData.address1;
          }

          if (addressData.address2) {
            billingAddress.address2.value = addressData.address2;
          }

          // state
          let selectedIndianState = indianStatesOptions.find(
            (option) =>
              option.label?.toLowerCase() === addressData.state?.toLowerCase()
          );

          let selectedAmericanState = americaStatesOptions.find(
            (option) =>
              option.label?.toLowerCase() === addressData.state?.toLowerCase()
          );

          // Build state options for India or US, in case no state is selected
          let stateOptions: any[] = [];
          if (defaultCountryOption?.value === COUNTRY_CODES.US) {
            stateOptions = [...americaStatesOptions];
          } else if (defaultCountryOption?.value === COUNTRY_CODES.IN) {
            stateOptions = [...indianStatesOptions];
          } else {
            stateOptions = [];
          }

          if (selectedIndianState) {
            billingAddress.state = {
              ...billingAddress.state,
              options: [...indianStatesOptions],
              defaultValue: selectedIndianState,
              value: selectedIndianState
            };
          } else if (selectedAmericanState) {
            billingAddress.state = {
              ...billingAddress.state,
              options: [...americaStatesOptions],
              defaultValue: selectedAmericanState,
              value: selectedAmericanState
            };
          } else {
            billingAddress.state = {
              ...billingAddress.state,
              options: stateOptions,
              defaultValue: {
                label: addressData.state,
                value: addressData.state
              },
              value: {
                label: addressData.state,
                value: addressData.state
              }
            };
          }

          // country
          if (addressData.country) {
            let selectedOption = countryOptions.find(
              (option) => option.label === addressData.country
            );

            billingAddress.country = {
              ...billingAddress.country,
              options: [...countryOptions],
              defaultValue: selectedOption,
              value: selectedOption
                ? selectedOption
                : { label: 'India', value: COUNTRY_CODES.IN }
            };
          }

          // city
          const state = INDIAN_STATES_MOCK_DATA.find(
            (state) => state.code === addressData.state
          );
          if (state) {
            const cityOptions: ReactOptionType[] = state.cities.map((city) => {
              const option: ReactOptionType = {
                label: city,
                value: city
              };
              if (city === addressData.city) {
                billingAddress.city.value = option;
              }
              return option;
            });
            if (!billingAddress.city.value.value) {
              billingAddress.city.value = {
                label: addressData.city,
                value: addressData.city
              };
            }
            billingAddress.city.options = [...cityOptions];
          } else {
            billingAddress.city = {
              ...billingAddress.city,
              options: [],
              defaultValue: {
                label: addressData.city,
                value: addressData.city
              },
              value: { label: addressData.city, value: addressData.city }
            };
          }

          // postal code
          if (addressData.postalCode) {
            billingAddress.postalCode.value = addressData.postalCode;
          }

          // address contact number
          if (updatedState.addressInfo) {
            // based on props
            if (addressData?.mobileNumber) {
              const contactNumberDetails = parsePhoneNumberFromString(
                addressData.mobileNumber
              );
              if (contactNumberDetails) {
                if (contactNumberDetails?.country) {
                  const phoneNumberFormatBasedOnProps = countryList.find(
                    (country) => country.code === contactNumberDetails.country
                  );

                  if (phoneNumberFormatBasedOnProps) {
                    billingAddress.countryNumberOptions.value =
                      phoneNumberFormatBasedOnProps;
                    billingAddress.contactNumber.value =
                      contactNumberDetails.nationalNumber;
                  }
                }
                if (addressData.mobileNumber?.startsWith('+1758')) {
                  billingAddress.countryNumberOptions.value =
                    countryList.find((country: any) => country.code === 'LC') ||
                    null;
                }
                if (addressData.mobileNumber?.startsWith('+1')) {
                  billingAddress.countryNumberOptions.value =
                    countryList.find((country: any) => country.code === 'US') ||
                    null;
                }
                if (addressData.mobileNumber?.startsWith('+7')) {
                  billingAddress.countryNumberOptions.value =
                    countryList.find((country: any) => country.code === 'TO') ||
                    null;
                }
                billingAddress.contactNumber.value =
                  contactNumberDetails.nationalNumber;
              } else {
                const phoneNumberFormatBasedOnProps: any = countryList.find(
                  (country) => country.code === countryCode
                );
                if (phoneNumberFormatBasedOnProps) {
                  billingAddress.countryNumberOptions.value =
                    phoneNumberFormatBasedOnProps;
                  billingAddress.contactNumber.value = addressData.mobileNumber;
                }
              }
            }
          }

          // email
          if (billingAddress) {
            if (addressData?.emailId) {
              const emailId: string = addressData.emailId;
              billingAddress.email.value = emailId;
            }
          }

          // address custom fields
          billingAddress.customFields = addressData?.customFields ?? [];

          billingAddressList.push(billingAddress);
        });
        updatedState.addressInfo = billingAddressList;
      }
    }

    // numbering Format
    if (updatedState.generalInfo.numberingFormat) {
      if (props.populateFormData?.documentSequenceCode) {
        const documentSequenceCode: string =
          props.populateFormData.documentSequenceCode;
        const sequenceFormat: string = props.populateFormData.sequenceFormat;

        updatedState.generalInfo.numberingFormat.defaultValue =
          documentSequenceCode;
        updatedState.generalInfo.numberingFormat.value = sequenceFormat;
      }
    }

    // currency
    if (updatedState.generalInfo.currency) {
      if (tenantCurrency) {
        updatedState.generalInfo.currency.value = tenantCurrency;
      }

      if (props.populateFormData?.currencyCode) {
        updatedState.generalInfo.currency.value =
          props.populateFormData.currencyCode;
      }
    }

    // intercompanyType
    if (
      updatedState.generalInfo.intercompanyType &&
      intercompanyTypeOptions.length
    ) {
      if (props.populateFormData?.intercompanyType) {
        const intercompanyType: string =
          props.populateFormData.intercompanyType;
        const selectedOption = intercompanyTypeOptions?.find(
          (option: any) => option.value === intercompanyType
        );
        if (selectedOption) {
          updatedState.generalInfo.intercompanyType.value = selectedOption;
        }
      } else {
        updatedState.generalInfo.intercompanyType.value =
          intercompanyTypeOptions[0];
      }
      updatedState.generalInfo.intercompanyType.options =
        intercompanyTypeOptions;
    }

    // resident and non-resident
    if (!updatedState.additonalInfo.resident.hidden) {
      if (props.populateFormData) {
        updatedState.additonalInfo.resident.isSelected = props.populateFormData
          .resident
          ? true
          : false;

        // for indonesia compliance
        if (props.populateFormData.residentStatusIndonesia) {
          updatedState.additonalInfo.resident.isSelected = props
            .populateFormData.residentStatusIndonesia
            ? true
            : false;
        }
      }
    }

    // peppolId
    if (!updatedState.generalInfo.peppolID.hidden) {
      if (props.populateFormData?.peppolId) {
        updatedState.generalInfo.peppolID.value =
          props.populateFormData.peppolId;
      }
    }

    // contact UEN
    if (!updatedState.generalInfo.contactUEN.hidden) {
      if (props.populateFormData?.uen) {
        updatedState.generalInfo.contactUEN.value = props.populateFormData.uen;
      }
    }

    // taxNumber
    if (!updatedState.generalInfo.taxNumber.hidden) {
      if (props.populateFormData?.taxNumber) {
        updatedState.generalInfo.taxNumber.value =
          props.populateFormData.taxNumber;
      }
    }
    // taxPayerIdIsrael
    if (!updatedState.accounting.taxPayerIdIsrael.hidden) {
      if (props.populateFormData?.taxPayerIdIsrael) {
        updatedState.accounting.taxPayerIdIsrael.value =
          props.populateFormData.taxPayerIdIsrael;
      }
    }

    // assessingOfficerNumber
    if (updatedState.accounting.assessingOfficerNumberIsrael) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.assessingOfficerNumberIsrael) {
        const assessingKey: string =
          props.populateFormData.assessingOfficerNumberIsrael;
        selectedOption = assessingOfficerOptions.find(
          (option) => option.value === assessingKey
        );
      }
      updatedState.accounting.assessingOfficerNumberIsrael = {
        ...updatedState.accounting.assessingOfficerNumberIsrael,
        options: [...assessingOfficerOptions],
        defaultValue: selectedOption
          ? selectedOption
          : { label: '', value: '' },
        value: selectedOption ? selectedOption : { label: '', value: '' }
      };
    }

    //typeOfCompanyIsrael
    if (updatedState.accounting.typeOfCompanyIsrael) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.typeOfCompanyIsrael) {
        const assessingKey: string = props.populateFormData.typeOfCompanyIsrael;
        selectedOption = typeOfCompanyOptions.find(
          (option) => option.value === assessingKey
        );
      }
      updatedState.accounting.typeOfCompanyIsrael = {
        ...updatedState.accounting.typeOfCompanyIsrael,
        options: [...typeOfCompanyOptions],
        defaultValue: selectedOption
          ? selectedOption
          : { label: '', value: '' },
        value: selectedOption ? selectedOption : { label: '', value: '' }
      };
    }

    //whtapplicable Israel
    if (!updatedState.accounting.whtApplicableIsrael.hidden) {
      if (props.populateFormData?.whtApplicableIsrael) {
        updatedState.accounting.whtApplicableIsrael.isSelected = props
          .populateFormData.whtApplicableIsrael
          ? true
          : false;
      }
    }
    //isSelfInvoicingIsrael
    if (!updatedState.accounting.isSelfInvoicingIsrael.hidden) {
      if (props.populateFormData?.isSelfInvoicingIsrael) {
        updatedState.accounting.isSelfInvoicingIsrael.isSelected = props
          .populateFormData.isSelfInvoicingIsrael
          ? true
          : false;
      }
    }
    //isSelfInvoicingIsrael
    if (!updatedState.accounting.isIncludedTaxReportingAtSourceIsrael.hidden) {
      if (props.populateFormData?.isSelfInvoicingIsrael) {
        updatedState.accounting.isSelfInvoicingIsrael.isSelected = props
          .populateFormData.isIncludedTaxReportingAtSourceIsrael
          ? true
          : false;
      }
    }
    // set todays date in taxCertificateValidityDateIsrael
    updatedState.accounting.taxCertificateValidityDateIsrael.date =
      Utility.formatDate(new Date(), DATE_FORMAT.DD_MM_YYYY);

    //taxCertificateValidityDateIsrael
    if (!updatedState.accounting.taxCertificateValidityDateIsrael.hidden) {
      if (props.populateFormData?.taxCertificateValidityDateIsrael) {
        updatedState.accounting.taxCertificateValidityDateIsrael.date =
          props.populateFormData.taxCertificateValidityDateIsrael;
      }
    }

    if (!updatedState.accounting.withHoldingCertificatesIsrael.hidden) {
      if (props.populateFormData?.withHoldingCertificatesIsrael) {
        let ilCertificatesData =
          props.populateFormData.withHoldingCertificatesIsrael?.map(
            (ele: any) => ({
              ...ele,
              validTo: DateFormatService.getDateFromStr(
                ele.validTo,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              validFrom: DateFormatService.getDateFromStr(
                ele.validFrom,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
            })
          );
        updatedState.accounting.withHoldingCertificatesIsrael.value =
          ilCertificatesData;
        setILCertificates(ilCertificatesData);
      }
    }
    if (!updatedState.accounting.israelBankDetails.hidden) {
      if (props.populateFormData?.israelBankDetails) {
        updatedState.accounting.israelBankDetails.value =
          props.populateFormData?.israelBankDetails;
        setILLocalBankDetails(
          Utility.isEmpty(props.populateFormData?.israelBankDetails)
            ? []
            : props.populateFormData?.israelBankDetails
        );
      }
    }
    if (!updatedState.accounting.israelGlobalBankDetails.hidden) {
      if (props.populateFormData?.israelGlobalBankDetails) {
        updatedState.accounting.israelGlobalBankDetails.value =
          props.populateFormData?.israelGlobalBankDetails;
        setILGlobalBankDetails(
          Utility.isEmpty(props.populateFormData?.israelGlobalBankDetails)
            ? []
            : props.populateFormData?.israelGlobalBankDetails
        );
      }
    }

    if (!updatedState.accounting.withHoldingTaxTypeIsraelId.hidden) {
      const defaultOption = whtTaxTypesOptions.find(
        (term) => term.value?.toString() === '720'
      );
      updatedState.accounting.withHoldingTaxTypeIsraelId = {
        ...updatedState.accounting.withHoldingTaxTypeIsraelId,
        options: [...whtTaxTypesOptions],
        defaultValue: defaultOption,
        value: defaultOption || { label: '', value: '' }
      };
      if (props.populateFormData?.withHoldingTaxTypeIsraelId) {
        const id: string =
          props.populateFormData.withHoldingTaxTypeIsraelId.toString();
        const type = whtTaxTypesOptions.find(
          (type) => type.value.toString() === id
        );
        if (type) {
          updatedState.accounting.withHoldingTaxTypeIsraelId.value = type;
        }
      }
    }
    updatedState.accounting.withHoldingTaxPaymentGroupIsraelId = {
      ...updatedState.accounting.withHoldingTaxPaymentGroupIsraelId,
      options: [...whtPaymentGroupOptions]
    };
    if (updatedState.accounting.withHoldingTaxPaymentGroupIsraelId) {
      let group: any;
      if (props.populateFormData?.withHoldingTaxPaymentGroupIsraelId) {
        const id: string =
          props.populateFormData.withHoldingTaxPaymentGroupIsraelId.toString();
        group = whtPaymentGroupOptions.find(
          (group) => group.value.toString() === id
        );
      } else {
        group = whtPaymentGroupOptions?.[0];
      }
      updatedState.accounting.withHoldingTaxPaymentGroupIsraelId.value = group;
      updatedState.accounting.withHoldingTaxPaymentGroupIsraelId.defaultValue =
        group;
    }

    if (countryCode === COUNTRY_CODES.IL) {
      salesTaxList.forEach((tax: any) => {
        if (tax.status?.toLowerCase() === STATUS_TYPE.active) {
          let option: ReactOptionType = {
            label: tax.name,
            value: tax.code
          };
          salesTaxOptions.push(option);
        }
      });

      purchaseTaxList.forEach((tax: any) => {
        if (tax.status?.toLowerCase() === STATUS_TYPE.active) {
          let option: ReactOptionType = {
            label: tax.name,
            value: tax.code
          };
          purchaseTaxOptions.push(option);
        }
      });
      if (updatedState.generalInfo.salesTaxCodeIsrael) {
        let selectedSalesTax = salesTaxOptions?.find(
          (taxObj: any) =>
            taxObj?.value === props?.populateFormData?.salesTaxCodeIsrael
        ) ?? { label: '', value: '' };
        updatedState.generalInfo.salesTaxCodeIsrael.options = [
          ...salesTaxOptions
        ];
        updatedState.generalInfo.salesTaxCodeIsrael.value = selectedSalesTax;
      }
      if (updatedState.generalInfo.purchaseTaxCodeIsrael) {
        let selectedPurchaseTax = purchaseTaxOptions?.find(
          (taxObj: any) =>
            taxObj?.value === props?.populateFormData?.purchaseTaxCodeIsrael
        ) ?? { label: '', value: '' };
        updatedState.generalInfo.purchaseTaxCodeIsrael.options = [
          ...purchaseTaxOptions
        ];
        updatedState.generalInfo.purchaseTaxCodeIsrael.value =
          selectedPurchaseTax;
      }
    }

    // contactSGGovt, business unit and attentionTo
    if (
      updatedState.accounting.businessUnit &&
      businessUnitsOptions.length > 0
    ) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.singaporeGovt) {
        const businessUnit: string = props.populateFormData.businessUnit;
        selectedOption = businessUnitsOptions.find(
          (option) =>
            option.label.split('-')[0].trim().toString() === businessUnit ||
            option.label === businessUnit
        );
        updatedState.additonalInfo.contactSingaporeGovt = {
          isSelected: true,
          hidden: false
        };
        updatedState.accounting.attentionTo.hidden = false;
        updatedState.accounting.attentionTo.value =
          props.populateFormData.attentionTo;
        updatedState.accounting.attentionTo.value =
          props.populateFormData.attentionTo;
        updatedState.accounting.businessUnit.hidden = false;
      }
      updatedState.accounting.businessUnit.options = [...businessUnitsOptions];
      updatedState.accounting.businessUnit.value = selectedOption
        ? selectedOption
        : businessUnitsOptions[0];
    }

    // tax exempted (USA)
    if (updatedState.additonalInfo.taxExempted) {
      let selectedOption: ReactSelectOptionsType<string, string> | undefined;
      if (props.populateFormData?.taxExempted) {
        updatedState.additonalInfo.taxExempted.isSelected =
          props.populateFormData.taxExempted;

        // expempt type
        const taxExemptionReason: string =
          props.populateFormData.taxExemptionReason;
        selectedOption = taxExemptOptions.find(
          (option) => option.label === taxExemptionReason
        );
        updatedState.accounting.exemptType.hidden = false;
        // no validation on address, since tax expemtion is true
        updatedState.addressInfo.forEach((addressInfo) => {
          addressInfo.address.isMandatory = false;
          addressInfo.postalCode.isMandatory = false;
          addressInfo.state.isMandatory = false;
          addressInfo.country.isMandatory = false;
        });
      }
      updatedState.accounting.exemptType.options = [...taxExemptOptions];
      updatedState.accounting.exemptType.value = selectedOption
        ? selectedOption
        : taxExemptOptions[0];
    }

    // deductee type
    if (updatedState.accounting.deducteeType) {
      updatedState.accounting.deducteeType.options = [...deducteeTypeOptions];
      updatedState.accounting.deducteeType.options?.splice(0, 0, {
        label: '+ Add Deductee',
        value: 'addDeductee'
      });
      updatedState.accounting.deducteeType.value = deducteeTypeOptions[2];
    }

    // non deduction reason
    if (updatedState.accounting.nonDeductionReasons) {
      updatedState.accounting.nonDeductionReasons.options = [
        ...nonDeductionReasonOptions
      ];
      updatedState.accounting.nonDeductionReasons.value =
        nonDeductionReasonOptions[0];
    }

    // set todays date in to date
    updatedState.additonalInfo.toDate.date = Utility.formatDate(
      new Date(),
      DATE_FORMAT.DD_MM_YYYY
    );

    // set todays date in from date
    updatedState.additonalInfo.fromDate.date = Utility.formatDate(
      new Date(),
      DATE_FORMAT.DD_MM_YYYY
    );

    // tds settings for indian compliance
    if (props?.populateFormData?.tdsInfoIndia?.deductionApplicable) {
      updatedState.additonalInfo.isTDS.isSelected = true;
      updatedState.accounting.vendorTAN.value =
        props.populateFormData.tdsInfoIndia.tan;
      const selectedOption = deducteeTypeOptions.find(
        (deducteeType) =>
          deducteeType.label ===
          props.populateFormData.tdsInfoIndia.deducteeType
      );

      if (selectedOption) {
        updatedState.accounting.deducteeType.value = selectedOption;
      }
      updatedState.additonalInfo.considerExemptLimit.isSelected =
        props.populateFormData.tdsInfoIndia.considerThresholdLimit;
      updatedState.accounting.higherRate.hidden = false;
      updatedState.accounting.higherRate.value =
        props.populateFormData.tdsInfoIndia.higherRate;

      // check for non-deduction checkbox
      if (props.populateFormData?.tdsInfoIndia?.nonDeductionApplicable) {
        updatedState.additonalInfo.lowerDeductionLimit.isSelected = true;

        // un-hide ref number, tds rate, from date, to date and reason list
        updatedState.accounting.certificateNumber.hidden = false;
        updatedState.accounting.tdsRate.hidden = false;
        updatedState.additonalInfo.toDate.hidden = false;
        updatedState.additonalInfo.fromDate.hidden = false;
        updatedState.accounting.nonDeductionReasons.hidden = false;

        // populating these fields from props values
        updatedState.accounting.certificateNumber.value =
          props.populateFormData.tdsInfoIndia.noDeductionRefNo;
        updatedState.accounting.tdsRate.value =
          props.populateFormData.tdsInfoIndia.noDeductionTdsRate;
        updatedState.additonalInfo.toDate.date =
          props.populateFormData.tdsInfoIndia.noDeductionToDate;
        updatedState.additonalInfo.fromDate.date =
          props.populateFormData.tdsInfoIndia.noDeductionFromDate;
        const deductionOptionReason = nonDeductionReasonOptions.find(
          (deductionReason) =>
            deductionReason.value ===
            props.populateFormData.tdsInfoIndia.noDeductionReason
        );
        if (deductionOptionReason) {
          updatedState.accounting.nonDeductionReasons.value =
            deductionOptionReason;
        }
        updatedState.accounting.higherRate.hidden = true;
      }
    }

    // customFields
    if (updatedState.customFields) {
      if (props.populateFormData?.customField) {
        updatedState.customFields = props.populateFormData.customField;
      }
    }

    // credit Limit
    if (props.populateFormData) {
      updatedState.accounting.creditLimit.creditLimitInput.value =
        props.populateFormData.creditLimit;
    }
    if (props.populateFormData?.isCreditLimitApplicable) {
      updatedState.accounting.creditLimit.showCreditLimitInfo = true;
      updatedState.accounting.creditLimit.invoiceCreditLimitType =
        props.populateFormData.invoiceCreditLimitType;
      updatedState.accounting.creditLimit.quoteCreditLimitType =
        props.populateFormData.quotationCreditLimitType;
      updatedState.accounting.creditLimit.salesOrderCreditLimitType =
        props.populateFormData.salesOrderCreditLimitType;

      if (props.populateFormData?.isIncludeCurrentInvoice) {
        updatedState.accounting.creditLimit.invoiceSelected =
          props.populateFormData.isIncludeCurrentInvoice;
      }

      if (props.populateFormData?.isIncludeCurrentQuotation) {
        updatedState.accounting.creditLimit.quoteSelected = true;
      }

      if (props.populateFormData?.isIncludeCurrentSalesOrder) {
        updatedState.accounting.creditLimit.salesOrderSelected =
          props.populateFormData.isIncludeCurrentSalesOrder;
      }
    }

    // credit limit exchange rate
    if (
      updatedState.generalInfo.currency.value.toString() !== tenantInfo.currency
    ) {
      updatedState.accounting.creditLimit.showConversionRate = true;
    }
    const selectedCurrencyInfo = updatedState.activeMultiCurrencyList.find(
      (currency) =>
        currency.currencyCode ===
        updatedState.generalInfo.currency.value.toString()
    );
    if (selectedCurrencyInfo) {
      const exchangeRate = selectedCurrencyInfo.currencyExchangeRate;
      const preciseCurrencyExchangeRate = roundingOffStr(
        1 / exchangeRate,
        CURRENCY_PRECISION
      );
      updatedState.exchangeRate = preciseCurrencyExchangeRate;
    }

    if (props.activeTab) {
      updatedState.activeTab = props.activeTab;
    }

    /**
     * Api call to fetch all the data required for the contact form
     */
    priceListInfoUpatedState.current = { ...updatedState };

    // Hide Price list section if Price Book is enabled
    if (!tenantInfo.isAdvancePriceListEnabled) {
      fetchApiData();
    } else {
      updatedState.accounting.salesPriceLists.hidden = true;
      updatedState.accounting.purchasePriceLists.hidden = true;
    }

    // Additional payment info
    if (
      Utility.isBankFileGenerationEnabled() &&
      props.populateFormData?.additionalPaymentInfo
    ) {
      updatedState.additionalPaymentInfo.customerId.value =
        props.populateFormData.additionalPaymentInfo.customerId;
      updatedState.additionalPaymentInfo.customerInternalId.value =
        props.populateFormData.additionalPaymentInfo.customerInternalId;

      const typeOption = PAYMENT_INFO_TYPE_OPTIONS.find(
        (o) => o.value === props.populateFormData.additionalPaymentInfo.type
      );
      if (typeOption) {
        updatedState.additionalPaymentInfo.type.value = typeOption;
      }

      const bankAccountTypeOption = BANK_ACCOUNT_TYPE_OPTIONS.find(
        (o) =>
          o.value ===
          props.populateFormData.additionalPaymentInfo.bankAccountType
      );
      if (bankAccountTypeOption) {
        updatedState.additionalPaymentInfo.bankAccountType.value =
          bankAccountTypeOption;
      }

      updatedState.additionalPaymentInfo.bankAccountNumber.value =
        props.populateFormData.additionalPaymentInfo.bankAccountNumber;

      const paymentFileFormatOption = PAYMENT_FILE_FORMAT_OPTIONS.find(
        (o) =>
          o.value ===
          props.populateFormData.additionalPaymentInfo.paymentFileFormat
      );
      if (paymentFileFormatOption) {
        updatedState.additionalPaymentInfo.paymentFileFormat.value =
          paymentFileFormatOption;
      }

      updatedState.additionalPaymentInfo.countryCheck.value =
        props.populateFormData.additionalPaymentInfo.countryCheck;
      updatedState.additionalPaymentInfo.bankCode.value =
        props.populateFormData.additionalPaymentInfo.bankCode;
      updatedState.additionalPaymentInfo.processorCode.value =
        props.populateFormData.additionalPaymentInfo.processorCode;
      updatedState.additionalPaymentInfo.receivingDfiId.value =
        props.populateFormData.additionalPaymentInfo.receivingDfiId;
      updatedState.additionalPaymentInfo.checkDigit.value =
        props.populateFormData.additionalPaymentInfo.checkDigit;
      updatedState.additionalPaymentInfo.clientNumber.value =
        props.populateFormData.additionalPaymentInfo.clientNumber;
      updatedState.additionalPaymentInfo.txnCode.value =
        props.populateFormData.additionalPaymentInfo.txnCode;
      updatedState.additionalPaymentInfo.cadFinancialInstTransit.value =
        props.populateFormData.additionalPaymentInfo.cadFinancialInstTransit;
      updatedState.additionalPaymentInfo.processingCenterId.value =
        props.populateFormData.additionalPaymentInfo.processingCenterId;
    }

    if (isSalesPersonEnable) {
      let selectedOption: ReactSelectOptionsType<string, string>[] = [];
      let selectedIndex: number[] = [];
      if (!Utility.isEmpty(agentsData?.content)) {
        let agents: any = agentsData?.content.map((agent: any) => {
          return {
            label: agent?.name,
            value: agent?.id
          };
        });
        if (!Utility.isEmpty(props?.populateFormData?.salesPersonMaster)) {
          let agentIds = props?.populateFormData?.salesPersonMaster.map(
            (person: any) => person.id
          );
          agents.forEach((agent: any, index: any) => {
            if (agentIds.includes(agent.value)) {
              selectedOption.push(agent);
              selectedIndex.push(index);
            }
          });
          setAgentSelectedIndexes(selectedIndex);
        }
        updatedState.generalInfo.agent.hidden = false;
        updatedState.generalInfo.agent.options = agents;
        updatedState.generalInfo.agent.value = selectedOption;
      }
    }

    // 1099 Eligible (USA)
    if (updatedState.additonalInfo.is1099Eligible) {
      if (props.populateFormData?.is1099Eligible) {
        updatedState.additonalInfo.is1099Eligible.isSelected =
          props.populateFormData.is1099Eligible;
      }
    }

    setFormState({ ...updatedState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.readOnly) {
      const divElem = document.getElementById('popup-wrapper-container');
      const inputElements = divElem?.querySelectorAll('input');

      inputElements?.forEach((el) => (el.disabled = true));
    }
  }, [formState]);

  const getHeaderSegment = () => {
    return (
      <div className="column parent-width">
        <div
          className="row bg-gray1 border-radius-s align-items-start p-h-s"
          style={{ gap: 30, backgroundColor: 'rgb(250, 250, 250)' }}
        >
          <div
            className=" cursor-hand"
            onClick={() => changeActiveTab(CONTACT_FORM_TAB.GENERAL_INFO)}
          >
            <DKLabel
              text={'General Info'}
              className={
                formState.activeTab === CONTACT_FORM_TAB.GENERAL_INFO
                  ? ' fw-b text-app-color '
                  : ''
              }
            />
            {formState.activeTab === CONTACT_FORM_TAB.GENERAL_INFO && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
          <div
            className=" cursor-hand"
            onClick={() => changeActiveTab(CONTACT_FORM_TAB.ADDRESS_INFO)}
          >
            <DKLabel
              text={'Address'}
              className={
                formState.activeTab === CONTACT_FORM_TAB.ADDRESS_INFO
                  ? ' fw-b text-app-color '
                  : ''
              }
            />
            {formState.activeTab === CONTACT_FORM_TAB.ADDRESS_INFO && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
          <div
            className=" cursor-hand"
            onClick={() => changeActiveTab(CONTACT_FORM_TAB.COMPLIANCE)}
          >
            <DKLabel
              text={'Accounting'}
              className={
                formState.activeTab === CONTACT_FORM_TAB.COMPLIANCE
                  ? ' fw-b text-app-color '
                  : ''
              }
            />
            {formState.activeTab === CONTACT_FORM_TAB.COMPLIANCE && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
          {Utility.isBankFileGenerationEnabled() && (
            <div
              className=" cursor-hand"
              onClick={() => changeActiveTab(CONTACT_FORM_TAB.PAYMENT_INFO)}
            >
              <DKLabel
                text={'Payment Info'}
                className={
                  formState.activeTab === CONTACT_FORM_TAB.PAYMENT_INFO
                    ? ' fw-b text-app-color '
                    : ''
                }
              />
              {formState.activeTab === CONTACT_FORM_TAB.PAYMENT_INFO && (
                <div
                  className="parent-width bg-app mt-r"
                  style={{ height: 2.5 }}
                />
              )}
            </div>
          )}
          <div
            className=" cursor-hand"
            onClick={() => changeActiveTab(CONTACT_FORM_TAB.ADVANCED)}
          >
            <DKLabel
              text={'Advanced'}
              className={
                formState.activeTab === CONTACT_FORM_TAB.ADVANCED
                  ? ' fw-b text-app-color '
                  : ''
              }
            />
            {formState.activeTab === CONTACT_FORM_TAB.ADVANCED && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
        </div>
        <DKLine />
      </div>
    );
  };

  const phoneNumberOption = (phoneNumberDetails: CountryInfo) => {
    return (
      <div className="parent-width">
        <div className="currency-select-trigger">
          <div className="currency-dropdown-flag">
            <span
              className={`currency-dropdown-selector-flag flag ${phoneNumberDetails.currencyCode}`}
            ></span>
          </div>
          <div className="currency-dropdown-code">
            {`${phoneNumberDetails.name} ${phoneNumberDetails.dial_code}`}
          </div>
        </div>
      </div>
    );
  };

  const changePhoneNumberType = (
    value: CountryInfo,
    isAddressFieldContact: boolean = false,
    indexToUpdate: number = 0
  ) => {
    const updatedState = formState;

    if (!isAddressFieldContact) {
      updatedState.generalInfo.countryNumberOptions.value = value;
      updatedState.generalInfo.countryNumberOptions.showDropdown = false;
    } else {
      updatedState.addressInfo[indexToUpdate].countryNumberOptions.value =
        value;
      updatedState.addressInfo[
        indexToUpdate
      ].countryNumberOptions.showDropdown = false;
    }

    setFormState({ ...updatedState });
  };

  const showHidePhoneNumberOptions = (
    isAddressFieldContact: boolean = false,
    indexToUpdate: number
  ) => {
    const updatedState = formState;
    if (!isAddressFieldContact) {
      updatedState.generalInfo.countryNumberOptions.showDropdown =
        !updatedState.generalInfo.countryNumberOptions.showDropdown;
    } else {
      updatedState.addressInfo[
        indexToUpdate
      ].countryNumberOptions.showDropdown =
        !updatedState.addressInfo[indexToUpdate].countryNumberOptions
          .showDropdown;
    }

    setFormState({ ...updatedState });
  };

  const contactNumberBtn = (
    isAddressFieldContact: boolean = false,
    indexToUpdate: number
  ) => {
    let mainValueKey = formState.generalInfo.countryNumberOptions.value;
    if (isAddressFieldContact) {
      mainValueKey =
        formState.addressInfo[indexToUpdate].countryNumberOptions.value;
    }
    if (mainValueKey) {
      const { currencyCode, dial_code } = mainValueKey;
      return (
        <div
          style={{
            padding: '5px 10px',
            borderRadius: '4px',
            backgroundColor: 'rgb(235, 235, 235)',
            border: '1px solid rgb(235, 235, 235)',
            cursor: 'pointer'
          }}
          className="mr-s"
          onClick={() => {
            showHidePhoneNumberOptions(isAddressFieldContact, indexToUpdate);
          }}
        >
          {/* <div className="parent-width"> */}
          <div
            className="currency-select-trigger"
            style={{ margin: 0, paddingTop: '1px' }}
          >
            <div
              className="row align-items-center justify-content-center currency-dropdown-flag"
              style={{ margin: 0 }}
            >
              <span
                className={`currency-dropdown-selector-flag flag ${currencyCode}`}
                style={{ margin: 0 }}
              ></span>
            </div>
            <div className="currency-dropdown-code">{dial_code}</div>
          </div>
          {/* </div> */}
        </div>
      );
    }
    return <div></div>;
  };

  const getEmailIdsInArray = (emails: string) => {
    if (!Utility.isEmptyObject(emails)) {
      return emails.split(',');
    } else {
      return [];
    }
  };

  const generalInfoForm = () => {
    return (
      <div className="column parent-width">
        {sdkInput(
          'Name',
          formState.generalInfo.name.value,
          '',
          CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
          CONTACT_FIELD_KEY_NAMES.NAME,
          'mb-s',
          formState.generalInfo.name.isMandatory,
          formState.generalInfo.name.hasError,
          formState.generalInfo.name.errorMsg
        )}
        {sdkInput(
          'Email Address',
          formState.generalInfo.email.value,
          '',
          CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
          CONTACT_FIELD_KEY_NAMES.EMAIL,
          'mt-l mb-s',
          formState.generalInfo.email.isMandatory,
          formState.generalInfo.email.hasError,
          formState.generalInfo.email.errorMsg
        )}
        <div className="mt-l mb-s parent-width row row-responsive justify-content-between align-items-center">
          <div className="mr-l" style={{ width: 191 }}>
            <DKLabel text="Alternative Emails" className=" fw-s" />
          </div>
          <div className="w-full flex-1">
            <MultiEmailInputField
              onValueChange={(value: string[], hasError: boolean) => {
                setAlternativeEmails(value.toString());
              }}
              value={getEmailIdsInArray(alternativeEmails)}
              placeHolder=""
              isMandatory={false}
            />
          </div>
        </div>
        <div className="parent-width">
          <div className="flex row-responsive justify-content-between align-items-center mt-l">
            <div style={{}}>
              <DKLabel
                text="Phone Number"
                className="row-auto-width mb-xs dkinput-header-section mt-r mr-l"
              />
            </div>
            <div
              className="row parent-width parent-height "
              style={{ width: '100%' }}
            >
              <div style={{}}>
                <div className="position-relative">
                  {contactNumberBtn(false, 0)}
                  {formState.generalInfo.countryNumberOptions.showDropdown && (
                    <DKListPicker2
                      title="Phone Number"
                      data={formState.generalInfo.countryNumberOptions.options}
                      style={{
                        width: 280
                      }}
                      allowSearch={true}
                      searchableKey="name"
                      className="position-absolute z-index-3 shadow-m"
                      onSelect={(index: number, value: any) => {
                        changePhoneNumberType(value);
                      }}
                      onClose={() => {
                        showHidePhoneNumberOptions(false, 0);
                      }}
                      renderer={(index: number, obj: any) => {
                        return (
                          <div
                            className="flex flex-row w-full justify-content-between"
                            style={{ width: '15rem' }}
                          >
                            {phoneNumberOption(obj)}
                          </div>
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              {sdkInput(
                '',
                formState.generalInfo.contactNumber.value,
                '',
                CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
                CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER,
                '',
                formState.generalInfo.contactNumber.isMandatory,
                formState.generalInfo.contactNumber.hasError,
                formState.generalInfo.contactNumber.errorMsg,
                INPUT_VIEW_DIRECTION.HORIZONTAL,
                false,
                INPUT_TYPE.NUMBER
              )}
            </div>
          </div>
        </div>
        {!formState.generalInfo.contactUEN.hidden &&
          sdkInput(
            'Contact UEN',
            formState.generalInfo.contactUEN.value,
            '',
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.CONTACT_UEN,
            'mt-l',
            formState.generalInfo.contactUEN.isMandatory,
            formState.generalInfo.contactUEN.hasError,
            formState.generalInfo.contactUEN.errorMsg
          )}
        {!formState.generalInfo.peppolID.hidden &&
          sdkInput(
            'Peppol Id',
            formState.generalInfo.peppolID.value,
            '',
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.PEPPOL_ID,
            'mt-l',
            formState.generalInfo.peppolID.isMandatory,
            formState.generalInfo.peppolID.hasError,
            formState.generalInfo.peppolID.errorMsg
          )}
        <div className="mt-l parent-width row flex-wrap justify-content-between align-items-start">
          {contactMode === DOCUMENT_MODE.EDIT ||
          contactMode === DOCUMENT_MODE.VIEW ? (
            <div className="parent-width position-relative">
              <div className="mt-r mb-r">
                {ComponentManager.getReadOnlyDKInput(
                  t(
                    `PRODUCTS.DIALOG.PRODUCT.GENERAL_INFO.TYPE.AUTO_NUMBERING_FORMAT`
                  ),
                  formState.generalInfo.numberingFormat.defaultValue
                )}
              </div>
            </div>
          ) : (
            <CustomNumberFormatInput
              module={CUSTOM_NUMBER_INPUT_MODULES.CONTACT}
              selectedFormat={selectedFormat}
              extraClass={'mt-s top-10 right-1'}
              isRow={true}
              autoNumberingFormat={autoNumberingFormat}
            />
          )}
        </div>
        {formState.activeMultiCurrencyList.length > 0 && (
          <div className="mt-xl parent-width row row-responsive justify-content-between align-items-center">
            <div className="mr-l mobile-mb-xs" style={{ marginRight: '155px' }}>
              <DKLabel text="Currency" className=" fw-s" />
            </div>
            <CurrencyDropdown
              getInfo={grabCurrencyInfo}
              currencyCode={
                formState.generalInfo.currency.value
                  ? formState.generalInfo.currency.value.toString()
                  : 'INR'
              }
              width={'100%'}
              options={formState.activeMultiCurrencyList}
              isDisabled={!tenantInfo.multicurrencyEnabled}
            />
          </div>
        )}
        {!formState.generalInfo.vatRegistrationUae.hidden &&
          sdkInput(
            'Vat Registration No.',
            formState.generalInfo.vatRegistrationUae.value,
            '',
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.VAT_REGISTRATION_UAE,
            'mt-l',
            formState.generalInfo.vatRegistrationUae.isMandatory,
            formState.generalInfo.vatRegistrationUae.hasError,
            formState.generalInfo.vatRegistrationUae.errorMsg
          )}
        {!formState.additonalInfo.taxExempted.hidden && (
          <DKCheckMark
            title="Tax Exemption"
            className="mt-l mr-xxl"
            isSelected={formState.additonalInfo.taxExempted.isSelected}
            onClick={(value: any) =>
              formToggleChanged(
                !formState.additonalInfo.taxExempted.isSelected,
                CONTACT_FIELD_KEY_NAMES.TAX_EXEMPT
              )
            }
          />
        )}
        {!formState.generalInfo.taxTreatmentUae.hidden &&
          sdkDropdown(
            'Tax Treatment',
            formState.generalInfo.taxTreatmentUae.value.label,
            formState.generalInfo.taxTreatmentUae.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.TAX_TREATMENT_UAE,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.taxTreatmentUae.isMandatory,
            formState.generalInfo.taxTreatmentUae.hasError,
            formState.generalInfo.taxTreatmentUae.errorMsg,
            false,
            'mt-l'
          )}
        {!formState.generalInfo.intercompanyType.hidden &&
          sdkDropdown(
            'Intercompany Type',
            formState.generalInfo.intercompanyType.value.label,
            formState.generalInfo.intercompanyType.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.INTERCOMPANY_TYPE,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.intercompanyType.isMandatory,
            formState.generalInfo.intercompanyType.hasError,
            formState.generalInfo.intercompanyType.errorMsg,
            false,
            'mt-l'
          )}
        {!formState.additonalInfo.is1099Eligible.hidden && (
          <DKCheckMark
            title="1099 Eligible"
            className="mt-l mr-xxl"
            isSelected={formState.additonalInfo.is1099Eligible.isSelected}
            onClick={(value: any) =>
              formToggleChanged(
                !formState.additonalInfo.is1099Eligible.isSelected,
                CONTACT_FIELD_KEY_NAMES.ELIGIBLE
              )
            }
          />
        )}
        {
          // UK compliance Business Reg Number, VAT id Number, Default tax sales
          //and purchase fields
        }
        {!formState.generalInfo.ukBusinessRegistrationNo.hidden &&
          sdkInput(
            'Business Registered No',
            formState.generalInfo.ukBusinessRegistrationNo.value,
            '',
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.UK_BUSINESS_REGISTRATION_NUMBER,
            'mt-l',
            formState.generalInfo.ukBusinessRegistrationNo.isMandatory,
            formState.generalInfo.ukBusinessRegistrationNo.hasError,
            formState.generalInfo.ukBusinessRegistrationNo.errorMsg
          )}
        {!formState.generalInfo.ukVatIdentificationNo.hidden &&
          sdkInput(
            'VAT Identification Number',
            formState.generalInfo.ukVatIdentificationNo.value,
            '',
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.UK_VAT_IDENTIFICATION_NUMBER,
            'mt-l',
            formState.generalInfo.ukVatIdentificationNo.isMandatory,
            formState.generalInfo.ukVatIdentificationNo.hasError,
            formState.generalInfo.ukVatIdentificationNo.errorMsg
          )}
        {!formState.generalInfo.ukDefaultSalesTaxRate.hidden &&
          sdkDropdown(
            'Default Sales Tax Rate',
            formState.generalInfo.ukDefaultSalesTaxRate.value.label,
            formState.generalInfo.ukDefaultSalesTaxRate.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_SALES_TAX_RATE,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.ukDefaultSalesTaxRate.isMandatory,
            formState.generalInfo.ukDefaultSalesTaxRate.hasError,
            formState.generalInfo.ukDefaultSalesTaxRate.errorMsg,
            false,
            'mt-l',
            '',
            0,
            '',
            ''
          )}
        {!formState.generalInfo.ukDefaultPurchaseTaxRate.hidden &&
          sdkDropdown(
            'Default Purchase Tax Rate',
            formState.generalInfo.ukDefaultPurchaseTaxRate.value.label,
            formState.generalInfo.ukDefaultPurchaseTaxRate.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_PURCHASE_TAX_RATE,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.ukDefaultPurchaseTaxRate.isMandatory,
            formState.generalInfo.ukDefaultPurchaseTaxRate.hasError,
            formState.generalInfo.ukDefaultPurchaseTaxRate.errorMsg,
            false,
            'mt-l',
            '',
            0,
            '',
            ''
          )}
        {!formState.generalInfo.salesTaxCodeIsrael.hidden &&
          sdkDropdown(
            'Default Sales Tax Rate',
            formState.generalInfo.salesTaxCodeIsrael.value.label,
            formState.generalInfo.salesTaxCodeIsrael.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.SALES_TAX_RATE_ISRAEL,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.salesTaxCodeIsrael.isMandatory,
            formState.generalInfo.salesTaxCodeIsrael.hasError,
            formState.generalInfo.salesTaxCodeIsrael.errorMsg,
            false,
            'mt-l',
            '',
            0,
            '',
            ''
          )}
        {!formState.generalInfo.purchaseTaxCodeIsrael.hidden &&
          sdkDropdown(
            'Default Purchase Tax Rate',
            formState.generalInfo.purchaseTaxCodeIsrael.value.label,
            formState.generalInfo.purchaseTaxCodeIsrael.options,
            CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
            CONTACT_FIELD_KEY_NAMES.PURCHASE_TAX_RATE_ISRAEL,
            INPUT_VIEW_DIRECTION.HORIZONTAL,
            formState.generalInfo.purchaseTaxCodeIsrael.isMandatory,
            formState.generalInfo.purchaseTaxCodeIsrael.hasError,
            formState.generalInfo.purchaseTaxCodeIsrael.errorMsg,
            false,
            'mt-l',
            '',
            0,
            '',
            ''
          )}
        {isSalesPersonEnable && (
          <div className="row mt-l">
            <div>
              <DKLabel
                className="mb-s mt-0 mr-3"
                style={{ minWidth: '12rem' }}
                text={'Agent'}
              />
            </div>
            <div
              className="row ml-2x bg-dk-input border-radius-s position-relative"
              style={{
                minHeight: 35,
                height: 'auto',
                minWidth: '50%',
                border: '1px solid rgb(235, 235, 235)'
              }}
              onClick={() => {
                setShowAgentDropDown(!showAgentDropDown);
              }}
            >
              {!Utility.isEmpty(formState.generalInfo.agent.value) &&
                formState.generalInfo.agent.value.map(
                  (item: any, index: any) => {
                    return (
                      formState?.generalInfo?.agent?.options && (
                        <div className="m-xs bg-white p-h-s border-radius-s shadow-m">
                          {item?.label}
                        </div>
                      )
                    );
                  }
                )}
              {showAgentDropDown && (
                <DKListPicker2
                  style={{ top: 35, left: -18, width: '100%' }}
                  title=""
                  className="position-absolute z-index-3 shadow-m ml-l"
                  allowSearch={true}
                  searchableKey="label"
                  multiSelect={true}
                  checkMarkColor="bg-blue"
                  selectedIndexes={agentSelectedIndexes}
                  data={formState.generalInfo.agent.options}
                  renderer={(index: any, obj: any) => {
                    return <DKLabel text={`${obj.label}`} />;
                  }}
                  onSelect={(index: any, obj: any) => {
                    var selectedIndexes: number[] = agentSelectedIndexes;
                    let agentSelectedIndexesCopy: any = [];
                    if (selectedIndexes.includes(index)) {
                      let indexToDelete = selectedIndexes.indexOf(index);
                      selectedIndexes.splice(indexToDelete, 1);
                      agentSelectedIndexesCopy = selectedIndexes;
                    } else {
                      agentSelectedIndexesCopy = [
                        ...selectedIndexes,
                        ...[index]
                      ];
                    }
                    let arr: any = [];
                    agentSelectedIndexesCopy.forEach(
                      (item: any, index: any) => {
                        if (formState.generalInfo.agent.options)
                          arr.push(formState.generalInfo.agent.options[item]);
                      }
                    );
                    formState.generalInfo.agent.value = arr;
                    setFormState({ ...formState });
                    setAgentSelectedIndexes(agentSelectedIndexesCopy);
                    setShowAgentDropDown(false);
                  }}
                  onClear={() => {
                    setAgentSelectedIndexes([]);
                    setShowAgentDropDown(false);
                    formState.generalInfo.agent.value = [];
                    setFormState({ ...formState });
                  }}
                  onClose={() => {}}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const addressInfoForm = () => {
    return (
      <div className="column parent-width">
        <div className="w-max pb-r">
          <DKButton
            title={'+ Add Another Address'}
            className={'fw-m text-blue'}
            onClick={addNewAddress}
          />
        </div>
        {formState.addressInfo.map((addressData, index) => {
          return (
            <div className="parent-width bg-gray0 border-m border-radius-s p-m mb-xl">
              <div className="row justify-content-between">
                <DKLabel
                  text={`Address ${
                    formState.addressInfo.length > 1
                      ? formState.addressInfo.length - index
                      : ''
                  }`}
                  className="fw-b"
                />
                {formState.addressInfo.length > 1 && (
                  <div onClick={() => removeAddress(index)}>
                    <DKIcon
                      src={ic_delete}
                      className="ic-s cursor-hand opacity-5"
                    />
                  </div>
                )}
              </div>
              <div
                className="row row-responsive flex-wrap justify-content-between mt-s"
                style={{ gap: '2%' }}
              >
                {/* contact name */}
                <div style={{ width: '100%' }}>
                  <DKInput
                    title="Contact Name"
                    canValidate={addressData.contactName.isMandatory}
                    errorMessage={addressData.contactName.errorMsg}
                    validator={(value: string) => {
                      return !addressData.contactName.hasError;
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    required={addressData.contactName.isMandatory}
                    value={addressData.contactName.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formFieldChanged(
                        value,
                        CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                        CONTACT_FIELD_KEY_NAMES.CONTACT_NAME,
                        index
                      )
                    }
                  />
                </div>
                {/* street -> Address1 */}
                <div className="web-width-48 mt-l">
                  <DKInput
                    title="Address 1"
                    canValidate={addressData.address.isMandatory}
                    errorMessage={addressData.address.errorMsg}
                    validator={(value: string) => {
                      return !addressData.address.hasError;
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    required={addressData.address.isMandatory}
                    value={addressData.address.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formFieldChanged(
                        value,
                        CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                        CONTACT_FIELD_KEY_NAMES.ADDRESS,
                        index
                      )
                    }
                  />
                </div>
                {/* address 2 */}
                <div className="web-width-48 mt-l">
                  <DKInput
                    title="Address 2"
                    canValidate={addressData.address2.isMandatory}
                    errorMessage={addressData.address2.errorMsg}
                    validator={(value: string) => {
                      return !addressData.address2.hasError;
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    required={addressData.address2.isMandatory}
                    value={addressData.address2.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formFieldChanged(
                        value,
                        CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                        CONTACT_FIELD_KEY_NAMES.ADDRESS2,
                        index
                      )
                    }
                  />
                </div>
                {/* city */}
                <div className="web-width-48 mt-l">
                  {addressData.city.options &&
                  addressData.city.options.length > 0 ? (
                    sdkDropdown(
                      'City',
                      addressData.city.value.label,
                      addressData.city.options,
                      CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                      CONTACT_FIELD_KEY_NAMES.CITY,
                      INPUT_VIEW_DIRECTION.VERTICAL,
                      addressData.city.isMandatory,
                      addressData.city.hasError,
                      addressData.city.errorMsg,
                      true,
                      '',
                      'label',
                      index,
                      'white'
                    )
                  ) : (
                    <DKInput
                      title="City"
                      required={false}
                      value={addressData.city.value.label}
                      placeholder=""
                      valueStyle={{
                        backgroundColor: 'white'
                      }}
                      titleStyle={{
                        color: 'gray'
                      }}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      onChange={(value: string) =>
                        formFieldChanged(
                          value,
                          CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                          CONTACT_FIELD_KEY_NAMES.CITY,
                          index
                        )
                      }
                    />
                  )}
                </div>
                {/* postal code */}
                <div className="web-width-48 mt-l">
                  <DKInput
                    title="Postal code"
                    canValidate={addressData.postalCode.isMandatory}
                    errorMessage={addressData.postalCode.errorMsg}
                    validator={(value: string) => {
                      return !addressData.postalCode.hasError;
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    required={addressData.postalCode.isMandatory}
                    value={addressData.postalCode.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formFieldChanged(
                        value,
                        CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                        CONTACT_FIELD_KEY_NAMES.POSTAL_CODE,
                        index
                      )
                    }
                  />
                </div>

                {/* state */}
                <div className="mt-l web-width-48">
                  {addressData.country.value.label === 'India' ||
                  addressData.country.value.label ===
                    'United States of America' ? (
                    <CommonStateListPicker
                      title={'State'}
                      value={addressData.state.value.label}
                      dropdownOptions={addressData.state.options}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      isMandatory={addressData.state.isMandatory}
                      hasError={addressData.state.hasError}
                      errMsg={addressData.state.errorMsg}
                      isSearchable={true}
                      className={''}
                      searchableKey={'searchableKey'}
                      index={index}
                      bgColor={'white'}
                      onChange={(value: any) => {
                        formDropdownChanged(
                          value,
                          CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                          CONTACT_FIELD_KEY_NAMES.STATE,
                          index
                        );
                      }}
                    />
                  ) : (
                    <DKInput
                      title="State"
                      canValidate={addressData.state.hasError || false}
                      required={addressData.state.isMandatory || false}
                      value={addressData.state.value.label}
                      placeholder=""
                      valueStyle={{
                        backgroundColor: 'white'
                      }}
                      titleStyle={{
                        color: 'gray'
                      }}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      onChange={(value: string) =>
                        formFieldChanged(
                          value,
                          CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                          CONTACT_FIELD_KEY_NAMES.STATE,
                          index
                        )
                      }
                    />
                  )}
                </div>
                {/* country */}
                <div className="mt-l web-width-48">
                  {sdkDropdown(
                    'Country',
                    addressData.country.value.label,
                    addressData.country.options,
                    CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                    CONTACT_FIELD_KEY_NAMES.COUNTRY,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    addressData.country.isMandatory,
                    addressData.country.hasError,
                    addressData.country.errorMsg,
                    true,
                    '',
                    'label',
                    index,
                    'white'
                  )}
                </div>

                {/* phone number */}
                <div className="web-width-48 mt-l">
                  <div className="column">
                    <div style={{}}>
                      <DKLabel
                        text="Phone Number"
                        className="row-auto-width mb-xs dkinput-header-section mt-r mr-l text-gray"
                      />
                    </div>
                    <div
                      className="row parent-width parent-height "
                      style={{ width: '100%' }}
                    >
                      <div style={{}}>
                        <div className="position-relative">
                          {contactNumberBtn(true, index)}
                          {formState.addressInfo[index].countryNumberOptions
                            .showDropdown && (
                            <DKListPicker2
                              title="Phone Number"
                              data={
                                formState.addressInfo[index]
                                  .countryNumberOptions.options
                              }
                              style={{
                                width: 280
                              }}
                              allowSearch={true}
                              searchableKey="name"
                              className="position-absolute z-index-3 shadow-m"
                              onSelect={(indexSelect: number, value: any) => {
                                changePhoneNumberType(value, true, index);
                              }}
                              onClose={() => {
                                showHidePhoneNumberOptions(true, index);
                              }}
                              renderer={(index: number, obj: any) => {
                                return (
                                  <div
                                    className="flex flex-row w-full justify-content-between"
                                    style={{ width: '15rem' }}
                                  >
                                    {phoneNumberOption(obj)}
                                  </div>
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <DKInput
                        title=""
                        canValidate={
                          formState.addressInfo[index].contactNumber.isMandatory
                        }
                        errorMessage={
                          formState.addressInfo[index].contactNumber.errorMsg
                        }
                        validator={(value: string) => {
                          return !formState.addressInfo[index].contactNumber
                            .hasError;
                        }}
                        valueStyle={{
                          backgroundColor: 'white'
                        }}
                        titleStyle={{
                          color: 'gray'
                        }}
                        required={
                          formState.addressInfo[index].contactNumber.isMandatory
                        }
                        value={formState.addressInfo[index].contactNumber.value}
                        placeholder=""
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        onChange={(value: string) =>
                          formFieldChanged(
                            value,
                            CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                            CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER,
                            index
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className="web-width-48 mt-l">
                  <DKInput
                    title="Email Address"
                    canValidate={formState.addressInfo[index].email.hasError}
                    errorMessage={formState.addressInfo[index].email.errorMsg}
                    validator={(value: string) => {
                      return !formState.addressInfo[index].email.hasError;
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    required={false}
                    value={formState.addressInfo[index].email.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formFieldChanged(
                        value,
                        CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO,
                        CONTACT_FIELD_KEY_NAMES.EMAIL,
                        index
                      )
                    }
                  />
                </div>

                {/* address custom fields */}
                <div className="column parent-width mb-l mt-s">
                  <CustomFieldsHolder
                    documentMode={props.contactMode}
                    moduleName={MODULES_NAME.CONTACT_ADDRESS}
                    customFieldsList={
                      formState.addressInfo[index]?.customFields
                        ? formState.addressInfo[index]?.customFields
                        : tmpCustomField
                        ? tmpCustomField
                        : []
                    }
                    columnConfig={columnConfig}
                    columnConfigTableId={columnConfigTableId}
                    onUpdate={(list) => addressCustomFieldUpdated(list, index)}
                    hideAddfieldButton={true}
                  />
                </div>
              </div>
              <div>
                {formState.addressInfo.length > 0 && (
                  <div className="row items-center p-m mt-r">
                    <div className="row">
                      <DKRadioButton
                        title="Default shipping address"
                        isSelected={
                          formState.activeShippingAddressIndex === index
                        }
                        color="bg-app"
                        className="ml-m"
                        onClick={() => {
                          setDefaultAddress(
                            index,
                            BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
                          );
                        }}
                      />
                    </div>
                    <div className="row">
                      <DKRadioButton
                        title="Default billing address"
                        isSelected={
                          formState.activeBillingAddressIndex === index
                        }
                        color="bg-app"
                        className="ml-m"
                        onClick={() => {
                          setDefaultAddress(
                            index,
                            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const complianceInfoForm = () => {
    return (
      <div className="column parent-width">
        <div className="row row-responsive flex-wrap justify-content-between">
          {formState.accounting.customerTaxType.options &&
            !formState.accounting.customerTaxType.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Customer Tax Type',
                  formState.accounting.customerTaxType.value.label,
                  formState.accounting.customerTaxType.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.CUSTOMER_TAX_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.customerTaxType.isMandatory,
                  formState.accounting.customerTaxType.hasError,
                  formState.accounting.customerTaxType.errorMsg
                )}
              </div>
            )}
          {formState.accounting.vendorTaxType.options &&
            !formState.accounting.vendorTaxType.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Vendor Tax Type',
                  formState.accounting.vendorTaxType.value.label,
                  formState.accounting.vendorTaxType.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.VENDOR_TAX_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.vendorTaxType.isMandatory,
                  formState.accounting.vendorTaxType.hasError,
                  formState.accounting.vendorTaxType.errorMsg
                )}
              </div>
            )}
          {formState.accounting.gstTreatment.options &&
            !formState.accounting.gstTreatment.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'GST Treatment',
                  formState.accounting.gstTreatment.value.label,
                  formState.accounting.gstTreatment.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.GST_TREATMENT,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.gstTreatment.isMandatory,
                  formState.accounting.gstTreatment.hasError,
                  formState.accounting.gstTreatment.errorMsg
                )}
              </div>
            )}

          {!formState.accounting.gstIn.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                'GSTIN',
                formState.accounting.gstIn.value,
                'GSTIN',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.GST_IN,
                '',
                formState.accounting.gstIn.isMandatory,
                formState.accounting.gstIn.hasError,
                formState.accounting.gstIn.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {formState.accounting.customerType.options &&
            !formState.accounting.customerType.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Customer Type',
                  formState.accounting.customerType.value.label,
                  formState.accounting.customerType.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.customerType.isMandatory,
                  formState.accounting.customerType.hasError,
                  formState.accounting.customerType.errorMsg
                )}
              </div>
            )}

          {formState.accounting.vendorType.options &&
            !formState.accounting.vendorType.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Vendor Type',
                  formState.accounting.vendorType.value.label,
                  formState.accounting.vendorType.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.vendorType.isMandatory,
                  formState.accounting.vendorType.hasError,
                  formState.accounting.vendorType.errorMsg
                )}
              </div>
            )}

          {formState.accounting.customerTypeMalaysia.options &&
            !formState.accounting.customerTypeMalaysia.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Customer Type',
                  formState.accounting.customerTypeMalaysia.value.label,
                  formState.accounting.customerTypeMalaysia.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE_MALAYSIA,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.customerTypeMalaysia.isMandatory,
                  formState.accounting.customerTypeMalaysia.hasError,
                  formState.accounting.customerTypeMalaysia.errorMsg
                )}
              </div>
            )}

          {formState.accounting.vendorTypeMalaysia.options &&
            !formState.accounting.vendorTypeMalaysia.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Vendor Type',
                  formState.accounting.vendorTypeMalaysia.value?.label,
                  formState.accounting.vendorTypeMalaysia.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE_MALAYSIA,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.vendorTypeMalaysia.isMandatory,
                  formState.accounting.vendorTypeMalaysia.hasError,
                  formState.accounting.vendorTypeMalaysia.errorMsg
                )}
              </div>
            )}

          {!formState.generalInfo.panNumber.hidden && (
            <div className="web-width-48" style={{ height: 85 }}>
              {sdkInput(
                'PAN/TAN Number',
                formState.generalInfo.panNumber.value,
                'PAN/TAN Number',
                CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
                CONTACT_FIELD_KEY_NAMES.PAN_NUMBER,
                '',
                formState.generalInfo.panNumber.isMandatory,
                formState.generalInfo.panNumber.hasError,
                formState.generalInfo.panNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.npwpNumber.hidden && (
            <div className="web-width-48" style={{ height: '85px' }}>
              {sdkInput(
                'PAN/TAN Number',
                formState.accounting.npwpNumber.value,
                'PAN/TAN Number',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.NPWP_NUMBER,
                '',
                formState.accounting.npwpNumber.isMandatory,
                formState.accounting.npwpNumber.hasError,
                formState.accounting.npwpNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.nikNumber.hidden && (
            <div className="web-width-48" style={{ height: '85px' }}>
              {sdkInput(
                'NIK Number',
                formState.accounting.nikNumber.value,
                'NIK Number',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.NIK_NUMBER,
                '',
                formState.accounting.nikNumber.isMandatory,
                formState.accounting.nikNumber.hasError,
                formState.accounting.nikNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.serviceTaxRegistrationNumber.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                'Service Tax Registration No.',
                formState.accounting.serviceTaxRegistrationNumber.value,
                'Service Tax Registration No.',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.SERVICE_TAX_REGISTRATION_NUMBER,
                '',
                formState.accounting.serviceTaxRegistrationNumber.isMandatory,
                formState.accounting.serviceTaxRegistrationNumber.hasError,
                formState.accounting.serviceTaxRegistrationNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.salesTaxRegistrationNumber.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                'Sales Tax Registration No.',
                formState.accounting.salesTaxRegistrationNumber.value,
                'Sales Tax Registration No.',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.SALES_TAX_REGISTRATION_NUMBER,
                '',
                formState.accounting.salesTaxRegistrationNumber.isMandatory,
                formState.accounting.salesTaxRegistrationNumber.hasError,
                formState.accounting.salesTaxRegistrationNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.businessRegistrationNumber.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                'Business Registration No.',
                formState.accounting.businessRegistrationNumber.value,
                'Business Registration No.',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.BUSINESS_RESGISTRATION_NUMBER,
                '',
                formState.accounting.businessRegistrationNumber.isMandatory,
                formState.accounting.businessRegistrationNumber.hasError,
                formState.accounting.businessRegistrationNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.generalInfo.taxNumber.hidden && (
            <div className="web-width-48" style={{ height: 85 }}>
              {sdkInput(
                'Tax Registration Number',
                formState.generalInfo.taxNumber.value,
                'Tax Registration Number',
                CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO,
                CONTACT_FIELD_KEY_NAMES.TAX_NUMBER,
                '',
                formState.generalInfo.taxNumber.isMandatory,
                formState.generalInfo.taxNumber.hasError,
                formState.generalInfo.taxNumber.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {!formState.accounting.vendorTAN.hidden && (
            <div className="web-width-48" style={{ height: 85 }}>
              {sdkInput(
                'Vendor TAN',
                formState.accounting.vendorTAN.value,
                'Vendor TAN',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.VENDER_TAN,
                '',
                formState.accounting.vendorTAN.isMandatory,
                formState.accounting.vendorTAN.hasError,
                formState.accounting.vendorTAN.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Payment Term',
              formState.accounting.paymentTerm.value.label,
              formState.accounting.paymentTerm.options,
              CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
              CONTACT_FIELD_KEY_NAMES.PAYMENT_TERM,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.accounting.paymentTerm.isMandatory,
              formState.accounting.paymentTerm.hasError,
              formState.accounting.paymentTerm.errorMsg,
              true
            )}
          </div>

          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Payable Account',
              formState.accounting.payableAccount.value.label,
              formState.accounting.payableAccount.options,
              CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
              CONTACT_FIELD_KEY_NAMES.PAYABLE_ACCOUNT,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.accounting.payableAccount.isMandatory,
              formState.accounting.payableAccount.hasError,
              formState.accounting.payableAccount.errorMsg,
              true
            )}

            {formState?.accounting?.payableAccount?.value?.value !==
              defaultAccountValues?.financeSettings?.payableAccountCode &&
              futureAccountsApply.payableAccountCodeTouched && (
                <div className="mb-r">
                  <DKCheckMark
                    title="Set as default account"
                    className="mt-s mb-2"
                    isSelected={futureAccountsApply.payableAccountCode}
                    onClick={(value: string) => {
                      setFutureAccountApply({
                        ...futureAccountsApply,
                        payableAccountCode:
                          !futureAccountsApply.payableAccountCode
                      });
                    }}
                  />
                </div>
              )}
          </div>

          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Receivable Account',
              formState.accounting.receiveableAccount.value.label,
              formState.accounting.receiveableAccount.options,
              CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
              CONTACT_FIELD_KEY_NAMES.RECEIVEABLE_ACCOUNT,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.accounting.receiveableAccount.isMandatory,
              formState.accounting.receiveableAccount.hasError,
              formState.accounting.receiveableAccount.errorMsg,
              true
            )}

            {formState?.accounting?.receiveableAccount?.value?.value !==
              defaultAccountValues?.financeSettings?.receivableAccountCode &&
              futureAccountsApply.receivableAccountCodeTouched && (
                <div className="mb-r">
                  <DKCheckMark
                    title="Set as default account"
                    className="mt-s mb-2"
                    isSelected={futureAccountsApply.receivableAccountCode}
                    onClick={(value: string) => {
                      setFutureAccountApply({
                        ...futureAccountsApply,
                        receivableAccountCode:
                          !futureAccountsApply.receivableAccountCode
                      });
                    }}
                  />
                </div>
              )}
          </div>

          {formState.accounting.exemptType.options &&
            !formState.accounting.exemptType.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Exempt Type',
                  formState.accounting.exemptType.value.label,
                  formState.accounting.exemptType.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.TAX_EXEMPT,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.exemptType.isMandatory,
                  formState.accounting.exemptType.hasError,
                  formState.accounting.exemptType.errorMsg
                )}
              </div>
            )}

          {!formState.additonalInfo.exemptionCertificateMalaysia.hidden && (
            <DKCheckMark
              title="Exemption Certificate"
              className="mr-xxl"
              isSelected={
                formState.additonalInfo.exemptionCertificateMalaysia.isSelected
              }
              onClick={(value: any) =>
                formToggleChanged(
                  !formState.additonalInfo.exemptionCertificateMalaysia
                    .isSelected,
                  CONTACT_FIELD_KEY_NAMES.EXEMPTION_CERTIFICATE_MALAYSIA
                )
              }
            />
          )}

          {formState.accounting.exemptionCriterialMalaysia.options &&
            !formState.accounting.exemptionCriterialMalaysia.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Exempt Type',
                  formState.accounting.exemptionCriterialMalaysia.value.label,
                  formState.accounting.exemptionCriterialMalaysia.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.EXEMPTION_CRITERIAL_MALAYSIA,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.exemptionCriterialMalaysia.isMandatory,
                  formState.accounting.exemptionCriterialMalaysia.hasError,
                  formState.accounting.exemptionCriterialMalaysia.errorMsg
                )}
              </div>
            )}

          {!formState.accounting.attentionTo.hidden && (
            <div className="web-width-48" style={{ height: 85 }}>
              {sdkInput(
                'Attention To',
                formState.accounting.attentionTo.value,
                'Attention To',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.ATTENTION_TO,
                '',
                formState.accounting.attentionTo.isMandatory,
                formState.accounting.attentionTo.hasError,
                formState.accounting.attentionTo.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}

          {formState.accounting.businessUnit.options &&
            !formState.accounting.businessUnit.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Business Unit',
                  formState.accounting.businessUnit.value.label,
                  formState.accounting.businessUnit.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.BUSINESS_UNIT,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.businessUnit.isMandatory,
                  formState.accounting.businessUnit.hasError,
                  formState.accounting.businessUnit.errorMsg,
                  true
                )}
              </div>
            )}
          {!formState.additonalInfo.contactSingaporeGovt.hidden && (
            <DKCheckMark
              title="Contact of Singapore Govt"
              className="mr-xxl"
              isSelected={
                formState.additonalInfo.contactSingaporeGovt.isSelected
              }
              onClick={(value: string) =>
                formToggleChanged(
                  !formState.additonalInfo.contactSingaporeGovt.isSelected,
                  CONTACT_FIELD_KEY_NAMES.CONTACT_SG_GOVT
                )
              }
            />
          )}
        </div>
        <div className="row row-responsive justify-content-between">
          {!formState.accounting.taxPayerIdIsrael.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                'Tax Payer Id',
                formState.accounting.taxPayerIdIsrael.value,
                'Tax Payer Id',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.TAX_PAYER_ID_ISRAEL,
                '',
                formState.accounting.taxPayerIdIsrael.isMandatory,
                formState.accounting.taxPayerIdIsrael.hasError,
                formState.accounting.taxPayerIdIsrael.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
            </div>
          )}
          {formState.accounting.assessingOfficerNumberIsrael.options &&
            !formState.accounting.assessingOfficerNumberIsrael.hidden && (
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkDropdown(
                  'Assessing officer number',
                  formState.accounting.assessingOfficerNumberIsrael.value
                    ?.label,
                  formState.accounting.assessingOfficerNumberIsrael.options,
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.ASSESSING_OFFICER_NUMBER_ISRAEL,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  formState.accounting.assessingOfficerNumberIsrael.isMandatory,
                  formState.accounting.assessingOfficerNumberIsrael.hasError,
                  formState.accounting.assessingOfficerNumberIsrael.errorMsg,
                  true
                )}
              </div>
            )}
        </div>
        {!formState.accounting.whtApplicableIsrael.hidden && (
          <DKCheckMark
            title="Enable WHT (Witholding Tax)"
            className="mr-xxl"
            isSelected={formState.accounting.whtApplicableIsrael.isSelected}
            onClick={(value: any) =>
              formToggleChanged(
                !formState.accounting.whtApplicableIsrael.isSelected,
                CONTACT_FIELD_KEY_NAMES.WHT_APPLICABLE_ISRAEL
              )
            }
          />
        )}
        {formState.accounting.whtApplicableIsrael.isSelected && (
          <div className="row row-responsive mt-r justify-content-between">
            {formState.accounting.typeOfCompanyIsrael.options &&
              !formState.accounting.withHoldingTaxTypeIsraelId.hidden && (
                <div className="web-width-48 mt-r" style={{ height: 85 }}>
                  {sdkDropdown(
                    'Withholding Tax Type',
                    formState.accounting.withHoldingTaxTypeIsraelId.value
                      ?.label,
                    formState.accounting.withHoldingTaxTypeIsraelId.options,
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_TYPE_ID_ISRAEL,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    formState.accounting.withHoldingTaxTypeIsraelId.isMandatory,
                    formState.accounting.withHoldingTaxTypeIsraelId.hasError,
                    formState.accounting.withHoldingTaxTypeIsraelId.errorMsg,
                    true
                  )}
                </div>
              )}
            {formState.accounting.typeOfCompanyIsrael.options &&
              !formState.accounting.withHoldingTaxPaymentGroupIsraelId
                .hidden && (
                <div
                  className="web-width-48 mt-r"
                  style={{ height: 85, maxHeight: 85 }}
                >
                  {sdkDropdown(
                    'Withholding Tax Payment Group',
                    formState.accounting.withHoldingTaxPaymentGroupIsraelId
                      .value?.label,
                    formState.accounting.withHoldingTaxPaymentGroupIsraelId
                      .options,
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_PAYMENT_GROUP_ID_ISRAEL,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    formState.accounting.withHoldingTaxPaymentGroupIsraelId
                      .isMandatory,
                    formState.accounting.withHoldingTaxPaymentGroupIsraelId
                      .hasError,
                    formState.accounting.withHoldingTaxPaymentGroupIsraelId
                      .errorMsg,
                    true
                  )}
                </div>
              )}
          </div>
        )}
        {formState.accounting.whtApplicableIsrael.isSelected && (
          <div className="row row-responsive mt-r justify-content-between">
            {formState.accounting.typeOfCompanyIsrael.options &&
              !formState.accounting.typeOfCompanyIsrael.hidden && (
                <div className="web-width-48 mt-r" style={{ height: 85 }}>
                  {sdkDropdown(
                    'Type of company',
                    formState.accounting.typeOfCompanyIsrael.value?.label,
                    formState.accounting.typeOfCompanyIsrael.options,
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.TYPE_OF_COMPANY_ISRAEL,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    formState.accounting.typeOfCompanyIsrael.isMandatory,
                    formState.accounting.typeOfCompanyIsrael.hasError,
                    formState.accounting.typeOfCompanyIsrael.errorMsg
                  )}
                </div>
              )}
            {!formState.accounting.taxCertificateValidityDateIsrael.hidden && (
              <div className="web-width-48  mt-r" style={{ height: 85 }}>
                <DKInput
                  className={''}
                  title="Tax Certificates Valid Till Date"
                  value={DateFormatService.getDateFromStr(
                    formState.accounting.taxCertificateValidityDateIsrael.date,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )}
                  type={INPUT_TYPE.DATE}
                  onChange={(value: any) =>
                    formCalendarFieldChanged(
                      value,
                      CONTACT_FIELD_KEY_NAMES.TAX_CERTIFICATE_VALIDITY_DATE
                    )
                  }
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  dateFormat={convertBooksDateFormatToUILibraryFormat(
                    tenantInfo.dateFormat
                  )}
                />
              </div>
            )}
          </div>
        )}
        {formState.accounting.whtApplicableIsrael.isSelected && (
          <div className="row row-responsive mt-r justify-content-between">
            {!formState.accounting.isIncludedTaxReportingAtSourceIsrael
              .hidden && (
              <div className="web-width-48">
                <DKCheckMark
                  title="Included In Tax Report At Source"
                  className="mr-xxl"
                  isSelected={
                    formState.accounting.isIncludedTaxReportingAtSourceIsrael
                      .isSelected
                  }
                  onClick={(value: any) =>
                    formToggleChanged(
                      !formState.accounting.isIncludedTaxReportingAtSourceIsrael
                        .isSelected,
                      CONTACT_FIELD_KEY_NAMES.IS_INCLUDED_TAX_REPORTING_SOURCE_ISRAEL
                    )
                  }
                />
              </div>
            )}
            {!formState.accounting.isSelfInvoicingIsrael.hidden && (
              <div className="web-width-48">
                <DKCheckMark
                  title="Provide Self Invoicing"
                  className="mr-xxl"
                  isSelected={
                    formState.accounting.isSelfInvoicingIsrael.isSelected
                  }
                  onClick={(value: any) =>
                    formToggleChanged(
                      !formState.accounting.isSelfInvoicingIsrael.isSelected,
                      CONTACT_FIELD_KEY_NAMES.IS_SELF_INVOICEING_ISRAEL
                    )
                  }
                />
              </div>
            )}
          </div>
        )}
        {!formState.accounting.withHoldingCertificatesIsrael.hidden && (
          <div className="d-flex justify-content-between parent-width mt-2">
            <DKLabel text="WHT Certificates" className="fw-m mt-2" />
            {ilCertificates.length > 0 && getAddILCertificates('mt-xs')}
          </div>
        )}

        {!formState.accounting.withHoldingCertificatesIsrael.hidden &&
          ilCertificates.length > 0 &&
          getGrid('Certificates', getComponentRow(), getColConfig())}
        {!formState.accounting.withHoldingCertificatesIsrael.hidden &&
          ilCertificates.length === 0 && (
            <div
              className="mt-8 bg-gray1 border border-radius-s row flex-wrap p-r mt-s mb-m overflow-scroll hide-scroll-bar paren-size position-relative"
              style={{ gap: 5, maxHeight: 400 }}
            >
              {
                <div className="column parent-size align-items-center">
                  <DKLabel className="text-gray" text="No Certificate added" />
                  <DKButton
                    className="mt-xs text-blue fw-m text-underline"
                    title={'+ Add Certificate'}
                    style={{ padding: 0 }}
                    onClick={() => {
                      setShowIlCertificatePopup(true);
                    }}
                  />
                </div>
              }
            </div>
          )}
        {showIlCertificatePopup && getAddCertificateView()}

        {!formState.accounting.israelBankDetails.hidden && (
          <div className="d-flex justify-content-between parent-width">
            <DKLabel text="Israeli Bank Details" className="fw-m mt-2" />
            {iLLocalBankDetails.length > 0 && getAddILLocalBank('mt-xs', true)}
          </div>
        )}

        {!formState.accounting.israelBankDetails.hidden &&
          iLLocalBankDetails.length > 0 &&
          getGrid(
            'IL Bank Details',
            getILLocalBankMenu(),
            IL_LOCAL_BANK_COL_CONFIG
          )}
        {!formState.accounting.israelBankDetails.hidden &&
          iLLocalBankDetails.length === 0 && (
            <div
              className="mt-8 bg-gray1 border border-radius-s row flex-wrap p-r mt-s mb-m overflow-scroll hide-scroll-bar paren-size position-relative"
              style={{ gap: 5, maxHeight: 400 }}
            >
              {
                <div className="column parent-size align-items-center">
                  <DKLabel className="text-gray" text="No Bank Details added" />
                  <DKButton
                    className="mt-xs text-blue fw-m text-underline"
                    title={'+ Add Bank Details'}
                    style={{ padding: 0 }}
                    onClick={() => {
                      setShowILLocalBankPopup(true);
                    }}
                  />
                </div>
              }
            </div>
          )}
        {showILLocalBankPopup && getAddILLoaclBankView()}

        {!formState.accounting.israelGlobalBankDetails.hidden && (
          <div className="d-flex justify-content-between parent-width">
            <DKLabel text="Global Bank Details" className="fw-m mt-2" />
            {iLGlobalBankDetails.length > 0 &&
              getAddILLocalBank('mt-xs', false)}
          </div>
        )}

        {!formState.accounting.israelGlobalBankDetails.hidden &&
          iLGlobalBankDetails.length > 0 &&
          getGrid(
            'IL Bank Details',
            getILGlobalBankMenu(),
            IL_GLOBAL_BANK_COL_CONFIG
          )}
        {!formState.accounting.israelGlobalBankDetails.hidden &&
          iLGlobalBankDetails.length === 0 && (
            <div
              className="mt-8 bg-gray1 border border-radius-s row flex-wrap p-r mt-s mb-m overflow-scroll hide-scroll-bar paren-size position-relative"
              style={{ gap: 5, maxHeight: 400 }}
            >
              {
                <div className="column parent-size align-items-center">
                  <DKLabel className="text-gray" text="No Bank Details added" />
                  <DKButton
                    className="mt-xs text-blue fw-m text-underline"
                    title={'+ Add Global Bank Details'}
                    style={{ padding: 0 }}
                    onClick={() => {
                      setShowILGlobalBankPopup(true);
                    }}
                  />
                </div>
              }
            </div>
          )}
        {showILGlobalBankPopup && getAddILGlobalBankView()}
        <div className="row row-responsive mt-r">
          {!formState.additonalInfo.resident.hidden && (
            <DKCheckMark
              title="Resident"
              className="mr-xxl"
              isSelected={formState.additonalInfo.resident.isSelected}
              onClick={(value: any) =>
                formToggleChanged(
                  !formState.additonalInfo.resident.isSelected,
                  CONTACT_FIELD_KEY_NAMES.RESIDENT
                )
              }
            />
          )}
          {!formState.additonalInfo.resident.hidden && (
            <DKCheckMark
              title="Non Resident"
              className="mr-xxl"
              isSelected={!formState.additonalInfo.resident.isSelected}
              onClick={(value: any) =>
                formToggleChanged(
                  !formState.additonalInfo.resident.isSelected,
                  CONTACT_FIELD_KEY_NAMES.RESIDENT
                )
              }
            />
          )}
          {!formState.additonalInfo.isTDS.hidden && (
            <DKCheckMark
              title="Is TDS applicable?"
              className="mr-xxl mobile-mt-m"
              isSelected={formState.additonalInfo.isTDS.isSelected}
              onClick={(value: any) =>
                formToggleChanged(
                  !formState.additonalInfo.isTDS.isSelected,
                  CONTACT_FIELD_KEY_NAMES.IS_TDS_APPLICABLE
                )
              }
            />
          )}
        </div>

        {formState.additonalInfo.isTDS.isSelected && (
          <div className="column parent-width mt-xxl pb-l">
            <DKLabel text="TDS Details" className="mb-m fw-m" />
            <div className="row flex-wrap justify-content-between">
              <div
                style={{
                  height: formState.accounting.vendorTAN.hasError ? '90px' : ''
                }}
                className={
                  formState.accounting.vendorTAN.hasError
                    ? 'web-width-48 mb-m'
                    : 'web-width-48 mb-l'
                }
              >
                {sdkInput(
                  'Vendor TAN',
                  formState.accounting.vendorTAN.value,
                  'Vendor TAN',
                  CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                  CONTACT_FIELD_KEY_NAMES.VENDER_TAN,
                  '',
                  formState.accounting.vendorTAN.isMandatory,
                  formState.accounting.vendorTAN.hasError,
                  formState.accounting.vendorTAN.errorMsg,
                  INPUT_VIEW_DIRECTION.VERTICAL
                )}
              </div>

              {formState.accounting.deducteeType.options && (
                <div
                  className={
                    formState.accounting.vendorTAN.hasError
                      ? 'mb-xxl web-width-48'
                      : 'mb-l web-width-48'
                  }
                >
                  {sdkDropdown(
                    'Deductee Type',
                    formState.accounting.deducteeType.value.label,
                    formState.accounting.deducteeType.options,
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.DEDUCTEE_TYPE,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    formState.accounting.deducteeType.isMandatory,
                    formState.accounting.deducteeType.hasError,
                    formState.accounting.deducteeType.errorMsg
                  )}
                </div>
              )}

              <DKCheckMark
                title="Consider Exemption Limit"
                className="mr-xxl mb-l"
                isSelected={
                  formState.additonalInfo.considerExemptLimit.isSelected
                }
                onClick={(value: string) =>
                  formToggleChanged(
                    !formState.additonalInfo.considerExemptLimit.isSelected,
                    CONTACT_FIELD_KEY_NAMES.EXEMPTION_LIMIT
                  )
                }
              />

              <DKCheckMark
                title="Non Deduction or Lower Deduction Applicable"
                className="mb-l"
                isSelected={
                  formState.additonalInfo.lowerDeductionLimit.isSelected
                }
                onClick={(value: string) =>
                  formToggleChanged(
                    !formState.additonalInfo.lowerDeductionLimit.isSelected,
                    CONTACT_FIELD_KEY_NAMES.LOWER_DEDUCTION_APPLICABLE
                  )
                }
              />

              {!formState.accounting.tdsRate.hidden && (
                <div className="web-width-48" style={{ height: '90px' }}>
                  {sdkInput(
                    'Certificate No',
                    formState.accounting.certificateNumber.value,
                    'Certificate No./ Declaration Reference No.',
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.CERTIFICATE_NUMBER,
                    '',
                    formState.accounting.certificateNumber.isMandatory,
                    formState.accounting.certificateNumber.hasError,
                    formState.accounting.certificateNumber.errorMsg,
                    INPUT_VIEW_DIRECTION.VERTICAL
                  )}
                </div>
              )}
              {!formState.accounting.tdsRate.hidden && (
                <div className="web-width-48" style={{ height: '90px' }}>
                  {sdkInput(
                    'TDS Rate Applicable',
                    formState.accounting.tdsRate.value,
                    'TDS Rate Applicable',
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.TDS_RATE,
                    '',
                    formState.accounting.tdsRate.isMandatory,
                    formState.accounting.tdsRate.hasError,
                    formState.accounting.tdsRate.errorMsg,
                    INPUT_VIEW_DIRECTION.VERTICAL
                  )}
                </div>
              )}

              {!formState.accounting.higherRate.hidden && (
                <div className="mb-m web-width-48">
                  {sdkInput(
                    'Higher Rate',
                    formState.accounting.higherRate.value,
                    'Higher Rate',
                    CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                    CONTACT_FIELD_KEY_NAMES.HIGHER_RATE,
                    '',
                    formState.accounting.higherRate.isMandatory,
                    formState.accounting.higherRate.hasError,
                    formState.accounting.higherRate.errorMsg,
                    INPUT_VIEW_DIRECTION.VERTICAL
                  )}
                </div>
              )}

              {!formState.additonalInfo.fromDate.hidden && (
                <div className="web-width-48">
                  <DKInput
                    className={''}
                    title="From Date"
                    value={DateFormatService.getDateFromStr(
                      formState.additonalInfo.fromDate.date,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )}
                    type={INPUT_TYPE.DATE}
                    onChange={(value: any) =>
                      formCalendarFieldChanged(
                        value,
                        CONTACT_FIELD_KEY_NAMES.FROM_DATE
                      )
                    }
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                    dateFormat={convertBooksDateFormatToUILibraryFormat(
                      tenantInfo.dateFormat
                    )}
                  />
                </div>
              )}

              {!formState.additonalInfo.toDate.hidden && (
                <div className="web-width-48 mobile-mt-l">
                  <DKInput
                    className={''}
                    title="To Date"
                    value={DateFormatService.getDateFromStr(
                      formState.additonalInfo.toDate.date,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )}
                    type={INPUT_TYPE.DATE}
                    onChange={(value: any) =>
                      formCalendarFieldChanged(
                        value,
                        CONTACT_FIELD_KEY_NAMES.TO_DATE
                      )
                    }
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                    dateFormat={convertBooksDateFormatToUILibraryFormat(
                      tenantInfo.dateFormat
                    )}
                  />
                </div>
              )}

              {!formState.accounting.nonDeductionReasons.hidden &&
                formState.accounting.nonDeductionReasons.options && (
                  <div style={{ width: '100%' }} className="mt-l">
                    {sdkDropdown(
                      'Deduction Reason',
                      formState.accounting.nonDeductionReasons.value.label,
                      formState.accounting.nonDeductionReasons.options,
                      CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                      CONTACT_FIELD_KEY_NAMES.NON_DEDUCTION_REASON,
                      INPUT_VIEW_DIRECTION.VERTICAL,
                      formState.accounting.nonDeductionReasons.isMandatory,
                      formState.accounting.nonDeductionReasons.hasError,
                      formState.accounting.nonDeductionReasons.errorMsg
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const advancedInfoForm = () => {
    return (
      <div>
        <DKLabel text="Credit limit" className="mb-r fw-m" />
        <div className="parent-width p-h-l p-v-s border-m border-radius-s mt-s row flex-wrap justify-content-between">
          {!formState.accounting.creditLimit.creditLimitInput.hidden && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              {sdkInput(
                `Amount (${formState.generalInfo.currency.value.toString()})`,
                formState.accounting.creditLimit.creditLimitInput.value,
                'Credit Limit',
                CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO,
                CONTACT_FIELD_KEY_NAMES.CREDIT_LIMIT,
                '',
                formState.accounting.creditLimit.creditLimitInput.isMandatory,
                formState.accounting.creditLimit.creditLimitInput.hasError,
                formState.accounting.creditLimit.creditLimitInput.errorMsg,
                INPUT_VIEW_DIRECTION.VERTICAL
              )}
              {getAmountBlockInBaseCurrency()}
            </div>
          )}

          {formState.accounting.creditLimit.showConversionRate && (
            <div
              className="web-width-48"
              style={{
                height: 85
              }}
            >
              <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
                <div className="parent-width">
                  <DKLabel text={`Conversion`} />
                  <div
                    className={
                      'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-xs ' +
                      'focus:outline-none focus:ring-2 focus:ring-blue-200'
                    }
                  >
                    <div className="row currency-select-trigger align-items-center content-start w-fit">
                      <div className="ml-1">{`1 ${formState.generalInfo.currency.value.toString()}`}</div>
                      <div className="currency-dropdown-flag ml-2">
                        <span
                          className={`currency-dropdown-selector-flag flag ${formState.generalInfo.currency.value.toString()}`}
                        ></span>
                      </div>
                      <DKIcon
                        className="ml-r"
                        style={{
                          transform: 'rotate(90deg)'
                        }}
                        src={DKIcons.ic_sort}
                      />
                    </div>
                    <div className="row align-items-start content-end">
                      <input
                        className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                        value={formState.exchangeRate}
                        type={'number'}
                        onChange={(e) => {}}
                        readOnly={true}
                        style={{ width: '60%' }}
                      />
                      <div className="ml-1">{`${tenantInfo.currency}`}</div>
                      <div className="currency-dropdown-flag ml-2">
                        <span
                          className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="parent-width mt-xs mb-r">
            Continue with global credit limit settings?, OR,
          </div>
          <div className="parent-width flex mt-s">
            <div style={{ marginTop: -2 }}>
              <DKLabel
                text="Apply advance credit limit settings(for this contact)"
                className="fw-m"
              />
            </div>

            <Toggle
              onChange={() =>
                checkboxUpdated(
                  !formState.accounting.creditLimit.showCreditLimitInfo,
                  CREDIT_LIMIT_TYPE.CREDIT_LIMIT_INFO
                )
              }
              isOn={formState.accounting.creditLimit.showCreditLimitInfo}
              className="ml-l box-content -mt-1"
            />
          </div>
          {formState.accounting.creditLimit.showCreditLimitInfo && (
            <div className="parent-width mt-r">
              <div className="mb-r">
                <DKLabel
                  text={`For ${Utility.isUSorg() ? 'Estimates' : 'Quotes'}`}
                  className="fs-r fw-m mb-m"
                />
                <div className="flex" style={{ width: '15rem' }}>
                  <DKRadioButton
                    title="Ignore"
                    isSelected={
                      formState.accounting.creditLimit.quoteCreditLimitType ===
                      CREDIT_LIMIT_TYPE.IGNORE
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.IGNORE,
                        CREDIT_LIMIT_DOC_TYPE.QUOTE
                      )
                    }
                    className="mr-l"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                  <DKRadioButton
                    title="Warn"
                    isSelected={
                      formState.accounting.creditLimit.quoteCreditLimitType ===
                      CREDIT_LIMIT_TYPE.WARN
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.WARN,
                        CREDIT_LIMIT_DOC_TYPE.QUOTE
                      )
                    }
                    className="mr-l"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                  <DKRadioButton
                    title="Block"
                    isSelected={
                      formState.accounting.creditLimit.quoteCreditLimitType ===
                      CREDIT_LIMIT_TYPE.BLOCK
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.BLOCK,
                        CREDIT_LIMIT_DOC_TYPE.QUOTE
                      )
                    }
                    className="mr-s"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                </div>
                <div>
                  <DKCheckMark
                    title={`Include current ${
                      Utility.isUSorg() ? 'Estimate' : 'Quote'
                    }`}
                    className="text-grey m-v-m"
                    isSelected={formState.accounting.creditLimit.quoteSelected}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                    onClick={(value: any) =>
                      checkboxUpdated(
                        !formState.accounting.creditLimit.quoteSelected,
                        CREDIT_LIMIT_TYPE.QUOTE
                      )
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  borderTop: '1px solid rgb(220, 220, 220)'
                }}
              >
                <DKLabel text="For Invoices" className="fs-r fw-m mb-m mt-r" />
                <div className="flex" style={{ width: '15rem' }}>
                  <DKRadioButton
                    title="Ignore"
                    isSelected={
                      formState.accounting.creditLimit
                        .invoiceCreditLimitType === CREDIT_LIMIT_TYPE.IGNORE
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.IGNORE,
                        CREDIT_LIMIT_DOC_TYPE.INVOICE
                      )
                    }
                    className="mr-l"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                  <DKRadioButton
                    title="Warn"
                    isSelected={
                      formState.accounting.creditLimit
                        .invoiceCreditLimitType === CREDIT_LIMIT_TYPE.WARN
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.WARN,
                        CREDIT_LIMIT_DOC_TYPE.INVOICE
                      )
                    }
                    className="mr-l"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                  <DKRadioButton
                    title="Block"
                    isSelected={
                      formState.accounting.creditLimit
                        .invoiceCreditLimitType === CREDIT_LIMIT_TYPE.BLOCK
                    }
                    onClick={() =>
                      optionUpdated(
                        CREDIT_LIMIT_TYPE.BLOCK,
                        CREDIT_LIMIT_DOC_TYPE.INVOICE
                      )
                    }
                    className="mr-s"
                    color={'bg-app'}
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                  />
                </div>
                <div>
                  <DKCheckMark
                    title={'Include current Invoice'}
                    className="text-grey m-v-m"
                    isSelected={
                      formState.accounting.creditLimit.invoiceSelected
                    }
                    disabled={
                      !formState.accounting.creditLimit.showCreditLimitInfo
                    }
                    onClick={(value: any) =>
                      checkboxUpdated(
                        !formState.accounting.creditLimit.invoiceSelected,
                        CREDIT_LIMIT_TYPE.INVOICE
                      )
                    }
                  />
                </div>
              </div>

              {/* Sales Order */}
              {isSalesOrderVisible() && (
                <div
                  style={{
                    borderTop: '1px solid rgb(220, 220, 220)'
                  }}
                >
                  <DKLabel
                    text="For Sales Order"
                    className="fs-r fw-m mb-m mt-r"
                  />
                  <div className="flex" style={{ width: '15rem' }}>
                    <DKRadioButton
                      title="Ignore"
                      isSelected={
                        formState.accounting.creditLimit
                          .salesOrderCreditLimitType ===
                        CREDIT_LIMIT_TYPE.IGNORE
                      }
                      onClick={() =>
                        optionUpdated(
                          CREDIT_LIMIT_TYPE.IGNORE,
                          CREDIT_LIMIT_DOC_TYPE.SALES_ORDER
                        )
                      }
                      className="mr-l"
                      color={'bg-app'}
                      disabled={
                        !formState.accounting.creditLimit.showCreditLimitInfo
                      }
                    />
                    <DKRadioButton
                      title="Warn"
                      isSelected={
                        formState.accounting.creditLimit
                          .salesOrderCreditLimitType === CREDIT_LIMIT_TYPE.WARN
                      }
                      onClick={() =>
                        optionUpdated(
                          CREDIT_LIMIT_TYPE.WARN,
                          CREDIT_LIMIT_DOC_TYPE.SALES_ORDER
                        )
                      }
                      className="mr-l"
                      color={'bg-app'}
                      disabled={
                        !formState.accounting.creditLimit.showCreditLimitInfo
                      }
                    />
                    <DKRadioButton
                      title="Block"
                      isSelected={
                        formState.accounting.creditLimit
                          .salesOrderCreditLimitType === CREDIT_LIMIT_TYPE.BLOCK
                      }
                      onClick={() =>
                        optionUpdated(
                          CREDIT_LIMIT_TYPE.BLOCK,
                          CREDIT_LIMIT_DOC_TYPE.SALES_ORDER
                        )
                      }
                      className="mr-s"
                      color={'bg-app'}
                      disabled={
                        !formState.accounting.creditLimit.showCreditLimitInfo
                      }
                    />
                  </div>
                  <div>
                    <DKCheckMark
                      title={'Include current Sales Order'}
                      className="text-grey m-v-m"
                      isSelected={
                        formState.accounting.creditLimit.salesOrderSelected
                      }
                      disabled={
                        !formState.accounting.creditLimit.showCreditLimitInfo
                      }
                      onClick={(value: any) =>
                        checkboxUpdated(
                          !formState.accounting.creditLimit.salesOrderSelected,
                          CREDIT_LIMIT_TYPE.SALES_ORDER
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {(!formState.accounting.salesPriceLists.hidden ||
          !formState.accounting.purchasePriceLists.hidden) && (
          <DKLabel text="Price List" className="mt-l fw-m" />
        )}

        {(!formState.accounting.salesPriceLists.hidden ||
          !formState.accounting.purchasePriceLists.hidden) && (
          <div className="column parent-width mt-r p-h-l p-v-s border-m border-radius-s mt-s justify-content-between">
            <div className="row flex-wrap justify-content-between">
              <div
                className=" column web-width-48"
                style={{
                  height: 85
                }}
              >
                <DKButton
                  className="mt-r text-blue fw-m text-underline"
                  style={{
                    padding: 0
                  }}
                  title="+ Purchase Price Lists"
                  onClick={() => {
                    setShowMultiPurchasePL(true);
                  }}
                />
                <span className="bg-chip-green p-xs fs-r mt-s">{`${
                  formState.accounting.purchasePriceLists.value.length
                } Price ${
                  formState.accounting.purchasePriceLists.value.length < 2
                    ? 'List'
                    : 'Lists'
                } Selected`}</span>
              </div>

              <div
                className="column web-width-48"
                style={{
                  height: 85
                }}
              >
                <DKButton
                  className="mt-r text-blue fw-m text-underline"
                  style={{
                    padding: 0
                  }}
                  title="+ Sales Price Lists"
                  onClick={() => {
                    setShowMultiSalesPL(true);
                  }}
                />
                <span className="bg-chip-green p-xs fs-r mt-s">{`${
                  formState.accounting.salesPriceLists.value.length
                } Price ${
                  formState.accounting.salesPriceLists.value.length < 2
                    ? 'List'
                    : 'Lists'
                } Selected`}</span>
              </div>
            </div>
          </div>
        )}
        <DKLabel text="Custom Fields" className="fw-m mt-l" />
        <div className="column parent-width mb-l p-h-l mt-r p-v-s border-m border-radius-s justify-content-between">
          {/* <DKLabel text="Custom Fields" className="fw-m" /> */}
          <CustomFieldsHolder
            documentMode={props.contactMode}
            moduleName={MODULES_NAME.CONTACT}
            customFieldsList={
              props.populateFormData?.customField
                ? props.populateFormData.customField
                : tmpCustomField
                ? tmpCustomField
                : []
            }
            columnConfig={columnConfig}
            columnConfigTableId={columnConfigTableId}
            onUpdate={(list) => customFieldUpdated(list)}
          />
        </div>
      </div>
    );
  };

  const paymentInfoForm = () => {
    return (
      <div className="column parent-width">
        <div className="row row-responsive flex-wrap justify-content-between">
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Customer ID',
              formState.additionalPaymentInfo.customerId.value,
              'Customer ID',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.CUSTOMER_ID,
              '',
              formState.additionalPaymentInfo.customerId.isMandatory,
              formState.additionalPaymentInfo.customerId.hasError,
              formState.additionalPaymentInfo.customerId.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.TEXT
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Customer Internal ID',
              formState.additionalPaymentInfo.customerInternalId.value,
              'Customer Internal ID',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.CUSTOMER_INTERNAL_ID,
              '',
              formState.additionalPaymentInfo.customerInternalId.isMandatory,
              formState.additionalPaymentInfo.customerInternalId.hasError,
              formState.additionalPaymentInfo.customerInternalId.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Type',
              formState.additionalPaymentInfo.type.value?.label,
              formState.additionalPaymentInfo.type.options,
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.TYPE,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.additionalPaymentInfo.type.isMandatory,
              formState.additionalPaymentInfo.type.hasError,
              formState.additionalPaymentInfo.type.errorMsg,
              false
            )}
          </div>
        </div>
        <div className="row row-responsive flex-wrap justify-content-between">
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Bank Account Type',
              formState.additionalPaymentInfo.bankAccountType.value?.label,
              formState.additionalPaymentInfo.bankAccountType.options,
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_TYPE,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.additionalPaymentInfo.bankAccountType.isMandatory,
              formState.additionalPaymentInfo.bankAccountType.hasError,
              formState.additionalPaymentInfo.bankAccountType.errorMsg,
              false
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Bank Account Number',
              formState.additionalPaymentInfo.bankAccountNumber.value,
              'Bank Account Number',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_NUMBER,
              '',
              formState.additionalPaymentInfo.bankAccountNumber.isMandatory,
              formState.additionalPaymentInfo.bankAccountNumber.hasError,
              formState.additionalPaymentInfo.bankAccountNumber.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkDropdown(
              'Payment File Format',
              formState.additionalPaymentInfo.paymentFileFormat.value?.label,
              formState.additionalPaymentInfo.paymentFileFormat.options,
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.PAYMENT_FILE_FORMAT,
              INPUT_VIEW_DIRECTION.VERTICAL,
              formState.additionalPaymentInfo.paymentFileFormat.isMandatory,
              formState.additionalPaymentInfo.paymentFileFormat.hasError,
              formState.additionalPaymentInfo.paymentFileFormat.errorMsg,
              false
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Country Check',
              formState.additionalPaymentInfo.countryCheck.value,
              'Country Check',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.COUNTRY_CHECK,
              '',
              formState.additionalPaymentInfo.countryCheck.isMandatory,
              formState.additionalPaymentInfo.countryCheck.hasError,
              formState.additionalPaymentInfo.countryCheck.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Bank Code',
              formState.additionalPaymentInfo.bankCode.value,
              'Bank Code',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.BANK_CODE,
              '',
              formState.additionalPaymentInfo.bankCode.isMandatory,
              formState.additionalPaymentInfo.bankCode.hasError,
              formState.additionalPaymentInfo.bankCode.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Processor Code',
              formState.additionalPaymentInfo.processorCode.value,
              'Processor Code',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.PROCESSOR_CODE,
              '',
              formState.additionalPaymentInfo.processorCode.isMandatory,
              formState.additionalPaymentInfo.processorCode.hasError,
              formState.additionalPaymentInfo.processorCode.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Routing Number',
              formState.additionalPaymentInfo.receivingDfiId.value,
              'Routing Number',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.RECEIVING_DFI_ID,
              '',
              formState.additionalPaymentInfo.receivingDfiId.isMandatory,
              formState.additionalPaymentInfo.receivingDfiId.hasError,
              formState.additionalPaymentInfo.receivingDfiId.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.TEXT,
              undefined,
              undefined,
              formState.additionalPaymentInfo.receivingDfiId.maxLength
            )}
          </div>
          <div className="web-width-48" style={{ height: 85 }}>
            {sdkInput(
              'Check Digit',
              formState.additionalPaymentInfo.checkDigit.value,
              'Check Digit',
              CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
              CONTACT_FIELD_KEY_NAMES.CHECK_DIGIT,
              '',
              formState.additionalPaymentInfo.checkDigit.isMandatory,
              formState.additionalPaymentInfo.checkDigit.hasError,
              formState.additionalPaymentInfo.checkDigit.errorMsg,
              INPUT_VIEW_DIRECTION.VERTICAL,
              false,
              INPUT_TYPE.NUMBER,
              undefined,
              undefined,
              formState.additionalPaymentInfo.checkDigit.maxLength
            )}
          </div>
          {isDefaultCanadaShippingCountry && (
            <>
              <div className="web-width-48" style={{ height: 85 }}>
                {sdkInput(
                  'Client Number',
                  formState.additionalPaymentInfo.clientNumber.value,
                  'Client Number',
                  CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
                  CONTACT_FIELD_KEY_NAMES.CLIENT_NUMBER,
                  '',
                  formState.additionalPaymentInfo.clientNumber.isMandatory,
                  formState.additionalPaymentInfo.clientNumber.hasError,
                  formState.additionalPaymentInfo.clientNumber.errorMsg,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  false,
                  INPUT_TYPE.TEXT,
                  undefined,
                  undefined,
                  formState.additionalPaymentInfo.clientNumber.maxLength
                )}
              </div>

              <div className="web-width-48" style={{ height: 85 }}>
                {sdkInput(
                  'Tax Code',
                  formState.additionalPaymentInfo.txnCode.value,
                  'Tax Code',
                  CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
                  CONTACT_FIELD_KEY_NAMES.TAX_CODE,
                  '',
                  formState.additionalPaymentInfo.txnCode.isMandatory,
                  formState.additionalPaymentInfo.txnCode.hasError,
                  formState.additionalPaymentInfo.txnCode.errorMsg,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  false,
                  INPUT_TYPE.TEXT,
                  undefined,
                  undefined,
                  formState.additionalPaymentInfo.txnCode.maxLength
                )}
              </div>

              <div className="web-width-48" style={{ height: 85 }}>
                {sdkInput(
                  'CAD Financial Institution and Branch Transit',
                  formState.additionalPaymentInfo.cadFinancialInstTransit.value,
                  'CAD Financial Institution and Branch Transit',
                  CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
                  CONTACT_FIELD_KEY_NAMES.CAD_FINANCIAL_INST_TRANSIT,
                  '',
                  formState.additionalPaymentInfo.cadFinancialInstTransit
                    .isMandatory,
                  formState.additionalPaymentInfo.cadFinancialInstTransit
                    .hasError,
                  formState.additionalPaymentInfo.cadFinancialInstTransit
                    .errorMsg,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  false,
                  INPUT_TYPE.TEXT,
                  undefined,
                  undefined,
                  formState.additionalPaymentInfo.cadFinancialInstTransit
                    .maxLength
                )}
              </div>

              <div className="web-width-48" style={{ height: 85 }}>
                {sdkInput(
                  'Processing Center ID',
                  formState.additionalPaymentInfo.processingCenterId.value,
                  'Processing Center ID',
                  CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO,
                  CONTACT_FIELD_KEY_NAMES.PROCESSING_CENTER_ID,
                  '',
                  formState.additionalPaymentInfo.processingCenterId
                    .isMandatory,
                  formState.additionalPaymentInfo.processingCenterId.hasError,
                  formState.additionalPaymentInfo.processingCenterId.errorMsg,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  false,
                  INPUT_TYPE.TEXT,
                  undefined,
                  undefined,
                  formState.additionalPaymentInfo.processingCenterId.maxLength
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const sdkDropdown = (
    title: string,
    value: string,
    dropdownOptions: ReactOptionType[] | undefined,
    sectionName: CONTACT_FIELD_SECTION_NAMES,
    fieldName: CONTACT_FIELD_KEY_NAMES,
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    isSearchable: boolean = false,
    className: string = '',
    searchableKey: string = 'label',
    index: number = 0,
    bgColor: string = '',
    titleColor: any = 'gray'
  ) => {
    return (
      <DKInput
        value={value}
        title={title}
        direction={direction}
        formatter={(obj: any) => {
          return obj;
        }}
        className={className}
        type={INPUT_TYPE.DROPDOWN}
        required={isMandatory}
        validator={(value: string) => {
          return !hasError;
        }}
        titleStyle={{
          color: titleColor
        }}
        valueStyle={{
          backgroundColor: bgColor ? 'white' : ''
        }}
        canValidate={hasError}
        onChange={(value: any) => {
          formDropdownChanged(value, sectionName, fieldName, index);
        }}
        errorMessage={errMsg}
        dropdownConfig={{
          allowSearch: isSearchable,
          searchableKey: searchableKey,
          data: dropdownOptions,
          renderer: (index: any, obj: any) => {
            return (
              <div className="flex flex-row w-full justify-content-between">
                <DKLabel
                  style={{ fontSize: '13px' }}
                  className="text-base border-radius-s"
                  text={obj.label}
                />
                {title === 'State' && (
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s opacity-50"
                    text={obj?.code ?? ''}
                  />
                )}
              </div>
            );
          }
        }}
      />
    );
  };

  const sdkInput = (
    title: string,
    value: string | number,
    placeholder: string,
    sectionName: CONTACT_FIELD_SECTION_NAMES,
    fieldName: CONTACT_FIELD_KEY_NAMES,
    className: string = '',
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL,
    readonly: boolean = false,
    type = INPUT_TYPE.TEXT,
    valueStyleColor: string = '#f8f8fa',
    titleStyleColor: string = 'gray',
    maxLength?: number | null
  ) => {
    return (
      <DKInput
        className={className}
        title={title}
        required={isMandatory}
        placeholder={placeholder}
        type={type}
        direction={direction}
        value={value}
        onChange={(value: string) =>
          formFieldChanged(value, sectionName, fieldName)
        }
        onBlur={(event: any) => {
          if (title === 'Contact UEN' && tenantInfo.peppolOptIn) {
            if (Utility.isEmpty(event.target.value)) {
              let updatedState = formState;
              updatedState.generalInfo.peppolID.value = ``;
              updatedState.generalInfo.peppolID.hidden = false;
              setFormState({ ...updatedState });
              return;
            }
            TenantService.checkValidUENNuber(event.target.value)
              .then((response: any) => {
                if (response) {
                  let buttons = [
                    {
                      title: 'No',
                      className: 'bg-gray2 border-m ',
                      onClick: () => {
                        let updatedState = formState;
                        // updatedState.generalInfo.peppolID.value = ``;
                        updatedState.generalInfo.peppolID.hidden = false;
                        setFormState({ ...updatedState });
                      }
                    },
                    {
                      title: 'Yes',
                      className: 'bg-button text-white ml-r',
                      onClick: () => {
                        let updatedState = formState;
                        updatedState.generalInfo.peppolID.value = `0195:sguen${event.target.value}`;
                        updatedState.generalInfo.peppolID.hidden = false;
                        setFormState({ ...updatedState });
                      }
                    }
                  ];
                  if (formState.generalInfo.peppolID.hidden === false) {
                    showAlert(
                      'UEN Registered with Peppol',
                      'We found a PEPPOL ID for this contact. Do you want to update it here?',
                      buttons
                    );
                  }
                }
              })
              .catch((error: any) => {
                console.error('error', error);
              });
          }
        }}
        readOnly={readonly}
        canValidate={hasError}
        validator={(value: string) => {
          return !hasError;
        }}
        errorMessage={errMsg}
        valueStyle={{
          backgroundColor: valueStyleColor ? valueStyleColor : '#f8f8fa'
        }}
        titleStyle={{
          color: titleStyleColor ? titleStyleColor : 'gray'
        }}
        maxLength={maxLength}
      />
    );
  };

  const getAddILCertificates = (className: string = '') => {
    return (
      <DKButton
        title={'+ Add Certificate'}
        className={`text-blue fw-m text-underline ${className}`}
        style={{ padding: 0 }}
        onClick={() => {
          setShowIlCertificatePopup(true);
        }}
      />
    );
  };
  const getAddILLocalBank = (className: string = '', isLocal: boolean) => {
    return (
      <DKButton
        title={isLocal ? '+ Add Bank Details' : '+ Add Global Bank Details'}
        className={`text-blue fw-m text-underline ${className}`}
        style={{ padding: 0 }}
        onClick={() => {
          if (isLocal) {
            setShowILLocalBankPopup(true);
          } else {
            setShowILGlobalBankPopup(true);
          }
        }}
      />
    );
  };

  const removeCertificate = async (data: any) => {
    if (data) {
      const isConfirmed = await confirm(
        'Deleting this certificate will delete it permanently'
      );

      if (isConfirmed) {
        let certificateList = [...ilCertificates];
        let index = certificateList.findIndex(
          (parameter: any) => parameter?.id === data.id
        );
        certificateList.splice(index, 1);
        setILCertificates(certificateList);
        setCurrentCertificateToEditOrDelete(null);
      }
    }
  };
  const removeILLocalBank = async (data: any) => {
    if (data) {
      const isConfirmed = await confirm(
        'Deleting this bank will delete it permanently'
      );

      if (isConfirmed) {
        let bankList = [...iLLocalBankDetails];
        let index = bankList.findIndex(
          (parameter: any) => parameter?.id === data.id
        );
        bankList.splice(index, 1);
        setILLocalBankDetails(bankList);
        setCurrentCertificateToEditOrDelete(null);
      }
    }
  };
  const removeGlobalBank = async (data: any) => {
    if (data) {
      const isConfirmed = await confirm(
        'Deleting this bank will delete it permanently'
      );

      if (isConfirmed) {
        let bankList = [...iLLocalBankDetails];
        let index = bankList.findIndex(
          (parameter: any) => parameter?.id === data.id
        );
        bankList.splice(index, 1);
        setILGlobalBankDetails(bankList);
        setCurrentCertificateToEditOrDelete(null);
      }
    }
  };

  const getAddCertificateView = () => {
    return (
      <ILCertificatePopup
        currentReason={currentCertificateToEditOrDelete}
        onSave={(newPayload: any) => {
          let certificateList = [...ilCertificates];
          certificateList.push(newPayload);
          setILCertificates(certificateList);
          setShowIlCertificatePopup(false);
        }}
        onEdit={(newPayload: any, index: any) => {
          let certificateList = [...ilCertificates];
          certificateList[index] = newPayload;
          setILCertificates(certificateList);
          setShowIlCertificatePopup(false);
        }}
        onCancel={() => {
          setCurrentCertificateToEditOrDelete(null);
          setShowIlCertificatePopup(false);
        }}
      />
    );
  };
  const getAddILLoaclBankView = () => {
    return (
      <ILBankDetailsPopup
        currentReason={currentCertificateToEditOrDelete}
        onSave={(newPayload: any) => {
          let bankList = [...iLLocalBankDetails];
          bankList.push(newPayload);
          setILLocalBankDetails(bankList);
          setShowILLocalBankPopup(false);
        }}
        onEdit={(newPayload: any, index: any) => {
          let bankList = [...iLLocalBankDetails];
          bankList[index] = newPayload;
          setILLocalBankDetails(bankList);
          setShowILLocalBankPopup(false);
        }}
        onCancel={() => {
          setCurrentCertificateToEditOrDelete(null);
          setShowILLocalBankPopup(false);
        }}
      />
    );
  };

  const getAddILGlobalBankView = () => {
    return (
      <ILGlobalBankDetailsPopup
        currentReason={currentCertificateToEditOrDelete}
        onSave={(newPayload: any) => {
          let bankList = [...iLGlobalBankDetails];
          bankList.push(newPayload);
          setILGlobalBankDetails(bankList);
          setShowILGlobalBankPopup(false);
        }}
        onEdit={(newPayload: any, index: any) => {
          let bankList = [...iLGlobalBankDetails];
          bankList[index] = newPayload;
          setILGlobalBankDetails(bankList);
          setShowILGlobalBankPopup(false);
        }}
        onCancel={() => {
          setCurrentCertificateToEditOrDelete(null);
          setShowILGlobalBankPopup(false);
        }}
      />
    );
  };

  const getGrid = (title: string, rows: any[], columns: any[]) => {
    return (
      <DKDataGrid
        tableName={''}
        filterTableName={title}
        needColumnIcons={false}
        allowRowEdit={false}
        allowColumnEdit={false}
        allowSort={false}
        allowRowAdd={false}
        allowColumnAdd={false}
        allowColumnDelete={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowSearch={false}
        // onSearch={onSearch}
        headerButtons={[]}
        allowDataExport={false}
        columns={columns}
        rows={rows}
        currentPage={1}
        totalPageCount={1}
        onRowUpdate={(data: any) => {}}
        allowBulkOperation={false}
        onRowSelect={(data: any) => {}}
        width={() => {
          let mainWidth = document.getElementById('mainDiv')?.offsetWidth || 30;
          return mainWidth - 30;
        }}
        needTrailingColumn={true}
        onAllRowSelect={(data: any) => {}}
      />
    );
  };

  const getComponentRow = () => {
    return (
      ilCertificates?.map((item: any, index: number) => {
        return {
          ...item,
          rowButtons: [
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_edit,
              onClick: () => {
                setCurrentCertificateToEditOrDelete({ ...item, index: index });
                setShowIlCertificatePopup(true);
              }
            },
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_delete,
              onClick: () => {
                removeCertificate(item);
              }
            }
          ]
        };
      }) || []
    );
  };

  const getColConfig = () => {
    return IL_CERTIFICATE_CONST.map((column: any) => {
      return column;
    });
  };

  const getILLocalBankMenu = () => {
    return (
      iLLocalBankDetails?.map((item: any, index: number) => {
        return {
          ...item,
          rowButtons: [
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_edit,
              onClick: () => {
                setCurrentCertificateToEditOrDelete({ ...item, index: index });
                setShowILLocalBankPopup(true);
              }
            },
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_delete,
              onClick: () => {
                removeILLocalBank(item);
              }
            }
          ]
        };
      }) || []
    );
  };

  const getILGlobalBankMenu = () => {
    return (
      iLGlobalBankDetails?.map((item: any, index: number) => {
        return {
          ...item,
          rowButtons: [
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_edit,
              onClick: () => {
                setCurrentCertificateToEditOrDelete({ ...item, index: index });
                setShowILGlobalBankPopup(true);
              }
            },
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_delete,
              onClick: () => {
                removeGlobalBank(item);
              }
            }
          ]
        };
      }) || []
    );
  };

  const fetchApiData = () => {
    // priceList Info
    fetchPriceListInfo();
  };

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_CONTACT,
        data: () => {
          createContact();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_CONTACT,
        data: () => {
          updateContact();
        }
      });
    }
  };

  const grabCurrencyInfo = (data: Information) => {
    const updatedState = formState;
    updatedState.accounting.creditLimit.showConversionRate = true;
    if (data.selectedCurrencyCode === tenantInfo.currency) {
      updatedState.accounting.creditLimit.showConversionRate = false;
    }
    const selectedCurrencyInfo = updatedState.activeMultiCurrencyList.find(
      (currency) => currency.currencyCode === data.selectedCurrencyCode
    );
    if (selectedCurrencyInfo) {
      const exchangeRate = selectedCurrencyInfo.currencyExchangeRate;
      const preciseCurrencyExchangeRate = roundingOffStr(
        1 / exchangeRate,
        CURRENCY_PRECISION
      );
      updatedState.exchangeRate = preciseCurrencyExchangeRate;
    }
    updatedState.generalInfo.currency.value = data.selectedCurrencyCode;
    setFormState({ ...updatedState });
  };

  const getCustomerVendorOptions = (gstTreatment: any) => {
    switch (gstTreatment) {
      case GST_TREATMENT_VALUES[0].key:
      case GST_TREATMENT_VALUES[2].key:
        return { customerOptions: customerType, vendorOptions: vendorType };
      case GST_TREATMENT_VALUES[3].key:
        return {
          customerOptions: CUSTOMER_TYPES_REGISTERED_BUSINESS_COMP,
          vendorOptions: VENDOR_TYPES_REGISTERED_BUSINESS_COMP
        };
      case GST_TREATMENT_VALUES[1].key:
      case GST_TREATMENT_VALUES[6].key:
        return {
          customerOptions: CUSTOMER_TYPES_REGISTERED_BUSINESS_COMP,
          vendorOptions: VENDOR_TYPES_REGISTERED_BUSINESS_COMP
        };

      case GST_TREATMENT_VALUES[4].key:
        return {
          customerOptions: customerTypesOverseas,
          vendorOptions: vendorTypesOverseas
        };
      case GST_TREATMENT_VALUES[5].key:
        return {
          customerOptions: customerTypesSEZ,
          vendorOptions: vendorTypesSEZ
        };
    }
  };

  // form updation methods

  const formDropdownChanged = (
    newValue: ReactOptionType | null,
    sectionName: CONTACT_FIELD_SECTION_NAMES,
    fieldName: CONTACT_FIELD_KEY_NAMES,
    index: number = 0
  ) => {
    const updatedState = formState;
    switch (sectionName) {
      case CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO:
        const generalSection: GeneralInfo = updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.TAX_TREATMENT_UAE:
            if (newValue && generalSection.taxTreatmentUae) {
              generalSection.taxTreatmentUae.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.INTERCOMPANY_TYPE:
            if (newValue && generalSection.intercompanyType) {
              generalSection.intercompanyType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_SALES_TAX_RATE:
            if (newValue && generalSection.ukDefaultSalesTaxRate) {
              generalSection.ukDefaultSalesTaxRate.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_PURCHASE_TAX_RATE:
            if (newValue && generalSection.ukDefaultPurchaseTaxRate) {
              generalSection.ukDefaultPurchaseTaxRate.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.SALES_TAX_RATE_ISRAEL:
            if (newValue && generalSection.salesTaxCodeIsrael) {
              generalSection.salesTaxCodeIsrael.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.PURCHASE_TAX_RATE_ISRAEL:
            if (newValue && generalSection.purchaseTaxCodeIsrael) {
              generalSection.purchaseTaxCodeIsrael.value = newValue;
            }
            break;
        }
        break;
      case CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO:
        const accountingSection: Accounting = updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.PAYABLE_ACCOUNT:
            if (newValue && accountingSection.payableAccount) {
              accountingSection.payableAccount.value = newValue;
            }
            setFutureAccountApply({
              ...futureAccountsApply,
              payableAccountCodeTouched: true
            });
            break;
          case CONTACT_FIELD_KEY_NAMES.RECEIVEABLE_ACCOUNT:
            if (newValue && accountingSection.receiveableAccount) {
              accountingSection.receiveableAccount.value = newValue;
            }
            setFutureAccountApply({
              ...futureAccountsApply,
              receivableAccountCodeTouched: true
            });
            break;
          case CONTACT_FIELD_KEY_NAMES.GST_TREATMENT:
            if (newValue) {
              let vendorTypeOptions: ReactOptionType[] = [];
              let customerTypeOptions: ReactOptionType[] = [];
              // default options
              vendorType.forEach((vendor) => {
                const option: ReactOptionType = {
                  label: vendor.value as string,
                  value: vendor.key as string
                };
                vendorTypeOptions.push(option);
              });

              customerType.forEach((customer) => {
                let option: ReactOptionType = {
                  label: customer.value as string,
                  value: customer.key as string
                };
                customerTypeOptions.push(option);
              });

              const custVendorOptions = getCustomerVendorOptions(
                newValue.value
              );
              if (custVendorOptions) {
                customerTypeOptions.splice(0, customerTypeOptions.length);
                customerTypeOptions = custVendorOptions.customerOptions?.map(
                  (option) => ({
                    label: option.value as string,
                    value: option.key as string
                  })
                );

                vendorTypeOptions.splice(0, vendorTypeOptions.length);
                vendorTypeOptions = custVendorOptions.vendorOptions?.map(
                  (option) => ({
                    label: option.value as string,
                    value: option.key as string
                  })
                );
              }

              accountingSection.gstIn.hidden = false;
              // if GST Treatment is Overseas/Consumer/Unregistered Business/ hide GSTIN field
              if (
                newValue.value === GST_TREATMENT_VALUES[2].key ||
                newValue.value === GST_TREATMENT_VALUES[3].key ||
                newValue.value === GST_TREATMENT_VALUES[4].key
              ) {
                accountingSection.gstIn.hidden = true;
              }
              accountingSection.vendorType.options = vendorTypeOptions;
              accountingSection.vendorType.value = vendorTypeOptions[0];
              accountingSection.customerType.value = customerTypeOptions[0];
              accountingSection.customerType.options = customerTypeOptions;
              accountingSection.gstTreatment.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE:
            if (newValue) {
              accountingSection.customerType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE_MALAYSIA:
            if (newValue) {
              accountingSection.customerTypeMalaysia.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE_MALAYSIA:
            if (newValue) {
              accountingSection.vendorTypeMalaysia.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.EXEMPTION_CRITERIAL_MALAYSIA:
            if (newValue) {
              accountingSection.exemptionCriterialMalaysia.value = newValue;
              let customerTypeMalaysiaOptions: ReactOptionType[] = [];

              switch (newValue.value) {
                case EXEMPTION_CRITERIAL_MALAYSIA_KEY.EXEMPTED_ZERO:
                  customerTypeMalaysiaExempted.forEach((customer) => {
                    if (
                      !updatedState.additonalInfo.exemptionCertificateMalaysia
                        .isSelected
                    ) {
                      if (customer.key === NONE) {
                        let option: ReactOptionType = {
                          label: customer.value as string,
                          value: customer.key as string
                        };
                        customerTypeMalaysiaOptions.push(option);
                      }
                    } else {
                      let option: ReactOptionType = {
                        label: customer.value as string,
                        value: customer.key as string
                      };
                      customerTypeMalaysiaOptions.push(option);
                    }
                  });

                  updatedState.accounting.customerTypeMalaysia = {
                    ...updatedState.accounting.customerTypeMalaysia,
                    options: [...customerTypeMalaysiaOptions],
                    defaultValue: customerTypeMalaysiaOptions[0],
                    value: customerTypeMalaysiaOptions[0]
                  };
                  break;
                case EXEMPTION_CRITERIAL_MALAYSIA_KEY.ZERO_RATED:
                  customerTypeMalaysia.forEach((customer) => {
                    if (
                      !updatedState.additonalInfo.exemptionCertificateMalaysia
                        .isSelected
                    ) {
                      if (customer.key === NONE) {
                        let option: ReactOptionType = {
                          label: customer.value as string,
                          value: customer.key as string
                        };
                        customerTypeMalaysiaOptions.push(option);
                      }
                    } else {
                      let option: ReactOptionType = {
                        label: customer.value as string,
                        value: customer.key as string
                      };
                      customerTypeMalaysiaOptions.push(option);
                    }
                  });

                  updatedState.accounting.customerTypeMalaysia = {
                    ...updatedState.accounting.customerTypeMalaysia,
                    options: [...customerTypeMalaysiaOptions],
                    defaultValue: customerTypeMalaysiaOptions[0],
                    value: customerTypeMalaysiaOptions[0]
                  };
                  break;
                case EXEMPTION_CRITERIAL_MALAYSIA_KEY.NONE:
                  customerTypeMalaysia.forEach((customer) => {
                    if (customer.key === NONE) {
                      let option: ReactOptionType = {
                        label: customer.value as string,
                        value: customer.key as string
                      };
                      customerTypeMalaysiaOptions.push(option);
                    }
                  });

                  updatedState.accounting.customerTypeMalaysia = {
                    ...updatedState.accounting.customerTypeMalaysia,
                    options: [...customerTypeMalaysiaOptions],
                    defaultValue: customerTypeMalaysiaOptions[0],
                    value: customerTypeMalaysiaOptions[0]
                  };
                  break;
                default:
                  break;
              }
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.CUSTOMER_TAX_TYPE:
            if (newValue) {
              accountingSection.customerTaxType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.VENDOR_TAX_TYPE:
            if (newValue) {
              accountingSection.vendorTaxType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE:
            if (newValue) {
              accountingSection.vendorType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.PAYMENT_TERM:
            if (newValue) {
              if (newValue.value === 'addPaymentTerm') {
                updatedState.paymentInfo.showPopup = true;
              } else {
                accountingSection.paymentTerm.value = newValue;
              }
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.TAX_EXEMPT:
            if (newValue) {
              accountingSection.exemptType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.BUSINESS_UNIT:
            if (newValue) {
              accountingSection.businessUnit.value = newValue;
              accountingSection.businessUnit.hasError =
                Utility.isEmptyObject(newValue);
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.NON_DEDUCTION_REASON:
            if (newValue) {
              accountingSection.nonDeductionReasons.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.DEDUCTEE_TYPE:
            if (newValue) {
              if (newValue.value === 'addDeductee') {
                updatedState.deducteeInfo.showPopup = true;
              } else {
                accountingSection.deducteeType.value = newValue;
              }
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.ASSESSING_OFFICER_NUMBER_ISRAEL:
            if (newValue) {
              accountingSection.assessingOfficerNumberIsrael.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.TYPE_OF_COMPANY_ISRAEL:
            if (newValue) {
              accountingSection.typeOfCompanyIsrael.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_PAYMENT_GROUP_ID_ISRAEL:
            if (newValue) {
              accountingSection.withHoldingTaxPaymentGroupIsraelId.value =
                newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_TYPE_ID_ISRAEL:
            if (newValue) {
              accountingSection.withHoldingTaxTypeIsraelId.value = newValue;
            }
            break;
          default:
            break;
        }
        break;
      case CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO:
        const addressSection: AddressInfo = deepClone(
          updatedState[sectionName][index]
        );
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.STATE:
            if (newValue) {
              addressSection.state.value = newValue;
              addressSection.state.hasError = false;
              if (!newValue && addressSection.state.isMandatory) {
                addressSection.postalCode.hasError = true;
                addressSection.postalCode.errorMsg = 'State is mandatory';
              }
            }
            const state = INDIAN_STATES_MOCK_DATA.find(
              (state) => state.name === newValue?.label
            );

            if (state) {
              const cityOptions: ReactOptionType[] = state.cities.map(
                (city) => {
                  const option: ReactOptionType = {
                    label: city,
                    value: city
                  };
                  return option;
                }
              );

              addressSection.city.options = cityOptions;
              addressSection.city.value = { label: '', value: '' };
            }

            break;
          case CONTACT_FIELD_KEY_NAMES.CITY:
            if (newValue) {
              addressSection.city.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.COUNTRY:
            if (newValue?.value === COUNTRY_CODES.IN) {
              const indianStatesOptions: ReactOptionType[] = indianStates.map(
                (state) => {
                  const option: ReactOptionType = {
                    label: state.name,
                    value: state.code,
                    code: state.stateCode,
                    searchableKey: `${state.name} ${state.stateCode}`
                  };
                  return option;
                }
              );
              const cityOptions: ReactOptionType[] =
                INDIAN_STATES_MOCK_DATA[1].cities.map((city) => {
                  const option: ReactOptionType = {
                    label: city,
                    value: city
                  };
                  return option;
                });

              addressSection.city = {
                ...addressSection.state,
                options: [...cityOptions],
                value: cityOptions[0]
              };
              addressSection.state = {
                ...addressSection.city,
                options: [...indianStatesOptions],
                value: indianStatesOptions[1]
              };
              addressSection.state.hasError = false;
              addressSection.city.hasError = false;
            } else if (newValue?.value === COUNTRY_CODES.US) {
              const americaStatesOptions: ReactOptionType[] = americaStates.map(
                (state) => {
                  const option: ReactOptionType = {
                    label: state.name,
                    value: state.code,
                    searchableKey: `${state.name} ${state.code}`,
                    code: state.code
                  };
                  return option;
                }
              );
              addressSection.state = {
                ...addressSection.city,
                options: [...americaStatesOptions],
                value: americaStatesOptions[1]
              };
              addressSection.city = {
                ...addressSection.city,
                options: undefined,
                value: { label: '', value: '' }
              };
            } else {
              addressSection.state = {
                ...addressSection.state,
                options: undefined,
                value: { label: '', value: '' }
              };
              addressSection.city = {
                ...addressSection.city,
                options: undefined,
                value: { label: '', value: '' }
              };
            }
            if (newValue) {
              addressSection.country.value = newValue;
            }
            break;
          default:
            break;
        }
        updatedState[sectionName][index] = addressSection;
        break;
      case CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO:
        const additionalPaymentInfoSection: AdditionalPaymentInfo =
          updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.TYPE:
            if (newValue && additionalPaymentInfoSection.type) {
              additionalPaymentInfoSection.type.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_TYPE:
            if (newValue && additionalPaymentInfoSection.bankAccountType) {
              additionalPaymentInfoSection.bankAccountType.value = newValue;
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.PAYMENT_FILE_FORMAT:
            if (newValue && additionalPaymentInfoSection.paymentFileFormat) {
              additionalPaymentInfoSection.paymentFileFormat.value = newValue;
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const formFieldChanged = (
    newValue: string,
    sectionName: CONTACT_FIELD_SECTION_NAMES,
    fieldName: CONTACT_FIELD_KEY_NAMES,
    index: number = 0
  ) => {
    const updatedState = deepClone(formState);
    switch (sectionName) {
      case CONTACT_FIELD_SECTION_NAMES.ACCOUNTING_INFO:
        const accountingSection: Accounting = updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.GST_IN:
            const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
            if (accountingSection.gstIn) {
              accountingSection.gstIn.hasError = false;
              accountingSection.gstIn.value = newValue;
              if (!gstRegex.test(newValue)) {
                accountingSection.gstIn.hasError = true;
                accountingSection.gstIn.errorMsg = 'GSTIN is not valid';
              }
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.ATTENTION_TO:
            accountingSection.attentionTo.value = newValue;
            accountingSection.attentionTo.hasError =
              Utility.isEmptyObject(newValue);
            break;
          case CONTACT_FIELD_KEY_NAMES.VENDER_TAN:
            accountingSection.vendorTAN.hasError = false;
            if (!newValue && accountingSection.vendorTAN.isMandatory) {
              accountingSection.vendorTAN.hasError = true;
              accountingSection.vendorTAN.errorMsg = 'Vendor TAN is mandatory';
            }
            accountingSection.vendorTAN.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CERTIFICATE_NUMBER:
            accountingSection.certificateNumber.hasError = false;
            if (!newValue && accountingSection.certificateNumber.isMandatory) {
              accountingSection.certificateNumber.hasError = true;
              accountingSection.certificateNumber.errorMsg =
                'Certificate Name is mandatory';
            }
            accountingSection.certificateNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CREDIT_LIMIT:
            accountingSection.creditLimit.creditLimitInput.hasError = false;
            if (newValue && !isNaN(Number(newValue)) && Number(newValue) < 0) {
              accountingSection.creditLimit.creditLimitInput.hasError = true;
              accountingSection.creditLimit.creditLimitInput.errorMsg =
                'Credit Limit cannot be negative';
            }
            accountingSection.creditLimit.creditLimitInput.value = newValue;
            break;

          case CONTACT_FIELD_KEY_NAMES.TDS_RATE:
            accountingSection.tdsRate.hasError = false;
            if (!newValue && accountingSection.tdsRate.isMandatory) {
              accountingSection.tdsRate.hasError = true;
              accountingSection.tdsRate.errorMsg = 'Tds Rate is mandatory';
            } else if (isNaN(Number(newValue))) {
              accountingSection.tdsRate.hasError = true;
              accountingSection.tdsRate.errorMsg = 'Tds Rate is not valid';
            }
            accountingSection.tdsRate.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.HIGHER_RATE:
            accountingSection.higherRate.hasError = false;
            if (!newValue && accountingSection.higherRate.isMandatory) {
              accountingSection.higherRate.hasError = true;
              accountingSection.higherRate.errorMsg =
                'Higher Rate is mandatory';
            } else if (isNaN(Number(newValue))) {
              accountingSection.higherRate.hasError = true;
              accountingSection.higherRate.errorMsg =
                'Higher Rate is not valid';
            }
            accountingSection.higherRate.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.NPWP_NUMBER:
            let num = newValue.replace(/[^0-9]/g, '');
            if (num.length <= 15) {
              accountingSection.npwpNumber.value = formatNPWPNumber(newValue);
              accountingSection.npwpNumber.hasError = false;
              if (num.length < 15) {
                accountingSection.npwpNumber.hasError = true;
                accountingSection.npwpNumber.errorMsg = 'NPWP is not valid';
              }
            }

            break;
          case CONTACT_FIELD_KEY_NAMES.NIK_NUMBER:
            let onlyNumbers = newValue.replace(/[^0-9]/g, '');
            if (onlyNumbers.length <= 16) {
              const nikRegex = new RegExp(INDONESIA.NIK_NUMBER);
              if (!!newValue) {
                accountingSection.nikNumber.hasError = false;
                accountingSection.nikNumber.value = onlyNumbers;
                if (!nikRegex.test(onlyNumbers)) {
                  accountingSection.nikNumber.hasError = true;
                  accountingSection.nikNumber.errorMsg =
                    'Please enter valid 16 digit NIK Number';
                }
              }
              accountingSection.nikNumber.value = onlyNumbers;
            }

            break;
          case CONTACT_FIELD_KEY_NAMES.SERVICE_TAX_REGISTRATION_NUMBER:
            accountingSection.serviceTaxRegistrationNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.SALES_TAX_REGISTRATION_NUMBER:
            accountingSection.salesTaxRegistrationNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.BUSINESS_RESGISTRATION_NUMBER:
            accountingSection.businessRegistrationNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.TAX_PAYER_ID_ISRAEL:
            accountingSection.taxPayerIdIsrael.value = newValue;
            break;
          default:
            break;
        }
        break;
      case CONTACT_FIELD_SECTION_NAMES.GENERAL_INFO:
        const generalInfoSection: GeneralInfo = updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.NAME:
            generalInfoSection.name.hasError = false;
            if (!newValue && generalInfoSection.name.isMandatory) {
              generalInfoSection.name.hasError = true;
              generalInfoSection.name.errorMsg = 'Name is mandatory';
            }
            generalInfoSection.name.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.EMAIL:
            if (generalInfoSection.email)
              generalInfoSection.email.hasError = false;
            if (generalInfoSection.email && !Utility.isValidEmail(newValue)) {
              generalInfoSection.email.hasError = true;
              generalInfoSection.email.errorMsg = 'Email is not valid';
            }
            if (generalInfoSection.email)
              generalInfoSection.email.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER:
            generalInfoSection.contactNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.PAN_NUMBER:
            const panRegex = new RegExp(PAN_REGEX_PATTERN);
            if (generalInfoSection.panNumber) {
              generalInfoSection.panNumber.value = newValue;
              generalInfoSection.panNumber.hasError = false;
            }
            if (generalInfoSection.panNumber && !panRegex.test(newValue)) {
              generalInfoSection.panNumber.hasError = true;
              generalInfoSection.panNumber.errorMsg = 'PAN/TAN is not valid';
            }
            break;
          case CONTACT_FIELD_KEY_NAMES.TAX_NUMBER:
            generalInfoSection.taxNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CONTACT_UEN:
            generalInfoSection.contactUEN.hasError = false;
            if (generalInfoSection.contactUEN.isMandatory && !newValue) {
              generalInfoSection.contactUEN.hasError = true;
              generalInfoSection.contactUEN.errorMsg =
                'Contact UEN is not valid';
            }
            generalInfoSection.contactUEN.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.PEPPOL_ID:
            generalInfoSection.peppolID.hasError = false;
            if (generalInfoSection.peppolID.isMandatory && !newValue) {
              generalInfoSection.peppolID.hasError = true;
              generalInfoSection.peppolID.errorMsg = 'Peppol Id is not valid';
            }
            generalInfoSection.peppolID.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.VAT_REGISTRATION_UAE:
            generalInfoSection.vatRegistrationUae.hasError = false;
            if (
              generalInfoSection.vatRegistrationUae.isMandatory &&
              !newValue
            ) {
              generalInfoSection.vatRegistrationUae.hasError = true;
              generalInfoSection.vatRegistrationUae.errorMsg =
                'Vat registraion is not valid';
            }
            generalInfoSection.vatRegistrationUae.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.UK_VAT_IDENTIFICATION_NUMBER:
            generalInfoSection.ukVatIdentificationNo.hasError = false;
            if (
              !newValue &&
              generalInfoSection.ukVatIdentificationNo.isMandatory
            ) {
              generalInfoSection.ukVatIdentificationNo.hasError = true;
              generalInfoSection.ukVatIdentificationNo.errorMsg = 'Mandatory';
            }
            generalInfoSection.ukVatIdentificationNo.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.UK_BUSINESS_REGISTRATION_NUMBER:
            generalInfoSection.ukBusinessRegistrationNo.hasError = false;
            if (
              !newValue &&
              generalInfoSection.ukBusinessRegistrationNo.isMandatory
            ) {
              generalInfoSection.ukBusinessRegistrationNo.hasError = true;
              generalInfoSection.ukBusinessRegistrationNo.errorMsg =
                'Mandatory';
            }
            generalInfoSection.ukBusinessRegistrationNo.value = newValue;
            break;
          default:
            break;
        }
        break;
      case CONTACT_FIELD_SECTION_NAMES.ADDRESS_INFO:
        const addressSection: AddressInfo = updatedState[sectionName][index];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.CONTACT_NAME:
            addressSection.contactName.hasError = false;
            if (!newValue && addressSection.contactName.isMandatory) {
              addressSection.contactName.hasError = true;
              addressSection.contactName.errorMsg = 'Contact Name is mandatory';
            }
            addressSection.contactName.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.ADDRESS:
            addressSection.address.hasError = false;
            if (!newValue && addressSection.address.isMandatory) {
              addressSection.address.hasError = true;
              addressSection.address.errorMsg = 'Address is mandatory';
            }
            addressSection.address.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.ADDRESS2:
            addressSection.address2.hasError = false;
            if (!newValue && addressSection.address2.isMandatory) {
              addressSection.address2.hasError = true;
              addressSection.address2.errorMsg = 'Address is mandatory';
            }
            addressSection.address2.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.POSTAL_CODE:
            addressSection.postalCode.hasError = false;
            if (!newValue && addressSection.postalCode.isMandatory) {
              addressSection.postalCode.hasError = true;
              addressSection.postalCode.errorMsg = 'Postal Code is mandatory';
            }
            addressSection.postalCode.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CITY:
            addressSection.city.value = { label: newValue, value: newValue };
            break;
          case CONTACT_FIELD_KEY_NAMES.STATE:
            addressSection.state.value = { label: newValue, value: newValue };
            break;
          case CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER:
            addressSection.contactNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.EMAIL:
            if (addressSection.email) addressSection.email.hasError = false;
            if (addressSection.email && !Utility.isValidEmail(newValue)) {
              addressSection.email.hasError = true;
              addressSection.email.errorMsg = 'Email is not valid';
            }
            if (addressSection.email) addressSection.email.value = newValue;
            break;
          default:
            break;
        }
        break;
      case CONTACT_FIELD_SECTION_NAMES.ADDITIONAL_PAYMENT_INFO:
        const additionalPaymentInfoSection: AdditionalPaymentInfo =
          updatedState[sectionName];
        switch (fieldName) {
          case CONTACT_FIELD_KEY_NAMES.CUSTOMER_ID:
            additionalPaymentInfoSection.customerId.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CUSTOMER_INTERNAL_ID:
            additionalPaymentInfoSection.customerInternalId.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_NUMBER:
            additionalPaymentInfoSection.bankAccountNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.COUNTRY_CHECK:
            additionalPaymentInfoSection.countryCheck.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.BANK_CODE:
            additionalPaymentInfoSection.bankCode.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.PROCESSOR_CODE:
            additionalPaymentInfoSection.processorCode.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.RECEIVING_DFI_ID:
            additionalPaymentInfoSection.receivingDfiId.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CHECK_DIGIT:
            additionalPaymentInfoSection.checkDigit.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CLIENT_NUMBER:
            additionalPaymentInfoSection.clientNumber.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.TAX_CODE:
            additionalPaymentInfoSection.txnCode.value = newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.CAD_FINANCIAL_INST_TRANSIT:
            additionalPaymentInfoSection.cadFinancialInstTransit.value =
              newValue;
            break;
          case CONTACT_FIELD_KEY_NAMES.PROCESSING_CENTER_ID:
            additionalPaymentInfoSection.processingCenterId.value = newValue;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    priceListInfoUpatedState.current = { ...updatedState };
    setFormState({ ...updatedState });
  };

  const formToggleChanged = (
    newValue: boolean,
    fieldName: CONTACT_FIELD_KEY_NAMES
  ) => {
    const updatedState = formState;
    switch (fieldName) {
      case CONTACT_FIELD_KEY_NAMES.TAX_EXEMPT:
        updatedState.additonalInfo.taxExempted.isSelected = newValue;
        updatedState.accounting.exemptType.hidden = !newValue;
        updatedState.addressInfo.forEach((addressInfo) => {
          addressInfo.address.isMandatory = !newValue;
          addressInfo.postalCode.isMandatory = !newValue;
          addressInfo.state.isMandatory = !newValue;
          addressInfo.country.isMandatory = !newValue;
        });
        if (updatedState.accounting.exemptType.options) {
          updatedState.accounting.exemptType.value =
            updatedState.accounting.exemptType.options[0];
        }
        break;

      case CONTACT_FIELD_KEY_NAMES.CONTACT_SG_GOVT:
        if (newValue && updatedState.accounting.businessUnit.options) {
          updatedState.accounting.businessUnit.hidden = false;
          updatedState.accounting.attentionTo.hidden = false;
          updatedState.accounting.businessUnit.value =
            updatedState.accounting.businessUnit.options[0];
        } else {
          updatedState.additonalInfo.taxExempted.isSelected = false;
          updatedState.accounting.businessUnit.hidden = true;
          updatedState.accounting.attentionTo.hidden = true;
        }
        updatedState.additonalInfo.contactSingaporeGovt.isSelected = newValue;
        updatedState.accounting.attentionTo.isMandatory = newValue;
        updatedState.accounting.businessUnit.isMandatory = newValue;
        updatedState.accounting.attentionTo.isMandatory = newValue;
        updatedState.accounting.attentionTo.isMandatory = newValue;
        formState.generalInfo.email.isMandatory = newValue;
        break;

      case CONTACT_FIELD_KEY_NAMES.RESIDENT:
        updatedState.additonalInfo.resident.isSelected = newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.EXEMPTION_CERTIFICATE_MALAYSIA:
        updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected =
          newValue;
        let exemptionCriterialMalaysiaOptions: ReactOptionType[] = [];
        let customerTypeMalaysiaOptions: ReactOptionType[] = [];
        let vendorTypeMalaysiaOptions: ReactOptionType[] = [];
        exemptionCriterialMalaysia.forEach((customer) => {
          if (
            !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected
          ) {
            if (customer.key === NONE) {
              let option: ReactOptionType = {
                label: customer.value as string,
                value: customer.key as string
              };
              exemptionCriterialMalaysiaOptions.push(option);
            }
          } else {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            exemptionCriterialMalaysiaOptions.push(option);
          }
        });

        customerTypeMalaysia.forEach((customer) => {
          if (
            !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected
          ) {
            if (customer.key === NONE) {
              let option: ReactOptionType = {
                label: customer.value as string,
                value: customer.key as string
              };
              customerTypeMalaysiaOptions.push(option);
            }
          } else if (
            updatedState.additonalInfo.exemptionCertificateMalaysia
              .isSelected &&
            updatedState.accounting.exemptionCriterialMalaysia.value.value ===
              NONE
          ) {
            if (customer.key === NONE) {
              let option: ReactOptionType = {
                label: customer.value as string,
                value: customer.key as string
              };
              customerTypeMalaysiaOptions.push(option);
            }
          } else {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            customerTypeMalaysiaOptions.push(option);
          }
        });

        vendorTypeMalaysia.forEach((customer) => {
          if (
            !updatedState.additonalInfo.exemptionCertificateMalaysia.isSelected
          ) {
            if (customer.key === NONE) {
              let option: ReactOptionType = {
                label: customer.value as string,
                value: customer.key as string
              };
              vendorTypeMalaysiaOptions.push(option);
            }
          } else {
            let option: ReactOptionType = {
              label: customer.value as string,
              value: customer.key as string
            };
            vendorTypeMalaysiaOptions.push(option);
          }
        });

        updatedState.accounting = {
          ...updatedState.accounting,
          exemptionCriterialMalaysia: {
            ...updatedState.accounting.exemptionCriterialMalaysia,
            options: [...exemptionCriterialMalaysiaOptions],
            defaultValue: exemptionCriterialMalaysiaOptions[0],
            value: exemptionCriterialMalaysiaOptions[0]
          },
          customerTypeMalaysia: {
            ...updatedState.accounting.customerTypeMalaysia,
            options: [...customerTypeMalaysiaOptions],
            defaultValue: customerTypeMalaysiaOptions[0],
            value: customerTypeMalaysiaOptions[0]
          },
          vendorTypeMalaysia: {
            ...updatedState.accounting.vendorTypeMalaysia,
            options: [...vendorTypeMalaysiaOptions],
            defaultValue: vendorTypeMalaysiaOptions[0],
            value: vendorTypeMalaysiaOptions[0]
          }
        };
        break;
      case CONTACT_FIELD_KEY_NAMES.IS_TDS_APPLICABLE:
        updatedState.additonalInfo.isTDS.isSelected = newValue;
        updatedState.accounting.higherRate.hidden = !newValue;
        // if non deduction checked, hide higheRate text box
        if (updatedState.additonalInfo.lowerDeductionLimit.isSelected) {
          updatedState.accounting.higherRate.hidden =
            updatedState.additonalInfo.lowerDeductionLimit.isSelected;
        }
        break;
      case CONTACT_FIELD_KEY_NAMES.LOWER_DEDUCTION_APPLICABLE:
        updatedState.additonalInfo.lowerDeductionLimit.isSelected = newValue;
        updatedState.accounting.higherRate.hidden = newValue;
        updatedState.accounting.certificateNumber.hidden = !newValue;
        updatedState.additonalInfo.fromDate.hidden = !newValue;
        updatedState.additonalInfo.toDate.hidden = !newValue;
        updatedState.accounting.nonDeductionReasons.hidden = !newValue;
        updatedState.accounting.tdsRate.hidden = !newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.EXEMPTION_LIMIT:
        updatedState.additonalInfo.considerExemptLimit.isSelected = newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.WHT_APPLICABLE_ISRAEL:
        updatedState.accounting.whtApplicableIsrael.isSelected = newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.IS_INCLUDED_TAX_REPORTING_SOURCE_ISRAEL:
        updatedState.accounting.isIncludedTaxReportingAtSourceIsrael.isSelected =
          newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.IS_SELF_INVOICEING_ISRAEL:
        updatedState.accounting.isSelfInvoicingIsrael.isSelected = newValue;
        break;
      case CONTACT_FIELD_KEY_NAMES.ELIGIBLE:
        updatedState.additonalInfo.is1099Eligible.isSelected = newValue;
        break;
      default:
        break;
    }

    setFormState({ ...updatedState });
  };

  const formCalendarFieldChanged = (
    date: Date,
    fieldName: CONTACT_FIELD_KEY_NAMES
  ) => {
    const updatedState = formState;
    switch (fieldName) {
      case CONTACT_FIELD_KEY_NAMES.FROM_DATE:
        updatedState.additonalInfo.fromDate.date = Utility.formatDate(
          date,
          DATE_FORMAT.DD_MM_YYYY
        );
        updatedState.additonalInfo.fromDate.isOpen = false;
        break;
      case CONTACT_FIELD_KEY_NAMES.TO_DATE:
        updatedState.additonalInfo.toDate.date = Utility.formatDate(
          date,
          DATE_FORMAT.DD_MM_YYYY
        );
        updatedState.additonalInfo.toDate.isOpen = false;
        break;
      case CONTACT_FIELD_KEY_NAMES.TAX_CERTIFICATE_VALIDITY_DATE:
        updatedState.accounting.taxCertificateValidityDateIsrael.date =
          Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY);
        updatedState.accounting.taxCertificateValidityDateIsrael.isOpen = false;
        break;
      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const updateTenantFinanceSettings = (contact: any) => {
    let Payload: any = { ...defaultAccountValues.financeSettings };
    for (let [key, value] of Object.entries(futureAccountsApply)) {
      if (value) {
        switch (key) {
          case FUTURE_CONTACT_ACCOUNTS_APPLY.PAYABLE_ACCOUNT_CODE:
            Payload.payableAccountCode = contact?.payableAccountCode;
            break;
          case FUTURE_CONTACT_ACCOUNTS_APPLY.RECEIVABLE_ACCOUNT_CODE:
            Payload.receivableAccountCode = contact?.receivableAccountCode;
            break;
        }
      }
    }

    if (Object.keys(Payload).length) {
      ContactService.updateFinanceSettings(
        Payload,
        AuthService.getUserTenantID()
      )
        .then((res) => {
          dispatch(fetchTenantSettings(AuthService.getUserTenantID()));
        })
        .catch((error: any) => {
          console.error('error updating finance settings', error);
        });
    }
  };

  const createContact = () => {
    if (apiCallInProgress.current) {
      return;
    }
    const payload = makePayload();
    if (payload) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      apiCallInProgress.current = true;
      ContactService.createContact(payload)
        .then((res) => {
          updateTenantFinanceSettings(payload);
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS,
              data: res
            });
          }
          apiCallInProgress.current = false;

          if (canApplySalesPersonFilterOnContact.canApplyFilter) {
            const config: ContactAPIConfig = {
              ...ContactService?.apiConfig,
              SalesPerson:
                canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id
            };
            ContactService.apiConfig = config;
          }
          dispatch(fetchContactSummary());
          dispatch(fetchContacts());
          // showToast('Contact Created Successfully', TOAST_TYPE.SUCCESS);
        })
        .catch((err) => {
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          }
          apiCallInProgress.current = false;
          showToast('Contact Creation Failed', TOAST_TYPE.FAILURE);
        });
    }
  };

  const callUpdateAPI = (request: any) => {
    const contactDetails = deepClone(props.populateFormData);
    if (request && contactDetails) {
      const contactId: number = contactDetails.id;
      const payload: ContactPayload = {
        ...request,
        id: contactId,
        documentSequenceCode: contactDetails.documentSequenceCode,
        sequenceFormat: contactDetails.sequenceFormat,
        code: contactDetails.code,
        status: contactDetails.status
      };
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      apiCallInProgress.current = true;
      ContactService.updateContact(payload, contactId)
        .then((res) => {
          updateTenantFinanceSettings(payload);
          if (props.onSuccess) {
            props.onSuccess(res);
          }
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          }
          apiCallInProgress.current = false;
          dispatch(fetchContacts());
        })
        .catch((err) => {
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          }
          apiCallInProgress.current = false;
          showToast('Contact updation Failed', TOAST_TYPE.FAILURE);
        });
    }
  };

  const updateContact = async () => {
    if (apiCallInProgress.current) {
      return;
    }
    const contactPayload = makePayload();
    const contactDetails = props.populateFormData;
    if (contactPayload && contactDetails) {
      showAlert(
        'Confirm Update',
        'Changes in contact will not reflect on viewing existing documents. Do you want to save changes?',
        [
          {
            title: 'Cancel',
            className: 'bg-gray1 text-black-light',
            onClick: () => {
              return;
            }
          },
          {
            title: 'Proceed',
            className: 'bg-button text-white ml-2',
            onClick: () => {
              callUpdateAPI(contactPayload);
            }
          }
        ]
      );
    }
  };

  /**
   * Custom Numbering Format
   */
  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState = formState;
    updatedState.generalInfo.numberingFormat.value = selected.id;
    if (selected.manualMode) {
      updatedState.generalInfo.numberingFormat.defaultValue = selected.text;
    } else {
      updatedState.generalInfo.numberingFormat.defaultValue = undefined;
    }
    setAutoNumberingFormat({ ...selected });
    setFormState({ ...updatedState });
  };
  /**
   * Custom Numbering Format
   */

  const formValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(formState);
    // to track tab which is in error
    let activeTab: CONTACT_FORM_TAB = CONTACT_FORM_TAB.NONE;

    // name
    if (
      updatedState.generalInfo.name.isMandatory &&
      (!updatedState.generalInfo.name.value ||
        updatedState.generalInfo.name.value.toString().trim() === '')
    ) {
      updatedState.generalInfo.name.hasError = true;
      updatedState.generalInfo.name.errorMsg = 'Name is mandatory';
      formHasError = true;

      if (activeTab === CONTACT_FORM_TAB.NONE) {
        activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
      }
    }

    //email
    if (!updatedState.generalInfo.email.hidden) {
      if (
        (updatedState.generalInfo.email.isMandatory ||
          updatedState.generalInfo.email.value.toString().trim().length > 0) &&
        !Utility.isValidEmail(updatedState.generalInfo.email.value.toString())
      ) {
        updatedState.generalInfo.email.hasError = true;
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
        }
      }
    }

    //account
    if (!updatedState.accounting.payableAccount.hidden) {
      if (
        Utility.isEmpty(updatedState.accounting.payableAccount.value?.value)
      ) {
        updatedState.accounting.payableAccount.hasError = true;
        updatedState.accounting.payableAccount.errorMsg =
          'Payable Account Can not be Empty';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    if (!updatedState.accounting.receiveableAccount.hidden) {
      if (
        Utility.isEmpty(updatedState.accounting.receiveableAccount.value?.value)
      ) {
        updatedState.accounting.receiveableAccount.hasError = true;
        updatedState.accounting.receiveableAccount.errorMsg =
          'Receivable Account Can not be Empty';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    // panNumber
    if (!updatedState.generalInfo.panNumber.hidden) {
      const panRegex = new RegExp(PAN_REGEX_PATTERN);
      if (
        (updatedState.generalInfo.panNumber.isMandatory &&
          !panRegex.test(
            updatedState.generalInfo.panNumber.value.toString()
          )) ||
        (updatedState.generalInfo.panNumber.value &&
          !panRegex.test(updatedState.generalInfo.panNumber.value.toString()))
      ) {
        updatedState.generalInfo.panNumber.hasError = true;
        updatedState.generalInfo.panNumber.errorMsg =
          'Not a valid PAN/TAN number';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    // peppolId
    if (!updatedState.generalInfo.peppolID.hidden) {
      if (
        updatedState.generalInfo.peppolID.isMandatory &&
        !updatedState.generalInfo.peppolID.value
      ) {
        updatedState.generalInfo.peppolID.hasError = true;
        updatedState.generalInfo.peppolID.errorMsg = 'PeppolD is mandatory';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
        }
      }
    }

    // contact UEN
    if (!updatedState.generalInfo.contactUEN.hidden) {
      if (
        updatedState.generalInfo.contactUEN.isMandatory &&
        !updatedState.generalInfo.contactUEN.value
      ) {
        updatedState.generalInfo.contactUEN.hasError = true;
        updatedState.generalInfo.contactUEN.errorMsg =
          'Contact UEN is mandatory';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
        }
      }
    }

    // gstIN
    if (!updatedState.accounting.gstIn.hidden) {
      const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
      if (
        updatedState.accounting.gstIn.isMandatory &&
        !gstRegex.test(updatedState.accounting.gstIn.value.toString())
      ) {
        updatedState.accounting.gstIn.hasError = true;
        updatedState.accounting.gstIn.errorMsg = 'Not a valid GSTIN number';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    // credit Limit
    if (!updatedState.accounting.creditLimit.creditLimitInput.hidden) {
      if (
        updatedState.accounting.creditLimit.creditLimitInput.value &&
        !isNaN(
          Number(updatedState.accounting.creditLimit.creditLimitInput.value)
        ) &&
        Number(updatedState.accounting.creditLimit.creditLimitInput.value) < 0
      ) {
        updatedState.accounting.creditLimit.creditLimitInput.hasError = true;
        updatedState.accounting.creditLimit.creditLimitInput.errorMsg =
          'Credit Limit cannot be negative';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.ADVANCED;
        }
      }
    }

    //npwp number
    if (
      !updatedState.accounting.npwpNumber.hidden &&
      updatedState.accounting.npwpNumber.value
    ) {
      let num = updatedState.accounting.npwpNumber.value
        .toString()
        .replace(/[^0-9]/g, '');
      if (num.length !== 15) {
        updatedState.accounting.npwpNumber.hasError = true;
        updatedState.accounting.npwpNumber.errorMsg = 'Not a valid NPWP number';
        formHasError = true;
      }
    }

    //nik number
    if (
      !updatedState.accounting.nikNumber.hidden &&
      updatedState.accounting.nikNumber.value
    ) {
      const nikRegex = new RegExp(INDONESIA.NIK_NUMBER);
      if (!nikRegex.test(updatedState.accounting.nikNumber.value.toString())) {
        updatedState.accounting.nikNumber.hasError = true;
        updatedState.accounting.nikNumber.errorMsg = 'Not a valid NIK number';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    //state, postalcode , address
    updatedState.addressInfo.forEach((address) => {
      if (address.address.isMandatory && !address.address.value) {
        address.address.hasError = true;
        address.address.errorMsg = 'Address is Mandatory';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.ADDRESS_INFO;
        }
      }
      if (address.postalCode.isMandatory && !address.postalCode.value) {
        address.postalCode.hasError = true;
        address.postalCode.errorMsg = 'Postal Code is Mandatory';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.ADDRESS_INFO;
        }
      }
      if (address.state.isMandatory && !address.state.value.label) {
        address.state.hasError = true;
        address.state.errorMsg = 'State is Mandatory';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.ADDRESS_INFO;
        }
      }

      if (
        Utility.isNotEmpty(address.contactNumber.value) &&
        address.contactNumber.value?.toString()?.length > 15
      ) {
        address.contactNumber.hasError = true;
        address.contactNumber.errorMsg = 'Max length 15 digits';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.ADDRESS_INFO;
        }
      }

      if (address.customFields && address.customFields.length > 0) {
        let mandatoryAddressCF = address.customFields.filter(
          (cf: any) => cf.mandatory && Utility.isEmpty(cf.value)
        );
        if (mandatoryAddressCF && mandatoryAddressCF.length > 0) {
          formHasError = true;
        }
      }
    });

    // TDS validation
    if (updatedState.additonalInfo.isTDS.isSelected) {
      // vender TAN
      if (!updatedState.accounting.vendorTAN.value) {
        updatedState.accounting.vendorTAN.hasError = true;
        updatedState.accounting.vendorTAN.errorMsg =
          'Vendor TAN cannot be left empty';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }

      // Higher Rate
      if (
        updatedState.accounting.higherRate.value &&
        isNaN(Number(updatedState.accounting.higherRate.value))
      ) {
        updatedState.accounting.higherRate.hasError = true;
        updatedState.accounting.higherRate.errorMsg =
          'Higher rate is not a number';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }

      // if non deduction enabled
      if (updatedState.additonalInfo.lowerDeductionLimit.isSelected) {
        // certificate number or reference number
        if (!updatedState.accounting.certificateNumber.value) {
          updatedState.accounting.certificateNumber.hasError = true;
          updatedState.accounting.certificateNumber.errorMsg =
            'Certificate Number cannot be left empty';
          formHasError = true;

          if (activeTab === CONTACT_FORM_TAB.NONE) {
            activeTab = CONTACT_FORM_TAB.COMPLIANCE;
          }
        }

        // tds rate
        if (!updatedState.accounting.tdsRate.value) {
          updatedState.accounting.tdsRate.hasError = true;
          updatedState.accounting.tdsRate.errorMsg = 'TDS rate is mandatory';
          formHasError = true;
        }
        if (isNaN(Number(updatedState.accounting.tdsRate.value))) {
          updatedState.accounting.tdsRate.hasError = true;
          updatedState.accounting.tdsRate.errorMsg = 'TDS rate is not valid';
          formHasError = true;

          if (activeTab === CONTACT_FORM_TAB.NONE) {
            activeTab = CONTACT_FORM_TAB.COMPLIANCE;
          }
        }
      }
    }
    if (updatedState?.additonalInfo?.contactSingaporeGovt?.isSelected) {
      updatedState.accounting.attentionTo.hasError = Utility.isEmptyObject(
        updatedState?.accounting?.attentionTo.value
      );
      updatedState.accounting.businessUnit.hasError = Utility.isEmptyObject(
        updatedState?.accounting?.businessUnit.value
      );
      if (
        updatedState.accounting.attentionTo.hasError ||
        updatedState.accounting.businessUnit.hasError
      ) {
        formHasError = true;
        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.COMPLIANCE;
        }
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      updatedState.customFields
    );
    if (customFieldHasErrors) {
      formHasError = customFieldHasErrors;
      if (activeTab === CONTACT_FORM_TAB.NONE) {
        activeTab = CONTACT_FORM_TAB.ADVANCED;
      }
    }

    // UK Business Registration No
    if (!updatedState.generalInfo.ukBusinessRegistrationNo.hidden) {
      const gstRegex = new RegExp(UK_VAT_AND_BUSINESS_REG_NUMBER);
      if (
        !Utility.isEmpty(
          updatedState.generalInfo.ukBusinessRegistrationNo.value.toString()
        ) &&
        !gstRegex.test(
          updatedState.generalInfo.ukBusinessRegistrationNo.value.toString()
        )
      ) {
        updatedState.generalInfo.ukBusinessRegistrationNo.hasError = true;
        updatedState.generalInfo.ukBusinessRegistrationNo.errorMsg =
          'The business registration number must be less than 20 characters';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
        }
      }
    }

    // UK VAT Identification No
    if (!updatedState.generalInfo.ukVatIdentificationNo.hidden) {
      const gstRegex = new RegExp(UK_VAT_AND_BUSINESS_REG_NUMBER);
      if (
        !Utility.isEmpty(
          updatedState.generalInfo.ukVatIdentificationNo.value.toString()
        ) &&
        !gstRegex.test(
          updatedState.generalInfo.ukVatIdentificationNo.value.toString()
        )
      ) {
        updatedState.generalInfo.ukVatIdentificationNo.hasError = true;
        updatedState.generalInfo.ukVatIdentificationNo.errorMsg =
          'The VAT identification number must be less than 20 characters';
        formHasError = true;

        if (activeTab === CONTACT_FORM_TAB.NONE) {
          activeTab = CONTACT_FORM_TAB.GENERAL_INFO;
        }
      }
    }

    // Custom fields validation ends

    if (formHasError) {
      if (activeTab !== CONTACT_FORM_TAB.NONE) {
        updatedState.activeTab = activeTab;
      }
      setFormState({ ...updatedState });
      return false;
    }
    return true;
  };

  const complianceSpecificFields = (updatedState: NewContactState) => {
    const isGstRegistered: boolean = tenantInfo.gstRegistered;
    const isPeppolOptIn: boolean = tenantInfo.peppolOptIn;
    switch (countryCode) {
      case COUNTRY_CODES.IN:
        if (isGstRegistered) {
          updatedState.accounting.gstIn.hidden = false;
          updatedState.accounting.gstIn.isMandatory = true;
          updatedState.generalInfo.panNumber.hidden = false;
          updatedState.accounting.customerType.hidden = false;
          updatedState.accounting.vendorType.hidden = false;
          updatedState.accounting.gstTreatment.hidden = false;
          updatedState.addressInfo.forEach((addressInfo) => {
            addressInfo.state.isMandatory = true;
          });
        }
        updatedState.additonalInfo.resident.hidden = false;
        // TDS independent of GST check
        updatedState.additonalInfo.isTDS.hidden = false;
        break;

      case COUNTRY_CODES.US:
        updatedState.generalInfo.taxNumber.hidden = false;
        updatedState.additonalInfo.taxExempted.hidden = false;
        updatedState.additonalInfo.is1099Eligible.hidden = false;
        if (tenantInfo?.complianceEnabled) {
          updatedState.addressInfo.forEach((addressInfo) => {
            addressInfo.address.isMandatory = true;
            addressInfo.postalCode.isMandatory = true;
            addressInfo.state.isMandatory = true;
            addressInfo.country.isMandatory = true;
          });
        } else {
          updatedState.addressInfo.forEach((addressInfo) => {
            addressInfo.address.isMandatory = false;
            addressInfo.postalCode.isMandatory = false;
            addressInfo.state.isMandatory = false;
            addressInfo.country.isMandatory = false;
          });
        }
        break;

      case COUNTRY_CODES.SG:
        updatedState.generalInfo.taxNumber.hidden = false;
        updatedState.generalInfo.contactUEN.hidden = false;
        if (isPeppolOptIn) {
          updatedState.generalInfo.peppolID.hidden = false;
          updatedState.additonalInfo.contactSingaporeGovt.hidden = false;
          updatedState.accounting.attentionTo.isMandatory = true;
          updatedState.accounting.businessUnit.isMandatory = true;
        }
        break;

      case COUNTRY_CODES.ID:
        updatedState.additonalInfo.resident.hidden = false;
        updatedState.accounting.nikNumber.hidden = false;
        updatedState.accounting.nikNumber.isMandatory = false;
        updatedState.accounting.npwpNumber.hidden = false;
        updatedState.accounting.customerTaxType.hidden = false;
        updatedState.accounting.vendorTaxType.hidden = false;
        break;

      case COUNTRY_CODES.MY:
        const isPeppolOptInMy: boolean = alternateTenantDetails?.peppolOptIn
          ? alternateTenantDetails?.peppolOptIn
          : false;
        updatedState.accounting.customerTypeMalaysia.hidden = false;
        updatedState.accounting.vendorTypeMalaysia.hidden = false;
        updatedState.accounting.exemptionCriterialMalaysia.hidden = false;
        updatedState.additonalInfo.exemptionCertificateMalaysia.hidden = false;
        updatedState.accounting.businessRegistrationNumber.hidden = false;
        updatedState.accounting.salesTaxRegistrationNumber.hidden = false;
        updatedState.accounting.serviceTaxRegistrationNumber.hidden = false;
        updatedState.addressInfo.forEach((addressInfo) => {
          addressInfo.address.isMandatory = true;
          addressInfo.postalCode.isMandatory = true;
          addressInfo.state.isMandatory = true;
          addressInfo.country.isMandatory = true;
        });
        updatedState.generalInfo.contactUEN.hidden = false;
        if (isPeppolOptInMy) {
          updatedState.generalInfo.peppolID.hidden = false;
        }
        break;

      case COUNTRY_CODES.PH:
        break;

      case COUNTRY_CODES.NZ:
        break;

      case COUNTRY_CODES.DE:
        break;

      case COUNTRY_CODES.AE:
        updatedState.generalInfo.taxTreatmentUae.hidden = false;
        updatedState.generalInfo.vatRegistrationUae.hidden = false;
        break;
      case COUNTRY_CODES.UK:
        updatedState.generalInfo.ukBusinessRegistrationNo.hidden = false;
        updatedState.generalInfo.ukDefaultPurchaseTaxRate.hidden = false;
        updatedState.generalInfo.ukDefaultSalesTaxRate.hidden = false;
        updatedState.generalInfo.ukVatIdentificationNo.hidden = false;
        break;
      case COUNTRY_CODES.BE:
        break;
      case COUNTRY_CODES.AU:
        break;
      case COUNTRY_CODES.CA:
        break;
      case COUNTRY_CODES.IL:
        updatedState.accounting.taxCertificateValidityDateIsrael.hidden = false;
        updatedState.accounting.isIncludedTaxReportingAtSourceIsrael.hidden =
          false;
        updatedState.accounting.typeOfCompanyIsrael.hidden = false;
        updatedState.accounting.taxPayerIdIsrael.hidden = false;
        updatedState.accounting.withHoldingTaxPaymentGroupIsraelId.hidden =
          false;
        updatedState.accounting.withHoldingTaxTypeIsraelId.hidden = false;
        updatedState.accounting.isSelfInvoicingIsrael.hidden = false;
        updatedState.accounting.whtApplicableIsrael.hidden = false;
        updatedState.accounting.assessingOfficerNumberIsrael.hidden = false;
        updatedState.generalInfo.taxNumber.hidden = false;
        updatedState.accounting.withHoldingCertificatesIsrael.hidden = false;
        updatedState.accounting.israelBankDetails.hidden = false;
        updatedState.accounting.israelGlobalBankDetails.hidden = false;
        updatedState.generalInfo.purchaseTaxCodeIsrael.hidden = false;
        updatedState.generalInfo.salesTaxCodeIsrael.hidden = false;
        break;

      default:
        break;
    }
  };

  const makePayload = () => {
    const isFormValid = formValidation();
    if (!isFormValid) {
      return;
    }
    const billingAddress = formState.addressInfo.map((addressData, index) => {
      const contactName: string = addressData.contactName.value
        ? addressData.contactName.value.toString().trim()
        : '';
      const address: string = addressData.address.value
        ? addressData.address.value.toString().trim()
        : '';
      const address2: string = addressData.address2.value
        ? addressData.address2.value.toString().trim()
        : '';
      const postalCode: string = addressData.postalCode.value
        ? addressData.postalCode.value.toString().trim()
        : '';
      const mobileCountryCode: string = addressData.countryNumberOptions.value
        ? addressData.countryNumberOptions.value.dial_code.toString().trim()
        : '';
      const contactNumber = `${mobileCountryCode}${addressData.contactNumber.value
        .toString()
        .trim()}`;
      const mobileNumber: string = addressData.contactNumber.value
        ? contactNumber
        : '';
      const emailId: string = addressData.email.value
        ? addressData.email.value.toString().trim()
        : '';
      const customFields: any[] = addressData.customFields ?? [];
      return {
        ...Utility.generateAddress(
          contactName,
          address,
          address2,
          addressData.city.value && addressData.city.value.value
            ? addressData.city.value.value.toString()
            : '',
          addressData.state.value.label,
          addressData.country.value.label,
          postalCode,
          mobileCountryCode,
          mobileNumber,
          emailId
        ),
        countryCode: addressData?.country?.value.value ?? '',
        preferred: index === formState.activeBillingAddressIndex,
        customFields
      };
    });
    const shippingAddress = formState.addressInfo.map((addressData, index) => {
      const contactName: string = addressData.contactName.value
        ? addressData.contactName.value.toString().trim()
        : '';
      const address: string = addressData.address.value
        ? addressData.address.value.toString().trim()
        : '';
      const address2: string = addressData.address2.value
        ? addressData.address2.value.toString().trim()
        : '';
      const postalCode: string = addressData.postalCode.value
        ? addressData.postalCode.value.toString().trim()
        : '';
      const mobileCountryCode: string = addressData.countryNumberOptions.value
        ? addressData.countryNumberOptions.value.dial_code.toString().trim()
        : '';
      const contactNumber = `${mobileCountryCode}${addressData.contactNumber.value
        .toString()
        .trim()}`;
      const mobileNumber: string = addressData.contactNumber.value
        ? contactNumber
        : '';
      const emailId: string = addressData.email.value
        ? addressData.email.value.toString().trim()
        : '';
      const customFields: any[] = addressData.customFields ?? [];
      return {
        ...Utility.generateAddress(
          contactName,
          address,
          address2,
          addressData.city.value && addressData.city.value.value
            ? addressData.city.value.value.toString()
            : '',
          addressData.state.value.label,
          addressData.country.value.label,
          postalCode,
          mobileCountryCode,
          mobileNumber,
          emailId
        ),
        countryCode: addressData?.country?.value.value ?? '',
        preferred: index === formState.activeShippingAddressIndex,
        customFields
      };
    });

    const customFieldsList: CustomField[] = [];
    formState.customFields.forEach((customField: CustomField) => {
      const cf: CustomField = {
        label: customField.label,
        code: customField.code,
        module: MODULES_NAME.CONTACT,
        value: customField.value,
        customFieldIndex: customField.customFieldIndex,
        status: customField.status
      };
      customFieldsList.push(cf);
    });
    const contactNumber = `${
      formState.generalInfo.countryNumberOptions.value?.dial_code
    }${formState.generalInfo.contactNumber.value.toString().trim()}`;

    const getDefaultCustomerAndVendorType = (type: any) => {
      if (type !== undefined && type !== null) {
        let val = type.value;
        if (val !== undefined && val !== null) {
          if (val.value !== undefined && val.value !== null) {
            return val.value;
          }
        }
      }
      return NONE;
    };

    const payload: ContactPayload = {
      name: formState.generalInfo.name.value?.toString().trim(),
      emailId: formState.generalInfo.email.value.toString(),
      contactNumber: formState.generalInfo.contactNumber.value
        ? contactNumber
        : null,
      isCreditLimitApplicable:
        formState.accounting.creditLimit.showCreditLimitInfo,
      owe_them: 0,
      owe_you: 0,
      paymentTermCode: parseInt(formState.accounting.paymentTerm?.value?.value),
      receivableAccountCode: formState.accounting.receiveableAccount.value
        ? formState.accounting.receiveableAccount.value.value
        : '',
      payableAccountCode: formState.accounting.payableAccount.value
        ? formState.accounting.payableAccount.value.value
        : '',
      currencyCode: formState.generalInfo.currency.value.toString(),
      sequenceFormat: formState.generalInfo.numberingFormat.value?.toString(),
      customField: customFieldsList,
      shippingAddress,
      billingAddress,
      purchasePriceLists: null,
      salesPriceLists: null,
      documentSequenceCode:
        formState.generalInfo.numberingFormat.defaultValue?.toString(),
      intercompanyType:
        formState.generalInfo?.intercompanyType?.value?.value || null,
      otherEmails: alternativeEmails
    };

    if (isSalesPersonEnable && !Utility.isEmpty(agentsData?.content)) {
      let agentIds: number[] = formState.generalInfo.agent.value.map(
        (x: any) => x?.value
      );

      let salesPersons: any[] = agentsData?.content.filter((agent: any) =>
        agentIds.includes(agent?.id)
      );
      payload.salesPersonMaster = salesPersons;
    }

    // credit limit
    payload.creditLimit = Number(
      formState.accounting.creditLimit.creditLimitInput.value
    );
    payload.creditLimitInBaseCurrency =
      Number(formState.accounting.creditLimit.creditLimitInput.value) *
      Number(formState.exchangeRate);
    if (formState.accounting.creditLimit.showCreditLimitInfo) {
      payload.invoiceCreditLimitType =
        formState.accounting.creditLimit.invoiceCreditLimitType;
      payload.quotationCreditLimitType =
        formState.accounting.creditLimit.quoteCreditLimitType;
      payload.salesOrderCreditLimitType =
        formState.accounting.creditLimit.salesOrderCreditLimitType;
      payload.isIncludeCurrentInvoice =
        formState.accounting.creditLimit.invoiceSelected;
      payload.isIncludeCurrentQuotation =
        formState.accounting.creditLimit.quoteSelected;
      payload.isIncludeCurrentSalesOrder =
        formState.accounting.creditLimit.salesOrderSelected;
    }

    // price list
    // purchasePriceLists
    if (formState.accounting.purchasePriceLists.value) {
      payload.purchasePriceLists =
        formState.accounting.purchasePriceLists.value.map(
          (item: any) => item.productId
        );
    }

    // salesPrice
    if (formState.accounting.salesPriceLists.value) {
      payload.salesPriceLists = formState.accounting.salesPriceLists.value.map(
        (item: any) => item.productId
      );
    }

    // indian compliance
    if (countryCode === COUNTRY_CODES.IN) {
      const isGstRegistered: boolean = tenantInfo.gstRegistered;

      if (isGstRegistered) {
        payload.pan = formState.generalInfo.panNumber.value.toString();
        payload.customerType = formState.accounting.customerType.value.value;
        payload.vendorType = formState.accounting.vendorType.value.value;

        const gstTreatment = formState.accounting.gstTreatment.value.value;
        if (
          gstTreatment === 'REGISTERED_BUSINESS_REGULAR' ||
          gstTreatment === 'REGISTERED_BUSINESS_COMPOSITION' ||
          gstTreatment === 'SPECIAL_ECONOMIC_ZONE'
        ) {
          payload.gstin = formState.accounting.gstIn.value.toString();
        } else {
          payload.gstin = '';
        }

        payload.gstTreatment = gstTreatment;
      }

      payload.resident = formState.additonalInfo.resident.isSelected;

      if (formState.additonalInfo.isTDS.isSelected) {
        const deducteeCode = deducteeTypeList.find(
          (type) => type.id === formState.accounting.deducteeType.value.value
        );
        payload.tdsInfoIndia = {
          deductionApplicable: true,
          considerThresholdLimit:
            formState.additonalInfo.considerExemptLimit.isSelected,
          nonDeductionApplicable:
            formState.additonalInfo.lowerDeductionLimit.isSelected,
          higherRate: Number(formState.accounting.higherRate.value),
          tan: formState.accounting.vendorTAN.value.toString(),
          deducteeType: formState.accounting.deducteeType.value.label,
          deducteeCode: deducteeCode.code
        };

        if (payload.tdsInfoIndia.nonDeductionApplicable) {
          payload.tdsInfoIndia = {
            ...payload.tdsInfoIndia,
            noDeductionRefNo:
              formState.accounting.certificateNumber.value.toString(),
            noDeductionFromDate: formState.additonalInfo.fromDate.date,
            noDeductionToDate: formState.additonalInfo.toDate.date,
            noDeductionTdsRate: Number(formState.accounting.tdsRate.value),
            noDeductionReason:
              formState.accounting.nonDeductionReasons.value.value
          };
        }
      }
    }
    // Israel compliance
    if (countryCode === COUNTRY_CODES.IL) {
      payload.taxNumber = formState.generalInfo.taxNumber.value.toString();
      payload.taxPayerIdIsrael =
        formState.accounting.taxPayerIdIsrael.value.toString();
      if (formState.accounting.assessingOfficerNumberIsrael.value.value) {
        payload.assessingOfficerNumberIsrael =
          formState.accounting.assessingOfficerNumberIsrael.value.value;
      }
      payload.whtApplicableIsrael =
        formState.accounting?.whtApplicableIsrael?.isSelected;
      if (payload?.whtApplicableIsrael) {
        if (formState.accounting?.typeOfCompanyIsrael?.value?.value) {
          payload.typeOfCompanyIsrael =
            formState.accounting.typeOfCompanyIsrael.value.value;
        }
        payload.taxCertificateValidityDateIsrael =
          formState.accounting?.taxCertificateValidityDateIsrael?.date;

        payload.isIncludedTaxReportingAtSourceIsrael =
          formState.accounting.isIncludedTaxReportingAtSourceIsrael?.isSelected;
        payload.isSelfInvoicingIsrael =
          formState.accounting.isSelfInvoicingIsrael?.isSelected;
        payload.withHoldingTaxPaymentGroupIsraelId =
          formState.accounting.withHoldingTaxPaymentGroupIsraelId?.value?.value;
        payload.withHoldingTaxTypeIsraelId =
          formState.accounting.withHoldingTaxTypeIsraelId?.value?.value;
      }

      if (!payload.whtApplicableIsrael) {
        payload.taxCertificateValidityDateIsrael = '';
        payload.isIncludedTaxReportingAtSourceIsrael = false;
        payload.isSelfInvoicingIsrael = false;
        // payload.typeOfCompanyIsrael = '';
      }
      let ilCertificatesData = ilCertificates?.map((ele: any) => ({
        ...ele,
        validTo: DateFormatService.getDateStrFromDate(
          ele.validTo,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        validFrom: DateFormatService.getDateStrFromDate(
          ele.validFrom,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      }));
      payload.withHoldingCertificatesIsrael = ilCertificatesData;
      payload.israelBankDetails = iLLocalBankDetails;
      payload.israelGlobalBankDetails = iLGlobalBankDetails;
      payload.purchaseTaxCodeIsrael =
        formState.generalInfo.purchaseTaxCodeIsrael.value.value;
      payload.salesTaxCodeIsrael =
        formState.generalInfo.salesTaxCodeIsrael.value.value;
    }
    // us compliance
    if (countryCode === COUNTRY_CODES.US) {
      payload.taxNumber = formState.generalInfo.taxNumber.value.toString();
      payload.taxExempted = formState.additonalInfo.taxExempted.isSelected;
      payload.is1099Eligible =
        formState.additonalInfo.is1099Eligible.isSelected;
      payload.taxExemptionReason = payload.taxExempted
        ? formState.accounting.exemptType.value.label
        : null;

      if (Utility.isBankFileGenerationEnabled()) {
        payload.additionalPaymentInfo = {
          customerId:
            formState.additionalPaymentInfo.customerId.value
              ?.toString()
              ?.trim() || '',
          customerInternalId:
            formState.additionalPaymentInfo.customerInternalId.value,
          type: formState.additionalPaymentInfo.type.value?.value,
          bankAccountType:
            formState.additionalPaymentInfo.bankAccountType.value?.value,
          bankAccountNumber:
            formState.additionalPaymentInfo.bankAccountNumber.value,
          paymentFileFormat:
            formState.additionalPaymentInfo.paymentFileFormat.value?.value,
          countryCheck: formState.additionalPaymentInfo.countryCheck.value,
          bankCode: formState.additionalPaymentInfo.bankCode.value,
          processorCode: formState.additionalPaymentInfo.processorCode.value,
          receivingDfiId: formState.additionalPaymentInfo.receivingDfiId.value,
          checkDigit: formState.additionalPaymentInfo.checkDigit.value,
          clientNumber: formState.additionalPaymentInfo.clientNumber.value,
          txnCode: formState.additionalPaymentInfo.txnCode.value,
          cadFinancialInstTransit:
            formState.additionalPaymentInfo.cadFinancialInstTransit.value,
          processingCenterId:
            formState.additionalPaymentInfo.processingCenterId.value
        };
      }
    }

    // singapore compliance
    if (countryCode === COUNTRY_CODES.SG) {
      const isPeppolOptIn: boolean = tenantInfo.peppolOptIn;

      payload.taxNumber = formState.generalInfo.taxNumber.value.toString();
      payload.uen = formState.generalInfo.contactUEN.value.toString();
      if (isPeppolOptIn) {
        payload.peppolId = formState.generalInfo.peppolID.value.toString();
        payload.singaporeGovt =
          formState.additonalInfo.contactSingaporeGovt.isSelected;
        payload.businessUnit = formState.accounting.businessUnit.value.label
          .split('-')[0]
          .trim()
          .toString();
        payload.attentionTo = formState.accounting.attentionTo.value.toString();
      }
    }

    //indonesia compliance
    if (countryCode === COUNTRY_CODES.ID) {
      payload.taxTypeCustomer =
        formState.accounting.customerTaxType.value.value;
      payload.taxTypeVendor = formState.accounting.vendorTaxType.value.value;
      payload.nikNumber = formState.accounting.nikNumber.value
        ? formState.accounting.nikNumber.value.toString()
        : '';
      payload.npwpNumber = formState.accounting.npwpNumber.value
        ? formState.accounting.npwpNumber.value
            .toString()
            .replaceAll(/[^0-9]/g, '')
        : '';
      payload.residentStatusIndonesia =
        formState.additonalInfo.resident.isSelected;
    }

    //malaysia
    if (countryCode === COUNTRY_CODES.MY) {
      const isPeppolOptInMy: boolean = alternateTenantDetails?.peppolOptIn
        ? alternateTenantDetails?.peppolOptIn
        : false;
      payload.serviceTaxRegNo = formState.accounting
        .serviceTaxRegistrationNumber.value
        ? formState.accounting.serviceTaxRegistrationNumber.value.toString()
        : '';
      payload.salesTaxRegNo = formState.accounting.salesTaxRegistrationNumber
        .value
        ? formState.accounting.salesTaxRegistrationNumber.value.toString()
        : '';
      payload.businessRegNo = formState.accounting.businessRegistrationNumber
        .value
        ? formState.accounting.businessRegistrationNumber.value.toString()
        : '';
      payload.customerTypeMalaysia = getDefaultCustomerAndVendorType(
        formState.accounting.customerTypeMalaysia
      );
      payload.vendorTypeMalaysia = getDefaultCustomerAndVendorType(
        formState.accounting.vendorTypeMalaysia
      );
      payload.exemptionCriterialMalaysia =
        formState.accounting.exemptionCriterialMalaysia.value.value;
      payload.exemptionCertificateMalaysia =
        formState.additonalInfo.exemptionCertificateMalaysia.isSelected;
      payload.extCompanyName = '';

      payload.uen = formState.generalInfo.contactUEN.value.toString();
      if (isPeppolOptInMy) {
        payload.peppolId = formState.generalInfo.peppolID.value.toString();
      }
    }

    //united arab emirates(UAE)
    if (countryCode === COUNTRY_CODES.AE) {
      payload.taxTreatment = formState.generalInfo.taxTreatmentUae.value.label;
      payload.vatRegistrationNo =
        formState.generalInfo.vatRegistrationUae.value.toString();
    }

    //UK compliance
    if (countryCode === COUNTRY_CODES.UK) {
      payload.ukBusinessRegistrationNo =
        formState.generalInfo.ukBusinessRegistrationNo.value.toString();
      payload.ukDefaultPurchaseTaxRate =
        formState.generalInfo.ukDefaultPurchaseTaxRate.value.value;
      payload.ukDefaultSalesTaxRate =
        formState.generalInfo.ukDefaultSalesTaxRate.value.value;
      payload.ukVatIdentificationNo =
        formState.generalInfo.ukVatIdentificationNo.value.toString();
    }

    return payload;
  };

  const addNewAddress = () => {
    const updatedState = formState;
    const newAddress = deepClone(updatedState.addressInfo[0]);
    // based on tenant info
    const phoneNumberFormatBasedOnTenant = countryList.find(
      (country) => country.code === countryCode
    );
    newAddress.contactName.value = '';
    newAddress.address.value = '';
    newAddress.address2.value = '';
    newAddress.postalCode.value = '';
    newAddress.city.value = { label: '', value: '' };
    newAddress.state.value = { label: '', value: '' };
    newAddress.contactNumber.value = '';
    newAddress.countryNumberOptions.value =
      phoneNumberFormatBasedOnTenant ?? null;
    newAddress.email.value = '';
    updatedState.addressInfo.unshift(newAddress);
    updatedState.activeBillingAddressIndex =
      updatedState.activeBillingAddressIndex + 1;
    updatedState.activeShippingAddressIndex =
      updatedState.activeShippingAddressIndex + 1;
    setFormState({ ...updatedState });
  };

  const removeAddress = (index: number) => {
    const updatedState = formState;
    if (updatedState.addressInfo.length === 1) {
      return;
    }
    if (
      updatedState.activeBillingAddressIndex === index ||
      updatedState.activeShippingAddressIndex === index
    ) {
      updatedState.activeBillingAddressIndex = index - 1;
      updatedState.activeShippingAddressIndex = index - 1;
    }

    updatedState.addressInfo.splice(index, 1);
    if (updatedState.addressInfo.length === 1) {
      updatedState.activeBillingAddressIndex = 0;
      updatedState.activeShippingAddressIndex = 0;
    } else if (
      updatedState.activeBillingAddressIndex >=
        updatedState.addressInfo.length ||
      updatedState.activeShippingAddressIndex >= updatedState.addressInfo.length
    ) {
      updatedState.activeBillingAddressIndex =
        updatedState.addressInfo.length - 1;
      updatedState.activeShippingAddressIndex =
        updatedState.addressInfo.length - 1;
    }
    setFormState({ ...updatedState });
  };

  const setDefaultAddress = (index: number, addressType: string) => {
    const updatedState = formState;
    if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
      updatedState.activeBillingAddressIndex = index;
    } else {
      updatedState.activeShippingAddressIndex = index;
    }
    setFormState({ ...updatedState });
  };

  const customFieldUpdated = (cfList: CustomField[]) => {
    const updatedState = formState;

    updatedState.customFields = cfList;
    setTmpCustomField(cfList);
    setFormState({ ...updatedState });
  };

  const addressCustomFieldUpdated = (
    cfList: CustomField[],
    indexToUpdate: number
  ) => {
    const updatedState = formState;

    updatedState.addressInfo[indexToUpdate].customFields = cfList;
    setFormState({ ...updatedState });
  };

  const optionUpdated = (
    option: CREDIT_LIMIT_TYPE,
    type: CREDIT_LIMIT_DOC_TYPE
  ) => {
    const updatedState = formState;
    switch (type) {
      case CREDIT_LIMIT_DOC_TYPE.INVOICE:
        updatedState.accounting.creditLimit.invoiceCreditLimitType = option;
        break;
      case CREDIT_LIMIT_DOC_TYPE.QUOTE:
        updatedState.accounting.creditLimit.quoteCreditLimitType = option;
        break;
      case CREDIT_LIMIT_DOC_TYPE.SALES_ORDER:
        updatedState.accounting.creditLimit.salesOrderCreditLimitType = option;
        break;
      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const checkboxUpdated = (value: boolean, type: CREDIT_LIMIT_TYPE) => {
    const updatedState = formState;
    switch (type) {
      case CREDIT_LIMIT_TYPE.INVOICE:
        updatedState.accounting.creditLimit.invoiceSelected = value;
        break;
      case CREDIT_LIMIT_TYPE.QUOTE:
        updatedState.accounting.creditLimit.quoteSelected = value;
        break;
      case CREDIT_LIMIT_TYPE.SALES_ORDER:
        updatedState.accounting.creditLimit.salesOrderSelected = value;
        break;
      case CREDIT_LIMIT_TYPE.CREDIT_LIMIT_INFO:
        updatedState.accounting.creditLimit.showCreditLimitInfo = value;
        break;
    }
    setFormState({ ...updatedState });
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        const updatedState = formState;
        updatedState.deducteeInfo.showPopup = false;
        updatedState.paymentInfo.showPopup = false;
        setFormState({ ...updatedState });
        break;
      case POPUP_CLICK_TYPE.CREATE_DEDUCTEE_TYPE:
        addContactRef.current?.storeCallbacksRef.updateContactRemainderStatus();
        break;
      case POPUP_CLICK_TYPE.ADD_PAYMENT_TERMS:
        addContactRef.current.storeCallbacksRef.createPaymentTerm();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const updatedState = formState;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        updatedState.deducteeInfo.showPopup = false;
        updatedState.paymentInfo.showPopup = false;
        setFormState({ ...updatedState });
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_DEDUCTEE_TYPE:
        addContactRef.current.storeCallbacksRef.updateContactRemainderStatus =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCTEE_CREATE_SUCCESS:
        updatedState.deducteeInfo.showPopup = false;
        const option: ReactOptionType = {
          label: passingData.data.type,
          value: passingData.data.code
        };
        updatedState.accounting.deducteeType.value = option;
        setFormState({ ...updatedState });
        break;
      case POPUP_CALLBACKS_TYPE.PAYMENT_TERM_SUCCESS:
        const paymentOption: ReactOptionType = {
          label: passingData.data.termName,
          value: passingData.data.id
        };
        updatedState.accounting.paymentTerm.value = paymentOption;
        updatedState.accounting.paymentTerm.options?.push(paymentOption);
        setFormState({ ...updatedState });
        break;
      case POPUP_CALLBACKS_TYPE.ADD_PAYMENT_TERMS:
        addContactRef.current.storeCallbacksRef.createPaymentTerm =
          passingData.data;
        break;
    }
  };

  const getAmountBlockInBaseCurrency = () => {
    return (
      !formState.accounting.creditLimit.creditLimitInput.hidden &&
      !formState.accounting.creditLimit.creditLimitInput.hasError &&
      formState.accounting.creditLimit.showConversionRate &&
      formState.accounting.creditLimit.creditLimitInput.value && (
        <div style={{ fontSize: 12 }}>
          Base currency({tenantInfo?.currency}):{' '}
          {NumberFormatService.getNumber(
            (formState.accounting.creditLimit.creditLimitInput
              .value as number) * (+formState.exchangeRate as number)
          )}
        </div>
      )
    );
  };

  const priceListUpdated = (
    options: number[],
    priceList: PRICE_LIMIT_CONTACT_FORM
  ) => {
    const updatedState = formState;
    switch (priceList) {
      case PRICE_LIMIT_CONTACT_FORM.PURCHASE_LIST:
        updatedState.accounting.purchasePriceLists.value = options;
        break;
      case PRICE_LIMIT_CONTACT_FORM.SALES_LIST:
        updatedState.accounting.salesPriceLists.value = options;
        break;
      default:
        break;
    }
    setFormState({ ...updatedState });
  };

  const fetchPriceListInfo = () => {
    let updatedState: any = { ...priceListInfoUpatedState.current };
    fetchPriceInfo({ ...updatedState });
  };

  const fetchPriceInfo = (updatedState: NewContactState) => {
    if (props.populateFormData?.code) {
      const contactCode: string = props.populateFormData.code;
      PriceListService.fetchPriceListForContact(contactCode)
        .then((res) => {
          const response: any = res;
          const purchasePriceLists: number[] = response['purchasePriceLists'];
          const salesPriceLists: number[] = response['salesPriceLists'];
          const idArray = [...purchasePriceLists, ...salesPriceLists];
          const purchasePlValue: any = [];
          const salesPlValue: any = [];

          PriceListService.getPriceListById(idArray)
            .then((res: any) => {
              purchasePriceLists.forEach((id: any) => {
                res.forEach((item: any) => {
                  if (id === item.id) {
                    updatedState.accounting.purchasePriceLists.value.push({
                      productId: item.id,
                      productName: item.name
                    });
                  }
                });
              });
              salesPriceLists.forEach((id: any) => {
                res.forEach((item: any) => {
                  if (id === item.id) {
                    updatedState.accounting.salesPriceLists.value.push({
                      productId: item.id,
                      productName: item.name
                    });
                  }
                });
              });
            })
            .catch((err: any) => console.log(err))
            .finally(() => {
              setFormState((prev) => {
                return { ...prev, ...updatedState };
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const changeActiveTab = (tab: CONTACT_FORM_TAB) => {
    const updatedState = formState;
    updatedState.activeTab = tab;
    let _sequenceFormat = { ...autoNumberingFormat };
    _sequenceFormat.id = updatedState.generalInfo.numberingFormat.value;
    _sequenceFormat.text =
      updatedState.generalInfo.numberingFormat.defaultValue;
    setAutoNumberingFormat({ ..._sequenceFormat });
    setFormState({ ...updatedState });
  };

  return (
    <div className="parent-width">
      {getHeaderSegment()}
      <div
        className="column p-h-l p-v-r parent-width"
        style={{ pointerEvents: props.readOnly ? 'none' : 'auto' }}
      >
        {formState.activeTab === CONTACT_FORM_TAB.GENERAL_INFO &&
          generalInfoForm()}
        {formState.activeTab === CONTACT_FORM_TAB.COMPLIANCE &&
          complianceInfoForm()}
        {formState.activeTab === CONTACT_FORM_TAB.ADVANCED &&
          advancedInfoForm()}
        {formState.activeTab === CONTACT_FORM_TAB.ADDRESS_INFO &&
          addressInfoForm()}
        {formState.activeTab === CONTACT_FORM_TAB.PAYMENT_INFO &&
          paymentInfoForm()}
      </div>

      {formState.deducteeInfo.showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add Deductee Type'}
          btnList={popupBtnConfigForDeducteeType}
          width={'30%'}
          height={'40%'}
          disableClickOutside={true}
        >
          <DeducteePopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
      {showMultiPurchasePL && (
        <MultiPriceListPopup
          title="Select Purchase Price List"
          selectedProductsIds={formState.accounting.purchasePriceLists.value}
          onSave={(selectedProducts: any) => {
            setMultiPlData(selectedProducts);
            setShowMultiPurchasePL(false);
            const updatedState = formState;
            updatedState.accounting.purchasePriceLists.value = selectedProducts;
            setFormState({ ...updatedState });
          }}
          type={'BUY'}
          onClose={() => {
            setShowMultiPurchasePL(false);
          }}
        />
      )}
      {showMultiSalesPL && (
        <MultiPriceListPopup
          title="Select Sales Price List"
          selectedProductsIds={formState.accounting.salesPriceLists.value}
          onSave={(selectedProducts: any) => {
            const updatedState = formState;
            updatedState.accounting.salesPriceLists.value = selectedProducts;
            setFormState({ ...updatedState });
            setMultiPlSalesData(selectedProducts);
            setShowMultiSalesPL(false);
          }}
          type={'SELL'}
          onClose={() => {
            setShowMultiSalesPL(false);
          }}
        />
      )}

      {formState.paymentInfo.showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add Payment Term'}
          btnList={popupBtnConfigForAddPaymentTerm}
          width={'30%'}
          height={'40%'}
          disableClickOutside={true}
        >
          <AddPaymentTermsPopUp
            populateFormData={null}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default AddContact;
