import {
  DKButton,
  DKIcons,
  DKInput,
  DKLabel,
  showToast,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  Toggle
} from 'deskera-ui-library';
import { useState } from 'react';
import { FIELD_TYPE, LabelCellItem, getValue } from './LabelTemplateHelper';
import { uploadFileToAWS } from '../ImportExport/utility/file';
import { TOAST_TYPES } from '../../Constants/Constant';

interface EditableCellProps {
  data: LabelCellItem;
  updateData: Function;
  deleteItem: Function;
  isReordering: Boolean;
}

const EditLabelCell: React.FC<EditableCellProps> = ({
  data,
  updateData,
  deleteItem,
  isReordering
}) => {
  const [expanded, setExpanded] = useState(false);
  const onUpdate = (updatedData: any) => {
    updateData({ ...data, ...updatedData });
  };

  const isImageField = data?.type === FIELD_TYPE.IMAGE;

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'false');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (target?.files) {
        const files = Array.from(target.files);
        files.forEach(async (file) => {
          try {
            const responseData = await uploadFileToAWS(
              file,
              'WORK_ORDER_LABEL_PRINTTING'
            );
            onUpdate({ value: responseData });
          } catch (error) {
            console.error('Error uploading image:', error);
            showToast('Failed to upload the image.', TOAST_TYPES.FAILURE);
          }
        });
      }
    });
    input.click();
  };

  const renderValueInput = () => {
    if (!data?.type) return null;

    if ([FIELD_TYPE.IMAGE].includes(data.type)) {
      return (
        <>
          <div className="flex flex-col gap-2">
            <div className="column parent-width mt-2">
              <DKButton
                title="+ Add Image"
                icon={DKIcons.ic_attachment}
                className="text-blue mt-r fw-m"
                onClick={triggerAttachmentUpload}
                isReverse={true}
              />
            </div>
          </div>
          <div className="row justify-content-start flex-wrap gap-2">
            <div className="image-preview">
              <img
                src={data.value}
                style={{
                  maxWidth: '100%',
                  maxHeight: '80px',
                  objectFit: 'contain',
                  borderRadius: '4px'
                }}
              />
            </div>
          </div>
        </>
      );
    }

    if ([FIELD_TYPE.SELECT_BARCODE_QR_CODE].includes(data.type)) {
      let selectedObjects = [];
      const keys = new Set(
        data?.valueInternal?.split(',').map((key: any) => key.trim()) || []
      );
      selectedObjects =
        data.options?.filter((item) => keys.has(item.key)) || [];

      const keyOrder =
        data?.valueInternal?.split(',').map((key: any) => key.trim()) || [];
      selectedObjects.sort(
        (a, b) => keyOrder.indexOf(a.key) - keyOrder.indexOf(b.key)
      );

      return (
        <>
          <div className="flex gap-2 parent-width">
            <DKInput
              required={false}
              title={'Components'}
              displayKey={'title'}
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(selectedIndexes: any[]) => {}}
              value={selectedObjects}
              dropdownConfig={{
                onSelect(index: number, value: any) {
                  const currentValue =
                    new Set(data?.valueInternal?.split(',')) ?? new Set();
                  if (currentValue.has(value.key)) {
                    currentValue.delete(value.key);
                  } else {
                    currentValue.add(value.key);
                  }
          
                  const optionNames = data.options
                  ?.filter((item) => currentValue.has(item.key))
                  .map((item) => item.title) || [];
                
                 const finalValue = `[${optionNames.join(' + ')}]`;
                  onUpdate({
                    valueInternal: Array.from(currentValue).join(','),
                    value: finalValue
                  });
                },
                data: data.options?.map((item) => {
                  return {
                    ...item,
                    id: item.key
                  };
                }),
                allowSearch: true,
                checkMarkColor: 'bg-blue',
                multiSelect: true,
                selectedIds: data?.valueInternal?.split(',') ?? [],
                selectedIndexes: [],
                searchableKey: 'title',
                title: 'Select option',
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.title} />;
                }
              }}
            />
          </div>

          {data.type !== FIELD_TYPE.ADDRESS &&
            data.type !== FIELD_TYPE.DATE &&
            data.type !== FIELD_TYPE.IMAGE && (
              <div className="flex parent-width gap-2 mb-1">
                <div className="flex-1">
                  <DKLabel
                    className="text-sm text-gray-500 font-medium"
                    style={{ marginTop: 2 }}
                    text="Show QR Code"
                  />
                  <Toggle
                    className="box-content"
                    onChange={(checked: any) =>
                      onUpdate({ showQRCode: !data.showQRCode })
                    }
                    isOn={data.showQRCode}
                  />
                </div>
              </div>
            )}

        </>
      );
    }

    const isReadOnly =
      data.type !== FIELD_TYPE.CUSTOM_TEXT && data.type !== FIELD_TYPE.IMAGE;

    return (
      <DKInput
        titleStyle={{ color: 'gray', fontSize: 12 }}
        className="mb-1"
        value={getValue(data)}
        title="Value"
        type={INPUT_TYPE.TEXT}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        canValidate={false}
        onChange={(value: any) => onUpdate({ value })}
        readOnly={isReadOnly}
      />
    );
  };

  return (
    <div
      className="bg-white border-radius-s border-m px-2 mt-1 column border-box shadow-s mobile-full-grid-width-2"
      style={{
        minWidth: 250,
        marginRight: 1,
        position: 'relative'
      }}
    >
      <div
        className="flex align-items-center parent-width"
        onClick={() => !isReordering && setExpanded((value) => !value)}
      >
        <div className="flex-1">
          <DKLabel text={data.title} className="fw-m fs-m" />
        </div>
        <div
          className="inline-flex"
          style={{ visibility: isReordering ? 'hidden' : 'visible' }}
        >
          <DKButton
            icon={DKIcons.ic_delete}
            className=""
            onClick={deleteItem}
          />
          <DKButton
            icon={expanded ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
            className=""
            onClick={(e: Event) => e.preventDefault()}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12 }}
            className="mb-1"
            value={data.label}
            title="Label"
            type={INPUT_TYPE.TEXT}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => onUpdate({ label: value })}
          />
          {renderValueInput()}

          <div className="flex flex-col parent-width mb-4">
            {/* Styles Section */}
            <div className="flex justify-between items-center my-1">
              <span className="text-gray-700 font-bold">Styles</span>
            </div>
            <div className="flex gap-2">
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                value={data.styleAlignment}
                title="Alignment"
                required={false}
                type={INPUT_TYPE.DROPDOWN}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                dropdownConfig={{
                  data: ['Left', 'Center', 'Right']
                }}
                onChange={(value: any) => onUpdate({ styleAlignment: value })}
              />
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className="parent-width"
                value={data.direction}
                formatter={(obj: any) => obj}
                title="Direction"
                type={INPUT_TYPE.DROPDOWN}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                canValidate={false}
                onChange={(value: any) => onUpdate({ direction: value })}
                dropdownConfig={{
                  data: ['Vertical', 'Horizontal'],
                  style: { minWidth: 250 },
                  className: 'shadow-m width-auto',
                  renderer: (index: any, obj: any) => (
                    <DKLabel key={index} text={`${obj}`} />
                  )
                }}
              />
            </div>

            {/* Label Section */}
            <div className="flex justify-between items-center my-1">
              <span className="text-gray-700 font-bold">Label</span>
            </div>
            <div className="flex gap-2">
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                value={data.labelFontSize}
                required={false}
                title="Font Size"
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                onChange={(value: any) => onUpdate({ labelFontSize: value })}
              />
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                value={data.labelStyle}
                required={false}
                title="Text Style"
                type={INPUT_TYPE.DROPDOWN}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                dropdownConfig={{
                  data: ['Normal', 'Bold', 'Italic', 'Underline']
                }}
                onChange={(value: any) => onUpdate({ labelStyle: value })}
              />
            </div>

            {/* Value Section */}
            {isImageField ? (
              <></>
            ) : (
              <>
                <div className="flex justify-between items-center my-1">
                  <span className="text-gray-700 font-bold">Value</span>
                </div>
                <div className="flex gap-2">
                  <DKInput
                    titleStyle={{ color: 'gray', fontSize: 12 }}
                    value={data.valueFontSize}
                    required={false}
                    title="Font Size"
                    type={INPUT_TYPE.TEXT}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: any) =>
                      onUpdate({ valueFontSize: value })
                    }
                  />
                  <DKInput
                    titleStyle={{ color: 'gray', fontSize: 12 }}
                    value={data.valueStyle}
                    required={false}
                    title="Text Style"
                    type={INPUT_TYPE.DROPDOWN}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    dropdownConfig={{
                      data: ['Normal', 'Bold', 'Italic', 'Underline']
                    }}
                    onChange={(value: any) => onUpdate({ valueStyle: value })}
                  />
                </div>
              </>
            )}
          </div>

          {data.type !== FIELD_TYPE.ADDRESS &&
            data.type !== FIELD_TYPE.DATE &&
            data.type !== FIELD_TYPE.IMAGE && data.type !== FIELD_TYPE.SELECT_BARCODE_QR_CODE && (
              <div className="flex parent-width gap-2 mb-1">
                <div className="flex-1">
                  <DKLabel
                    className="text-sm text-gray-500 font-medium"
                    style={{ marginTop: 2 }}
                    text="Show Barcode"
                  />
                  <Toggle
                    className="box-content"
                    onChange={(checked: any) =>
                      onUpdate({ showBarcode: !data.showBarcode })
                    }
                    isOn={data.showBarcode}
                  />
                </div>
                {data.showBarcode && (
                  <div className="flex-1">
                    <DKInput
                      titleStyle={{ color: 'gray', fontSize: 12 }}
                      className={'parent-width'}
                      value={data.barcodePosition}
                      formatter={(obj: any) => {
                        return obj;
                      }}
                      title="Barcode Position"
                      type={INPUT_TYPE.DROPDOWN}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      required={false}
                      canValidate={false}
                      onChange={(value: any) =>
                        onUpdate({ barcodePosition: value })
                      }
                      dropdownConfig={{
                        title: '',
                        allowSearch: false,
                        searchableKey: '',
                        style: { minWidth: 250 },
                        className: 'shadow-m width-auto',
                        searchApiConfig: null,
                        data: ['Top', 'Bottom'],
                        renderer: (index: any, obj: any) => {
                          return <DKLabel key={index} text={`${obj}`} />;
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}

          <div className="flex parent-width gap-2 mb-1">
            <div className="flex-1">
              <DKLabel
                className="text-sm text-gray-500 font-medium"
                style={{ marginTop: 2 }}
                text="Fixed Position"
              />
              <Toggle
                className="box-content"
                onChange={(checked: any) =>
                  onUpdate({ fixedPosition: !data.fixedPosition })
                }
                isOn={data.fixedPosition}
              />
            </div>
            <div className="flex-1"></div>
          </div>
          {data.fixedPosition && (
            <div className="flex parent-width gap-2 mb-1">
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={''}
                value={data.leftMargin}
                title="Left Margin"
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                canValidate={false}
                onChange={(value: any) => onUpdate({ leftMargin: value })}
              />
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={''}
                value={data.topMargin}
                title="Top Margin"
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                canValidate={false}
                onChange={(value: any) => onUpdate({ topMargin: value })}
              />
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditLabelCell;
