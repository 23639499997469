export interface FormulaFunction {
  name: string;
  description: string;
  parameters: {
    name: string;
    description: string;
    type: string;
    required: boolean;
  }[];
  example: string;
  result: string;
}

export const MATH_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'ABS',
    description: 'Returns the absolute value of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ABS(-4)',
    result: '4'
  },
  {
    name: 'ACOS',
    description: 'Returns the arccosine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ACOS(-0.5)',
    result: '2.0943951023931957'
  },
  {
    name: 'ACOSH',
    description: 'Returns the inverse hyperbolic cosine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ACOSH(10)',
    result: '2.993222846126381'
  },
  {
    name: 'ACOT',
    description: 'Returns the arccotangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ACOT(2)',
    result: '0.46364760900080615'
  },
  {
    name: 'ACOTH',
    description: 'Returns the hyperbolic arccotangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ACOTH(6)',
    result: '0.16823611831060645'
  },
  {
    name: 'AGGREGATE',
    description: 'Returns an aggregate in a list or database',
    parameters: [
      {
        name: 'function_num',
        description: 'The function number',
        type: 'number',
        required: true
      },
      {
        name: 'options',
        description: 'Options for the aggregate',
        type: 'number',
        required: true
      },
      {
        name: 'ref1',
        description: 'First reference',
        type: 'any',
        required: true
      },
      {
        name: 'ref2',
        description: 'Second reference',
        type: 'any',
        required: false
      }
    ],
    example: "AGGREGATE(9, 4, [-5,15], [32,'Hello World!'])",
    result: '10,32'
  },
  {
    name: 'ARABIC',
    description: 'Converts a Roman numeral to an Arabic numeral',
    parameters: [
      {
        name: 'roman',
        description: 'The Roman numeral',
        type: 'string',
        required: true
      }
    ],
    example: "ARABIC('MCMXII')",
    result: '1912'
  },
  {
    name: 'ASIN',
    description: 'Returns the arcsine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ASIN(-0.5)',
    result: '-0.5235987755982988'
  },
  {
    name: 'ASINH',
    description: 'Returns the inverse hyperbolic sine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ASINH(-2.5)',
    result: '-1.6472311463710965'
  },
  {
    name: 'ATAN',
    description: 'Returns the arctangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ATAN(1)',
    result: '0.7853981633974483'
  },
  {
    name: 'ATAN2',
    description: 'Returns the arctangent of the quotient of its arguments',
    parameters: [
      {
        name: 'y',
        description: 'The y-coordinate',
        type: 'number',
        required: true
      },
      {
        name: 'x',
        description: 'The x-coordinate',
        type: 'number',
        required: true
      }
    ],
    example: 'ATAN2(-1, -1)',
    result: '-2.356194490192345'
  },
  {
    name: 'ATANH',
    description: 'Returns the inverse hyperbolic tangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ATANH(-0.1)',
    result: '-0.10033534773107562'
  },
  // {
  //   name: 'BASE',
  //   description:
  //     'Converts a number into a text representation with a given base',
  //   parameters: [
  //     {
  //       name: 'number',
  //       description: 'The number to convert',
  //       type: 'number',
  //       required: true
  //     },
  //     {
  //       name: 'base',
  //       description: 'The base to use',
  //       type: 'number',
  //       required: true
  //     },
  //     {
  //       name: 'min_length',
  //       description: 'Minimum length of the result',
  //       type: 'number',
  //       required: false
  //     }
  //   ],
  //   example: 'BASE(15, 2, 10)',
  //   result: '0000001111'
  // },
  {
    name: 'CEILING',
    description:
      'Rounds a number up to the nearest integer or specified multiple',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round up to',
        type: 'number',
        required: false
      }
    ],
    example: 'CEILING(-5.5, 2, -1)',
    result: '-6'
  },
  {
    name: 'CEILINGMATH',
    description:
      'Rounds a number up to the nearest integer or specified multiple',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round up to',
        type: 'number',
        required: false
      }
    ],
    example: 'CEILINGMATH(-5.5, 2, -1)',
    result: '-6'
  },
  {
    name: 'CEILINGPRECISE',
    description:
      'Rounds a number up to the nearest integer or specified multiple, ignoring the sign',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round up to',
        type: 'number',
        required: false
      }
    ],
    example: 'CEILINGPRECISE(-4.1, -2)',
    result: '-4'
  },
  {
    name: 'COMBIN',
    description:
      'Returns the number of combinations for a given number of objects',
    parameters: [
      {
        name: 'n',
        description: 'Total number of objects',
        type: 'number',
        required: true
      },
      {
        name: 'k',
        description: 'Number of objects to choose',
        type: 'number',
        required: true
      }
    ],
    example: 'COMBIN(8, 2)',
    result: '28'
  },
  {
    name: 'COMBINA',
    description:
      'Returns the number of combinations with repetitions for a given number of objects',
    parameters: [
      {
        name: 'n',
        description: 'Total number of objects',
        type: 'number',
        required: true
      },
      {
        name: 'k',
        description: 'Number of objects to choose',
        type: 'number',
        required: true
      }
    ],
    example: 'COMBINA(4, 3)',
    result: '20'
  },
  {
    name: 'COS',
    description: 'Returns the cosine of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'COS(1)',
    result: '0.5403023058681398'
  },
  {
    name: 'COSH',
    description: 'Returns the hyperbolic cosine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'COSH(1)',
    result: '1.5430806348152437'
  },
  {
    name: 'COT',
    description: 'Returns the cotangent of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'COT(30)',
    result: '-0.15611995216165922'
  },
  {
    name: 'COTH',
    description: 'Returns the hyperbolic cotangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'COTH(2)',
    result: '1.0373147207275482'
  },
  {
    name: 'CSC',
    description: 'Returns the cosecant of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'CSC(15)',
    result: '1.5377805615408537'
  },
  {
    name: 'CSCH',
    description: 'Returns the hyperbolic cosecant of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'CSCH(1.5)',
    result: '0.46964244059522464'
  },
  {
    name: 'DECIMAL',
    description:
      'Converts text representation of a number in a given base to a decimal number',
    parameters: [
      {
        name: 'text',
        description: 'The text representation',
        type: 'string',
        required: true
      },
      {
        name: 'base',
        description: 'The base to convert from',
        type: 'number',
        required: true
      }
    ],
    example: "DECIMAL('FF', 16)",
    result: '255'
  },
  {
    name: 'ERF',
    description: 'Returns the error function',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ERF(1)',
    result: '0.8427007929497149'
  },
  {
    name: 'ERFC',
    description: 'Returns the complementary error function',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ERFC(1)',
    result: '0.1572992070502851'
  },
  {
    name: 'EVEN',
    description: 'Rounds a number up to the nearest even integer',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'EVEN(-1)',
    result: '-2'
  },
  {
    name: 'EXP',
    description: 'Returns e raised to the power of a given number',
    parameters: [
      {
        name: 'number',
        description: 'The exponent',
        type: 'number',
        required: true
      }
    ],
    example: 'EXP(1)',
    result: '2.718281828459045'
  },
  {
    name: 'FACT',
    description: 'Returns the factorial of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'FACT(5)',
    result: '120'
  },
  {
    name: 'FACTDOUBLE',
    description: 'Returns the double factorial of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'FACTDOUBLE(7)',
    result: '105'
  },
  {
    name: 'FLOOR',
    description: 'Rounds a number down to the nearest integer',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'FLOOR(-3.1)',
    result: '-4'
  },
  {
    name: 'FLOORMATH',
    description:
      'Rounds a number down to the nearest integer or specified multiple',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round down to',
        type: 'number',
        required: false
      }
    ],
    example: 'FLOORMATH(-4.1, -2, -1)',
    result: '-4'
  },
  {
    name: 'FLOORPRECISE',
    description:
      'Rounds a number down to the nearest integer or specified multiple, ignoring the sign',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round down to',
        type: 'number',
        required: false
      }
    ],
    example: 'FLOORPRECISE(-3.1, -2)',
    result: '-4'
  },
  {
    name: 'GCD',
    description: 'Returns the greatest common divisor of two or more numbers',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'GCD(24, 36, 48)',
    result: '12'
  },
  {
    name: 'INT',
    description: 'Rounds a number down to the nearest integer',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'INT(-8.9)',
    result: '-9'
  },
  // {
  //   name: 'ISEVEN',
  //   description: 'Returns TRUE if the number is even, FALSE otherwise',
  //   parameters: [
  //     {
  //       name: 'number',
  //       description: 'The number',
  //       type: 'number',
  //       required: true
  //     }
  //   ],
  //   example: 'ISEVEN(-2.5)',
  //   result: 'true'
  // },
  {
    name: 'ISOCEILING',
    description:
      'Rounds a number up to the nearest integer or specified multiple, ignoring the sign',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'significance',
        description: 'The multiple to round up to',
        type: 'number',
        required: false
      }
    ],
    example: 'ISOCEILING(-4.1, -2)',
    result: '-4'
  },
  // {
  //   name: 'ISODD',
  //   description: 'Returns TRUE if the number is odd, FALSE otherwise',
  //   parameters: [
  //     {
  //       name: 'number',
  //       description: 'The number',
  //       type: 'number',
  //       required: true
  //     }
  //   ],
  //   example: 'ISODD(-2.5)',
  //   result: 'false'
  // },
  {
    name: 'LCM',
    description: 'Returns the least common multiple of two or more numbers',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'LCM(24, 36, 48)',
    result: '144'
  },
  {
    name: 'LN',
    description: 'Returns the natural logarithm of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'LN(86)',
    result: '4.454347296253507'
  },
  {
    name: 'LOG',
    description: 'Returns the logarithm of a number to a specified base',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      },
      { name: 'base', description: 'The base', type: 'number', required: false }
    ],
    example: 'LOG(8, 2)',
    result: '3'
  },
  {
    name: 'LOG10',
    description: 'Returns the base-10 logarithm of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'LOG10(100000)',
    result: '5'
  },
  {
    name: 'MOD',
    description: 'Returns the remainder from division',
    parameters: [
      {
        name: 'dividend',
        description: 'The dividend',
        type: 'number',
        required: true
      },
      {
        name: 'divisor',
        description: 'The divisor',
        type: 'number',
        required: true
      }
    ],
    example: 'MOD(3, -2)',
    result: '-1'
  },
  {
    name: 'MROUND',
    description: 'Rounds a number to the nearest multiple of a specified value',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'multiple',
        description: 'The multiple to round to',
        type: 'number',
        required: true
      }
    ],
    example: 'MROUND(-10, -3)',
    result: '-9'
  },
  {
    name: 'MULTINOMIAL',
    description: 'Returns the multinomial of a set of numbers',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to calculate the multinomial for',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MULTINOMIAL(2, 3, 4)',
    result: '1260'
  },
  {
    name: 'ODD',
    description: 'Rounds a number up to the nearest odd integer',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'ODD(-1.5)',
    result: '-3'
  },
  {
    name: 'POWER',
    description: 'Returns the result of a number raised to a power',
    parameters: [
      {
        name: 'base',
        description: 'The base number',
        type: 'number',
        required: true
      },
      {
        name: 'exponent',
        description: 'The exponent',
        type: 'number',
        required: true
      }
    ],
    example: 'POWER(5, 2)',
    result: '25'
  },
  {
    name: 'PRODUCT',
    description: 'Returns the product of a series of numbers',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to multiply',
        type: 'number[]',
        required: true
      }
    ],
    example: 'PRODUCT(5, 15, 30)',
    result: '2250'
  },
  {
    name: 'QUOTIENT',
    description: 'Returns the integer portion of a division',
    parameters: [
      {
        name: 'dividend',
        description: 'The dividend',
        type: 'number',
        required: true
      },
      {
        name: 'divisor',
        description: 'The divisor',
        type: 'number',
        required: true
      }
    ],
    example: 'QUOTIENT(-10, 3)',
    result: '-3'
  },
  {
    name: 'RADIANS',
    description: 'Converts degrees to radians',
    parameters: [
      {
        name: 'degrees',
        description: 'The angle in degrees',
        type: 'number',
        required: true
      }
    ],
    example: 'RADIANS(180)',
    result: '3.141592653589793'
  },
  {
    name: 'RAND',
    description: 'Returns a random number between 0 and 1',
    parameters: [],
    example: 'RAND()',
    result: '[Random real number greater between 0 and 1]'
  },
  {
    name: 'RANDBETWEEN',
    description: 'Returns a random integer between two values',
    parameters: [
      {
        name: 'min',
        description: 'The minimum value',
        type: 'number',
        required: true
      },
      {
        name: 'max',
        description: 'The maximum value',
        type: 'number',
        required: true
      }
    ],
    example: 'RANDBETWEEN(-1, 1)',
    result: '[Random integer between bottom and top]'
  },
  {
    name: 'ROUND',
    description: 'Rounds a number to a specified number of digits',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'digits',
        description: 'The number of digits to round to',
        type: 'number',
        required: false
      }
    ],
    example: 'ROUND(626.3, -3)',
    result: '1000'
  },
  {
    name: 'ROUNDDOWN',
    description: 'Rounds a number down, away from zero',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'digits',
        description: 'The number of digits to round to',
        type: 'number',
        required: false
      }
    ],
    example: 'ROUNDDOWN(-3.14159, 2)',
    result: '-3.14'
  },
  {
    name: 'ROUNDUP',
    description: 'Rounds a number up, away from zero',
    parameters: [
      {
        name: 'number',
        description: 'The number to round',
        type: 'number',
        required: true
      },
      {
        name: 'digits',
        description: 'The number of digits to round to',
        type: 'number',
        required: false
      }
    ],
    example: 'ROUNDUP(-3.14159, 2)',
    result: '-3.15'
  },
  {
    name: 'SEC',
    description: 'Returns the secant of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'SEC(45)',
    result: '1.9035944074044246'
  },
  {
    name: 'SECH',
    description: 'Returns the hyperbolic secant of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'SECH(45)',
    result: '5.725037161098787e-20'
  },
  {
    name: 'SIGN',
    description: 'Returns the sign of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'SIGN(-0.00001)',
    result: '-1'
  },
  {
    name: 'SIN',
    description: 'Returns the sine of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'SIN(1)',
    result: '0.8414709848078965'
  },
  {
    name: 'SINH',
    description: 'Returns the hyperbolic sine of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'SINH(1)',
    result: '1.1752011936438014'
  },
  {
    name: 'SQRT',
    description: 'Returns the square root of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'SQRT(16)',
    result: '4'
  },
  {
    name: 'SQRTPI',
    description: 'Returns the square root of the product of pi and a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'SQRTPI(2)',
    result: '2.5066282746310002'
  },
  {
    name: 'SUBTOTAL',
    description: 'Returns a subtotal in a list or database',
    parameters: [
      {
        name: 'function_num',
        description: 'The function number',
        type: 'number',
        required: true
      },
      {
        name: 'ref1',
        description: 'First reference',
        type: 'any',
        required: true
      },
      {
        name: 'ref2',
        description: 'Second reference',
        type: 'any',
        required: false
      }
    ],
    example: "SUBTOTAL(9, [-5,15], [32,'Hello World!'])",
    result: '10,32'
  },
  {
    name: 'SUM',
    description: 'Adds numbers together',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to add',
        type: 'number[]',
        required: true
      }
    ],
    example: "SUM(-5, 15, 32, 'Hello World!')",
    result: '42'
  },
  {
    name: 'SUMIF',
    description: 'Adds the cells specified by a given condition or criteria',
    parameters: [
      {
        name: 'range',
        description: 'The range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria',
        description: 'The criteria to apply',
        type: 'string',
        required: true
      },
      {
        name: 'sum_range',
        description: 'The cells to sum',
        type: 'any[]',
        required: false
      }
    ],
    example: "SUMIF([2,4,8,16], '>5')",
    result: '24'
  },
  {
    name: 'SUMIFS',
    description: 'Adds the cells specified by multiple conditions or criteria',
    parameters: [
      {
        name: 'sum_range',
        description: 'The cells to sum',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria_range1',
        description: 'The first range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria1',
        description: 'The first criteria to apply',
        type: 'string',
        required: true
      },
      {
        name: 'criteria_range2',
        description: 'The second range to evaluate',
        type: 'any[]',
        required: false
      },
      {
        name: 'criteria2',
        description: 'The second criteria to apply',
        type: 'string',
        required: false
      }
    ],
    example: "SUMIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
    result: '12'
  },
  {
    name: 'SUMPRODUCT',
    description:
      'Returns the sum of the products of corresponding ranges or arrays',
    parameters: [
      {
        name: 'arrays',
        description: 'The arrays to multiply and then add',
        type: 'any[][]',
        required: true
      }
    ],
    example: 'SUMPRODUCT([[1,2],[3,4]], [[1,0],[0,1]])',
    result: '5'
  },
  {
    name: 'SUMSQ',
    description: 'Returns the sum of the squares of a set of numbers',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to square and sum',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SUMSQ(3, 4)',
    result: '25'
  },
  {
    name: 'SUMX2MY2',
    description: 'Returns the sum of the difference of squares of two arrays',
    parameters: [
      {
        name: 'array_x',
        description: 'The first array',
        type: 'number[]',
        required: true
      },
      {
        name: 'array_y',
        description: 'The second array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SUMX2MY2([1,2], [3,4])',
    result: '-20'
  },
  {
    name: 'SUMX2PY2',
    description: 'Returns the sum of the sum of squares of two arrays',
    parameters: [
      {
        name: 'array_x',
        description: 'The first array',
        type: 'number[]',
        required: true
      },
      {
        name: 'array_y',
        description: 'The second array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SUMX2PY2([1,2], [3,4])',
    result: '30'
  },
  {
    name: 'SUMXMY2',
    description: 'Returns the sum of the squares of differences of two arrays',
    parameters: [
      {
        name: 'array_x',
        description: 'The first array',
        type: 'number[]',
        required: true
      },
      {
        name: 'array_y',
        description: 'The second array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SUMXMY2([1,2], [3,4])',
    result: '8'
  },
  {
    name: 'TAN',
    description: 'Returns the tangent of an angle',
    parameters: [
      {
        name: 'angle',
        description: 'The angle in radians',
        type: 'number',
        required: true
      }
    ],
    example: 'TAN(1)',
    result: '1.5574077246549023'
  },
  {
    name: 'TANH',
    description: 'Returns the hyperbolic tangent of a number',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'TANH(-2)',
    result: '-0.9640275800758168'
  },
  {
    name: 'TRUNC',
    description:
      'Truncates a number to an integer by removing the fractional part',
    parameters: [
      {
        name: 'number',
        description: 'The number',
        type: 'number',
        required: true
      }
    ],
    example: 'TRUNC(-8.9)',
    result: '-8'
  }
];

export const STATISTICAL_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'AVEDEV',
    description:
      'Returns the average of the absolute deviations of data points from their mean.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'AVEDEV([2,4], [8,16])',
    result: '4.5'
  },
  {
    name: 'AVERAGE',
    description: 'Calculates the average of numbers.',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to average',
        type: 'number[]',
        required: true
      }
    ],
    example: 'AVERAGE([2,4], [8,16])',
    result: '7.5'
  },
  {
    name: 'AVERAGEA',
    description:
      'Calculates the average of a set of values, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to average',
        type: 'any[]',
        required: true
      }
    ],
    example: 'AVERAGEA([2,4], [8,16])',
    result: '7.5'
  },
  {
    name: 'AVERAGEIF',
    description:
      'Calculates the average of a range based on a given condition.',
    parameters: [
      {
        name: 'range',
        description: 'The range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria',
        description: 'The criteria to apply',
        type: 'string',
        required: true
      },
      {
        name: 'average_range',
        description: 'The range to average',
        type: 'any[]',
        required: false
      }
    ],
    example: "AVERAGEIF([2,4,8,16], '>5', [1, 2, 3, 4])",
    result: '3.5'
  },
  {
    name: 'AVERAGEIFS',
    description:
      'Calculates the average of a range based on multiple conditions.',
    parameters: [
      {
        name: 'average_range',
        description: 'The range to average',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria_range1',
        description: 'The first range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria1',
        description: 'The first criteria to apply',
        type: 'string',
        required: true
      },
      {
        name: 'criteria_range2',
        description: 'The second range to evaluate',
        type: 'any[]',
        required: false
      },
      {
        name: 'criteria2',
        description: 'The second criteria to apply',
        type: 'string',
        required: false
      }
    ],
    example: "AVERAGEIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
    result: '6'
  },
  {
    name: 'BETADIST',
    description: 'Returns the cumulative beta distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'alpha',
        description: 'The alpha parameter',
        type: 'number',
        required: true
      },
      {
        name: 'beta',
        description: 'The beta parameter',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'BETADIST(2, 8, 10, true, 1, 3)',
    result: '0.6854705810117458'
  },
  {
    name: 'BETAINV',
    description: 'Returns the inverse of the cumulative beta distribution.',
    parameters: [
      {
        name: 'probability',
        description: 'The probability associated with the beta distribution',
        type: 'number',
        required: true
      },
      {
        name: 'alpha',
        description: 'The alpha parameter',
        type: 'number',
        required: true
      },
      {
        name: 'beta',
        description: 'The beta parameter',
        type: 'number',
        required: true
      }
    ],
    example: 'BETAINV(0.6854705810117458, 8, 10, 1, 3)',
    result: '1.9999999999999998'
  },
  {
    name: 'BINOMDIST',
    description:
      'Returns the individual term binomial distribution probability.',
    parameters: [
      {
        name: 'number_s',
        description: 'Number of successes',
        type: 'number',
        required: true
      },
      {
        name: 'trials',
        description: 'Number of trials',
        type: 'number',
        required: true
      },
      {
        name: 'probability_s',
        description: 'Probability of success',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description: 'TRUE for cumulative distribution, FALSE for probability',
        type: 'boolean',
        required: true
      }
    ],
    example: 'BINOMDIST(6, 10, 0.5, false)',
    result: '0.205078125'
  },
  {
    name: 'CORREL',
    description: 'Returns the correlation coefficient between two data sets.',
    parameters: [
      {
        name: 'array1',
        description: 'The first data set',
        type: 'number[]',
        required: true
      },
      {
        name: 'array2',
        description: 'The second data set',
        type: 'number[]',
        required: true
      }
    ],
    example: 'CORREL([3,2,4,5,6], [9,7,12,15,17])',
    result: '0.9970544855015815'
  },
  {
    name: 'COUNT',
    description: 'Counts the number of cells that contain numbers.',
    parameters: [
      {
        name: 'values',
        description: 'The range to count',
        type: 'any[]',
        required: true
      }
    ],
    example: 'COUNT([1,2], [3,4])',
    result: '4'
  },
  {
    name: 'COUNTA',
    description: 'Counts the number of non-empty cells.',
    parameters: [
      {
        name: 'values',
        description: 'The range to count',
        type: 'any[]',
        required: true
      }
    ],
    example: "COUNTA([1, null, 3, 'a', '', 'c'])",
    result: '4'
  },
  {
    name: 'COUNTBLANK',
    description: 'Counts the number of empty cells.',
    parameters: [
      {
        name: 'range',
        description: 'The range to count',
        type: 'any[]',
        required: true
      }
    ],
    example: "COUNTBLANK([1, null, 3, 'a', '', 'c'])",
    result: '2'
  },
  {
    name: 'COUNTIF',
    description: 'Counts the number of cells that meet a specified condition.',
    parameters: [
      {
        name: 'range',
        description: 'The range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria',
        description: 'The criteria to apply',
        type: 'string',
        required: true
      }
    ],
    example: "COUNTIF(['Caen', 'Melbourne', 'Palo Alto', 'Singapore'], 'a')",
    result: '3'
  },
  {
    name: 'COUNTIFS',
    description: 'Counts the number of cells that meet multiple conditions.',
    parameters: [
      {
        name: 'criteria_range1',
        description: 'The first range to evaluate',
        type: 'any[]',
        required: true
      },
      {
        name: 'criteria1',
        description: 'The first criteria to apply',
        type: 'string',
        required: true
      },
      {
        name: 'criteria_range2',
        description: 'The second range to evaluate',
        type: 'any[]',
        required: false
      },
      {
        name: 'criteria2',
        description: 'The second criteria to apply',
        type: 'string',
        required: false
      }
    ],
    example: "COUNTIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
    result: '2'
  },
  {
    name: 'COUNTUNIQUE',
    description: 'Counts the number of unique values in a range.',
    parameters: [
      {
        name: 'range',
        description: 'The range to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'COUNTUNIQUE([1,1,2,2,3,3])',
    result: '3'
  },
  {
    name: 'COVARIANCEP',
    description:
      'Returns the covariance, the average of the products of deviations for each data point pair in two data sets.',
    parameters: [
      {
        name: 'array1',
        description: 'The first data set',
        type: 'number[]',
        required: true
      },
      {
        name: 'array2',
        description: 'The second data set',
        type: 'number[]',
        required: true
      }
    ],
    example: 'COVARIANCEP([3,2,4,5,6], [9,7,12,15,17])',
    result: '5.2'
  },
  {
    name: 'COVARIANCES',
    description: 'Returns the sample covariance of two data sets.',
    parameters: [
      {
        name: 'array1',
        description: 'The first data set',
        type: 'number[]',
        required: true
      },
      {
        name: 'array2',
        description: 'The second data set',
        type: 'number[]',
        required: true
      }
    ],
    example: 'COVARIANCES([2,4,8], [5,11,12])',
    result: '9.666666666666668'
  },
  {
    name: 'DEVSQ',
    description:
      'Returns the sum of the squares of deviations of a dataset from its mean.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'DEVSQ([2,4,8,16])',
    result: '115'
  },
  {
    name: 'EXPONDIST',
    description: 'Returns the exponential distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'lambda',
        description: 'The rate parameter',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'EXPONDIST(0.2, 10, true)',
    result: '0.8646647167633873'
  },
  {
    name: 'FDIST',
    description: 'Returns the F probability distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom1',
        description: 'The numerator degrees of freedom',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom2',
        description: 'The denominator degrees of freedom',
        type: 'number',
        required: true
      }
    ],
    example: 'FDIST(15.2069, 6, 4, false)',
    result: '0.0012237917087831735'
  },
  {
    name: 'FINV',
    description: 'Returns the inverse of the F probability distribution.',
    parameters: [
      {
        name: 'probability',
        description: 'The probability associated with the F distribution',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom1',
        description: 'The numerator degrees of freedom',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom2',
        description: 'The denominator degrees of freedom',
        type: 'number',
        required: true
      }
    ],
    example: 'FINV(0.01, 6, 4)',
    result: '0.10930991412457851'
  },
  {
    name: 'FISHER',
    description: 'Returns the Fisher transformation.',
    parameters: [
      {
        name: 'x',
        description: 'The value to transform',
        type: 'number',
        required: true
      }
    ],
    example: 'FISHER(0.75)',
    result: '0.9729550745276566'
  },
  {
    name: 'FISHERINV',
    description: 'Returns the inverse of the Fisher transformation.',
    parameters: [
      {
        name: 'y',
        description: 'The value to transform',
        type: 'number',
        required: true
      }
    ],
    example: 'FISHERINV(0.9729550745276566)',
    result: '0.75'
  },
  {
    name: 'FORECAST',
    description: 'Returns a value along a linear trend.',
    parameters: [
      {
        name: 'x',
        description: 'The data point for which you want to predict a value',
        type: 'number',
        required: true
      },
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'FORECAST(30, [6,7,9,15,21], [20,28,31,38,40])',
    result: '10.607253086419755'
  },
  {
    name: 'FREQUENCY',
    description: 'Calculates how often values occur within a range of values.',
    parameters: [
      {
        name: 'data_array',
        description: 'The array of data to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'bins_array',
        description: 'The array of bins to group the data',
        type: 'number[]',
        required: true
      }
    ],
    example: 'FREQUENCY([79,85,78,85,50,81,95,88,97], [70,79,89])',
    result: '1,2,4,2'
  },
  {
    name: 'GAMMA',
    description: 'Returns the gamma function value.',
    parameters: [
      {
        name: 'number',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      }
    ],
    example: 'GAMMA(2.5)',
    result: '1.3293403919101043'
  },
  {
    name: 'GAMMALN',
    description: 'Returns the natural logarithm of the gamma function.',
    parameters: [
      {
        name: 'number',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      }
    ],
    example: 'GAMMALN(10)',
    result: '12.801827480081961'
  },
  {
    name: 'GAUSS',
    description:
      'Returns the standard normal cumulative distribution function.',
    parameters: [
      {
        name: 'z',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      }
    ],
    example: 'GAUSS(2)',
    result: '0.4772498680518208'
  },
  {
    name: 'GEOMEAN',
    description: 'Returns the geometric mean of a set of positive numbers.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'GEOMEAN([2,4], [8,16])',
    result: '5.656854249492381'
  },
  {
    name: 'GROWTH',
    description: 'Returns values along an exponential trend.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'new_x',
        description: 'The new independent values',
        type: 'number[]',
        required: false
      },
      {
        name: 'const',
        description: 'TRUE to force the constant b to equal 1',
        type: 'boolean',
        required: false
      }
    ],
    example: 'GROWTH([2,4,8,16], [1,2,3,4], [5])',
    result: '32.00000000000003'
  },
  {
    name: 'HARMEAN',
    description: 'Returns the harmonic mean of a set of numbers.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'HARMEAN([2,4], [8,16])',
    result: '4.266666666666667'
  },
  {
    name: 'HYPGEOMDIST',
    description: 'Returns the hypergeometric distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The number of successes in the sample',
        type: 'number',
        required: true
      },
      {
        name: 'sample_size',
        description: 'The number of draws',
        type: 'number',
        required: true
      },
      {
        name: 'population_size',
        description: 'The population size',
        type: 'number',
        required: true
      },
      {
        name: 'number_successes',
        description: 'The number of successes in the population',
        type: 'number',
        required: true
      }
    ],
    example: 'HYPGEOMDIST(1, 4, 8, 20, false)',
    result: '0.3632610939112487'
  },
  {
    name: 'INTERCEPT',
    description: 'Returns the intercept of the linear regression line.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'INTERCEPT([2,3,9,1,8], [6,5,11,7,5])',
    result: '0.04838709677419217'
  },
  {
    name: 'KURT',
    description: 'Returns the kurtosis of a data set.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'KURT([3,4,5,2,3,4,5,6,4,7])',
    result: '-0.15179963720841627'
  },
  {
    name: 'LARGE',
    description: 'Returns the k-th largest value in a data set.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'k',
        description: 'The position of the value to return',
        type: 'number',
        required: true
      }
    ],
    example: 'LARGE([3,5,3,5,4,4,2,4,6,7], 3)',
    result: '5'
  },
  {
    name: 'LINEST',
    description: 'Returns the parameters of a linear trend.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'const',
        description: 'TRUE to force the constant b to equal 1',
        type: 'boolean',
        required: false
      },
      {
        name: 'stats',
        description: 'TRUE to return additional regression statistics',
        type: 'boolean',
        required: false
      }
    ],
    example: 'LINEST([1,9,5,7], [0,4,2,3], true, true)',
    result: '2,1'
  },
  {
    name: 'LOGNORMDIST',
    description: 'Returns the cumulative lognormal distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The mean of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'standard_dev',
        description: 'The standard deviation of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'LOGNORMDIST(4, 3.5, 1.2, true)',
    result: '0.0390835557068005'
  },
  {
    name: 'LOGNORMINV',
    description: 'Returns the inverse of the lognormal distribution.',
    parameters: [
      {
        name: 'probability',
        description:
          'The probability associated with the lognormal distribution',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The mean of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'standard_dev',
        description: 'The standard deviation of the distribution',
        type: 'number',
        required: true
      }
    ],
    example: 'LOGNORMINV(0.0390835557068005, 3.5, 1.2, true)',
    result: '4.000000000000001'
  },
  {
    name: 'MAX',
    description: 'Returns the largest number.',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MAX([0.1,0.2], [0.4,0.8], [true, false])',
    result: '0.8'
  },
  {
    name: 'MAXA',
    description:
      'Returns the largest value in a set of values, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'MAXA([0.1,0.2], [0.4,0.8], [true, false])',
    result: '1'
  },
  {
    name: 'MEDIAN',
    description: 'Returns the median of a set of numbers.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MEDIAN([1,2,3], [4,5,6])',
    result: '3.5'
  },
  {
    name: 'MIN',
    description: 'Returns the smallest number.',
    parameters: [
      {
        name: 'values',
        description: 'Numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MIN([0.1,0.2], [0.4,0.8], [true, false])',
    result: '0.1'
  },
  {
    name: 'MINA',
    description:
      'Returns the smallest value in a set of values, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'MINA([0.1,0.2], [0.4,0.8], [true, false])',
    result: '0'
  },
  {
    name: 'MODEMULT',
    description:
      'Returns a vertical array of the most frequently occurring values in a data set.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MODEMULT([1,2,3,4,3,2,1,2,3])',
    result: '2,3'
  },
  {
    name: 'MODESNGL',
    description: 'Returns the most frequently occurring value in a data set.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'MODESNGL([1,2,3,4,3,2,1,2,3])',
    result: '2'
  },
  {
    name: 'NORMDIST',
    description: 'Returns the normal distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The mean of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'standard_dev',
        description: 'The standard deviation of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'NORMDIST(42, 40, 1.5, true)',
    result: '0.9087887802741321'
  },
  {
    name: 'NORMINV',
    description: 'Returns the inverse of the normal distribution.',
    parameters: [
      {
        name: 'probability',
        description: 'The probability associated with the normal distribution',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The mean of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'standard_dev',
        description: 'The standard deviation of the distribution',
        type: 'number',
        required: true
      }
    ],
    example: 'NORMINV(0.9087887802741321, 40, 1.5)',
    result: '42'
  },
  {
    name: 'NORMSDIST',
    description:
      'Returns the standard normal cumulative distribution function.',
    parameters: [
      {
        name: 'z',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      }
    ],
    example: 'NORMSDIST(1, true)',
    result: '0.8413447460685429'
  },
  {
    name: 'NORMSINV',
    description:
      'Returns the inverse of the standard normal cumulative distribution function.',
    parameters: [
      {
        name: 'probability',
        description:
          'The probability associated with the standard normal distribution',
        type: 'number',
        required: true
      }
    ],
    example: 'NORMSINV(0.8413447460685429)',
    result: '1.0000000000000002'
  },
  {
    name: 'PEARSON',
    description: 'Returns the Pearson product moment correlation coefficient.',
    parameters: [
      {
        name: 'array1',
        description: 'The first data set',
        type: 'number[]',
        required: true
      },
      {
        name: 'array2',
        description: 'The second data set',
        type: 'number[]',
        required: true
      }
    ],
    example: 'PEARSON([9,7,5,3,1], [10,6,1,5,3])',
    result: '0.6993786061802354'
  },
  {
    name: 'PERCENTILEEXC',
    description:
      'Returns the k-th percentile of values in a range, excluding the k-th percentile itself.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'k',
        description: 'The percentile value (0-1)',
        type: 'number',
        required: true
      }
    ],
    example: 'PERCENTILEEXC([1,2,3,4], 0.3)',
    result: '1.5'
  },
  {
    name: 'PERCENTILEINC',
    description:
      'Returns the k-th percentile of values in a range, including the k-th percentile itself.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'k',
        description: 'The percentile value (0-1)',
        type: 'number',
        required: true
      }
    ],
    example: 'PERCENTILEINC([1,2,3,4], 0.3)',
    result: '1.9'
  },
  {
    name: 'PERCENTRANKEXC',
    description:
      'Returns the rank of a value in a data set as a percentage (0-1), excluding the value itself.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'x',
        description: 'The value to rank',
        type: 'number',
        required: true
      }
    ],
    example: 'PERCENTRANKEXC([1,2,3,4], 2, 2)',
    result: '0.4'
  },
  {
    name: 'PERCENTRANKINC',
    description:
      'Returns the rank of a value in a data set as a percentage (0-1), including the value itself.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'x',
        description: 'The value to rank',
        type: 'number',
        required: true
      }
    ],
    example: 'PERCENTRANKINC([1,2,3,4], 2, 2)',
    result: '0.33'
  },
  {
    name: 'PERMUT',
    description:
      'Returns the number of permutations for a given number of objects.',
    parameters: [
      {
        name: 'number',
        description: 'The total number of objects',
        type: 'number',
        required: true
      },
      {
        name: 'number_chosen',
        description: 'The number of objects to choose',
        type: 'number',
        required: true
      }
    ],
    example: 'PERMUT(100, 3)',
    result: '970200'
  },
  {
    name: 'PERMUTATIONA',
    description:
      'Returns the number of permutations for a given number of objects, allowing for repetitions.',
    parameters: [
      {
        name: 'number',
        description: 'The total number of objects',
        type: 'number',
        required: true
      },
      {
        name: 'number_chosen',
        description: 'The number of objects to choose',
        type: 'number',
        required: true
      }
    ],
    example: 'PERMUTATIONA(4, 3)',
    result: '64'
  },
  {
    name: 'PHI',
    description:
      'Returns the value of the standard normal probability density function.',
    parameters: [
      {
        name: 'z',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      }
    ],
    example: 'PHI(0.75)',
    result: '0.30113743215480443'
  },
  {
    name: 'POISSONDIST',
    description: 'Returns the Poisson distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The number of events',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The expected number of events',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description: 'TRUE for cumulative distribution, FALSE for probability',
        type: 'boolean',
        required: true
      }
    ],
    example: 'POISSONDIST(2, 5, true)',
    result: '0.12465201948308113'
  },
  {
    name: 'PROB',
    description:
      'Returns the probability that a value will fall between two limits.',
    parameters: [
      {
        name: 'x',
        description: 'The array of values',
        type: 'number[]',
        required: true
      },
      {
        name: 'probabilities',
        description: 'The array of probabilities',
        type: 'number[]',
        required: true
      },
      {
        name: 'lower_limit',
        description: 'The lower limit',
        type: 'number',
        required: true
      },
      {
        name: 'upper_limit',
        description: 'The upper limit',
        type: 'number',
        required: true
      }
    ],
    example: 'PROB([1,2,3,4], [0.1,0.2,0.2,0.1], 2, 3)',
    result: '0.4'
  },
  {
    name: 'QUARTILEEXC',
    description:
      'Returns the quartile of a data set, excluding the quartiles themselves.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'quart',
        description: 'The quartile number (1-3)',
        type: 'number',
        required: true
      }
    ],
    example: 'QUARTILEEXC([1,2,3,4], 1)',
    result: '1.25'
  },
  {
    name: 'QUARTILEINC',
    description:
      'Returns the quartile of a data set, including the quartiles themselves.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'quart',
        description: 'The quartile number (1-4)',
        type: 'number',
        required: true
      }
    ],
    example: 'QUARTILEINC([1,2,3,4], 1)',
    result: '1.75'
  },
  {
    name: 'RANKAVG',
    description:
      'Returns the rank of a number in a list of numbers, with the average rank for ties.',
    parameters: [
      {
        name: 'number',
        description: 'The number to rank',
        type: 'number',
        required: true
      },
      {
        name: 'ref',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'order',
        description: 'The order of ranking (0 for descending, 1 for ascending)',
        type: 'number',
        required: false
      }
    ],
    example: 'RANKAVG(4, [2,4,4,8,8,16], false)',
    result: '4.5'
  },
  {
    name: 'RANKEQ',
    description:
      'Returns the rank of a number in a list of numbers, with the same rank for ties.',
    parameters: [
      {
        name: 'number',
        description: 'The number to rank',
        type: 'number',
        required: true
      },
      {
        name: 'ref',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'order',
        description: 'The order of ranking (0 for descending, 1 for ascending)',
        type: 'number',
        required: false
      }
    ],
    example: 'RANKEQ(4, [2,4,4,8,8,16], false)',
    result: '4'
  },
  {
    name: 'RSQ',
    description:
      'Returns the square of the Pearson product moment correlation coefficient.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'RSQ([9,7,5,3,1], [10,6,1,5,3])',
    result: '0.4891304347826088'
  },
  {
    name: 'SKEW',
    description: 'Returns the skewness of a distribution.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SKEW([3,4,5,2,3,4,5,6,4,7])',
    result: '0.3595430714067974'
  },
  {
    name: 'SKEWP',
    description: 'Returns the skewness of a population.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SKEWP([3,4,5,2,3,4,5,6,4,7])',
    result: '0.303193339354144'
  },
  {
    name: 'SLOPE',
    description: 'Returns the slope of the linear regression line.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'SLOPE([1,9,5,7], [0,4,2,3])',
    result: '2'
  },
  {
    name: 'SMALL',
    description: 'Returns the k-th smallest value in a data set.',
    parameters: [
      {
        name: 'array',
        description: 'The array to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'k',
        description: 'The position of the value to return',
        type: 'number',
        required: true
      }
    ],
    example: 'SMALL([3,5,3,5,4,4,2,4,6,7], 3)',
    result: '3'
  },
  {
    name: 'STANDARDIZE',
    description: 'Returns a normalized value from a distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value to normalize',
        type: 'number',
        required: true
      },
      {
        name: 'mean',
        description: 'The mean of the distribution',
        type: 'number',
        required: true
      },
      {
        name: 'standard_dev',
        description: 'The standard deviation of the distribution',
        type: 'number',
        required: true
      }
    ],
    example: 'STANDARDIZE(42, 40, 1.5)',
    result: '1.3333333333333333'
  },
  {
    name: 'STDEVA',
    description:
      'Returns the standard deviation of a set of values, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'STDEVA([2,4], [8,16], [true, false])',
    result: '6.013872850889572'
  },
  {
    name: 'STDEVP',
    description: 'Returns the standard deviation of a population.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'STDEVP([2,4], [8,16], [true, false])',
    result: '5.361902647381804'
  },
  {
    name: 'STDEVPA',
    description:
      'Returns the standard deviation of a population, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'STDEVPA([2,4], [8,16], [true, false])',
    result: '5.489889697333535'
  },
  {
    name: 'STDEVS',
    description: 'Returns the standard deviation of a sample.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'STDEVS([2,4], [8,16], [true, false])',
    result: '6.191391873668904'
  },
  {
    name: 'STEYX',
    description:
      'Returns the standard error of the predicted y-value for each x in the regression.',
    parameters: [
      {
        name: 'known_y',
        description: 'The dependent array',
        type: 'number[]',
        required: true
      },
      {
        name: 'known_x',
        description: 'The independent array',
        type: 'number[]',
        required: true
      }
    ],
    example: 'STEYX([2,3,9,1,8,7,5], [6,5,11,7,5,4,4])',
    result: '3.305718950210041'
  },
  {
    name: 'TDIST',
    description: "Returns the Student's t-distribution.",
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom',
        description: 'The degrees of freedom',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'TDIST(60, 1, true)',
    result: '0.9946953263673741'
  },
  {
    name: 'TINV',
    description: "Returns the inverse of the Student's t-distribution.",
    parameters: [
      {
        name: 'probability',
        description: 'The probability associated with the t-distribution',
        type: 'number',
        required: true
      },
      {
        name: 'deg_freedom',
        description: 'The degrees of freedom',
        type: 'number',
        required: true
      }
    ],
    example: 'TINV(0.9946953263673741, 1)',
    result: '59.99999999996535'
  },
  {
    name: 'TRIMMEAN',
    description: 'Returns the mean of the interior of a data set.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      },
      {
        name: 'percent',
        description: 'The percentage of data to exclude from the calculation',
        type: 'number',
        required: true
      }
    ],
    example: 'TRIMMEAN([4,5,6,7,2,3,4,5,1,2,3], 0.2)',
    result: '3.7777777777777777'
  },
  {
    name: 'VARA',
    description:
      'Returns the variance of a set of values, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'VARA([2,4], [8,16], [true, false])',
    result: '36.16666666666667'
  },
  {
    name: 'VARP',
    description: 'Returns the variance of a population.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'VARP([2,4], [8,16], [true, false])',
    result: '28.75'
  },
  {
    name: 'VARPA',
    description:
      'Returns the variance of a population, including text and logical values.',
    parameters: [
      {
        name: 'values',
        description: 'The values to evaluate',
        type: 'any[]',
        required: true
      }
    ],
    example: 'VARPA([2,4], [8,16], [true, false])',
    result: '30.13888888888889'
  },
  {
    name: 'VARS',
    description: 'Returns the variance of a sample.',
    parameters: [
      {
        name: 'values',
        description: 'The numbers to evaluate',
        type: 'number[]',
        required: true
      }
    ],
    example: 'VARS([2,4], [8,16], [true, false])',
    result: '38.333333333333336'
  },
  {
    name: 'WEIBULLDIST',
    description: 'Returns the Weibull distribution.',
    parameters: [
      {
        name: 'x',
        description: 'The value at which to evaluate the function',
        type: 'number',
        required: true
      },
      {
        name: 'alpha',
        description: 'The shape parameter',
        type: 'number',
        required: true
      },
      {
        name: 'beta',
        description: 'The scale parameter',
        type: 'number',
        required: true
      },
      {
        name: 'cumulative',
        description:
          'TRUE for cumulative distribution, FALSE for probability density',
        type: 'boolean',
        required: true
      }
    ],
    example: 'WEIBULLDIST(105, 20, 100, true)',
    result: '0.9295813900692769'
  },
  {
    name: 'ZTEST',
    description: 'Returns the one-tailed P-value of a Z-test.',
    parameters: [
      {
        name: 'array',
        description: 'The array of data',
        type: 'number[]',
        required: true
      },
      {
        name: 'x',
        description: 'The value to test against',
        type: 'number',
        required: true
      },
      {
        name: 'sigma',
        description: 'The population standard deviation',
        type: 'number',
        required: true
      }
    ],
    example: 'ZTEST([3,6,7,8,6,5,4,2,1,9], 4)',
    result: '0.09057419685136381'
  }
];

export const FINANCIAL_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'ACCRINT',
    description:
      'Returns the accrued interest for a security that pays interest at maturity.',
    parameters: [
      {
        name: 'issue',
        description: "The security's issue date",
        type: 'Date',
        required: true
      },
      {
        name: 'first_interest',
        description: 'The date of the first interest payment',
        type: 'Date',
        required: true
      },
      {
        name: 'settlement',
        description: 'The settlement date',
        type: 'Date',
        required: true
      },
      {
        name: 'rate',
        description: 'The interest rate',
        type: 'number',
        required: true
      },
      {
        name: 'par',
        description: "The security's par value",
        type: 'number',
        required: true
      },
      {
        name: 'frequency',
        description: 'The number of interest payments per year',
        type: 'number',
        required: true
      },
      {
        name: 'basis',
        description: 'The type of day count basis to use',
        type: 'number',
        required: false
      }
    ],
    example:
      "ACCRINT('01/01/2011', '02/01/2011', '07/01/2014', 0.1, 1000, 1, 0)",
    result: '350'
  },
  {
    name: 'CUMIPMT',
    description:
      'Returns the cumulative interest paid on a loan between two periods.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate for the loan',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description:
          'The present value, or total amount that a series of future payments is worth now',
        type: 'number',
        required: true
      },
      {
        name: 'start_period',
        description: 'The first period in which interest is paid',
        type: 'number',
        required: true
      },
      {
        name: 'end_period',
        description: 'The last period in which interest is paid',
        type: 'number',
        required: true
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'CUMIPMT(0.1/12, 30*12, 100000, 13, 24, 0)',
    result: '-9916.77251395708'
  },
  {
    name: 'CUMPRINC',
    description:
      'Returns the cumulative principal paid on a loan between two periods.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate for the loan',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'start_period',
        description: 'The first period in which principal is paid',
        type: 'number',
        required: true
      },
      {
        name: 'end_period',
        description: 'The last period in which principal is paid',
        type: 'number',
        required: true
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'CUMPRINC(0.1/12, 30*12, 100000, 13, 24, 0)',
    result: '-614.0863271085149'
  },
  {
    name: 'DB',
    description:
      'Returns the depreciation of an asset for a specified period using the fixed-declining balance method.',
    parameters: [
      {
        name: 'cost',
        description: 'The initial cost of the asset',
        type: 'number',
        required: true
      },
      {
        name: 'salvage',
        description: 'The value at the end of the depreciation',
        type: 'number',
        required: true
      },
      {
        name: 'life',
        description:
          'The number of periods over which the asset is depreciated',
        type: 'number',
        required: true
      },
      {
        name: 'period',
        description:
          'The period for which you want to calculate the depreciation',
        type: 'number',
        required: true
      },
      {
        name: 'month',
        description: 'The month in which the asset was purchased',
        type: 'number',
        required: false
      }
    ],
    example: 'DB(1000000, 100000, 6, 1, 6)',
    result: '159500'
  },
  {
    name: 'DDB',
    description:
      'Returns the depreciation of an asset for a specified period using the double-declining balance method.',
    parameters: [
      {
        name: 'cost',
        description: 'The initial cost of the asset',
        type: 'number',
        required: true
      },
      {
        name: 'salvage',
        description: 'The value at the end of the depreciation',
        type: 'number',
        required: true
      },
      {
        name: 'life',
        description:
          'The number of periods over which the asset is depreciated',
        type: 'number',
        required: true
      },
      {
        name: 'period',
        description:
          'The period for which you want to calculate the depreciation',
        type: 'number',
        required: true
      }
    ],
    example: 'DDB(1000000, 100000, 6, 1, 1.5)',
    result: '250000'
  },
  {
    name: 'DOLLARDE',
    description:
      'Converts a dollar price expressed as a fraction into a dollar price expressed as a decimal.',
    parameters: [
      {
        name: 'fractional_dollar',
        description: 'The dollar price expressed as a fraction',
        type: 'string',
        required: true
      },
      {
        name: 'fraction',
        description: 'The fraction to use',
        type: 'number',
        required: true
      }
    ],
    example: 'DOLLARDE(1.1, 16)',
    result: '1.625'
  },
  {
    name: 'DOLLARFR',
    description:
      'Converts a dollar price expressed as a decimal into a dollar price expressed as a fraction.',
    parameters: [
      {
        name: 'decimal_dollar',
        description: 'The dollar price expressed as a decimal',
        type: 'number',
        required: true
      },
      {
        name: 'fraction',
        description: 'The fraction to use',
        type: 'number',
        required: true
      }
    ],
    example: 'DOLLARFR(1.625, 16)',
    result: '1.1'
  },
  {
    name: 'EFFECT',
    description: 'Returns the effective annual interest rate.',
    parameters: [
      {
        name: 'nominal_rate',
        description: 'The nominal annual interest rate',
        type: 'number',
        required: true
      },
      {
        name: 'npery',
        description: 'The number of compounding periods per year',
        type: 'number',
        required: true
      }
    ],
    example: 'EFFECT(0.1, 4)',
    result: '0.10381289062499977'
  },
  {
    name: 'FV',
    description:
      'Returns the future value of an investment based on periodic, constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pmt',
        description: 'The payment made each period',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: false
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'FV(0.1/12, 10, -100, -1000, 0)',
    result: '2124.874409194097'
  },
  {
    name: 'FVSCHEDULE',
    description:
      'Returns the future value of an initial principal after applying a series of compound interest rates.',
    parameters: [
      {
        name: 'principal',
        description: 'The initial principal',
        type: 'number',
        required: true
      },
      {
        name: 'schedule',
        description: 'An array of interest rates',
        type: 'number[]',
        required: true
      }
    ],
    example: 'FVSCHEDULE(100, [0.09,0.1,0.11])',
    result: '133.08900000000003'
  },
  {
    name: 'IPMT',
    description:
      'Returns the interest payment for a given period for an investment based on periodic, constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'per',
        description: 'The period for which you want to find the interest',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'IPMT(0.1/12, 6, 2*12, 100000, 1000000, 0)',
    result: '928.8235718400465'
  },
  {
    name: 'IRR',
    description:
      'Returns the internal rate of return for a series of cash flows.',
    parameters: [
      {
        name: 'values',
        description: 'An array of cash flows',
        type: 'number[]',
        required: true
      },
      {
        name: 'guess',
        description: 'A number that you guess is close to the result',
        type: 'number',
        required: false
      }
    ],
    example: 'IRR([-75000,12000,15000,18000,21000,24000], 0.075)',
    result: '0.05715142887178447'
  },
  {
    name: 'ISPMT',
    description:
      'Calculates the interest paid during a specific period of an investment.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate for the investment',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      }
    ],
    example: 'ISPMT(0.1/12, 6, 2*12, 100000)',
    result: '-625'
  },
  {
    name: 'MIRR',
    description:
      'Returns the modified internal rate of return for a series of cash flows.',
    parameters: [
      {
        name: 'values',
        description: 'An array of cash flows',
        type: 'number[]',
        required: true
      },
      {
        name: 'finance_rate',
        description:
          'The interest rate on the money used to finance the investment',
        type: 'number',
        required: true
      },
      {
        name: 'reinvest_rate',
        description: 'The interest rate received on reinvestment of cash',
        type: 'number',
        required: true
      }
    ],
    example: 'MIRR([-75000,12000,15000,18000,21000,24000], 0.1, 0.12)',
    result: '0.07971710360838036'
  },
  {
    name: 'NOMINAL',
    description: 'Returns the nominal annual interest rate.',
    parameters: [
      {
        name: 'effect_rate',
        description: 'The effective interest rate',
        type: 'number',
        required: true
      },
      {
        name: 'npery',
        description: 'The number of compounding periods per year',
        type: 'number',
        required: true
      }
    ],
    example: 'NOMINAL(0.1, 4)',
    result: '0.09645475633778045'
  },
  {
    name: 'NPER',
    description:
      'Returns the number of periods for an investment based on periodic, constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'pmt',
        description: 'The payment made each period',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'fv',
        description: 'The future value',
        type: 'number',
        required: false
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'NPER(0.1/12, -100, -1000, 10000, 0)',
    result: '63.39385422740764'
  },
  {
    name: 'NPV',
    description:
      'Returns the net present value of an investment based on a series of periodic cash flows and a discount rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The discount rate',
        type: 'number',
        required: true
      },
      {
        name: 'values',
        description: 'An array of cash flows',
        type: 'number[]',
        required: true
      }
    ],
    example: 'NPV(0.1, -10000, 2000, 4000, 8000)',
    result: '1031.3503176012546'
  },
  {
    name: 'PDURATION',
    description:
      'Returns the number of periods required to achieve an investment goal at a given interest rate.',
    parameters: [
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'fv',
        description: 'The future value',
        type: 'number',
        required: true
      },
      {
        name: 'rate',
        description: 'The interest rate',
        type: 'number',
        required: true
      }
    ],
    example: 'PDURATION(0.1, 1000, 2000)',
    result: '7.272540897341714'
  },
  {
    name: 'PMT',
    description:
      'Returns the payment for a loan based on constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'fv',
        description: 'The future value',
        type: 'number',
        required: false
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'PMT(0.1/12, 2*12, 100000, 1000000, 0)',
    result: '-42426.08563793503'
  },
  {
    name: 'PPMT',
    description:
      'Returns the principal payment for a given period for an investment based on periodic, constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'per',
        description: 'The period for which you want to find the principal',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'PPMT(0.1/12, 6, 2*12, 100000, 1000000, 0)',
    result: '-43354.909209775076'
  },
  {
    name: 'PV',
    description:
      'Returns the present value of an investment based on periodic, constant payments and a constant interest rate.',
    parameters: [
      {
        name: 'rate',
        description: 'The interest rate per period',
        type: 'number',
        required: true
      },
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pmt',
        description: 'The payment made each period',
        type: 'number',
        required: true
      },
      {
        name: 'fv',
        description: 'The future value',
        type: 'number',
        required: false
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      }
    ],
    example: 'PV(0.1/12, 2*12, 1000, 10000, 0)',
    result: '-29864.950264779152'
  },
  {
    name: 'RATE',
    description: 'Returns the interest rate per period of an annuity.',
    parameters: [
      {
        name: 'nper',
        description: 'The number of periods',
        type: 'number',
        required: true
      },
      {
        name: 'pmt',
        description: 'The payment made each period',
        type: 'number',
        required: true
      },
      {
        name: 'pv',
        description: 'The present value',
        type: 'number',
        required: true
      },
      {
        name: 'fv',
        description: 'The future value',
        type: 'number',
        required: false
      },
      {
        name: 'type',
        description: 'When payments are due',
        type: 'number',
        required: false
      },
      {
        name: 'guess',
        description: 'A number that you guess is close to the result',
        type: 'number',
        required: false
      }
    ],
    example: 'RATE(2*12, -1000, -10000, 100000, 0, 0.1)',
    result: '0.06517891177181533'
  }
];

export const LOGICAL_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'AND',
    description: 'Returns TRUE if all arguments are TRUE.',
    parameters: [
      {
        name: 'logical1',
        description: 'The first condition to evaluate',
        type: 'boolean',
        required: true
      }
    ],
    example: 'AND(true, false, true)',
    result: 'false'
  },
  {
    name: 'FALSE',
    description: 'Returns the logical value FALSE.',
    parameters: [],
    example: 'FALSE()',
    result: 'false'
  },
  {
    name: 'IF',
    description:
      'Returns one value if a condition is TRUE and another value if it is FALSE.',
    parameters: [
      {
        name: 'logical_test',
        description: 'The condition to evaluate',
        type: 'boolean',
        required: true
      },
      {
        name: 'value_if_true',
        description: 'The value to return if the condition is TRUE',
        type: 'any',
        required: true
      },
      {
        name: 'value_if_false',
        description: 'The value to return if the condition is FALSE',
        type: 'any',
        required: false
      }
    ],
    example: "IF(true, 'Hello!', 'Goodbye!')",
    result: 'Hello!'
  },
  {
    name: 'IFS',
    description:
      'Checks whether one or more conditions are met and returns a value that corresponds to the first TRUE condition.',
    parameters: [
      {
        name: 'condition1',
        description: 'The first condition to evaluate',
        type: 'boolean',
        required: true
      },
      {
        name: 'value1',
        description: 'The value to return if condition1 is TRUE',
        type: 'any',
        required: true
      },
      {
        name: 'condition2',
        description: 'The second condition to evaluate',
        type: 'boolean',
        required: false
      },
      {
        name: 'value2',
        description: 'The value to return if condition2 is TRUE',
        type: 'any',
        required: false
      }
      // Additional conditions can be added as needed
    ],
    example: "IFS(false, 'Hello!', true, 'Goodbye!')",
    result: 'Goodbye!'
  },
  {
    name: 'IFERROR',
    description:
      'Returns a value you specify if a formula evaluates to an error; otherwise, returns the result of the formula.',
    parameters: [
      {
        name: 'value',
        description: 'The value or formula to check for an error',
        type: 'any',
        required: true
      },
      {
        name: 'value_if_error',
        description: 'The value to return if an error is found',
        type: 'any',
        required: true
      }
    ],
    example: "IFERROR('#DIV/0!', 'Error')",
    result: 'Error'
  },
  {
    name: 'IFNA',
    description:
      'Returns the value you specify if the expression resolves to #N/A; otherwise, returns the result of the expression.',
    parameters: [
      {
        name: 'value',
        description: 'The value or formula to check for #N/A',
        type: 'any',
        required: true
      },
      {
        name: 'value_if_na',
        description: 'The value to return if #N/A is found',
        type: 'any',
        required: true
      }
    ],
    example: "IFNA('#N/A', 'Error')",
    result: 'Error'
  },
  {
    name: 'NOT',
    description: 'Reverses the value of its argument.',
    parameters: [
      {
        name: 'logical',
        description: 'The value to reverse',
        type: 'boolean',
        required: true
      }
    ],
    example: 'NOT(true)',
    result: 'false'
  },
  {
    name: 'OR',
    description: 'Returns TRUE if any argument is TRUE.',
    parameters: [
      {
        name: 'logical1',
        description: 'The first condition to evaluate',
        type: 'boolean',
        required: true
      }
    ],
    example: 'OR(true, false, true)',
    result: 'true'
  },
  {
    name: 'SWITCH',
    description:
      'Evaluates an expression against a list of values and returns the result corresponding to the first matching value.',
    parameters: [
      {
        name: 'expression',
        description: 'The expression to evaluate',
        type: 'any',
        required: true
      },
      {
        name: 'value1',
        description: 'The first value to compare against',
        type: 'any',
        required: true
      },
      {
        name: 'result1',
        description: 'The result to return if value1 matches',
        type: 'any',
        required: true
      }
      // Additional values and results can be added as needed
    ],
    example: "SWITCH(7, 9, 'Nine', 7, 'Seven')",
    result: 'Seven'
  },
  {
    name: 'TRUE',
    description: 'Returns the logical value TRUE.',
    parameters: [],
    example: 'TRUE()',
    result: 'true'
  },
  {
    name: 'XOR',
    description: 'Returns a logical exclusive OR of all arguments.',
    parameters: [
      {
        name: 'logical1',
        description: 'The first condition to evaluate',
        type: 'boolean',
        required: true
      }
    ],
    example: 'XOR(true, false, true)',
    result: 'false'
  }
];

export const TEXT_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'CHAR',
    description: 'Returns the character specified by a code number.',
    parameters: [
      {
        name: 'number',
        description: 'The code number of the character',
        type: 'number',
        required: true
      }
    ],
    example: 'CHAR(65)',
    result: 'A'
  },
  {
    name: 'CLEAN',
    description: 'Removes all non-printable characters from text.',
    parameters: [
      {
        name: 'text',
        description: 'The text to clean',
        type: 'string',
        required: true
      }
    ],
    example: "CLEAN('Monthly report')",
    result: 'Monthly report'
  },
  {
    name: 'CODE',
    description:
      'Returns a numeric code for the first character in a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text to evaluate',
        type: 'string',
        required: true
      }
    ],
    example: "CODE('A')",
    result: '65'
  },
  {
    name: 'CONCATENATE',
    description: 'Joins two or more text strings into one string.',
    parameters: [
      {
        name: 'text1',
        description: 'The first text string',
        type: 'string',
        required: true
      },
      {
        name: 'text2',
        description: 'The second text string',
        type: 'string',
        required: true
      },
      {
        name: 'textN',
        description: 'Additional text strings',
        type: 'string',
        required: false
      }
    ],
    example: "CONCATENATE('Andreas', ' ', 'Hauser')",
    result: 'Andreas Hauser'
  },
  {
    name: 'EXACT',
    description:
      'Checks whether two text strings are exactly the same, and returns TRUE or FALSE.',
    parameters: [
      {
        name: 'text1',
        description: 'The first text string',
        type: 'string',
        required: true
      },
      {
        name: 'text2',
        description: 'The second text string',
        type: 'string',
        required: true
      }
    ],
    example: "EXACT('Word', 'word')",
    result: 'false'
  },
  {
    name: 'FIND',
    description: 'Finds one text value within another (case-sensitive).',
    parameters: [
      {
        name: 'find_text',
        description: 'The text to find',
        type: 'string',
        required: true
      },
      {
        name: 'within_text',
        description: 'The text to search within',
        type: 'string',
        required: true
      },
      {
        name: 'start_num',
        description: 'The position to start the search',
        type: 'number',
        required: false
      }
    ],
    example: "FIND('M', 'Miriam McGovern', 3)",
    result: '8'
  },
  {
    name: 'LEFT',
    description: 'Returns the leftmost characters from a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      },
      {
        name: 'num_chars',
        description: 'The number of characters to return',
        type: 'number',
        required: true
      }
    ],
    example: "LEFT('Sale Price', 4)",
    result: 'Sale'
  },
  {
    name: 'LEN',
    description: 'Returns the number of characters in a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      }
    ],
    example: "LEN('Phoenix, AZ')",
    result: '11'
  },
  {
    name: 'LOWER',
    description:
      'Converts all uppercase letters in a text string to lowercase.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      }
    ],
    example: "LOWER('E. E. Cummings')",
    result: 'e. e. cummings'
  },
  {
    name: 'MID',
    description:
      'Returns a specific number of characters from a text string, starting at the position you specify.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      },
      {
        name: 'start_num',
        description: 'The position to start extracting',
        type: 'number',
        required: true
      },
      {
        name: 'num_chars',
        description: 'The number of characters to return',
        type: 'number',
        required: true
      }
    ],
    example: "MID('Fluid Flow', 7, 20)",
    result: 'Flow'
  },
  {
    name: 'NUMBERVALUE',
    description: 'Converts text to a number.',
    parameters: [
      {
        name: 'text',
        description: 'The text to convert',
        type: 'string',
        required: true
      },
      {
        name: 'decimal_separator',
        description: 'The character used as the decimal separator',
        type: 'string',
        required: false
      },
      {
        name: 'group_separator',
        description: 'The character used as the group separator',
        type: 'string',
        required: false
      }
    ],
    example: "NUMBERVALUE('2.500,27', ',', '.')",
    result: '2500.27'
  },
  {
    name: 'PROPER',
    description: 'Capitalizes the first letter of each word in a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      }
    ],
    example: "PROPER('this is a TITLE')",
    result: 'This Is A Title'
  },
  {
    name: 'REGEXEXTRACT',
    description:
      'Extracts matching substrings according to a regular expression.',
    parameters: [
      {
        name: 'text',
        description: 'The text to search',
        type: 'string',
        required: true
      },
      {
        name: 'regex',
        description: 'The regular expression to match',
        type: 'string',
        required: true
      }
    ],
    example: "REGEXEXTRACT('Palo Alto', 'Alto')",
    result: 'Alto'
  },
  {
    name: 'REGEXMATCH',
    description: 'Returns TRUE if the text matches the regular expression.',
    parameters: [
      {
        name: 'text',
        description: 'The text to search',
        type: 'string',
        required: true
      },
      {
        name: 'regex',
        description: 'The regular expression to match',
        type: 'string',
        required: true
      }
    ],
    example: "REGEXMATCH('Palo Alto', 'Alto')",
    result: 'true'
  },
  {
    name: 'REGEXREPLACE',
    description:
      'Replaces text that matches a regular expression with a new text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text to search',
        type: 'string',
        required: true
      },
      {
        name: 'regex',
        description: 'The regular expression to match',
        type: 'string',
        required: true
      },
      {
        name: 'replacement',
        description: 'The text to replace with',
        type: 'string',
        required: true
      }
    ],
    example: "REGEXREPLACE('Sutoiku', 'utoiku', 'TOIC')",
    result: 'STOIC'
  },
  {
    name: 'REPLACE',
    description: 'Replaces part of a text string with a different text string.',
    parameters: [
      {
        name: 'old_text',
        description: 'The text to replace',
        type: 'string',
        required: true
      },
      {
        name: 'start_num',
        description: 'The position to start replacing',
        type: 'number',
        required: true
      },
      {
        name: 'num_chars',
        description: 'The number of characters to replace',
        type: 'number',
        required: true
      },
      {
        name: 'new_text',
        description: 'The text to replace with',
        type: 'string',
        required: true
      }
    ],
    example: "REPLACE('abcdefghijk', 6, 5, '*')",
    result: 'abcde*k'
  },
  {
    name: 'REPT',
    description: 'Repeats text a given number of times.',
    parameters: [
      {
        name: 'text',
        description: 'The text to repeat',
        type: 'string',
        required: true
      },
      {
        name: 'number_times',
        description: 'The number of times to repeat the text',
        type: 'number',
        required: true
      }
    ],
    example: "REPT('*-', 3)",
    result: '*-*-*-'
  },
  {
    name: 'RIGHT',
    description: 'Returns the rightmost characters from a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      },
      {
        name: 'num_chars',
        description: 'The number of characters to return',
        type: 'number',
        required: true
      }
    ],
    example: "RIGHT('Sale Price', 5)",
    result: 'Price'
  },
  {
    name: 'ROMAN',
    description: 'Converts an Arabic numeral to Roman numeral.',
    parameters: [
      {
        name: 'number',
        description: 'The number to convert',
        type: 'number',
        required: true
      },
      {
        name: 'form',
        description: 'The form of the Roman numeral',
        type: 'number',
        required: false
      }
    ],
    example: 'ROMAN(499)',
    result: 'CDXCIX'
  },
  {
    name: 'SEARCH',
    description: 'Finds one text value within another (case-insensitive).',
    parameters: [
      {
        name: 'find_text',
        description: 'The text to find',
        type: 'string',
        required: true
      },
      {
        name: 'within_text',
        description: 'The text to search within',
        type: 'string',
        required: true
      },
      {
        name: 'start_num',
        description: 'The position to start the search',
        type: 'number',
        required: false
      }
    ],
    example: "SEARCH('margin', 'Profit Margin')",
    result: '8'
  },
  {
    name: 'SPLIT',
    description:
      'Divides text around a specified character or string and puts each fragment into a separate cell in the row.',
    parameters: [
      {
        name: 'text',
        description: 'The text to split',
        type: 'string',
        required: true
      },
      {
        name: 'delimiter',
        description: 'The character or string to split by',
        type: 'string',
        required: true
      }
    ],
    example: "SPLIT('A,B,C', ',')",
    result: 'A,B,C'
  },
  {
    name: 'SUBSTITUTE',
    description: 'Substitutes new text for old text in a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text to modify',
        type: 'string',
        required: true
      },
      {
        name: 'old_text',
        description: 'The text to replace',
        type: 'string',
        required: true
      },
      {
        name: 'new_text',
        description: 'The text to replace with',
        type: 'string',
        required: true
      },
      {
        name: 'instance_num',
        description: 'The instance of old_text to replace',
        type: 'number',
        required: false
      }
    ],
    example: "SUBSTITUTE('Quarter 1, 2011', '1', '2', 3)",
    result: 'Quarter 1, 2012'
  },
  {
    name: 'T',
    description: 'Returns the text referred to by a value.',
    parameters: [
      {
        name: 'value',
        description: 'The value to evaluate',
        type: 'any',
        required: true
      }
    ],
    example: "T('Rainfall')",
    result: 'Rainfall'
  },
  {
    name: 'TRIM',
    description:
      'Removes all spaces from text except for single spaces between words.',
    parameters: [
      {
        name: 'text',
        description: 'The text to trim',
        type: 'string',
        required: true
      }
    ],
    example: "TRIM(' First Quarter Earnings ')",
    result: 'First Quarter Earnings'
  },
  {
    name: 'TEXTJOIN',
    description:
      'Joins text from multiple ranges and/or strings, with a specified delimiter.',
    parameters: [
      {
        name: 'delimiter',
        description: 'The delimiter to use',
        type: 'string',
        required: true
      },
      {
        name: 'ignore_empty',
        description: 'TRUE to ignore empty cells',
        type: 'boolean',
        required: true
      },
      {
        name: 'text1',
        description: 'The first text string',
        type: 'string',
        required: true
      },
      {
        name: 'textN',
        description: 'Additional text strings',
        type: 'string',
        required: false
      }
    ],
    example:
      "TEXTJOIN(' ', true, 'The', '', 'sun', 'will', 'come', 'up', 'tomorrow.')",
    result: 'The sun will come up tomorrow.'
  },
  {
    name: 'UNICHAR',
    description:
      'Returns the Unicode character that is referenced by the given code point.',
    parameters: [
      {
        name: 'code',
        description: 'The Unicode code point',
        type: 'number',
        required: true
      }
    ],
    example: 'UNICHAR(66)',
    result: 'B'
  },
  {
    name: 'UNICODE',
    description:
      'Returns the Unicode value of the first character in a text string.',
    parameters: [
      {
        name: 'text',
        description: 'The text to evaluate',
        type: 'string',
        required: true
      }
    ],
    example: "UNICODE('B')",
    result: '66'
  },
  {
    name: 'UPPER',
    description:
      'Converts all lowercase letters in a text string to uppercase.',
    parameters: [
      {
        name: 'text',
        description: 'The text string',
        type: 'string',
        required: true
      }
    ],
    example: "UPPER('total')",
    result: 'TOTAL'
  }
];

export const DATE_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'DATE',
    description: 'Returns the serial number of a particular date.',
    parameters: [
      {
        name: 'year',
        description: 'The year of the date',
        type: 'number',
        required: true
      },
      {
        name: 'month',
        description: 'The month of the date',
        type: 'number',
        required: true
      },
      {
        name: 'day',
        description: 'The day of the date',
        type: 'number',
        required: true
      }
    ],
    example: 'DATE(2008, 7, 8)',
    result: 'Tue Jul 08 2008 00:00:00 GMT-0700 (PDT)'
  },
  {
    name: 'DATEVALUE',
    description: 'Converts a date in the form of text to a serial number.',
    parameters: [
      {
        name: 'date_text',
        description: 'The date in text format',
        type: 'string',
        required: true
      }
    ],
    example: "DATEVALUE('8/22/2011')",
    result: 'Mon Aug 22 2011 00:00:00 GMT-0700 (PDT)'
  },
  {
    name: 'DAY',
    description: 'Returns the day of a date, represented by a serial number.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "DAY('15-Apr-11')",
    result: '15'
  },
  {
    name: 'DAYS',
    description: 'Returns the number of days between two dates.',
    parameters: [
      {
        name: 'end_date',
        description: 'The end date',
        type: 'Date',
        required: true
      },
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      }
    ],
    example: "DAYS('3/15/11', '2/1/11')",
    result: '42'
  },
  {
    name: 'DAYS360',
    description:
      'Calculates the number of days between two dates based on a 360-day year.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'end_date',
        description: 'The end date',
        type: 'Date',
        required: true
      },
      {
        name: 'method',
        description: 'TRUE for US method, FALSE for European method',
        type: 'boolean',
        required: false
      }
    ],
    example: "DAYS360('1-Jan-11', '31-Dec-11')",
    result: '360'
  },
  {
    name: 'EDATE',
    description:
      'Returns the serial number of the date that is the indicated number of months before or after a specified date.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'months',
        description: 'The number of months to add',
        type: 'number',
        required: true
      }
    ],
    example: "EDATE('1/15/11', -1)",
    result: 'Wed Dec 15 2010 00:00:00 GMT-0800 (PST)'
  },
  {
    name: 'EOMONTH',
    description:
      'Returns the serial number of the last day of the month that is the indicated number of months before or after a specified date.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'months',
        description: 'The number of months to add',
        type: 'number',
        required: true
      }
    ],
    example: "EOMONTH('1/1/11', -3)",
    result: 'Sun Oct 31 2010 00:00:00 GMT-0700 (PDT)'
  },
  {
    name: 'HOUR',
    description: 'Returns the hour of a time value.',
    parameters: [
      {
        name: 'time',
        description: 'The time to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "HOUR('7/18/2011 7:45:00 AM')",
    result: '7'
  },
  {
    name: 'MINUTE',
    description: 'Returns the minute of a time value.',
    parameters: [
      {
        name: 'time',
        description: 'The time to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "MINUTE('2/1/2011 12:45:00 PM')",
    result: '45'
  },
  {
    name: 'ISOWEEKNUM',
    description: 'Returns the ISO week number of a given date.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "ISOWEEKNUM('3/9/2012')",
    result: '10'
  },
  {
    name: 'MONTH',
    description: 'Returns the month of a date, represented by a serial number.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "MONTH('15-Apr-11')",
    result: '4'
  },
  {
    name: 'NETWORKDAYS',
    description: 'Returns the number of whole workdays between two dates.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'end_date',
        description: 'The end date',
        type: 'Date',
        required: true
      },
      {
        name: 'holidays',
        description: 'An array of dates to exclude',
        type: 'Date[]',
        required: false
      }
    ],
    example: "NETWORKDAYS('10/1/2012', '3/1/2013', ['11/22/2012'])",
    result: '109'
  },
  {
    name: 'NETWORKDAYSINTL',
    description:
      'Returns the number of whole workdays between two dates with custom weekend days.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'end_date',
        description: 'The end date',
        type: 'Date',
        required: true
      },
      {
        name: 'weekend',
        description: 'A string that specifies which days are weekends',
        type: 'string',
        required: false
      },
      {
        name: 'holidays',
        description: 'An array of dates to exclude',
        type: 'Date[]',
        required: false
      }
    ],
    example: "NETWORKDAYSINTL('1/1/2006', '2/1/2006', 7, ['1/2/2006'])",
    result: '23'
  },
  {
    name: 'NOW',
    description: 'Returns the current date and time.',
    parameters: [],
    example: 'NOW()',
    result: 'Thu Feb 20 2020 23:02:55 GMT+0100 (Central European Standard Time)'
  },
  {
    name: 'SECOND',
    description: 'Returns the second of a time value.',
    parameters: [
      {
        name: 'time',
        description: 'The time to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "SECOND('2/1/2011 4:48:18 PM')",
    result: '18'
  },
  {
    name: 'TIME',
    description: 'Returns the serial number of a particular time.',
    parameters: [
      {
        name: 'hour',
        description: 'The hour of the time',
        type: 'number',
        required: true
      },
      {
        name: 'minute',
        description: 'The minute of the time',
        type: 'number',
        required: true
      },
      {
        name: 'second',
        description: 'The second of the time',
        type: 'number',
        required: true
      }
    ],
    example: 'TIME(16, 48, 10)',
    result: '0.7001157407407408'
  },
  {
    name: 'TIMEVALUE',
    description: 'Converts a time in the form of text to a serial number.',
    parameters: [
      {
        name: 'time_text',
        description: 'The time in text format',
        type: 'string',
        required: true
      }
    ],
    example: "TIMEVALUE('22-Aug-2011 6:35 AM')",
    result: '0.2743055555555556'
  },
  {
    name: 'TODAY',
    description: 'Returns the current date.',
    parameters: [],
    example: 'TODAY()',
    result: 'Thu Feb 20 2020 23:02:55 GMT+0100 (Central European Standard Time)'
  },
  {
    name: 'WEEKDAY',
    description: 'Returns the day of the week corresponding to a date.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      },
      {
        name: 'return_type',
        description:
          'A number that specifies which day is the first day of the week',
        type: 'number',
        required: false
      }
    ],
    example: "WEEKDAY('2/14/2008', 3)",
    result: '3'
  },
  {
    name: 'YEAR',
    description: 'Returns the year of a date, represented by a serial number.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      }
    ],
    example: "YEAR('7/5/2008')",
    result: '2008'
  },
  {
    name: 'WEEKNUM',
    description: 'Returns the week number of a specific date.',
    parameters: [
      {
        name: 'date',
        description: 'The date to evaluate',
        type: 'Date',
        required: true
      },
      {
        name: 'return_type',
        description: 'A number that specifies how the week is calculated',
        type: 'number',
        required: false
      }
    ],
    example: "WEEKNUM('3/9/2012', 2)",
    result: '11'
  },
  {
    name: 'WORKDAY',
    description:
      'Returns the serial number of the date before or after a specified number of workdays.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'days',
        description: 'The number of workdays to add',
        type: 'number',
        required: true
      },
      {
        name: 'holidays',
        description: 'An array of dates to exclude',
        type: 'Date[]',
        required: false
      }
    ],
    example: "WORKDAY('10/1/2008', 151, ['11/26/2008', '12/4/2008'])",
    result: 'Mon May 04 2009 00:00:00 GMT-0700 (PDT)'
  },
  {
    name: 'WORKDAYINTL',
    description:
      'Returns the serial number of the date before or after a specified number of workdays with custom weekend days.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'days',
        description: 'The number of workdays to add',
        type: 'number',
        required: true
      },
      {
        name: 'weekend',
        description: 'A string that specifies which days are weekends',
        type: 'string',
        required: false
      },
      {
        name: 'holidays',
        description: 'An array of dates to exclude',
        type: 'Date[]',
        required: false
      }
    ],
    example: "WORKDAYINTL('1/1/2012', 30, 17)",
    result: 'Sun Feb 05 2012 00:00:00 GMT-0800 (PST)'
  },
  {
    name: 'YEARFRAC',
    description:
      'Returns the year fraction representing the number of whole days between two dates.',
    parameters: [
      {
        name: 'start_date',
        description: 'The start date',
        type: 'Date',
        required: true
      },
      {
        name: 'end_date',
        description: 'The end date',
        type: 'Date',
        required: true
      },
      {
        name: 'basis',
        description: 'The type of day count basis to use',
        type: 'number',
        required: false
      }
    ],
    example: "YEARFRAC('1/1/2012', '7/30/2012', 3)",
    result: '0.5780821917808219'
  }
];

export const ENGINEERING_FUNCTIONS: FormulaFunction[] = [
  {
    name: 'BIN2DEC',
    description: 'Converts a binary number to decimal.',
    parameters: [
      {
        name: 'number',
        description: 'The binary number as a string',
        type: 'string',
        required: true
      }
    ],
    example: 'BIN2DEC(101010)',
    result: '42'
  },
  {
    name: 'BIN2HEX',
    description: 'Converts a binary number to hexadecimal.',
    parameters: [
      {
        name: 'number',
        description: 'The binary number as a string',
        type: 'string',
        required: true
      }
    ],
    example: 'BIN2HEX(101010)',
    result: '2a'
  },
  {
    name: 'BIN2OCT',
    description: 'Converts a binary number to octal.',
    parameters: [
      {
        name: 'number',
        description: 'The binary number as a string',
        type: 'string',
        required: true
      }
    ],
    example: 'BIN2OCT(101010)',
    result: '52'
  },
  {
    name: 'BITAND',
    description: 'Returns a bitwise AND of two numbers.',
    parameters: [
      {
        name: 'number1',
        description: 'The first number',
        type: 'number',
        required: true
      },
      {
        name: 'number2',
        description: 'The second number',
        type: 'number',
        required: true
      }
    ],
    example: 'BITAND(42, 24)',
    result: '8'
  },
  {
    name: 'BITLSHIFT',
    description: 'Returns a number shifted left by a specified number of bits.',
    parameters: [
      {
        name: 'number',
        description: 'The number to shift',
        type: 'number',
        required: true
      },
      {
        name: 'shift_amount',
        description: 'The number of bits to shift',
        type: 'number',
        required: true
      }
    ],
    example: 'BITLSHIFT(42, 24)',
    result: '704643072'
  },
  {
    name: 'BITOR',
    description: 'Returns a bitwise OR of two numbers.',
    parameters: [
      {
        name: 'number1',
        description: 'The first number',
        type: 'number',
        required: true
      },
      {
        name: 'number2',
        description: 'The second number',
        type: 'number',
        required: true
      }
    ],
    example: 'BITOR(42, 24)',
    result: '58'
  },
  {
    name: 'BITRSHIFT',
    description:
      'Returns a number shifted right by a specified number of bits.',
    parameters: [
      {
        name: 'number',
        description: 'The number to shift',
        type: 'number',
        required: true
      },
      {
        name: 'shift_amount',
        description: 'The number of bits to shift',
        type: 'number',
        required: true
      }
    ],
    example: 'BITRSHIFT(42, 2)',
    result: '10'
  },
  {
    name: 'BITXOR',
    description: 'Returns a bitwise XOR of two numbers.',
    parameters: [
      {
        name: 'number1',
        description: 'The first number',
        type: 'number',
        required: true
      },
      {
        name: 'number2',
        description: 'The second number',
        type: 'number',
        required: true
      }
    ],
    example: 'BITXOR(42, 24)',
    result: '50'
  },
  {
    name: 'COMPLEX',
    description: 'Returns a complex number as a text string.',
    parameters: [
      {
        name: 'real_num',
        description: 'The real number part',
        type: 'number',
        required: true
      },
      {
        name: 'imaginary_num',
        description: 'The imaginary number part',
        type: 'number',
        required: true
      },
      {
        name: 'suffix',
        description: 'The suffix for the imaginary part',
        type: 'string',
        required: false
      }
    ],
    example: 'COMPLEX(3, 4)',
    result: '3+4i'
  },
  {
    name: 'CONVERT',
    description: 'Converts a number from one measurement system to another.',
    parameters: [
      {
        name: 'number',
        description: 'The number to convert',
        type: 'number',
        required: true
      },
      {
        name: 'from_unit',
        description: 'The unit to convert from',
        type: 'string',
        required: true
      },
      {
        name: 'to_unit',
        description: 'The unit to convert to',
        type: 'string',
        required: true
      }
    ],
    example: "CONVERT(64, 'kibyte', 'bit')",
    result: '524288'
  },
  {
    name: 'DEC2BIN',
    description: 'Converts a decimal number to binary.',
    parameters: [
      {
        name: 'number',
        description: 'The decimal number',
        type: 'number',
        required: true
      },
      {
        name: 'places',
        description: 'The number of bits to return',
        type: 'number',
        required: false
      }
    ],
    example: 'DEC2BIN(42)',
    result: '101010'
  },
  {
    name: 'DEC2HEX',
    description: 'Converts a decimal number to hexadecimal.',
    parameters: [
      {
        name: 'number',
        description: 'The decimal number',
        type: 'number',
        required: true
      },
      {
        name: 'places',
        description: 'The number of characters to return',
        type: 'number',
        required: false
      }
    ],
    example: 'DEC2HEX(42)',
    result: '2a'
  },
  {
    name: 'DEC2OCT',
    description: 'Converts a decimal number to octal.',
    parameters: [
      {
        name: 'number',
        description: 'The decimal number',
        type: 'number',
        required: true
      },
      {
        name: 'places',
        description: 'The number of characters to return',
        type: 'number',
        required: false
      }
    ],
    example: 'DEC2OCT(42)',
    result: '52'
  },
  {
    name: 'DELTA',
    description: 'Returns 1 if two values are equal, and 0 otherwise.',
    parameters: [
      {
        name: 'number1',
        description: 'The first number',
        type: 'number',
        required: true
      },
      {
        name: 'number2',
        description: 'The second number',
        type: 'number',
        required: false
      }
    ],
    example: 'DELTA(42, 42)',
    result: '1'
  },
  {
    name: 'ERF',
    description:
      'Returns the error function integrated between the limits of 0 and a specified value.',
    parameters: [
      {
        name: 'x',
        description: 'The upper limit of integration',
        type: 'number',
        required: true
      }
    ],
    example: 'ERF(1)',
    result: '0.8427007929497149'
  },
  {
    name: 'ERFC',
    description: 'Returns the complementary error function.',
    parameters: [
      {
        name: 'x',
        description: 'The upper limit of integration',
        type: 'number',
        required: true
      }
    ],
    example: 'ERFC(1)',
    result: '0.1572992070502851'
  },
  {
    name: 'GESTEP',
    description:
      'Returns 1 if the value is greater than or equal to the step value, and 0 otherwise.',
    parameters: [
      {
        name: 'number',
        description: 'The value to evaluate',
        type: 'number',
        required: true
      },
      {
        name: 'step',
        description: 'The step value',
        type: 'number',
        required: false
      }
    ],
    example: 'GESTEP(42, 24)',
    result: '1'
  },
  {
    name: 'HEX2BIN',
    description: 'Converts a hexadecimal number to binary.',
    parameters: [
      {
        name: 'number',
        description: 'The hexadecimal number as a string',
        type: 'string',
        required: true
      },
      {
        name: 'places',
        description: 'The number of bits to return',
        type: 'number',
        required: false
      }
    ],
    example: "HEX2BIN('2a')",
    result: '101010'
  },
  {
    name: 'HEX2DEC',
    description: 'Converts a hexadecimal number to decimal.',
    parameters: [
      {
        name: 'number',
        description: 'The hexadecimal number as a string',
        type: 'string',
        required: true
      }
    ],
    example: "HEX2DEC('2a')",
    result: '42'
  },
  {
    name: 'HEX2OCT',
    description: 'Converts a hexadecimal number to octal.',
    parameters: [
      {
        name: 'number',
        description: 'The hexadecimal number as a string',
        type: 'string',
        required: true
      }
    ],
    example: "HEX2OCT('2a')",
    result: '52'
  },
  {
    name: 'IMABS',
    description: 'Returns the absolute value (modulus) of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMABS('3+4i')",
    result: '5'
  },
  {
    name: 'IMAGINARY',
    description: 'Returns the imaginary coefficient of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMAGINARY('3+4i')",
    result: '4'
  },
  {
    name: 'IMARGUMENT',
    description: 'Returns the argument (angle) of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMARGUMENT('3+4i')",
    result: '0.9272952180016122'
  },
  {
    name: 'IMCONJUGATE',
    description: 'Returns the complex conjugate of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCONJUGATE('3+4i')",
    result: '3-4i'
  },
  {
    name: 'IMCOS',
    description: 'Returns the cosine of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCOS('1+i')",
    result: '0.8337300251311491-0.9888977057628651i'
  },
  {
    name: 'IMCOSH',
    description: 'Returns the hyperbolic cosine of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCOSH('1+i')",
    result: '0.8337300251311491+0.9888977057628651i'
  },
  {
    name: 'IMCOT',
    description: 'Returns the cotangent of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCOT('1+i')",
    result: '0.21762156185440265-0.8680141428959249i'
  },
  {
    name: 'IMCSC',
    description: 'Returns the cosecant of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCSC('1+i')",
    result: '0.6215180171704283-0.3039310016284264i'
  },
  {
    name: 'IMCSCH',
    description: 'Returns the hyperbolic cosecant of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMCSCH('1+i')",
    result: '0.3039310016284264-0.6215180171704283i'
  },
  {
    name: 'IMDIV',
    description: 'Returns the quotient of two complex numbers.',
    parameters: [
      {
        name: 'inumber1',
        description: 'The numerator complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumber2',
        description: 'The denominator complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMDIV('1+2i', '3+4i')",
    result: '0.44+0.08i'
  },
  {
    name: 'IMEXP',
    description: 'Returns the exponential of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMEXP('1+i')",
    result: '1.4686939399158851+2.2873552871788423i'
  },
  {
    name: 'IMLN',
    description: 'Returns the natural logarithm of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMLN('1+i')",
    result: '0.3465735902799727+0.7853981633974483i'
  },
  {
    name: 'IMLOG10',
    description: 'Returns the base-10 logarithm of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMLOG10('1+i')",
    result: '0.1505149978319906+0.3410940884604603i'
  },
  {
    name: 'IMLOG2',
    description: 'Returns the base-2 logarithm of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMLOG2('1+i')",
    result: '0.5000000000000001+1.1330900354567985i'
  },
  {
    name: 'IMPOWER',
    description: 'Returns a complex number raised to a power.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      },
      {
        name: 'power',
        description: 'The power to raise the complex number to',
        type: 'number',
        required: true
      }
    ],
    example: "IMPOWER('1+i', 2)",
    result: '1.2246063538223775e-16+2.0000000000000004i'
  },
  {
    name: 'IMPRODUCT',
    description: 'Returns the product of two or more complex numbers.',
    parameters: [
      {
        name: 'inumber1',
        description: 'The first complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumber2',
        description: 'The second complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumberN',
        description: 'Additional complex numbers',
        type: 'string',
        required: false
      }
    ],
    example: "IMPRODUCT('1+2i', '3+4i', '5+6i')",
    result: '-85+20i'
  },
  {
    name: 'IMREAL',
    description: 'Returns the real coefficient of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMREAL('3+4i')",
    result: '3'
  },
  {
    name: 'IMSEC',
    description: 'Returns the secant of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSEC('1+i')",
    result: '0.4983370305551868+0.591083841721045i'
  },
  {
    name: 'IMSECH',
    description: 'Returns the hyperbolic secant of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSECH('1+i')",
    result: '0.4983370305551868-0.591083841721045i'
  },
  {
    name: 'IMSIN',
    description: 'Returns the sine of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSIN('1+i')",
    result: '1.2984575814159773+0.6349639147847361i'
  },
  {
    name: 'IMSINH',
    description: 'Returns the hyperbolic sine of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSINH('1+i')",
    result: '0.6349639147847361+1.2984575814159773i'
  },
  {
    name: 'IMSQRT',
    description: 'Returns the square root of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSQRT('1+i')",
    result: '1.0986841134678098+0.45508986056222733i'
  },
  {
    name: 'IMSUB',
    description: 'Returns the difference of two complex numbers.',
    parameters: [
      {
        name: 'inumber1',
        description: 'The first complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumber2',
        description: 'The second complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMSUB('3+4i', '1+2i')",
    result: '2+2i'
  },
  {
    name: 'IMSUM',
    description: 'Returns the sum of two or more complex numbers.',
    parameters: [
      {
        name: 'inumber1',
        description: 'The first complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumber2',
        description: 'The second complex number',
        type: 'string',
        required: true
      },
      {
        name: 'inumberN',
        description: 'Additional complex numbers',
        type: 'string',
        required: false
      }
    ],
    example: "IMSUM('1+2i', '3+4i', '5+6i')",
    result: '9+12i'
  },
  {
    name: 'IMTAN',
    description: 'Returns the tangent of a complex number.',
    parameters: [
      {
        name: 'inumber',
        description: 'The complex number',
        type: 'string',
        required: true
      }
    ],
    example: "IMTAN('1+i')",
    result: '0.2717525853195117+1.0839233273386946i'
  },
  {
    name: 'OCT2BIN',
    description: 'Converts an octal number to binary.',
    parameters: [
      {
        name: 'number',
        description: 'The octal number as a string',
        type: 'string',
        required: true
      },
      {
        name: 'places',
        description: 'The number of bits to return',
        type: 'number',
        required: false
      }
    ],
    example: "OCT2BIN('52')",
    result: '101010'
  },
  {
    name: 'OCT2DEC',
    description: 'Converts an octal number to decimal.',
    parameters: [
      {
        name: 'number',
        description: 'The octal number as a string',
        type: 'string',
        required: true
      }
    ],
    example: "OCT2DEC('52')",
    result: '42'
  },
  {
    name: 'OCT2HEX',
    description: 'Converts an octal number to hexadecimal.',
    parameters: [
      {
        name: 'number',
        description: 'The octal number as a string',
        type: 'string',
        required: true
      }
    ],
    example: "OCT2HEX('52')",
    result: '2a'
  }
];

// Combine mathematical functions with any additional functions from formulajs
export const FORMULA_FUNCTIONS: FormulaFunction[] = [
  ...MATH_FUNCTIONS,
  ...STATISTICAL_FUNCTIONS
  // ...LOGICAL_FUNCTIONS,
  // ...ENGINEERING_FUNCTIONS,
  // ...DATE_FUNCTIONS,
  // ...TEXT_FUNCTIONS
];
