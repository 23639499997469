import {
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { POPUP_CLICK_TYPE, POPUP_TYPE } from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { selectUsersList } from '../../Redux/Slices/PermissionsSlice';
import LabelTemplateService from '../../Services/LabelTemplates';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import {
  FIELD_TYPE,
  getStyleFromValue,
  LABEL_DOCUMENT_TYPE,
  LabelTemplateData
} from './LabelTemplateHelper';
import LabelTemplateView from './LabelTemplateView';

interface GenerateLabelPopupProps {
  onCancel: () => void;
  documentType: LABEL_DOCUMENT_TYPE;
  documentData: any;
  hideProductField?: boolean;
}

const GenerateLabelPopup: React.FC<GenerateLabelPopupProps> = ({
  onCancel,
  documentType,
  documentData,
  hideProductField
}: GenerateLabelPopupProps) => {
  const [labelTemplate, setLabelTemplate] = useState<LabelTemplateData>();
  const [labelTemplateToRender, setLabelTemplateToRender] =
    useState<LabelTemplateData>();
  const [templateList, setTemplateList] = useState<any>([]);
  const [lineItems, setLineItems] = useState<any>([]);
  const [selectedLineItem, setSelectedLineItem] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const usersListData = useAppSelector(selectUsersList);
  const printRef = useRef<any>(null);

  useEffect(() => {
    if (documentData && documentType) {
      let itemList = [];
      switch (documentType) {
        case LABEL_DOCUMENT_TYPE.SALES_ORDER:
          itemList = documentData?.salesOrderItems;
          break;
        case LABEL_DOCUMENT_TYPE.SALES_INVOICE:
          itemList = documentData?.salesInvoiceItems;
          break;
        case LABEL_DOCUMENT_TYPE.PURCHASE_ORDER:
          itemList = documentData?.purchaseOrderItems;
          break;
        case LABEL_DOCUMENT_TYPE.PURCHASE_INVOICE:
          itemList = documentData?.purchaseInvoiceProducts;
          break;
        case LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING:
          itemList = [documentData];
          break;
      }
      if (itemList.length) {
        itemList.forEach(
          (item: any) => (item.productName = item?.product?.name)
        );
      }
      setLineItems(itemList);
      if (itemList?.length > 0) {
        setSelectedLineItem(itemList[0]);
      }
    }
  }, [documentData]);

  useEffect(() => {
    // fetch templates
    loadLabelTemplate();
  }, []);

  useEffect(() => {
    if (labelTemplate && selectedLineItem) {
      onLabelTemplateChange();
    }
  }, [labelTemplate, selectedLineItem]);

  let loadLabelTemplate = () => {
    setIsLoading(true);
    LabelTemplateService.getLabelTemplateByType(documentType)
      .then((res: any) => {
        if (res.length) {
          let parsedData = res?.map((item: any) => ({
            ...item,
            templateData: JSON.parse(item.templateData),
            labelSettings: JSON.parse(item.labelSettings)
          }));
          setTemplateList(parsedData);
          setLabelTemplate(parsedData[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let onLabelTemplateChange = () => {
    let getValue: any = (obj: any, key: string[]) => {
      if (key.length > 1) {
        let newObj = obj[key[0]];
        key.splice(0, 1);
        return newObj ? getValue(newObj, key) : '';
      }
      return obj[key[0]] || '';
    };

    if (labelTemplate) {
      let updatedLabelTemplate = { ...labelTemplate };
      updatedLabelTemplate?.templateData.forEach((row) => {
        row.columns.forEach((col) => {
          col.items.forEach((item) => {
            const ignoreFields = [FIELD_TYPE.CUSTOM_TEXT, FIELD_TYPE.IMAGE];
            if (!ignoreFields.includes(item?.type as string)) {
              if (item.isCustomField) {
                let cfList: any[] = [];
                if (item.isProductField) {
                  cfList = selectedLineItem.customField;
                } else {
                  cfList = documentData.customField;
                }
                item.value = null;
                if (cfList.length) {
                  const cf: any = cfList.find(
                    (cfItem: any) => cfItem.code === item.key
                  );
                  if (cf) {
                    item.value = cf.value;
                  }
                }
              } else {
                if (item.isProductField) {
                  item.value = getValue(selectedLineItem, item.key.split('.'));
                } else {
                  item.value = getValue(documentData, item.key.split('.'));
                }
              }
              if (item.type === FIELD_TYPE.USER && item.value) {
                let user = usersListData.find(
                  (userItem) => userItem.iamUserId === parseInt(item.value)
                );
                if (user) {
                  item.value = `${user.firstName} ${user.lastName}`.trim();
                }
              }
            }
          });
        });
      });
      setLabelTemplateToRender(updatedLabelTemplate);
    }
  };

  const handleThermalPrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true
  });

  return (
    <PopupWrapper
      type={POPUP_TYPE.POPUP}
      title={`Print Label`}
      btnList={[
        {
          title: 'Cancel',
          class: 'bg-gray1 border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: 'Print',
          class: 'bg-app text-white',
          clickAction: POPUP_CLICK_TYPE.PRINT_DOCUMENT
        }
      ]}
      clickAction={({ type }: any) => {
        if (type === POPUP_CLICK_TYPE.CLOSE_POPUP) {
          onCancel();
        } else if (type === POPUP_CLICK_TYPE.PRINT_DOCUMENT) {
          handleThermalPrint();
        }
      }}
      height={'auto'}
      width={600}
      disableClickOutside={true}
    >
      <div
        className="parent-width p-s justify-content-between border-radius-m"
        style={{ minHeight: 250 }}
      >
        <div
          className="row mt-xs"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
            className={''}
            value={labelTemplate}
            formatter={(obj: any) => {
              return obj.templateName;
            }}
            title="Label Template"
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => setLabelTemplate(value)}
            dropdownConfig={{
              title: 'Select Template',
              allowSearch: true,
              searchableKey: 'templateName',
              style: { minWidth: 250 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: templateList,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj.templateName}`} />;
              }
            }}
          />
        {(hideProductField==null || hideProductField ==false)  &&  <DKInput
            titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
            className={''}
            value={selectedLineItem}
            formatter={(obj: any) => {
              return obj.product?.name;
            }}
            title="Product"
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => setSelectedLineItem(value)}
            dropdownConfig={{
              title: 'Select Product',
              allowSearch: true,
              searchableKey: 'productName',
              style: { minWidth: 250 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: lineItems,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj.product?.name}`} />;
              }
            }}
          />
}
        </div>
        {isLoading ? (
          <DKSpinner />
        ) : labelTemplateToRender ? (
          <div className="mt-3 flex justify-content-center" ref={printRef}>
            <LabelTemplateView
              labelTemplate={labelTemplateToRender}
              isReadOnly={true}
            />
          </div>
        ) : (
          <DKLabel
            text="No label template to print."
            className="mt-l"
            style={{ textAlign: 'center' }}
          />
        )}
      </div>
    </PopupWrapper>
  );
};

export default GenerateLabelPopup;
