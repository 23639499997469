import {
  DKButton,
  DKCheckMark,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_resend from '../../../Assets/Icons/ic_resend.svg';
import ic_warning_red from '../../../Assets/Icons/ic_warning_red.png';
import {
  DOCUMENT_TYPE,
  DOC_TYPE,
  MODULE_TYPE,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchSerialTrackingProducts,
  selectSerialTrackingProduct
} from '../../../Redux/Slices/SerialTrackingSlice';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import WarehouseService from '../../../Services/Warehouse';
import SourceDestinationWarehouseManagementRRB from '../../../SharedComponents/WarehouseManagement/SourceDestinationWarehouseManagementRRB';
import Utility, {
  deepClone,
  sortSerialNumbers
} from '../../../Utility/Utility';
import {
  fetchJWOstockTransfer,
  fetchSerialTrackingProductsJWO,
  selectSerialTrackingProductJWO
} from '../../../Redux/Slices/SerialTrackingProductJWOSlice';
import { t } from 'i18next';
import { DKSegmentControl, DKLine } from 'deskera-ui-library';
import { getNewColumn } from '../../Accounting/JournalEntry/JEHelper';
import { selectBatchSerialCustomFields } from '../../../Redux/Slices/CommonDataSlice';
import {
  addProductCustomFieldsToLineItem,
  getLineItemCFs
} from '../../../SharedComponents/CustomFieldsHolder/BatchSerialCustomFieldsHelper';

export default function StockTransferAdvancedSerialTracking(props: any) {
  const dispatch = useAppDispatch();
  const selectSerialTrackingProductData = useAppSelector(
    selectSerialTrackingProduct
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [item, setItem] = useState(props.itemDetails);
  const [stockTransferItem, setStockTransferItem] = useState(
    props.stockTransferData
  );
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
  const [existingAllocatedSerial, setExistingAllocatedSerial] = useState<any[]>(
    []
  );
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState(
    props.defaultProductWarehouse
  );
  const [moduleName, setModuleName] = useState(props.module);
  const [availableSerialData, setAvailableSerialData] = useState<any[]>([]);
  const [availableSerialDataNonFiltered, setAvailableSerialDataNonFiltered] =
    useState<any[]>([]);

  const [allocatedSerialData, setAllocatedSerialData] = useState<any[]>([]);
  const [allocatedTapped, setAllocatedTapped] = useState(false);
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [isAllAvailableSerialSelected, setIsAllAvailableSerialSelected] =
    useState(false);
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const [isAllSerialSelected, setIsAllSerialSelected] = useState(false);
  const productInventoryWarehouse = props?.selectedWarehouseProductsData;
  const [selectedSerial, setSelectedSerial] = useState<any[]>([]);
  const [serialData, setSerialData] = useState<any[]>([]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    item?.requiredQuantity * props.buildQuantity
    // item?.productQuantity
  );
  const [stocksAvailable, setStockAvailable] = useState(
    item?.product?.availableQuantity
  );
  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [advancedTrackingMetaData, setAdvancedTrackingMetaData] = useState(
    item && item.advancedTrackingMetaData ? item.advancedTrackingMetaData : []
  );
  const [newSerial, setNewSerial] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [totalAllocatedQty, setTotalAllocatedQty] = useState(0);
  const warehousesData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const [sourceRRB, setSourceRRB] = useState<any>({
    srcRowCode: null,
    srcRackCode: null,
    srcBinCode: null
  });
  const [destinationRRB, setDestinationRRB] = useState<any>({
    destRowCode: null,
    destRackCode: null,
    destBinCode: null
  });
  const usedQty = props.stockTransferData[props.currentIndex]
    ?.stockTransferWarehouseInventoryData
    ? props.stockTransferData[props.currentIndex]
        ?.stockTransferWarehouseInventoryData.quantity
    : 0;
  const [sharedAvailableQty, setSharedAvailableQty] = useState(
    props.stockTransferData && props.stockTransferData[currentIndex]
      ? props.stockTransferData[currentIndex].availableQuantity + usedQty
      : 0
  );
  const [readOnlyTargetWarehouseRRB, setReadOnlyTargetWarehouseRRB] =
    useState(false);

  const [isEditSourceWh, setIsEditSourceWh] = useState<boolean>(false);
  const [jwoWarehouseFetched, setJwoWarehouseFetched] = useState<any>();
  const selectProductJWOList = useAppSelector(selectSerialTrackingProductJWO);
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(0);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);

  useEffect(() => {
    getSerialTrackingGridColumns();
    loadJWOWH();
    dispatch(
      fetchSerialTrackingProducts({
        productCode: props.itemDetails.pid
          ? props.itemDetails.pid
          : props.itemDetails.productId,
        enableQCWarehouse: false,
        includeRejectedWarehouse: true
      })
    );
    getActiveWarehouses();
    setDefaultActiveWarehouse();
    // loadProductInventory();
    if (!Utility.isEmpty(props.targetWarehouse)) {
      if (!Utility.isEmpty(productInventoryWarehouse)) {
        if (productInventoryWarehouse) {
          let warehouseIdWithInventory: any[] = [];
          productInventoryWarehouse.forEach((piw: any) => {
            if (
              piw.productAvailableQuantity &&
              piw.productAvailableQuantity[item.pid] &&
              piw.productAvailableQuantity[item.pid] > 0
            ) {
              //get list of warehouse where product is available
              warehouseIdWithInventory.push(piw.id);
            }
          });
          if (warehouseIdWithInventory.length > 0) {
            //remove targetwarehouse from list
            let x = warehouseIdWithInventory.filter(
              (w: any) => w.id !== props.targetWarehouse.id
            );
            if (x && x.length > 0) {
              //set target warehouse if there are inventory in other warehouse
              let tmp = productInventoryWarehouse.filter(
                (w: any) => w.id === props.targetWarehouse.id
              );
              if (tmp && tmp.length > 0) {
                setDestinationWarehouse(tmp[0]);
                setReadOnlyTarget(true);
              }
            }
          }
        }
      }
    }
    resetAllocation();
  }, []);

  const loadJWOWH = async () => {
    let findJWOWarehouse = warehousesData?.content?.find(
      (warehouse: any) => warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
    );

    if (Utility.isEmpty(findJWOWarehouse)) {
      const jwoWH = await WarehouseService.getJWOWarehouses();
      if (Utility.isNotEmpty(jwoWH)) {
        setJwoWarehouseFetched(jwoWH);
      }
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      props.stockTransferDocumentDetails?.jwoCode
    ) {
      dispatch(
        fetchJWOstockTransfer(props.stockTransferDocumentDetails?.jwoCode)
      );
    }
  };
  useEffect(() => {
    let tempAvailableSeraialData = availableSerialDataNonFiltered.filter(
      (serial: any) => {
        return (
          serial.rowCode === sourceRRB?.srcRowCode &&
          serial.rackCode === sourceRRB?.srcRackCode &&
          serial.binCode === sourceRRB?.srcBinCode
        );
      }
    );
    // if (!isEditSourceWh) {
    setAvailableSerialData(tempAvailableSeraialData);
    // }
    setIsEditSourceWh(true);
  }, [sourceRRB]);

  useEffect(() => {
    if (!Utility.isEmpty(warehouseData)) {
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        getJWDestinationWarehouse();
      }
    }
  }, [warehouseData]);

  useEffect(() => {
    resetAllocation();
    if (moduleName === MODULE_TYPE.SELL) {
      if (!Utility.isEmpty(selectSerialTrackingProductData)) {
        setLocalWarehouse(selectSerialTrackingProductData);
      }
    }
  }, [selectSerialTrackingProductData]);

  useEffect(() => {
    let warehouseTempData =
      props.hasMultilpleWarehouseData &&
      props.hasMultilpleWarehouseData.length > 0
        ? props.hasMultilpleWarehouseData
        : [
            {
              warehouseCode: defaultProductWarehouse,
              availableQty: 0,
              quantity: 0
            }
          ];

    setWarehouseData(warehouseTempData);
  }, [defaultProductWarehouse, defaultProductWarehouse]);

  useEffect(() => {
    if (
      moduleName === MODULE_TYPE.SELL &&
      !Utility.isEmpty(localWarehouse) &&
      !Utility.isEmpty(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
      )
    ) {
      let updateSerial: any[] = [];
      let serialData: any[] = [];
      if (Utility.isEmpty(availableSerialData) && !allocatedTapped) {
        let srcWh = getActiveWarehouses()?.find(
          (ele: any) =>
            ele.code ==
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcWarehouseCode
        );
        let destWh = getDestinationWarehouse()?.find(
          (ele: any) =>
            ele.code ==
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destWarehouseCode
        );
        let result = localWarehouse.find(
          (warehouse: any) => warehouse.code === srcWh?.code
        )?.advancedTrackingMeta;
        serialData = result?.filter(
          (serial: any) =>
            serial.batchSizeFulfilled < serial.batchSize &&
            serial.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRowCode &&
            serial.rackCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRackCode &&
            serial.binCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcBinCode
        );
        updateSerial = serialData;
        if (
          stockTransferItem &&
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData &&
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.advancedTrackingData?.length > 0 &&
          serialData &&
          serialData.length > 0
        ) {
          let remainingAvailableSerial = serialData.filter(
            (e: any) =>
              !stockTransferItem[
                currentIndex
              ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
                (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
              )
          );
          let allocatedAvailableSerial = serialData.filter((e: any) =>
            stockTransferItem[
              currentIndex
            ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
              (a: any) => {
                if (a.sourceSerialBatchNumber === e.serialBatchNumber) {
                  return {
                    ...e,
                    selected: !e?.selected
                  };
                }
              }
            )
          );

          let allocatedAvailableSerialData = serialData.filter((e: any) =>
            stockTransferItem[
              currentIndex
            ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
              (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
            )
          );
          if (
            props?.isBulk &&
            stockTransferItem[currentIndex]?.isLineItemChange
          ) {
            remainingAvailableSerial = serialData;
            allocatedAvailableSerial = [];
            allocatedAvailableSerialData = [];
          }

          updateSerial = remainingAvailableSerial;
          let updatedData: any = [];
          remainingAvailableSerial.forEach((serial) => {
            let item = {
              ...serial,
              selected: false
            };
            item = addProductCustomFieldsToLineItem(
              { ...item },
              batchSerialCFfromStore?.content
            );
            updatedData.push(item);
          });
          updateSerial = updatedData;

          if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
            const newArr1 = allocatedAvailableSerial.map((v) => ({
              ...v,
              allocated: true,
              selected: true
            }));
            setAllocatedSerialData(newArr1);
            setSerialData(newArr1);
            setTotalAllocatedQty(newArr1.length);
          }
          let filteredRacksDataForSourceWarehouse: any[] =
            srcWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
          ) {
            filteredRacksDataForSourceWarehouse =
              srcWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredBinDataForSourceWarehouse: any[] =
            srcWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
          ) {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRackCode
              );
          } else {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredRacksDataForDestinationWarehouse: any[] =
            destWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRowCode
          ) {
            filteredRacksDataForDestinationWarehouse =
              destWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          let filteredBinDataForDestinationWarehouse: any[] =
            destWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRackCode
          ) {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRackCode
              );
          } else {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          srcWh = {
            ...srcWh,
            srcRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRowCode ?? null,
            srcRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRackCode ?? null,
            srcBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcBinCode ?? null,
            filteredRackInfos: filteredRacksDataForSourceWarehouse,
            filteredBinInfos: filteredBinDataForSourceWarehouse
          };
          setSourceWarehouse(srcWh);
          destWh = {
            ...destWh,
            destRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode ?? null,
            destRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode ?? null,
            destBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destBinCode ?? null,
            filteredRackInfos: filteredRacksDataForDestinationWarehouse,
            filteredBinInfos: filteredBinDataForDestinationWarehouse
          };
          setDestinationWarehouse(destWh);
          setIsEditSourceWh(true);
          setSourceRRB({
            ...srcWh,
            srcRowCode: srcWh?.srcRowCode ?? null,
            srcRackCode: srcWh?.srcRackCode ?? null,
            srcBinCode: srcWh?.srcBinCode ?? null
          });
          setDestinationRRB({
            ...destWh,
            destRowCode: destWh?.destRowCode ?? null,
            destRackCode: destWh?.destRackCode ?? null,
            destBinCode: destWh?.destBinCode ?? null
          });

          if (!Utility.isEmpty(props.stockTransferData)) {
            let filterData: any = [];
            stockTransferItem.forEach((element: any, index: any) => {
              if (index !== currentIndex) {
                filterData.push(element);
              }
            });
            filterData = filterData.filter(
              (ele: any) =>
                ele.productVariantCode == props.itemDetails.pid &&
                !Utility.isEmpty(ele.stockTransferWarehouseInventoryData) &&
                ele.stockTransferWarehouseInventoryData?.srcWarehouseCode ==
                  srcWh?.code
            );
            let allocatedSerial = filterData.flatMap(
              (ele: any) =>
                ele.stockTransferWarehouseInventoryData.advancedTrackingData
            );
            if (!Utility.isEmpty(allocatedSerial)) {
              let serials: any = [];
              allocatedSerial.forEach((element: any) => {
                serials.push(element.sourceSerialBatchNumber);
              });
              setExistingAllocatedSerial(serials);
              let availableSerials: any = [];
              updateSerial.forEach((ele: any) => {
                if (!serials.includes(ele.serialBatchNumber)) {
                  availableSerials.push(ele);
                }
              });

              if (!Utility.isEmpty(availableSerials)) {
                updateSerial = availableSerials;
              }
            }
          }
          let updatedAllocatedData: any = [];
          allocatedAvailableSerialData.forEach((serial) => {
            let item = {
              ...serial,
              selected: true
            };
            item = addProductCustomFieldsToLineItem(
              { ...item },
              batchSerialCFfromStore?.content
            );
            updatedAllocatedData.push(item);
          });
          setAvailableSerialDataNonFiltered([
            ...updateSerial,
            ...updatedAllocatedData
          ]);
        }

        if (updateSerial && updateSerial.length > 0) {
          setAvailableSerialData(updateSerial);
        }
      }
    } else if (
      moduleName === MODULE_TYPE.SELL &&
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      let selectedWarehouseDestInd = stockTransferItem?.findIndex(
        (stk: any) => stk.stockTransferWarehouseInventoryData
      );
      let transferItemDetails: any;
      if (
        selectedWarehouseDestInd === -1 &&
        selectProductJWOList &&
        selectProductJWOList.length !== 0
      ) {
        selectProductJWOList.forEach((availSerial: any) => {
          if (
            availSerial.stockTransferItems &&
            availSerial.stockTransferItems.length > 0 &&
            availSerial.stockTransferItems[0] &&
            availSerial.stockTransferItems[0]
              .stockTransferWarehouseInventoryData
          ) {
            selectedWarehouseDestInd = availSerial.stockTransferItems.findIndex(
              (stk: any) => stk.stockTransferWarehouseInventoryData
            );
            if (selectedWarehouseDestInd !== -1) {
              transferItemDetails =
                availSerial.stockTransferItems[selectedWarehouseDestInd];
            }
          }
        });
      } else if (selectedWarehouseDestInd !== -1) {
        transferItemDetails = stockTransferItem[selectedWarehouseDestInd];
      }
      let updateSerial: any[] = [];
      let serialData: any[] = [];
      if (Utility.isEmpty(availableSerialData) && !allocatedTapped) {
        let srcWh = getActiveWarehouses()?.find(
          (ele: any) =>
            ele.code ==
            transferItemDetails?.stockTransferWarehouseInventoryData
              ?.srcWarehouseCode
        );
        let destWh = getDestinationWarehouse()?.find(
          (ele: any) =>
            ele.code ==
            transferItemDetails?.stockTransferWarehouseInventoryData
              ?.destWarehouseCode
        );
        let result = localWarehouse.find(
          (warehouse: any) => warehouse.code === srcWh?.code
        )?.advancedTrackingMeta;
        serialData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
        updateSerial = serialData;
        if (
          stockTransferItem &&
          transferItemDetails?.stockTransferWarehouseInventoryData &&
          serialData &&
          serialData.length > 0
        ) {
          const remainingAvailableSerial = serialData.filter(
            (e: any) =>
              !stockTransferItem[
                selectedWarehouseDestInd
              ].stockTransferWarehouseInventoryData?.advancedTrackingData?.some(
                (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
              )
          );
          const allocatedAvailableSerial = serialData.filter((e: any) =>
            stockTransferItem[
              selectedWarehouseDestInd
            ].stockTransferWarehouseInventoryData?.advancedTrackingData?.some(
              (a: any) => {
                if (a.sourceSerialBatchNumber === e.serialBatchNumber) {
                  return {
                    ...e,
                    selected: !e?.selected
                  };
                }
              }
            )
          );

          let allocatedAvailableSerialData = serialData.filter((e: any) =>
            stockTransferItem[
              selectedWarehouseDestInd
            ].stockTransferWarehouseInventoryData?.advancedTrackingData?.some(
              (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
            )
          );

          updateSerial = remainingAvailableSerial;
          let updatedData: any = [];
          remainingAvailableSerial.forEach((serial) => {
            let item = {
              ...serial,
              selected: false
            };
            item = addProductCustomFieldsToLineItem(
              { ...item },
              batchSerialCFfromStore?.content
            );
            updatedData.push(item);
          });
          updateSerial = updatedData;

          let filteredRacksDataForDestinationWarehouse: any[] =
            destWh?.warehouseRackInfos || [];
          if (
            transferItemDetails.stockTransferWarehouseInventoryData?.destRowCode
          ) {
            filteredRacksDataForDestinationWarehouse =
              destWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  transferItemDetails.stockTransferWarehouseInventoryData
                    ?.destRowCode
              );
          }

          let filteredBinDataForDestinationWarehouse: any[] =
            destWh?.warehouseBinInfos || [];
          if (
            transferItemDetails.stockTransferWarehouseInventoryData
              ?.destRackCode
          ) {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  transferItemDetails.stockTransferWarehouseInventoryData
                    ?.destRackCode
              );
          } else {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  transferItemDetails.stockTransferWarehouseInventoryData
                    ?.destRowCode
              );
          }

          destWh = {
            ...destWh,
            destRowCode:
              transferItemDetails.stockTransferWarehouseInventoryData
                ?.destRowCode ?? null,
            destRackCode:
              transferItemDetails.stockTransferWarehouseInventoryData
                ?.destRackCode ?? null,
            destBinCode:
              transferItemDetails.stockTransferWarehouseInventoryData
                ?.destBinCode ?? null,
            filteredRackInfos: filteredRacksDataForDestinationWarehouse,
            filteredBinInfos: filteredBinDataForDestinationWarehouse
          };
          setDestinationWarehouse(destWh);
          setIsEditSourceWh(true);

          setDestinationRRB({
            ...destWh,
            destRowCode: destWh?.destRowCode ?? null,
            destRackCode: destWh?.destRackCode ?? null,
            destBinCode: destWh?.destBinCode ?? null
          });

          if (!Utility.isEmpty(props.stockTransferData)) {
            let filterData: any = [];
            stockTransferItem.forEach((element: any, index: any) => {
              if (index !== selectedWarehouseDestInd) {
                filterData.push(element);
              }
            });
            filterData = filterData.filter(
              (ele: any) =>
                ele.productVariantCode == props.itemDetails.pid &&
                !Utility.isEmpty(ele.stockTransferWarehouseInventoryData) &&
                ele.stockTransferWarehouseInventoryData?.srcWarehouseCode ==
                  srcWh?.code
            );
            let allocatedSerial = filterData.flatMap(
              (ele: any) =>
                ele.stockTransferWarehouseInventoryData.advancedTrackingData
            );
            if (!Utility.isEmpty(allocatedSerial)) {
              let serials: any = [];
              allocatedSerial.forEach((element: any) => {
                serials.push(element.sourceSerialBatchNumber);
              });
              setExistingAllocatedSerial(serials);
              let availableSerials: any = [];
              updateSerial.forEach((ele: any) => {
                if (!serials.includes(ele.serialBatchNumber)) {
                  availableSerials.push(ele);
                }
              });

              if (!Utility.isEmpty(availableSerials)) {
                updateSerial = availableSerials;
              }
            }
          }
          let updatedAllocatedData: any = [];
          allocatedAvailableSerialData.forEach((serial) => {
            let item = {
              ...serial,
              selected: true
            };
            item = addProductCustomFieldsToLineItem(
              { ...item },
              batchSerialCFfromStore?.content
            );
            updatedAllocatedData.push(item);
          });
          setAvailableSerialDataNonFiltered([
            ...updateSerial,
            ...updatedAllocatedData
          ]);
        }

        // if (updateSerial && updateSerial.length > 0) {
        //   setAvailableSerialData(updateSerial);
        // }
        setReadOnlyTargetWarehouseRRB(true);
      }
    }
  }, [localWarehouse]);

  useEffect(() => {
    getSerialTrackingGridColumns();
  }, [batchSerialCFfromStore]);

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content.filter(
        (item: any) => item.active && !item.rejected
      );
    if (activeWarehouses && activeWarehouses.length > 0) {
      let selectedWarehouse = activeWarehouses.filter(
        (warehouse: any) => warehouse.primary === true
      );
      if (selectedWarehouse[0] !== undefined && selectedWarehouse[0] !== null) {
        setDefaultAllocationWarehouse(selectedWarehouse[0].code);
        setSelectedWarehouse(selectedWarehouse[0]);
        // setSelectedBOMWarehouse(selectedWarehouse[0]);
      }
    }
  };

  const selectAllAvailableSerial = () => {
    let localData = [...availableSerialData];
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        selected: !isAllAvailableSerialSelected
      };
    });
    setIsAllAvailableSerialSelected(!isAllAvailableSerialSelected);
    setSelectedSerial(serialTrackingData);
    setAvailableSerialData(serialTrackingData);
  };

  const selectAllSelectedSerial = () => {
    const localData = deepClone(allocatedSerialData);
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        allocated: !isAllSerialSelected
      };
    });
    if (!isAllSerialSelected) {
      setTotalAllocatedQty(serialTrackingData.length);
    } else {
      setTotalAllocatedQty(0);
    }
    setIsAllSerialSelected(!isAllSerialSelected);
    setSerialData(serialTrackingData);
    setAllocatedSerialData(serialTrackingData);
  };

  const allocateSerialNumber = () => {
    const localData = deepClone(selectedSerial);
    const selectedSerialNumber = localData.filter(
      (option: any) => option.selected
    );
    const getSelectedSerialNumber = [
      ...allocatedSerialData,
      ...selectedSerialNumber
    ];
    const updatedSerialData = selectedSerial.filter(
      (x) =>
        !selectedSerialNumber.filter(
          (y) => y.serialBatchNumber === x.serialBatchNumber
        ).length
    );

    setAllocatedTapped(true);
    setIsAllAvailableSerialSelected(false);
    setAllocatedSerialData((prevState: any[]) => getSelectedSerialNumber);
    setAvailableSerialData(updatedSerialData);
    setSerialData(getSelectedSerialNumber);
  };

  const getSelectedSerial = (item: any, index: any) => {
    const localData = [...availableSerialData];
    localData[index] = {
      ...localData[index],
      selected: !localData[index]?.selected
    };
    setSelectedSerial(localData);
    setAvailableSerialData(localData);
  };

  const getAllocatedSerial = (item: any, index: any) => {
    const localData = [...allocatedSerialData];
    localData[index] = {
      ...localData[index],
      allocated: !localData[index]?.allocated
    };
    const totalAllocatedQuantity: any[] = localData.filter(
      (option: any) => option.allocated
    );
    setTotalAllocatedQty(totalAllocatedQuantity.length);
    setSerialData(localData);
    setAllocatedSerialData(localData);
  };

  const onResetClick = () => {
    resetAllocation();
    setAvailableSerialData(availableSerialDataNonFiltered);
  };

  const resetAllocation = () => {
    setAllocatedTapped(false);
    setIsAllSerialSelected(false);
    setIsAllAvailableSerialSelected(false);
    setAllocatedTapped(false);
    setAllocatedSerialData([]);
    setSelectedSerial([]);
    setAvailableSerialData([]);
    setSerialData([]);
  };

  const getSelectedWarehousesValue = (code: string) => {
    if (Utility.isEmpty(code)) {
      return [];
    }
    let filtered =
      localWarehouse &&
      localWarehouse.filter((warehouse: any) => warehouse.code === code);
    if (filtered && filtered.length > 0) {
      let first = filtered[0];
      return [localWarehouse.indexOf(first)];
    } else {
      return [];
    }
  };

  const getActiveWarehouses = () => {
    let activeWarehouses =
      localWarehouse &&
      localWarehouse.filter((item: any) => item.active && !item.rejected);
    if (!Utility.isEmpty(activeWarehouses)) {
      return activeWarehouses;
      //   return activeWarehouses.map((item: any) => item.name);
    } else {
      return [];
    }
  };

  const validateDetail = () => {
    //
    if (Utility.isEmpty(sourceWarehouse)) {
      showAlert('Error', 'Please select source warehouse');
      return false;
    }
    if (Utility.isEmpty(destinationWarehouse) && !props?.isBulk) {
      showAlert('Error', 'Please select target warehouse');
      return false;
    }
    let selectedData = availableSerialData?.filter(
      (item: any) => item.selected
    );
    if (Utility.isEmpty(selectedData)) {
      showAlert('Error', 'Please select at least one serial');
      return false;
    }
    if (props?.isBulk) {
      if (
        !isNaN(Number(props.stockTransferData[props.currentIndex]?.quantity)) &&
        Number(props.stockTransferData[props.currentIndex]?.quantity) !==
          selectedData?.length
      ) {
        showAlert(
          'Error',
          'Transfer quantity can not be more or less than required quantity'
        );
        return false;
      }
    }

    if (
      item.requiredQuantity !== undefined &&
      item.requiredQuantity >= 0 &&
      Number(item.requiredQuantity) !== selectedData.length &&
      props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      showAlert(
        'Error',
        'Selected quantity can not be more or less than required quantity'
      );
      return false;
    } else if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) < selectedData.length &&
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable()
    ) {
      showAlert('Error', t('JOB_WORK_OUT.ERROR_TRANSFER_REQ_QTY'));
      return false;
    } else if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) > selectedData.length
    ) {
      showAlert(
        'Error',
        t('Transfer quantity cannot be less than required quantity')
      );
      return false;
    } else if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      selectedData.length > sharedAvailableQty
    ) {
      showAlert(
        'Error',
        'Transfer quantity cannot be more than available quantity. Please check with other shared transfer quantity.'
      );
      return false;
    }
    let wareHouseData = productInventoryWarehouse?.find(
      (warehouse: any) => warehouse?.code === sourceWarehouse.code
    );
    if (
      (!Utility.isEmpty(wareHouseData?.warehouseRowInfos) &&
        Utility.isEmpty(sourceRRB?.srcRowCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseRackInfos) &&
        Utility.isEmpty(sourceRRB?.srcRackCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseBinInfos) &&
        Utility.isEmpty(sourceRRB?.srcBinCode))
    ) {
      showAlert(
        'Warning!',
        'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
      );
      return false;
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !Utility.checkSameWarehouseSelected(
        props,
        selectProductJWOList,
        destinationWarehouse,
        sourceWarehouse
      )
    ) {
      return false;
    }

    return true;
  };

  const onSave = () => {
    let advancedData: any[] = [];
    // let selectedData = availableSerialDataNonFiltered?.filter(
    //   (item: any) => item.selected
    // );
    let selectedData = availableSerialData?.filter(
      (item: any) => item.selected
    );
    selectedData.forEach((data: any) => {
      let d: any = {
        qtyToTransfer: 1,
        sourceSerialBatchNumber: data.serialBatchNumber,
        destSerialBatchNumber: data.serialBatchNumber
      };
      let customData = getLineItemCFs(
        data,
        gridColumns,
        batchSerialCFfromStore.content
      );
      if (Utility.isNotEmpty(customData)) {
        d['customField'] = [...customData];
      }
      advancedData.push(d);
    });
    if (props.onSerialSave) {
      if (validateDetail()) {
        let totalTransferQty = 0;
        totalTransferQty = selectedData.length;
        let warehouseDetail = {
          srcWarehouseCode: sourceWarehouse.code,
          destWarehouseCode: destinationWarehouse.code,
          destBinCode: destinationRRB.destBinCode ?? null,
          destRackCode: destinationRRB.destRackCode ?? null,
          destRowCode: destinationRRB.destRowCode ?? null,
          srcBinCode: sourceRRB?.srcBinCode ?? null,
          srcRackCode: sourceRRB?.srcRackCode ?? null,
          srcRowCode: sourceRRB?.srcRowCode ?? null,
          quantity: totalTransferQty,
          advancedTrackingType: TRACKING_TYPE.SERIAL,
          advancedTrackingData: advancedData,
          productCode: item?.pid
        };
        let data: any = {};
        data['stockTransferWarehouseInventoryData'] = warehouseDetail;

        props.onSerialSave(data);
      }
    }
  };

  const assignSerial = (serials: string) => {
    let duplicateSerial: any[] = [];
    let existingSerialWarehouse = [...selectSerialTrackingProductData];
    let filterSerialWarehouse = existingSerialWarehouse.find(
      (item: any) => item.code === selectedWarehouse.code
    );
    let existingSerial =
      (filterSerialWarehouse && filterSerialWarehouse.advancedTrackingMeta) ||
      [];
    var serial = serials.split(',');
    var tmp: any = [];
    if (advancedTrackingMetaData && advancedTrackingMetaData.length > 0) {
      tmp = [...advancedTrackingMetaData];
    }
    duplicateSerial = existingSerial.filter((o1: any) =>
      serial.some((o2: any) => o1.serialBatchNumber === o2)
    );

    if (duplicateSerial && duplicateSerial.length > 0) {
      showAlert('Error', 'Serial Number already exists');
      return;
    }

    serial.forEach((e) => {
      var b = {
        warehouse: selectedWarehouse,
        serialBatchNumber: e,
        batchSize: 1
      };
      tmp.push(b);
    });
    setAdvancedTrackingMetaData(tmp);
    setSelectedWarehouse({});
    setNewSerial('');
    let totalitem = tmp.length;
    setTotalAllocatedItem(totalitem);
    setSaveButtonTapped(false);
  };

  const getHeader = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      let selectedData = availableSerialDataNonFiltered?.filter(
        (item: any) => item.selected
      )?.length;
      return (
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text="Stock Transfer Serial-Tracked Product"
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-r"
              onClick={props.onClose}
            />
            <DKButton
              title={'Save'}
              // disabled={serialData.length === 0}
              disabled={selectedData === 0}
              className={
                selectedData > 0
                  ? 'bg-button text-white'
                  : 'bg-gray-300 text-white'
              }
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      );
    }
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-between p-h-r p-v-s mt-5">
          <div className="column width-auto ">
            <DKLabel
              text="Product Name"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.name} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text="Product Code"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.documentSequenceCode} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text="Available Quantity"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel
              text={getProductAvailableQuantity(item)}
              className="fs-r pl-2"
            />
          </div>
          {item.requiredQuantity !== undefined &&
            item.requiredQuantity >= 0 && (
              <div className="column width-auto">
                <DKLabel
                  text="Required Quantity"
                  className="fs-r pl-2 font-medium text-blue"
                />
                <DKLabel text={item.requiredQuantity} className="fs-r pl-2" />
              </div>
            )}
          {props?.isBulk && (
            <div className="column width-auto">
              <DKLabel
                text="Required Quantity"
                className="fs-r pl-2 font-medium text-blue"
              />
              <DKLabel
                text={props.stockTransferData[props.currentIndex]?.quantity}
                className="fs-r pl-2"
              />
            </div>
          )}
        </div>
      );
    }
  };

  const getDestinationWarehouse = () => {
    if (!Utility.isEmpty(warehouseData)) {
      //   return productWarehouse.map((item: any) => item.name);
      //   let warehouseList = warehousesData?.content?.filter(
      //     (warehouse: any) =>
      //       warehouse.id !==
      //         (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '') &&
      //       warehouse.warehouseType !== 'REJECTED' &&
      //       warehouse.active
      //   );
      let warehouseList = warehousesData?.content?.filter(
        (warehouse: any) =>
          //   warehouse.id !==
          //     (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '') &&
          warehouse.warehouseType !== 'REJECTED' &&
          warehouse.warehouseType !== 'QA' &&
          warehouse.warehouseType !== 'TOLERANCE_REJECTED' &&
          warehouse.active
      );
      if (!Utility.isEmpty(warehousesData?.content)) {
        let findJWOWarehouse = warehousesData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;

        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      return warehouseList && warehouseList.length > 0 ? warehouseList : [];
    } else {
      return [];
    }
  };

  const getJWDestinationWarehouse = () => {
    if (!Utility.isEmpty(warehouseData)) {
      //   return productWarehouse.map((item: any) => item.name);
      let warehouseList = productInventoryWarehouse.filter(
        (warehouse: any) =>
          warehouse.id !==
          (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '')
      );
      if (!Utility.isEmpty(warehousesData?.content)) {
        let findJWOWarehouse = warehousesData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;

        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      if (warehouseList && warehouseList.length > 0) {
        if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          let jwoWarehouse = warehouseList?.find(
            (warehouse: any) =>
              warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
          );
          if (!Utility.isEmpty(jwoWarehouse)) {
            setDestinationWarehouse(jwoWarehouse);
          }
        }
      }
    }
  };

  const getProductAvailableQuantity = (product: any) => {
    let reserveQty = 0,
      availableQty = 0;
    let whData = props?.selectedWarehouseProductsData;
    whData?.forEach((ele: any) => {
      reserveQty += ele.productReservedQuantity[product?.pid]
        ? ele.productReservedQuantity[product?.pid]
        : 0;
      availableQty += ele.productAvailableQuantity[product?.pid]
        ? ele.productAvailableQuantity[product?.pid]
        : 0;
    });
    return product ? availableQty - reserveQty : 0;
  };

  const getSubHeaderSection = () => {
    return (
      <div className="row flex justify-content-between p-h-r p-v-s align-items-end">
        <div className="row pt-3">
          {warehouseData &&
            !props?.isBulk &&
            warehouseData.map((item: any, i: any) => {
              return (
                <div className="row parent-width pt-3 ml-2">
                  <SourceDestinationWarehouseManagementRRB
                    document={props?.document ?? ''}
                    type={ADVANCE_TRACKING.SERIAL}
                    sourceWarehouseData={getActiveWarehouses()}
                    sourceWarehouse={sourceWarehouse}
                    destinationWarehouse={destinationWarehouse}
                    targetWarehouseData={getDestinationWarehouse()}
                    readOnlyTarget={readOnlyTargetWarehouseRRB}
                    srcWarehouseForReservedJWO={props.srcWarehouse}
                    onSave={(data: any) => {
                      setSourceRRB({
                        ...sourceRRB,
                        srcRowCode: data?.srcRowCode ?? null,
                        srcRackCode: data?.srcRackCode ?? null,
                        srcBinCode: data?.srcBinCode ?? null
                      });
                      setDestinationRRB({
                        ...destinationRRB,
                        destRowCode: data?.destRowCode ?? null,
                        destRackCode: data?.destRackCode ?? null,
                        destBinCode: data?.destBinCode ?? null
                      });
                    }}
                    onSourceWarehouseChange={(data: any) => {
                      onResetClick();
                      if (data) {
                        let updateSerial: any[] = [];
                        let serialData: any[] = [];
                        let result = localWarehouse.find(
                          (warehouse: any) => warehouse.id === data.id
                        )?.advancedTrackingMeta;
                        //   let result = localWarehouse.find(
                        //     (warehouse: any) => warehouse.primary
                        //   )?.advancedTrackingMeta;
                        result = result?.filter(
                          (serial: any) => serial.reservedQuantity === 0
                        );
                        serialData = result?.filter(
                          (serial: any) =>
                            serial.batchSizeFulfilled < serial.batchSize
                        );
                        let updatedData: any = [];
                        serialData.forEach((serial) => {
                          let item = {
                            ...serial,
                            selected: false
                          };
                          item = addProductCustomFieldsToLineItem(
                            { ...item },
                            batchSerialCFfromStore?.content
                          );
                          updatedData.push(item);
                        });
                        updateSerial = updatedData;
                        // updateSerial = serialData?.map((ele: any) => {
                        //   return {
                        //     ...ele,
                        //     selected: false,
                        //   };
                        // });

                        if (
                          props.itemDetails &&
                          props.itemDetails.advancedTrackingFulfilmentData &&
                          props.itemDetails.advancedTrackingFulfilmentData
                            .length > 0 &&
                          serialData &&
                          serialData.length > 0
                        ) {
                          const remainingAvailableSerial = serialData.filter(
                            (e: any) =>
                              !props.itemDetails.advancedTrackingFulfilmentData.some(
                                (a: any) =>
                                  a.serialBatchNumber === e.serialBatchNumber
                              )
                          );
                          const allocatedAvailableSerial = serialData.filter(
                            (e: any) =>
                              props.itemDetails.advancedTrackingFulfilmentData.some(
                                (a: any) => {
                                  if (
                                    a.serialBatchNumber === e.serialBatchNumber
                                  ) {
                                    return {
                                      ...e,
                                      selected: !e?.selected
                                    };
                                  }
                                }
                              )
                          );

                          updateSerial = remainingAvailableSerial;
                          if (
                            allocatedAvailableSerial &&
                            allocatedAvailableSerial.length > 0
                          ) {
                            const newArr1 = allocatedAvailableSerial.map(
                              (v) => ({
                                ...v,
                                allocated: true,
                                selected: true
                              })
                            );
                            setAllocatedSerialData(newArr1);
                            setSerialData(newArr1);
                            setTotalAllocatedQty(newArr1.length);
                          }
                        }

                        if (!Utility.isEmpty(props.stockTransferData)) {
                          let filterData: any = [];
                          stockTransferItem.forEach(
                            (element: any, index: any) => {
                              if (index !== currentIndex) {
                                filterData.push(element);
                              }
                            }
                          );
                          filterData = filterData.filter(
                            (ele: any) =>
                              ele.productVariantCode == props.itemDetails.pid &&
                              !Utility.isEmpty(
                                ele.stockTransferWarehouseInventoryData
                              ) &&
                              ele.stockTransferWarehouseInventoryData
                                ?.srcWarehouseCode == data?.code
                          );
                          let allocatedSerial = filterData.flatMap(
                            (ele: any) =>
                              ele.stockTransferWarehouseInventoryData
                                .advancedTrackingData
                          );
                          if (!Utility.isEmpty(allocatedSerial)) {
                            let serials: any = [];
                            allocatedSerial.forEach((element: any) => {
                              serials.push(element.sourceSerialBatchNumber);
                            });
                            setExistingAllocatedSerial(serials);
                            let availableSerials: any = [];
                            updateSerial.forEach((ele: any) => {
                              if (!serials.includes(ele.serialBatchNumber)) {
                                availableSerials.push(ele);
                              }
                            });
                            if (!Utility.isEmpty(availableSerials)) {
                              updateSerial = availableSerials;
                            }
                          }
                        }

                        if (updateSerial && updateSerial.length > 0) {
                          setTimeout(() => {
                            setAvailableSerialData(updateSerial);
                            setAvailableSerialDataNonFiltered(updateSerial);
                          }, 300);
                        }
                        setSourceWarehouse(data);
                        if (!readOnlyTarget) {
                          if (props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER) {
                            setDestinationWarehouse({});
                          }
                        }
                      }
                    }}
                    onTargetWarehouseChange={(data: any) => {
                      onResetClick();
                      if (data) {
                        setDestinationWarehouse(data);
                      }
                    }}
                    onRRBInfoChange={(data: any) => {
                      onResetClick();
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const getSerialTrackingGridColumns = () => {
    let columns: any = [];
    columns.push({
      key: 'serialBatchNumber',
      name: 'Serial Number',
      type: INPUT_TYPE.TEXT,
      textAlign: 'left',
      width: Utility.isEmpty(batchSerialCFfromStore?.content) ? 850 : 145,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    });

    let activeProductCustomFields = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
        return item.status === STATUS_TYPE.ACTIVE;
      });
      activeProductCustomFields = productCf.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
    }

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      newItem['editable'] = false;

      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        columns.push({ ...newItem });
      }
    });
    setGridColumns(columns);
  };

  const onRowSelect = ({ rowIndex, rowData }: any) => {
    let localData = [...availableSerialData];
    localData[rowIndex] = {
      ...localData[rowIndex],
      selected: rowData?.selected
    };
    setSelectedSerial(localData);
    setAvailableSerialData(localData);
    // setAvailableSerialDataNonFiltered(localData);
  };

  const selectAllSerial = () => {
    let localData = [...availableSerialData];
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        selected: !isAllAvailableSerialSelected
      };
    });
    // setAvailableSerialDataNonFiltered(serialTrackingData);
    setIsAllAvailableSerialSelected(!isAllAvailableSerialSelected);
    setSelectedSerial(serialTrackingData);
    setAvailableSerialData(serialTrackingData);
  };

  const getBatchTrackingGrid = () => {
    // let rows =
    //   selectedViewIndex === 0
    //     ? availableSerialDataNonFiltered
    //     : availableSerialDataNonFiltered.filter((item: any) => item.selected);
    // const selectedRows =
    //   availableSerialDataNonFiltered?.filter((item: any) => item.selected)
    //     ?.length || 0;
    // const isAllRowSelected =
    //   (availableSerialDataNonFiltered?.length || 0) === selectedRows;
    let rows =
      selectedViewIndex === 0
        ? availableSerialData
        : availableSerialData.filter((item: any) => item.selected);
    const selectedRows =
      availableSerialData?.filter((item: any) => item.selected)?.length || 0;
    const isAllRowSelected =
      (availableSerialData?.length || 0) === selectedRows;

    return (
      <>
        {/* {!props?.isReadOnly && ( */}
        <div className="p-h-r p-v-s parent-width ml-2">
          <div className="row">
            <div style={{ width: 200 }} className="">
              <DKSegmentControl
                segments={[
                  'Available',
                  `Selected (${
                    availableSerialData?.filter((item: any) => item.selected)
                      ?.length || 0
                  })`
                ]}
                backgroundColor="bg-gray2"
                selectedColor=""
                barColor="bg-white"
                selectedIndex={selectedViewIndex}
                onSelect={(index: number) => {
                  setSelectedViewIndex(index);
                }}
              />
            </div>
          </div>

          <DKDataGrid
            isAllRowSelected={isAllRowSelected}
            onAllRowSelect={selectAllSerial}
            needShadow={true}
            needBorder={true}
            needColumnIcons={false}
            needTrailingColumn={true}
            allowBulkOperation={true}
            allowColumnSort={false}
            allowColumnAdd={false}
            width={950}
            allowColumnEdit={false}
            allowRowEdit={false}
            currentPage={1}
            totalPageCount={1}
            title={''}
            columns={gridColumns}
            rows={
              selectedViewIndex === 0
                ? availableSerialData
                : availableSerialData.filter((item: any) => item.selected)
            }
            onRowSelect={onRowSelect}
          />
        </div>
      </>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '100%',
          // height: "85%",
          minHeight: '80%',
          maxHeight: '95%',
          height: 565,
          padding: 0,
          paddingBottom: 60
        }}
      >
        {getHeader()}
        {getHeaderSection()}
        {moduleName === MODULE_TYPE.SELL && getSubHeaderSection()}
        <DKLine className="parent-width mt-m bg-gray4" />
        {/* {moduleName === MODULE_TYPE.SELL && getBodySection()} */}
        {getBatchTrackingGrid()}
      </div>
    </div>
  );
}
