import { useEffect, useRef, useState } from 'react';
import { addDays } from 'date-fns';
import Utility from '../../Utility/Utility';
import DateFormatService from '../../Services/DateFormat';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { BOOKS_DATE_FORMAT, DOCUMENT_MODE } from '../../Constants/Constant';
import {
  getValidTillDateFromDocDate,
  getFormattedAddressObj
} from './NewDocumentHelper';
import {
  DKIcon,
  INPUT_TYPE,
  DKIcons,
  DKLabel,
  DKInput,
  showLoader,
  removeLoader,
  DKButton,
  DKDataGrid
} from 'deskera-ui-library';
import RateAnalysisSummaryView from './RateAnalysisSummary';
import RateAnalysisService from '../../Services/RateAnalysis';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import PrintRateAnalysis from '../../Components/Reports/Sales/PrintRateAnalysis';
import { pdf } from '@react-pdf/renderer';
import { draftTableId } from '../../Redux/Slices/DraftsSlice';
export interface RateAnalysisProps {
  data: any;
  documentMode?: DOCUMENT_MODE;
  onCancel?: any;
  onSave?: any;
}

export default function RateAnalysisForm(props: RateAnalysisProps) {
  const [productRows, setProductRows] = useState<any[]>([]);
  const currentDate = new Date();
  const laterDate = addDays(currentDate, 30);
  const [dueDate, setDueDate] = useState<Date>(laterDate);
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const draftsTableId = useAppSelector(draftTableId);
  const buildProductMethod = (data: any) => {
    switch (data) {
      case 'FIFO':
        return [0];
      case 'AVERAGE':
        return [1];
      case 'PURCHASE PRICE':
        return [2];
      default:
        return [0];
    }
  };

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [selectProductMethod, setSelectProductMethod] = useState<any>(
    buildProductMethod(
      props?.data.productPriceMethod ||
        tenantInfo?.additionalSettings?.RATE_ANALYSIS?.PRODUCT_PRICE_METHOD
    )
  );
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);

  const isDocDateUpdatedManually = useRef<boolean>(false);
  //Need to change hardcode this and need to get from props
  const [columnConfig, setColumnConfig] = useState([
    {
      id: 'documentSequenceCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      renderer: DocumentConfigUtility.rateAnlysisConfigProductCode
    },
    {
      id: 'product',
      key: 'product',
      name: 'Product Name',
      type: INPUT_TYPE.NUMBER,
      width: 160,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      renderer: DocumentConfigUtility.rateAnlysisConfigProduct
    },

    {
      id: 'quantity',
      key: 'productQuantity',
      name: 'Quantity',
      type: INPUT_TYPE.NUMBER,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'purchasePrice',
      key: 'purchasePrice',
      name: 'Purchase Price',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'productCost',
      key: 'productCost',
      name: 'Product Cost',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'price',
      key: 'unitPrice',
      name: 'Unit Price',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      editable: true,
      allowNegativeNumber: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      formatter: ({ value }: any) => value
    },
    {
      id: 'grossRevenue',
      key: 'grossRevenue',
      name: 'Gross Revenue',
      type: INPUT_TYPE.TEXT,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'discountRate',
      key: 'discountRate',
      name: 'Discount',
      type: INPUT_TYPE.TEXT,
      width: 100,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.discount
    },
    {
      id: 'discountAmount',
      key: 'discountAmount',
      name: 'Discount Amount',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'revenue',
      key: 'revenue',
      name: 'Revenue',
      type: INPUT_TYPE.NUMBER,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'profitMargin',
      key: 'profitMargin',
      name: 'Profit Margin',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'margin',
      key: 'margin',
      name: 'Profit Margin (%)',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    },
    {
      id: 'tax',
      key: 'tax',
      name: 'Tax',
      type: INPUT_TYPE.TEXT,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisTax
    },
    {
      id: 'taxAmount',
      key: 'taxAmount',
      name: 'Total Tax',
      type: INPUT_TYPE.NUMBER,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      renderer: DocumentConfigUtility.rateAnlysisConfig
    }
  ]);
  const [contact, setContact] = useState<any>(null);

  const initializePayload = () => {
    return {
      id: '',
      tenantId: '',
      productPriceMethod: '',
      contactCode: '',
      contact: '',
      documentSequenceCode: '',
      documentCode: '',
      documentType: '',
      documentDate: '',
      currency: '',
      exchangeRate: '',
      unitPriceGstInclusive: '',
      customField: '',
      additionalCharges: '',
      commissionCharges: '',
      totalAmount: '',
      totalAmountInBaseCurrency: '',
      taxAmount: '',
      grossProductCost: '',
      totalCommissionAmount: '',
      netProductCost: '',
      grossProductRevenue: '',
      discountOnRevenue: '',
      netProductRevenue: '',
      netProfitMargin: '',
      profitMarginPercentage: '',
      lineItems: [],
      approvalStatus: null
    };
  };

  const [booksDocument, setBooksDocument] = useState<any>(initializePayload);
  const [rateAnalysisPayload, setRateAnalysisPayload] =
    useState<any>(booksDocument);

  useEffect(() => {
    showLoader();
    const payload = {
      documentCode: props?.data.documentCode,
      documentType: props?.data.documentType,
      productPriceMethod: props?.data.productPriceMethod,
      isDraft: props?.data.isDraft,
      draftId: props?.data.draftId,
      tableId: draftsTableId,
      draftCode: props?.data.draftCode
    };
    let lineItems: any = [];
    RateAnalysisService.getRateAnalysis(payload)
      .then((res: any) => {
        removeLoader();
        if (!Utility.isEmpty(res)) {
          lineItems = [...(res.lineItems as any)];
          setProductRows(lineItems);
          setBooksDocument(res);

          lineItems.forEach((rowData: any, index: any) => {
            lineItems[index] = calculationLineItmes(index, [
              ...(lineItems as any)
            ]);
          });
        }
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error loading rate analysis: ', err);
      });

    let documentUpdates: any = {};

    if (booksDocument.documentDate) {
      setDocumentDate(
        DateFormatService.getDateFromStr(
          booksDocument.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    setProductRows(lineItems);
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        ...documentUpdates,
        currency: booksDocument.currency
          ? booksDocument.currency
          : tenantInfo.currency,
        currencyCode: booksDocument.currencyCode
          ? booksDocument.currencyCode
          : tenantInfo.currency,

        documentDate: booksDocument.documentDate
          ? booksDocument.documentDate
          : DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        lineItems: lineItems,
        totalAmount: booksDocument.totalAmount
      };
    });

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  const onMouseUp = (e: any) => {
    const target = e.target;
    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setopenCustomNumberList(false);
    }
  };

  useEffect(() => {
    if (documentDate && documentDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          documentDate: DateFormatService.getDateStrFromDate(
            documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
      if (isDocDateUpdatedManually.current) {
        const updatedValidTillDate = getValidTillDateFromDocDate(
          documentDate,
          contact
        );
        setDueDate((prevState) =>
          updatedValidTillDate ? updatedValidTillDate : prevState
        );
      }
    }
  }, [documentDate]);

  const calculationLineItmes = (rowIndex: number, unsavedRows?: any) => {
    let item = unsavedRows[rowIndex];
    item.purchasePrice = Utility.roundOffToTenantDecimalScale(
      item.purchasePrice
    );
    item.productCost = Utility.roundOffToTenantDecimalScale(
      item.purchasePrice * item.productQuantity
    );
    item.grossRevenue = item.unitPrice * item.productQuantity;

    if (item.discountInPercent) {
      item.discountRate = item.discount;
      item.discount = item.discountRate;
      item.discountAmount = Utility.roundOffToTenantDecimalScale(
        (item.discountRate * item.grossRevenue) / 100
      );
    } else {
      item.discountRate = item.discount;
      item.discount = item.discountRate;
      item.discountAmount = item.discountRate;
    }

    item.revenue = Utility.roundOffToTenantDecimalScale(
      item.grossRevenue - item.discountAmount
    );

    if (!Utility.isEmpty(item?.tax?.percent) || Utility.isEmpty(item.tax)) {
      item.taxAmount = item.taxAmount;
    } else {
      item.taxAmount = Number((item.tax.percent * item.revenue) / 100);
    }

    item.profitMargin = Utility.roundOffToTenantDecimalScale(
      item.revenue - item.productCost
    );
    item.margin = Utility.roundOffToTenantDecimalScale(
      (item.profitMargin / item.productCost) * 100
    );
    if (item.margin == 'Infinity') {
      item.margin = 0;
    }
    return item;
  };

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1, position: 'absolute', left: 0, top: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky "
      >
        <div className="row">
          <DKLabel text={'Rate Analysis'} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={props.onCancel}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-m"
            onClick={() => {
              saveSetting();
            }}
          />
        </div>
      </div>
    );
  };

  const handleDocumentUpdate = (doc: any) => {
    let payload = {
      id: booksDocument.id,
      tenantId: booksDocument.tenantId,
      productPriceMethod: booksDocument.productPriceMethod
        ? booksDocument.productPriceMethod
        : tenantInfo?.additionalSettings?.RATE_ANALYSIS?.PRODUCT_PRICE_METHOD,
      contactCode: booksDocument.contactCode,
      contact: booksDocument.contact,
      documentSequenceCode: props?.data.isDraft
        ? props?.data.documentCode
        : booksDocument.documentSequenceCode,
      documentCode: booksDocument.documentCode,
      documentType: booksDocument.documentType,
      documentDate: booksDocument.documentDate,
      currency: booksDocument.currency,
      exchangeRate: booksDocument.exchangeRate,
      unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
      customField: booksDocument.customField,
      additionalCharges: booksDocument.additionalCharges,
      commissionCharges: doc.selectedCommission,
      totalAmount: doc.grandTotal,
      totalAmountInBaseCurrency: booksDocument.totalAmountInBaseCurrency,
      taxAmount: doc.tax,
      grossProductCost: doc.productCost,
      totalCommissionAmount: doc.netProductCost - doc.productCost,
      netProductCost: doc.netProductCost,
      grossProductRevenue: doc.productRevenue,
      discountOnRevenue: doc.dicountOnProductRevenue,
      netProductRevenue: doc.netProductRevenue,
      netProfitMargin: doc.totalProfitMargin,
      profitMarginPercentage: doc.totalProfitMarginPercent,
      lineItems: booksDocument.lineItems,
      approvalStatus: null,
      draft: props?.data.isDraft,
      shipFrom : booksDocument?.shipFrom,
      shipTo : booksDocument?.shipTo,
      billTo : booksDocument?.billTo
    };

    setRateAnalysisPayload(payload);
  };

  const saveSetting = async () => {
    await RateAnalysisService.saveRateAnalysis(rateAnalysisPayload);
    props.onSave();
  };

  const productPurchasePriceMethod = () => {
    let product: any[] = [];
    booksDocument.lineItems.forEach((data: any) => {
      product.push(data.productCode);
    });

    let productMethod;
    switch (selectProductMethod) {
      case 0:
        productMethod = 'FIFO';
        break;
      case 1:
        productMethod = 'AVERAGE';
        break;
      case 0:
        productMethod = 'PURCHASE PRICE';
        break;
      default:
      case 0:
        productMethod = 'FIFO';
        break;
    }

    let payload = {
      productPriceMethod: productMethod,
      productCodes: product
    };
    let newRow: any = [];

    RateAnalysisService.getProductPurchasePrice(payload)
      .then((res: any) => {
        const list = booksDocument.lineItems;
        res.map((item: any) => {
          const indexes = list.reduce(
            (arr: number[], elem: any, index: number) => {
              if (elem.productCode === item.productCode) {
                arr.push(index);
              }
              return arr;
            },
            []
          );

          indexes.forEach((index: number) => {
            let rows: any = [...productRows];
            rows[index].purchasePrice = item.purchasePrice;
            newRow[index] = calculationLineItmes(index, rows);
          });
        });

        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            lineItems: newRow
          };
        });
        setProductRows(newRow);
      })
      .catch((err) => {
        console.error('Error fetching Purchase Price', err);
      });
  };

  const calculateDiscount = (rowIndex: number, unsavedRows?: any) => {
    let item = unsavedRows[rowIndex];
    if (item.discountInPercent) {
      item.discountRate = item.discountRate;
      item.discount = item.discountRate;
      item.discountAmount = Utility.roundOffToTenantDecimalScale(
        (item.discountRate * item.grossRevenue) / 100
      );
    } else {
      item.discountRate = item.discountRate;
      item.discount = item.discountRate;
      item.discountAmount = item.discountRate;
    }

    item.revenue = Utility.roundOffToTenantDecimalScale(
      item.grossRevenue - item.discountAmount
    );

    if (!Utility.isEmpty(item?.tax?.percent) || Utility.isEmpty(item.tax)) {
      item.taxAmount = item.taxAmount;
    } else {
      item.taxAmount = Number((item.tax.percent * item.revenue) / 100);
    }

    item.profitMargin = Utility.roundOffToTenantDecimalScale(
      item.revenue - item.productCost
    );
    item.margin = Utility.roundOffToTenantDecimalScale(
      (item.profitMargin / item.productCost) * 100
    );
    if (item.margin == 'Infinity') {
      item.margin = 0;
    }
    return item;
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let rows: any = [...productRows];
    let dataToUpdate: any = rowData && rowData[columnKey];

    switch (columnKey) {
      case 'purchasePrice':
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex] = calculationLineItmes(rowIndex, rows);
        break;
      case 'discountRate':
        const amt = dataToUpdate.replace('%', '');
        const isPercent = dataToUpdate.includes('%');
        if (isPercent) {
          rows[rowIndex][columnKey] = amt;
          rows[rowIndex]['discountInPercent'] = isPercent;
        } else {
          rows[rowIndex]['discountAmount'] = dataToUpdate;
          rows[rowIndex]['discountInPercent'] = isPercent;
        }
        rows[rowIndex] = calculateDiscount(rowIndex, rows);
        break;
      case 'unitPrice':
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex] = calculationLineItmes(rowIndex, rows);
        break;
    }

    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        lineItems: rows
      };
    });
    setProductRows(rows);
  };

  const printPdf = () => {
    const pdfData = getUpdatedDataForPdf(rateAnalysisPayload);
    const ratePdf = <PrintRateAnalysis dataForPrintDocument={pdfData} />;
    pdf(ratePdf)
      .toBlob()
      .then((pdfBlob: any) => {
        if (pdfBlob !== '') {
          const responseBlob = new Blob([pdfBlob], {
            type: 'application/pdf'
          });
          const fileURL = URL.createObjectURL(responseBlob);
          const downloadAnchor = document.createElement('a');
          downloadAnchor.setAttribute('download', `rate-analysis-report.pdf`);
          downloadAnchor.href = fileURL;
          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        }
      });
  };

  const getUpdatedDataForPdf = (docData: any) => {
    let updatedLineItems: any = [];

    if (!Utility.isEmpty(docData?.lineItems)) {
      docData?.lineItems.forEach((item: any) => {
        let rowData = {
          ...item,
          profitMarginPercentage: item.margin,
          netRevenue: item.revenue
        };
        updatedLineItems.push(rowData);
      });
    }

    let payload = {
      documentSequenceCode: docData.documentSequenceCode,
      contact: docData.contact,
      documentDate: docData.documentDate,
      lineItems: updatedLineItems,
      commissionCharges: docData.commissionCharges,
      productPriceMethod: docData.productPriceMethod,
      netProductCost: docData.netProductCost,
      netProductRevenue: docData.netProductRevenue,
      discountOnRevenue: docData.discountOnRevenue,
      taxAmount: docData.taxAmount,
      netProfitMargin: docData.netProfitMargin,
      profitMarginPercentage: docData.profitMarginPercentage,
      grossProductCost: docData.grossProductCost
    };
    return payload;
  };

  const getDocumentButtons = () => {
    const btnStyle = {
      border: '1px solid #dcdcdc',
      borderRadius: 8,
      width: 'auto',
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: 10,
      paddingTop: 10,
      marginRight: 10
    };

    return (
      <div className="flex row .justify-content-start mb-xl ml-l">
        <DKButton
          className="bg-white"
          icon={DKIcons.ic_printer}
          onClick={() => {
            printPdf();
          }}
          style={btnStyle}
          title="Print"
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1078,
          width: '90%',
          height: '90%',
          maxHeight: '90%',
          borderRadius: 4,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div
          className=" parent-width parent-height"
          style={{ paddingTop: 30, overflowY: 'auto' }}
        >
          <div>
            <div className="row justify-content-between align-items-stretch mt-l">
              <div
                className={
                  'column justify-content-between position-relative width-80 pr-l '
                }
              >
                <div className="column justify-content-between position-relative width-80 pr-l">
                  <div className="row width-auto align-items-start gap-2">
                    <div>
                      <div
                        className={`column document-address-block`}
                        style={{
                          minWidth: 170,
                          maxWidth: 250,
                          marginLeft: -10,
                          paddingRight: 10,
                          paddingLeft: 10,
                          paddingBottom: 10
                        }}
                      >
                        <div
                          className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                        >
                          <DKLabel
                            text={tenantInfo.name}
                            className="fw-m fs-r"
                          />
                          <DKLabel
                            className="cursor-pointer"
                            text={getFormattedAddress(
                              booksDocument?.shipFrom || 'No Address'
                            )}
                          />
                        </div>
                        <div
                          className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                        >
                          <DKLabel text={'Contact'} className="fw-m fs-r" />
                          <DKLabel
                            text={booksDocument.contact?.name || ''}
                            className="fw-s fs-r"
                          />
                        </div>
                        <div
                          className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                        >
                          <DKLabel
                            text="Product Price Method"
                            className="fw-m fs-r"
                          />
                          <div style={{ width: '6rem', marginTop: 2 }}>
                            <DKInput
                              options={['FIFO', 'AVERAGE', 'PURCHASE PRICE']}
                              onChange={(typeObj: any) => {
                                setSelectProductMethod(typeObj);
                                switch (typeObj[0]) {
                                  case 0:
                                    setBooksDocument(
                                      (prevBooksDocument: any) => {
                                        return {
                                          ...prevBooksDocument,
                                          productPriceMethod: 'FIFO'
                                        };
                                      }
                                    );
                                    break;
                                  case 1:
                                    setBooksDocument(
                                      (prevBooksDocument: any) => {
                                        return {
                                          ...prevBooksDocument,
                                          productPriceMethod: 'AVERAGE'
                                        };
                                      }
                                    );
                                    break;
                                  case 2:
                                    setBooksDocument(
                                      (prevBooksDocument: any) => {
                                        return {
                                          ...prevBooksDocument,
                                          productPriceMethod: 'PURCHASE PRICE'
                                        };
                                      }
                                    );
                                    break;
                                  default:
                                    setBooksDocument(
                                      (prevBooksDocument: any) => {
                                        return {
                                          ...prevBooksDocument,
                                          productPriceMethod: 'FIFO'
                                        };
                                      }
                                    );
                                    break;
                                }
                                productPurchasePriceMethod();
                              }}
                              value={[selectProductMethod]}
                              type={INPUT_TYPE.SELECT}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column align-items-end m-l">
                <div>
                  <div className="position-relative">
                    <div
                      className={`row width-auto mb-xs justify-content-between 
                          listPickerBG `}
                      style={{
                        width: 240
                      }}
                    >
                      <div className="row width-auto cursor-pointer">
                        <DKIcon
                          src={DKIcons.data_type.ic_number}
                          className="ic-xs-2"
                          style={{ opacity: 0.6 }}
                        />
                        <DKLabel text={'No.'} className={'fw-m ml-r'} />
                      </div>
                      <DKLabel
                        text={booksDocument.documentSequenceCode}
                        className={'ml-r'}
                      />
                    </div>
                  </div>

                  <div className="position-relative mb-m">
                    <div
                      className={`row width-auto mb-xs justify-content-between cursor-pointer `}
                      style={{
                        width: 240
                      }}
                    >
                      <div className="row width-auto ">
                        <DKIcon
                          src={DKIcons.data_type.ic_date}
                          className="ic-xs-2"
                          style={{ opacity: 0.6 }}
                        />
                        <DKLabel text="Date" className={'fw-m ml-r'} />
                      </div>
                      <DKLabel
                        text={booksDocument.documentDate}
                        className="ml-r "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DKDataGrid
              className={'m-r'}
              allowBulkOperation={false}
              needColumnIcons={false}
              allowColumnEdit={false}
              allowColumnSort={false}
              allowRowEdit={true}
              rows={productRows}
              columns={columnConfig}
              onRowUpdate={onRowUpdate}
              width={'100%'}
            />
            <div className="flex justify-content-between">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                {getDocumentButtons()}
              </div>
              <div className="flex justify-content-between mr-r">
                <div
                  style={{
                    width: 330,
                    display: 'inline-block',
                    right: 27
                  }}
                >
                  <RateAnalysisSummaryView
                    booksDocument={booksDocument}
                    onDocumentUpdate={(x: any) => handleDocumentUpdate(x)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
