import {
  COLORS,
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker,
  DKTooltipWrapper,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ic_whatsapp from '../../Assets/Icons/ic_whatsapp.png';
import ic_milestone from '../../Assets/Icons/milestone.png';
import ic_form from '../../Assets/menu/ic_form.png';
import OrganisationProfileForm from '../../Components/Settings/OrganisationProfile/OrganisationProfileForm';
import SellConfig, { SELL_CONFIG_BTN } from '../../Configs/SellConfig';
import ApiConstants from '../../Constants/ApiConstants';
import {
  API_STATUS,
  APPROVAL_STATUS,
  APPROVAL_STATUS_LIST,
  BOOKS_DATE_FORMAT,
  COLUMN_TYPE,
  COUNTRY_CODES,
  CUSTOM_FIELD_TYPE,
  DOC_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  E_INVOICE_RESPONSE_COLOR_MAP,
  E_INVOICE_RESPONSE_MAP,
  EXPORT_FILE_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  IMPORT_CONSTANTS,
  IMPORT_MODULE,
  LABELS,
  MOBILE_APP_ACTIONS,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  MODULE_TYPE,
  MODULES_NAME,
  MRP_APP_NAME,
  OPENING_DOCUMENT_LABEL,
  PAYMENT_STATUS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_TYPE,
  RECURRING_DOCUMENT_TYPE,
  STATUS_TYPE,
  TAX_SYSTEM,
  TEMPLATE_CATEGORY,
  TemplateMappingEvent,
  UOM_NA_ID
} from '../../Constants/Constant';
import {
  EXPORTED_FILES_TYPE_EXTENSION,
  ORGANISATION_FORM_TAB,
  RETURN_MODE
} from '../../Constants/Enum';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE
} from '../../Constants/Permission';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES,
  TABLE_DISPLAY_NAME,
  TABLES
} from '../../Constants/TableConstants';
import {
  updateColumnInfo,
  updateColumnShift
} from '../../Helper/TableColumnUpdateHelper';
import WhatsAppHelper from '../../Helper/WhatsAppHelper';
import useConfirm from '../../Hooks/useConfirm';
import useDebounce from '../../Hooks/useDebounce';
import AppManager from '../../Managers/AppManager';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { DraftTypes } from '../../Models/Drafts';
import {
  BtnType,
  CallBackPayloadType,
  IState,
  LoadingBtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { InvoiceInitialState } from '../../Models/Invoice';
import {
  ColumnDeleteType,
  IColumn,
  IColumnType,
  RowUpdateType
} from '../../Models/Table';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  eInvoiceAuthInfo,
  featurePermissions,
  fetchEInvoiceAuthInfo,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import {
  fetchapprovalConditionList,
  selectAutomation
} from '../../Redux/Slices/AutomationSlice';
import {
  invoiceFilter,
  selectInvoiceFilter,
  selectApplySalesPersonFilter
} from '../../Redux/Slices/BookFilterSlice';
import {
  documentUpdated,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  selectedFooterAction,
  selectIsLoadingPopupWrapper,
  SelectUpdateDocumentData,
  setFooterAction
} from '../../Redux/Slices/CommonDataSlice';
import {
  bulkDeleteDrafts,
  createBlankDraft,
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  selectInvoiceDraftsData,
  setDraftCenter,
  setPopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import {
  addInvoiceColumnConfig,
  fetchInvoices,
  fetchInvoiceSummary,
  fetchPaymentOptions,
  selectInvoices,
  selectInvoicesColumnConfig,
  selectInvoicesColumnConfigTableId,
  selectInvoicesLoadingStatus,
  selectInvoicesSummary,
  updateColumnConfig,
  updateInvoiceDetails
} from '../../Redux/Slices/InvoicesSlice';
import AutomationService from '../../Services/Automation';
import BillService from '../../Services/Bill';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import CustomFieldService from '../../Services/CustomField';
import DateFormatService from '../../Services/DateFormat';
import DraftService from '../../Services/Drafts';
import EInvoiceService from '../../Services/EInvoice';
import EximService from '../../Services/Exim';
import FulfillmentService from '../../Services/FulfillmentService';
import InvoiceService, {
  defaultConfig,
  InvoiceAPIConfig
} from '../../Services/Invoice';
import { localizedText } from '../../Services/Localization/Localization';
import PrintService from '../../Services/Print';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import QuotationService from '../../Services/Quotation';
import SalesOrderService from '../../Services/SalesOrder';
import SalesReturnService from '../../Services/SalesReturn';
import {
  FILTER_DATE,
  SessionStorageService,
  SHOW_TOTAL_IN_BASE_CURRENCY
} from '../../Services/SessionStorageService';
import ApprovalHistory from '../../SharedComponents/ApprovalHistory';
import { Attachments } from '../../SharedComponents/Attachments';
import BulkPayment from '../../SharedComponents/BulkPayment/BulkPayment';
import Chip from '../../SharedComponents/Chip';
import { showAddCustomField } from '../../SharedComponents/CustomField/AddCustomField';
import DataGridHolder from '../../SharedComponents/DataGridHolder';
import { ActionMenuEvent } from '../../SharedComponents/DocumentForm/DocumentActionMenu';
import {
  checkMultiApprovalRequired,
  getApprovalLevel,
  getAutomationDocDraftIds,
  getBulkPrintPreview,
  getEmailPopup,
  getEmailStatusForGrid,
  getMinimizedDocInfo,
  getPrintPreview,
  getTenantTaxSystem,
  isSGAndPeppolOptInDocument,
  isMYAndPeppolOptInDocument,
  isSGAndPeppolOptInEnabled,
  setEmailStatusIds,
  showDocAmountUpdatedAlert,
  addressRenderer,
  checkIfAllProductsActiveInDoc,
  getAdvanceTrackingStatusFromLineItems,
  LINEITEMS_ADV_TRACK_STATUS,
  isStockReservedInDocLineItems
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import RateAnalysisForm from '../../SharedComponents/DocumentForm/RateAnalysisForm';
import EinvoiceStatusHistoryPopup from '../../SharedComponents/EinvoiceStatusHistoryPopup';
import BulkFulillment from '../../SharedComponents/FulfillmentPopup/BulkFulfillment';
import DropshipWarning from '../../SharedComponents/FulfillmentPopup/DropshipWarningPopup';
import Fulfillment from '../../SharedComponents/FulfillmentPopup/Fulfillment';
import FulfillmentConfirmation from '../../SharedComponents/FulfillmentPopup/FulfillmentConfirmationPopup';
import FulfillmentRecords from '../../SharedComponents/FulfillmentPopup/FulfillmentRecords';
import ApprovalOrReject from '../../SharedComponents/FulfillmentPopup/ApprovalOrRejectPopup';
import { COLUMN_VISIBILITY_BUTTON_CONFIG } from '../../SharedComponents/GridColumnVisibility';
import LinkedRecordsPopup from '../../SharedComponents/LinkedRecords/LinkedRecordsPopup';
import { MainDocumentGridSettings } from '../../SharedComponents/MainDocumentGridSettings';
import { enableMultiViewJournalEntry } from '../../SharedComponents/MultiViewJournalEntry/enableMultiViewJounalEntry';
import MultiViewJournalEntry from '../../SharedComponents/MultiViewJournalEntry/MultiViewJournalEntry';
import OffsetPopup from '../../SharedComponents/OffsetPopup/OffsetPopup';
import Payment from '../../SharedComponents/PaymentPopup/Payment';
import PaymentRecords from '../../SharedComponents/PaymentRecords/PaymentRecords';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import RecurringDocument from '../../SharedComponents/RecurringDocumentPopup/RecurringDocument';
import SalesReturnPopup from '../../SharedComponents/SalesReturn/SalesReturn';
import SalesReturnRecords from '../../SharedComponents/SalesReturn/SalesReturnRecords';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import {
  isDesktopOrTablet,
  isTabletView,
  isViewportLarge
} from '../../Utility/ViewportSizeUtils';
import CreateAndEditNotes from '../Accounting/CreateAndEditNotes/CreateAndEditNotes';
import ExpenseDepositForm from '../Bank/ExpenseDeposit/ExpenseDepositForm';
import { getUpdatedBillObject } from '../Bills/BillHelper';
import DetailsOpener from '../Common/DetailsOpener';
import { PaymentMileStone } from '../Common/PaymentMileStone';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Contacts/Details';
import Summary from '../Dashboard/Summary';
import SummaryBarGraph from '../Dashboard/SummaryBarGraph';
import AddEwayBill from '../EwayBill/AddEwayBill';
import EWBErrorLogs from '../EwayBill/EWBErrorLogs';
import GenerateLabelPopup from '../LabelTemplate/GenerateLabelPopup';
import { LABEL_DOCUMENT_TYPE } from '../LabelTemplate/LabelTemplateHelper';
import AddNewWorkOrder from '../Mrp/WorkOrder/AddWorkOrder';
import { validateSelectedInvoiceForInterLinkedWO } from '../Mrp/WorkOrder/WorkOrderHelper';
import { getUpdatedPurchaseOrderObject } from '../PurchaseOrders/PurchaseOrderHelper';
import { getUpdatedQuoteObject } from '../Quotations/QuoteHelper';
import { ResponsiveDateRangePicker } from '../responsive/ResponsiveDateRangePicker';
import { getUpdatedSalesOrderObject } from '../SalesOrders/SalesOrderHelper';
import {
  checkUserPermission,
  GranularPermissionsHelper
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import CancelEInvoice from '../Settings/OrganisationProfile/CancelEInvoice';
import { IRPCredPopup } from '../Settings/OrganisationProfile/IRPCredPopup';
import {
  EWAY_BILL_STATUS,
  getInvoiceImportOptions,
  getInvoiceNowStatus,
  getUpdatedInvoiceObject,
  INVOICE_NOW_TYPES,
  invoiceEmailTemplateConfig
} from './InvoiceHelper';
import RateAnalysisService from '../../Services/RateAnalysis';
import { selectImportCustomFormats } from '../../Redux/Slices/ImportSlice';
import ViewAdvancedTrackingReservedData from '../Common/ViewAdvancedTrackingReservedData';
import { LOG_VIEW } from '../Settings/Logs';
import { COUNTRIES_WITH_CURRENCIES } from '../PriceBook/CountriesWithCurrencies';
import { selectDimensions } from '../../Redux/Slices/LocationSlice';
import { fetchContacts } from '../../Redux/Slices/ContactsSlice';
import AiAPICallService from '../../Services/AiAPICall';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import DropshipConfirmation from '../../SharedComponents/FulfillmentPopup/DropshipConfirmationPopup';
export interface IInvoicesProps {
  listByContact?: boolean;
  contact?: any;
}
export default function Invoices(props: IInvoicesProps) {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const initialState: IState = {
    columnData: [],
    rowData: [],
    originalData: [],
    filter: []
  };

  const dispatch = useAppDispatch();

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };

  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];

  const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Saving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const paymentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.RECEIVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.RECEIVE_PAYMENT
    }
  ];

  const loadingPaymentPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Receiving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const fulfillmentConfirmationPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const popupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.BUTTON.CANCEL`),
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.BUTTON.UPDATE`),
      class: 'bg-button text-white mr-r',
      clickAction: POPUP_CLICK_TYPE.UPDATE_INVOICE
    }
  ];

  const offSetBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.PAYMENT_RECORDS.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.PAYMENT_RECORDS.BUTTON.APPLY`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.APPLY_OFFSET
    }
  ];

  const paymentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`Close`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const fulfillmentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const bulkFulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.BULK_FULFILMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.BULK_FULFILLMENT
    }
  ];

  const dropShipButtonConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.NO`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.YES`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CONFIRM_DROPSHIP
    }
  ];

  const recurringDocumentPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SAVE_RECURRING_DOCUMENT
    }
  ];
  const salesReturnPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Return',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SALES_RETURN
    }
  ];
  const directExpenseBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT
    }
  ];
  const CNBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_CREDIT
    }
  ];

  // State hooks
  const [invoices, setInvoices] = useState(initialState);
  const [updating, setUpdating] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [editableInvoice, setEditableInvoice] = useState<any>();
  const [editableInvoiceForDropship, setEditableInvoiceInvoiceForDropship] =
    useState<any>();
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>();
  const [fulfillmentType, setFulfillmentType] = useState<any>(
    'Fulfillment Records'
  );
  const addInvoiceRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const dimensionData = useAppSelector(selectDimensions);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [isFulfillmentForDropship, setisFulfillmentForDropship] =
    useState<boolean>(false);
  const [
    showFulfillmentConfirmationPopup,
    setShowFulfillmentConfirmationPopup
  ] = useState<boolean>(false);
  const [showDropshipConfirmationPopup, setShowDropshipConfirmationPopup] =
    useState<boolean>(false);
  const [showRateAnalysisForm, setShowRateAnalysisForm] = useState(false);
  const [rateAnalysisData, setRateAnalysisData] = useState<any>(null);
  const [showPaymentRecord, setShowPaymentRecord] = useState<boolean>(false);
  const [paymentRecords, setPaymentRecords] = useState<any[]>();
  const [salesInvoiceCode, setSalesInvoiceCode] = useState<string>('');
  const [salesDocumentSequenceCode, setSalesDocumentSequenceCode] =
    useState<string>('');
  const [showOffsetPopup, setShowOffsetPopup] = useState<boolean>(false);
  const [showImportOptions, setShowImportOptions] = useState<boolean>(false);
  const [showFulfillmentRecordsPopup, setShowFulfillmentRecordsPopup] =
    useState<boolean>(false);
  const [currentSelectedRows, setCurrentSelectedRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [bulkPaymentRows, setBulkPaymentRows] = useState<any[]>([]);
  const [bulkPendingEInvoicesRows, setBulkPendingEInvoicesRows] = useState<
    any[]
  >([]);
  const [bulkGeneratedEInvoicesRows, setBulkGeneratedEInvoicesRows] = useState<
    any[]
  >([]);
  const [showBulkFulfillmentPopup, setShowBulkFulfillmentPopup] =
    useState<boolean>(false);
  const [bulkFulfillmentDetails, setBulkFulfillmentDetails] = useState<any[]>(
    []
  );
  const [showBulkPaymentPopup, setShowBulkPaymentPopup] =
    useState<boolean>(false);
  const tableId = useAppSelector(draftTableId);
  const [showDropShipWarningPopup, setShowDropShipWarningPopup] =
    useState<boolean>(false);
  const [showDropShipPopup, setShowDropShipPopup] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>([]);
  const [showRecurringPopup, setShowRecurringPopup] = useState<boolean>(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showTotalInBaseCurrency, setShowTotalInBaseCurrency] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [filterDates, setFilterDates] = useState<any>(undefined);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [bulkFulfillmentRows, setBulkFulfillmentRows] = useState<any[]>([]);
  const [showIRPCredPopup, setShowIRPCredPopup] = useState(false);
  const [showCancelEInvoice, setShowCancelEInvoice] = useState(false);
  const [IRPCredPopupType, setIRPCredPopupType] = useState('');
  const [isApprovalInprogress, setIsApprovalInprogress] = useState(false);
  // Store data selectors
  const invoicesData = useAppSelector(selectInvoices);
  const loadingStatus = useAppSelector(selectInvoicesLoadingStatus);
  const columnConfig = useAppSelector(selectInvoicesColumnConfig);
  const columnConfigTableId = useAppSelector(selectInvoicesColumnConfigTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const invoiceDraftData = useAppSelector(selectInvoiceDraftsData) as any[];
  const draftsTableId = useAppSelector(draftTableId);
  const activeDrafts: any[] = useAppSelector(selectDrafts);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const footerAction = useAppSelector(selectedFooterAction);
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(0);
  const [bulkApprovalRows, setBulkApprovalRows] = useState<any[]>([]);
  const [bulkRejectRows, setBulkRejectRows] = useState<any[]>([]);
  const invoicesSummarydata = useAppSelector(selectInvoicesSummary);
  const [invoicesSummary, setInvoicesSummary] = useState<any>();
  const [showCreateEWayBill, setShowCreateEWayBill] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [showSalesReturnPopup, setShowSalesReturnPopup] =
    useState<boolean>(false);
  const [showSalesReturnRecordsPopup, setShowSalesReturnRecordsPopup] =
    useState<boolean>(false);
  const [salesReturnsDetails, setSalesReturnsDetails] = useState<any>();
  const [showApprovalHistoryPopup, setShowApprovalHistoryPopup] =
    useState<boolean>(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState<any>();
  const [showExpenseButton, setshowExpenseButton] = useState<any>();
  const [expenseData, setExpenseData] = useState<any>();
  const expenseRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [showCNAndDNInfo, setShowCNAndDNInfo] = useState({
    showPopup: false,
    data: null,
    documentType: DOC_TYPE.CREDIT_NOTE,
    isTouched: false
  });
  // Approval/Reject PopUp

  const [showApprovalOrRejectPopup, setShowApprovalOrRejectPopup] =
    useState<boolean>(false);

  const [approvalOrRejectData, setApprovalOrRejectData] = useState<any>({
    title: '',
    actionDetails: null
  });
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const createCreditRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [importOptions, setImportOptions] = useState<string[]>(
    getInvoiceImportOptions(tenantInfo)
  );
  const availableCustomFields = useAppSelector(selectCustomFields);
  // Filter contacts
  const invoiceFilterData: any = useAppSelector(selectInvoiceFilter);

  const [paymentMilestoneBeingSaved, setPaymentMilestoneBeingSaved] =
    useState(false);
  const addPaymentMilestoneRef =
    useRef<UpdateCallBacksRefType>(refInitialState);
  const [showPaymentMilestonePopup, setShowPaymentMilestonePopup] =
    useState<boolean>(false);
  const [showMultiViewJournalEntry, setShowMultiViewJournalEntry] =
    useState<boolean>(false);
  const duration_filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_30_DAYS'),
      value: 'LAST30'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_MONTH'),
      value: 'CURRENT_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_MONTH'),
      value: 'LAST_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];

  const [detailsPopupData, setDetailsPopupData] = useState<any>();

  const [bulkEWBRows, setBulkEWBRows] = useState<any[]>([]);
  const [bulkPrintRecordData, setBulkPrintRecordData] = useState<any[]>([]);
  const { id: docCodeInURL, contactId: contactIdInURL }: any = useParams();
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);
  const [showLinkRecordsPopup, setShowLinkRecordsPopup] =
    useState<boolean>(false);
  const [invoiceLinkRecordsData, setInvoiceLinkRecords] = useState<any>({});
  const [multiViewJournalEntryData, setMultiViewJournalEntryData] =
    useState<any>({});
  const updatedDocumentData = useAppSelector(SelectUpdateDocumentData);
  const [generateLabelPopup, setGenerateLabelPopup] = useState<boolean>(false);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  const [isCNActionBtnDisabled, setIsCNActionBtnDisabled] =
    useState<boolean>(false);
  const [showAddWorkOrderPopup, setShowAddWorkOrderPopup] = useState(false);
  const [paymentContactId, setPaymentContactId] = useState(null);
  const [showEinvoiceStatusHistoryPopup, setShowEinvoiceStatusHistoryPopup] =
    useState({
      show: false,
      data: []
    });
  const [cancelEwayBillErrorLogs, setCancelEwayBillErrorLogs] = useState<any>({
    show: false,
    logs: []
  });
  const [selectedWO, setSelectedWO] = useState(null);

  const [viewReservedQuantityPopup, setViewReservedQuantityPopup] =
    useState(false);
  const [selectedRowForReservedData, setSelectedRowForReservedData] =
    useState(null);

  const approvalConditions = useAppSelector(selectAutomation);
  const approvalColumnKey =
    columnConfig?.find(
      (col: any) =>
        col?.columnCode === COLUMN_CODE.PURCHASE_ORDER.ApprovalStatus
    )?.id ?? '';

  const createdByColumnKey =
    columnConfig?.find(
      (col: any) => col?.columnCode === COLUMN_CODE.PURCHASE_ORDER.CreatedBy
    )?.id ?? '';

  const customImportFormats = useAppSelector(selectImportCustomFormats);
  const canApplySalesPersonFilterOnInvoice = useAppSelector(
    selectApplySalesPersonFilter
  );

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };
  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  useEffect(() => {
    const { newData, oldData } = updatedDocumentData;
    if (
      newData &&
      oldData &&
      newData['documentType'] === DOC_TYPE.INVOICE &&
      newData['paymentMilestoneFlag']
    ) {
      if (newData?.totalAmount !== oldData?.totalAmount) {
        showDocAmountUpdatedAlert(
          () => {
            setEditableInvoice(newData);
            setShowPaymentMilestonePopup(true);
          },
          () => {
            dispatch(documentUpdated({}));
          }
        );
      }
    }
  }, [updatedDocumentData]);

  useEffect(() => {
    setInvoicesSummary(invoicesSummarydata);
  }, [invoicesSummarydata]);

  useEffect(() => {
    if (!showCNAndDNInfo.showPopup && showCNAndDNInfo.isTouched) {
      loadInvoices({});
    }
  }, [showCNAndDNInfo]);

  useEffect(() => {
    if (!props.listByContact) {
      RouteManager.setPresenter({ props });
    }
    loadPaymentOptions();
    loadApprovalConditionList();
    addCustomImportOptions();
    if (!props.listByContact) {
      dispatch(
        fetchDrafts({
          tableId: draftsTableId,
          isSaveColumnId: isSavedColumnId,
          draftTypeColId: draftTypeColId,
          draftTypeColValue: LABELS.INVOICES
        })
      );
    }
    if (!Utility.isEmpty(tenantInfo)) {
      setInitialConfigFromStorage();
    }
    return () => setInvoices(initialState);
  }, []);

  // Open doc from document code in the URL
  useEffect(() => {
    if (!props.listByContact) {
      if (!Utility.isEmpty(tenantInfo) && !Utility.isEmpty(docCodeInURL)) {
        const openedAndMaximizedDrafts = activeDrafts?.filter(
          (draft: any) => draft.isMaximized && draft.isCenterAlign
        );
        if (!Utility.isEmpty(docCodeInURL)) {
          directOpenDocument(docCodeInURL);
        } else {
          openedAndMaximizedDrafts.forEach((draft: any) => {
            dispatch(removeDraft(draft.id));
          });
        }
      }
    }
  }, [docCodeInURL, tenantInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo) && Utility.isEmpty(filterDates)) {
      setInitialConfigFromStorage();
    }
  }, [tenantInfo]);

  const setInitialConfigFromStorage = () => {
    const savedSettings = SessionStorageService.fetchConfigSettings(
      MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
      DOC_TYPE.INVOICE
    );
    if (!Utility.isEmpty(savedSettings)) {
      setShowTotalInBaseCurrency(savedSettings[SHOW_TOTAL_IN_BASE_CURRENCY]);
    }
    setFilterDates({
      startDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(invoiceFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              invoiceFilterData?.filterDates.startDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialStartDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      endDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(invoiceFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              invoiceFilterData?.filterDates.endDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialEndDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )
    });
  };

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      let config = {
        ...defaultConfig,
        Query: `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}`,
        QueryParam: `&sort=salesInvoiceDate&sortDir=DESC`,
        QuerySummary: `fromDate=${DateFormatService.getFormattedDateString(
          filterDates.startDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )}&toDate=${DateFormatService.getFormattedDateString(
          filterDates.endDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )}`
      };

      if (
        canApplySalesPersonFilterOnInvoice.canApplyFilter &&
        canApplySalesPersonFilterOnInvoice?.loggedInUserInfo?.[0]?.id
      ) {
        config.SalesPerson =
          canApplySalesPersonFilterOnInvoice?.loggedInUserInfo?.[0]?.id;
      }

      InvoiceService.apiConfig = config;

      // Check selected filter already exist
      if (Utility.isObject(invoiceFilterData?.config)) {
        let filteredQueryList = Utility.getFilterParameters(
          invoiceFilterData?.queries,
          REMOTE_CONFIG_TABLES.INVOICE
        );

        const list = Utility.getFilteredQueryList(filteredQueryList);
        let withoutFilter, withFilter;
        if (
          list.newFilteredQueyListArr &&
          list.newFilteredQueyListArr.length > 0
        ) {
          withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
        }
        if (list.customField && list.customField.length > 0) {
          assignProductModule('CLASS', 'CLASS', list.customField);
          assignProductModule('CLASS', 'LOCATION', list.customField);
          withFilter = Utility.getQueryString(list.customField);
        }

        config = {
          ...config,
          SearchTerm: invoiceFilterData?.SearchTerm,
          Query:
            `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}` +
            (withoutFilter ? `,${withoutFilter}` : ''),
          QueryParam: `&sort=salesInvoiceDate&sortDir=DESC&${withFilter}`
        };
        InvoiceService.apiConfig = config;

        setFilterDataInStore(
          { ...config },
          invoiceFilterData?.SearchTerm,
          deepClone(invoiceFilterData?.queries),
          {
            startDate: filterDates.startDate,
            endDate: filterDates.endDate
          }
        );
      } else {
        setFilterDataInStore(
          { ...config },
          invoiceFilterData?.SearchTerm,
          deepClone(invoiceFilterData?.queries),
          { startDate: filterDates.startDate, endDate: filterDates.endDate }
        );
      }
    }
  }, [filterDates]);

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      getInitialFilterData();
    }
  }, [invoiceFilterData]);

  const loadPaymentOptions = () => {
    dispatch(fetchPaymentOptions(tenantInfo?.currency));
  };

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const _getEInvoiceChip = (obj: any) => {
    let _status: any = {
      title: '',
      color: ''
    };
    switch (obj?.value?.[0]) {
      case INVOICE_NOW_TYPES.FAILED:
        _status = {
          title: INVOICE_NOW_TYPES.FAILED,
          color: 'bg-chip-red'
        };
        break;
      case INVOICE_NOW_TYPES.GENERATED:
        _status = {
          title: INVOICE_NOW_TYPES.GENERATED,
          color: 'bg-chip-green'
        };
        break;
      case INVOICE_NOW_TYPES.PENDING:
        _status = {
          title: INVOICE_NOW_TYPES.PENDING,
          color: 'bg-chip-orange'
        };
        break;
      case INVOICE_NOW_TYPES.CANCELLED:
        _status = {
          title: INVOICE_NOW_TYPES.CANCELLED,
          color: 'bg-chip-red'
        };
        break;
    }

    let HTML: any = '';
    if (
      obj?.rowData?.einvoiceInfoIndia &&
      getInvoiceNowStatus(obj?.rowData) !== INVOICE_NOW_TYPES.FAILED
    ) {
      HTML = `
      <div style="word-wrap: break-word" className="w-3/4">
          <div className="row">
            <span className="fw-m mr-2"><strong>IRN Number : </strong></span>
          </div>
          <div className="row mb-1">${obj?.rowData?.einvoiceInfoIndia.irn}</div>
          ${
            getInvoiceNowStatus(obj?.rowData) !== INVOICE_NOW_TYPES.CANCELLED
              ? `<div className="row">
                <span className="fw-m mr-2"><strong>Acknowledgement Number : </strong></span></br>
                ${
                  obj?.rowData?.einvoiceInfoIndia &&
                  obj?.rowData?.einvoiceInfoIndia.ackNo
                }
              </div>
              <div className="row">
                <span className="fw-m mr-2"><strong>Acknowledgement Date : </strong></span></br>
                ${
                  obj?.rowData?.einvoiceInfoIndia &&
                  obj?.rowData?.einvoiceInfoIndia.ackDt
                }
              </div>`
              : ``
          }
        </div>
        `;
    }

    return (
      <DKTooltipWrapper
        content={HTML}
        tooltipClassName="bg-deskera-secondary width-auto"
      >
        <div className="flex items-center">
          <DKLabel
            text={Utility.convertInTitleCase(_status.title)}
            style={{
              textTransform: 'capitalize',
              width: 'auto',
              padding: '2px 6px'
            }}
            className={`${_status.color} border-radius-s`}
          />
        </div>
      </DKTooltipWrapper>
    );
  };

  const getFilteredColumns = (columns: any[]) => {
    const columnCode: any[] = [];
    columnCode.push(COLUMN_CODE.INVOICE.PaymentFileGenerated);
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.VIEW)) {
      columnCode.push(COLUMN_CODE.INVOICE.Contact);
    }
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.VIEW_PRICE)) {
      columnCode.push(COLUMN_CODE.INVOICE.TotalAmount);
      columnCode.push(COLUMN_CODE.INVOICE.DueAmount);
    }
    if (Utility.isNotEmpty(columnCode)) {
      columns = columns?.filter((col) => !columnCode.includes(col.columnCode));
    }
    return columns;
  };

  const addCustomImportOptions = () => {
    if (Array.isArray(customImportFormats)) {
      const invCustomFormats = customImportFormats.filter(
        (cf) => cf.module === IMPORT_MODULE.INVOICE
      );

      if (invCustomFormats.length > 0) {
        const newImportOptions = importOptions.map((io) =>
          io === 'Invoice' ? 'Default Invoice' : io
        );
        invCustomFormats.forEach((cf) => {
          newImportOptions.push(cf.formatName);
        });

        setImportOptions(newImportOptions);
      }
    }
  };

  // Executes when invoice data changes in store
  useEffect(() => {
    if (!Utility.isEmpty(invoicesData)) {
      const payload: any = Utility.getKeyOfColumn(
        draftsColumnConfig,
        COLUMN_CODE.DRAFTS.Payload
      );
      const payloadKey = payload && payload.length ? payload[0].id : null;
      let draftInvoices;
      if (payloadKey) {
        const userMap = Utility.getUserIdMap();
        draftInvoices = invoiceDraftData
          ?.filter((data) => Utility.isNotEmpty(data.cells[payloadKey]))
          .map((data: any) => {
            return {
              ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
              id: data._id,
              isDraftDocument: true,
              approvalStatus: data.cells.approvalStatus,
              draftCode: data.cells.documentSequenceCode,
              createdBy: userMap.get(data.createdBy)
            };
          });
        if (draftInvoices) {
          draftInvoices = draftInvoices?.filter((ele) => {
            if (!Utility.isEmpty(searchTerm)) {
              let safePattern = Utility.escapeRegex(searchTerm);
              return (
                ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                ele.draftCode?.search(new RegExp(safePattern, 'i')) >= 0
              );
            } else {
              return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
            }
          });
        }
      }

      const { content, pageable, totalPages } = invoicesData;
      let combinedInvoices = !Utility.isEmpty(content) ? [...content] : [];
      if (
        draftInvoices &&
        draftInvoices.length > 0 &&
        Utility.isEmpty(filters) &&
        !props.listByContact
      ) {
        combinedInvoices = [...draftInvoices, ...combinedInvoices];
      }

      if (
        !Utility.isEmpty(combinedInvoices) ||
        (filters && filters.length > 0)
      ) {
        let tempColumnConfig = deepClone(columnConfig) as any[];
        tempColumnConfig =
          Utility.updateLocationAndClassTitleForGrid(tempColumnConfig);
        let contactConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'contact'
        );
        if (tenantInfo.country === COUNTRY_CODES.IN) {
          tempColumnConfig = localiseColumnConfig(tempColumnConfig);
        } else {
          tempColumnConfig = tempColumnConfig.filter((col) => {
            return (
              col.columnCode !== 'ewayBillNumber' &&
              col.columnCode !== 'ewayBillStatus'
            );
          });
        }
        // Convert user type to select type to support filter
        tempColumnConfig = Utility.convertUserTypeColumnToSelectType([
          ...tempColumnConfig
        ]);

        if (contactConfigIndex !== -1) {
          tempColumnConfig[contactConfigIndex] = {
            ...tempColumnConfig[contactConfigIndex],
            renderer: (obj: any) => {
              const linkedDocsWithoutWO = obj.rowData?.linkedDocuments;
              return (
                <div className="row justify-content-between">
                  <div
                    onClick={() => {
                      contactDetailOpener(
                        obj?.rowData?.contactDto?.code ||
                          obj?.rowData?.contactCode
                      );
                    }}
                  >
                    <DKLabel
                      text={obj.value}
                      className="underline cursor-hand mr-r fs-m"
                    />
                  </div>
                  <div className="row width-auto">
                    {getEmailStatusForGrid(obj.rowData, DOC_TYPE.INVOICE)}
                    <DKLabel text={Utility.getIconForGrid(obj.rowData)} />
                    {linkedDocsWithoutWO?.length > 0 && (
                      <DKTooltipWrapper
                        contentRenderer={() =>
                          getTootltipContent(linkedDocsWithoutWO)
                        }
                        tooltipClassName="bg-deskera-secondary width-auto"
                      >
                        <DKIcon
                          src={DKIcons.data_type.ic_url}
                          className="ic-xs opacity-60 cursor-pointer ml-s"
                        />
                      </DKTooltipWrapper>
                    )}
                  </div>
                </div>
              );
            }
          };
        }
        if (!!props?.listByContact) {
          tempColumnConfig.splice(contactConfigIndex, 1);
        }

        let dueDateConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.InvoiceDueDate
        );
        if (dueDateConfigIndex !== -1) {
          tempColumnConfig[dueDateConfigIndex] = {
            ...tempColumnConfig[dueDateConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              const infoObj = Utility.getDueDateInfoForGrid(
                obj.value,
                obj.rowData.isDraftDocument
              );
              const paymentStatus = obj.rowData?.paymentStatus
                ? obj.rowData?.paymentStatus[0]
                : null;
              const paymentMilestoneBlock = obj.rowData
                ?.paymentMilestoneFlag ? (
                <DKTooltipWrapper
                  content={'Payment milestones added, click to view.'}
                  tooltipClassName="bg-deskera-secondary width-auto"
                >
                  <DKIcon
                    className="ml-m ic-s cursor-hand"
                    src={ic_milestone}
                    onClick={() => {
                      const invoice = deepClone(obj.rowData);
                      setEditableInvoice(invoice);
                      setShowPaymentMilestonePopup(true);
                    }}
                  />
                </DKTooltipWrapper>
              ) : null;

              return (
                <div className="row justify-content-between">
                  {paymentStatus &&
                    paymentStatus === PAYMENT_STATUS.RECEIVED && (
                      <DKLabel
                        text={DateFormatService.getDateStrFromDate(obj.value)}
                        className="fs-m"
                      />
                    )}
                  {paymentStatus &&
                    paymentStatus !== PAYMENT_STATUS.RECEIVED && (
                      <DKTooltipWrapper
                        content={infoObj.text}
                        tooltipClassName="bg-deskera-secondary width-auto"
                      >
                        <DKLabel
                          text={DateFormatService.getDateStrFromDate(obj.value)}
                          className={`${infoObj.class}`}
                        />
                      </DKTooltipWrapper>
                    )}
                  {paymentMilestoneBlock}
                </div>
              );
            }
          };
        }

        let eInvoiceConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.InvoiceNow
        );

        if (tenantInfo?.indiaEinvoice) {
          if (eInvoiceConfigIndex !== -1) {
            tempColumnConfig[eInvoiceConfigIndex] = {
              ...tempColumnConfig[eInvoiceConfigIndex],
              allowFilter: true,
              type: 'text',
              renderer: (obj: any) => {
                return <div className="column">{_getEInvoiceChip(obj)}</div>;
              }
            };
          }
        } else {
          tempColumnConfig.splice(eInvoiceConfigIndex, 1);
        }

        let totalAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.TotalAmount
        );

        if (totalAmountColumnIndex !== -1) {
          let name = tempColumnConfig[totalAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[totalAmountColumnIndex] = {
            ...tempColumnConfig[totalAmountColumnIndex],
            // allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }

        let dueAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.DueAmount
        );

        if (dueAmountColumnIndex !== -1) {
          let name = tempColumnConfig[dueAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[dueAmountColumnIndex] = {
            ...tempColumnConfig[dueAmountColumnIndex],
            allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }
        if (!Utility.isApprovalColumn(DOC_TYPE.INVOICE)) {
          tempColumnConfig = tempColumnConfig.filter(
            (config) => config.columnCode !== COLUMN_CODE.INVOICE.ApprovalStatus
          );
        }

        let einvoiceResponseStatus = tempColumnConfig.findIndex(
          (config) =>
            config.columnCode === COLUMN_CODE.INVOICE.EInvoiceResponseStatus
        );
        if (!isSGAndPeppolOptInEnabled()) {
          // remove
          tempColumnConfig = tempColumnConfig.filter(
            (config) => config.columnCode !== COLUMN_CODE.BILL.PaymentTerm
          );
        }
        if (
          tenantInfo.country == COUNTRY_CODES.SG &&
          einvoiceResponseStatus !== -1
        ) {
          tempColumnConfig[einvoiceResponseStatus] = {
            ...tempColumnConfig[einvoiceResponseStatus],
            renderer: (obj: any) => {
              const invoiceNowStatus = obj?.rowData
                ?.sgEInvoiceStatus as keyof typeof E_INVOICE_RESPONSE_MAP;
              const status = E_INVOICE_RESPONSE_MAP[invoiceNowStatus];
              const chipColor = E_INVOICE_RESPONSE_COLOR_MAP[invoiceNowStatus];
              if (status) {
                return (
                  <Chip
                    label={status}
                    color={chipColor}
                    onClick={() => {
                      setShowEinvoiceStatusHistoryPopup({
                        show: true,
                        data: obj?.rowData?.einvoiceStatus
                      });
                    }}
                  />
                );
              } else {
                return <></>;
              }
            }
          };
        } else {
          tempColumnConfig = tempColumnConfig.filter(
            (config) =>
              config.columnCode !== COLUMN_CODE.INVOICE.EInvoiceResponseStatus
          );
        }
        tempColumnConfig = tempColumnConfig.filter(
          (config) =>
            config.columnCode !== COLUMN_CODE.INVOICE.EInvoiceResponseReason
        );

        // Payment Term Column
        const paymentTermColumn = 'paymentTerm';
        const paymentTermColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === paymentTermColumn
        );
        if (paymentTermColumnIndex !== -1) {
          if (isSGAndPeppolOptInEnabled()) {
          } else {
            tempColumnConfig.splice(paymentTermColumnIndex, 1);
          }
        }
        let attachmentColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'attachments'
        );
        if (attachmentColumnIndex !== -1) {
          tempColumnConfig[attachmentColumnIndex] = {
            ...tempColumnConfig[attachmentColumnIndex],
            renderer: (obj: any) => {
              if (obj?.rowData?.attachmentsWithLink?.length) {
                return (
                  <div className="row">
                    <div onClick={() => onAttachmentCLicked(obj)}>
                      <DKIcon
                        src={DKIcons.ic_attachment}
                        className="ic-s cursor-pointer opacity-60 hover:opacity-50"
                      />
                    </div>
                    <DKButton
                      title="View"
                      className="text-blue underline p-v-0"
                      onClick={() => onAttachmentCLicked(obj)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            }
          };
        }

        let remarksConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.Remarks
        );

        if (remarksConfigIndex !== -1) {
          tempColumnConfig[remarksConfigIndex] = {
            ...tempColumnConfig[remarksConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              let remarksData = '';
              if (
                obj.rowData.multiApprovalDetails?.approvalHistory?.length > 0
              ) {
                const allRemarks =
                  obj.rowData.multiApprovalDetails.approvalHistory
                    .filter((history: any) => history?.remarks)
                    .map(
                      (history: any) =>
                        `Level ${history.level}: ${history.remarks}`
                    );

                remarksData = allRemarks.join(', ');
              }
              return (
                <div className="column">
                  <DKTooltipWrapper
                    content={remarksData}
                    tooltipClassName="bg-deskera-secondary width-auto"
                  >
                    <DKLabel text={obj.value} />
                  </DKTooltipWrapper>
                </div>
              );
            }
          };
        }

        const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
          (product: any) => {
            return product === MRP_APP_NAME;
          }
        );

        let linkedWorkorder = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'linkedDocuments'
        );
        if (
          window.location.origin?.includes(
            ApiConstants.URL.APP_MRP_URL || ''
          ) &&
          !Utility.isEmpty(mrpProduct)
        ) {
          if (linkedWorkorder !== -1) {
            tempColumnConfig[linkedWorkorder] = {
              ...tempColumnConfig[linkedWorkorder],
              renderer: (obj: any) => {
                const workOrders = obj.rowData?.linkedDocuments?.filter(
                  (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
                );
                return (
                  <div className="" style={{ gap: 1 }}>
                    {workOrders &&
                      workOrders?.map((workOrder: any) =>
                        getLinkedWorkOrders(workOrder)
                      )}
                  </div>
                );
              }
            };
          }
        } else {
          tempColumnConfig.splice(linkedWorkorder, 1);
        }

        let billToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.BillTo
        );

        let shipToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.ShipTo
        );

        if (billToConfigIndex !== -1) {
          tempColumnConfig[billToConfigIndex] = {
            ...tempColumnConfig[billToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.billTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.BillTo);
              } else {
                return '';
              }
            }
          };
        }

        if (shipToConfigIndex !== -1) {
          tempColumnConfig[shipToConfigIndex] = {
            ...tempColumnConfig[shipToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.shipTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.ShipTo);
              } else {
                return '';
              }
            }
          };
        }

        let createdByConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.CreatedBy
        );

        if (createdByConfigIndex !== -1) {
          tempColumnConfig[createdByConfigIndex] = {
            ...tempColumnConfig[createdByConfigIndex],
            options: Utility.getUserOptions()
          };
        }

        tempColumnConfig = getUpdatedColumnConfig(tempColumnConfig);
        tempColumnConfig = getFilteredColumns(tempColumnConfig);
        const filterColumns = getColumnsForFilter(tempColumnConfig);
        const rowData = getRowData(combinedInvoices);
        const passingData = {
          columnData: tempColumnConfig,
          filterColumns,
          rowData,
          filter: filters,
          originalData: rowData
        };
        setInvoices(passingData);
        setTotalPageCount(totalPages);
        setCurrentPage(+pageable?.pageNumber + 1);
      } else {
        const rowData = getRowData(combinedInvoices);
        let tempColumnConfig = deepClone(columnConfig) as any[];
        if (tenantInfo.country === COUNTRY_CODES.IN) {
          tempColumnConfig = localiseColumnConfig(tempColumnConfig);
        } else {
          tempColumnConfig = tempColumnConfig.filter((col) => {
            return (
              col.columnCode !== 'ewayBillNumber' &&
              col.columnCode !== 'ewayBillStatus'
            );
          });
        }
        // Convert user type to select type to support filter
        tempColumnConfig = Utility.convertUserTypeColumnToSelectType([
          ...tempColumnConfig
        ]);

        let eInvoiceConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.INVOICE.InvoiceNow
        );

        if (!tenantInfo?.indiaEinvoice && eInvoiceConfigIndex !== -1) {
          tempColumnConfig.splice(eInvoiceConfigIndex, 1);
        }

        tempColumnConfig =
          Utility.updateLocationAndClassTitleForGrid(tempColumnConfig);
        if (!Utility.isApprovalColumn(DOC_TYPE.INVOICE)) {
          tempColumnConfig = tempColumnConfig.filter(
            (config) => config.columnCode !== COLUMN_CODE.INVOICE.ApprovalStatus
          );
        }

        tempColumnConfig = getUpdatedColumnConfig(tempColumnConfig);
        tempColumnConfig = getFilteredColumns(deepClone(tempColumnConfig));
        const filterColumns = getColumnsForFilter(tempColumnConfig);
        const passingData = {
          columnData: tempColumnConfig,
          filterColumns,
          rowData,
          filter: filters,
          originalData: rowData
        };
        setInvoices(passingData);
        setTotalPageCount(totalPages);
      }
    }
  }, [
    invoicesData,
    invoiceDraftData,
    showTotalInBaseCurrency,
    availableCustomFields
  ]);

  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== 'undefined' &&
      debouncedSearchTerm !== null
    ) {
      const config: InvoiceAPIConfig = {
        ...InvoiceService.apiConfig,
        Page: 0,
        SearchTerm: debouncedSearchTerm
      };
      setFilterDataInStore(
        { ...config },
        debouncedSearchTerm,
        deepClone(invoiceFilterData?.queries)
      );
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!showPaymentMilestonePopup) {
      setPaymentMilestoneBeingSaved(false);
      dispatch(documentUpdated({}));
    }
  }, [showPaymentMilestonePopup]);

  const getUpdatedColumnConfig = (tempColumnConfig: any) => {
    let advanceTrackingStatusColIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'advancedTrackingStatus'
    );

    if (advanceTrackingStatusColIndex !== -1) {
      tempColumnConfig[advanceTrackingStatusColIndex] = {
        ...tempColumnConfig[advanceTrackingStatusColIndex],
        allowFilter: false,
        allowColumnSort: false,
        renderer: (data: any) => {
          let advTrackLineItemStatus = getAdvanceTrackingStatusFromLineItems(
            data?.rowData?.salesInvoiceItems
          );
          let color = '';
          if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.BATCH_TRACKED
          ) {
            color = 'data-grid-badge-color-2';
          } else if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.SERIAL_TRACKED
          ) {
            color = 'data-grid-badge-color-4';
          } else if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.BOTH
          ) {
            color = 'data-grid-badge-color-5';
          }
          return (
            <DKLabel
              text={Utility.convertInTitleCase(advTrackLineItemStatus ?? '-')}
              style={{
                height: 24,
                padding: 4
              }}
              className={`text-align-center border-radius-s ${color}`}
            />
          );
        }
      };
    }

    let stockReservedColIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'stockReserved'
    );

    if (stockReservedColIndex !== -1) {
      tempColumnConfig[stockReservedColIndex] = {
        ...tempColumnConfig[stockReservedColIndex],
        allowFilter: false,
        allowColumnSort: false,
        renderer: (data: any) => {
          let isStockReserved = isStockReservedInDocLineItems(
            data?.rowData?.salesInvoiceItems
          );
          return (
            <div className="row">
              <DKLabel
                text={isStockReserved ? 'Stock Reserved' : 'Not Reserved'}
                className={`text-align-center ${
                  isStockReserved ? 'text-green' : 'text-red'
                }`}
              />
              {isStockReserved && (
                <span
                  className={`underline text-green ml-s cursor-hand`}
                  onClick={() => {
                    setSelectedRowForReservedData(data?.rowData);
                    setViewReservedQuantityPopup(true);
                  }}
                >
                  (View)
                </span>
              )}
            </div>
          );
        }
      };
    }

    return tempColumnConfig;
  };

  const getLinkedWorkOrders = (workorder: any) => {
    return (
      <div className="column align-items-center">
        <DKButton
          title={workorder.documentSequenceCode}
          className="text-blue underline p-v-0"
          onClick={() =>
            setDetailsPopupData({
              documentSeqCode: workorder.documentSequenceCode,
              documentType: DOC_TYPE.WORK_ORDER,
              showDetailsOpener: true
            })
          }
        />
      </div>
    );
  };

  const contactDetailOpener = (contactId: any) => {
    let documentCode = contactId;
    setDetailsPopupData({
      documentSeqCode: documentCode,
      documentType: DOC_TYPE.Contact,
      showDetailsOpener: true
    });
  };

  const localiseColumnConfig = (tempColumnConfig: any[]) => {
    const fulfillmentColumnConfigIndex = tempColumnConfig.findIndex(
      (config: any) =>
        config.columnCode === COLUMN_CODE.INVOICE.FulfillmentStatus
    );
    if (fulfillmentColumnConfigIndex !== -1) {
      tempColumnConfig[fulfillmentColumnConfigIndex] = {
        ...tempColumnConfig[fulfillmentColumnConfigIndex],
        name: 'Delivery Status',
        options: [
          {
            id: 'FULLY_FULFILLED',
            name: 'Delivered',
            color: 'data-grid-badge-color-6'
          },
          {
            id: 'PARTIAL_FULFILLED',
            name: 'Partially Delivered',
            color: 'data-grid-badge-color-5'
          },
          {
            id: 'UNFULFILLED',
            name: 'Not Delivered',
            color: 'data-grid-badge-color-10'
          }
        ]
      };
    }
    return tempColumnConfig;
  };

  const getTootltipContent = (linkedDocsWithoutWO: any[]) => {
    return (
      <div className="column gap-1" style={{ width: 200 }}>
        <div className="row parent-width font-semibold">Linked Documents</div>
        <div className="row parent-width font-semibold justify-content-between  mr-s">
          <div className="column parent-width gap-1">
            {linkedDocsWithoutWO?.map((item: any) => {
              return (
                <div
                  className="row cursor-hand gap-1 align-items-end "
                  onClick={(e) => {
                    e.stopPropagation();
                    return;
                  }}
                >
                  <DKLabel text={item.documentSequenceCode} />
                  <DKLabel
                    className="text-gray fs-s"
                    text={`(${Utility.convertInTitleCase(
                      item.documentType.replaceAll('_', ' ')
                    )})`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getAmountBlock = (docCurrency: string, amount: number) => {
    const tenantCurrency = tenantInfo.currency;
    let exchangeRate = 1;
    if (showTotalInBaseCurrency && tenantCurrency !== docCurrency) {
      exchangeRate = activeMultiCurrencyList.find(
        (currency) => currency.currencyCode === docCurrency
      )?.currencyExchangeRate;
    }
    if (exchangeRate) {
      return Utility.getAmoutBlockForGrid(
        showTotalInBaseCurrency ? tenantCurrency : docCurrency,
        amount / exchangeRate + ''
      );
    }
  };

  const sendEInvoice = (invoiceCode: string) => {
    try {
      EInvoiceService.sendEInvoice([invoiceCode]).then(
        (res: any) => {
          showToast(
            COUNTRY_CODES.MY === tenantInfo.country
              ? 'E-Invoice Sent Successfully'
              : 'InvoiceNow has been sent successfully',
            TOAST_TYPE.SUCCESS
          );
        },
        (err) => {
          console.error('Error generating eInvoice: ', err);
        }
      );
    } catch (error: any) {}
  };

  type ChipType = 'Accepted' | 'Rejected';

  interface InvoiceNowChipProps {
    type: ChipType | string;
  }

  const chipColorMap: Record<ChipType, string> = {
    Accepted: 'data-grid-badge-color-6',
    Rejected: 'data-grid-badge-color-10'
  };

  const defaultChipColor = 'data-grid-badge-color-5';

  const InvoiceNowChip: React.FC<InvoiceNowChipProps> = ({ type }) => {
    const chipColor = chipColorMap[type as ChipType] || defaultChipColor;

    return (
      <div
        className={`row fs-m border-radius-s ${chipColor}`}
        style={{ width: 'auto', padding: '2px 6px' }}
      >
        <div
          className="fs-r text-align-left display-block"
          style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {type}
        </div>
      </div>
    );
  };

  const getRowData = (invoiceList: any[]) => {
    let rowData: any[] = [];
    invoiceList.forEach((invoice, index) => {
      if (invoice) {
        const contact = invoice.contact?.name || invoice.contactDto?.name || '';
        const subTotalAmount = invoice.totalAmount - invoice.taxAmount;
        const isSelected = currentSelectedRows.find((x) => x.id === invoice.id);
        let remarks = '';
        if (invoice.multiApprovalDetails?.approvalHistory?.length > 0) {
          const allRemarks = invoice.multiApprovalDetails.approvalHistory
            .filter((history: any) => history?.remarks)
            .map(
              (history: any) => `Level ${history.level}: ${history.remarks}`
            );
          remarks = allRemarks.join(', ');
        }
        const truncatedRemarks =
          remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks;
        let row = {
          ...invoice,
          [COLUMN_CODE.INVOICE.EInvoiceResponseStatus]:
            invoice?.einvoiceResponseStatus,
          [COLUMN_CODE.INVOICE.EInvoiceResponseReason]:
            invoice[COLUMN_CODE.INVOICE.EInvoiceResponseReason] ?? '',
          [COLUMN_CODE.INVOICE.Number]:
            !invoice.isDraftDocument &&
            typeof invoice.documentSequenceCode !== 'undefined'
              ? invoice.documentSequenceCode
              : Utility.getDraftIconBlockForGrid(invoice.draftCode),
          [COLUMN_CODE.INVOICE.InvoiceDate]: DateFormatService.getDateFromStr(
            invoice.salesInvoiceDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          [COLUMN_CODE.INVOICE.Code]: invoice.salesInvoiceCode,
          [COLUMN_CODE.INVOICE.InvoiceDueDate]:
            DateFormatService.getDateFromStr(
              invoice.salesInvoiceDueDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
          totalAmountUnformatted: invoice.totalAmount,
          [COLUMN_CODE.INVOICE.TotalAmount]: getAmountBlock(
            invoice.currency,
            invoice.totalAmount
          ),
          [COLUMN_CODE.INVOICE.PendingStatus]: invoice?.isDraftDocument
            ? '-'
            : [invoice.paymentStatus],
          [COLUMN_CODE.INVOICE.Recurring]: [
            invoice.recurring
              ? RECURRING_DOCUMENT_TYPE.RECURRING
              : RECURRING_DOCUMENT_TYPE.NON_RECURRING
          ],
          [COLUMN_CODE.INVOICE.FulfillmentStatus]: invoice?.isDraftDocument
            ? '-'
            : [invoice.fulfillmentStatus],
          [COLUMN_CODE.INVOICE.Contact]: contact,
          [COLUMN_CODE.INVOICE.DueAmount]: invoice?.isDraftDocument
            ? '-'
            : getAmountBlock(invoice.currency, invoice.dueAmount),
          [COLUMN_CODE.INVOICE.CustomerOrderNo]: invoice.customerOrderNumber,
          [COLUMN_CODE.INVOICE.Id]: invoice.id ? invoice.id : index,
          [COLUMN_CODE.INVOICE.Remarks]: truncatedRemarks,
          [COLUMN_CODE.INVOICE.ApprovalStatus]:
            APPROVAL_STATUS_LIST[invoice.approvalStatus] +
            getApprovalLevel(
              invoice,
              APPROVAL_STATUS_LIST[invoice.approvalStatus]
            ),
          [COLUMN_CODE.INVOICE.EwayBillStatus]: [invoice.ewayBillStatus],
          recurringActivated: invoice.recurringActivated,
          backOrder: invoice.backOrder,
          openingInvoice: invoice.openingInvoice,
          [COLUMN_CODE.INVOICE.COGS]: invoice.cogsAmount
            ? getAmountBlock(
                invoice.currency,
                invoice.cogsAmount * invoice?.exchangeRate
              )
            : '',
          [COLUMN_CODE.INVOICE.Profit]: invoice.cogsAmount
            ? getAmountBlock(
                invoice.currency,
                subTotalAmount - invoice.cogsAmount * invoice?.exchangeRate
              )
            : '',
          [COLUMN_CODE.INVOICE.GrossMargin]: invoice.cogsAmount
            ? Utility.roundOffToTenantDecimalScale(
                ((subTotalAmount - invoice.cogsAmount * invoice?.exchangeRate) /
                  subTotalAmount) *
                  100
              ) + '%'
            : '',
          [COLUMN_CODE.INVOICE.CreatedBy]: [invoice.createdBy],
          rowType:
            typeof invoice.documentSequenceCode === 'undefined'
              ? 'draft'
              : DOCUMENT_TYPE.INVOICE,
          allowRowEdit: true,
          rowContextMenu: getContextMenuForRow(invoice),
          rowButtons: getButtonsForRow(invoice),
          selected: isSelected ? true : false
        };

        // Check condition for Invoice Now status
        if (
          tenantInfo.country === COUNTRY_CODES.IN &&
          tenantInfo.indiaEinvoice
        ) {
          switch (getInvoiceNowStatus(invoice)) {
            case INVOICE_NOW_TYPES.FAILED:
              row = {
                ...row,
                invoiceNow: [INVOICE_NOW_TYPES.FAILED]
              };
              break;
            case INVOICE_NOW_TYPES.GENERATED:
              row = {
                ...row,
                invoiceNow: [INVOICE_NOW_TYPES.GENERATED]
              };
              break;
            case INVOICE_NOW_TYPES.PENDING:
              row = {
                ...row,
                invoiceNow: [INVOICE_NOW_TYPES.PENDING]
              };
              break;
            case INVOICE_NOW_TYPES.CANCELLED:
              row = {
                ...row,
                invoiceNow: [INVOICE_NOW_TYPES.CANCELLED]
              };
              break;
          }
        }
        // Check condition for Invoice Now status

        if (invoice.customField && invoice.customField.length > 0) {
          invoice.customField.forEach((cf: any) => {
            const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
            const colType = columnConfig.find(
              (config) => config.columnCode === columnCode
            )?.type;
            const cfInAvaialbleCFs = availableCustomFields?.content?.find(
              (field: any) => field.id === cf.id
            );
            const isUserTypeCF = cfInAvaialbleCFs
              ? cfInAvaialbleCFs.fieldType === CUSTOM_FIELD_TYPE.USER
              : false;
            let cfValue = '';
            if (isUserTypeCF) {
              const tempCF = cfInAvaialbleCFs?.attributes?.find(
                (attr: any) => attr.code === cf.value
              );
              cfValue = tempCF ? tempCF.value : '';
            } else {
              cfValue = cf ? cf.value : '';
            }
            row = {
              ...row,
              [columnCode]: colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue
            };
          });
        }
        row.ewayGenerated = invoice.ewayGenerated;
        rowData.push(row);
      }
    });
    return rowData;
  };

  const getButtonsForRow = (invoice: any) => {
    let buttons: any[] = [];
    if (
      Utility.isEmpty(invoice?.draftCode) &&
      !Utility.isEmpty(invoice?.documentSequenceCode)
    ) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.GET_PAID,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
      ) {
        buttons.push({
          title: 'Get Paid',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => receivePayment(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.FULFILL
        )
      ) {
        buttons.push({
          title: localizedText('Fulfill'),
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => fulfillment(data)
        });
      }

      if (invoice.linkedDocuments?.length) {
        const docType = invoice?.linkedDocuments[0]?.documentType;
        let title = '';
        if (docType === DOC_TYPE.QUOTE) {
          title = `Linked ${getCapitalized(localizedText('quote'))}`;
        }
        if (docType === DOC_TYPE.SALES_ORDER) {
          title = 'Linked Sales Order';
        }
        if (docType === DOC_TYPE.BILL) {
          title = 'Linked Bill';
        }
        if (docType === DOC_TYPE.ORDER) {
          title = 'Linked Order';
        }
        buttons.push({
          title: title,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            if (docType === DOC_TYPE.QUOTE) {
              loadLinkedQuote(invoice.linkedDocuments[0].documentCode);
            } else if (docType === DOC_TYPE.SALES_ORDER) {
              loadLinkedSO(invoice.linkedDocuments[0].documentCode);
            } else if (docType === DOC_TYPE.BILL) {
              loadLinkedBill(invoice.linkedDocuments[0].documentCode);
            } else {
              loadLinkedOrder(invoice.linkedDocuments[0].documentCode);
            }
          }
        });
      }
    } else {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.EDIT
        )
      ) {
        buttons.push({
          title: 'Edit',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            onInvoiceEdit(data);
          }
        });
      }
    }

    if (invoice.openingInvoice) {
      buttons = [];
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.GET_PAID,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
      ) {
        buttons.push({
          title: 'Get Paid',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => receivePayment(data)
        });
      }
    }

    return buttons;
  };

  const updateComplianceSettings = () => {
    showAlert(
      'Warning!',
      'Generate E-Way options is not enabled in organisation settings, please enable it to continue.',
      [
        {
          title: 'Cancel',
          className: 'bg-gray1 border-m',
          onClick: () => {}
        },
        {
          title: 'Open Settings',
          className: 'bg-button text-white ml-m',
          onClick: () => {
            setShowUpdateOrgPopup(true);
          }
        }
      ]
    );
  };

  const isLinkedSalesOrder = (linkedDocuments: any) => {
    return (
      linkedDocuments &&
      linkedDocuments.filter((data: any) => data.documentType === 'SALES_ORDER')
    );
  };

  const getContextMenuForRow = (invoice: any) => {
    let menuItems: any[] = [];
    if (invoice.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']) {
      if (
        Utility.isApproverButtonShow(
          invoice.documentType || DOCUMENT_TYPE.INVOICE,
          invoice,
          APPROVAL_STATUS.APPROVED
        )
      ) {
        menuItems.push({
          title: 'Approve',
          icon: DKIcons.ic_check_mark,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Approve',
              actionDetails: data
            });
            // onApproveDraft(data, APPROVAL_STATUS.APPROVED);
          }
        });
      }
      if (
        Utility.isApproverButtonShow(
          invoice.documentType || DOCUMENT_TYPE.INVOICE,
          invoice,
          APPROVAL_STATUS.REJECTED
        )
      ) {
        menuItems.push({
          title: 'Reject',
          icon: DKIcons.ic_close,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Reject',
              actionDetails: data
            });
            // onApproveDraft(data, APPROVAL_STATUS.REJECTED);
          }
        });
      }
    }
    if (Utility.isEmpty(invoice?.draftCode)) {
      if (
        (SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.EDIT
          )) ||
        tenantInfo?.additionalSettings?.INVOICE_EDIT_SETTING ||
        false
      ) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          onClick: (data: any) => {
            onInvoiceEdit(data);
          }
        });
      } else if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.VIEW,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.VIEW
        )
      ) {
        menuItems.push({
          title: 'Open',
          icon: DKIcons.ic_open,
          onClick: (data: any) => {
            onInvoiceEdit(data);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.WORK_ORDER,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        Utility.isMRP() &&
        window.location.origin?.includes(ApiConstants.URL.APP_MRP_URL || '') &&
        checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE)
      ) {
        menuItems.push({
          title: 'Create Work Order',
          icon: DKIcons.ic_document,
          onClick: async (data: any) => {
            await validateSelectedInvoiceForInterLinkedWO(invoice, () => {
              setSelectedInvoice(invoice);
              setShowAddWorkOrderPopup(true);
            });
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.FULFILL
        )
      ) {
        menuItems.push({
          title: localizedText('Fulfill'),
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => fulfillment(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.CREATE
        )
      ) {
        menuItems.push({
          title: 'Create Sales Return',
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => salesReturn(data)
        });
      }
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.GET_PAID,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
      ) {
        menuItems.push({
          title: 'Get Paid',
          icon: DKIcons.ic_getPaid,
          onClick: (data: any) => receivePayment(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.COPY,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.CREATE
        )
      ) {
        menuItems.push({
          title: 'Copy',
          icon: DKIcons.ic_copy,
          onClick: ({ rowIndex, rowData }: any) => {
            loadInvoiceForCopy(rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.VIEW,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.VIEW
        ) &&
        !Utility.isEmpty(invoice?.contactDto?.contactNumber)
      ) {
        menuItems.push({
          title: 'Whatsapp',
          icon: ic_whatsapp,
          onClick: ({ rowIndex, rowData }: any) => {
            sendDocumentLinkThroughWhatsapp(invoice);
          }
        });
      }

      if (
        !invoice.ewayGenerated &&
        tenantInfo.country === COUNTRY_CODES.IN &&
        !isTabletView()
      ) {
        if (
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.GENERATE_EWAY_BILL,
            invoice,
            DOC_TYPE.INVOICE
          )
        ) {
          menuItems.push({
            title: 'Generate E-Way Bill',
            icon: DKIcons.ic_open,
            onClick: (data: any) => {
              if (Utility.canGenerateEWayBill()) {
                const invoicesInStore: any[] = invoicesData.content;
                const invoiceClicked = invoicesInStore.find(
                  (x) =>
                    x.documentSequenceCode === data.rowData.documentSequenceCode
                );
                setBulkEWBRows([invoiceClicked]);
                setShowCreateEWayBill(true);
              } else {
                updateComplianceSettings();
              }
            }
          });
        }
      }

      if (
        invoice?.ewayGenerated &&
        invoice?.ewayBillStatus == EWAY_BILL_STATUS.GENERATED &&
        tenantInfo.country === COUNTRY_CODES.IN &&
        !isTabletView()
      ) {
        if (
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.GENERATE_EWAY_BILL,
            invoice,
            DOC_TYPE.INVOICE
          )
        ) {
          menuItems.push({
            title: 'Cancel E-Way Bill',
            icon: DKIcons.ic_close,
            onClick: (data: any) => {
              cancelEWayBill(invoice.id);
            }
          });
        }
      }
      // EInvoice context menu
      if (
        tenantInfo.country === COUNTRY_CODES.IN &&
        tenantInfo.indiaEinvoice &&
        !invoice.isDraftDocument &&
        !isTabletView()
      ) {
        if (
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.EINVOICE,
            invoice,
            DOC_TYPE.INVOICE
          )
        ) {
          switch (getInvoiceNowStatus(invoice)) {
            case INVOICE_NOW_TYPES.GENERATED:
              menuItems.push({
                title: 'Cancel e-Invoice',
                icon: DKIcons.ic_close,
                onClick: (data: any) => {
                  setIRPCredPopupType(INVOICE_NOW_TYPES.GENERATED);
                  cancelEInvoiceIndia(invoice, false);
                }
              });
              break;
            case INVOICE_NOW_TYPES.PENDING:
            case INVOICE_NOW_TYPES.FAILED:
              menuItems.push({
                title: 'Generate e-Invoice',
                icon: DKIcons.ic_document,
                onClick: (data: any) => {
                  setIRPCredPopupType(INVOICE_NOW_TYPES.PENDING);
                  generateEInvoiceIndia(invoice, false);
                }
              });
              break;
          }
        }
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.PRINT,
          invoice,
          DOC_TYPE.INVOICE
        )
      ) {
        if (checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.PRINT)) {
          menuItems.push({
            title: 'View',
            icon: DKIcons.ic_view,
            onClick: ({ rowIndex, rowData }: any) => setInvoiceForPrint(rowData)
          });
        }
        if (
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.PRINT
          )
        ) {
          menuItems.push({
            title: 'Print',
            icon: DKIcons.ic_printer,
            onClick: ({ rowIndex, rowData }: any) => {
              if (!isTabletView()) {
                setInvoiceForPrint(rowData);
              } else {
                Utility.postMobileAppActions(
                  MOBILE_APP_ACTIONS.MOBILE_PRINT +
                    ':' +
                    JSON.stringify(rowData)
                );
              }
            }
          });
        }
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EMAIL,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.EMAIL
        )
      ) {
        menuItems.push({
          title: 'Email',
          icon: DKIcons.ic_email,
          onClick: ({ rowIndex, rowData }: any) => {
            setInvoiceForEmail(rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.DESIGN,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.DESIGN
        )
      ) {
        menuItems.push({
          title: 'Design',
          icon: DKIcons.ic_paint,
          onClick: (data: any) => {
            openDesigner(data.rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.ATTACHMENT,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
      ) {
        menuItems.push({
          title: 'Attachment',
          icon: DKIcons.ic_attachment,
          onClick: (data: any) => onAttachmentCLicked(data)
        });
      }

      if (
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.LABEL_PRINT
        ) &&
        tenantInfo?.additionalSettings?.LABEL_PRINT === true
      ) {
        menuItems.push({
          title: 'Generate Label',
          icon: DKIcons.ic_barcode,
          onClick: ({ rowIndex, rowData }: any) =>
            loadInvoiceDetailsForEdit(rowData, POPUP_CLICK_TYPE.GENERATE_LABEL)
        });
      }

      if (invoice.paymentStatus !== PAYMENT_STATUS.PENDING) {
        if (
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.PAYMENT_RECORD,
            invoice,
            DOC_TYPE.QUOTE
          )
        ) {
          menuItems.push({
            title: 'Payment Records ',
            icon: DKIcons.ic_paymentRecord,
            onClick: (data: any) => getPaymentRecords(data)
          });
        }
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL_RECORD,
          invoice,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.FULFILL
        )
      ) {
        menuItems.push({
          title: localizedText('Fulfillment Records'),
          icon: DKIcons.ic_document,
          onClick: (data: any) => getFulfillmentRecords(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN_RECORDS,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.VIEW
        )
      ) {
        menuItems.push({
          title: 'Sales Returns Records',
          icon: DKIcons.ic_document,
          onClick: (data: any) => getSalesReturnRecords(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.OFFSET_PAYMENT,
          invoice,
          DOC_TYPE.INVOICE
        )
      ) {
        menuItems.push({
          title: 'Offset Payment',
          icon: DKIcons.ic_cards,
          onClick: (data: any) => offSetPayment(data)
        });
      }
      if (
        Utility.isApprovalColumn(DOC_TYPE.INVOICE) &&
        invoice.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
      ) {
        menuItems.push({
          title: 'Approval/Rejection History',
          icon: DKIcons.ic_document,
          className: 'p-v-0',
          onClick: (data: any) => {
            getApprovalHistory(data, false);
          }
        });
      }

      if (
        isSGAndPeppolOptInDocument(invoice?.contactDto) ||
        isMYAndPeppolOptInDocument(invoice?.contactDto)
      ) {
        if (
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.SEND_INVOICE_NOW,
            invoice,
            DOC_TYPE.INVOICE
          )
        ) {
          menuItems.push({
            title:
              COUNTRY_CODES.MY === tenantInfo?.country
                ? 'Send E-Invoice'
                : 'Send InvoiceNow',
            icon: ic_form,
            className: 'p-v-0',
            onClick: (data: any) => {
              sendEInvoice(invoice?.salesInvoiceCode);
            }
          });
        }
      }
    } else {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.EDIT
        )
      ) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          className: 'p-v-0',
          onClick: (data: any) => {
            onInvoiceEdit(data);
          }
        });
      }
      if (
        Utility.isApprovalColumn(DOC_TYPE.INVOICE) &&
        invoice.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
      ) {
        menuItems.push({
          title: 'Approval/Rejection History',
          icon: DKIcons.ic_document,
          className: 'p-v-0',
          onClick: (data: any) => {
            getApprovalHistory(data, true);
          }
        });
      }
    }
    if (!Utility.isEmpty(invoice.documentSequenceCode)) {
      menuItems.push({
        title: `${getIsReadOnly(invoice) ? 'View' : 'Add'} payment milestones`,
        icon: ic_milestone,
        className: 'p-v-0',
        onClick: (data: any) => {
          setEditableInvoice(invoice);
          setShowPaymentMilestonePopup(true);
        }
      });
    }

    if (!Utility.isEmpty(invoice.documentSequenceCode)) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RECURRING,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.SET_RECURRING
        )
      ) {
        menuItems.push({
          title: 'Set Recurring',
          icon: DKIcons.ic_reload,
          onClick: (data: any) => {
            recurringDocument(data);
          }
        });
      }
    }

    if (
      typeof invoice.documentSequenceCode !== 'undefined' &&
      invoice.recurring
    ) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RECURRING,
          invoice,
          DOC_TYPE.INVOICE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.SET_RECURRING
        )
      ) {
        menuItems.push({
          title: 'Deactivate Recurring',
          icon: DKIcons.ic_reload,
          onClick: (data: any) => {
            deactivateRecurringDocument(data);
          }
        });
      }
    }
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.LINKED_RECORDS,
        invoice,
        DOC_TYPE.INVOICE
      )
    ) {
      menuItems.push({
        title: 'Linked Records',
        icon: DKIcons.ic_document,
        className: 'p-v-0',
        onClick: (data: any) => {
          onLinkedRecords(data);
        }
      });
    }
    const isLinkedSalesOrderCheck = isLinkedSalesOrder(invoice.linkedDocuments);
    if (
      tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_SALES_INVOICE &&
      !isLinkedSalesOrderCheck
    ) {
      menuItems.push({
        title: 'Rate Analysis',
        icon: DKIcons.ic_document,
        onClick: ({ rowIndex, rowData }: any) => {
          getRateAnalysis(rowData);
        }
      });
    }
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.DELETE,
        invoice,
        DOC_TYPE.INVOICE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.DELETE
      )
    ) {
      menuItems.push({
        title: 'Delete',
        icon: DKIcons.ic_delete,
        onClick: async (data: any) => {
          const rowData = [data.rowData];
          if (!Utility.isEmpty(rowData)) {
            const isConfirmed = await confirm(
              t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`)
            );

            if (isConfirmed) {
              if (rowData[0].ewayGenerated) {
                showAlert(
                  'Error',
                  `E-Way Bill is associated with this invoice. Invoice cannot be deleted`
                );
                return;
              }

              if (
                ((rowData[0].paymentStatus[0] === PAYMENT_STATUS.PENDING ||
                  (rowData[0].paymentStatus[0] === PAYMENT_STATUS.RECEIVED &&
                    invoice.totalAmount === 0)) &&
                  rowData[0].fulfillmentStatus[0] ===
                    FULFILLMENT_STATUS.UNFULFILLED) ||
                rowData[0]?.isDraftDocument ||
                rowData[0]?.draft
              ) {
                onInvoiceDelete(rowData);
              } else {
                showAlert(
                  '',
                  t(
                    `Invoice <b>${
                      invoice?.documentSequenceCode
                        ? invoice?.documentSequenceCode
                        : ''
                    }</b> is already in use, so cannot be deleted.`
                  )
                );
              }
            }
          }
        }
      });
    }

    // Only for handling Opening Invoices
    if (invoice.openingInvoice) {
      menuItems = [];
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.GET_PAID,
          invoice,
          DOC_TYPE.INVOICE
        )
      ) {
        menuItems.push({
          title: 'Get Paid',
          icon: DKIcons.ic_getPaid,
          onClick: (data: any) => receivePayment(data)
        });
      }
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.PAYMENT_RECORD,
          invoice,
          DOC_TYPE.INVOICE
        )
      ) {
        menuItems.push({
          title: 'Payment Records',
          icon: DKIcons.ic_paymentRecord,
          onClick: (data: any) => getPaymentRecords(data)
        });
      }
    }

    // Handle MultiView Journal Entry
    if (!invoice?.isDraftDocument) {
      menuItems = enableMultiViewJournalEntry(
        menuItems,
        getMultiViewJournalEntry
      );
    }
    return menuItems;
  };

  // Send document link through Whatsapp
  const sendDocumentLinkThroughWhatsapp = (rowData: any) => {
    const payload: any = {
      category: DOC_TYPE.INVOICE,
      contactCode: rowData?.contactCode,
      documentCode: rowData?.salesInvoiceCode,
      documentType: PrintService.getTemplateNameType(
        DOC_TYPE.INVOICE.toString().toLowerCase()
      ),
      event: TemplateMappingEvent.PRINT,
      parentDocumentType: null,
      parentDocumentCode: null
    };
    PrintService.getTemplatePrintInfo(payload).then(
      (data: any) => {
        const code = data?.code;
        if (Utility.isEmpty(code)) {
          showAlert('Error!', 'Error creating document link');
          return;
        }
        let link = `${process.env.REACT_APP_DOC_DESIGNER_URL}/?pid=${code}`;
        if (
          data.defaultTemplateMapping &&
          data.defaultTemplateMapping.templateId
        ) {
          link = `${link}&tid=${data.defaultTemplateMapping.templateId}`;
        }
        const sanitizedNumber = Utility.sanitizePhoneForWhatsapp(
          rowData.contactDto?.contactNumber
        );
        const messageToSend = WhatsAppHelper.getMessageForSalesDocument(
          'Invoice',
          rowData,
          link,
          tenantInfo?.name
        );
        const urlToOpen =
          ApiConstants.URL.WHATSAPP.WHATSAPP_URL(sanitizedNumber) +
          `?text=${encodeURIComponent(messageToSend)}`;
        window.open(urlToOpen, '_blank');
      },
      (err: any) => {
        showAlert('Error!', 'Error fetching document link');
      }
    );
  };

  // eInvoice actions
  const generateEInvoiceIndia = (invoice: any, bulkGenerate?: boolean) => {
    if (bulkGenerate ? invoice.length : invoice && tenantInfo) {
      const generateEInvoice = (authToken: any) => {
        let payload = { ...authToken };
        payload.gstin = tenantInfo.gstin;

        if (bulkGenerate) {
          let invoiceIds = invoice.map((data: any) => data.id);
          payload.ids = invoiceIds;
          try {
            EInvoiceService.bulkGenerateEInvoice(payload).then(
              (res: any) => {
                const config: InvoiceAPIConfig = {
                  ...InvoiceService.apiConfig
                };

                showAlert(
                  'eInvoice portal reported as below',
                  `${
                    res && res?.success?.length ? res?.success?.length : `0`
                  } generated. ${
                    res && res?.failure?.length ? res?.failure?.length : `0`
                  } failed`
                );

                loadInvoices(config);
              },
              (err) => {
                console.error('Error generating eInvoice: ', err);
              }
            );
          } catch (error: any) {}
        } else {
          payload.id = invoice.id;
          try {
            EInvoiceService.generateEInvoice(payload).then(
              (res: any) => {
                const config: InvoiceAPIConfig = {
                  ...InvoiceService.apiConfig
                };

                if (!Utility.isEmpty(res?.errorDetails)) {
                  let errors = res?.errorDetails;
                  if (errors?.length) {
                    let HTML = `<div>
                      <ul>
                        ${errors.map((error: any) => {
                          return `<li>${error?.ErrorMessage}</li>`;
                        })}
                      </ul>
                    </div>`;
                    showAlert('e-Invoice portal reported errors below', HTML);
                  }
                }

                loadInvoices(config);
              },
              (err) => {
                console.error('Error generating e-Invoice: ', err);
              }
            );
          } catch (error: any) {}
        }
      };

      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (
            Utility.isEmpty(authToken) ||
            authToken?.payload?.status !== '1'
          ) {
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
            setEditableInvoice(invoice);
            setShowIRPCredPopup(true);
          } else {
            generateEInvoice(authToken?.payload);
          }
        });
      } else {
        // disable token expiration check
        // let currentTime = new Date();
        // let currentIndiaDate = new Date(
        //   currentTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
        // );
        // let tokenExpiry: any = eInvoiceAuthInfoData?.tokenExp;

        // if (tokenExpiry) {
        //   let tokenExpiryDate = new Date(tokenExpiry);

        //   if (currentIndiaDate < tokenExpiryDate) {
        //     generateEInvoice(eInvoiceAuthInfoData);
        //   } else {
        //     dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
        //       if (Utility.isEmpty(eInvoiceAuthInfoData)) {
        //         setEditableInvoice(invoice);
        //         setShowIRPCredPopup(true);
        //       } else {
        //         generateEInvoice(authToken.payload);
        //       }
        //     });
        //   }
        // }

        generateEInvoice(eInvoiceAuthInfoData);
      }
    }
  };

  const cancelEInvoiceIndia = (invoice: any, bulkCancel?: boolean) => {
    if (bulkCancel ? invoice.length : invoice && tenantInfo) {
      setEditableInvoice(invoice);
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
          } else {
            setShowCancelEInvoice(true);
          }
        });
      } else {
        setShowCancelEInvoice(true);
      }
    }
  };

  const cancelEWayBill = async (id: any) => {
    try {
      setUpdating(true);
      const response = await InvoiceService.cancelEwayBill(id);
      if (Utility.isEmpty(response) || response === false) {
        showAlert('Error', 'Unable to cancel E-Way Bill');
      } else {
        if (typeof response === 'string') {
          showAlert('Success', response);
        }
        if (typeof response === 'object') {
          const errorLogs = response.map((log: any) => {
            return {
              errorCode: log?.ErrorCode,
              errorMessage: log?.ErrorMessage
            };
          });
          setCancelEwayBillErrorLogs({
            show: true,
            logs: errorLogs
          });
        }
      }
    } finally {
      loadInvoices({});
    }
  };

  /**
   * Load Invoice Filter Data
   */

  const getInitialFilterData = () => {
    if (Utility.isObject(invoiceFilterData?.config)) {
      if (!Utility.isEmpty(invoiceFilterData.SearchTerm)) {
        // Set search value in datagrid
        setSearchTerm(invoiceFilterData.SearchTerm);
      }

      if (invoiceFilterData.queries.length) {
        setFilters(
          invoiceFilterData?.queries.length
            ? deepClone(invoiceFilterData?.queries)
            : []
        );
      }

      loadInvoices(invoiceFilterData.config);
    } else {
      loadInvoices({});
    }
  };

  const setFilterDataInStore = (
    config: any,
    SearchTerm?: any,
    queries?: any,
    filterDates?: any
  ) => {
    dispatch(
      invoiceFilter({
        config,
        SearchTerm,
        queries,
        filterDates
      })
    );
  };

  // eInvoice actions

  const loadInvoices = async (config: InvoiceAPIConfig) => {
    try {
      setUpdating(true);
      if (Utility.isNotEmpty(config)) {
        if (props.listByContact && !config?.Query?.includes('contactCode')) {
          config = deepClone(config);
          config.Query += `${config.Query ? ',' : ''}contactCode=${
            props?.contact?.code
          }`;
        }
        InvoiceService.apiConfig = config;
      }

      await dispatch(fetchInvoices());
      setUpdating(false);
    } catch (err) {
      console.error('Error loading Invoice: ', err);
      setUpdating(false);
    }
  };

  useEffect(() => {
    loadSummaryData({});
  }, [invoicesData]);

  const loadSummaryData = async (config: InvoiceAPIConfig) => {
    try {
      setUpdating(true);
      if (Utility.isNotEmpty(config)) {
        InvoiceService.apiConfig = config;
      }
      if (Utility.isEmpty(InvoiceService.apiConfig?.QuerySummary)) {
        return;
      }
      await dispatch(fetchInvoiceSummary());
      setUpdating(false);
    } catch (err) {
      console.error('Error loading Invoice Summary : ', err);
      setUpdating(false);
    }
  };

  const onColumnUpdated = async (data: any) => {
    const { columnData } = data;
    const columnCode = columnData?.columnCode as string;
    let columnIndex = columnConfig.findIndex(
      (column) => column.columnCode === columnCode
    );
    if (typeof columnData.id !== 'undefined') {
      const isCustomField = columnCode.startsWith('custom_');
      if (isCustomField && columnData.name !== columnConfig[columnIndex].name) {
        try {
          const customFieldId = columnCode.split('_')[2];
          if (customFieldId) {
            const customFieldResponse =
              await CustomFieldService.getCustomFieldById(+customFieldId);
            if (customFieldResponse) {
              let customField = customFieldResponse;
              customField = {
                ...customField,
                label: columnData.name
              };

              const updatedCustomField =
                await CustomFieldService.updateCustomField(
                  +customFieldId,
                  customField,
                  columnConfigTableId
                );
              if (updatedCustomField) {
                dispatch(
                  addInvoiceColumnConfig({
                    tableId: columnConfigTableId as string,
                    config: updatedCustomField.newColumnConfig
                  })
                );
              }
            }
          } else {
            console.error('Error updating custom field');
          }
        } catch (err) {
          console.error('Error fetching custom field: ', err);
        }
      } else {
        updateColumnInfoAndStore(columnIndex, columnData);
      }
    }
  };

  const updateColumnInfoAndStore = (columnIndex: number, columnData: any) => {
    updateColumnInfo(columnConfigTableId as string, columnData);
    const currentColumnsConfig = [...columnConfig];
    currentColumnsConfig[columnIndex] = columnData;
    dispatch(updateColumnConfig(currentColumnsConfig));
  };

  const onColumnShifted = (data: IColumn[]) => {
    const updatedConfigArray = data.filter((x) => typeof x !== 'undefined');
    const columnIdArray = updatedConfigArray.map(
      (config) => config.id
    ) as string[];
    updateColumnShift(columnConfigTableId as string, columnIdArray);
    dispatch(updateColumnConfig(updatedConfigArray));
  };

  const dataHasReservedDocuments = (selectedDate: any[]) => {
    return selectedDate.some(
      (obj) => !obj.isDraftDocument && obj.reservedStock
    );
  };

  const handleBulkDelete = async (selectedData: any[], draftIdsList: any[]) => {
    setUpdating(true);
    const drafts = selectedData.filter((data: any) => data.isDraftDocument);
    const invoices = selectedData.filter((data: any) => !data.isDraftDocument);
    if (drafts.length) {
      const draftIds = drafts.map((draft) => draft.id);
      try {
        await dispatch(
          bulkDeleteDrafts({
            recordIds: draftIds,
            tableId: draftsTableId
          })
        );
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
      } catch (err) {
        console.error('Error deleting draft invoices: ', err);
      }
    }
    if (invoices.length) {
      const filteredInvoices: any = invoices.filter(
        (invoice) => !invoice.ewayGenerated
      );
      if (invoices.length !== filteredInvoices.length) {
        showAlert(
          'Error',
          `E-Way Bill is associated with some invoices. Invoices cannot be deleted`
        );
      }
      try {
        const bulkDeleteResponse = await InvoiceService.bulkDelete(
          filteredInvoices.map((invoice: any) => invoice.id)
        );
        if (bulkDeleteResponse.length) {
          if (bulkDeleteResponse.some((resp) => resp.httpStatus !== 'OK')) {
            const documentNumbers = bulkDeleteResponse
              .filter((resp) => resp.httpStatus !== 'OK')
              .map((resp) => `<li>${resp.documentSequenceCode}</li>`);

            if (!Utility.isEmpty(draftIdsList)) {
              const documentNumbers = bulkDeleteResponse
                .filter((resp) => resp.httpStatus !== 'OK')
                .map((resp) => resp.documentSequenceCode);

              const finalDraftIds = draftIdsList.filter((draft: any) => {
                return !documentNumbers.includes(draft.cells.documentCode);
              });

              if (!Utility.isEmpty(finalDraftIds)) {
                deleteAutomationDocDrafts(finalDraftIds);
              }
            }

            showAlert(
              'Bulk Delete',
              `Following documents are already in use, So cannot be deleted:<ul>${documentNumbers.join(
                ''
              )}</ul>`
            );
          }
          loadInvoices({});
        }
      } catch (err) {
        showAlert('Error!', 'Error while bulk deleting invoices');
        loadInvoices({});
      }
    }
    setUpdating(false);
  };

  const deleteAutomationDocDrafts = async (draftIds: any) => {
    if (!Utility.isEmpty(draftIds)) {
      const ids = draftIds.map((id: any) => id._id);
      await dispatch(
        bulkDeleteDrafts({
          recordIds: ids,
          tableId: draftsTableId
        })
      );
    }
  };

  const onInvoiceDelete = async (data: any[]) => {
    if (dataHasReservedDocuments(data)) {
      showAlert('', "You can't delete document(s) having reserved quantity");
      return;
    }
    let draftIds: any = [];

    if (!Utility.isEmpty(invoiceDraftData)) {
      draftIds = getAutomationDocDraftIds(data, invoiceDraftData);
    }
    if (data?.length > 1) {
      handleBulkDelete(data, draftIds);
      return;
    }
    const rowData = data[0];
    if (!Utility.isEmpty(rowData)) {
      setUpdating(true);

      if (rowData.isDraftDocument) {
        try {
          await dispatch(
            deleteDrafts({
              recordId: rowData.id,
              tableId: draftsTableId
            })
          );
          if (
            tenantInfo.additionalSettings?.RATE_ANALYSIS
              ?.ENABLE_FOR_SALES_INVOICE
          ) {
            RateAnalysisService.delteDraftRateAnalysis(rowData.id);
          }
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.INVOICES
            })
          );
          setUpdating(false);
        } catch (err: any) {
          console.error('Error deleting draft or fetching list: ', err);
          setUpdating(false);
        }
      } else {
        const invoiceId: number = rowData.id;
        const documentSequenceCode = rowData.documentSequenceCode;
        if (rowData.ewayGenerated) {
          showAlert(
            'Error',
            `E-Way Bill is associated with this invoice. Invoice cannot be deleted`
          );
          setUpdating(false);
          return;
        }
        InvoiceService.deleteInvoice(invoiceId).then(
          (res) => {
            if (
              APPROVAL_STATUS.NOT_REQUIRED !== rowData.approvalStatus &&
              'Not Required' !== rowData.approvalStatus
            ) {
              if (!Utility.isEmpty(draftIds)) {
                deleteAutomationDocDrafts(draftIds);
              }
            }
            loadInvoices({});
          },
          (err: any) => {
            setUpdating(false);
            if (
              err?.data?.code === 406 &&
              err?.data?.httpStatus === 'NOT_ACCEPTABLE'
            ) {
              showAlert(
                '',
                `Invoice <b>${
                  documentSequenceCode ? documentSequenceCode : ''
                }</b> is already in use, so cannot be deleted.`
              );
            } else {
              showAlert('Error!', 'Error deleting invoice');
              console.error('Error deleting invoice: ', err.data);
            }
          }
        );
      }
    }
  };

  const loadInvoiceForCopy = (invoiceDetails: any) => {
    const invoicesInStore: any[] = invoicesData.content;
    const invoiceClicked = invoicesInStore.find(
      (x) => x.documentSequenceCode === invoiceDetails.documentSequenceCode
    );
    showLoader('Loading...');
    InvoiceService.getInvoiceByCode(invoiceClicked.salesInvoiceCode).then(
      (data: any) => {
        removeLoader();
        const isINDTaxSystem = getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST;
        let invoiceDetailsData: any = {
          ...data,
          id: null,
          einvoiceInfoIndia: null,
          einvoiceInfoIndiaCancel: null,
          ewayBillInfoIndia: null,
          ewayGenerated: false,
          isCancelEinvoice: false,
          documentSequenceCode: null,
          isPartialInvoice: false,
          duplicate: true,
          paymentStatus: PAYMENT_STATUS.PENDING,
          fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
          fulfillmentType: FULFILLMENT_TYPE.NONE,
          approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
          multiApprovalDetails: null,
          reservedStock: null,
          reservedQuantitiesData: null,
          salesInvoiceItems: data.salesInvoiceItems.map((item: any) => {
            return {
              ...item,
              id: null,
              documentSequenceCode: item?.product?.documentSequenceCode,
              documentItemCode: null,
              isPartialInvoice: false,
              linkedQuoteItem: null,
              quantityFulfilled: 0,
              qtyConvertedToDropShip: 0,
              qtyFulfilledFromToDropShip: 0,
              qtyFulfilledFromPps: 0,
              qtyConvertedToPps: 0,
              reservedQuantitiesData: null,
              igstAmount: isINDTaxSystem ? item.igstAmount : 0,
              igstRate: isINDTaxSystem ? item.igstRate : 0,
              cgstAmount: isINDTaxSystem ? item.cgstAmount : 0,
              cgstRate: isINDTaxSystem ? item.cgstRate : 0,
              sgstAmount: isINDTaxSystem ? item.sgstAmount : 0,
              sgstRate: isINDTaxSystem ? item.sgstRate : 0,
              cessAmount: isINDTaxSystem ? item.cessAmount : 0,
              cessPercentage: isINDTaxSystem ? item.cessPercentage : 0,
              nonEditableColumns:
                item.type === PRODUCT_TYPE.NON_TRACKED &&
                item.product?.stockUom === UOM_NA_ID
                  ? ['productQuantity']
                  : []
            };
          }),
          linkedDocuments: null,
          knockoffInfo: null,
          tdsInfo: null,
          entityId: data.id,
          recurring: false,
          recurringActivated: false,
          backOrder: false,
          status: DOCUMENT_STATUS.OPEN,
          amountToReceiveOrPay: data.totalAmount || 0,
          paymentMilestoneFlag: false,
          paymentType: 'BANK_TRANSFER'
        };

        if (!Utility.isEmpty(invoiceDetailsData)) {
          let payloadData: any = {
            type: LABELS.INVOICES,
            title: LABELS.INVOICES,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: invoiceDetailsData,
            isSaved: true,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          };

          invoiceDetailsData.salesInvoiceItems =
            invoiceDetailsData.salesInvoiceItems.filter(
              (item: any) => item?.product?.active
            );

          dispatch(
            createBlankDraft({ payloadData, draftType: DraftTypes.COPY })
          );
        }
      },
      (err) => {
        removeLoader();
        console.error('Error loading detailed invoice: ', err);
      }
    );
  };

  const openDesigner = (rowData: any) => {
    const docsInStore: any[] = invoicesData.content;
    const docId = rowData.id;
    const data = docsInStore.filter((doc) => doc.id === docId)[0];
    if (data) {
      let docCode = data.salesInvoiceCode;
      let docType = DOC_TYPE.INVOICE;
      showLoader();
      PrintService.getDocumentPrintUUID(docCode, docType)
        .then(
          (response: any) => {
            removeLoader();
            const printExternalAppUrl = [
              ApiConstants.PRODUCT_URL_DOCUMENT_BUILDER,
              response.code
            ].join('/');
            window.open(printExternalAppUrl, '_blank');
          },
          (err: any) => {
            removeLoader();
            console.error('Failed to load the designer code', err);
          }
        )
        .catch((err: any) => {
          removeLoader();
          console.error('Failed to load the designer code', err);
        });
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(footerAction)) {
      let data: any;
      switch (footerAction.action) {
        case ActionMenuEvent.OFFSET_PAYMENT:
          data = {
            rowData: footerAction.document
          };
          offSetPayment(data);
          break;
        case ActionMenuEvent.SET_RECURRING:
          data = {
            rowData: footerAction.document
          };
          recurringDocument(data);
          break;
        case ActionMenuEvent.FULLFILLMENT_RECORDS:
          data = {
            rowData: footerAction.document
          };
          getFulfillmentRecords(data);
          break;
        case ActionMenuEvent.PAYMENT_RECORDS:
          data = {
            rowData: footerAction.document
          };
          getPaymentRecords(data);
          break;
        default:
          break;
      }
      dispatch(setFooterAction(null));
    }
  }, [footerAction]);

  const setInvoiceForPrint = (rowData: any) => {
    const invoicesInStore: any[] = invoicesData.content;
    const invoiceClicked = invoicesInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (invoiceClicked?.salesInvoiceCode) {
      getPrintPreview(TEMPLATE_CATEGORY.INVOICE, {
        ...invoiceClicked,
        contactCode: invoiceClicked.contactCode,
        documentCode: invoiceClicked.salesInvoiceCode,
        documentType: DOC_TYPE.INVOICE
      });
    }
  };

  const setBulkInvoiceForPrint = (selectedRows: any[]) => {
    let list = selectedRows.map((row: any) => row.salesInvoiceCode);
    if (list && list.length > 0) {
      FulfillmentService.getInvoiceBulkFulfillmentDetails(list).then(
        (data: any) => {
          let invoiceList: any[] = data;
          if (
            invoiceList &&
            invoiceList.length > 1 &&
            selectedRows.length > 1
          ) {
            getBulkPrintPreview(
              TEMPLATE_CATEGORY.INVOICE,
              data.map((row: any) => row.salesInvoiceCode) || []
            );
          }
        }
      );
    }
  };

  const onBulkPrintlick = (data: any) => {
    setBulkInvoiceForPrint(data);
  };

  const onEmailCallBack = (data: any, id: any) => {
    setEmailStatusIds(id, DOC_TYPE.INVOICE);
    setTimeout(() => {
      // Code to execute after 5 seconds
      loadInvoices({});
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const setInvoiceForEmail = (rowData: any) => {
    const invoicesInStore: any[] = invoicesData.content;
    const invoiceClicked = invoicesInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (invoiceClicked?.salesInvoiceCode) {
      getEmailPopup(
        TEMPLATE_CATEGORY.INVOICE,
        {
          ...invoiceClicked,
          contactCode: invoiceClicked.contactCode,
          documentCode: invoiceClicked.salesInvoiceCode,
          documentType: DOC_TYPE.INVOICE
        },
        invoiceEmailTemplateConfig,
        (data: any) => onEmailCallBack(data, invoiceClicked.salesInvoiceCode)
      );
    }
  };

  const loadInvoiceDetailsForEdit = (
    invoiceDetails: any,
    popupActionType: POPUP_CLICK_TYPE
  ) => {
    const invoiceCode: string = invoiceDetails.salesInvoiceCode;
    showLoader('Loading...');
    InvoiceService.getInvoiceByCode(invoiceCode).then(
      (data: any) => {
        removeLoader();
        let invoiceDetailsData: any = getUpdatedInvoiceObject(data);

        setEditableInvoiceInvoiceForDropship(invoiceDetailsData);
        setEditableInvoice(invoiceDetailsData);
        const displayDropShipConfirmation =
          Utility.needDropshipPopup(invoiceDetailsData);

        switch (popupActionType) {
          case POPUP_CLICK_TYPE.RECEIVE_PAYMENT:
            setShowPaymentPopup(true);
            break;
          case POPUP_CLICK_TYPE.FULFILLMENT:
            const isAllProductActive = checkIfAllProductsActiveInDoc(
              invoiceDetailsData,
              DOC_TYPE.INVOICE
            );
            if (isAllProductActive) {
              if (displayDropShipConfirmation) {
                setShowDropshipConfirmationPopup(true);
              } else {
                setShowFulfillmentConfirmationPopup(true);
                setShowFulfillmentPopup(false);
              }
            } else {
              showAlert(
                'Unable to fulfill!',
                'Invoice has inactive products, please remove them and try again'
              );
            }
            break;
          case POPUP_CLICK_TYPE.UPDATE_INVOICE:
            if (!Utility.isEmpty(data)) {
              let payloadData: any = {
                id: data.id,
                type: LABELS.INVOICES,
                title: LABELS.INVOICES,
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: data,
                isSaved: true,
                draftsTableId,
                draftsColumnConfig
              };

              dispatch(
                createBlankDraft({ payloadData, draftType: DraftTypes.UPDATE })
              );
            }
            break;
          case POPUP_CLICK_TYPE.VIEW_DOCUMENT:
            if (!Utility.isEmpty(data)) {
              let payloadData: any = {
                id: data.id,
                type: LABELS.INVOICES,
                title: LABELS.INVOICES,
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: data,
                isSaved: true,
                draftsTableId,
                draftsColumnConfig
              };

              dispatch(
                createBlankDraft({
                  payloadData,
                  draftType: DraftTypes.READONLY
                })
              );
            }
            break;
          case POPUP_CLICK_TYPE.SET_RECURRING_DOCUMENT:
            setShowRecurringPopup(true);
            break;
          case POPUP_CLICK_TYPE.OPEN_ATTACHMENT:
            setShowAttachmentPopup(true);
            break;
          case POPUP_CLICK_TYPE.SALES_RETURN:
            setShowSalesReturnPopup(true);
            break;
          case POPUP_CLICK_TYPE.GENERATE_LABEL:
            setGenerateLabelPopup(true);
            break;
          default:
            break;
        }
      },
      (err) => {
        console.error('Error while fetching invoice details: ', err);
        removeLoader();
      }
    );
  };

  const loadLocalInvoiceDetailsForEdit = (
    invoiceDetails: any,
    popupActionType: POPUP_CLICK_TYPE = POPUP_CLICK_TYPE.FULFILLMENT
  ) => {
    let invoiceDetailsData: any = getUpdatedInvoiceObject(invoiceDetails);
    setEditableInvoice(invoiceDetailsData);
    setEditableInvoiceInvoiceForDropship(invoiceDetailsData);
    const displayDropshipConfirmation =
      Utility.needDropshipPopup(invoiceDetailsData);

    switch (popupActionType) {
      case POPUP_CLICK_TYPE.FULFILLMENT:
        if (displayDropshipConfirmation) {
          setShowDropshipConfirmationPopup(true);
        } else {
          setShowFulfillmentConfirmationPopup(true);
          setShowFulfillmentPopup(false);
        }
        break;
      case POPUP_CLICK_TYPE.RECEIVE_PAYMENT:
        setShowPaymentPopup(true);
        break;
      case POPUP_CLICK_TYPE.SALES_RETURN:
        setShowSalesReturnPopup(true);
        break;
      default:
        break;
    }
  };

  const loadInvoiceFulfillmentRecords = (
    invoiceDetails: any,
    popupActionType: POPUP_CLICK_TYPE
  ) => {
    const invoiceCode: string = invoiceDetails.salesInvoiceCode;
    const invoiceDetailsData: any = invoiceDetails;
    setEditableInvoice(invoiceDetailsData);
    const salesInvoiceCode = invoiceDetailsData?.documentSequenceCode;
    let salesInvoiceCodeFilters = [
      `documentSequenceCode=${invoiceDetailsData?.documentSequenceCode}`
    ];
    if (Utility.isNotEmpty(invoiceDetailsData?.linkedDocuments)) {
      salesInvoiceCodeFilters.push(
        ...(invoiceDetailsData?.linkedDocuments
          ?.filter(
            (doc: any) =>
              Utility.isNotEmpty(doc?.documentSequenceCode) &&
              [DOCUMENT_TYPE.SALES_ORDER, DOCUMENT_TYPE.QUOTATION].includes(
                doc?.documentType ?? ''
              )
          )
          ?.map(
            (doc: any) => `documentSequenceCode=${doc?.documentSequenceCode}`
          ) ?? [])
      );
    }
    const salesInvoiceCodeQuery = salesInvoiceCodeFilters?.join(',');
    setFulfillmentDetails([]);
    setShowFulfillmentRecordsPopup(false);
    FulfillmentService.getFulfillmentRecords(
      invoiceCode,
      DOC_TYPE.INVOICE
    ).then((data1: any) => {
      if (
        Utility.isNotEmpty(salesInvoiceCode) &&
        (GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PPS.READ
        ) ||
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.PPS.CREATE
          ))
      ) {
        FulfillmentService.getLinkedPPSFulfillmentRecords(
          salesInvoiceCodeQuery
        ).then((data2: any) => {
          setFulfillmentDetails((prev: any) => {
            if (Utility.isEmpty(data1) && Utility.isEmpty(data2?.content)) {
              showAlert('Error', 'Fulfillment record not found');
              return;
            }
            if (
              Utility.isNotEmpty(data1) &&
              Utility.isNotEmpty(data2?.content)
            ) {
              setFulfillmentType('Fulfillment Records');
            } else if (Utility.isNotEmpty(data1)) {
              setFulfillmentType('Fulfillment Records');
            } else {
              setFulfillmentType('PPS Records');
            }
            const mappedRecords = data2?.content?.map((row: any) => {
              return {
                ...row,
                category: 'PPS Fulfillment',
                fulfillment_code: row?.documentSequenceCode
              };
            });
            setShowFulfillmentRecordsPopup(true);
            return [
              ...(prev ?? []),
              ...(data1 ?? []),
              ...(mappedRecords ?? [])
            ];
          });
        });
      } else {
        if (Utility.isEmpty(data1)) {
          showAlert('Error', 'Fulfillment record not found');
          return;
        }
        setFulfillmentType('Fulfillment Records');
        setFulfillmentDetails(data1);
        setShowFulfillmentRecordsPopup(true);
      }
    });
  };

  // Check if minimized doc exists and maximize it
  const minimizedDocExists = (documentCode: string, isDraft: boolean) => {
    const minimizedDocInfo = getMinimizedDocInfo(
      documentCode,
      isDraft,
      DOC_TYPE.INVOICE
    );

    if (minimizedDocInfo.isMinimized) {
      let payloadData: any = {
        id: isDraft ? documentCode : minimizedDocInfo?.activeFormData?.id,
        isCenterAlign: true
      };
      dispatch(setDraftCenter(payloadData));
      dispatch(
        setPopulateFormData({
          id: documentCode,
          formdata: minimizedDocInfo.activeFormData
        })
      );
    }

    return minimizedDocInfo.isMinimized;
  };

  // Open document from document code in the URL
  const directOpenDocument = (documentCode: string) => {
    if (documentCode.toLowerCase() === 'create') {
      if (
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.CREATE
        )
      ) {
        if (!isOpeningForm) {
          addRecordClicked();
        }
      } else {
        showAlert(
          'No Permission!',
          `You don't have permission to create invoice, please contact owner/admin of this ${localizedText(
            'organisation'
          )}`
        );
        return;
      }
      removeLoader();
    }
    if (documentCode.toLowerCase() !== 'create') {
      if (minimizedDocExists(documentCode, true)) {
        return;
      }
      const existingDraft = invoiceDraftData?.find(
        (draft: any) => draft._id === documentCode
      );
      if (!Utility.isEmpty(existingDraft)) {
        removeLoader();
        const { type, payload, isMaximized, isCenterAlign, isSaved } =
          Utility.getKeysForDraftColumns(draftsColumnConfig);

        const formData = getUpdatedInvoiceObject(
          Utility.parseDraftDocumentPayload(existingDraft.cells[payload[0].id])
        );
        let payloadData: any = {
          id: existingDraft._id,
          type: existingDraft.cells[type[0].id],
          title: existingDraft.cells[type[0].id],
          isMaximized: existingDraft.cells[isMaximized[0].id],
          isCenterAlign: existingDraft.cells[isCenterAlign[0].id],
          populateFormData: formData,
          isSaved: existingDraft.cells[isSaved[0].id],
          draftCode: existingDraft.cells.documentSequenceCode,
          draftsTableId,
          draftsColumnConfig
        };

        const isDraftReadOnly = Utility.isDraftReadOnly(
          payloadData.populateFormData
        );

        if (isDraftReadOnly) {
          payloadData.title = 'Draft';
          payloadData.hideSave = true;
          payloadData.isReadOnlyDraft = true;
        }
        dispatch(
          createBlankDraft({
            payloadData,
            draftType: isDraftReadOnly ? DraftTypes.READONLY : DraftTypes.DRAFT
          })
        );
      } else {
        if (minimizedDocExists(documentCode, false)) {
          return;
        }
        showLoader(OPENING_DOCUMENT_LABEL);
        InvoiceService.getInvoiceByCode(documentCode).then(
          (data: any) => {
            let docDetailsData: any = getUpdatedInvoiceObject(data);
            const customFieldCheck = !(
              tenantInfo?.additionalSettings?.INVOICE_EDIT_SETTING || false
            );

            const isReadOnly =
              getIsReadOnly(docDetailsData) && customFieldCheck;

            if (!docDetailsData.openingInvoice) {
              if (!Utility.isEmpty(docDetailsData)) {
                let payloadData: any = {
                  id: docDetailsData.id,
                  type: LABELS.INVOICES,
                  title: LABELS.INVOICES,
                  isMaximized: true,
                  isCenterAlign: true,
                  populateFormData: docDetailsData,
                  isSaved: true,
                  draftsTableId,
                  draftsColumnConfig
                };

                dispatch(
                  createBlankDraft({
                    payloadData,
                    draftType: isReadOnly
                      ? DraftTypes.READONLY
                      : DraftTypes.UPDATE
                  })
                );
              }
            } else {
              showAlert('', 'Opening invoice cannot be opened');
            }
            removeLoader();
          },
          (err) => {
            console.error('Error while fetching document details: ', err);
            removeLoader();
          }
        );
      }
    }
  };

  const getIsReadOnly = (docClicked: any) => {
    let isReadOnly = false;

    if (
      GranularPermissionsHelper.hasDocumentReadOnly(
        PERMISSIONS_BY_MODULE.INVOICE
      )
    ) {
      isReadOnly = true;
    }

    if (
      docClicked.paymentStatus !== PAYMENT_STATUS.PENDING ||
      docClicked.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED
    ) {
      isReadOnly = true;
    }
    if (
      docClicked.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP ||
      docClicked.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
    ) {
      isReadOnly = true;
    }

    if (getInvoiceNowStatus(docClicked) === INVOICE_NOW_TYPES.GENERATED) {
      isReadOnly = true;
    }

    if (docClicked.reservedStock) {
      isReadOnly = true;
    }
    let res = Utility.validationForClosedDate(docClicked);
    if (res.isDisable) {
      isReadOnly = res.isDisable;
    }

    if (docClicked.totalAmount === 0) {
      isReadOnly = false;
    }

    return isReadOnly;
  };

  // WIP: Invoice edits
  const onInvoiceEdit = (data: any) => {
    const invoicesInStore: any[] = invoicesData.content;
    const invoiceClicked = invoicesInStore?.find(
      (x) => x.documentSequenceCode === data.rowData.documentSequenceCode
    );
    if (!!props?.listByContact) {
      if (invoiceClicked?.salesInvoiceCode) {
        directOpenDocument(invoiceClicked.salesInvoiceCode);
      }
    } else {
      if (!data.rowData.openingInvoice) {
        const rowData = data.rowData;
        if (rowData.isDraftDocument) {
          const draftId = rowData.id;
          RouteManager.navigateToPage(PAGE_ROUTES.INVOICES + `/${draftId}`);
        } else {
          RouteManager.navigateToPage(
            PAGE_ROUTES.INVOICES + `/${invoiceClicked?.salesInvoiceCode}`
          );
        }
      } else {
        showAlert('', 'Opening invoice cannot be opened');
      }
    }
  };

  // Load linked Quote
  const loadLinkedQuote = async (code: any) => {
    try {
      const quote = await QuotationService.getQuoteByCode(code);
      const quoteDetailsData: any = getUpdatedQuoteObject(quote);
      if (!Utility.isEmpty(quoteDetailsData)) {
        let payloadData: any = {
          id: quoteDetailsData.id,
          type: LABELS.QUOTES,
          title: Utility.convertInTitleCase(localizedText('quote')),
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: quoteDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked quote: ', err);
    }
  };

  // Load linked Sales Order
  const loadLinkedSO = async (code: any) => {
    try {
      const salesOrder = await SalesOrderService.getSalesOrderByCode(code);
      const salesOrderDetailsData: any = getUpdatedSalesOrderObject(salesOrder);
      if (!Utility.isEmpty(salesOrderDetailsData)) {
        let payloadData: any = {
          id: salesOrderDetailsData.id,
          type: LABELS.SALES_ORDER,
          title: LABELS.SALES_ORDER,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: salesOrderDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked sales order: ', err);
    }
  };

  // Load linked Order
  const loadLinkedOrder = async (code: any) => {
    try {
      const order = await PurchaseOrderService.fetchOrderDetails(code);
      const orderDetailsData: any = getUpdatedPurchaseOrderObject(order as any);
      if (!Utility.isEmpty(orderDetailsData)) {
        let payloadData: any = {
          id: orderDetailsData.id,
          type: LABELS.PURCHASE_ORDERS,
          title: LABELS.PURCHASE_ORDERS,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: orderDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked order: ', err);
    }
  };

  // Load linked Bill
  const loadLinkedBill = async (code: any) => {
    try {
      const bill = await BillService.fetchBillDetails(code);
      const billDetailsData: any = getUpdatedBillObject(bill as any);
      if (!Utility.isEmpty(billDetailsData)) {
        let payloadData: any = {
          id: billDetailsData.id,
          type: LABELS.BILLS,
          title: LABELS.BILLS,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: billDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked bill: ', err);
    }
  };

  const findApprovalLevels = (inputData: any[]) => {
    const result: Record<string, string> = {};

    const levelHandled = new Set();
    inputData?.forEach((item) => {
      item?.fields?.forEach((field: any) => {
        if (field?.field_type === 'PURCHASE_ORDER') {
          field?.multiApprovalDetails?.forEach((approvalDetail: any) => {
            if (!levelHandled.has(approvalDetail?.level)) {
              const key = `${field?.field_type}_PENDING_${approvalDetail?.level}_${approvalDetail?.levelLabel}`;
              result[key] = `Pending at ${approvalDetail?.levelLabel}`;
              const keyRejected = `${field?.field_type}_REJECTED_${approvalDetail?.level}_${approvalDetail?.levelLabel}`;
              result[keyRejected] = `Rejected at ${approvalDetail?.levelLabel}`;
              levelHandled.add(approvalDetail?.level);
            }
          });
        }
      });
    });

    const sortedResult: Record<string, string> = {};
    Object.keys(result)
      .sort((a, b) => {
        if (a.includes('REJECTED') && b.includes('PENDING')) {
          return -1;
        }
        if (a.includes('PENDING') && b.includes('REJECTED')) {
          return 1;
        }
        return 0;
      })
      .forEach((key) => {
        sortedResult[key] = result[key];
      });

    return sortedResult;
  };

  const getApprovalLevelAndLevelLabel = (key: string) => {
    const values = key.split('_');
    return {
      level: values[values?.length - 2],
      levelLabel: values[values?.length - 1]
    };
  };

  const getColumnsForFilter = (columnConfig: any[]) => {
    let tempColumnConfigList: any[] =
      columnConfig.filter((col) => col?.allowFilter !== false) || [];
    const productColumnForFilter = {
      id: 'product',
      key: 'product',
      name: 'Product',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (search: any) =>
            DocumentConfigManager.getProductURLForFilter(search),
          dataParser: (data: any) =>
            DocumentConfigUtility.productDataParser(data, DOC_TYPE.ORDER),
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };

    const contactColumnForFilter = {
      id: 'contact',
      key: 'contact',
      name: 'contact',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 10,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };
            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };
    const linkedDocumentCode = {
      id: 'linkedDocumentCode',
      key: 'linkedDocumentCode',
      columnCode: 'linkedDocumentCode',
      name: 'linked Document',
      type: INPUT_TYPE.TEXT
    };
    tempColumnConfigList.push(productColumnForFilter);
    tempColumnConfigList.push(contactColumnForFilter);
    tempColumnConfigList.push(linkedDocumentCode);

    const approvalStatusFilterIndex = tempColumnConfigList?.findIndex(
      (config) => config.columnCode === COLUMN_CODE.INVOICE.ApprovalStatus
    );

    if (approvalStatusFilterIndex !== -1) {
      let approvalLevels: any = {};

      if (!Utility.isEmpty(approvalConditions)) {
        approvalLevels = findApprovalLevels(approvalConditions);
      }
      const options = Object.keys(approvalLevels).map((key) => {
        return {
          id: key,
          name: approvalLevels[key],
          color: 'data-grid-badge-color-9'
        };
      });
      const removeOption = ['PENDING_FOR_APPROVAL', 'REJECTED'];
      tempColumnConfigList[approvalStatusFilterIndex] = {
        ...tempColumnConfigList[approvalStatusFilterIndex],
        type: IColumnType.select,
        options: [
          ...tempColumnConfigList[approvalStatusFilterIndex].options?.filter(
            (data: any) => !removeOption.includes(data.id)
          ),
          ...options
        ]
      };
    }

    tempColumnConfigList = tempColumnConfigList.map((config: any) => {
      let columnConfig = { ...config };
      if (
        INPUT_TYPE.DROPDOWN === config.type ||
        INPUT_TYPE.SELECT === config.type
      ) {
        columnConfig.filterConditions = [{ title: 'Equal to', value: 'eq' }];
      }
      return columnConfig;
    });

    return tempColumnConfigList;
  };

  const removeNonColumnFields = (queries: any[]) => {
    const nonColumnFields = [
      'product',
      'contact',
      'linkedDocumentCode',
      createdByColumnKey
    ];
    let filteredQueries = queries?.filter(
      (data) =>
        !(
          nonColumnFields.includes(data?.key) ||
          (approvalColumnKey === data?.key &&
            data?.value[0]?.toString()?.includes('PENDING')) ||
          data?.value[0]?.toString()?.includes('REJECTED')
        )
    );

    return filteredQueries;
  };
  const getQueryForNonColumnField = (queryString: string, queries: any[]) => {
    let nonColumnFieldQueries = '';
    nonColumnFieldQueries = queryString ?? '';
    queries.map((query: any) => {
      if (query.key === 'product') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries +
            `,salesInvoiceItems.productCode=${query?.value?.productId}`
          : `salesInvoiceItems.productCode=${query?.value?.productId}`;
      }
      if (query.key === 'contact') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries + `,contactCode=${query?.value?.code}`
          : `contactCode=${query?.value?.code}`;
      }
      if (query.key === 'linkedDocumentCode') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries + `,linkedDocumentCode=${query?.value}`
          : `linkedDocumentCode=${query?.value}`;
      }
      if (query.key === createdByColumnKey) {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries + `,createdBy=${query?.value[0]}`
          : `createdBy=${query?.value[0]}`;
      }
    });

    return nonColumnFieldQueries;
  };

  const onFilter = (updatedList: any[], queries: any[]) => {
    if (queries.length === 0) {
      const config: InvoiceAPIConfig = {
        ...InvoiceService.apiConfig,
        Query: `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}`,
        QueryParam: `&sort=salesInvoiceDate&sortDir=DESC`,
        Page: 0
      };
      setFilterDataInStore(
        { ...config },
        invoiceFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    } else {
      const approvalStatusFilter = queries.find(
        (item) =>
          approvalColumnKey === item?.key &&
          (item?.value[0]?.toString()?.includes('PENDING') ||
            item?.value[0]?.toString()?.includes('REJECTED'))
      );

      const fileredQueries = removeNonColumnFields(queries);
      let filteredQueryList = Utility.getFilterParameters(
        fileredQueries,
        REMOTE_CONFIG_TABLES.INVOICE
      );

      const list = Utility.getFilteredQueryList(filteredQueryList);
      let withoutFilter, withFilter;
      if (
        list.newFilteredQueyListArr &&
        list.newFilteredQueyListArr.length > 0
      ) {
        withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
      }
      if (list.customField && list.customField.length > 0) {
        assignProductModule('CLASS', 'CLASS', list?.customField);
        assignProductModule('CLASS', 'LOCATION', list?.customField);
        withFilter = Utility.getQueryString(list.customField);
      }

      withoutFilter = getQueryForNonColumnField(withoutFilter, queries);

      if (approvalStatusFilter) {
        const value = approvalStatusFilter?.value[0] ?? '';
        if (value.includes('PENDING')) {
          const { level, levelLabel } = getApprovalLevelAndLevelLabel(value);
          const payload: any = Utility.getKeyOfColumn(
            draftsColumnConfig,
            COLUMN_CODE.DRAFTS.Payload
          );
          const payloadKey = payload && payload.length ? payload[0].id : null;
          let draftSIOrders = [];
          if (payloadKey) {
            draftSIOrders = invoiceDraftData?.map((data: any) => {
              if (data.cells[payloadKey]) {
                return {
                  ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
                  id: data._id,
                  isDraftDocument: true,
                  createdBy: data?.createdBy,
                  draftCode: data.cells.documentSequenceCode
                };
              }
            });
            if (draftSIOrders) {
              draftSIOrders = draftSIOrders?.filter((ele: any) => {
                if (!Utility.isEmpty(searchTerm)) {
                  let safePattern = Utility.escapeRegex(searchTerm);
                  return (
                    ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                    ele.draftCode?.search(new RegExp(safePattern, 'i')) >= 0
                  );
                } else {
                  return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
                }
              });
            }
          }
          draftSIOrders =
            draftSIOrders?.filter(
              (ele) =>
                ele?.multiApprovalDetails?.currentLevel === Number(level) &&
                ele?.approvalStatus?.includes('PENDING')
            ) ?? [];

          const rowData = getRowData(draftSIOrders ?? []);
          let tempColumnConfig = deepClone(columnConfig) as any[];
          let columnData = getFilteredColumns(tempColumnConfig);
          const filterColumns = getColumnsForFilter(columnData);

          const passingData = {
            columnData: columnData,
            rowData,
            filterColumns,
            filter: queries,
            originalData: rowData
          };
          setInvoices(passingData);
          setFilters(queries);
          return;
        } else if (value.includes('REJECTED')) {
          const { level, levelLabel } = getApprovalLevelAndLevelLabel(value);
          const payload: any = Utility.getKeyOfColumn(
            draftsColumnConfig,
            COLUMN_CODE.DRAFTS.Payload
          );
          const payloadKey = payload && payload.length ? payload[0].id : null;
          let draftSIOrders = [];
          if (payloadKey) {
            draftSIOrders = invoiceDraftData?.map((data: any) => {
              if (data.cells[payloadKey]) {
                return {
                  ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
                  id: data._id,
                  isDraftDocument: true,
                  createdBy: data?.createdBy,
                  draftCode: data.cells.documentSequenceCode
                };
              }
            });
            if (draftSIOrders) {
              draftSIOrders = draftSIOrders?.filter((ele: any) => {
                if (!Utility.isEmpty(searchTerm)) {
                  let safePattern = Utility.escapeRegex(searchTerm);
                  return (
                    ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                    ele.draftCode?.search(new RegExp(safePattern, 'i')) >= 0
                  );
                } else {
                  return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
                }
              });
            }
          }
          draftSIOrders =
            draftSIOrders?.filter(
              (ele) =>
                ele?.multiApprovalDetails?.currentLevel === Number(level) &&
                ele?.approvalStatus?.includes('REJECTED')
            ) ?? [];

          const rowData = getRowData(draftSIOrders ?? []);
          let tempColumnConfig = deepClone(columnConfig) as any[];
          let columnData = getFilteredColumns(tempColumnConfig);
          const filterColumns = getColumnsForFilter(columnData);

          const passingData = {
            columnData: columnData,
            rowData,
            filterColumns,
            filter: queries,
            originalData: rowData
          };
          setInvoices(passingData);
          setFilters(queries);
          return;
        }
      }

      const config: InvoiceAPIConfig = {
        ...InvoiceService.apiConfig,
        Query:
          `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}` +
          (withoutFilter ? `,${withoutFilter}` : ''),
        QueryParam: `&sort=${InvoiceService.apiConfig.Sort}&sortDir=${InvoiceService.apiConfig.SortDir}&${withFilter}`,
        Page: 0,
        WithFilter: withFilter,
        WithoutFilter: withoutFilter
      };
      InvoiceService.apiConfig = config;
      setFilterDataInStore(
        { ...config },
        invoiceFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    }
  };

  const createAndHandleAIRequest = async (command: string) => {
    const payload = {
      query: command,
      table: 'Invoices'
    };
    try {
      const aiResponse = await AiAPICallService.tryAi(payload);
      const aiwithoutFilter = aiResponse.data.query;
      let aiwithFilter = '';

      const aiFilterDates = {
        startDate: aiResponse.DocumentDates?.startDate ?? '',
        endDate: aiResponse.DocumentDates?.endDate ?? ''
      };

      const startDate = aiFilterDates.startDate || filterDates.startDate;
      const endDate = aiFilterDates.endDate || filterDates.endDate;

      if (
        aiResponse.data.customField &&
        aiResponse.data.customField.length > 0
      ) {
        assignProductModule('CLASS', 'CLASS', aiResponse.data?.customField);
        assignProductModule('CLASS', 'LOCATION', aiResponse.data?.customField);
        aiwithFilter = Utility.getQueryString(aiResponse.data.customField);
      }

      const config: InvoiceAPIConfig = {
        ...InvoiceService.apiConfig,
        Query:
          `salesInvoiceDate>=${startDate},salesInvoiceDate<=${endDate}` +
          (aiwithoutFilter ? `,${aiwithoutFilter}` : ''),
        QueryParam: `&sort=${InvoiceService.apiConfig.Sort}&sortDir=${InvoiceService.apiConfig.SortDir}&${aiwithFilter}`,
        Page: 0,
        WithFilter: aiwithFilter,
        WithoutFilter: aiwithoutFilter
      };
      InvoiceService.apiConfig = config;
      setFilterDataInStore({ ...config }, invoiceFilterData?.SearchTerm);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const assignProductModule = (key: string, label: string, list: any) => {
    if (tenantInfo.additionalSettings?.[key]?.assignClasses === 'ROW') {
      const cfInAvailableCFs = availableCustomFields?.content?.find(
        (field: any) => field.label === label
      );
      list?.forEach((field: any) => {
        field?.customfield?.forEach((data: any) => {
          if (data?.code === cfInAvailableCFs?.code) {
            if (!data?.module.includes('PRODUCT')) {
              data.module.push('PRODUCT');
            }
          }
        });
      });
    }
  };

  const onRowAdd = (newRow: any) => {
    if (newRow) {
      let updatedRowData = [...invoices.rowData];
      updatedRowData.unshift(newRow);
      setInvoices({
        ...invoices,
        rowData: updatedRowData,
        originalData: updatedRowData
      });
    }
  };

  const receivePayment = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert(
        '',
        t(`INVOICES.TOAST.CANNOT_MAKE_PAYMENT_AGAINST_DRAFT_INVOICE`)
      );
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      loadLocalInvoiceDetailsForEdit(
        invoiceData,
        POPUP_CLICK_TYPE.RECEIVE_PAYMENT
      );
    }
  };

  const salesReturn = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert('', 'Sales Return not allowed on draft');
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      Promise.all([
        FulfillmentService.getFulfillmentRecords(
          invoiceData.salesInvoiceCode,
          DOC_TYPE.INVOICE
        ),
        SalesReturnService.isFullyReturned(
          invoiceData.salesInvoiceCode,
          DOC_TYPE.INVOICE
        )
      ])
        .then(
          (data: any) => {
            let returnedData = [];
            if (!Utility.isEmpty(data?.[1])) {
              returnedData = data[1];
            }
            let filteredFulfillment = data?.[0];
            if (
              data &&
              returnedData &&
              Array.isArray(returnedData) &&
              returnedData.length > 0 &&
              !Utility.hasQtyToReturn(
                returnedData,
                RETURN_MODE.SALE,
                filteredFulfillment
              )
            ) {
              showAlert('', 'This invoice has been fully returned.');
            } else {
              loadInvoiceDetailsForEdit(
                invoiceData,
                POPUP_CLICK_TYPE.SALES_RETURN
              );
            }
          },
          (err) => {
            console.error('Error getting fulfillment records: ', err);
            showAlert(
              '',
              'No Fulfillment with valid products available for the sales return.'
            );
          }
        )
        .catch((err) => {
          showAlert(
            '',
            'No Fulfillment with valid products available for the sales return.'
          );
        });
    }
  };

  const recheckDpl = (contact: any) => {
    const billingAddress: any[] = [];
    const countryCodeMap: any = {};
    COUNTRIES_WITH_CURRENCIES.forEach((country) => {
      countryCodeMap[country?.country?.toLowerCase()] = country?.countryCode;
    });
    if (contact?.billingAddress) {
      contact?.billingAddress?.forEach((address: any) => {
        if (address?.country) {
          billingAddress.push({
            ...address,
            countryCode: countryCodeMap?.[address?.country?.toLowerCase()]
          });
        }
      });
    }
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0) return;
    const payload = {
      name: contact?.name ?? '',
      billingAddress: billingAddress,
      customField: dplContactCustomField
    };
    showLoader();
    ContactService.recheck(contact?.id, payload)
      .then((res) => {
        removeLoader();
        if (res) {
          showToast(
            'The contact you are using is under denied party list',
            TOAST_TYPE.SUCCESS
          );
        } else {
          showToast(
            'The contact you are using is not under denied party list',
            TOAST_TYPE.SUCCESS
          );
        }
        dispatch(fetchContacts(''));
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const fulfillment = (data: any) => {
    if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
      recheckDpl(data?.rowData?.contactDto);
    }
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    const isDraft = data.rowData.isDraftDocument;
    const isFulfilled: boolean =
      invoiceData.fulfillmentStatus === FULFILLMENT_STATUS.FULLY_FULFILLED;
    if (isDraft) {
      showAlert(
        '',
        t(`INVOICES.TOAST.FULLFILLMENT_AGAINST_DRAFT_INVOICE_IS_NOT_ALLOWED`)
      );
      return;
    }
    if (isFulfilled) {
      showAlert('', t(`INVOICES.TOAST.THIS_DOCUMENT_IS_FULLY_FULFILLED`));
      return;
    }

    if (invoiceData) {
      // Redirect to PPS page, if fulfillmentType is PPS
      // if (invoiceData?.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
      //   showLoader();
      //   RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
      //   return;
      // }
      const displayFulfillmentConfirmation =
        !Utility.isAllNonTracked(invoiceData?.salesInvoiceItems) &&
        invoiceData.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED;

      loadInvoiceDetailsForEdit(invoiceData, POPUP_CLICK_TYPE.FULFILLMENT);
    }
  };

  const getFulfillmentRecords = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    const isNotFulfilled: boolean =
      data.rowData.fulfillmentStatus[0] === FULFILLMENT_STATUS.UNFULFILLED;
    if (isDraft) {
      showAlert(
        '',
        t(
          `INVOICES.TOAST.CANNOT_GET_FULFILLMENT_RECORDS_AGAINST_DRAFT_INVOICES`
        )
      );
      return;
    }
    if (isNotFulfilled) {
      showAlert(
        'Fulfillment Info',
        t(`INVOICES.TOAST.THERE_IS_NO_FULFILLMENT_RECORDS_IN_THIS_DOCUMENT`)
      );
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      loadInvoiceFulfillmentRecords(
        invoiceData,
        POPUP_CLICK_TYPE.FULFILLMENT_RECORDS
      );
    }
  };

  const getSalesReturnRecords = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    setEditableInvoice(invoiceData);
    SalesReturnService.isFullyReturned(
      invoiceData.salesInvoiceCode,
      DOC_TYPE.INVOICE
    )
      .then((res: any) => {
        // let data = Utility.filterReturnRecords(res, DOC_TYPE.INVOICE);
        let data = res;
        if (data?.length === 0) {
          showAlert('', 'Sales Returns Records not available.');
        } else {
          setSalesReturnsDetails(data);
          setShowSalesReturnRecordsPopup(true);
        }
      })
      .catch((err) => {
        removeLoader();
        showAlert('Error', 'Unable to get Sales Returns Records');
      });
  };

  const getApprovalHistory = (data: any, isDraft: any) => {
    setEditableInvoice(data?.rowData);
    if (isDraft) {
      if (
        Utility.isEmpty(data?.rowData?.multiApprovalDetails?.approvalHistory)
      ) {
        showAlert('Approval/Rejection History', 'No History Found');
        return;
      }
      setApprovalHistoryData(data?.rowData?.multiApprovalDetails);
      setShowApprovalHistoryPopup(true);
    } else {
      AutomationService.getApprovalHistory(
        data?.rowData?.documentSequenceCode,
        DOC_TYPE.INVOICE,
        draftsTableId
      )
        .then((res: any) => {
          if (res) {
            setApprovalHistoryData(res?.cells?.multiApprovalDetails);
            setShowApprovalHistoryPopup(true);
          } else {
            showAlert('Approval/Rejection History', 'No History Found');
          }
        })
        .catch((err) => {
          removeLoader();
          showAlert('Approval/Rejection History', 'No History Found');
        });
    }
  };

  const setBulkPaymentRecords = (selectedRowIds: any[] = []) => {
    let selectedRowCode: any = selectedRowIds;
    if (selectedRowCode && selectedRowCode.length > 0) {
      FulfillmentService.getInvoiceBulkFulfillmentDetails(selectedRowIds).then(
        (data: any) => {
          let invoiceList: any[] = data;
          if (
            invoiceList &&
            invoiceList.length > 1 &&
            selectedRowIds.length > 1
          ) {
            const allEqual = invoiceList.every(
              (v) =>
                v.contactCode === invoiceList[0].contactCode &&
                v.currency === invoiceList[0].currency &&
                v.paymentStatus &&
                !v.paymentStatus.includes(PAYMENT_STATUS.RECEIVED) &&
                v.dueAmount > 0
            );
            if (allEqual) {
              setBulkPaymentRows(invoiceList);
              return;
            }
          }
        }
      );
    }
    setBulkPaymentRows([]);
  };

  const setBulkPendingEInvoiceRecords = (data: any) => {
    let pendingInvoices: any[] = [];
    let otherInvoiceCount: number = 0;

    data.forEach((invoice: any) => {
      switch (getInvoiceNowStatus(invoice)) {
        case INVOICE_NOW_TYPES.FAILED:
          otherInvoiceCount++;
          break;
        case INVOICE_NOW_TYPES.GENERATED:
          otherInvoiceCount++;
          break;
        case INVOICE_NOW_TYPES.PENDING:
          pendingInvoices.push(invoice);
          break;
        case INVOICE_NOW_TYPES.CANCELLED:
          otherInvoiceCount++;
          break;
      }
    });

    if (pendingInvoices.length && otherInvoiceCount === 0) {
      setBulkPendingEInvoicesRows(pendingInvoices);
    } else {
      setBulkPendingEInvoicesRows([]);
    }
  };

  const setBulkGeneratedEInvoiceRecords = (data: any) => {
    let generatedInvoices: any[] = [];
    let otherInvoiceCount: number = 0;

    data.forEach((invoice: any) => {
      switch (getInvoiceNowStatus(invoice)) {
        case INVOICE_NOW_TYPES.FAILED:
          otherInvoiceCount++;
          break;
        case INVOICE_NOW_TYPES.GENERATED:
          generatedInvoices.push(invoice);
          break;
        case INVOICE_NOW_TYPES.PENDING:
          otherInvoiceCount++;
          break;
        case INVOICE_NOW_TYPES.CANCELLED:
          otherInvoiceCount++;
          break;
      }
    });

    if (generatedInvoices.length && otherInvoiceCount === 0) {
      setBulkGeneratedEInvoicesRows(generatedInvoices);
    } else {
      setBulkGeneratedEInvoicesRows([]);
    }
  };

  const setBulkFulfillmentRecords = (selectedRowIds: any[] = []) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceData =
      invoiceList &&
      invoiceList.filter(
        (invoice) =>
          selectedRowIds && selectedRowIds.includes(invoice.salesInvoiceCode)
      );
    if (invoiceData && invoiceData.length > 0 && selectedRowIds.length > 0) {
      const allEqual = invoiceData.every((v) => {
        return (
          (v.contactCode || v.contactDto.code) === invoiceData[0].contactCode &&
          v.status === DOCUMENT_STATUS.OPEN &&
          v.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED &&
          (v.fulfillmentType === FULFILLMENT_TYPE.DEFAULT ||
            v.fulfillmentType === FULFILLMENT_TYPE.NONE)
        );
      });
      if (allEqual) {
        setBulkFulfillmentRows(invoiceData);
        return;
      } else {
        setBulkFulfillmentRows([]);
      }
    }
    setBulkFulfillmentRows([]);
  };

  const updateBulkPrintRecord = (selectedRows: any[] = []) => {
    if (selectedRows && selectedRows.length > 1) {
      let draft = selectedRows.find(
        (x) => x.isDraftDocument === true || x.rowType === 'draft'
      );
      if (!draft) {
        setBulkPrintRecordData(selectedRows);
        return;
      }
    }
    setBulkPrintRecordData([]);
  };

  const setBulkApprovalRecords = (selectedRowIds: any[] = []) => {
    const allEqual = isBulkApprovalRejectCondition(selectedRowIds);
    if (allEqual) {
      setBulkApprovalRows(selectedRowIds);
      return;
    }
    setBulkApprovalRows([]);
  };
  const setBulkRejectRecords = (selectedRowIds: any[] = []) => {
    const allEqual = isBulkApprovalRejectCondition(selectedRowIds);
    if (allEqual) {
      setBulkRejectRows(selectedRowIds);
      return;
    }
    setBulkRejectRows([]);
  };

  const isBulkApprovalRejectCondition = (data: any) => {
    if (data && data.length > 1) {
      const allEqual = data.every((v: any) => {
        let payload: any = Utility.getKeyOfColumn(
          draftsColumnConfig,
          COLUMN_CODE.DRAFTS.Payload
        );
        let isApprovalConditionApplied = true;
        if (v.isDraftDocument) {
          let draft = invoiceDraftData.find((x) => x._id === v.id);
          isApprovalConditionApplied = Utility.isApproverButtonShow(
            DOC_TYPE.INVOICE,
            Utility.parseDraftDocumentPayload(
              draft?.cells?.[payload?.[0]?.id] || ''
            )
          );
        }
        return (
          v.isDraftDocument &&
          v.approvalStatus !== APPROVAL_STATUS_LIST['NOT_REQUIRED'] &&
          isApprovalConditionApplied
        );
      });
      return allEqual;
    }
  };
  const getMultiViewJournalEntry = (data: any) => {
    setMultiViewJournalEntryData(data.rowData);
    setShowMultiViewJournalEntry(true);
  };
  const getPaymentRecords = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert(
        'Draft Info',
        t(`INVOICES.TOAST.CANNOT_GET_PAYMENT_RECORDS_OF_DRAFT_BILL`)
      );
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    setPaymentContactId(invoiceData?.contactDto?.id);
    if (
      (invoiceData.knockoffInfo && invoiceData.knockoffInfo.length > 0) ||
      (invoiceData.linkedCreditNote && invoiceData.linkedCreditNote.length > 0)
    ) {
      setSalesDocumentSequenceCode(data.rowData.documentSequenceCode);
      setSalesInvoiceCode(invoiceData.salesInvoiceCode);

      const combinedData = [
        ...(invoiceData?.knockoffInfo || []),
        ...(invoiceData?.linkedCreditNote || [])
      ];

      setPaymentRecords(combinedData);
      setShowPaymentRecord(true);
      setEditableInvoice({
        ...editableInvoice,
        contactCode: invoiceData.contactCode
      });
    } else {
      showAlert(
        'Payment',
        t(`INVOICES.TOAST.NO_PAYMENT_RECORDS_EXIST_FOR_INVOICE`)
      );
    }
  };

  const onAddCustomFieldClicked = () => {
    const module = 'INVOICE';
    const columnConfigInfo = {
      columnConfig: columnConfig,
      tableId: columnConfigTableId as string
    };
    showAddCustomField(
      {
        module: module,
        forDocument: true,
        columnConfig: columnConfigInfo.columnConfig,
        tableId: columnConfigInfo.tableId
      },
      (newCustomField) => {}
    );
  };

  const columnDelete = (data: ColumnDeleteType) => {
    const [identifier, customFieldCode, customFieldId] =
      data.columnData.columnCode.split('_');
    if (
      !isNaN(Number(customFieldId)) &&
      data.columnData.id &&
      columnConfigTableId
    ) {
      CustomFieldService.deleteCustomField(
        Number(customFieldId),
        data,
        columnConfigTableId,
        columnConfig
      )
        .then((res) => {
          const data: any = res;
          const newColumnConfigs: IColumn[] = data.config;
          // dispatch(
          //   addInvoiceColumnConfig({
          //     tableId: columnConfigTableId,
          //     config: newColumnConfigs
          //   })
          // );
        })
        .catch((err) => {
          dispatch(
            addInvoiceColumnConfig({
              tableId: columnConfigTableId,
              config: columnConfig
            })
          );
          console.log(err, 'err');
        });
    }
  };

  const bulkFulfillment = (data: any) => {
    if (data?.length === 1) {
      fulfillment({ rowData: data[0] });
      return;
    }
    const hasFulfilled = Utility.isFulfilledReceived(data, MODULE_TYPE.SELL);

    if (hasFulfilled) {
      const docCodes = data
        .filter(
          (obj: any) =>
            obj.fulfillmentStatus[0] === FULFILLMENT_STATUS.FULLY_FULFILLED
        )
        .map((filteredObj: any) => '#' + filteredObj.documentSequenceCode);
      showAlert(
        '',
        t(`INVOICES.TOAST.THE_SELECTED_QUOTE_INVOICES`) +
          ', ' +
          docCodes +
          t(
            `INVOICES.TOAST.FULLY_FULLFILLED_PLEASE_DESELECT_THIS_AND_TRY_AGAIN`
          )
      );
      return;
    }
    let selectedRowCode: any = selectedRows;
    if (selectedRowCode && selectedRowCode.length > 0) {
      FulfillmentService.getInvoiceBulkFulfillmentDetails(selectedRows).then(
        (data: any) => {
          setBulkFulfillmentDetails(data);
          setEditableInvoice(data[0]);
          setShowBulkFulfillmentPopup(true);
        }
      );
    }
  };

  const rowAllSelected = (data: any) => {};

  const rowUpdate = (columnData: RowUpdateType) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = columnData.rowData.id;
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    const [identifier, customFieldCode, customFieldId] =
      columnData.columnKey.split('_');
    const customFieldColumnConfig = columnConfig.find(
      (column) => column.columnCode === columnData.columnKey
    );
    if (invoiceData && customFieldCode) {
      const updatedInvoice = Utility.updateCustomField(
        invoiceData,
        customFieldCode,
        columnData,
        customFieldColumnConfig
      );
      if (updatedInvoice) {
        let payload: any = deepClone(updatedInvoice);
        payload = { ...payload, contact: payload.contactDto };
        delete payload?.items;
        delete payload?.contactDto;

        InvoiceService.updateInvoice(payload, updatedInvoice?.id as number)
          .then((res) => {
            const response: any = res;
            const customFields: any[] = response.customField;
            updatedInvoice.customField = customFields;
            dispatch(updateInvoiceDetails(updatedInvoice));
          })
          .catch((error) => {
            console.log(error, 'err');
          });
      }
    }
  };
  const offSetPayment = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert(
        '',
        t(`INVOICES.TOAST.CANNOT_MAKE_OFFSET_AGAINST_OF_DRAFT_BILL`)
      );
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      setEditableInvoice(invoiceData);
      setShowOffsetPopup(true);
    } else {
      showAlert('', t(`INVOICES.TOAST.CANNOT_FIND_BILL`));
    }
  };

  const recurringDocument = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert('', 'Unable to set recurring document for Draft Document');
      return;
    }
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      loadInvoiceDetailsForEdit(
        invoiceData,
        POPUP_CLICK_TYPE.SET_RECURRING_DOCUMENT
      );
    }
  };

  // Linked Record Action
  const onLinkedRecords = (data: any) => {
    setInvoiceLinkRecords(data.rowData);
    setShowLinkRecordsPopup(true);
  };

  const getRateAnalysis = (rowData: any) => {
    if (!Utility.isEmpty(rowData)) {
      let detail = {
        documentCode:
          rowData.rowType == 'draft' || rowData.isDraftDocument
            ? rowData.draftCode
            : rowData.salesInvoiceCode,
        documentType: 'SALES_INVOICE',
        draftId: rowData.id,
        isDraft:
          rowData.rowType == 'draft' || rowData.isDraftDocument ? true : false,
        draftCode: rowData.draftCode
      };
      setRateAnalysisData(detail);
      setShowRateAnalysisForm(true);
    }
  };

  const deactivateRecurringDocument = (data: any) => {
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert(
        '',
        'Unable to deactivate recurring document for Draft Document'
      );
      return;
    }
    showAlert(
      'Deactivate recurring?',
      'Are you sure you want to deactivate the selected recurring document?',
      [
        {
          title: 'Cancel',
          className: 'bg-gray2 border-m',
          onClick: () => {}
        },
        {
          title: 'Deactivate',
          className: 'bg-red text-white border-m',
          onClick: () => {
            InvoiceService.deactivateRecurring(
              'SALES_INVOICE',
              data.rowData?.salesInvoiceCode
            )
              .then((res) => {
                const config: InvoiceAPIConfig = {
                  ...InvoiceService.apiConfig
                };
                loadInvoices(config);
              })
              .catch((error) => {
                console.log(error, 'err');
              });
          }
        }
      ]
    );
  };

  const onAttachmentCLicked = (data: any) => {
    const invoiceList: any[] = invoicesData['content'];
    const invoiceId: number = data.rowData.id;
    const invoiceData = invoiceList.filter(
      (invoice) => invoice.id === invoiceId
    )[0];
    if (invoiceData) {
      loadInvoiceDetailsForEdit(invoiceData, POPUP_CLICK_TYPE.OPEN_ATTACHMENT);
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPaymentPopup(false);
        setShowPaymentRecord(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentPopup(false);
        setShowOffsetPopup(false);
        setShowPaymentMilestonePopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowDropShipPopup(false);
        setShowDropShipWarningPopup(false);
        setShowIRPCredPopup(false);
        setShowSalesReturnPopup(false);
        setShowSalesReturnRecordsPopup(false);
        setShowApprovalHistoryPopup(false);
        setshowExpenseButton(false);
        setShowApprovalOrRejectPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowCNAndDNInfo({
          ...showCNAndDNInfo,
          showPopup: false,
          isTouched: true
        });
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        break;
      case POPUP_CLICK_TYPE.UPDATE_INVOICE:
        addInvoiceRef.current?.storeCallbacksRef.updateInvoice();
        break;
      case POPUP_CLICK_TYPE.RECEIVE_PAYMENT:
        addInvoiceRef.current?.storeCallbacksRef.receivePayment();
        break;
      case POPUP_CLICK_TYPE.APPLY_OFFSET:
        addInvoiceRef.current?.storeCallbacksRef.applyOffset();
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addInvoiceRef.current?.storeCallbacksRef.fulfillment();
        break;
      case POPUP_CLICK_TYPE.BULK_FULFILLMENT:
        addInvoiceRef.current?.storeCallbacksRef.bulkFulfillment();
        break;
      case POPUP_CLICK_TYPE.SAVE_RECURRING_DOCUMENT:
        addInvoiceRef.current?.storeCallbacksRef.saveRecurringDocument();
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
      case POPUP_CLICK_TYPE.SALES_RETURN:
        addInvoiceRef.current?.storeCallbacksRef.updateInvoice();
        break;
      case POPUP_CLICK_TYPE.CONFIRM_DROPSHIP:
        let poDetails: any = Utility.createDropShip(
          { ...editableInvoice },
          DOC_TYPE.INVOICE
        );
        dispatch(
          createBlankDraft({
            payloadData: {
              title: LABELS.PURCHASE_ORDERS,
              type: LABELS.PURCHASE_ORDERS,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig,
              populateFormData: poDetails,
              isCenterAlign: true,
              isMaximized: true
            },
            draftType: DraftTypes.NEW
          })
        );
        setShowDropShipWarningPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT:
        expenseRef.current?.storeCallbacksRef.createExpenseDeposit();
        break;
      case POPUP_CLICK_TYPE.CREATE_CREDIT:
        createCreditRef.current?.storeCallbacksRef.createCredit();
        break;
      case POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE:
        setPaymentMilestoneBeingSaved(true);
        addPaymentMilestoneRef.current?.storeCallbacksRef?.savePaymentMileStone();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        createCreditRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.UPDATE_INVOICE:
        addInvoiceRef.current.storeCallbacksRef.updateInvoice =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPaymentPopup(false);
        setShowPaymentRecord(false);
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowOffsetPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowBulkPaymentPopup(false);
        setShowSalesReturnPopup(false);
        setShowPaymentMilestonePopup(false);
        setShowSalesReturnRecordsPopup(false);
        setShowApprovalHistoryPopup(false);
        setshowExpenseButton(false);
        setIsCNActionBtnDisabled(false);
        setShowCNAndDNInfo({
          ...showCNAndDNInfo,
          showPopup: false,
          isTouched: true
        });
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.RECEIVE_PAYMENT:
        addInvoiceRef.current.storeCallbacksRef.receivePayment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.APPLY_OFFSET:
        addInvoiceRef.current.storeCallbacksRef.applyOffset = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addInvoiceRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.BULK_FULFILLMENT:
        addInvoiceRef.current.storeCallbacksRef.bulkFulfillment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_RECURRING_DOCUMENT:
        addInvoiceRef.current.storeCallbacksRef.saveRecurringDocument =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(false);
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT_FOR_DROPSHIP:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(true);
        break;
      case POPUP_CALLBACKS_TYPE.DROPSHIP_FULFILLMENT:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropShipPopup(false);
        setShowDropShipWarningPopup(true);

        break;
      case POPUP_CALLBACKS_TYPE.PICK_PACK_SHIP:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(false);
        //RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP);
        break;
      case POPUP_CALLBACKS_TYPE.SALES_RETURN:
        addInvoiceRef.current.storeCallbacksRef.updateInvoice =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_DEPOSITE:
        expenseRef.current.storeCallbacksRef.createExpenseDeposit =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CREDIT:
        createCreditRef.current.storeCallbacksRef.createCredit =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_PAYMENT_MILESTONE:
        addPaymentMilestoneRef.current.storeCallbacksRef.savePaymentMileStone =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        createCreditRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS:
        setIsCNActionBtnDisabled(true);
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS:
        setIsCNActionBtnDisabled(false);
        break;
    }
  };

  const showExportOptionsList = () => {
    return (
      <DKListPicker
        data={EXPORT_FILE_TYPE}
        className="shadow-m absolute z-10"
        style={{ width: 80 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            exportInvoice(EXPORTED_FILES_TYPE_EXTENSION.PDF);
          } else if (index === 1) {
            exportInvoice(EXPORTED_FILES_TYPE_EXTENSION.XLS);
          } else {
            exportInvoice(EXPORTED_FILES_TYPE_EXTENSION.CSV);
          }
        }}
        onClose={() => {
          setShowExportOptions(false);
        }}
      />
    );
  };

  const exportInvoice = (fileFormat: EXPORTED_FILES_TYPE_EXTENSION) => {
    EximService.exportFiles(
      fileFormat,
      MODULES_NAME.INVOICE_EXPORT,
      InvoiceService.apiConfig
    )
      .then((res) => {
        removeLoader();
        setShowExportOptions(false);
      })
      .catch((err) => {
        removeLoader();
        console.error('Error exporting invoice: ', err);
        showAlert('Error', 'Unable to export invoice');
      });
  };

  const getInvoiceSummaryView = () => {
    return props.listByContact ? null : (
      <div className="column parent-width mt-r mb-m">
        <div className="row row-responsive align-items-start">
          <Summary
            docType={DOCUMENT_TYPE.INVOICE}
            tenantCurrency={tenantInfo.currency}
            isRightGapRequired={isDesktopOrTablet()}
            selectedIndex={selectedFilterIndex}
            filterChanged={(index: any) => {
              setSelectedFilterIndex(index);
            }}
            filters={duration_filters.map((item: any) => item.name)}
            title="Invoice Summary"
            data1={{
              title: '⦿ Total Amount',
              subTitle: Utility.getAmoutBlockForGrid(
                tenantInfo.currency,
                invoicesSummary?.invoiceAmount
                  ? invoicesSummary?.invoiceAmount
                  : 0,
                ''
              ),
              totalAmount: invoicesSummary?.invoiceAmount
                ? invoicesSummary?.invoiceAmount
                : 0
            }}
            data2={{
              title: '⦿ Unpaid',
              subTitle: Utility.getAmoutBlockForGrid(
                tenantInfo.currency,
                invoicesSummary?.invoiceDueAmount
                  ? invoicesSummary?.invoiceDueAmount
                  : 0,
                ''
              ),
              totalAmount: invoicesSummary?.invoiceDueAmount
                ? invoicesSummary?.invoiceDueAmount
                : 0
            }}
            data3={{
              title: '⦿ Paid',
              color: 'text-green',
              subTitle: Utility.getAmoutBlockForGrid(
                tenantInfo.currency,
                `${
                  (invoicesSummary?.invoiceAmount
                    ? invoicesSummary?.invoiceAmount
                    : 0) -
                  (invoicesSummary?.invoiceDueAmount
                    ? invoicesSummary?.invoiceDueAmount
                    : 0)
                }`
              ),
              totalAmount:
                (invoicesSummary?.invoiceAmount
                  ? invoicesSummary?.invoiceAmount
                  : 0) -
                (invoicesSummary?.invoiceDueAmount
                  ? invoicesSummary?.invoiceDueAmount
                  : 0)
            }}
            footer={''}
          />
          <SummaryBarGraph
            tenantCurrency={tenantInfo.currency}
            docType={DOCUMENT_TYPE.INVOICE}
            data={[
              {
                title: 'Paid',
                value:
                  (invoicesSummary?.invoiceAmount
                    ? invoicesSummary?.invoiceAmount
                    : 0) -
                  (invoicesSummary?.invoiceDueAmount
                    ? invoicesSummary?.invoiceDueAmount
                    : 0),
                color: COLORS.chart.blue
              },
              {
                title: 'Unpaid',
                value: invoicesSummary?.invoiceDueAmount,
                color: COLORS.chip.blue
              }
              // {
              //   title: 'Over Due',
              //   value: invoicesSummary?.invoiceDueAmount,
              //   color: COLORS.chart.red
              // }
            ]}
            title="Overall Comparison"
            leftTitle={{
              title: 'Paid',
              value: Utility.getAmoutBlockForGrid(
                tenantInfo.currency,
                `${
                  (invoicesSummary?.invoiceAmount
                    ? invoicesSummary?.invoiceAmount
                    : 0) -
                  (invoicesSummary?.invoiceDueAmount
                    ? invoicesSummary?.invoiceDueAmount
                    : 0)
                }`,
                ''
              )
            }}
            leftTotalAmount={
              (invoicesSummary?.invoiceAmount
                ? invoicesSummary?.invoiceAmount
                : 0) -
              (invoicesSummary?.invoiceDueAmount
                ? invoicesSummary?.invoiceDueAmount
                : 0)
            }
            rightTitle={{
              title: 'Unpaid',
              value: Utility.getAmoutBlockForGrid(
                tenantInfo.currency,
                invoicesSummary?.invoiceDueAmount
                  ? invoicesSummary?.invoiceDueAmount
                  : 0,
                ''
              )
            }}
            rightTotalAmount={
              invoicesSummary?.invoiceDueAmount
                ? invoicesSummary?.invoiceDueAmount
                : 0
            }
          />
        </div>
      </div>
    );
  };

  const addRecordClicked = async (isCashInvoice = false) => {
    setIsOpeningForm(true);
    if (contactIdInURL || props.listByContact) {
      const contactId = contactIdInURL || docCodeInURL;
      ContactService.getContactDetailsById(contactId).then(
        (contactInfo: any) => {
          if (contactInfo?.status === STATUS_TYPE.INACTIVE) {
            showAlert('Operation Not allowed', 'Selected Contact is Inactive');
            setIsOpeningForm(false);
            return;
          }

          let docDetailsData = InvoiceInitialState;
          docDetailsData = {
            ...docDetailsData,
            contact: contactInfo,
            contactCode: contactInfo.code,
            contactDto: contactInfo,
            needToUpdateAddresses: true
          };
          if (!Utility.isEmpty(docDetailsData)) {
            let payloadData: any = {
              type: LABELS.INVOICES,
              title: LABELS.INVOICES,
              isMaximized: true,
              isCenterAlign: true,
              populateFormData: docDetailsData,
              isSaved: true,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig,
              isCashInvoice
            };

            dispatch(
              createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
            );
          }
          setIsOpeningForm(false);
        },
        (err: any) => {
          setIsOpeningForm(false);
          console.error('Error loading contact details: ', err);
        }
      );
    } else {
      await dispatch(
        createBlankDraft({
          payloadData: {
            title: LABELS.INVOICES,
            type: LABELS.INVOICES,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig,
            isCenterAlign: true,
            isCashInvoice
          },
          draftType: DraftTypes.NEW
        })
      );
      setIsOpeningForm(false);
    }
  };

  const bulkPaymentClick = (data: any) => {
    setShowBulkPaymentPopup(true);
  };

  const createDocForReturn = (data: any) => {
    let msg =
      'Returned Goods have been added back to your selected warehouse. To refund , create a Credit Note or a Direct Expense for this contact.';
    showAlert('Sales Return Created Successfully', msg, [
      {
        title: 'Do it later',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {}
      },
      {
        title: 'Direct Expense',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          const record: any = {
            gstExchangeRate: 1,
            exchangeRate: 1,
            referenceNumber: '',
            makePaymentItemDtoList: [],
            contactCode: editableInvoice.contactCode,
            contactName: editableInvoice.contact.name
          };
          setExpenseData(record);
          setshowExpenseButton(true);
        }
      },
      {
        title: 'Credit Note',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          createCNPayload(data);
        }
      }
    ]);
  };

  const createCNPayload = (data: any) => {
    const documentItems = editableInvoice.items;
    const returnedItems = data.salesReturnItems;
    data['returnedItems'] = data.salesReturnItems;
    let arr: any = [];
    let srAccountDetails = documentItems
      .filter((item1: any) =>
        returnedItems.some(
          (item2: any) =>
            item1.productCode === item2.productCode &&
            item2.quantityToReturn > 0
        )
      )
      .reduce((res: any, item: any) => {
        let accountCode = item?.product?.salesReturnAccountCode;
        let obj: any = {};
        if (!res[accountCode]) {
          obj = {
            accountCode: accountCode,
            amountDue: 0,
            amount: 0,
            taxAmount: 0,
            taxCode: item.taxCode,
            taxList: []
          };
        }
        let qtyToReturn = returnedItems.find(
          (i: any) => i.productCode === item.productCode
        ).quantityToReturn;
        obj.amount += item.unitPrice * qtyToReturn;
        obj.taxAmount =
          (Number(item.taxAmount) / Number(item.productQuantity)) * qtyToReturn;
        arr.push(obj);
        return arr;
      }, {});

    const record: any = {
      gstExchangeRate: 1,
      exchangeRate: 1,
      referenceNumber: '',
      lineItems: arr,
      contactCode: editableInvoice.contactCode,
      contact: {
        ...editableInvoice.contact,
        code: editableInvoice.contactCode
      },
      cnDate: data.salesReturnDate,
      currency: editableInvoice.currency,
      type: 'SALES',
      salesInvoice:
        editableInvoice.dueAmount > 0 ? { ...editableInvoice } : undefined,
      salesReturn: editableInvoice.dueAmount > 0 ? data : undefined
    };
    setShowCNAndDNInfo({
      showPopup: true,
      data: record,
      documentType: DOC_TYPE.CREDIT_NOTE,
      isTouched: true
    });
  };

  const getDateRangeString = () => {
    if (filterDates) {
      return (
        DateFormatService.getFormattedDateString(
          filterDates.startDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ) +
        ' to ' +
        DateFormatService.getFormattedDateString(
          filterDates.endDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      );
    } else {
      return '';
    }
  };

  const getDateRangePicker = () => {
    return (
      <div
        className={`position-absolute bg-white z-10 top-12 ${
          isDesktop ? 'right-36' : ''
        }`}
      >
        <ResponsiveDateRangePicker
          className=" border-radius-s shadow-m border-box"
          color={'rgb(56, 79, 190)'}
          onClose={() => {
            setShowHideCalendar(false);
          }}
          isDateRange={true}
          showPresetList={true}
          selectedDate={new Date()}
          startDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedStartDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedEndDate={DateFormatService.getDateFromStr(
            filterDates.endDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                startDate: DateFormatService.getDateStrFromDate(
                  startDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                endDate: DateFormatService.getDateStrFromDate(
                  endDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              });
              SessionStorageService.saveConfigToStorage(
                MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
                DOC_TYPE.INVOICE,
                {
                  [FILTER_DATE]: {
                    start: startDate,
                    end: endDate
                  }
                }
              );
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const showImportOption = () => {
    return (
      <DKListPicker
        data={importOptions}
        className="shadow-m absolute z-10"
        width={130}
        onSelect={(index: any, value: any) => {
          switch (value) {
            case 'Invoice':
            case 'Default Invoice':
              RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.INVOICE);
              break;
            case 'Get Paid':
              RouteManager.navigateToPage(
                IMPORT_CONSTANTS.ROUTE.RECEIVE_PAYMENT
              );
              break;
            case 'QB Invoice':
              RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.QB_INVOICE);
              break;
            case 'Fulfillment':
              RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.FULFILLMENT);
              break;
            case 'Delivery Order':
              RouteManager.navigateToPage(
                IMPORT_CONSTANTS.ROUTE.DELIVERY_ORDER
              );
              break;
            default:
              if (Array.isArray(customImportFormats)) {
                const customFormat = customImportFormats.find(
                  (cf) => cf.formatName === value
                );
                if (customFormat) {
                  RouteManager.navigateToPage(
                    `import-portal?module=books_invoice&submodule=${customFormat.formatId}&origin=/invoices`
                  );
                }
              }
              break;
          }
        }}
        onClose={() => {
          setShowImportOptions(false);
        }}
      />
    );
  };

  const getHeaderAction = () => {
    let buttons: any[] = [];
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.NEW,
        { ...editableInvoice },
        DOC_TYPE.INVOICE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.CREATE
      )
    ) {
      buttons.push({
        title: `+ ${t(`INVOICES.NEW_INVOICES`)}`,
        className: 'bg-button border-blue text-white ml-r',
        onClick: () => {
          if (!isOpeningForm) {
            addRecordClicked();
          }
        }
      });
    }
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.NEW,
        { ...editableInvoice },
        DOC_TYPE.INVOICE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.CREATE
      )
    ) {
      buttons.push({
        title: `+ Cash Invoice`,
        className: 'bg-chip-blue text-blue border-blue ml-r',
        onClick: () => {
          if (!isOpeningForm) {
            addRecordClicked(true);
          }
        }
      });
    }

    /* ********** INVOICE COLUMNS DISPLAY TOGGLE ********** */
    buttons.push(COLUMN_VISIBILITY_BUTTON_CONFIG);

    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.EXPORT,
        { ...editableInvoice },
        DOC_TYPE.INVOICE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.EXPORT
      )
    ) {
      buttons.push({
        className: 'border-m bg-white ml-r position-relative',
        icon: DKIcons.ic_export,
        onClick: () => {
          setShowExportOptions(true);
        }
      });
    }

    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.IMPORT,
        { ...editableInvoice },
        DOC_TYPE.INVOICE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.IMPORT
      ) &&
      !props.listByContact
    ) {
      buttons.push({
        icon: DKIcons.ic_import,
        className: 'border-m bg-white ml-r',
        onClick: () => {
          setShowImportOptions(true);
        }
      });
    }

    buttons.push({
      icon: DKIcons.ic_settings,
      className: 'border-m bg-white ml-r settings-btn display-only-web',
      onClick: () => {
        setShowSettingsPopup((prevValue) => !prevValue);
      }
    });
    buttons.push({
      title: isDesktop && !isTabletView() ? getDateRangeString() : '',
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => {
        setShowHideCalendar(true);
      }
    });
    return buttons;
  };

  const sortInvoices = (data: any) => {
    let oldConfig: any = InvoiceService.apiConfig;
    let config: InvoiceAPIConfig = {
      ...InvoiceService.apiConfig,
      Query:
        `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}` +
        (oldConfig.WithoutFilter ? `,${oldConfig.WithoutFilter}` : ''),
      QueryParam: `&sort=${data.columnData.key}&sortDir=${data.order}&${
        InvoiceService.apiConfig.WithFilter ?? ''
      }`, // Books backend pagination offset is 0, so subtract 1,
      Sort: data.columnData.key,
      SortDir: data.order
    };

    if (data.columnData.key === 'totalAmount') {
      config = {
        ...InvoiceService.apiConfig,
        Query:
          `salesInvoiceDate>=${filterDates.startDate},salesInvoiceDate<=${filterDates.endDate}` +
          (oldConfig.withoutFilter ? `,${oldConfig.withoutFilter}` : ''),
        QueryParam: `&sort=totalAmountInBaseCurrency&sortDir=${data.order}&${
          InvoiceService.apiConfig.WithFilter ?? ''
        }`,
        Sort: `totalAmountInBaseCurrency`,
        SortDir: data.order
      };
    }
    InvoiceService.apiConfig = config;
    loadInvoices(config);
  };

  const onApproveDraft = async (data: any, status: any, remarks: string) => {
    if (isApprovalInprogress === false) {
      const rowData = data.rowData;
      if (rowData.isDraftDocument) {
        const draftIds = [rowData.id];

        setIsApprovalInprogress(true);
        setUpdating(true);
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.INVOICE,
            status,
            remarks
          );
        } catch (e) {
        } finally {
          setIsApprovalInprogress(false);
          setUpdating(false);
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.INVOICES
            })
          );
          loadInvoices({});
        }
      }
    }
  };

  const bulkApprovalClick = async (data: any, remarks?: string) => {
    if (bulkApprovalRows?.length) {
      setUpdating(true);

      const draftIds = bulkApprovalRows
        .filter((data) => data.isDraftDocument)
        ?.map((data) => data.id);
      if (draftIds?.length) {
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.INVOICE,
            APPROVAL_STATUS.APPROVED,
            remarks
          );
        } catch (e) {
        } finally {
          setBulkRejectRows([]);
          setBulkApprovalRows([]);
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.INVOICES
            })
          );
          loadInvoices({});
          setUpdating(false);
        }
      }
    }
  };

  const bulkPendingEInvoiceClick = (data: any) => {
    generateEInvoiceIndia(data, true);
  };

  const bulkGeneratedEInvoiceClick = (data: any) => {
    cancelEInvoiceIndia(data, true);
  };

  const bulkRejectClick = async (data: any, remarks?: string) => {
    if (bulkRejectRows?.length) {
      setUpdating(true);

      const draftIds = bulkRejectRows
        .filter((data) => data.isDraftDocument)
        ?.map((data) => data.id);
      if (draftIds?.length) {
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.INVOICE,
            APPROVAL_STATUS.REJECTED,
            remarks
          );
        } catch (e) {
        } finally {
          setBulkRejectRows([]);
          setBulkApprovalRows([]);
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.INVOICES
            })
          );
          loadInvoices({});
          setUpdating(false);
        }
      }
    }
  };

  const showMainDocumentGridSettings = () => {
    return (
      <MainDocumentGridSettings
        showTotalInBaseCurrency={showTotalInBaseCurrency}
        onClose={() => setShowSettingsPopup(false)}
        onShowTotalInBaseCurrencyChange={(value: boolean) => {
          setShowTotalInBaseCurrency((prevValue) => {
            SessionStorageService.saveConfigToStorage(
              MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
              DOC_TYPE.INVOICE,
              {
                [SHOW_TOTAL_IN_BASE_CURRENCY]: value
              }
            );
            return value;
          });
        }}
      />
    );
  };

  const getUpdateOrgPopup = () => {
    const popupConfigForOrganisationProfile: BtnType[] = [
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.UPDATE`),
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
      }
    ];
    return showUpdateOrgPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Update ${getCapitalized(
          localizedText('organisation')
        )} Profile`}
        btnList={popupConfigForOrganisationProfile}
        width={!isDesktop ? '80%' : '40%'}
        height={!isDesktop ? '75%' : '95%'}
        disableClickOutside
      >
        <OrganisationProfileForm
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          activeTab={ORGANISATION_FORM_TAB.COMPLIANCE}
        />
      </PopupWrapper>
    ) : null;
  };

  const getPaymentMilestoneFormPopup = () => {
    const paymentMilestonePopupBtnConfig: BtnType[] = [
      {
        title: 'Cancel',
        class: 'bg-gray1 border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-s',
        clickAction: POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE
      }
    ];

    const loadingPaymentMileStoneBtnConfig: LoadingBtnType[] = [
      {
        title: 'Cancel',
        class: 'border-m mr-s',
        type: 'CLOSE'
      },
      {
        title: 'Saving',
        class: 'bg-app text-white mr-ss',
        type: 'ACTION'
      }
    ];

    const { originalData } = invoices;
    let invoice = originalData.find(
      (item: any) =>
        item.documentSequenceCode === editableInvoice.documentSequenceCode
    );
    const isReadOnly = getIsReadOnly({
      ...invoice,
      fulfillmentStatus: invoice.fulfillmentStatus[0],
      paymentStatus: invoice.paymentStatus[0]
    });

    return (
      <PopupWrapper
        disableClickOutside={true}
        clickAction={catchClicks}
        btnList={
          !isReadOnly
            ? paymentMilestonePopupBtnConfig
            : [paymentMilestonePopupBtnConfig[0]]
        }
        loadingBtnList={loadingPaymentMileStoneBtnConfig}
        isLoading={paymentMilestoneBeingSaved}
        type={POPUP_TYPE.POPUP}
        title={`${isReadOnly ? 'View' : 'Add'} payment milestones`}
        height={'60%'}
        width={'60%'}
      >
        <PaymentMileStone
          document={{
            ...invoice,
            totalAmount: invoice.totalAmountUnformatted,
            documentNumber: invoice.salesInvoiceCode,
            documentType: DOC_TYPE.INVOICE
          }}
          allowEdit={!isReadOnly}
          onCancel={() => {
            setShowPaymentMilestonePopup(false);
            loadInvoices({});
          }}
          onSave={() => {
            setShowPaymentMilestonePopup(false);
            loadInvoices({});
          }}
          onError={() => {
            setPaymentMilestoneBeingSaved(false);
          }}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };

  return (
    <div
      className={`column parent-width main-holder-padding padding-bottom-50 ${
        props.listByContact ? 'flex-1' : ''
      }`}
      style={
        props.listByContact
          ? {
              paddingLeft: 4
            }
          : {}
      }
    >
      {getInvoiceSummaryView()}
      <div
        className={`column parent-width mb-s position-relative flex-1 ${
          props?.listByContact ? 'invoice-data-grid' : ''
        }`}
      >
        <div className="position-absolute" style={{ right: 300, top: 45 }}>
          {showExportOptions && showExportOptionsList()}
        </div>
        <div className="position-absolute" style={{ right: 350, top: 45 }}>
          {showImportOptions && showImportOption()}
        </div>
        <div
          className="position-absolute z-index-3"
          style={{ right: 135, top: 45 }}
        >
          {showSettingsPopup && showMainDocumentGridSettings()}
        </div>
        {showHideCalendar && getDateRangePicker()}
        <DataGridHolder
          headerButtons={getHeaderAction()}
          tableName={TABLES.INVOICE}
          displayTableName={t(`INVOICES.` + TABLE_DISPLAY_NAME[TABLES.INVOICE])}
          filterTableName={REMOTE_CONFIG_TABLES.INVOICE}
          gridIcon="📋"
          needBoldTheme={isDesktop}
          needBorder={true}
          needShadow={false}
          updating={updating || loadingStatus === API_STATUS.LOADING}
          actionColumnWidth={250}
          extraWidth={props.listByContact ? CONTACT_LEFT_PANEL_WIDTH : 0}
          allowRowEdit={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.CREATE
          )}
          allowColumnEdit={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.CREATE
          )}
          allowRowAdd={true}
          allowColumnAdd={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.CREATE
          )}
          allowColumnDelete={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.DELETE
          )}
          allowColumnShift={true}
          allowColumnVisibilityToggle={true}
          allowSearch={!props.listByContact}
          allowFilter={true}
          onRowDelete={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.DELETE
            )
              ? onInvoiceDelete
              : undefined
          }
          onRowEdit={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.EDIT
            )
              ? onInvoiceEdit
              : undefined
          }
          allowDataExport={true}
          gridData={invoices}
          onSearch={(term: string) => {
            setSearchTerm(term);
          }}
          onColumnAddClick={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.CREATE
            )
              ? onAddCustomFieldClicked
              : () => {}
          }
          onColumnUpdate={onColumnUpdated}
          onColumnShift={onColumnShifted}
          onFilter={onFilter}
          filter={Utility.checkFilterTypeDate(invoices.filter)}
          onRowAdd={onRowAdd}
          onPaginationClick={(requestedPageNumber: number) => {
            const config: InvoiceAPIConfig = {
              ...InvoiceService.apiConfig,
              Page: requestedPageNumber - 1 // Books backend pagination offset is 0, so subtract 1
            };
            setFilterDataInStore(
              { ...config },
              invoiceFilterData?.SearchTerm,
              deepClone(invoiceFilterData?.queries)
            );
          }}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          onPayment={receivePayment}
          paymentRecords={getPaymentRecords}
          onFulfillment={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.FULFILL
            )
              ? fulfillment
              : undefined
          }
          onColumnDelete={columnDelete}
          onRowUpdate={rowUpdate}
          onRowSelect={(selectedRows) => {
            const selectedRowIds =
              selectedRows?.map((row: any) => row.salesInvoiceCode) || [];
            setSelectedRows(selectedRowIds);
            setBulkFulfillmentRecords(selectedRowIds);
            setBulkPaymentRecords(selectedRowIds);
            setCurrentSelectedRows(selectedRows);
            setBulkApprovalRecords(selectedRows);
            setBulkRejectRecords(selectedRows);
            setBulkPendingEInvoiceRecords(selectedRows);
            setBulkGeneratedEInvoiceRecords(selectedRows);
            setBulkEWBRows(
              selectedRows.filter((item: any) => !item.ewayGenerated)
            );
            updateBulkPrintRecord(selectedRows);
          }}
          onRowOpenClick={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.EDIT
            )
              ? onInvoiceEdit
              : undefined
          }
          onAllRowSelect={rowAllSelected}
          offsetPayment={offSetPayment}
          onBulkFulfillment={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.FULFILL
            )
              ? bulkFulfillment
              : undefined
          }
          bulkPaymentRows={bulkPaymentRows}
          bulkFulfillmentRows={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.FULFILL
            )
              ? bulkFulfillmentRows
              : undefined
          }
          onBulkPayment={bulkPaymentClick}
          onBulkPrint={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.PRINT
            )
              ? onBulkPrintlick
              : undefined
          }
          bulkPrintRows={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.INVOICE.PRINT
            )
              ? bulkPrintRecordData
              : undefined
          }
          allowBulkOperation={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.INVOICE.CREATE
          )}
          allowColumnSort={true}
          onSort={(data: any) => sortInvoices(data)}
          bulkApprovalRows={bulkApprovalRows}
          onBulkApproval={bulkApprovalClick}
          bulkRejectRows={bulkRejectRows}
          onBulkReject={bulkRejectClick}
          bulkEWBRows={bulkEWBRows}
          onBulkEWBGenerate={() => {
            if (Utility.canGenerateEWayBill()) {
              setShowCreateEWayBill(true);
            } else {
              updateComplianceSettings();
            }
          }}
          bulkPendingEInvoicesRows={bulkPendingEInvoicesRows}
          onBulkPendingEInvoice={bulkPendingEInvoiceClick}
          bulkGeneratedEInvoicesRows={bulkGeneratedEInvoicesRows}
          onBulkGeneratedEInvoice={bulkGeneratedEInvoiceClick}
          needNoDataView={
            !invoicesData.content?.length || !invoiceDraftData?.length
          }
          noDataTitle="No invoices found"
          noDataText="Create your first invoice now"
          searchBarConfig={{ searchText: searchTerm }}
          permissionModule={PERMISSIONS_BY_MODULE.INVOICE}
          onRowClick={(data: any) => {}}
          allowAIOps={true}
          defaultAICommand="Show me all overdued invoices"
          onTriggerAIRequest={(command: string) =>
            createAndHandleAIRequest(command)
          }
        />
      </div>
      {showPaymentPopup && (
        <PopupWrapper
          isLoading={popupLoading}
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.RECEIVE_PAYMENT.TITLE`)}
          btnList={paymentPopupBtnConfig}
          loadingBtnList={loadingPaymentPopupBtnConfig}
          maxWidth={!isDesktop ? '80%' : '800px'}
          maxHeight={'90%'}
          disableClickOutside={true}
        >
          <Payment
            documentDetails={{ ...editableInvoice }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}

      {showPaymentRecord && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.PAYMENT_RECORDS.TITLE`)}
          btnList={paymentRecordsBtnConfig}
          width={!isDesktop ? '90%' : '50%'}
          disableClickOutside={true}
          maxHeight={'90%'}
          showScrollBar={true}
        >
          <PaymentRecords
            documentSequenceCode={salesDocumentSequenceCode}
            paymentRecordList={paymentRecords}
            documentType={DOC_TYPE.INVOICE}
            documentCode={salesInvoiceCode}
            contactCode={editableInvoice.contactCode}
            isDocumentEmailFlow={true}
            closePopup={() => {
              setShowPaymentRecord(false);
            }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            contactId={paymentContactId}
          />
        </PopupWrapper>
      )}

      {showOffsetPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.OFFSET_PAYMENT.TITLE`)}
          btnList={offSetBtnConfig}
          width={!isDesktop ? '80%' : 'auto'}
          minWidth={'35%'}
        >
          <OffsetPopup
            documentData={{ ...editableInvoice }}
            documentType={DOC_TYPE.INVOICE}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}

      {showFulfillmentConfirmationPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Fulfill Your Invoice')}
          width={!isDesktop ? '80%' : '460px'}
          height={'auto'}
          overflowVisible={true}
          disableClickOutside={true}
          btnList={fulfillmentConfirmationPopupBtnConfig}
        >
          <FulfillmentConfirmation
            documentDetails={{ ...editableInvoice }}
            fulfillmentType={editableInvoice?.fulfillmentType}
            passingInteraction={(callback: CallBackPayloadType, data: any) => {
              setEditableInvoice(data);
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}

      {showDropshipConfirmationPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Fulfill Your Invoice')}
          width={!isDesktop ? '80%' : '460px'}
          height={'auto'}
          overflowVisible={true}
          disableClickOutside={true}
          btnList={fulfillmentConfirmationPopupBtnConfig}
        >
          <DropshipConfirmation
            documentDetails={{ ...editableInvoiceForDropship }}
            fulfillmentType={editableInvoice?.fulfillmentType}
            passingInteraction={(callback: CallBackPayloadType, data: any) => {
              setEditableInvoice(data);
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowDropshipConfirmationPopup(false);
            }}
            openFulfillmentPopup={() => {
              setisFulfillmentForDropship(false);
              setShowDropshipConfirmationPopup(false);

              setShowFulfillmentConfirmationPopup(true);
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}

      {showFulfillmentPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Direct Fulfillment of Invoice')}
          height={'auto'}
          minHeight={'50%'}
          width={!isDesktop ? '90%' : '100%'}
          disableClickOutside={true}
          btnList={fulfillmentPopupBtnConfig}
        >
          <Fulfillment
            documentDetails={{ ...editableInvoice }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            documentType={DOC_TYPE.INVOICE}
            isFulfillmentForDropship={isFulfillmentForDropship}
          />
        </PopupWrapper>
      )}
      {showMultiViewJournalEntry && (
        <MultiViewJournalEntry
          data={multiViewJournalEntryData}
          jeCode={multiViewJournalEntryData?.journalEntryCode}
          documentType={DOC_TYPE.INVOICE}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onCancel={() => setShowMultiViewJournalEntry(false)}
        />
      )}

      {showDropShipWarningPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.DROPSHIP.TITLE`)}
          btnList={dropShipButtonConfig}
          width={!isDesktop ? '80%' : '600px'}
          height={'auto'}
        >
          <DropshipWarning
            document={{ ...editableInvoice }}
            passingInteraction={(callback: CallBackPayloadType, data) => {
              parentChildInteraction(callback);
              setEditableInvoice(data);
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}

      {showBulkFulfillmentPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`Bulk ${localizedText('Fulfillment')} of Invoices`}
          height={'auto'}
          minHeight={'60%'}
          disableClickOutside={true}
          width={!isDesktop ? '80%' : '90%'}
          maxWidth={'1100px'}
          btnList={bulkFulfillmentPopupBtnConfig}
        >
          <BulkFulillment
            documentDetails={bulkFulfillmentDetails}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={DOC_TYPE.INVOICE}
            refreshFulfillmentDetails={() => {
              FulfillmentService.getInvoiceBulkFulfillmentDetails(
                selectedRows
              ).then((data: any) => {
                setBulkFulfillmentDetails(data);
                setEditableInvoice(data[0]);
                setShowBulkFulfillmentPopup(true);
              });
            }}
          />
        </PopupWrapper>
      )}

      {showFulfillmentRecordsPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={fulfillmentType}
          width={!isDesktop ? '90%' : '65%'}
          btnList={fulfillmentRecordsBtnConfig}
          disableClickOutside={true}
          showScrollBar={true}
        >
          <FulfillmentRecords
            documentDetails={{ ...editableInvoice }}
            fulfillmentDetails={fulfillmentDetails}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            defaultCategory="Fulfillment"
            isDeleted={(val: boolean) => {
              if (val) {
                loadInvoices({});
              }
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
      {showSalesReturnPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Sales Return'}
          height={'auto'}
          minHeight={'50%'}
          width={!isDesktop ? '90%' : '100%'}
          disableClickOutside={true}
          btnList={salesReturnPopupBtnConfig}
        >
          <SalesReturnPopup
            documentDetails={{ ...editableInvoice }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowSalesReturnPopup(false);
            }}
            isClosed={(val: boolean, data: any) => {
              if (val) {
                createDocForReturn(data);
              }
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
      {showSalesReturnRecordsPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Sales Returns Records'}
          width={!isDesktop ? '80%' : '60%'}
          btnList={fulfillmentRecordsBtnConfig}
          disableClickOutside={true}
        >
          <SalesReturnRecords
            documentDetails={{ ...editableInvoice }}
            salesReturnsDetails={salesReturnsDetails}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isDeleted={(val: boolean) => {
              if (val) {
                loadInvoices({});
              }
            }}
            isClosed={(val: boolean) => {
              if (val) {
                setShowSalesReturnRecordsPopup(false);
              }
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
      {showRecurringPopup && (
        <RecurringDocument
          documentDetails={{ ...editableInvoice }}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          documentType={DOC_TYPE.INVOICE}
          emailTemplateConfig={invoiceEmailTemplateConfig}
          closePopup={() => setShowRecurringPopup(false)}
        />
      )}
      {showBulkPaymentPopup && (
        <BulkPayment
          documentType={DOC_TYPE.INVOICE}
          documents={bulkPaymentRows}
          closePayment={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      )}
      {showAttachmentPopup && !Utility.isEmpty(editableInvoice) && (
        <Attachments
          documentDetails={{ ...editableInvoice }}
          documentType={DOC_TYPE.INVOICE}
          onClose={(docId: any, updatedAttachments: any[]) => {
            const docList: any[] = invoicesData['content'];
            const docData = docList.filter((doc) => doc.id === docId)[0];
            let refreshList = false;
            if (
              docData.attachmentsWithLink?.length !== updatedAttachments?.length
            ) {
              refreshList = true;
            }
            if (!refreshList && docData) {
              for (let attachment of updatedAttachments) {
                const existingAttachment = docData.attachmentsWithLink?.find(
                  (att: any) => att.attachmentId === attachment.attachmentId
                );
                if (Utility.isEmpty(existingAttachment)) {
                  refreshList = true;
                  break;
                }
              }
            }
            if (refreshList) {
              loadInvoices({});
            }
            setShowAttachmentPopup(false);
          }}
        />
      )}
      {showIRPCredPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Enter your IRP Credentials'}
          btnList={popupBtnConfigForEInvoice}
          isLoading={popupLoading}
          loadingBtnList={loadingIRPCredPopupBtnConfig}
          width={!isDesktop ? '80%' : '35%'}
          maxHeight={'95%'}
          disableClickOutside={true}
        >
          <IRPCredPopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            gstIn={tenantInfo.gstin}
            sessionOutMessage={true}
            onCancel={() => {
              setShowIRPCredPopup(false);
              if (IRPCredPopupType === INVOICE_NOW_TYPES.PENDING) {
                generateEInvoiceIndia({ ...editableInvoice }, false);
              } else if (IRPCredPopupType === INVOICE_NOW_TYPES.GENERATED) {
                cancelEInvoiceIndia({ ...editableInvoice }, false);
              }
            }}
          />
        </PopupWrapper>
      )}
      {showCancelEInvoice && (
        <CancelEInvoice
          data={{
            isBulkCancel: bulkGeneratedEInvoicesRows.length ? true : false,
            id: bulkGeneratedEInvoicesRows.length
              ? bulkGeneratedEInvoicesRows
              : editableInvoice.id,
            irn: bulkGeneratedEInvoicesRows.length
              ? bulkGeneratedEInvoicesRows
              : editableInvoice.einvoiceInfoIndia.irn
          }}
          onCancel={() => {
            setShowCancelEInvoice(false);
            const config: InvoiceAPIConfig = {
              ...InvoiceService.apiConfig
            };
            loadInvoices(config);
          }}
        />
      )}
      {showCreateEWayBill && (
        <AddEwayBill
          data={null}
          invoiceObjects={bulkEWBRows} //[selectedInvoice]}
          readOnly={false}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onSuccess={() => {
            setShowCreateEWayBill(false);
            loadInvoices({});
          }}
          onCancel={() => {
            setShowCreateEWayBill(false);
          }}
        />
      )}
      {showExpenseButton && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Direct Expense'}
          btnList={directExpenseBtnConfig}
          height={'98%'}
          allowFullScreen
        >
          <ExpenseDepositForm
            formType={MODULES_NAME.EXPENSE}
            populateData={expenseData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            allowFullScreen
          />
        </PopupWrapper>
      )}
      {showCNAndDNInfo.showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Credit Note'}
          disableClickOutside={true}
          readOnly={false}
          btnList={CNBtnConfig}
          isActionBtnDisabled={isCNActionBtnDisabled}
          height={'98%'}
          allowFullScreen
        >
          <CreateAndEditNotes
            documentMode={DOCUMENT_MODE.NEW}
            readOnly={false}
            populateFormData={showCNAndDNInfo.data}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            notesType={showCNAndDNInfo.documentType}
            showLinkedDocPopup={(data: any) => {}}
            allowFullScreen
          />
        </PopupWrapper>
      )}
      {showApprovalHistoryPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Approval/Rejection History'}
          height={'80%'}
          width={!isDesktop ? '80%' : '60%'}
          btnList={fulfillmentRecordsBtnConfig}
          disableClickOutside={true}
        >
          <ApprovalHistory
            documentDetails={{ ...editableInvoice }}
            approvalHistoryDetails={approvalHistoryData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isClosed={(val: boolean) => {
              if (val) {
                setShowApprovalHistoryPopup(false);
              }
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
      {showApprovalOrRejectPopup && (
        <ApprovalOrReject
          data={approvalOrRejectData}
          actionData={{
            isBulkAction: false,
            action: onApproveDraft
          }}
          setShowApprovalOrRejectPopup={setShowApprovalOrRejectPopup}
          title={`${approvalOrRejectData.title} Invoice`}
        ></ApprovalOrReject>
      )}

      {detailsPopupData?.showDetailsOpener && (
        <DetailsOpener
          data={detailsPopupData}
          onCancel={() => {
            setDetailsPopupData({
              showDetailsOpener: false
            });
          }}
        />
      )}
      {showRateAnalysisForm && rateAnalysisData && (
        <RateAnalysisForm
          data={rateAnalysisData}
          onCancel={() => {
            setShowRateAnalysisForm(false);
          }}
          onSave={() => {
            setShowRateAnalysisForm(false);
          }}
        />
      )}
      {showLinkRecordsPopup && (
        <LinkedRecordsPopup
          data={invoiceLinkRecordsData}
          isDocumentEmailFlow={true}
          documentType={DOC_TYPE.INVOICE}
          onCancel={() => setShowLinkRecordsPopup(false)}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          isDeleted={(val: boolean) => {
            if (val) {
              loadInvoices({});
            }
          }}
        />
      )}
      {getUpdateOrgPopup()}
      {showPaymentMilestonePopup && getPaymentMilestoneFormPopup()}
      {generateLabelPopup && (
        <GenerateLabelPopup
          onCancel={() => setGenerateLabelPopup(false)}
          documentType={LABEL_DOCUMENT_TYPE.SALES_INVOICE}
          documentData={{ ...editableInvoice }}
        />
      )}
      {showAddWorkOrderPopup && (
        <AddNewWorkOrder
          salesInvoice={selectedInvoice}
          workOrder={selectedWO ?? null}
          continueInEditMode={(res: any, selectedTabIndex = 0) => {
            setShowAddWorkOrderPopup(false);
            let selectedWorkOrder = Array.isArray(res)
              ? res?.[selectedTabIndex]
              : res;
            setSelectedInvoice(null);
            setSelectedWO(selectedWorkOrder); // because in edit mode, WO document is no longer a sales order created document.
            setShowAddWorkOrderPopup(true);
          }}
          onClose={() => {
            setSelectedInvoice(null);
            setShowAddWorkOrderPopup(false);
          }}
          onSave={() => {
            setSelectedInvoice(null);
            setShowAddWorkOrderPopup(false);
          }}
          onGotoClick={() => {
            setShowAddWorkOrderPopup(false);
            RouteManager.navigateToPage(PAGE_ROUTES.MRP_WORK_ORDER);
          }}
        />
      )}
      {showEinvoiceStatusHistoryPopup.show && (
        <EinvoiceStatusHistoryPopup
          // @ts-ignore
          data={showEinvoiceStatusHistoryPopup.data}
          onCancel={() => {
            setShowEinvoiceStatusHistoryPopup({
              show: false,
              data: []
            });
          }}
        />
      )}

      {cancelEwayBillErrorLogs.show && (
        <EWBErrorLogs
          errorLogs={cancelEwayBillErrorLogs.logs}
          onCancel={() => setCancelEwayBillErrorLogs({ show: false, logs: [] })}
        />
      )}

      {viewReservedQuantityPopup && (
        <ViewAdvancedTrackingReservedData
          selectedRowData={selectedRowForReservedData}
          documentType={DOC_TYPE.INVOICE}
          onCancel={() => {
            setViewReservedQuantityPopup(false);
          }}
        />
      )}
    </div>
  );
}
