import {
  DKButton,
  DKIcons,
  DKInput,
  DKLabel,
  DKListPicker,
  DKSpinner,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert
} from 'deskera-ui-library';
import { Fragment, useEffect, useState, useMemo } from 'react';
import { Prompt } from 'react-router';
import { LABELS } from '../../Constants/Constant';
import RouteManager from '../../Managers/RouteManager';
import CustomFieldService from '../../Services/CustomField';
import LabelTemplateService from '../../Services/LabelTemplates';
import Utility, { deepClone } from '../../Utility/Utility';
import CreateLabelTemplate from './CreateLabelTemplate';
import EditLabelTemplate from './EditLabelTemplate';
import {
  LABEL_DOCUMENT_TYPE,
  LabelCell,
  LabelRow,
  LabelTemplateData
} from './LabelTemplateHelper';
import LabelTemplateSettings from './LabelTemplateSettings';
import LabelTemplateView from './LabelTemplateView';

export default function LabelTemplate(props: any) {
  const [showSavePicker, setShowSavePicker] = useState(false);
  const [labelTemplate, setLabelTemplate] = useState<LabelTemplateData>();
  const [cellToEdit, setCellToEdit] = useState<LabelCell>();
  const [createTemplatePopup, setCreateTemplatePopup] = useState(false);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [documentType, setDocumentType] = useState<any>();
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cfList, setCFList] = useState<any>({});
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isReorderingRows, setIsReorderingRows] = useState(false);

  const documentTypeOptions = useMemo(() => {
    const options = [
      { value: LABEL_DOCUMENT_TYPE.SALES_ORDER, title: LABELS.SALES_ORDER },
      { value: LABEL_DOCUMENT_TYPE.SALES_INVOICE, title: LABELS.INVOICES },
      {
        value: LABEL_DOCUMENT_TYPE.PURCHASE_ORDER,
        title: LABELS.PURCHASE_ORDERS
      },
      { value: LABEL_DOCUMENT_TYPE.PURCHASE_INVOICE, title: LABELS.BILLS }
    ];

    if (Utility.isMRPWithURLCheck()) {
      return [
        ...options,
        {
          value: LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING,
          title: LABELS.WORK_ORDER_LABEL_PRINTTING
        }
      ];
    }
    return options;
  }, []);

  useEffect(() => {
    // fetch templates
    loadLabelTemplate();
  }, []);

  useEffect(() => {
    if (documentType?.value) {
      loadCustomFields();
    }
  }, [documentType]);

  let saveOptions = ['Save as', 'Save & close'];

  let loadCustomFields = () => {
    let modules = [
      'PRODUCT',
      'INVOICE',
      'SALESORDER',
      'ORDER',
      'BILL',
      'BATCHSERIAL',
      'WORKORDER'
    ];
    CustomFieldService.getCustomFieldsByModules(modules)?.then((cfList) => {
      setCFList(cfList);
    });
  };

  let loadLabelTemplate = () => {
    setIsLoading(true);
    LabelTemplateService.getLabelTemplate()
      .then((res: any) => {
        if (res.length) {
          let parsedData = res?.map((item: any) => ({
            ...item,
            templateData: JSON.parse(item.templateData),
            labelSettings: JSON.parse(item.labelSettings)
          }));
          setTemplateList(parsedData);
          let matchingTemplate = parsedData.find((template: any) =>
            documentTypeOptions.some(
              (option: any) => option.value === template.documentType
            )
          );
          if (matchingTemplate) {
            setLabelTemplate(matchingTemplate);
            let docType = documentTypeOptions.find(
              (option: any) => option.value === matchingTemplate.documentType
            );
            setDocumentType(docType);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  let updateRow = (rowIndex: number, updatedRow: LabelRow) => {
    if (labelTemplate) {
      setLabelTemplate((labelTemplate: any) => {
        let updatedData = { ...labelTemplate };
        updatedData.templateData[rowIndex] = { ...updatedRow };
        return updatedData;
      });
      setIsUpdated(true);
    }
  };

  let deleteRow = (rowIndex: number) => {
    if (labelTemplate && rowIndex < labelTemplate.templateData.length) {
      let updatedTemplateData = { ...labelTemplate };
      updatedTemplateData.templateData.splice(rowIndex, 1);
      setLabelTemplate(updatedTemplateData);
      setIsUpdated(true);
    }
  };

  let addNewTemplate = async (newTemplateData: any) => {
    setIsLoading(true);
    let newTemplate: LabelTemplateData | undefined = undefined;
    if (saveAsOpen) {
      if (labelTemplate) {
        newTemplate = deepClone(labelTemplate);
        newTemplate.templateName = newTemplateData.templateName;
        newTemplate.description = newTemplateData.description;
      }
    } else {
      newTemplate = deepClone(newTemplateData);
    }
    if (newTemplate) {
      await LabelTemplateService.saveLabelTemplate(newTemplate)
        .then((res) => {
          if (newTemplate) {
            newTemplate.id = res.id;
            setTemplateList((oldList: LabelTemplateData[]) => [
              ...oldList,
              newTemplate
            ]);
            setLabelTemplate(newTemplate);
            if (!saveAsOpen) {
              setCellToEdit(deepClone(newTemplate?.templateData[0].columns[0]));
            } else {
              setCellToEdit(undefined);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          showAlert('Failed to save label template data.');
        });
    }
    setCreateTemplatePopup(false);
    setSaveAsOpen(false);
    setIsLoading(false);
  };

  let updateLabelTemplate = async () => {
    if (labelTemplate?.id) {
      setIsLoading(true);
      await LabelTemplateService.updateLabelTemplate(labelTemplate)
        .then((res) => {})
        .catch((error) => {
          console.error(error);
          showAlert('Failed to save label template data.');
        })
        .finally(() => setIsLoading(false));
    }
  };

  let deleteLabelTemplate = () => {
    if (labelTemplate?.id) {
      let buttons = [
        {
          title: 'No',
          className: 'bg-gray2 border-m ',
          onClick: () => {}
        },
        {
          title: 'Yes',
          className: 'bg-red text-white ml-r',
          onClick: async () => {
            setIsLoading(true);
            await LabelTemplateService.deleteLabelTemplate(labelTemplate.id)
              .then((res) => {
                let updatedTemplateList = templateList.filter(
                  (item: any) => item.id !== labelTemplate.id
                );
                setTemplateList(updatedTemplateList);
                setLabelTemplate(undefined);
                setCellToEdit(undefined);
              })
              .catch((error) => {
                console.error(error);
                showAlert('Failed to delete label template.');
              })
              .finally(() => setIsLoading(false));
          }
        }
      ];
      showAlert(
        'Confirm Delete',
        'Are you sure, you want to delete this template?',
        buttons
      );
    }
  };

  let closeCreateTemplatePopup = () => {
    setCreateTemplatePopup(false);
    setSaveAsOpen(false);
  };

  return (
    <div className="column parent-size overflow-y-auto mb-r mt-xs">
      <Prompt
        message={'Are you sure to exit, all unsaved data will be removed.'}
        when={isUpdated}
      />
      <div className="row align-items-end justify-content-start bg-white p-3 border rounded mt-s gap-2">
        <div className="column">
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
            className={''}
            value={documentType}
            formatter={(obj: any) => {
              return obj.title;
            }}
            title="Document Type"
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => {
              setDocumentType(value);
              setLabelTemplate(undefined);
            }}
            dropdownConfig={{
              style: { minWidth: 250 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: documentTypeOptions,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj.title}`} />;
              }
            }}
          />
        </div>
        {documentType && (
          <div className="column">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
              className={''}
              value={labelTemplate}
              formatter={(obj: any) => {
                return obj.templateName;
              }}
              title="Label Template"
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => setLabelTemplate(value)}
              dropdownConfig={{
                title: 'Select Template',
                allowSearch: true,
                searchableKey: 'templateName',
                style: { minWidth: 250 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: templateList.filter(
                  (option: any) => option.documentType === documentType.value
                ),
                renderer: (index: any, obj: any) => {
                  return <DKLabel key={index} text={`${obj.templateName}`} />;
                },
                button: {
                  title: '+ Add New',
                  className: 'bg-button text-white',
                  onClick: () => setCreateTemplatePopup(true)
                }
              }}
            />
          </div>
        )}
        {documentType && labelTemplate && (
          <>
            <div className="column">
              <div
                className={`row border-radius-m justify-content-between ${
                  false ? 'border-m' : 'bg-button'
                } position-relative`}
                style={{ borderColor: false ? '' : 'transparent' }}
              >
                <div className={`row`}>
                  <DKButton
                    icon={DKIcons.white.ic_save}
                    title={'Save'}
                    onClick={updateLabelTemplate}
                    disabled={isLoading}
                    className={`border-radius-none ${
                      false ? 'text-gray' : 'text-white'
                    }`}
                  />
                </div>
                <Fragment>
                  <DKButton
                    icon={
                      false
                        ? DKIcons.ic_arrow_down2
                        : DKIcons.white.ic_arrow_down2
                    }
                    disabled={false}
                    className={`border-radius-none`}
                    onClick={() => setShowSavePicker(true)}
                  />
                  {showSavePicker ? (
                    <DKListPicker
                      data={saveOptions}
                      className="position-absolute z-index-3 border-m shadow-m"
                      style={{
                        top: 40,
                        right: 0,
                        width: 'max-content'
                      }}
                      onSelect={(index: number, title: string) => {
                        setShowSavePicker(false);
                        switch (index) {
                          case 0:
                            setSaveAsOpen(true);
                            break;
                          case 1:
                            updateLabelTemplate();
                            setCellToEdit(undefined);
                            setLabelTemplate(undefined);
                            break;
                        }
                      }}
                      onClose={() =>
                        setTimeout(() => setShowSavePicker(false), 100)
                      }
                    />
                  ) : null}
                </Fragment>
              </div>
            </div>
            <div className="column">
              <DKButton
                icon={DKIcons.white.ic_delete}
                title={'Delete'}
                onClick={deleteLabelTemplate}
                disabled={isLoading}
                className={`bg-red text-white`}
              />
            </div>
            <div className="column">
              <DKButton
                icon={DKIcons.ic_settings}
                title="Label Settings"
                className="border-m"
                onClick={() => setSettingsPopup(true)}
              />
            </div>
            {isLoading && (
              <div className="column width-auto mb-s">
                <DKSpinner />
              </div>
            )}
          </>
        )}
      </div>
      {labelTemplate ? (
        <div className="row parent-size align-items-start mt-m overflow-auto show-scroll-bar">
          <EditLabelTemplate
            labelTemplate={labelTemplate}
            setLabelTemplate={setLabelTemplate}
            setIsUpdated={setIsUpdated}
            cellToEdit={cellToEdit}
            setCellToEdit={setCellToEdit}
            documentType={documentType}
            cfList={cfList}
            isReorderingRows={isReorderingRows}
            setIsReorderingRows={setIsReorderingRows}
          />
          <div className="column">
            <LabelTemplateView
              labelTemplate={labelTemplate}
              updateRow={updateRow}
              cellToEdit={cellToEdit}
              setCellToEdit={setCellToEdit}
              deleteRow={deleteRow}
              isReorderingRows={isReorderingRows}
              setLabelTemplate={setLabelTemplate}
            />
          </div>
        </div>
      ) : (
        <div style={{ margin: 'auto' }}>No Template Selected</div>
      )}
      {(createTemplatePopup || saveAsOpen) && (
        <CreateLabelTemplate
          cancelPopup={closeCreateTemplatePopup}
          createTemplate={addNewTemplate}
          documentType={documentType?.value}
        />
      )}
      {settingsPopup && (
        <LabelTemplateSettings
          labelSettings={labelTemplate?.labelSettings}
          setLabelSettings={(settings: any) => {
            if (labelTemplate) {
              let updatedData = { ...labelTemplate };
              updatedData.labelSettings = settings;
              setLabelTemplate(updatedData);

              let updatedTemplateList: any[] = [...templateList];
              if (updatedTemplateList) {
                let index = updatedTemplateList?.findIndex(
                  (item: LabelTemplateData) => item.id === updatedData.id
                );
                updatedTemplateList[index] = updatedData;
                setTemplateList(updatedTemplateList);
              }
            }
          }}
          setShowPopup={setSettingsPopup}
        />
      )}
    </div>
  );
}
