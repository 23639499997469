import {
  DKButton,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useMemo, useRef, useState } from 'react';
import { deepClone } from '../../Utility/Utility';
import EditLabelCell from './EditLabelCell';
import {
  DefaultCellItem,
  FIELD_TYPE,
  LABEL_DOCUMENT_TYPE,
  LabelCell,
  LabelCellItem,
  LabelRow,
  LabelTemplateData,
  defaultCellItems
} from './LabelTemplateHelper';
import { DK_INPUT_TYPE } from '../../Models/Table';
import { IF } from '@formulajs/formulajs';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';

interface EditLabelTemplateProps {
  labelTemplate: LabelTemplateData;
  cellToEdit?: LabelCell;
  documentType: any;
  cfList: any;
  isReorderingRows: boolean;
  setLabelTemplate: (value: any) => void;
  setIsUpdated: (value: any) => void;
  setCellToEdit: (value: any) => void;
  setIsReorderingRows: (value: any) => void;
}

const EditLabelTemplate = ({
  labelTemplate,
  cellToEdit,
  documentType,
  cfList,
  isReorderingRows,
  setLabelTemplate,
  setIsUpdated,
  setCellToEdit,
  setIsReorderingRows
}: EditLabelTemplateProps) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isReordering, setIsReordering] = useState(false);
  const [elementToAdd, setElementToAdd] = useState<DefaultCellItem>();
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const getCFList = (): DefaultCellItem[] => {
    let cfCellList: DefaultCellItem[] = [];
    if (cfList) {
      let modules: string[] = [];

      switch (documentType.value) {
        case LABEL_DOCUMENT_TYPE.SALES_INVOICE:
          modules = ['INVOICE'];
          break;
        case LABEL_DOCUMENT_TYPE.SALES_ORDER:
          modules = ['SALESORDER'];
          break;
        case LABEL_DOCUMENT_TYPE.PURCHASE_ORDER:
          modules = ['ORDER'];
          break;
        case LABEL_DOCUMENT_TYPE.PURCHASE_INVOICE:
          modules = ['BILL'];
          break;
        case LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING:
          modules = ['BATCHSERIAL', 'WORKORDER'];
          break;
      }

      if (cfList?.PRODUCT?.length) {
        cfCellList = cfList.PRODUCT.map((cfItem: any) => ({
          key: cfItem.code,
          title: 'Product - ' + cfItem.label,
          value: cfItem.value || 'Value',
          type: cfItem.fieldType,
          isProductField: true,
          isCustomField: true
        }));
      }

      modules.forEach((module) => {
        if (module && cfList?.[module]?.length) {
          let cfTitle = documentType.title;
          if (
            documentType.value ===
            LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING
          ) {
            if (module === 'BATCHSERIAL') {
              cfTitle = 'Batch Serial';
            } else if (module === 'WORKORDER') {
              cfTitle = 'Work Order';
            }
          }

          const moduleCfList = cfList[module].map((cfItem: any) => ({
            key: cfItem.code,
            title: `${cfTitle} - ${cfItem.label}`,
            value: cfItem.value || 'Value',
            type: cfItem.fieldType,
            isProductField: false,
            isCustomField: true
          }));

          cfCellList.push(...moduleCfList);
        }
      });
    }
    return cfCellList;
  };

  const addNewRow = () => {
    if (labelTemplate) {
      let updatedTemplateData = { ...labelTemplate };

      let newRow: LabelRow = {
        columns: [
          {
            items: [],
            rowIndex: labelTemplate.templateData.length,
            colIndex: 0
          }
        ]
      };
      updatedTemplateData.templateData.push(newRow);
      setLabelTemplate(updatedTemplateData);
      setCellToEdit(deepClone(newRow.columns[0]));
      setIsUpdated(true);
    }
  };

  const addElement = () => {
    if (cellToEdit && elementToAdd) {
      let updatedCell = { ...cellToEdit };
      const newCell: LabelCellItem = {
        key: elementToAdd.key,
        label: elementToAdd.title,
        title: elementToAdd.title,
        value: elementToAdd.value,
        barcodePosition: 'Bottom',
        direction: 'Vertical',
        labelFontSize: 12,
        valueFontSize: 12,
        type: elementToAdd.type || FIELD_TYPE.TEXT,
        isCustomField: elementToAdd.isCustomField || false,
        isProductField: elementToAdd.isProductField || false,
        options: [] as any[],
        valueInternal: ''
      };
      const isDocumentTypeWorkOrderLabelPrinting =
        documentType.value === LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING;

      if (isDocumentTypeWorkOrderLabelPrinting) {
        const isCellItemBarcode =
          elementToAdd.type === FIELD_TYPE.SELECT_BARCODE_QR_CODE;
        const optionsOfBarcodeQrCode = elementToAdd.options || [];
        if (isCellItemBarcode) {
          newCell.options = optionsOfBarcodeQrCode;
        }
      }
      updatedCell.items.push(newCell);
      setCellToEdit({ ...updatedCell });
      setElementToAdd(undefined);
      setIsUpdated(true);
    }
  };

  const onCellItemUpdated = (itemIndex: number, updatedItem: LabelCellItem) => {
    if (cellToEdit) {
      let updatedCell = { ...cellToEdit };
      if (updatedCell.items && itemIndex < updatedCell.items?.length) {
        updatedCell.items[itemIndex] = updatedItem;
        setCellToEdit({ ...updatedCell });
        setIsUpdated(true);
      }
    }
  };

  const saveCellData = (saveFlag: boolean) => {
    if (
      saveFlag &&
      cellToEdit &&
      cellToEdit.rowIndex !== undefined &&
      cellToEdit.colIndex !== undefined &&
      labelTemplate
    ) {
      let updatedTemplateData = { ...labelTemplate };
      updatedTemplateData.templateData[cellToEdit.rowIndex].columns[
        cellToEdit.colIndex
      ] = {
        ...cellToEdit
      };
      setLabelTemplate(updatedTemplateData);
      setIsUpdated(true);
    }
    setIsReordering(false);
    setCellToEdit(undefined);
  };

  const deleteLabelItem = (itemIndex: number) => {
    if (cellToEdit) {
      let updatedCell = { ...cellToEdit };
      if (updatedCell.items && itemIndex < updatedCell.items?.length) {
        updatedCell.items.splice(itemIndex, 1);
        setCellToEdit({ ...updatedCell });
        setIsUpdated(true);
      }
    }
  };

  const dragStart = (position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    if (cellToEdit) {
      setCellToEdit((cellToEdit: LabelCell) => {
        let updatedCell = { ...cellToEdit };
        const dragItemContent = updatedCell.items[dragItem.current];
        updatedCell.items.splice(dragItem.current, 1);
        updatedCell.items.splice(dragOverItem.current, 0, dragItemContent);
        return updatedCell;
      });
      dragItem.current = null;
      dragOverItem.current = null;
    }
  };

  const moduleOptionsList = useMemo(() => {
    if (
      !documentType?.value ||
      !defaultCellItems ||
      typeof getCFList !== 'function'
    ) {
      return [];
    }

    const specificOptionsFields =
      defaultCellItems[documentType.value as keyof typeof defaultCellItems] ||
      [];
    const cfItems = getCFList() || [];

    const orgName = tenantInfo?.name || '';
    const orgEmail = tenantInfo?.contacts.email || '';

    const orgSpecificFields = [...defaultCellItems.ALL_ORG_TYPE];

    orgSpecificFields.forEach((item) => {
      if (item.key === 'orgName') {
        item.value = orgName;
      } else if (item.key === 'orgEmail') {
        item.value = orgEmail;
      }
    });

    if (documentType.value === LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING) {
      const barCodeQRCodeField = [
        ...specificOptionsFields,
        ...cfItems,
        ...orgSpecificFields
      ].find((item) => item.key === 'selectBarcodeQrCode');

      if (barCodeQRCodeField) {
        barCodeQRCodeField.options = specificOptionsFields.filter(
          (item) =>
            item.key !== 'selectBarcodeQrCode' && item.key !== 'selectImage'
        );
      }

      return [...specificOptionsFields, ...cfItems, ...orgSpecificFields];
    } else {
      const commonItems = defaultCellItems.ALL_TYPE || [];
      return [
        ...specificOptionsFields,
        ...commonItems,
        ...cfItems,
        ...orgSpecificFields
      ];
    }
  }, [documentType?.value, defaultCellItems, getCFList]);

  return (
    <div className="column bg-white border-m p-2 mr-2" style={{ width: 269 }}>
      <div className="flex parent-width gap-2 my-1 align-items-center justify-content-between">
        <DKButton
          icon={DKIcons.white.ic_plus}
          title="Add Row"
          className="bg-blue text-white"
          onClick={() => addNewRow()}
          disabled={!!cellToEdit}
        />
        {labelTemplate.templateData.length > 1 && (
          <DKButton
            title={isReorderingRows ? 'Done' : 'Reorder Rows'}
            className="border-m"
            icon={isReorderingRows ? DKIcons.ic_check_mark : DKIcons.ic_repeat}
            onClick={() => setIsReorderingRows((value: boolean) => !value)}
            disabled={!!cellToEdit}
          />
        )}
      </div>
      {cellToEdit && (
        <>
          <DKLabel text="Edit Row" className="fw-m mt-m mb-2 fs-m" />
          <div className="flex parent-width mb-1">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={''}
              value={cellToEdit.width}
              title="Row Width (in mm)"
              type={DK_INPUT_TYPE.NUMBER}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) =>
                setCellToEdit((data: LabelCell) => ({ ...data, width: value }))
              }
            />
          </div>
          <div className="flex parent-width mb-1">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={''}
              value={
                Number(cellToEdit.rowIndex)> -1 &&
                labelTemplate.templateData[Number(cellToEdit.rowIndex)].height
              }
              title="Row Height (in inch)"
              type={DK_INPUT_TYPE.NUMBER}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => {
                let updatedTemplateData = { ...labelTemplate };
                if (cellToEdit.rowIndex !== undefined) {
                  updatedTemplateData.templateData[cellToEdit.rowIndex].height =
                    value;
                }
                setLabelTemplate(updatedTemplateData);
              }}
            />
          </div>
          <div className="flex gap-2 align-items-end mb-1 parent-width">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={'parent-width'}
              value={elementToAdd}
              formatter={(obj: any) => {
                return obj.title;
              }}
              title="Add Element"
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => setElementToAdd(value)}
              dropdownConfig={{
                title: 'Select element',
                allowSearch: true,
                searchableKey: 'title',
                style: { minWidth: 250 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: moduleOptionsList,
                renderer: (index: any, obj: any) => {
                  return <DKLabel key={index} text={`${obj.title}`} />;
                }
              }}
            />
            <DKButton
              title="Add"
              className="bg-blue text-white"
              style={{ height: 'fit-content' }}
              onClick={addElement}
            />
          </div>
          <div className="flex parent-width align-items-end justify-content-between">
            <DKLabel text="Cell Items" className="fw-m mt-m mb-2 fs-m" />
            {cellToEdit?.items?.length > 1 && (
              <DKButton
                title={isReordering ? 'Done' : 'Reorder'}
                icon={isReordering ? DKIcons.ic_check_mark : DKIcons.ic_repeat}
                onClick={() => setIsReordering((value) => !value)}
              />
            )}
          </div>
          {cellToEdit?.items?.map((cell: any, index: number) => (
            <div
              key={index}
              draggable={
                isReordering &&
                (dragItem.current === null || dragItem.current === index)
              }
              onDragStart={() => dragStart && dragStart(index)}
              onDragEnter={(e) => dragEnter && dragEnter(index)}
              onDragEnd={() => drop && drop()}
              onDragOver={(e) => e.preventDefault()}
              style={{ cursor: isReordering ? 'move' : 'pointer' }}
            >
              <EditLabelCell
                data={cell}
                updateData={(updatedItem: any) =>
                  onCellItemUpdated(index, updatedItem)
                }
                deleteItem={() => deleteLabelItem(index)}
                isReordering={isReordering}
              />
            </div>
          ))}
          <div className="flex parent-width gap-2 mt-2">
            <DKButton
              title="Cancel"
              className="bg-white border-m parent-width parent-width"
              onClick={() => saveCellData(false)}
            />
            <DKButton
              title="Save"
              className="bg-blue border-m parent-width text-white parent-width"
              onClick={() => saveCellData(true)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EditLabelTemplate;
