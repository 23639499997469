import { FONT_SIZE, PRINT_SCALE } from "../Constants/Constants"
import ComponentManager from "../Manager/ComponentManager"
import CustomFieldManager from "../Manager/CustomFieldManager";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import NumberUtility from "./NumberUtility"
import Utility, { getIsAccountModule, getIsFulfillment, getIsGoodsReceipt, getIsMakePayment, getIsReceivePayment, getIsJournalEntry, getIsPackList, getIsPickList, getIsShipList, getIsBill, getIsInvoice, getIsOrder, getIsQuotation, getIsSalesOrder, getIsExpenseBill, getCapitalized, getConvertedDate, convertDateFromERPFormatToDocFormat, getIsEwayBill, getIsSOACustomer, getIsSOAVendor, getIsRequestForQuotation, getIsPurchaseInwardQuotation, getIsAgedReceivable} from './Utility';
export default class TopSectionAboveTableUtility {

    static getGlobalTopSectionAboveTableFontStyle() {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }

        if (style.topSectionBeforeTable === undefined || style.topSectionBeforeTable === null) {
            let font = TemplateSettingsManager.getDefaultTopSectionBeforeTableStyle()
            style.topSectionBeforeTable = font
        }

        if (style.topSectionBeforeTable.font === undefined || style.topSectionBeforeTable.font === null) {
            style.topSectionBeforeTable['font'] = TemplateSettingsManager.getDefaultTopSectionBeforeTableStyle()
        }

        return style
    }

    static getGlobalTopSectionAboveTableFontStyleWithProperty(fontStyle, property) {
        var style = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (fontStyle !== undefined) {
            style = fontStyle
        }
        if (style.font[property] !== undefined && style.font[property] !== null) {
            return style.font[property]
        }

        return undefined
    }

    static updateGlobalTopSectionAboveTableIsEnable(fontStyle) {
        var style = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (fontStyle !== undefined) {
            style = fontStyle
        }
        style.font.isEnable = !style.font.isEnable
        return style
        // TemplateSettingsManager.updateTemplateStyle(style)
    }


    static updateGlobalTopSectionAboveTableIsPaddingHorizontalEnable(fontStyle) {
        var style = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (fontStyle !== undefined) {
            style = fontStyle
        }
        style.font.isPaddingHorizontalEnable = !style.font.isPaddingHorizontalEnable
        return style
        // TemplateSettingsManager.updateTemplateStyle(style)
    }

    static updateGlobalTopSectionAboveTableIsPaddingVerticalEnable(fontStyle) {
        var style = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (fontStyle !== undefined) {
            style = fontStyle
        }
        style.font.isPaddingVerticalEnable = !style.font.isPaddingVerticalEnable
        return style
        // TemplateSettingsManager.updateTemplateStyle(style)
    }


    static updateGlobalTopSectionAboveTableProperty(fontStyle, property, value) {
        var style = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (fontStyle !== undefined) {
            style = fontStyle
        }
        if (property === 'style') {
            if (style.font.style === 'italic') {
                style.font.style = 'normal'
            }
            else {
                style.font.style = value
            }
        }
        else if (property === 'weight') {
            if (style.font.weight === 'bold') {
                style.font.weight = undefined
            }
            else {
                style.font.weight = value
            }
        }
        else if (property === 'color') {
            style.font.color = value
        }
        else if (property === 'border') {
            style.font.border = value
        }
        else if (property === 'borderColor') {
            style.font.borderColor = value
        }
        else if (property === 'borderWidth') {
            style.font.borderWidth = value
        }
        else if (property === 'paddingHorizontal') {
            style.font.paddingHorizontal = value
        }
        else if (property === 'paddingVertical') {
            style.font.paddingVertical = value
        }
        else {
            style.font[property] = value
        }
        return style
        // TemplateSettingsManager.updateTemplateStyle(style)
    }

    static updateGlobalTopSectionAboveTableFontSize(style, action) {
        var newStyle = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (style !== undefined) {
            newStyle = style
        }
        var fontSize = newStyle.font.size
        var size = 0.1
        var maxRange = 1
        var minRange = -0.5

        if (action === 'up') {
            fontSize = fontSize + size
            if (fontSize > maxRange) {
                fontSize = maxRange
            }
        }
        else if (action === 'down') {
            fontSize = fontSize - size
            if (fontSize < minRange) {
                fontSize = minRange
            }
        }

        return this.updateGlobalTopSectionAboveTableProperty(style, 'size', fontSize)
    }

    static updateGlobalTopSectionAboveTableDimensionByProperty(property) {
        var style = this.getGlobalTopSectionAboveTableFontStyle()
        if (style.topSectionBeforeTable.font[property] !== undefined && style.topSectionBeforeTable.font[property] !== null) {
            style.topSectionBeforeTable.font[property] = !style.topSectionBeforeTable.font[property]
        }
        TemplateSettingsManager.updateTemplateStyle(style)
    }

    static updateGlobalTopSectionAboveTablePadding(fieldName, action) {
        var style = this.getGlobalTopSectionAboveTableFontStyle()
        var paddingSize = style.topSectionBeforeTable.font[fieldName]
        var size = 0.05
        var maxRange = .5
        var minRange = -0.5

        paddingSize = Utility.calculateMaxOrMinSize(action, paddingSize, size, maxRange, minRange);

        this.updateGlobalTopSectionAboveTableProperty(fieldName, paddingSize)
    }

    static getTopSectionAboveTableFontSize(fontStyle, size, fontSize, printScale = PRINT_SCALE) {
        if (fontSize === undefined || fontSize === null) {
            return undefined
        }
        var additionalFontSize = this.getGlobalTopSectionAboveTableFontStyleWithProperty(fontStyle, 'size')
        if (size !== undefined) {
            additionalFontSize = size
        }
        var fontSizeNum = Number(fontSize.replace('vw', '')) + additionalFontSize
        if (TemplateSettingsManager.getPrintInfo() !== undefined) {
            var scale = fontSizeNum * printScale
            if (scale !== undefined && !isNaN(scale)) {
                return scale.toFixed(2) + 'vw'
            }
        }
        return fontSizeNum + 'vw'
    }

    static getTopSectionWithTableFontStyleProp(fontStyle, printScale) {

        var topSectionConfiguration = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        //this.state.topSectionConfiguration
        if (fontStyle !== undefined) {
            topSectionConfiguration = fontStyle
        }
        var newStyle = Utility.getFontStyleProp({}, printScale)
        if (topSectionConfiguration.font !== undefined && topSectionConfiguration.font !== null) {
            var font = topSectionConfiguration.font
            if (font.isEnable && font.isEnable !== null) {
                if (font.isEnable) {
                    if (font.size !== undefined && font.size !== null) {
                        var fontSize = font.fontSize
                        if (fontSize === undefined) {
                            fontSize = FONT_SIZE.regular
                        }
                        var newFontSize = TopSectionAboveTableUtility.getTopSectionAboveTableFontSize(fontStyle, font.size, fontSize, printScale)
                        if (newFontSize !== undefined) {
                            newStyle['fontSize'] = newFontSize
                        }
                    }

                    if (font.color !== undefined) {
                        newStyle['color'] = font.color
                    }

                    if (font.style !== undefined) {
                        newStyle['font-style'] = font.style
                    }

                    if (font.weight !== undefined) {
                        newStyle['font-weight'] = font.weight
                    }

                    if (font.family !== undefined) {
                        newStyle['font-family'] = font.family
                    }
                    if (font.color !== undefined && font.color !== null) {
                        newStyle.color = font.color
                    }
                }
            }
            if (font.border !== undefined) {
                newStyle['border-style'] = font.border
                newStyle['border-width'] = 1
            }

            if (font.borderColor !== undefined) {
                newStyle['border-color'] = font.borderColor
            }

            if (font.borderWidth !== undefined) {
                newStyle['border-width'] = font.borderWidth
            }

            if (font.isPaddingHorizontalEnable) {
                if (font.paddingHorizontal !== undefined) {
                    if (font.paddingHorizontal !== '') {
                        newStyle['padding-left'] = font.paddingHorizontal
                        newStyle['padding-right'] = font.paddingHorizontal
                    }
                }
            }
            else {
                newStyle['padding-left'] = undefined
                newStyle['padding-right'] = undefined
            }

            if (font.isPaddingVerticalEnable) {
                if (font.paddingVertical !== undefined) {
                    if (font.paddingVertical !== '') {
                        newStyle['padding-top'] = font.paddingVertical
                        newStyle['padding-bottom'] = font.paddingVertical
                    }
                }
            }
            else {
                newStyle['padding-top'] = undefined
                newStyle['padding-bottom'] = undefined
            }

            newStyle['text-align'] = 'left'
        }
        return newStyle
    }

    static getHtmlString(documentData) {
        var topSectionConfiguration = this.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable
        if (topSectionConfiguration.font.text !== undefined) {
            return this.convertTopSectionStringToHtml(topSectionConfiguration.font.text, documentData)
        }
    }

    static getIsTopSectionAboveTableIsVisible() {
        return TemplateSettingsManager.getTopSectionAboveTableIsVisible()
    }

    static convertTopSectionStringToHtml(textValue, documentData) {
        if (textValue !== undefined && textValue !== null) {
            var lines = textValue.replace(/\r?\n/g, "<br>")

            if (lines !== undefined && lines !== null) {
                var lineSplit = lines.split('<br>')
                var newLines = []
                if (lineSplit.length > 0) {
                    lineSplit.forEach(line => {
                        newLines.push(this.replaceTextHolder(line, documentData))
                    });
                    lines = newLines.join('<br>')
                }

            }

            return lines
        }
        return undefined
    }

    static replaceTextHolder(line, documentData) {
        line = line.replace('#_documentType_#', getCapitalized(documentData.type))
        line = line.replace('#_documentDate_#', documentData.date)
        line = line.replace('#_companyName_#', this.getCompanyName(documentData))
        line = line.replace('#_contactName_#', this.getContactName(documentData))

        if (this.getTotalAmount(documentData) !== undefined) {
            line = line.replace('#_total_#', this.getTotalAmount(documentData))
        }
        else {
            line = line.replace('#_total_#', '')
        }

        if (documentData.amountInWords !== undefined) {
            line = line.replace('#_amountInWords_#', documentData.amountInWords)
        }
        else {
            line = line.replace('#_amountInWords_#', '')
        }

        var rawDate = new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear()
        var today = getConvertedDate(convertDateFromERPFormatToDocFormat(rawDate), documentData.dateFormat)
        line = line.replace('#_currentDate_#', today)

        line = line.replace('#b#', '<b>')
        line = line.replace('#bb#', '</b>')

        line = line.replace('#i#', '<i>')
        line = line.replace('#ii#', '</i>')

        line = this.getCustomField(line, documentData)
        return line
    }

    static getTotalAmount(documentData) {
        var roundingOffValue = 0
        var currency = NumberUtility.getCurrency(documentData)
        documentData.taxBreakdown.forEach((tax) => {
            if (tax.taxId === 'Rounding Off') {
                roundingOffValue = tax.taxAmount
            }
        })

        if (getIsAccountModule(documentData)) {
            return Utility.toCurrencyFormat(this.getTotalAmount(documentData, roundingOffValue), currency)
        }
        else if (getIsExpenseBill(documentData.type, documentData.lineItems)) {
            return Utility.toCurrencyFormat(this.getTotalAmount(documentData, roundingOffValue), currency)
        }
        else if (getIsFulfillment(documentData.type)) {
            return ''
        }
        else if (getIsGoodsReceipt(documentData.type)) {
            return ''
        }
        else if (getIsMakePayment(documentData.type)) {
            return Utility.toCurrencyFormat(this.getTotalAmount(documentData.lineItems), currency)
        }
        else if (getIsReceivePayment(documentData.type)) {
            return Utility.toCurrencyFormat(this.getTotalAmount(documentData.lineItems), currency)
        }
        else if (getIsJournalEntry(documentData.type)) {
            return ''
        }
        else if (getIsPickList(documentData.type) || getIsPackList(documentData.type) || getIsShipList(documentData.type) || getIsEwayBill(documentData.type)) {
            return ''
        }
        else if (getIsSOACustomer(documentData.type) || getIsSOAVendor(documentData.type)) {
            return ''
        }
        else if (getIsAgedReceivable(documentData.type)) {
            return ''
        }
        else if (getIsInvoice(documentData.type) ||
            getIsSalesOrder(documentData.type) ||
            getIsQuotation(documentData.type) ||
            getIsRequestForQuotation(documentData.type) ||
            getIsPurchaseInwardQuotation(documentData.type) ||
            getIsBill(documentData.type) ||
            getIsOrder(documentData.type)) {
            var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(documentData.lineItems, false, false, roundingOffValue), currency)
            if (documentData.total !== undefined) {
                total = Utility.toCurrencyFormat(documentData.total, currency)
            }
            return total
        }
        return ''
    }

    static getCompanyName(documentData) {
        var name = ''
        if (documentData.tenantInfo !== undefined && documentData.tenantInfo !== null) {
            if (documentData.tenantInfo.name !== undefined && documentData.tenantInfo.name !== null) {
                name = documentData.tenantInfo.name
            }
        }

        if (getIsPickList(documentData.type) || getIsPackList(documentData.type) || getIsShipList(documentData.type) || getIsEwayBill(documentData.type)) {
            name = documentData.companyName
        }
        return name
    }

    static getContactName(documentData) {
        var name = ''
        if (documentData.contactInfo !== undefined && documentData.contactInfo !== null) {
            if (documentData.contactInfo.name !== undefined && documentData.contactInfo.name !== null) {
                name = documentData.contactInfo.name
            }
        }

        else if (getIsFulfillment(documentData.type)) {
            name = documentData.clientName
        }
        else if (getIsGoodsReceipt(documentData.type)) {
            name = documentData.clientName
        }

        if (getIsPickList(documentData.type) || getIsPackList(documentData.type) || getIsShipList(documentData.type) || getIsEwayBill(documentData.type)) {
            name = documentData.companyName
        }
        return name
    }

    static getCustomField(line, documentData) {
        if(line !== undefined && line !== null) {
            var customFieldLines = line.split('#_')
            var newLine = ''
            var customFieldCount = 0
            customFieldLines.forEach(element => {
                var customFieldCode = ''
                let splitCustomFieldLine = element.split('_')
                let module = ''
                if (element.includes('customField_') && element.includes('_#')) {
                    var endStringIndex = element.indexOf('_#')
                    customFieldCount = customFieldCount + 1
                    if(element.length === endStringIndex + 2) {
                        customFieldCode = element.replace('customField_', '').replace('_#', '')
                        if (splitCustomFieldLine.length === 4) {
                            customFieldCode = splitCustomFieldLine[2]
                            module = splitCustomFieldLine[1]
                        }
                        newLine = newLine + this.getCustomFieldString(customFieldCode, documentData, module)
                    }
                    else {
                        var extraText = element.substr(endStringIndex + 2)
                        customFieldCode = element.substr(0, endStringIndex).replace('customField_', '').replace('_#', '')
                        if (splitCustomFieldLine.length === 4) {
                            customFieldCode = splitCustomFieldLine[2]
                            module = splitCustomFieldLine[1]
                        }
                        newLine = newLine + this.getCustomFieldString(customFieldCode, documentData, module) + extraText
                    }
                }
                else {
                    newLine = element
                }
            });
            if (newLine !== '' && customFieldCount > 0) {
                line = newLine
            }
        }
        return line
    }

    static getCustomFieldString(code, data, module) {
        return CustomFieldManager.parseCustomField(data, code, module)
    }
}
