export const TAX_ADJUSTMENT_TYPES_INDIA = [
  {
    name: 'Reversal of GST Credit',
    value: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Increase in GST Credit',
    value: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Increase of Tax liability',
    value: 'INCREASE_OF_TAX_LIABILITY'
  },
  {
    name: 'None',
    value: 'NONE'
  }
];

export const TAX_ADJUSTMENT_SUBTYPES_INDIA = [
  {
    name: 'Reversal due to Capital Goods Rule 43 1(h)',
    value: 'REVERSAL_CAPITAL_GOODS_RULE_43',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Exempted supplies Rule 42(1)',
    value: 'EXEMPTED_SUPPLIES_RULE_42',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Rule 39(1)(j)(ii)',
    value: 'RULE_39',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Non Payment to Supplier Rule 37',
    value: 'NON_PAYMENT_SUPPLIER_RULE_37',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Claimed More Rule 42',
    value: 'CLAIMED_MORE_RULE_42',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'Others',
    value: 'OTHERS',
    isDisplay: 'REVERSAL_OF_GST_CREDIT'
  },
  {
    name: 'ISD Transfers',
    value: 'ISD_TRANSFERS',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Others',
    value: 'OTHERS',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Reclaim of Reversal ITC(On account of Buyer’s payment)',
    value: 'RECLAIM_OF_REVERSAL_ITC_BUYER_PAYMENT',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Reclaim of Reversal ITC Rule 42',
    value: 'RECLAIM_OF_REVERSAL_ITC_RULE_42',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Import of Goods from Overseas',
    value: 'IMPORT_OF_GOODS_FROM_OVERSEAS',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Import of Services from Overseas',
    value: 'IMPORT_OF_SERVICES_FROM_OVERSEAS',
    isDisplay: 'INCREASE_IN_GST_CREDIT'
  },
  {
    name: 'Advances paid under RCM',
    value: 'ADVANCES_PAID_UNDER_RCM',
    isDisplay: 'INCREASE_OF_TAX_LIABILITY'
  },
  {
    name: 'Interest',
    value: 'INTEREST',
    isDisplay: 'INCREASE_OF_TAX_LIABILITY'
  },
  {
    name: 'Late Fees',
    value: 'LATE_FEES',
    isDisplay: 'INCREASE_OF_TAX_LIABILITY'
  },
  {
    name: 'On Account of advance receipt',
    value: 'ON_ACCOUNT_OF_ADVANCE_RECEIPT',
    isDisplay: 'INCREASE_OF_TAX_LIABILITY'
  }
];
