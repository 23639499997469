import httpClient from "./Interceptor";
import { IPaymentMileStonePayload } from "../Models/PaymentMilestone";
import ApiConstants from "../Constants/ApiConstants";
import DateFormatService from "./DateFormat";
import { BOOKS_DATE_FORMAT, DOC_TYPE } from "../Constants/Constant";
import { roundOff } from "../SharedComponents/DocumentForm/NewDocumentHelper";

export const savePaymentMileStone = (payload: IPaymentMileStonePayload[]): Promise<any> => {
    return httpClient.post(ApiConstants.URL.INVOICES.ADD_PAYMENT_MILESTONE, payload);
}
export const updatePaymentMileStone = (payload: IPaymentMileStonePayload[], documentCode: string): Promise<any> => {
    return httpClient.put(ApiConstants.URL.INVOICES.UPDATE_PAYMENT_MILESTONE(documentCode), payload);
}
export const fetchPaymentMilestones = (params: any): Promise<any> => {
    return httpClient.get(ApiConstants.URL.INVOICES.FETCH_PAYMENT_MILESTONES, { params });
}
export const parsePayload = async (payload: any[], document: any): Promise<IPaymentMileStonePayload[]> => {
    return Promise.resolve(payload.map(item => ({
        documentType : document.documentType,
        documentCode: item.documentCode,
        contactCode: document.contactCode,
        milestoneDate: DateFormatService.getDateStrFromDate(new Date(item.milestoneDate), BOOKS_DATE_FORMAT['DD-MM-YYYY']),
        amount: parseFloat(item.amount),
        amountInPercent: parseFloat(item.amountInPercent),
        amountInPercentFlag: item.amountInPercentFlag,
        dueAmount: parseFloat(item.amount) - parseFloat(item.receivedAmount),
        milestoneNo: item.milestoneNo,
        receivedAmount: parseFloat(item.receivedAmount),
        status: item.status[0],
    })));
}
export const parseResponseForGrid = (payload: IPaymentMileStonePayload[], document: any) => {
    payload.sort((a, b) => a.milestoneNo - b.milestoneNo);
    payload = payload.map(item => {
        const amountWithPrecision = parseFloat(item['amount'] as any);
        return {
        ...item,
        amountInPercent: ((100 * amountWithPrecision) / parseFloat(document.totalAmount)),
        milestoneDate: DateFormatService.getDateFromStr(item.milestoneDate as string, BOOKS_DATE_FORMAT['DD-MM-YYYY']),
        status: [item.status] as any
    }});
    return payload;
}
export const getDataForMilestonePopup = (docType: string, document: any) => {
    const docToReturn = { ...document };

    switch (docType) {
        case DOC_TYPE.BILL:
            return {
                ...document,
                documentType: DOC_TYPE.BILL,
                documentNumber: document?.purchaseInvoiceCode,
                dueDate: document?.purchaseInvoiceDueDate
            };
        case DOC_TYPE.ORDER:
            return {
                ...document,
                contactCode: document.contactDto.code,
                currency: document.currencyCode,
                documentNumber: document.poCode,
                documentCode:document.documentSequenceCode,
                documentType: DOC_TYPE.ORDER,
            };
        case DOC_TYPE.INVOICE:
            return {
                ...document,
                documentType: DOC_TYPE.INVOICE,
                documentNumber: document.salesInvoiceCode,
            }
    }
    return docToReturn;
}
export const fetchMilestoneSummary = (params: any): Promise<any> => {
    return httpClient.get(ApiConstants.URL.INVOICES.MILESTONE_SUMMARY, { params });
};
export const fetchMilestoneDetails = (params: any): Promise<any> => {
    return httpClient.get(ApiConstants.URL.INVOICES.MILESTONE_DETAILS, { params });
};

export const deletePaymentMileStone = (documentCode: any, payload: any) => {
  return httpClient
    .delete(ApiConstants.URL.INVOICES.DELETE_PAYMENT_MILESTONE(documentCode), {
      data: payload 
    })
    .then((response: any) => Promise.resolve(response))
    .catch((error: any) => Promise.reject(error));
};