import { DateFormat, TableColumnPopupType } from "../Constants/Constants";
import { getDateFrom, getDateString } from "../Utilities/Utility";
import TemplateSettingsManager from "./TemplateSettingsManager";

export const CustomModuleType = {
    product: "PRODUCT",
    account: "ACCOUNT",
}

export default class CustomFieldManager {

    static customFieldOptions = [];

    static masterCustomFieldOptions = []

    static productCustomFieldOptions = []
    
    static childCustomFieldOptions = []

    static accountCustomFieldOptions = []

    static dateFormat = ""

    static tenantDateFormat = ""

    static preferredVendorCustomFieldOptions = [];

    static getDateFormat() {
        let dateFormat = CustomFieldManager.dateFormat

        var isCheck = TemplateSettingsManager.getDateFormatIsSystemDefault()
        if (isCheck) {
            if (this.tenantDateFormat && this.tenantDateFormat !== null) {
                if(this.tenantDateFormat !== ''){
                    dateFormat = this.tenantDateFormat
                }
            }
        }

        return dateFormat;
    }

    static setDateFormat(dateformat) {
        CustomFieldManager.dateFormat = dateformat
    }

    static setTenantDateFormat(dateformat) {
        CustomFieldManager.tenantDateFormat = dateformat
    }

    static getMaterCustomFields(includeInactive) {
        let nameArray = []
        CustomFieldManager.masterCustomFieldOptions.forEach((item) => {
            if (item.status === 'ACTIVE' || includeInactive) {
                nameArray.push(item)
            }
        })
        return nameArray
    }

    static getMaterCustomFieldCodes() {
        let nameArray = []
        CustomFieldManager.masterCustomFieldOptions.forEach((item) => {
            if (item.status === 'ACTIVE') {
                nameArray.push(item.code)
            }
        })

        return nameArray
    }

    static setMasterCustomFields(items) {
        CustomFieldManager.masterCustomFieldOptions = items
        this.setProductCustomFieldsFromMasterList(items)
        this.setAccountCustomFieldsFromMasterList(items)
    }

    static getCustomFields() {
        let nameArray = []
        if(CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                CustomFieldManager.customFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        item.label = activeItem.label
                        item.type = activeItem.fieldType
                        item.id = activeItem.id
                        nameArray.push(item)
                    }
                })
            }
        }
        return nameArray
    }

    static getActiveCustomFields() {
        let nameArray = []
        if (CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                CustomFieldManager.customFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        if (activeItem.status === 'ACTIVE') {
                            item.label = activeItem.label
                            item.type = activeItem.fieldType
                            item.id = activeItem.id
                            nameArray.push(item)
                        }
                    }
                })
            }
        }
        return nameArray
    }

    static setCustomFields(items) {
        CustomFieldManager.customFieldOptions = items
    }

    static getCustomFieldAt(index) {
        return CustomFieldManager.customFieldOptions[index]
    }

    static getCustomFieldByCode(code) {
        if (CustomFieldManager.customFieldOptions !== undefined && CustomFieldManager.customFieldOptions !== null) {
            if (CustomFieldManager.customFieldOptions.length > 0) {
                var item = CustomFieldManager.customFieldOptions.filter(x => x.code === code)
                if (item.length > 0) {
                    return item[0]
                }
            }
        }

        return undefined
    }

    static getCustomFieldByCodeFromApi(data, code, module) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList

        if (data !== undefined && data !== null) {
            if (data.customFields !== undefined && data.customFields !== null) {
                if (data.customFields.length > 0) {
                    var item = data.customFields.find(x => x.code === code)

                    let cfModule = module ?? ''
                    if(cfModule !== '') {
                        item = data.customFields.find(x => x.code === code && x.module === cfModule)
                    }
                    if (item !== undefined) {
                        if(customFieldList !== undefined && customFieldList !== null) {
                            if(customFieldList.length > 0) {
                                var masterCF = customFieldList.find(x => x.id === item.id)
                                if(masterCF !== undefined) {
                                    item.type = masterCF.type
                                }
                            }
                        }

                        return item
                    }
                }
            }
        }

        return undefined
    }

    static getSelectedGlobalCustomFieldList(data, position) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        if(position !== undefined && position !== null) {
            if(position === 'bottom') {
                customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList
            }
        }

        if (customFieldList === undefined || customFieldList === null) {
            customFieldList = []
        }
        if(customFieldList.length > 0) {
            customFieldList = customFieldList.filter(x => x.isSelected)
        }
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var customField = data.customFields
            if (customField !== undefined && customField !== null) {
                if (customField.length > 0) {
                    var customFieldCodes = customField.map(x => x.code)
                    var customFieldListWithMultiDoc = TemplateSettingsManager.getCustomFieldListWithMultiDoc()
                    if (position !== undefined && position !== null) {
                        if (position === 'bottom') {
                            customFieldListWithMultiDoc = TemplateSettingsManager.getBottomCustomFieldListWithMultiDoc()
                        }
                    }
                    let customFieldListPerDoc = customFieldListWithMultiDoc.find(x => x.documentId === data.refNumber)
                    if (customFieldListPerDoc !== undefined) {
                        let cfListPerDoc = []
                        if (customFieldListPerDoc.customList && customFieldListPerDoc.customList !== null) {
                            cfListPerDoc = [...customFieldListPerDoc.customList]
                        }
                        if (cfListPerDoc !== undefined) {
                            if (cfListPerDoc.length > 0) {
                                cfListPerDoc.forEach(element => {
                                    // element.isSelected = customFieldCodes.includes(element.code)
                                    if (element.isSelected === true) {
                                        let parentCF = customFieldList.find(x => x.code === element.code)
                                        if (parentCF) {
                                            element.isSelected = parentCF.isSelected
                                        }
                                    }
                                });
                            }
                            customFieldList = cfListPerDoc.filter(x => x.isSelected)

                            let newCFList = []

                            customFieldList.forEach(element => {
                                if (customFieldCodes.includes(element.code)) {
                                    newCFList.push(element)
                                }
                            });

                            if (newCFList.length > 0) {
                                customFieldList = newCFList
                            }
                        }
                    }
                }
            }
        }
        return customFieldList
    }

    static getIsContainsRenderSystemField() {
        if (CustomFieldManager.getCustomFields().length > 0) {
            return true
        }

        return false
    }

    static setProductCustomFieldsFromMasterList(masterList) {
        if(masterList === undefined || masterList === null) {
            return
        }

        if(masterList.length === 0) {
            return
        }

        var newProductCustomFieldList = []
        var newPreferredVendorCustomFieldList = []

        masterList.forEach(element => {
            if (element.status === 'ACTIVE') {
                if (element.modules !== undefined && element.modules !== null) {
                    if(element.modules.length > 0) {
                        if(element.modules.includes(CustomModuleType.product)) {
                            newProductCustomFieldList.push(element)
                        } else if(element.modules.includes("PREFERREDVENDOR")) {
                            newPreferredVendorCustomFieldList.push(element)
                        }
                    }
                }
            }
        });

        this.setProductCustomFields(newProductCustomFieldList)
        this.setPreferredVendorProductCustomFields(newPreferredVendorCustomFieldList)
    }

    static setProductCustomFields(items) {
        CustomFieldManager.productCustomFieldOptions = items
    }

    static getProductCustomFields() {
        if(TemplateSettingsManager.defaultTemplateSettings?.productTableConfiguration?.isIncludesPreferredVendorProductCustomField){
            return [...CustomFieldManager.productCustomFieldOptions,...CustomFieldManager.preferredVendorCustomFieldOptions]
        } else {
            return CustomFieldManager.productCustomFieldOptions
        }
    }

    static getProductWithPreferredVendorCustomFields(isIncludesPreferredVendorProductCustomField) {
        if(isIncludesPreferredVendorProductCustomField){
            return [...CustomFieldManager.productCustomFieldOptions,...CustomFieldManager.preferredVendorCustomFieldOptions]
        } else {
            return CustomFieldManager.productCustomFieldOptions
        }
    }

    static setPreferredVendorProductCustomFields(items) {
        CustomFieldManager.preferredVendorCustomFieldOptions = items
    }

    static getPreferredVendorProductCustomFields() {
        return CustomFieldManager.preferredVendorCustomFieldOptions
    }

    static getActiveProductCustomFields() {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        var productCustomFieldList = CustomFieldManager.getProductCustomFields()
        var list = []
        var prevList = []
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.combinedProductCustomField !== undefined) {
                prevList = productTableConfiguration.combinedProductCustomField
            }
        }

        if (productCustomFieldList.length > 0) {
            if (prevList.length === 0) {
                productCustomFieldList.forEach(element => {
                    list.push(this.convertProductCustomField(element))
                });
            }
            else {
                productCustomFieldList.forEach(element => {
                    var prevItem = prevList.find(x => x.code === element.code)
                    if (prevItem !== undefined) {
                        list.push(prevItem)
                    }
                    else {
                        list.push(this.convertProductCustomField(element))
                    }
                });
            }
        }
        return list
    }

    static convertProductCustomField(element) {
        var item = {}
        item.code = element.code
        item.label = element.label
        item.type = TableColumnPopupType.productCustomField
        item.isSelected = false
        return item
    }

    static getActiveProductCustomFieldsTableOptions(columnList) {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        var list = []
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isIncludesProductCustomField !== undefined) {
                if (productTableConfiguration.isIncludesProductCustomField) {
                    var productCustomFieldList = CustomFieldManager.getProductCustomFields()
                    var productCustomFieldCodeList = []
                    columnList.forEach((element) => {
                        if (element.type === TableColumnPopupType.productCustomField && element.code !== undefined && productCustomFieldList.length > 0) {
                            var productCustomField = productCustomFieldList.find(x => x.code === element.code)
                            if(productCustomField !== undefined) {
                                list.push(element)
                                productCustomFieldCodeList.push(element.code)
                            }
                        }
                        else {
                            list.push(element)
                        }
                    });

                    if (productCustomFieldList.length > 0) {
                        var newProductCustomFieldList = []
                        productCustomFieldList.forEach(element => {
                            if(!productCustomFieldCodeList.includes(element.code)) {
                                newProductCustomFieldList.push(element)
                            }
                        });

                        if (newProductCustomFieldList.length > 0) {
                            newProductCustomFieldList.forEach(element => {
                                var item = {}
                                item.code = element.code
                                item.name = element.label
                                item.cfType = element.type
                                item.type = TableColumnPopupType.productCustomField
                                item.isSelected = false
                                list.push(item)
                            });
                            list.forEach((element, index) => {
                                if(element.index === undefined) {
                                    element.index = index
                                }
                            });
                        }
                    }
                }
                else {
                    if (columnList.length > 0) {
                        //remove product custom field
                        columnList.forEach(element => {
                            if (!(element.code !== undefined && element.type === TableColumnPopupType.productCustomField)) {
                                list.push(element)
                            }
                        });
                    }
                }
            }
        }
        else {
            list = columnList
        }

        return list
    }

    static getNumericProductCustomField(selectedList) {
        var numericProductCustomFieldList = []

        if(selectedList === undefined || selectedList === null) {
            return numericProductCustomFieldList
        }

        selectedList.forEach(element => {
            if(element.code !== undefined) {
                var productCustomField = this.productCustomFieldOptions.find(x => x.code === element.code)
                if(productCustomField !== undefined) {
                    if (productCustomField.fieldType === 'NUMBER') {
                        numericProductCustomFieldList.push(productCustomField)
                    }
                }
            }
        });
        return numericProductCustomFieldList
    }

    static setAccountCustomFieldsFromMasterList(masterList) {
        if (masterList === undefined || masterList === null) {
            return
        }

        if (masterList.length === 0) {
            return
        }

        var newAccountCustomFieldList = []
        masterList.forEach(element => {
            if (element.status === 'ACTIVE') {
                if (element.modules !== undefined && element.modules !== null) {
                    if (element.modules.length > 0) {
                        if (element.modules.includes(CustomModuleType.account)) {
                            newAccountCustomFieldList.push(element)
                        }
                    }
                }
            }
        });

        this.setAccountCustomFields(newAccountCustomFieldList)
    }

    static setAccountCustomFields(items) {
        CustomFieldManager.accountCustomFieldOptions = items
    }

    static getAccountCustomFields() {
        return CustomFieldManager.accountCustomFieldOptions
    }

    static getActiveAccountCustomFields() {
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        var accountCustomFieldList = CustomFieldManager.getAccountCustomFields()
        var list = []
        var prevList = []
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.combinedAccountCustomField !== undefined) {
                prevList = accountTableConfiguration.combinedAccountCustomField
            }
        }
        if (accountCustomFieldList.length > 0) {
            if (prevList.length === 0) {
                accountCustomFieldList.forEach(element => {
                    list.push(this.convertAccountCustomField(element))
                });
            }
            else {
                accountCustomFieldList.forEach(element => {
                    var prevItem = prevList.find(x => x.code === element.code)
                    if (prevItem !== undefined) {
                        list.push(prevItem)
                    }
                    else {
                        list.push(this.convertAccountCustomField(element))
                    }
                });
            }
        }
        return list
    }

    static convertAccountCustomField(element) {
        var item = {}
        item.code = element.code
        item.label = element.label
        item.type = TableColumnPopupType.accountCustomField
        item.isSelected = false
        return item
    }

    static getActiveAccountCustomFieldsTableOptions(columnList) {
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        var list = []
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.isIncludesAccountCustomField !== undefined) {
                if (accountTableConfiguration.isIncludesAccountCustomField) {
                    var accountCustomFieldList = CustomFieldManager.getAccountCustomFields()
                    var accountCustomFieldCodeList = []
                    columnList.forEach((element) => {
                        if (element.type === TableColumnPopupType.accountCustomField && element.code !== undefined && accountCustomFieldList.length > 0) {
                            var productCustomField = accountCustomFieldList.find(x => x.code === element.code)
                            if (productCustomField !== undefined) {
                                list.push(element)
                                accountCustomFieldCodeList.push(element.code)
                            }
                        }
                        else {
                            list.push(element)
                        }
                    });

                    if (accountCustomFieldList.length > 0) {
                        var newAccountCustomFieldList = []
                        accountCustomFieldList.forEach(element => {
                            if (!accountCustomFieldCodeList.includes(element.code)) {
                                newAccountCustomFieldList.push(element)
                            }
                        });

                        if (newAccountCustomFieldList.length > 0) {
                            newAccountCustomFieldList.forEach(element => {
                                var item = {}
                                item.code = element.code
                                item.name = element.label
                                item.cfType = element.type
                                item.type = TableColumnPopupType.accountCustomField
                                item.isSelected = false
                                list.push(item)
                            });
                            list.forEach((element, index) => {
                                if (element.index === undefined) {
                                    element.index = index
                                }
                            });
                        }
                    }
                }
                else {
                    if (columnList.length > 0) {
                        //remove product custom field
                        columnList.forEach(element => {
                            if (element.code === undefined) {
                                list.push(element)
                            }
                        });
                    }
                }
            }
        }
        else {
            list = columnList
        }
        return list
    }

    static parseCustomField(data, code, module) {
        var customFieldValue = ''
        var customField = CustomFieldManager.getCustomFieldByCodeFromApi(data, code, module)
        if(customField !== undefined) {
            if (customField.type === "DATE") {
                customFieldValue = '-'
                if(customField.value !== undefined && customField.value !== null) {
                    if(customField.value !== '' ) {
                        const dateFormat = CustomFieldManager.getDateFormat();
                        customFieldValue = getDateString(getDateFrom(customField.value, DateFormat.MM_DD_YYYY_SLASH), dateFormat);
                    }
                }
            } else {
                if (customField !== undefined) {
                    if(customField.value !== null && customField.value !== '') {
                        customFieldValue = customField.value
                    }
                    else {
                        customFieldValue = ' '
                    }
                }
                else {
                    customFieldValue = ' '
                }
            }
        }
        return customFieldValue
    }

    static isDateCustomField(cfId) {
        const masterCustomFields = CustomFieldManager.getMaterCustomFields(true);
        if (Array.isArray(masterCustomFields)) {
            return masterCustomFields.findIndex(cf => cf.id === cfId && cf.fieldType === "DATE") !== -1;
        }

        return false;
    }

    static setChildCustomFields(items) {
        CustomFieldManager.childCustomFieldOptions = items
    }

    static getChildCustomFieldAt(index) {
        return CustomFieldManager.childCustomFieldOptions[index]
    }

    static getChildCustomFields() {
        let nameArray = []
        if (CustomFieldManager.childCustomFieldOptions !== undefined && CustomFieldManager.childCustomFieldOptions !== null) {
            if (CustomFieldManager.childCustomFieldOptions.length > 0) {
                CustomFieldManager.childCustomFieldOptions.forEach((item) => {
                    var activeItem = CustomFieldManager.masterCustomFieldOptions.find(x => x.code === item.code)
                    if (activeItem !== undefined) {
                        item.label = activeItem.label
                        item.type = activeItem.fieldType
                        item.id = activeItem.id
                        nameArray.push(item)
                    }
                })
            }
        }
        return nameArray
    }

    static getSelectedChildCustomFieldList(data) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList
        if (customFieldList === undefined || customFieldList === null) {
            customFieldList = []
        }
        if (customFieldList.length > 0) {
            customFieldList = customFieldList.filter(x => x.isSelected)
        }
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var customField = data.childCustomFields
            if (customField !== undefined && customField !== null) {
                if (customField.length > 0) {
                    // var customFieldCodes = customField.map(x => x.code)
                    var customFieldListWithMultiDoc = TemplateSettingsManager.getChildCustomFieldListWithMultiDoc()
                    
                    let customFieldListPerDoc = customFieldListWithMultiDoc.find(x => x.documentId === data.refNumber)
                    if (customFieldListPerDoc !== undefined) {
                        let cfListPerDoc = customFieldListPerDoc.customList
                        if (cfListPerDoc !== undefined) {
                            if (cfListPerDoc.length > 0) {
                                cfListPerDoc.forEach(element => {
                                    // element.isSelected = customFieldCodes.includes(element.code)
                                    if (element.isSelected === true) {
                                        let parentCF = customFieldList.find(x => x.code === element.code)
                                        if (parentCF) {
                                            element.isSelected = parentCF.isSelected
                                        }
                                    }
                                });
                            }
                            customFieldList = cfListPerDoc.filter(x => x.isSelected)
                        }
                    }
                }
            }
        }
        return customFieldList
    }

    static getChildCustomFieldByCodeFromApi(data, code) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList

        if (data !== undefined && data !== null) {
            if (data.childCustomFields !== undefined && data.childCustomFields !== null) {
                if (data.childCustomFields.length > 0) {
                    var item = data.childCustomFields.find(x => x.code === code)
                    if (item !== undefined) {
                        if (customFieldList !== undefined && customFieldList !== null) {
                            if (customFieldList.length > 0) {
                                var masterCF = customFieldList.find(x => x.id === item.id)
                                if (masterCF !== undefined) {
                                    item.type = masterCF.type
                                }
                            }
                        }

                        return item
                    }
                }
            }
        }

        return undefined
    }

    static getCombineSerialBatchCustomField(rawItemValue, data) {
        var val = "";
        const serialBatchCustomFieldType = rawItemValue.serialBatchCustomFieldType

        if (serialBatchCustomFieldType && serialBatchCustomFieldType !== 'NONE') {
            const serialBatchCustomFields = rawItemValue?.serialBatchCustomFields ?? undefined
            if (serialBatchCustomFieldType === 'BATCH') {
                if (serialBatchCustomFields && serialBatchCustomFields.length > 0) {
                    serialBatchCustomFields.forEach((batch) => {
                        if (batch.customField && batch.customField.length > 0) {
                            batch.customField.forEach((cfBatchEle) => {
                                val += cfBatchEle.label + ": <b>" + cfBatchEle.value + "</b>\n";
                            });
                        }
                    });
                }
            }

            if (serialBatchCustomFieldType === 'SERIAL') {
                const serialNumbers = rawItemValue?.serialNumbers ?? []
                if (serialNumbers.length > 0 && serialBatchCustomFields !== undefined) {
                    serialNumbers.forEach((element) => {
                        let cf = serialBatchCustomFields[element]
                        if (cf && cf !== null) {
                            cf.forEach((cfElement) => {
                                val += cfElement.label + ": <b>" + cfElement.value + "</b>\n";
                            });
                        }
                    });
                }
            }

            if (serialBatchCustomFieldType === 'SERIAL_BATCH') {
                const serialNumbers = rawItemValue?.serialBatchList ?? []
                if (serialNumbers.length > 0 && serialBatchCustomFields !== undefined) {
                    let isSingleSerialBatch = serialNumbers.length === 1 ?? false
                    serialNumbers.forEach((element) => {
                        let cf = serialBatchCustomFields[element]
                        if (cf && cf !== null) {
                            if (!isSingleSerialBatch) {
                                val += element + ',\n'
                            }
                            cf.forEach((cfElement) => {
                                var cfType = cf.cfType;
                                var value = cfElement.value 
                                if(cfType === undefined) {
                                    const dateValue = getDateFrom(cfElement.value, DateFormat.MM_DD_YYYY_SLASH)
                                    if(dateValue instanceof Date && !isNaN(dateValue)) {
                                        cfType = 'DATE'
                                    }
                                }
                                if (cfType === "DATE") {
                                    value = getDateString(getDateFrom(cfElement.value, DateFormat.MM_DD_YYYY_SLASH), data.dateFormat) || cfElement.value;
                                }
                                val += cfElement.label + ": <b>" + value + "</b>\n";
                            });
                        }
                    });
                }
            }
        }
        return val;
    }
}
