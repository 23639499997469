import {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {
  DKLabel,
  DKLine,
  DKIcon,
  DKIcons,
  DKTooltipWrapper,
  DKDataGrid,
  DKCheckMark,
  showAlert,
  showToast,
  TOAST_TYPE,
  showLoader,
  removeLoader,
  DKButton,
  DKSpinner,
  Toggle,
  INPUT_TYPE
} from 'deskera-ui-library';
import { DynamicPopupWrapper } from '../../../../../SharedComponents/PopupWrapper';
import {
  IWorkOrder,
  IWorkOrderItems,
  addBulkWorkOrder
} from '../../../../../Services/MRP/WorkOrder';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  LOCAL_STORAGE_KEYS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import Utility, {
  deepClone,
  shiftArrayElement
} from '../../../../../Utility/Utility';
import ProductService from '../../../../../Services/Product';
import ic_not_box from '../../../../../../src/Assets/Icons/ic_not_box.png';
import {
  BOM_EXPLOSION_BUTTON_TYPE,
  filterWIPProductsOnly,
  filterNonAvailableProductIds,
  findIfAnyProductShortFallInBomExplosion,
  getNestedProductCodes,
  isAvailable,
  parseBomExplosionGridRows,
  parseBomExplosionResponseToTree,
  updateBomExplosionDataByWorkOrderData,
  saveTrackingDetails,
  calculateShortfallForActionButton,
  getAvailabilityStatusColor,
  getAvailabilityStatus,
  getUpdatedBomExplosionDataByRowId,
  BOMExplosionHelper,
  getTooltipMessageForTargetWHAvailability,
  MORE_CELL_OPTIONS,
  assignBomExplosionRowContextMenu,
  payloadForWorkOrder,
  removeMaterialFromBomexplosion,
  getAddMaterialBomExplosionDataByRowId,
  getLinkedPOPRCode,
  parseStockRequestDataFromBomProducts,
  getJwoDraftDocumentPayloadFromProductData,
  fetchBomExplosionDetailsWithProductCodeAndMetaCode,
  getInitialRawMaterial,
  parseProductRowsForAutoCreatePOPR,
  getPurchaseRequisitionPayloadFromProductData,
  getPurchaseOrderPayloadFromProductData,
  prepareBomExplosionProductForAllocation,
  autoAllocateProduct,
  getLinkedJWOCode,
  attachProductHierarchicalPath,
  filterLinkedLineItems
} from '../../../BomExplosion/BOMExplosionHelper';
import {
  BOM_EXPLOSION_COLUMN_KEYS,
  BOM_EXPLOSION_CUSTOM_EXPAND_COLUMN_CONFIG
} from '../../../Constants/MRPColumnConfigs';
import {
  getTaggedWHQtyMessage,
  populateProductDetailWithSelectedOrDefaultBom
} from '../../WorkOrderHelper';
import BomExplosionHeader from './BomExplosionComponents/BomExplosionHeader';
import { WORK_ORDER_PR_PO } from '../../../../Settings/AdvancedSettings/AdvancedSettings';
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchUserPreferences,
  updateUserPreferences
} from '../../../../../Redux/Slices/AuthSlice';
import { IColumn } from '../../../../../Models/Table';
import useScreenResize from '../../../../../Hooks/useScreenResize';

import AddNewWorkOrder from '../../AddWorkOrder';
import ProductSelectionPopup from '../../../BomExplosion/ProductSelectionPopup';
import AddStockRequestPopup from '../../../../StockManagement/StockRequest/AddStockRequest';
import {
  selectWorkOrderAssociatedPOPR,
  selectWorkOrderAssociatedSRData,
  selectWorkOrderBomExplosionAssociation,
  updateStockRequestAssociation,
  updateWOBomexplosionAssociation
} from '../../../../../Redux/Slices/MRP/WorkOrderSlice';
import { DraftTypes } from '../../../../../Models/Drafts';
import { createBlankDraft } from '../../../../../Redux/Slices/DraftsSlice';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import RawMaterialHelper from './RawMaterialHelper';
import ApiConstants from '../../../../../Constants/ApiConstants';
import LinkedDocsPopup from '../../../BomExplosion/LinkedDocsPopup';
import AddStockTransferPopup from '../../../../StockManagement/StockTransfer/AddStockTransfer';
import BomSelector from '../../../BomExplosion/BomSelector';
import GetAssignmentPopupForTargetWarehouseWO from './GetAssignmentPopupForTargetWarehouseWO';
import { isEqual } from 'lodash';
import { REQUIRED_ITEM_TABLE } from '../../../Constants/TableConstant';
import {
  addBOMExplosionColumnConfig,
  selectBOMExplosionColumnConfig,
  selectBOMExplosionColumnConfigTableId
} from '../../../../../Redux/Slices/MRP/BOMExplosionSlice';
import { REMOTE_CONFIG_TABLES } from '../../../../../Constants/TableConstants';
import {
  updateColumnInfo,
  updateColumnShift
} from '../../../../../Helper/TableColumnUpdateHelper';
import { updateColumnConfig } from '../../../../../Redux/Slices/MRP/BOMExplosionSlice';
import CustomFieldService from '../../../../../Services/CustomField';
import NumberFormatService from '../../../../../Services/NumberFormat';
import useConfirm from '../../../../../Hooks/useConfirm';
import TenantService from '../../../../../Services/Tenant';
import { BOM_EXPLOSION_ALLOCATION_TYPE } from '../../BomExplosionAllocationConfirmation';
import { selectUOMs } from '../../../../../Redux/Slices/CommonDataSlice';
import WarehouseManagementHelper from '../../../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import { getIsApprovalFlowEnabled } from '../../../../StockManagement/StockAdjustment/StockAdjustmentHelper';

interface BomExplosionPopupProps {
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  activeWorkOrder: IWorkOrder;
  existingRows: IWorkOrderItems[];
  jwoList: any[];
  payloadForCurrentWO: IWorkOrder[];
  hasProductionEntry?: boolean;
  handleDetailOpenerOnLinkedRecordTap: (linkedDocData: {
    documentSeqCode: string;
    documentCode: string;
    documentType: DOC_TYPE;
    showDetailsOpener: boolean;
    targetWarehouse?: string;
    documentUOMSchemaDefinition?: any;
  }) => void;
  checkIfProductConsumed: (productCode: string) => boolean;
  onBOMPOPRExists: (exists: boolean) => void;
  /** @todo to rename as per functionality  */
  updateLinkedDocs: (linkedWoDetails: any) => void;
  /** @todo to rename as per functionality  */
  woCreateCallback: (woListResponse: IWorkOrder[], substituteObj: any) => void;
  handleWOCreationInNewMode: (
    response: IWorkOrder[],
    selectedProductRow: any[]
  ) => void;
  onSave: (bomExplosionData: any) => void;
  onClose: (needRefresh?: any) => void;
  forcefullyCloseWO: () => void;
  /** @todo to rename as per functionality  */
  closeAndOpenInEditMode: (response: IWorkOrder[]) => void;
}

const LEFT_PANEL_HEADER_ID = `bom-explosion-left-panel-header`;
const LEFT_PANEL_ROW_IDENTIFIER = `bom-explosion-left-panel-row`;
const RIGHT_PANEL_ROW_IDENTIFIER = `dk-data-grid-row-bg`;
const LEFT_PANEL_WIDTH = 310;

const BomExplosionPopup: FunctionComponent<BomExplosionPopupProps> = (
  props
) => {
  const [state, setState] = useState({
    loading: false,
    leftPanelExpanded: true,
    hideMainProductRowsWithSubstitute:
      Utility.getPersistentValue(
        LOCAL_STORAGE_KEYS.BOM_EXPLOSION_SUBSTITUTE_ONLY
      ) === 'true',
    /** Use bomExplosionData, instead of state.bomExplosionData  */
    bomExplosionData: null as any,
    wipSubstitutesData: [] as any[],
    showAvailableSubstituteListPopup: false,
    showTrackingPopup: false,
    needSubstituteAllocationPopup: false,
    showStockTransferPopup: false,
    showStockRequestPopup: false,
    showLinkedDocPopup: false,
    showWOPopup: false,
    showBomSelector: false,
    showProductSelectionPopup: false,
    selectedProductFromContext: null as any,
    productDetailByIdForWO: null,
    productsForActionBtnClick: [] as any[],
    warehouseProductsInfo: [],
    materialListForWO: [],
    headerActionButtonType: '',
    stockRequestProducts: [] as any[],
    poPrCreationInProgress: false,
    showSubstituteOnlyComponentPopup: false,
    bomExplosionDataForAvailableSubstitutePopup: null as any,
    targetWarehouse: null,
    showAllocatedTrackingDetailsView: false,
    isInnerDocumentRelatedToCurrentWOSaved: true,
    isMasterHeaderCheckboxSelected: false,
    addedToPOPR: false
  });
  const nonAvailableProductDetailsById = useRef<{ [id: number]: any }>({});
  const bomMaterialPopupContainerRef = useRef<HTMLDivElement | null>(null);
  const productsArray = useRef<any>([]);
  const [width] = useScreenResize(bomMaterialPopupContainerRef, 0.95);
  const { confirm } = useConfirm();

  /** Store state selectors */
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const woSRAssociateData = useAppSelector(selectWorkOrderAssociatedSRData);
  const associatedPOPR = useAppSelector(selectWorkOrderAssociatedPOPR);
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);
  const woBOMExplosionObj = useAppSelector(
    selectWorkOrderBomExplosionAssociation
  );
  const bomExplosionColumnConfig = useAppSelector(
    selectBOMExplosionColumnConfig
  );
  const columnConfigTableId = useAppSelector(
    selectBOMExplosionColumnConfigTableId
  );
  const uomList = useAppSelector(selectUOMs);
  const freezedColumns =
    useAppSelector(
      (state) => state.authInfo.userPreferences.data?.freezedColumns
    ) || {};

  const moduleFreezedColumns =
    freezedColumns[REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION] || [];

  /** Derived states */
  const woInsufficientSetting =
    tenantInfo?.additionalSettings?.WO_STOCK_SHORTFALL_SETTING ||
    WORK_ORDER_PR_PO.PURCHASE_ORDER;
  const bomProductCode = props.activeWorkOrder?.productCode || '';
  const bomMetaCode = props.isEditMode
    ? props.activeWorkOrder?.bomMetaCode
    : props.activeWorkOrder?.selectedBom?.code;

  /* Use this variable instead of directly accessing state variable */
  const bomExplosionData = state.showSubstituteOnlyComponentPopup
    ? state.bomExplosionDataForAvailableSubstitutePopup
    : state.bomExplosionData;

  const isAnyProductShortFall =
    findIfAnyProductShortFallInBomExplosion(bomExplosionData);
  let gridRows =
    parseBomExplosionGridRows(bomExplosionData, 0, 0, null, {
      isReadOnlyMode: props.isReadOnlyMode,
      checkIfProductConsumed: props.checkIfProductConsumed
    }) || [];

  const getFilteredRows = (componentWithSubstituteOnly?: boolean) => {
    let filteredRows = gridRows;

    /** Get Filtered component products having available/candidate substitutes (Related to SELECT SUBSTITUTES)  */
    if (componentWithSubstituteOnly) {
      filteredRows = filteredRows.filter(
        (row) => !row.hideOnEnableAvailableSubstituteOnlySetting
      );
    }

    /** Filter out main product rows/component products for which substitutes are assigned (Related to ASSIGNED SUBSTITUTES)  */
    if (state.hideMainProductRowsWithSubstitute) {
      filteredRows = filteredRows.filter(
        (row) =>
          !row.hideOnEnableSubstituteOnlySetting || row.isParentASubstitute
      );
    }

    return filteredRows;
  };

  const gridColumns = getGridColumns() || [];

  const selectedProductList = gridRows.filter(
    (row) => row[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION]
  );

  /**
   * @description instead of setState, Use below method as
   * updater/setter function for component state
   */
  const updateComponentState = useCallback(
    (dataToUpdate: Partial<typeof state>) => {
      setState((state) => ({ ...state, ...dataToUpdate }));
    },
    []
  );

  /**
   * @description In case of edits to rows instead of directly updating bomExplosionData,
   * Use below method as updater/setter function
   */
  const updateBomExplosionDataByUniqueId = async (data: {
    rowUniqueId: string;
    key: string;
    value: any;
  }) => {
    try {
      if (data.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME) {
        if (!data.value) return;

        let updatedValue = data.value;
        if (data.value.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
          let productDetails = populateProductDetailWithSelectedOrDefaultBom(
            data.value
          );

          let responseProductData =
            await fetchBomExplosionDetailsWithProductCodeAndMetaCode(
              data.value.productId,
              productDetails.selectedBom?.code
            );

          updatedValue = {
            ...responseProductData,
            produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
            productDetails: productDetails,
            bomMetaCode: productDetails.selectedBom?.code,
            [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]: [],
            selected: state?.isMasterHeaderCheckboxSelected,
            isNewRow: true
          };
        } else {
          updatedValue = getInitialRawMaterial(data.value);
        }

        data.value = updatedValue;
      }

      let newBomExplosionData = getUpdatedBomExplosionDataByRowId(
        bomExplosionData,
        data
      );

      // Re-attach paths
      if (newBomExplosionData) {
        newBomExplosionData = attachProductHierarchicalPath({
          ...newBomExplosionData
        });
      }

      if (
        data.key === BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS ||
        data.key === BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES ||
        data.key === BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY ||
        data.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME ||
        data.key === BOM_EXPLOSION_COLUMN_KEYS.UOM
      ) {
        newBomExplosionData = parseBomExplosionResponseToTree({
          data: newBomExplosionData,
          activeWorkOrder: props.activeWorkOrder,
          isEditMode: props.isEditMode,
          substituteBomExplosionData: state.wipSubstitutesData,
          showSubstituteOnly: state.hideMainProductRowsWithSubstitute
        });
      }

      newBomExplosionData = updateBomExplosionDataByWorkOrderData(
        newBomExplosionData,
        props.activeWorkOrder,
        state.warehouseProductsInfo
      );

      updateComponentState({
        [state.showSubstituteOnlyComponentPopup
          ? 'bomExplosionDataForAvailableSubstitutePopup'
          : 'bomExplosionData']: newBomExplosionData
      });
    } catch (err) {
      console.error(err);
    }
  };

  /************* DATA FETCH UTILS & EFFECTS *********
   *************************************************/
  const fetchComponentProductDetails = useCallback(async () => {
    nonAvailableProductDetailsById.current = {};
    const nonAvailableProductIds = filterNonAvailableProductIds(
      state.bomExplosionData
    );
    if (Utility.isEmpty(nonAvailableProductIds)) return [];

    try {
      const response = await ProductService.getProductsByProductIds(
        nonAvailableProductIds || [],
        true
      );

      response?.forEach((product: any) => {
        nonAvailableProductDetailsById.current[product.productId] = product;
      });
    } catch (err) {}
  }, [state.bomExplosionData]);

  const fetchWareHouseStockByProductCode = useCallback(
    (productCodes: string[]) => {
      ProductService.fetchWarehouseProductsByID(productCodes)
        .then((data) => {
          if (data) {
            updateComponentState({
              warehouseProductsInfo: data?.warehouses || []
            });
          }
        })
        .catch((err) => {
          console.error('Error loading warehouse info: ', err);
          showAlert('Error', 'Problem loading all warehouses details.', [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => props.onClose()
            }
          ]);
        });
    },
    [props, updateComponentState]
  );

  const includeNewRawMaterialRowsInBomExplosionData = async (
    explosionData: any,
    workOrderData: IWorkOrder
  ) => {
    explosionData = {
      ...explosionData,
      [BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS]: [
        ...(explosionData[BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS]?.map(
          (bomProductConfig: any) => {
            const existingSavedWOItem = workOrderData.workOrderItems?.find(
              (woItem: any) => woItem?.itemName?.pid === bomProductConfig?.pid
            );

            if (Utility.isNotEmpty(existingSavedWOItem)) {
              bomProductConfig = {
                ...bomProductConfig,
                stockUom: existingSavedWOItem?.stockUom,
                documentUOMSchemaDefinition:
                  existingSavedWOItem?.documentUOMSchemaDefinition,
                availableUomQuantity: Utility.getUomQuantity(
                  bomProductConfig?.availableQuantity,
                  existingSavedWOItem?.documentUOMSchemaDefinition
                ),
                addToRequisition: existingSavedWOItem?.addToRequisition || false
              };
            }

            return bomProductConfig;
          }
        ) || [])
      ]
    };
    const existingComponentProductIdsFromResponse = explosionData[
      BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS
    ].map((componentProduct: any) => componentProduct?.pid);

    const newMaterialRows = (workOrderData?.workOrderItems || []).filter(
      (rawMaterial) =>
        rawMaterial.isNewRow &&
        rawMaterial.produceProductType === PRODUCE_PRODUCT_TYPE.NONE &&
        !existingComponentProductIdsFromResponse.includes(
          rawMaterial.itemName?.pid ?? rawMaterial.itemName?.productId
        )
    );
    const newBomExplosionDataForNewMaterials = await Promise.all(
      newMaterialRows.map(async (newMaterialRow) => {
        let bomExplosionDataForNewMaterial = newMaterialRow.bomExplosionData;
        /* If preformed data found, use that */
        if (bomExplosionDataForNewMaterial) {
          return Promise.resolve(bomExplosionDataForNewMaterial);
        }

        const productDetails = newMaterialRow.productDetails;
        if (!productDetails) return Promise.resolve(null);

        if (productDetails.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
          let productDetailWithSelectedBom =
            populateProductDetailWithSelectedOrDefaultBom(
              productDetails,
              null,
              newMaterialRow.bomMetaCode
            );

          let responseProductData =
            await fetchBomExplosionDetailsWithProductCodeAndMetaCode(
              productDetailWithSelectedBom.productId,
              productDetailWithSelectedBom.selectedBom.code
            );

          return Promise.resolve({
            ...responseProductData,
            productDetails: productDetailWithSelectedBom,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]:
              newMaterialRow[REQUIRED_ITEM_TABLE.REQUIRED_QTY],
            [BOM_EXPLOSION_COLUMN_KEYS.CHARGES]:
              newMaterialRow[BOM_EXPLOSION_COLUMN_KEYS.CHARGES] || 0,
            bomMetaCode: productDetailWithSelectedBom?.selectedBom?.code,
            produceProductType: PRODUCE_PRODUCT_TYPE.NONE
          });
        } else {
          const bomExplosionDataForRM = getInitialRawMaterial(productDetails);
          return Promise.resolve({
            ...bomExplosionDataForRM,
            [BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY]:
              newMaterialRow[REQUIRED_ITEM_TABLE.REQUIRED_QTY],
            [BOM_EXPLOSION_COLUMN_KEYS.CHARGES]:
              newMaterialRow[BOM_EXPLOSION_COLUMN_KEYS.CHARGES] || 0
          });
        }
      })
    );

    newBomExplosionDataForNewMaterials.forEach(
      (newBomExplosionDataForNewMaterial) =>
        newBomExplosionDataForNewMaterial &&
        explosionData[BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS].push(
          newBomExplosionDataForNewMaterial
        )
    );

    return explosionData;
  };

  const fetchBOMExplosionDetails = useCallback(() => {
    if (!bomProductCode || (!isAdhocEnabled && !bomMetaCode)) return;

    updateComponentState({ loading: true });

    const needAdhocApiCall =
      (isAdhocEnabled || !bomMetaCode) && props.activeWorkOrder.workOrderCode;
    const bomExplosionApiCallPromise = needAdhocApiCall
      ? ProductService.fetchAdhocBOMExplosionDetails(
          bomProductCode,
          props.activeWorkOrder.workOrderCode as string
        )
      : ProductService.fetchBOMExplosionDetails(
          bomProductCode,
          bomMetaCode as string
        );

    bomExplosionApiCallPromise
      .then(async (explosionDetails) => {
        explosionDetails = await includeNewRawMaterialRowsInBomExplosionData(
          explosionDetails,
          props.activeWorkOrder
        );

        if (explosionDetails) {
          explosionDetails = attachProductHierarchicalPath(
            deepClone(explosionDetails)
          );
        }

        let bomExplosionData = parseBomExplosionResponseToTree({
          data: explosionDetails,
          isEditMode: props.isEditMode,
          activeWorkOrder: props.activeWorkOrder,
          substituteBomExplosionData: state.wipSubstitutesData,
          showSubstituteOnly: state.hideMainProductRowsWithSubstitute
        });

        const isRequisitionAddedAlready =
          bomExplosionData?.bomProductConfiguration?.some(
            (bomConfigRow: any) => bomConfigRow?.addToRequisition
          );

        updateComponentState({
          bomExplosionData: bomExplosionData,
          loading: false,
          addedToPOPR: isRequisitionAddedAlready
        });

        fetchComponentProductDetails();

        let allProductCodes = getNestedProductCodes(bomExplosionData);
        if (
          (Utility.isRRBTaggingEnabled() ||
            Utility.isWarehouseTaggingEnabled()) &&
          !Utility.isEmpty(allProductCodes)
        ) {
          // Fetch warehouse info for all component and substitute products
          fetchWareHouseStockByProductCode(allProductCodes);
        }
      })
      .catch((err) => {
        console.error('Error fetching bom explosion data', err);
        updateComponentState({ loading: false });
      });
  }, [
    bomProductCode,
    isAdhocEnabled,
    bomMetaCode,
    updateComponentState,
    props.activeWorkOrder,
    props.isEditMode,
    state.wipSubstitutesData,
    state.hideMainProductRowsWithSubstitute,
    fetchComponentProductDetails,
    fetchWareHouseStockByProductCode
  ]);

  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (!bomProductCode) props.onClose();

    if (!firstRenderRef.current) return;

    firstRenderRef.current = false;
    fetchBOMExplosionDetails();
  }, [bomProductCode, dispatch, fetchBOMExplosionDetails, props]);

  useEffect(() => {
    if (Utility.isNotEmpty(associatedPOPR)) {
      updateComponentState({
        addedToPOPR: false
      });
    }
  }, [associatedPOPR, updateComponentState]);

  const wipSubstitutesPayload: {
    productCode: string;
    workorderCode?: string;
  }[] = gridRows
    .filter((gridRow) => {
      const isWipSubstitute =
        gridRow[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE] &&
        gridRow.type === PRODUCT_TYPE.BILL_OF_MATERIALS;
      return isWipSubstitute;
    })
    .map((wipSubstitute) => ({
      productCode:
        wipSubstitute.pid ??
        wipSubstitute.productId ??
        wipSubstitute.productCode
    }));

  const substituteBomExplosionPayloadRef = useRef<
    { productCode: string; workorderCode?: string }[] | null
  >(null);

  useEffect(() => {
    const needAdhocApiCall = false;
    // (isAdhocEnabled || !bomMetaCode) && props.activeWorkOrder.workOrderCode;

    if (needAdhocApiCall) {
      wipSubstitutesPayload.forEach((wipSubstitute) => {
        wipSubstitute['workorderCode'] = props.activeWorkOrder.workOrderCode;
      });
    }

    if (
      Utility.isEmpty(wipSubstitutesPayload) ||
      isEqual(wipSubstitutesPayload, substituteBomExplosionPayloadRef.current)
    ) {
      return;
    }

    substituteBomExplosionPayloadRef.current = wipSubstitutesPayload;
    const substitutesBomExplosionApiCallPromise = needAdhocApiCall
      ? ProductService.fetchAdhocAdditionalProductsBOMExplosionDetails(
          wipSubstitutesPayload as any
        )
      : ProductService.fetchAdditionalProductsBOMExplosionDetails(
          wipSubstitutesPayload
        );

    substitutesBomExplosionApiCallPromise
      .then((wipSubstitutesData: any) => {
        let updatedBomExplosionData = parseBomExplosionResponseToTree({
          data: bomExplosionData,
          isEditMode: props.isEditMode,
          activeWorkOrder: props.activeWorkOrder,
          substituteBomExplosionData: wipSubstitutesData,
          showSubstituteOnly: state.hideMainProductRowsWithSubstitute
        });

        updateComponentState({
          wipSubstitutesData,
          [state.showSubstituteOnlyComponentPopup
            ? 'bomExplosionDataForAvailableSubstitutePopup'
            : 'bomExplosionData']: updatedBomExplosionData,
          loading: false
        });
      })
      .catch((err) => {});
  }, [
    bomMetaCode,
    isAdhocEnabled,
    wipSubstitutesPayload,
    bomExplosionData,
    props.isEditMode,
    props.activeWorkOrder,
    state.hideMainProductRowsWithSubstitute,
    state.showSubstituteOnlyComponentPopup,
    updateComponentState
  ]);

  const dataChangeTrackingRef = useRef({
    workOrderData: null,
    warehouseProductsInfo: null,
    warehouseInfoProductCodes: null
  });
  useEffect(() => {
    let allProductCodes = state.bomExplosionData
      ? getNestedProductCodes(state.bomExplosionData)
      : [];

    if (
      (dataChangeTrackingRef.current?.workOrderData === props.activeWorkOrder &&
        dataChangeTrackingRef.current?.warehouseProductsInfo ===
          state.warehouseProductsInfo) ||
      !state.bomExplosionData
    ) {
      if (
        (Utility.isRRBTaggingEnabled() ||
          Utility.isWarehouseTaggingEnabled()) &&
        !Utility.isEmpty(allProductCodes) &&
        !isEqual(
          allProductCodes,
          dataChangeTrackingRef.current.warehouseInfoProductCodes
        )
      ) {
        fetchWareHouseStockByProductCode(allProductCodes);
      }

      return;
    }
    const newBomExplosionData = updateBomExplosionDataByWorkOrderData(
      state.bomExplosionData,
      props.activeWorkOrder,
      state.warehouseProductsInfo
    );

    dataChangeTrackingRef.current = {
      workOrderData: props.activeWorkOrder as any,
      warehouseProductsInfo: state.warehouseProductsInfo as any,
      warehouseInfoProductCodes: allProductCodes as any
    };

    updateComponentState({ bomExplosionData: newBomExplosionData });
  }, [
    props.activeWorkOrder,
    updateComponentState,
    state.bomExplosionData,
    state.warehouseProductsInfo,
    fetchWareHouseStockByProductCode
  ]);

  /********* GRID UI EFFECTS, SCROLL & LAYOUT *******
   *************************************************/
  useLayoutEffect(() => {
    if (!bomMaterialPopupContainerRef.current) return;

    const leftPanelRowElementList =
      bomMaterialPopupContainerRef.current.querySelectorAll(
        `.${LEFT_PANEL_ROW_IDENTIFIER}`
      );
    const gridRowElementList =
      bomMaterialPopupContainerRef.current.querySelectorAll(
        `.${RIGHT_PANEL_ROW_IDENTIFIER}:not(.row)`
      );

    const leftPanelHeader: HTMLDivElement | null =
      bomMaterialPopupContainerRef.current.querySelector(
        `#${LEFT_PANEL_HEADER_ID}`
      );
    const gridColumnContainer: HTMLDivElement | null =
      bomMaterialPopupContainerRef.current.querySelector(
        `[id^='dk-grid-column-holder_']`
      );

    if (gridColumnContainer) {
      gridColumnContainer.style.position = 'sticky';
      gridColumnContainer.style.top = '0';
      gridColumnContainer.style.zIndex = '1';
    }

    if (gridColumnContainer && leftPanelHeader) {
      const columnRowHeight =
        gridColumnContainer.getBoundingClientRect()?.height ||
        gridColumnContainer.clientHeight;
      leftPanelHeader.style.height = `${columnRowHeight}px`;
      leftPanelHeader.style.maxHeight = `${columnRowHeight}px`;
    }

    if (
      leftPanelRowElementList &&
      gridRowElementList &&
      leftPanelRowElementList.length === gridRowElementList.length
    ) {
      gridRowElementList.forEach((gridRow, index) => {
        gridRow.setAttribute('data-index', `${index}`);
        const gridRowHeight =
          gridRow.getBoundingClientRect()?.height || gridRow.clientHeight;

        const correspondingLeftPanelRow = leftPanelRowElementList[
          index
        ] as HTMLDivElement;
        correspondingLeftPanelRow.style.height = `${gridRowHeight}px`;
        correspondingLeftPanelRow.style.maxHeight = `${gridRowHeight}px`;
      });
    }
  });

  useEffect(() => {
    let preventGridScrollEvent = false,
      preventCustomScrollEvent = false;
    const leftPanelContainerRef =
      bomMaterialPopupContainerRef.current?.querySelector(
        `.bom-explosion-left-panel-scroll-container`
      );
    const gridHolderContainerRef =
      bomMaterialPopupContainerRef.current?.querySelector(
        `[id^='DK_GRID_HOLDER_']`
      );

    const handlePanelsScrollSync = (e: any, isScrollByGrid: boolean) => {
      if (isScrollByGrid && preventGridScrollEvent) {
        preventGridScrollEvent = false;
        return false;
      } else if (!isScrollByGrid && preventCustomScrollEvent) {
        preventCustomScrollEvent = false;
        return false;
      }

      if (isScrollByGrid && leftPanelContainerRef) {
        leftPanelContainerRef.scrollTop = e.target.scrollTop;
        preventCustomScrollEvent = true;
      } else if (!isScrollByGrid && gridHolderContainerRef) {
        gridHolderContainerRef.scrollTop = e.target.scrollTop;
        preventGridScrollEvent = true;
      }

      return true;
    };

    const onLeftPanelScroll = (e: any) => handlePanelsScrollSync(e, false);
    const onGridScroll = (e: any) => handlePanelsScrollSync(e, true);

    leftPanelContainerRef?.addEventListener('scroll', onLeftPanelScroll);
    gridHolderContainerRef?.addEventListener('scroll', onGridScroll);

    return () => {
      leftPanelContainerRef?.removeEventListener('scroll', onLeftPanelScroll);
      gridHolderContainerRef?.removeEventListener('scroll', onGridScroll);
    };
  });

  /**** BOM EXPLOSION HEADER/CONTEXT MENU UTILS
   *******************************************/
  const saveBomExplosion = () => {
    const rowWithInvalidValue = gridRows.find(
      (row) => !Utility.isEmptyObject(row.invalidFields)
    );

    if (rowWithInvalidValue) {
      showAlert(
        `Invalid product row found!`,
        rowWithInvalidValue[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME]
          ? `Please add minimum 1 quantity for following product: ${
              rowWithInvalidValue[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME]
            }, or remove if not required.`
          : `Bom explosion cannot be saved without selecting product, please either select a product or remove the newly added row.`
      );
      return;
    }

    let bomExplosionData: any = { ...(state.bomExplosionData || {}) };
    bomExplosionData.bomProductConfiguration =
      bomExplosionData?.bomProductConfiguration?.map((item: any) => {
        return {
          ...item,
          addToRequisition: false
        };
      });

    props.onSave(bomExplosionData);
    props.onBOMPOPRExists(false);
  };

  const updateLaterForWOItems = () => {
    let laterFlag = false;
    let productsIds =
      selectedProductList?.map((product: any) => product.productId) || [];
    let woItems: any = { ...(state.bomExplosionData || {}) };
    woItems?.bomProductConfiguration?.forEach((item: any) => {
      if (productsIds?.includes(item.productId)) {
        item.addToRequisition = true;
        laterFlag = true;
      } else {
        item.addToRequisition = false;
      }
    });
    props.onSave(woItems);
    if (laterFlag) {
      props.onBOMPOPRExists(true);
    } else {
      props.onBOMPOPRExists(false);
    }
  };

  const showSaveWorkOrderAlertWithCallback = (
    triggerDocType: DOC_TYPE,
    callback: (res: IWorkOrder[]) => void
  ) => {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 text-black border-m mr-r',
        onClick: () => {}
      },
      {
        title: 'Save current WO',
        className: 'bg-button text-white mr-r',
        onClick: () => {
          showLoader();
          addBulkWorkOrder(props.payloadForCurrentWO)
            .then((res) => {
              removeLoader();
              callback(res);
            })
            .catch((err) => {
              removeLoader();
            });
        }
      }
    ];
    let displayDocName = 'document';
    switch (triggerDocType) {
      case DOC_TYPE.WORK_ORDER:
        displayDocName = 'Work Order';
        break;
      case DOC_TYPE.STOCK_REQUEST:
        displayDocName = 'Stock Request';
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        displayDocName = 'Job Work Out';
        break;
    }
    showAlert(
      'Warning',
      `To create ${displayDocName} for product(s), you need to save the current Work Order first.`,
      buttons
    );
  };

  const createWOWithSelectedProducts = useCallback(
    async (selectedRowItems: any) => {
      try {
        const productCodes = filterWIPProductsOnly(selectedRowItems)?.map(
          (item: any) => item?.pid
        );
        if (Utility.isEmpty(productCodes)) return;

        showLoader('Fetching all product details...');
        let productListResponse = await ProductService.getProductsByProductIds(
          productCodes || [],
          true
        );
        removeLoader();

        const materialListForWO = productListResponse
          .map((productDetails: any) => {
            const selectedItemData = selectedRowItems?.find(
              (productItem: any) => {
                if (!productItem?.isExplodeSubstitute) {
                  return productItem?.productId === productDetails.id;
                } else {
                  return productItem?.productId === productDetails.productId;
                }
              }
            );

            if (!selectedItemData) return null;

            let shortFall =
              calculateShortfallForActionButton(selectedItemData) || 1;

            if (
              Utility.isNotEmpty(selectedItemData?.documentUOMSchemaDefinition)
            ) {
              shortFall = Utility.getUomWarehouseQuantity(
                shortFall,
                selectedItemData?.documentUOMSchemaDefinition
              );
            }
            let warehouse = RawMaterialHelper.getDefaultActiveWarehouse();
            if (Utility.isRRBTaggingEnabled()) {
              warehouse = props.activeWorkOrder?.targetWarehouse;
            }

            return {
              ...productDetails,
              woRequiredQuantity: shortFall,
              [REQUIRED_ITEM_TABLE.INNER_BOM_PRODUCT_DETAILS]:
                selectedItemData.innerBomProductDetails,
              parentWorkOrderCode: props.activeWorkOrder.workOrderCode,
              warehouseInventoryData: Utility.isRRBTaggingEnabled()
                ? [
                    {
                      advancedTrackingData: [],
                      quantity:
                        WarehouseManagementHelper.isRRBEnabledForWarehouse(
                          props.activeWorkOrder?.targetWarehouse
                        )
                          ? 0
                          : shortFall,
                      uomQuantity: shortFall,
                      warehouseCode: warehouse?.code,
                      warehouseName: warehouse?.name
                    }
                  ]
                : null
            };
          })
          .filter((productDetails: any) => Utility.isNotEmpty(productDetails));

        updateComponentState({
          showWOPopup: true,
          materialListForWO: materialListForWO
        });
      } catch (err) {
        removeLoader();
        dispatch(updateWOBomexplosionAssociation(null));
        console.error('Error fetching product details from WO...');
        return;
      }
    },
    [updateComponentState, dispatch]
  );

  /** When parent WO is force to continue in edit mode from Bom Explosion screen,
   * i.e. while creating child work orders,
   * we open the bom explosion window back along with child wo popup  */
  const prevWOBomExplosionObjRef = useRef(null);
  const currentWOProductId = props.activeWorkOrder.productCode;
  useEffect(() => {
    if (!woBOMExplosionObj || Utility.isEmpty(woBOMExplosionObj)) return;

    const bomExplosionProductId = woBOMExplosionObj[0]?.parentProductId;
    if (
      prevWOBomExplosionObjRef.current !== woBOMExplosionObj &&
      bomExplosionProductId === currentWOProductId
    ) {
      createWOWithSelectedProducts(woBOMExplosionObj);
      prevWOBomExplosionObjRef.current = woBOMExplosionObj;
    }
  }, [woBOMExplosionObj, createWOWithSelectedProducts, currentWOProductId]);

  const onTapBulkAutoCreateWO = () => {
    let WIPOrFGProducts = getFilteredRows().filter(
      (row: any) =>
        row.level === 1 &&
        (row.productType ?? row.type) === PRODUCT_TYPE.BILL_OF_MATERIALS
    );
    WIPOrFGProducts = payloadForWorkOrder(WIPOrFGProducts);
    updateComponentState({
      showProductSelectionPopup: true,
      headerActionButtonType: BOM_EXPLOSION_BUTTON_TYPE.WO,
      productsForActionBtnClick: WIPOrFGProducts ?? []
    });
  };

  const onTapCreateStockRequestFromHeader = async () => {
    const allProductCodes = selectedProductList.map((item: any) => item.pid);
    let stockRequestProducts: any[] = [];
    try {
      stockRequestProducts = await ProductService.getProductsByProductIds(
        allProductCodes
      );
      updateComponentState({
        stockRequestProducts: stockRequestProducts
      });
    } catch (err) {
      console.error('Error fetching product details: ', err);
      return;
    }

    if (!props.isEditMode && Utility.isEmpty(woSRAssociateData)) {
      showSaveWorkOrderAlertWithCallback(DOC_TYPE.STOCK_REQUEST, (res) => {
        const srData: any = parseStockRequestDataFromBomProducts({
          selectedProductList: selectedProductList,
          activeWorkOrder: props.activeWorkOrder,
          stockRequestProducts: stockRequestProducts
        });
        dispatch(
          updateStockRequestAssociation({
            woDetails: {
              id: res?.[0]?.['id'],
              documentCode: res?.[0]?.['workOrderCode'],
              documentSequenceCode: res?.[0]?.['documentSequenceCode']
            },
            rowItems: [...srData]
          })
        );
        props.forcefullyCloseWO();
      });
    } else {
      updateComponentState({
        showStockRequestPopup: true
      });
    }
  };

  const onTapCreatePOFromHeader = async (productList = selectedProductList) => {
    let updatedProductList: any[] = [];
    productList.forEach((product: any) => {
      const existingIndex = updatedProductList.findIndex(
        (updatedProduct: any) => updatedProduct?.pid === product?.pid
      );
      const productPath = product?.productHierarchicalPath || '';
      let productPaths: string = '';
      // TODO: push product paths in line items to use them while creating linkedDocuments
      if (existingIndex !== -1) {
        const totalAlloted =
          (updatedProductList[existingIndex]?.allotedQuantity || 0) +
          (product?.allotedQuantity || 0);
        const totalRequired =
          (updatedProductList[existingIndex]?.requiredQuantity || 0) +
          (product?.requiredQuantity || 0);
        productPaths = updatedProductList[existingIndex]?.productPaths
          ? updatedProductList[existingIndex]?.productPaths + ',' + productPath
          : productPath;

        updatedProductList[existingIndex] = {
          ...updatedProductList[existingIndex],
          allotedQuantity: Math.abs(totalAlloted),
          quantityRequired: Math.abs(totalRequired - totalAlloted),
          requiredQuantity: Math.abs(totalRequired - totalAlloted),
          productPaths: productPaths
        };
        delete updatedProductList[existingIndex]?.productHierarchicalPath;
      } else {
        product = {
          ...product,
          productPaths: productPath
        };
        delete product?.productHierarchicalPath;
        updatedProductList.push(product);
      }
    });
    const payloadData = await getPurchaseOrderPayloadFromProductData(
      updatedProductList,
      props.activeWorkOrder
    );

    dispatch(
      createBlankDraft({
        payloadData,
        draftType: DraftTypes.NEW
      })
    );
    updateComponentState({ poPrCreationInProgress: true });
  };

  const onTapCreatePRFromHeader = async (productList = selectedProductList) => {
    let updatedProductList: any[] = [];
    productList.forEach((product: any) => {
      const existingIndex = updatedProductList.findIndex(
        (updatedProduct: any) => updatedProduct?.pid === product?.pid
      );
      product = {
        ...product,
        documentSequenceCode: product?.productCode
      };
      const productPath = product?.productHierarchicalPath || '';
      let productPaths: string = '';

      if (existingIndex !== -1) {
        const totalAlloted =
          (updatedProductList[existingIndex]?.allotedQuantity || 0) +
          (product?.allotedQuantity || 0);
        const totalRequired =
          (updatedProductList[existingIndex]?.requiredQuantity || 0) +
          (product?.requiredQuantity || 0);
        productPaths = updatedProductList[existingIndex]?.productPaths
          ? updatedProductList[existingIndex]?.productPaths + ',' + productPath
          : productPath;

        updatedProductList[existingIndex] = {
          ...updatedProductList[existingIndex],
          allotedQuantity: Math.abs(totalAlloted),
          quantityRequired: Math.abs(totalRequired - totalAlloted),
          requiredQuantity: Math.abs(totalRequired - totalAlloted),
          productPaths: productPaths
        };
        delete updatedProductList[existingIndex]?.productHierarchicalPath;
      } else {
        product = {
          ...product,
          productPaths: productPath
        };
        delete product?.productHierarchicalPath;
        updatedProductList.push(product);
      }
    });
    const payloadData = await getPurchaseRequisitionPayloadFromProductData(
      updatedProductList,
      props.activeWorkOrder
    );
    dispatch(
      createBlankDraft({
        payloadData,
        draftType: DraftTypes.NEW,
        documentMode: DOCUMENT_MODE.NEW,
        validTillDate: ''
      })
    );
    updateComponentState({ poPrCreationInProgress: true });
  };

  const onTapBulkAutoCreatePOPR = (type: string) => {
    const parsedProductRows = parseProductRowsForAutoCreatePOPR(
      getFilteredRows(),
      type,
      nonAvailableProductDetailsById.current
    );

    updateComponentState({
      showProductSelectionPopup: true,
      headerActionButtonType: type,
      productsForActionBtnClick: parsedProductRows
    });
  };

  const onTapBulkRemoveProducts = async () => {
    const isConfirmed = await confirm(
      'Are you sure you want to remove selected component or substitute products?'
    );

    if (!isConfirmed) return;

    let bomExplosionData = state.bomExplosionData;

    selectedProductList.forEach((rowData) => {
      bomExplosionData = removeMaterialFromBomexplosion(
        bomExplosionData,
        rowData
      );
    });

    updateComponentState({
      bomExplosionData: bomExplosionData
    });
  };

  const bomExplosionHeaderActionsHandler = async (type: string, data?: any) => {
    switch (type) {
      case 'saveBomExplosion':
        saveBomExplosion();
        break;
      case 'saveLaterBOMExplosion':
        updateLaterForWOItems();
        break;
      case 'createWO':
        if (!props.isEditMode) {
          showSaveWorkOrderAlertWithCallback(DOC_TYPE.WORK_ORDER, (res) => {
            const componentProductBomExplosionData = Object.assign(
              {},
              ...selectedProductList,
              { parentProductId: props.activeWorkOrder.productCode }
            );
            props.handleWOCreationInNewMode(res, [
              componentProductBomExplosionData
            ]);
          });
        } else {
          createWOWithSelectedProducts(selectedProductList);
        }

        break;
      case 'createAutoWO':
        onTapBulkAutoCreateWO();
        break;
      case 'createStocksRequest':
        onTapCreateStockRequestFromHeader();
        break;
      case 'createPO':
        onTapCreatePOFromHeader();
        break;
      case 'createPR':
        onTapCreatePRFromHeader();
        break;
      case 'createAutoPO':
        onTapBulkAutoCreatePOPR(BOM_EXPLOSION_BUTTON_TYPE.PO);
        break;
      case 'createAutoPR':
        onTapBulkAutoCreatePOPR(BOM_EXPLOSION_BUTTON_TYPE.PR);
        break;
      case 'bulkRemove':
        onTapBulkRemoveProducts();
        break;
      case 'toggleShowOnlySubstitute':
        onToggleDisplayOnlySubstituteSetting();
        break;
      case 'assignSubstitutes':
        updateComponentState({
          showSubstituteOnlyComponentPopup: true,
          bomExplosionDataForAvailableSubstitutePopup: deepClone(
            state.bomExplosionData
          )
        });
        break;
      case 'addToPOPR':
        updateComponentState({
          addedToPOPR: !state?.addedToPOPR
        });
        break;
      case 'createJWO':
        const onlyWIPProducts = filterWIPProductsOnly(selectedProductList);
        createMultipleJWOForProducts(onlyWIPProducts, props.activeWorkOrder);
        break;
      default:
        break;
    }
  };

  const updateTrackingDetails = (
    allocationType: string,
    trackingDetails: any,
    selectedProductItem: any = state.selectedProductFromContext,
    isAutoAllocationFlow: boolean = false,
    substituteItem?: any
  ) => {
    let updatedData;
    if (
      allocationType === ADVANCE_TRACKING.BATCH ||
      allocationType === ADVANCE_TRACKING.SERIAL
    ) {
      let warehouseInventoryData = trackingDetails?.map((item: any) => {
        return { ...item, quantity: item?.qtyToFulfil ?? 0 };
      });

      updatedData = saveTrackingDetails(
        warehouseInventoryData,
        bomExplosionData,
        selectedProductItem,
        allocationType
      );
    }

    if (allocationType === ADVANCE_TRACKING.NONE) {
      let warehouseInventoryData = trackingDetails?.map((obj: any) => {
        return {
          ...obj,
          advancedTrackingData: []
        };
      });
      updatedData = saveTrackingDetails(
        warehouseInventoryData,
        bomExplosionData,
        selectedProductItem,
        allocationType
      );
    }

    if (allocationType === 'SUBSTITUTE') {
      if (
        isAutoAllocationFlow &&
        (substituteItem.advancedTracking === ADVANCE_TRACKING.BATCH ||
          substituteItem.advancedTracking === ADVANCE_TRACKING.SERIAL)
      ) {
        trackingDetails = trackingDetails?.map((item: any) => {
          return { ...item, quantity: item?.qtyToFulfil ?? 0 };
        });
      }
      const existingWOItem = props?.existingRows?.find((itemRow: any) => {
        return (
          itemRow.itemName.documentSequenceCode ===
          selectedProductItem?.productCode
        );
      });
      updatedData = saveTrackingDetails(
        trackingDetails,
        bomExplosionData,
        selectedProductItem,
        allocationType,
        isAutoAllocationFlow,
        substituteItem,
        existingWOItem
      );
    }

    const newBomExplosionData = updateBomExplosionDataByWorkOrderData(
      updatedData,
      props.activeWorkOrder,
      state.warehouseProductsInfo
    );

    updateComponentState({
      [state.showSubstituteOnlyComponentPopup
        ? 'bomExplosionDataForAvailableSubstitutePopup'
        : 'bomExplosionData']: newBomExplosionData,
      showTrackingPopup: false,
      needSubstituteAllocationPopup: false,
      selectedProductFromContext: null
    });
  };

  const handleOnCreateFromProductSelectionPopup = (selectedRowItems: any) => {
    updateComponentState({
      showProductSelectionPopup: false,
      headerActionButtonType: '',
      productsForActionBtnClick: []
    });
    switch (state.headerActionButtonType) {
      case BOM_EXPLOSION_BUTTON_TYPE.PO:
        onTapCreatePOFromHeader(selectedRowItems);
        return;

      case BOM_EXPLOSION_BUTTON_TYPE.PR:
        onTapCreatePRFromHeader(selectedRowItems);
        return;

      case BOM_EXPLOSION_BUTTON_TYPE.WO:
        // createWOWithSelectedProducts(selectedRowItems);

        if (!props.isEditMode) {
          showSaveWorkOrderAlertWithCallback(DOC_TYPE.WORK_ORDER, (res) => {
            const componentProductBomExplosionData = selectedRowItems?.map(
              (item: any) => {
                return {
                  ...item,
                  parentProductId: props.activeWorkOrder.productCode
                };
              }
            );
            props.handleWOCreationInNewMode(
              res,
              componentProductBomExplosionData
            );
          });
        } else {
          createWOWithSelectedProducts(selectedRowItems);
        }
        return;

      default:
        break;
    }
  };

  /** ************ GRID UTILS ***********
   **************************************/
  type BomExplosionFormatterObj = {
    rowData: any;
    rowIndex: number;
    columnData: Partial<IColumn>;
    columnKey: string;
    value?: any;
  };

  const onRowUpdate = (data: BomExplosionFormatterObj) => {
    updateBomExplosionDataByUniqueId({
      rowUniqueId: data.rowData.uniqueId,
      key: data.columnKey,
      value: data.rowData[data.columnKey]
    });
  };

  const onRowClick = (data: BomExplosionFormatterObj) => {
    if (
      data.columnData.columnCode === BOM_EXPLOSION_COLUMN_KEYS.UOM &&
      data?.rowData?.level === 1
    ) {
      let productCopy = { ...data?.rowData };
      let uoms = [];
      let filtered = uomList?.filter((uom: any) => {
        const keyToCompare = productCopy?.oldStockUOM ?? productCopy?.stockUom;
        return uom.id === keyToCompare;
      });
      if (!Utility.isEmpty(filtered)) {
        uoms.push({ ...filtered[0] });
      }
      if (
        !Utility.isEmpty(productCopy) &&
        !Utility.isEmpty(productCopy.uomSchemaDto)
      ) {
        let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
          (uomSchema: any) => {
            let filteredFromList = uomList?.filter(
              (uom: any) => uom.id === uomSchema.sinkUOM
            );
            let name = '';
            if (filteredFromList && filteredFromList.length > 0) {
              name = filteredFromList[0].name;
            }
            return {
              ...uomSchema,
              name: name,
              id: uomSchema?.sinkUOM
            };
          }
        );
        uoms = uoms.concat(processedUoms);
      }
      // @ts-ignore
      data.columnData.dropdownConfig.data = uoms ?? [];
    }
  };

  function getGridColumns() {
    let columns: IColumn[] = deepClone(bomExplosionColumnConfig);

    const hasServiceProduct = gridRows?.some(
      (row) => row?.level === 1 && row?.productType === PRODUCT_TYPE.NON_TRACKED
    );

    const hasWipProduct = gridRows?.some(
      (row) => row?.productType === PRODUCT_TYPE.BILL_OF_MATERIALS
    );

    if (state.showSubstituteOnlyComponentPopup && hasWipProduct) {
      columns.unshift(BOM_EXPLOSION_CUSTOM_EXPAND_COLUMN_CONFIG as any);
    }
    columns.forEach((column: Partial<IColumn>) => {
      const key = column.key as BOM_EXPLOSION_COLUMN_KEYS;
      column.editable = false;
      column.freezed = moduleFreezedColumns.includes(column.id);

      if (
        [
          BOM_EXPLOSION_COLUMN_KEYS.LEAD_TIME,
          BOM_EXPLOSION_COLUMN_KEYS.RECEIVED_BY
        ].includes(key)
      ) {
        column.hidden = !isAnyProductShortFall;
      }

      if (
        [
          BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY_TARGET_WH,
          BOM_EXPLOSION_COLUMN_KEYS.TOTAL_AVAILABLE_STOCK_IN_TAGGED_WH
        ].includes(key)
      ) {
        column.hidden = !(
          Utility.isBinAllocationMandatory() ||
          Utility.isWarehouseTaggingEnabled()
        );
      }

      column.formatter = (data: BomExplosionFormatterObj) => {
        let displayValue = Utility.isNullish(data.value) ? '-' : data.value;
        const isServiceProduct =
          data.rowData?.productType === PRODUCT_TYPE.NON_TRACKED;

        if (
          isServiceProduct &&
          [
            BOM_EXPLOSION_COLUMN_KEYS.ALLOTED_QUANTITY,
            BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_QUANTITY,
            BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY,
            BOM_EXPLOSION_COLUMN_KEYS.LEAD_TIME,
            BOM_EXPLOSION_COLUMN_KEYS.TOTAL_AVAILABLE_STOCK_IN_TAGGED_WH
          ].includes(column.key as BOM_EXPLOSION_COLUMN_KEYS)
        ) {
          return data.rowData?.isRawMaterial
            ? '-'
            : `<span class='fw-m'>-</span>`;
        }

        if (column.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME) {
          displayValue = typeof displayValue === 'string' ? displayValue : '-';
          displayValue = `<span style="width:${
            data.rowData?.level * 6
          }px;display:inline-block;"></span>${displayValue}`;
        }

        if (column.key === BOM_EXPLOSION_COLUMN_KEYS.LEAD_TIME) {
          displayValue = !Utility.isNullish(data.value)
            ? `${displayValue} days`
            : displayValue;
        }

        if (column.key === BOM_EXPLOSION_COLUMN_KEYS.CHARGES) {
          displayValue =
            data.rowData?.productType === PRODUCT_TYPE.NON_TRACKED &&
            data.rowData?.level === 1
              ? Utility.amountFormatter(displayValue || 0, tenantInfo.currency)
              : '-';
        }

        if (column.key === BOM_EXPLOSION_COLUMN_KEYS.TOTAL_AMOUNT) {
          const costPerUnit = Number(data.rowData?.costPerUnit) || 0;
          /* Should be parent product required qty, in case need to show till nth level */
          const plannedQty =
            Number(props.activeWorkOrder?.manufactureQuantity) || 0;
          displayValue =
            data.rowData?.productType === PRODUCT_TYPE.NON_TRACKED &&
            data.rowData?.level === 1
              ? Utility.amountFormatter(
                  costPerUnit * plannedQty,
                  tenantInfo.currency
                )
              : '-';
        }

        if (column.key === BOM_EXPLOSION_COLUMN_KEYS.UOM) {
          displayValue =
            typeof displayValue === 'object'
              ? displayValue?.name
              : displayValue;
        }

        if (
          [
            BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY,
            BOM_EXPLOSION_COLUMN_KEYS.ALLOTED_QUANTITY
          ].includes(column.key as BOM_EXPLOSION_COLUMN_KEYS)
        ) {
          displayValue = Number(displayValue)
            ? NumberFormatService.getNumber(displayValue)
            : displayValue;
        }

        if (data.rowData && !data.rowData.isRawMaterial) {
          displayValue = `<span class='fw-m'>${displayValue}</span>`;
        }

        if (
          column.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME &&
          data.rowData &&
          data.rowData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]
        ) {
          displayValue = `<span class="row">${displayValue} <span class="fs-r text-align-left bg-chip-blue border-radius-l p-h-s ml-s p-v-xs display-inline">Subs</span></span>`;
        } else if (
          column.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME &&
          isServiceProduct
        ) {
          displayValue = `<span class="row">${displayValue} <span class="fs-r text-align-left data-grid-badge-color-4 border-radius-l p-h-s ml-s p-v-xs display-inline">Non-Tracked</span></span>`;
        }

        return displayValue;
      };

      switch (column.key) {
        case BOM_EXPLOSION_COLUMN_KEYS.EXPANDED:
          column.renderer = (data: BomExplosionFormatterObj) => {
            const isExpanded = data.rowData[BOM_EXPLOSION_COLUMN_KEYS.EXPANDED];

            // const isComponentOrSubstituteProduct = data.rowData.level === 1;
            const isWipOrFgProduct =
              data.rowData.productType === PRODUCT_TYPE.BILL_OF_MATERIALS ||
              data.rowData.type === PRODUCT_TYPE.BILL_OF_MATERIALS;
            // const isSubstitute =
            //   data.rowData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
            return state.showSubstituteOnlyComponentPopup &&
              isWipOrFgProduct ? (
              <DKIcon
                src={DKIcons.ic_arrow_down2}
                className="cursor-hand ic-s opacity-7"
                style={{
                  margin: 'auto',
                  transition: 'rotate 0.15s ease-in-out',
                  rotate: isExpanded ? '0deg' : '-90deg'
                }}
                onClick={() => {
                  const updatedRowData = {
                    ...data.rowData,
                    [BOM_EXPLOSION_COLUMN_KEYS.EXPANDED]: !isExpanded
                  };
                  onRowUpdate({
                    rowIndex: data.rowIndex,
                    rowData: updatedRowData,
                    columnData: column,
                    columnKey: column.key || '',
                    value: !isExpanded
                  });
                }}
              />
            ) : (
              <div></div>
            );
          };
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION:
          column.hidden = Boolean(state.showSubstituteOnlyComponentPopup);
          column.renderer = (data: BomExplosionFormatterObj) => {
            if (props.isReadOnlyMode) return <></>;

            const currentValue =
              data.rowData[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION];

            const isComponentOrSubstituteProduct = data.rowData.level >= 1;
            return isComponentOrSubstituteProduct ? (
              <DKCheckMark
                color={'bg-blue'}
                className="mx-auto"
                isSelected={currentValue}
                onClick={() =>
                  updateBomExplosionDataByUniqueId({
                    rowUniqueId: data.rowData.uniqueId,
                    key: BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION,
                    value: !currentValue
                  })
                }
              />
            ) : (
              <DKTooltipWrapper
                content={`Option available only for main component or substitute products`}
                className="width-auto mx-auto"
                tooltipClassName="bg-deskera-secondary "
              >
                <div
                  className="row border-radius-s justify-content-center align-items-center unselectable bg-gray1 cursor-not-allowed"
                  style={{
                    width: 18,
                    height: 18,
                    border: '1.3px solid rgb(200, 200, 200)'
                  }}
                ></div>
              </DKTooltipWrapper>
            );
          };
          column.headerRenderer = (data: any) => {
            if (props.isReadOnlyMode) return <></>;
            return (
              <div
                className="mx-auto"
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  updateComponentState({
                    isMasterHeaderCheckboxSelected:
                      !state?.isMasterHeaderCheckboxSelected
                  });
                  updateBomExplosionDataByUniqueId({
                    rowUniqueId:
                      BOM_EXPLOSION_COLUMN_KEYS.MASTER_HEADER_CHECKBOX,
                    key: BOM_EXPLOSION_COLUMN_KEYS.MASTER_HEADER_CHECKBOX,
                    value: !state?.isMasterHeaderCheckboxSelected
                  });
                }}
              >
                <DKCheckMark
                  color={'bg-blue'}
                  isSelected={state?.isMasterHeaderCheckboxSelected}
                />
              </div>
            );
          };
          column.editable = false;
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_QUANTITY:
          column.name =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? `${column.name} (all WH)`
              : column.name;
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY_TARGET_WH:
          column.renderer = (data: BomExplosionFormatterObj) => {
            if (data.rowData?.productType === PRODUCT_TYPE.NON_TRACKED) {
              return (
                <DKLabel
                  text={'-'}
                  className="parent-width text-align-center"
                />
              );
            }

            const tooltipMessageForTargetWHAvailability =
              getTooltipMessageForTargetWHAvailability(
                data.rowData,
                props.activeWorkOrder
              );

            return (
              <div
                className="row parent-width text-align-center border-radius-s justify-content-center"
                style={{
                  backgroundColor: getAvailabilityStatusColor(data.value),
                  height: 24,
                  padding: 2
                }}
              >
                <DKLabel
                  style={{
                    whiteSpace: 'noWrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  text={getAvailabilityStatus(data.rowData, data.value)}
                  className="text-white fs-m fw-m"
                />
                {Utility.isNotEmpty(tooltipMessageForTargetWHAvailability) && (
                  <DKTooltipWrapper
                    content={tooltipMessageForTargetWHAvailability}
                    tooltipClassName="bg-deskera-secondary fw-r"
                    tooltipStyle={{
                      minWidth: 350
                    }}
                  >
                    <DKIcon
                      src={DKIcons.ic_warning_red}
                      className="ic-s position-absolute"
                      style={{ right: -20, bottom: -10 }}
                      onClick={() => {}}
                    />
                  </DKTooltipWrapper>
                )}
              </div>
            );
          };
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.AVAILABILITY:
          column.name =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? `${column.name} (all WH)`
              : column.name;
          column.renderer = (data: BomExplosionFormatterObj) =>
            data.rowData?.productType !== PRODUCT_TYPE.NON_TRACKED ? (
              <DKLabel
                style={{
                  whiteSpace: 'noWrap',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  backgroundColor: getAvailabilityStatusColor(data.value),
                  height: 24,
                  padding: 2
                }}
                text={getAvailabilityStatus(data.rowData, data.value)}
                className="text-align-center text-white border-radius-s fs-m fw-m"
              />
            ) : (
              <DKLabel text={'-'} className="parent-width text-align-center" />
            );
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.LINKED_WO_CODE:
          column.renderer = (data: BomExplosionFormatterObj) => (
            <DKButton
              title={data.value || '-'}
              className={'fw-m ' + (data.value ? 'text-underline' : '')}
              style={{
                padding: 0
              }}
              onClick={() =>
                data.value &&
                props.handleDetailOpenerOnLinkedRecordTap({
                  documentCode: '',
                  documentSeqCode: data.value,
                  documentType: DOC_TYPE.WORK_ORDER,
                  showDetailsOpener: true
                })
              }
            />
          );
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.LINKED_DOC_CODE:
          const woInsufficientSettingForPO =
            Utility.isEmpty(woInsufficientSetting) ||
            woInsufficientSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER;

          column.name =
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? 'PO/PR/SR Code'
              : woInsufficientSettingForPO
              ? 'PO Code'
              : 'PR Code';
          column.renderer = (data: BomExplosionFormatterObj) => (
            <DKButton
              title={data.value || '-'}
              className={'fw-m ' + (data.value ? 'text-underline' : '')}
              style={{
                padding: 0
              }}
              onClick={() => {
                const documentData = getLinkedPOPRCode(data.rowData, false);
                data.value &&
                  props.handleDetailOpenerOnLinkedRecordTap({
                    documentCode: documentData.documentCode,
                    documentSeqCode: data.value,
                    documentType: documentData.documentType,
                    targetWarehouse: props.activeWorkOrder.targetWarehouse,
                    showDetailsOpener: true,
                    documentUOMSchemaDefinition:
                      data?.rowData?.documentUOMSchemaDefinition
                  });
              }}
            />
          );
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.LINKED_JWO_DOC_CODE:
          column.renderer = (data: BomExplosionFormatterObj) => {
            let JwoList: any = data?.rowData?.linkedPOPRData?.filter(
              (row: any) => row.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
            );

            if (Utility.isEmpty(JwoList)) {
              return (
                <DKButton
                  title={'-'}
                  className={'fw-m '}
                  style={{
                    padding: 0
                  }}
                />
              );
            }
            return (
              <>
                {JwoList.map((jwoDoc: any) => {
                  return (
                    <DKButton
                      title={jwoDoc.documentSequenceCode || '-'}
                      className={'fw-m ' + 'text-underline'}
                      style={{
                        padding: 0
                      }}
                      onClick={() => {
                        const documentData = jwoDoc;
                        jwoDoc.documentSequenceCode &&
                          props.handleDetailOpenerOnLinkedRecordTap({
                            documentCode: documentData.documentCode,
                            documentSeqCode: jwoDoc.documentSequenceCode,
                            documentType: documentData.documentType,
                            targetWarehouse:
                              props.activeWorkOrder.targetWarehouse,
                            showDetailsOpener: true,
                            documentUOMSchemaDefinition:
                              data?.rowData?.documentUOMSchemaDefinition
                          });
                      }}
                    />
                  );
                })}
              </>
            );
          };
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME:
          if (isAdhocEnabled) {
            column.editable = true;
            column.dropdownConfig = {
              title: '',
              allowSearch: true,
              searchableKey: 'name',
              className: 'shadow-m',
              searchApiConfig: {},
              data: [],
              renderer: null,
              onSelect: (index: any, obj: any, rowIndex: any) => {}
            };

            column.dropdownConfig.data =
              BOMExplosionHelper.getFilteredComponentProductsForDropdown(
                productsArray?.current ?? [],
                bomExplosionData
              );
            // @ts-ignore
            column.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
              let endPoint =
                ApiConstants.URL.BASE +
                `products?search=${search}&limit=20&page=0&query=${
                  isAdhocEnabled ? '' : 'type!NONTRACKED,'
                }active=true,hasVariants=false`;
              return endPoint;
            };
            // @ts-ignore
            column.dropdownConfig.searchApiConfig.dataParser = (
              response: any
            ) => {
              let filteredData =
                BOMExplosionHelper.getFilteredComponentProductsForDropdown(
                  response?.content,
                  bomExplosionData
                );
              productsArray.current = filteredData;
              return filteredData;
            };
            column.dropdownConfig.renderer = (index: any, obj: any) => {
              return (
                <div
                  className={`column parent-width ${
                    obj.productType === PRODUCT_TYPE.BILL_OF_MATERIALS
                      ? 'fw-m'
                      : ''
                  }`}
                >
                  <DKLabel className="row text-left" text={obj.name} />
                  <DKLabel
                    className="text-left text-gray fs-s"
                    text={`Number: ${
                      obj.documentSequenceCode ? obj.documentSequenceCode : ''
                    }`}
                  />
                  {obj.hsnOrSacCode && (
                    <DKLabel
                      className="text-left text-gray fs-s"
                      text={`HSN/SAC: ${
                        obj.hsnOrSacCode ? obj.hsnOrSacCode : ''
                      }`}
                    />
                  )}
                </div>
              );
            };
            column.dropdownConfig.onSelect = (index: number, value: any) => {};
          }
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_CODE:
          column.width = (column.width as number) < 100 ? 100 : column.width;
          column.renderer = ({ rowData, value }: BomExplosionFormatterObj) => (
            <div className="row justify-content-between parent-size">
              <DKLabel text={value} />
              {rowData.allowAutoAllocateIcon &&
              !state.showSubstituteOnlyComponentPopup ? (
                <DKButton
                  title={'Allocate'}
                  className="text-blue text-underline fs-s action-wrapper cursor-hand"
                  style={{
                    padding: 4
                  }}
                  onClick={() => handleAutoAllocateFromContextMenu(rowData)}
                />
              ) : null}
              {Utility.isNotEmpty(
                rowData[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
              ) &&
              !rowData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE] &&
              state.showSubstituteOnlyComponentPopup &&
              !rowData.isParentASubstitute ? (
                <DKIcon
                  src={DKIcons.ic_product}
                  className="ic-s ml-r action-wrapper cursor-hand"
                  onClick={() =>
                    handleContextMenuActions(
                      rowData,
                      MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE
                    )
                  }
                />
              ) : null}
            </div>
          );
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY:
          if (isAdhocEnabled) {
            column.editable = true;
          }
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.CHARGES:
          if (isAdhocEnabled && hasServiceProduct) {
            column.editable = true;
            column.hidden = !isAdhocEnabled;
          }
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.TOTAL_AMOUNT:
          if (isAdhocEnabled && hasServiceProduct) {
            column.hidden = !isAdhocEnabled;
          }
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.UOM:
          if (isAdhocEnabled) {
            column.type = INPUT_TYPE.DROPDOWN;
            column.editable = true;
            column.dropdownConfig = {
              title: '',
              allowSearch: false,
              searchableKey: '',
              className: 'shadow-m',
              searchApiConfig: {},
              data: [],
              renderer: null,
              onSelect: (index: any, obj: any, rowIndex: any) => {}
            };
            column.dropdownConfig.renderer = (index: any, obj: any) => {
              return (
                <DKLabel className="row text-left" text={obj?.name ?? ''} />
              );
            };
          }
          break;
        case BOM_EXPLOSION_COLUMN_KEYS.ADVANCED_TRACKING:
          column.renderer = (data: BomExplosionFormatterObj) => {
            let color = '';
            if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.NONE) {
              color = 'data-grid-badge-color-2';
            } else if (
              data?.rowData?.advancedTracking === ADVANCE_TRACKING.BATCH
            ) {
              color = 'data-grid-badge-color-4';
            } else if (
              data?.rowData?.advancedTracking === ADVANCE_TRACKING.SERIAL
            ) {
              color = 'data-grid-badge-color-5';
            }
            return data.rowData?.productType !== PRODUCT_TYPE.NON_TRACKED ? (
              <DKLabel
                text={Utility.convertInTitleCase(
                  data?.rowData?.advancedTracking ?? '-'
                )}
                style={{
                  height: 24,
                  padding: 4
                }}
                className={`text-align-center border-radius-s ${color}`}
              />
            ) : (
              <DKLabel text="-" />
            );
          };

          break;

        case BOM_EXPLOSION_COLUMN_KEYS.ACTIONS:
          if (isAdhocEnabled) {
            column.width = 150;
          }
          break;
        default:
      }

      column.name = `${column.name}`;
    });

    /* Shifting Material ID column after material name in case of adhoc enabled */
    const materialIdColumnIndex = columns.findIndex(
      (column) => column.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_CODE
    );
    const materialNameColumnIndex = columns.findIndex(
      (column) => column.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_NAME
    );
    isAdhocEnabled &&
      shiftArrayElement(
        columns,
        materialIdColumnIndex,
        materialNameColumnIndex
      );
    return columns.filter((column) => !column.hidden);
  }

  const handleMouseOverRow = (e: MouseEvent, index: number) => {
    const leftPanelRow = bomMaterialPopupContainerRef.current?.querySelectorAll(
      `.${LEFT_PANEL_ROW_IDENTIFIER}`
    )?.[index];
    const rightPanelRow =
      bomMaterialPopupContainerRef.current?.querySelectorAll(
        `.${RIGHT_PANEL_ROW_IDENTIFIER}`
      )?.[index];

    leftPanelRow?.classList.add('-active-item');
    rightPanelRow?.classList.add('-active-item');
  };

  const handleMouseLeaveRow = (e: MouseEvent, index: number) => {
    const leftPanelRow = bomMaterialPopupContainerRef.current?.querySelectorAll(
      `.${LEFT_PANEL_ROW_IDENTIFIER}`
    )?.[index];
    const rightPanelRow =
      bomMaterialPopupContainerRef.current?.querySelectorAll(
        `.${RIGHT_PANEL_ROW_IDENTIFIER}`
      )?.[index];

    leftPanelRow?.classList.remove('-active-item');
    rightPanelRow?.classList.remove('-active-item');
  };

  const createMultipleJWOForProducts = async (
    productData: any[],
    workOrderData: IWorkOrder
  ) => {
    try {
      const payloadData = await getJwoDraftDocumentPayloadFromProductData({
        productData,
        workOrderData
      });
      let updatedJobWorkOutOrderItems = await getBomDetailsForMultipleProduct(
        payloadData.populateFormData.items
      );
      payloadData['populateFormData']['items'] = updatedJobWorkOutOrderItems;
      payloadData['populateFormData']['jobWorkOutOrderItems'] =
        updatedJobWorkOutOrderItems;
      dispatch(
        createBlankDraft({
          payloadData,
          draftType: DraftTypes.NEW
        })
      );
      updateComponentState({ poPrCreationInProgress: true });
      removeLoader();
    } catch (error) {
      removeLoader();
      console.error('fetching products', error);
    }
  };

  const getBomDetailsForMultipleProduct = async (data: any) => {
    let updatedData: any = [];
    await Promise.all(
      data.map(async (item: any, index: any) => {
        let allowQtyToBuild = 0;
        await ProductService.getBomDetailsWithQtyForJWO(item?.product?.id, 1)
          .then((response: any) => {
            let bomDetailsResponse = { ...response };
            allowQtyToBuild = bomDetailsResponse?.allowQuantityToBuild ?? 0;
          })
          .catch((error: any) => {
            allowQtyToBuild = 0;
            console.error('Error fetching BOM product details ', error);
          });
        updatedData.push({ ...item, allowQuantityToBuild: allowQtyToBuild });
      })
    );
    return updatedData;
  };

  const createJWOForProduct = async (
    productData: any,
    workOrderData: IWorkOrder
  ) => {
    try {
      const payloadData = await getJwoDraftDocumentPayloadFromProductData({
        productData,
        workOrderData
      });
      let updatedJobWorkOutOrderItems = await getBomDetailsForMultipleProduct(
        payloadData.populateFormData.items
      );
      payloadData['populateFormData']['items'] = updatedJobWorkOutOrderItems;
      payloadData['populateFormData']['jobWorkOutOrderItems'] =
        updatedJobWorkOutOrderItems;
      dispatch(
        createBlankDraft({
          payloadData,
          draftType: DraftTypes.NEW
        })
      );
      updateComponentState({ poPrCreationInProgress: true });
      removeLoader();
    } catch (error) {
      removeLoader();
      console.error('fetching products', error);
    }
  };

  const createWOWithBomSelection = async (
    productDetails: any,
    selectedProductRow: any
  ) => {
    if (!productDetails || !selectedProductRow) return;

    const isSubstitute =
      selectedProductRow[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
    const productCodeForLinkedSubstituteParent = isSubstitute
      ? selectedProductRow.parentProductRowData?.pid
      : null;

    try {
      if (Utility.isEmpty(RawMaterialHelper.getDefaultActiveWarehouse())) {
        showAlert(
          'Cannot proceed.',
          'No suitable warehouses are active currently for creating this WorkOrder.'
        );
        return;
      }

      if (!productDetails.selectedBom) {
        productDetails =
          populateProductDetailWithSelectedOrDefaultBom(productDetails);
      }

      showLoader('Please wait...');

      let payload = await RawMaterialHelper.createWorkOrderPayload(
        {
          ...selectedProductRow,
          shortFallQty:
            calculateShortfallForActionButton(selectedProductRow) ?? 0
        },
        productDetails,
        Utility.isBinAllocationMandatory() ||
          Utility.isWarehouseTaggingEnabled()
          ? selectedProductRow.taggedWHProductAvailabilityDetails
          : null,
        props.activeWorkOrder,
        true
      );

      const workOrdersResponseList: any = await addBulkWorkOrder(payload);
      const newWorkOrder = workOrdersResponseList?.[0];
      props.woCreateCallback?.(workOrdersResponseList, {
        isSubstituteProduct: isSubstitute,
        productCodeForSubstituteLink: productCodeForLinkedSubstituteParent
      });
      if (newWorkOrder) {
        updateComponentState({
          isInnerDocumentRelatedToCurrentWOSaved: false
        });
      }
      removeLoader();
      newWorkOrder
        ? showAlert(
            'Work Order Created!',
            `A new WO has been created for <span class="fw-b"> ${newWorkOrder.productName} (${newWorkOrder.productCode}) </span>. Please save current WO to link the created WO.`
          )
        : showAlert(
            'Something went wrong!',
            `Failed to raise work order with selected product, please try again.`
          );
    } catch (err) {
      removeLoader();
      showAlert(
        'Something went wrong!',
        `Failed to raise work order with selected product, please try again.`
      );
    }
  };

  const handleCreateWOFromContextMenu = async (productData: any) => {
    if (!productData?.pid) return;

    const products = await ProductService.getProductsByProductIds(
      [productData.pid],
      false,
      true,
      true
    );
    const productDetails = products?.[0];
    if (Utility.isEmpty(productDetails?.bomMetaDetailsList)) {
      showAlert(
        'No bom found!',
        'Looks like currently selected product have no valid bom configs.'
      );
      return;
    }

    if (productDetails.bomMetaDetailsList.length > 1) {
      updateComponentState({
        showBomSelector: true,
        selectedProductFromContext: productData,
        productDetailByIdForWO: productDetails
      });
    } else {
      createWOWithBomSelection(productDetails, productData);
    }
  };

  const handleAutoAllocateFromContextMenu = async (productData: any) => {
    if (!productData?.pid) return;

    const isSubstitute = productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE];
    let rawMaterialItem = productData[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]
      ? productData.parentProductRowData
      : productData;

    try {
      showLoader('Hold on! We are allocating the quantities meanwhile.');

      const productIds = [rawMaterialItem.pid];
      if (isSubstitute) {
        productIds.push(productData.productId);
      }

      const warehouseProductsByIdResponse =
        await ProductService.fetchWarehouseProductsByID(productIds);

      const rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
        (item: any) => item?.enable
      );

      let quantitiesAllocated: boolean[] = [];
      removeLoader();

      autoAllocateProduct({
        rawMaterialItem,
        warehouseProductsByIdResponse,
        activeWorkOrder: props.activeWorkOrder,
        allocationType: isSubstitute
          ? BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE
          : BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_ORIGINAL_PRODUCT,
        rrbEnabled,
        quantitiesAllocated,
        updateTrackingDetails
      });

      if (
        quantitiesAllocated?.length > 0 &&
        quantitiesAllocated.includes(true)
      ) {
        showToast(
          `We have auto allocated quantities for ${
            isSubstitute
              ? `${rawMaterialItem.productCode} substitutes`
              : productData.productCode
          }.`,
          TOAST_TYPE.SUCCESS
        );
      } else {
        if (rawMaterialItem.availableQuantity === 0) {
          showAlert(
            'No quantities allocated.',
            `No quantities were allocated for ${
              isSubstitute
                ? `${rawMaterialItem.productCode} substitutes`
                : productData.productCode
            } due to non availability`
          );
        } else {
          showAlert(
            'No quantities allocated.',
            `The quantity that you are trying to allocate is either reserved in another transaction or pending for QA for ${
              isSubstitute
                ? `${rawMaterialItem.productCode} substitutes`
                : productData.productCode
            }.`
          );
        }
      }
    } catch (err) {
      removeLoader();
    }
  };

  function handleContextMenuActions(
    productData: any,
    actionType: MORE_CELL_OPTIONS
  ) {
    switch (actionType) {
      case MORE_CELL_OPTIONS.ADD_TO_PO:
      case MORE_CELL_OPTIONS.ADD_TO_PR:
      case MORE_CELL_OPTIONS.REMOVE_PO:
      case MORE_CELL_OPTIONS.REMOVE_PR:
        updateBomExplosionDataByUniqueId({
          rowUniqueId: productData.uniqueId,
          key: BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION,
          value: !productData[BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_SELECTION]
        });
        break;
      case MORE_CELL_OPTIONS.CREATE_JWO:
        createJWOForProduct(productData, props.activeWorkOrder);
        break;
      case MORE_CELL_OPTIONS.CREATE_WO:
        /* Auto opening of child wo, only available for first level work order childs */
        if (!props.isEditMode && Utility.isNotEmpty(woBOMExplosionObj)) {
          showAlert(
            `Can't Create WO?`,
            'Please save current work order first to create child work order.'
          );
          return;
        }

        if (!props.isEditMode) {
          showSaveWorkOrderAlertWithCallback(DOC_TYPE.WORK_ORDER, (res) => {
            const componentProductBomExplosionData = {
              ...productData,
              parentProductId: props.activeWorkOrder.productCode
            };
            props.handleWOCreationInNewMode(res, [
              componentProductBomExplosionData
            ]);
          });
        } else {
          handleCreateWOFromContextMenu(productData);
        }
        break;
      case MORE_CELL_OPTIONS.VIEW_LINKED_DOCS:
      case MORE_CELL_OPTIONS.VIEW_LINKED_DOCS_WITH_SR:
        updateComponentState({
          selectedProductFromContext: productData,
          showLinkedDocPopup: true
        });
        break;
      case MORE_CELL_OPTIONS.ALLOCATE_MATERIAL:
      case MORE_CELL_OPTIONS.ALLOCATE_BATCH:
      case MORE_CELL_OPTIONS.ALLOCATE_SERIAL:
      case MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE:
        updateComponentState({
          selectedProductFromContext: productData[
            BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE
          ]
            ? productData.parentProductRowData || productData
            : productData,
          showTrackingPopup: true,
          needSubstituteAllocationPopup:
            actionType === MORE_CELL_OPTIONS.ALLOCATE_SUBSTITUTE
        });
        break;
      case MORE_CELL_OPTIONS.SELECT_SUBSTITUTE:
        updateComponentState({
          showTrackingPopup: true,
          showAvailableSubstituteListPopup: true,
          selectedProductFromContext: productData
        });
        break;
      case MORE_CELL_OPTIONS.STOCK_TRANSFER:
        updateComponentState({
          selectedProductFromContext: {
            ...productData,
            quantityRequired: Utility.getUomWarehouseQuantity(
              productData?.requiredQuantity,
              productData?.documentUOMSchemaDefinition
            ),
            requiredQuantity: Utility.getUomWarehouseQuantity(
              productData?.requiredQuantity,
              productData?.documentUOMSchemaDefinition
            ),
            actualRequiredQty: Utility.getUomWarehouseQuantity(
              productData?.requiredQuantity,
              productData?.documentUOMSchemaDefinition
            )
          },
          showStockTransferPopup: true
        });
        break;
      case MORE_CELL_OPTIONS.ADD_MATERIAL:
        addMaterialRow(productData);
        break;
      case MORE_CELL_OPTIONS.DELETE_MATERIAL:
        onDeleteMaterialRow(productData);
        break;
      case MORE_CELL_OPTIONS.VIEW_ADVANCED_TRACKED_INFO:
        updateComponentState({
          showTrackingPopup: true,
          selectedProductFromContext: productData,
          needSubstituteAllocationPopup: false,
          showAllocatedTrackingDetailsView: true
        });
        break;
      default:
        break;
    }
  }

  const addMaterialRow = (productData: any) => {
    try {
      const newBomExplosionData = getAddMaterialBomExplosionDataByRowId(
        state.bomExplosionData,
        productData.uniqueId
      );
      updateComponentState({ bomExplosionData: newBomExplosionData });
    } catch (err) {
      console.error(err);
    }
  };

  const onDeleteMaterialRow = (rowData: any) => {
    let bomExplosionData = removeMaterialFromBomexplosion(
      state.bomExplosionData,
      rowData
    );

    updateComponentState({
      bomExplosionData: bomExplosionData
    });
  };

  const onSubstitutedChanged = (substitutes: any, selectedRow: any) => {
    try {
      const updatedSubstitutes = substitutes.map((substitute: any) => {
        substitute = { ...substitute };
        const keysToDelete = [
          'barcode',
          'customField',
          'reservedQuantity',
          'stockUomString',
          'weight'
        ];

        keysToDelete.forEach((keyToDelete) => delete substitute[keyToDelete]);
        return substitute;
      });
      substitutes = updatedSubstitutes;
    } catch (err) {}

    updateBomExplosionDataByUniqueId({
      rowUniqueId: selectedRow.uniqueId,
      key: BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES,
      value: substitutes
    });

    let newSelectedRowData = {
      ...selectedRow,
      [BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]: substitutes
    };
    try {
      const rowDataWithParsedKeys = parseBomExplosionResponseToTree({
        data: newSelectedRowData,
        majorIndex: newSelectedRowData.level ?? 1,
        activeWorkOrder: props.activeWorkOrder,
        isEditMode: props.isEditMode,
        showSubstituteOnly: state.hideMainProductRowsWithSubstitute,
        substituteBomExplosionData: state.wipSubstitutesData
      });
      newSelectedRowData = rowDataWithParsedKeys;
    } catch (err) {}

    /* Automatically open product substitute allocation/pick popup just after substitutes candidate selection.. */
    updateComponentState({
      selectedProductFromContext: newSelectedRowData,
      showAvailableSubstituteListPopup: false,
      needSubstituteAllocationPopup: true
    });
  };

  const onToggleDisplayOnlySubstituteSetting = () => {
    const hideMainProductRowsWithSubstitute =
      !state.hideMainProductRowsWithSubstitute;
    Utility.setPersistentValue(
      LOCAL_STORAGE_KEYS.BOM_EXPLOSION_SUBSTITUTE_ONLY,
      hideMainProductRowsWithSubstitute
    );
    updateComponentState({
      hideMainProductRowsWithSubstitute,
      isMasterHeaderCheckboxSelected: false
    });
  };

  const onColumnUpdated = async (data: any) => {
    let { columnData, property } = data;
    const columnCode = columnData?.columnCode as string;
    let columnIndex = bomExplosionColumnConfig.findIndex(
      (column: any) => column.columnCode === columnCode
    );
    if (typeof columnData?.id !== 'undefined') {
      const isCustomField = columnCode?.startsWith('custom_');
      if (
        isCustomField &&
        columnData?.name !== bomExplosionColumnConfig[columnIndex].name
      ) {
        try {
          const customFieldId = columnCode?.split('_')[2];
          if (customFieldId) {
            const customFieldResponse =
              await CustomFieldService.getCustomFieldById(+customFieldId);
            if (customFieldResponse) {
              let customField = customFieldResponse;
              customField = {
                ...customField,
                label: columnData?.name
              };

              const updatedCustomField =
                await CustomFieldService.updateCustomField(
                  +customFieldId,
                  customField,
                  columnConfigTableId
                );
              if (updatedCustomField) {
                dispatch(
                  addBOMExplosionColumnConfig({
                    tableId: columnConfigTableId as string,
                    config: updatedCustomField?.newColumnConfig
                  })
                );
              }
            }
          } else {
            console.error('Error updating custom field');
          }
        } catch (err) {
          console.error('Error fetching custom field: ', err);
        }
      } else {
        let dataToUpdate = bomExplosionColumnConfig.find(
          (column: any) => column.columnCode === columnCode
        );
        dataToUpdate = {
          ...dataToUpdate,
          [property]: columnData[property]
        } as IColumn;
        updateColumnInfoAndStore(columnIndex, dataToUpdate);
      }
    }
  };

  const updateColumnInfoAndStore = (columnIndex: number, columnData: any) => {
    updateColumnInfo(columnConfigTableId as string, columnData);
    const currentColumnsConfig = [...bomExplosionColumnConfig];
    currentColumnsConfig[columnIndex] = columnData;
    showLoader();
    dispatch(updateColumnConfig(currentColumnsConfig));
    removeLoader();
  };

  const onColumnShifted = (data: any) => {
    let { newIndex, oldIndex } = data;
    const columns = deepClone(bomExplosionColumnConfig);

    const findOldColumnInGridColumns = gridColumns?.find(
      (col: any, index: number) => oldIndex === index
    );
    const findOldIndexInAllColumns = columns?.findIndex(
      (col: any, index: number) =>
        col.columnCode === findOldColumnInGridColumns?.columnCode
    );

    const findNewColumnInGridColumns = gridColumns?.find(
      (col: any, index: number) => newIndex === index
    );
    const findNewIndexInAllColumns = columns?.findIndex(
      (col: any, index: number) =>
        col.columnCode === findNewColumnInGridColumns?.columnCode
    );

    let newColumnArray = shiftArrayElement(
      columns,
      findOldIndexInAllColumns,
      findNewIndexInAllColumns
    );
    const updatedConfigArray = newColumnArray.filter(
      (x) => typeof x !== 'undefined'
    );
    const columnIdArray = updatedConfigArray.map(
      (config) => config.id
    ) as string[];
    updateColumnShift(columnConfigTableId as string, columnIdArray);
    showLoader();
    dispatch(updateColumnConfig(updatedConfigArray));
    removeLoader();
  };

  const onColumnFreezeToggle = async (data: {
    column: IColumn;
    freezed: boolean;
  }) => {
    if (!data?.column?.id) return;
    try {
      let freezedColumnsByModule = moduleFreezedColumns || [];

      if (data.freezed) {
        freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
      } else {
        freezedColumnsByModule = freezedColumnsByModule.filter(
          (columnId: string) => columnId !== data.column.id
        );
      }

      const payload = {
        freezedColumns: {
          ...freezedColumns,
          [REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION]: freezedColumnsByModule
        }
      };
      dispatch(updateUserPreferences(payload));

      await TenantService.updateUserPreferences(payload);
      dispatch(fetchUserPreferences());
    } catch (err) {
      console.error(
        'Failed to update user preferences for freezing column: ',
        err
      );
    }
  };

  /***************** RENDER UTILS *************
   ********************************************/
  const getProductSelectionPopupView = () => {
    return (
      <DynamicPopupWrapper>
        <ProductSelectionPopup
          allProducts={state.productsForActionBtnClick ?? []}
          actionButtonType={state.headerActionButtonType}
          onClose={() => {
            updateComponentState({
              showProductSelectionPopup: false
            });
          }}
          onCreateActionBtnClick={handleOnCreateFromProductSelectionPopup}
        />
      </DynamicPopupWrapper>
    );
  };

  const getLinkedDocPopup = () => {
    return (
      <LinkedDocsPopup
        selectedProduct={state.selectedProductFromContext}
        onCancel={() => {
          updateComponentState({
            showLinkedDocPopup: false,
            selectedProductFromContext: null
          });
        }}
        onLinkClick={(linkedDoc: any) => {
          props.handleDetailOpenerOnLinkedRecordTap({
            documentCode: linkedDoc.documentCode,
            documentSeqCode: linkedDoc.documentSequenceCode,
            documentType: linkedDoc.documentType,
            showDetailsOpener: true,
            targetWarehouse: props.activeWorkOrder?.targetWarehouse,
            documentUOMSchemaDefinition:
              state.selectedProductFromContext?.documentUOMSchemaDefinition
          });
        }}
      />
    );
  };

  const getWOPopupView = () => {
    return (
      <AddNewWorkOrder
        targetWarehouse={
          Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()
            ? props?.activeWorkOrder?.targetWarehouse
            : null
        }
        isFlowFromBomExplosion={true}
        // this props will be only required in case of BOM Explosion,
        // for carrying on source details for child wo's
        workOrderSourceDetails={
          props.activeWorkOrder?.workOrderSourceDetails ?? {
            workOrderSource: 'NONE'
          }
        }
        materialList={state.materialListForWO}
        onClose={() => {
          updateComponentState({
            showWOPopup: false,
            materialListForWO: []
          });
          dispatch(updateWOBomexplosionAssociation(null));
        }}
        onSave={(res: any) => {
          updateComponentState({
            showWOPopup: false,
            materialListForWO: []
          });
          props.woCreateCallback(res, {});
          dispatch(updateWOBomexplosionAssociation(null));
        }}
        onGotoClick={() => {
          props?.forcefullyCloseWO();
        }}
      />
    );
  };

  const getBomSelectionView = () => (
    <BomSelector
      selectedProduct={state.productDetailByIdForWO}
      onCancel={() =>
        updateComponentState({
          showBomSelector: false,
          selectedProductFromContext: null
        })
      }
      onSelect={(bomConfig: any) => {
        const selectedProductRowData = state.selectedProductFromContext;
        updateComponentState({
          showBomSelector: false,
          selectedProductFromContext: null
        });
        const productDetailWithSelectedBom =
          populateProductDetailWithSelectedOrDefaultBom(
            state.productDetailByIdForWO,
            bomConfig
          );
        createWOWithBomSelection(
          productDetailWithSelectedBom,
          selectedProductRowData
        );
      }}
    />
  );

  const getStockRequestPopup = () => {
    const parsedStockRequestData = parseStockRequestDataFromBomProducts({
      selectedProductList: selectedProductList,
      activeWorkOrder: props.activeWorkOrder,
      stockRequestProducts: state.stockRequestProducts
    });
    return (
      <AddStockRequestPopup
        readOnly={false}
        records={[]}
        woDetails={{
          documentCode: props.activeWorkOrder?.workOrderCode,
          documentSequenceCode: props.activeWorkOrder?.documentSequenceCode
        }}
        rowItemsForNewDoc={parsedStockRequestData}
        selectedRecord={null}
        onSave={(res: any) => {
          updateComponentState({
            showStockRequestPopup: false,
            isInnerDocumentRelatedToCurrentWOSaved: false
          });
          showAlert(
            'Stock request created!',
            `Stock request ${res?.documentSequenceCode} created successfully.`
          );
          if (props.updateLinkedDocs) {
            const srItems = res?.stockRequestItems?.map((item: any) => {
              const itemInRequestData = parsedStockRequestData?.find(
                (data: any) => item?.productCode === data?.productVariantCode
              );
              if (!Utility.isEmpty(itemInRequestData)) {
                item = {
                  ...item,
                  product: {
                    ...item.product,
                    productPaths: itemInRequestData?.product?.productPaths || ''
                  }
                };
              }
              return item;
            });
            const productCodesArr = srItems?.map(
              (srItem: any) => srItem?.productCode
            );
            // Update productCodes
            let docType: any = DOC_TYPE.STOCK_REQUEST;
            let docCode = res?.stockRequestCode;
            if (getIsApprovalFlowEnabled(DOC_TYPE.STOCK_REQUEST)) {
              docType = 'STOCK_REQUEST_DRAFT';
              docCode = res?.stockRequestDraftCode;
            }
            let linkedLineItemsDetails: any = filterLinkedLineItems(srItems);
            props.updateLinkedDocs({
              documentType: docType,
              documentCode: docCode,
              documentSequenceCode: res?.documentSequenceCode,
              productCodes: productCodesArr,
              lineItemDetails: linkedLineItemsDetails
            });
          }
        }}
        onCancel={() => {
          updateComponentState({
            showStockRequestPopup: false
          });
        }}
      />
    );
  };

  const getStockTransferPopupView = () => {
    return state.selectedProductFromContext ? (
      <AddStockTransferPopup
        records={[]}
        document={DOC_TYPE.WORK_ORDER}
        selectedRecord={null}
        detail={{
          ...state.selectedProductFromContext,
          itemName: {
            ...state.selectedProductFromContext,
            name: state.selectedProductFromContext.productName,
            documentSequenceCode: state.selectedProductFromContext.productCode
          },
          actualRequiredQty: Number(
            state.selectedProductFromContext[
              BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY
            ]
          ),
          requiredQty: Number(
            state.selectedProductFromContext[
              BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY
            ]
          ),
          linkedDocuments: [
            {
              documentCode: props.activeWorkOrder.workOrderCode,
              documentSequenceCode: props.activeWorkOrder.documentSequenceCode,
              documentType: DOC_TYPE.WORK_ORDER
            }
          ]
        }}
        passingInteraction={() => {}}
        onSave={() => {
          updateComponentState({
            showStockTransferPopup: false
          });
        }}
        onCancel={() => {
          updateComponentState({
            showStockTransferPopup: false
          });
        }}
        allowFullScreen
      />
    ) : null;
  };

  const getProductStockAssignmentPopup = () => {
    return (
      <GetAssignmentPopupForTargetWarehouseWO
        isEditMode={props.isEditMode}
        workOrderProductId={props.activeWorkOrder?.productCode as string}
        activeWOtargetWarehouse={props.activeWorkOrder.targetWarehouse}
        existingItems={props.existingRows}
        needSubstituteAllocation={state.needSubstituteAllocationPopup}
        needAvailableSubstituteListPopup={
          state.showAvailableSubstituteListPopup
        }
        selectedProductRowForTracking={state.selectedProductFromContext}
        trackingItemParser={prepareBomExplosionProductForAllocation}
        onAssignmentSave={updateTrackingDetails}
        onAvailableSubstituteListSave={(selectedSubstitutes: any[]) =>
          onSubstitutedChanged(
            selectedSubstitutes,
            state.selectedProductFromContext
          )
        }
        onClose={() =>
          updateComponentState({
            showTrackingPopup: false,
            needSubstituteAllocationPopup: false,
            showAvailableSubstituteListPopup: false,
            selectedProductFromContext: null
          })
        }
        showAllocatedTrackingDetailsView={
          state.showAllocatedTrackingDetailsView
        }
        closeAllocatedTrackingDetailView={() => {
          updateComponentState({
            showAllocatedTrackingDetailsView: false,
            showTrackingPopup: false
          });
        }}
      />
    );
  };

  const getTaggedWHShortfallInfoTooltip = (nonTaggedWHStockSummary: any) => {
    let nonTaggedWHSummaryStr = '';
    if (!Utility.isEmpty(nonTaggedWHStockSummary)) {
      nonTaggedWHSummaryStr +=
        '<br><br>Quantities in other warehouse(s):<br><ul style="list-style-type: disc; margin-left: 15px; margin-top: 5px;">';
      nonTaggedWHStockSummary?.forEach((detail: any) => {
        nonTaggedWHSummaryStr += `<li><span class="fw-m">${detail?.wh}</span>: ${detail?.availableQuantity}</li>`;
      });
      nonTaggedWHSummaryStr += '</ul>';
    }
    let taggedWHMessage = (
      <DKTooltipWrapper
        content={
          getTaggedWHQtyMessage(props.activeWorkOrder?.targetWarehouse?.name) +
          (nonTaggedWHSummaryStr ? `${nonTaggedWHSummaryStr}` : '')
        }
        tooltipClassName="bg-deskera-secondary"
        tooltipStyle={{ minWidth: 350, marginLeft: -175, marginTop: 5 }}
      >
        <div className="row">
          <DKIcon
            src={DKIcons.ic_warning_red}
            className="ic-s mr-s"
            onClick={() => {}}
          />
        </div>
      </DKTooltipWrapper>
    );
    return taggedWHMessage;
  };

  const renderHeader = () => {
    return (
      <BomExplosionHeader
        bomExplosionData={state.bomExplosionData}
        isAddedToPOPR={state?.addedToPOPR}
        activeWorkOrder={props.activeWorkOrder}
        warehouseProductsInfo={state.warehouseProductsInfo}
        isReadOnlyMode={props.isReadOnlyMode}
        isAdhocEnabled={isAdhocEnabled}
        selectedProductList={selectedProductList}
        displayedProductRows={getFilteredRows()}
        woInsufficientSetting={woInsufficientSetting}
        hideMainProductRowsWithSubstitute={
          state.hideMainProductRowsWithSubstitute
        }
        onClose={() =>
          props.onClose(
            state.isInnerDocumentRelatedToCurrentWOSaved ? false : true
          )
        }
        updateTrackingDetails={updateTrackingDetails}
        onButtonClick={bomExplosionHeaderActionsHandler}
      />
    );
  };

  const plotLeftPanelProductRow = (productData: any, productIndex: number) => {
    const isSubstitute = Boolean(productData.isExplodeSubstitute);
    const isRawMaterial = Boolean(productData.isRawMaterial);
    const heirarchyLevel = productData.level;
    const rowIdSuffix = productData.rowIdSuffix;
    const taggedWHInventoryDetails: any =
      productData.taggedWHProductAvailabilityDetails;
    const availableQty = Utility.roundOffToTenantDecimalScale(
      productData.availableQuantity ?? 0
    );

    return (
      <div
        className={
          'bom-explosion-left-panel-row row position-relative justify-content-between border-s-b cursor-hand ' +
          (isRawMaterial
            ? ' listPickerBG '
            : productData.expanded
            ? ' listPickerBG '
            : ' bg-gray1')
        }
        style={{ minHeight: 35, flexShrink: 0 }}
        id={`${LEFT_PANEL_ROW_IDENTIFIER}${rowIdSuffix}`}
        onMouseOver={(e) => handleMouseOverRow(e, productIndex)}
        onMouseLeave={(e) => handleMouseLeaveRow(e, productIndex)}
        onClick={() => {
          if (isRawMaterial) return;

          updateBomExplosionDataByUniqueId({
            rowUniqueId: productData.uniqueId,
            key: 'expanded',
            value: !productData.expanded
          });
        }}
      >
        <div
          className="row"
          style={{ width: isSubstitute ? 'calc(100% - 35px)' : 'auto' }}
        >
          <DKTooltipWrapper
            content={`${productData.productName ?? '-'} (${
              productData.productCode ?? '-'
            }) (${isNaN(availableQty) ? 0 : availableQty})`}
            tooltipClassName="bg-deskera-secondary "
          >
            <div className="row p-v-s ">
              <div
                className={
                  ' border-radius-s ' +
                  (isSubstitute
                    ? 'bg-chip-blue'
                    : isRawMaterial
                    ? 'bg-yellow'
                    : 'bg-blue')
                }
                style={{
                  flexShrink: 0,
                  width: 12,
                  height: 12,
                  marginLeft: 15 * (heirarchyLevel + 1)
                }}
              />

              <DKLabel
                text={`${productData.productCode ?? '-'} (${
                  isNaN(availableQty) ? 0 : availableQty
                })`}
                className={
                  'ml-s ' + (isRawMaterial || isSubstitute ? '' : 'fw-m')
                }
                style={{
                  whiteSpace: 'noWrap',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>
          </DKTooltipWrapper>
        </div>
        <div className="row row-reverse width-auto mr-s">
          {!isRawMaterial && (
            <DKIcon
              src={DKIcons.ic_arrow_right2}
              className="ic-s opacity-5 pb-xs"
              style={{
                transition: 'rotate 0.15s ease-in-out',
                rotate: productData.expanded ? `90deg` : '0deg'
              }}
            />
          )}

          {/* {isAdhocEnabled && !props.isReadOnlyMode && !props.hasProductionEntry && (
            <DKButton
              title={''}
              className="ic-xs p-0 opacity-60"
              style={{
                paddingLeft: 0
              }}
              onClick={(e: any) => {
                e.stopPropagation?.();
                addMaterialRow(productData);
              }}
              icon={DKIcons.ic_add}
            />
          )} */}

          {/* {isAdhocEnabled &&
            !props.isReadOnlyMode &&
            productData.isBomRowEditModeEnabled &&
            (heirarchyLevel !== 0 || isSubstitute) && (
              <DKButton
                title={''}
                className="ic-xs p-0  opacity-60"
                style={{
                  paddingLeft: 0
                }}
                onClick={(e: any) => {
                  e.stopPropagation?.();
                  onDeleteMaterialRow(productData);
                }}
                icon={DKIcons.ic_delete}
              />
            )} */}

          {!isSubstitute && !isAvailable(productData) && (
            <DKTooltipWrapper
              content="Stock not available"
              tooltipClassName="bg-deskera-secondary "
            >
              <DKIcon src={ic_not_box} className="ic-s mr-s " />
            </DKTooltipWrapper>
          )}
          {heirarchyLevel !== 0 &&
            Utility.isNotEmpty(taggedWHInventoryDetails) &&
            !taggedWHInventoryDetails.allItemsAvailableInTaggedWH &&
            getTaggedWHShortfallInfoTooltip(
              taggedWHInventoryDetails.nonTaggedWHStockSummary
            )}
          {!isSubstitute &&
            !Utility.isEmpty(
              productData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES]
            ) &&
            !productData.isParentASubstitute && (
              <DKTooltipWrapper
                content="Substitute available for this product"
                tooltipClassName="bg-deskera-secondary "
              >
                <DKIcon
                  src={DKIcons.ic_product}
                  className="ic-s mr-s opacity-60"
                />
              </DKTooltipWrapper>
            )}
        </div>
      </div>
    );
  };

  const renderLeftPanel = () => {
    return (
      <div
        className="column border-s zIndex-1 bg-white position-relative"
        style={{
          borderWidth: '0px 1px 0px 0px',
          zIndex: 9,
          height: '100%',
          width: state.leftPanelExpanded ? LEFT_PANEL_WIDTH : 0,
          flexShrink: 0
        }}
      >
        <div
          className="row width-auto text-white align-items-center justify-content-center position-absolute z-index-2 opacity-6"
          style={{
            left: state.leftPanelExpanded ? LEFT_PANEL_WIDTH - 24 : 8,
            top: 4,
            rotate: state.leftPanelExpanded ? '90deg' : '-90deg',
            transition: 'rotate 0.15s ease-in-out'
          }}
          onClick={() =>
            updateComponentState({
              leftPanelExpanded: !state.leftPanelExpanded
            })
          }
        >
          <DKIcon
            src={DKIcons.ic_arrow_down2}
            className={`p-xs ic-xs-2 dk-sidebar-arrow-icon`}
          />
        </div>
        <div className="bom-explosion-left-panel-scroll-container column parent-width flex-1 overflow-y-scroll hide-scroll-bar">
          <div
            id={LEFT_PANEL_HEADER_ID}
            className="row bg-white border-s-b"
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              height: 36,
              paddingLeft: 8,
              paddingRight: 8,
              flexShrink: 0
            }}
          >
            <DKLabel
              text="Hierarchical BOM View"
              className="fw-m row parent-height"
            />
          </div>
          <div
            className="column parent-width flex-1"
            style={{
              paddingBottom: 2 /* to display last elements bottom border */
            }}
          >
            {getFilteredRows().map(plotLeftPanelProductRow)}
          </div>
        </div>
        {/* Empty div block to replicate grid bottom spacing */}
        <div
          className="row parent-width"
          style={{
            height: 38
          }}
        ></div>
      </div>
    );
  };

  const getBomGrid = (componentWithSubstituteOnly?: boolean) => {
    const rowsWithContextMenu = getFilteredRows(
      componentWithSubstituteOnly
    ).map((row) => {
      row['rowButtons'] = [];
      if (
        isAdhocEnabled &&
        !props.isReadOnlyMode &&
        !props.hasProductionEntry &&
        row.level === 0 &&
        !componentWithSubstituteOnly
      ) {
        row['rowButtons'].push({
          icon: DKIcons.ic_add,
          className: 'padding-button-custom',
          onClick: () => addMaterialRow(row)
        });
      }

      if (
        isAdhocEnabled &&
        !props.isReadOnlyMode &&
        row.level === 1 &&
        row.isBomRowEditModeEnabled &&
        !componentWithSubstituteOnly
      ) {
        row['rowButtons'].push({
          icon: DKIcons.ic_delete,
          className: 'padding-button-custom',
          onClick: () => onDeleteMaterialRow(row)
        });
      }
      return assignBomExplosionRowContextMenu(row, handleContextMenuActions, {
        woInsufficientSetting,
        jwoList: props.jwoList,
        isReadOnly: props.isReadOnlyMode,
        isAdhocEnabled: isAdhocEnabled,
        hasProductionEntry: props.hasProductionEntry,
        componentWithSubstituteOnly
      });
    });
    return (
      <DKDataGrid
        tableName={REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION}
        displayTableName={''}
        filterTableName={REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION}
        width={componentWithSubstituteOnly ? width : width - LEFT_PANEL_WIDTH}
        rows={rowsWithContextMenu}
        columns={gridColumns}
        styles={{
          mainGridHolder: { padding: 0 }
        }}
        needColumnIcons={false}
        needTrailingColumn={true}
        needBorder={false}
        needShadow={false}
        allowRowEdit={componentWithSubstituteOnly ? false : true}
        allowColumnEdit={componentWithSubstituteOnly ? false : true}
        allowColumnSort={false}
        allowBulkOperation={false}
        allowColumnShift={componentWithSubstituteOnly ? false : true}
        allowColumnFreeze={componentWithSubstituteOnly ? false : true}
        onColumnUpdate={onColumnUpdated}
        onColumnShift={onColumnShifted}
        onColumnFreezeToggle={onColumnFreezeToggle}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
      />
    );
  };

  const renderBomView = () => {
    return (
      <div
        className="row parent-width align-items-stretch bom-explosion show-scroll-bar"
        style={{
          height: `calc(100% - 60px)`
        }}
      >
        {renderLeftPanel()}
        {getBomGrid()}
      </div>
    );
  };

  const renderComponentsWithSubstitutesPopup = () => {
    return (
      <DynamicPopupWrapper>
        <div className="transparent-background">
          <div
            id="bom-explosion-component-substitute-popup"
            className="popup-window"
            style={{
              maxWidth: '95%',
              maxHeight: '90%',
              height: '90%',
              width: '95%',
              padding: 0,
              overflow: 'auto'
            }}
          >
            <div
              className="row justify-content-between bg-gray1 p-l pop-header-drag-handle"
              style={{ height: 60 }}
            >
              <DKLabel text={'Assign substitutes'} className="fw-m fs-l" />
              <div className="row width-auto ml-auto">
                <DKLabel
                  text="Substitutes only"
                  style={{ whiteSpace: 'nowrap' }}
                />
                <Toggle
                  isOn={state.hideMainProductRowsWithSubstitute}
                  onChange={() =>
                    bomExplosionHeaderActionsHandler('toggleShowOnlySubstitute')
                  }
                  className="box-content ml-s"
                />
                <DKButton
                  title="Cancel"
                  onClick={() => {
                    /* to refresh wip substitutes inner bom structure */
                    substituteBomExplosionPayloadRef.current = null;

                    updateComponentState({
                      showSubstituteOnlyComponentPopup: false,
                      bomExplosionDataForAvailableSubstitutePopup: null
                    });
                  }}
                  className="bg-white border-m ml-r"
                />
                <DKButton
                  title="Save"
                  onClick={() =>
                    updateComponentState({
                      showSubstituteOnlyComponentPopup: false,
                      bomExplosionData:
                        state.bomExplosionDataForAvailableSubstitutePopup,
                      bomExplosionDataForAvailableSubstitutePopup: null
                    })
                  }
                  className="bg-button text-white ml-r"
                />
              </div>
            </div>
            <DKLine />
            <div className="column parent-width flex-1">{getBomGrid(true)}</div>
          </div>
        </div>
      </DynamicPopupWrapper>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          id="bom-explosion-popup"
          className="bom-explosion-popup popup-window"
          ref={bomMaterialPopupContainerRef}
          style={{
            maxWidth: '95%',
            maxHeight: '93%',
            height: '93%',
            width: '95%',
            padding: 0,
            overflow: 'visible'
          }}
        >
          {renderHeader()}
          <DKLine />
          {Utility.isNotEmpty(state.bomExplosionData) && renderBomView()}
          {Utility.isEmpty(state.bomExplosionData) ? (
            state.loading ? (
              <DKSpinner
                title="Preparing bom explosion for work order material..."
                className={'align-self-center parent-size'}
              />
            ) : (
              <DKLabel
                text="Something went wrong while fetching BOM material details, please try re-opening the popup."
                className="row parent-size align-self-center justify-content-center"
              />
            )
          ) : null}
        </div>

        {state.showWOPopup && getWOPopupView()}
        {state.showProductSelectionPopup &&
          !Utility.isEmpty(state.headerActionButtonType) &&
          getProductSelectionPopupView()}
        {state.showLinkedDocPopup && getLinkedDocPopup()}
        {state.showStockRequestPopup && getStockRequestPopup()}
        {state.showStockTransferPopup && getStockTransferPopupView()}
        {state.showTrackingPopup && getProductStockAssignmentPopup()}
        {state.showBomSelector && getBomSelectionView()}
        {state.showSubstituteOnlyComponentPopup &&
          renderComponentsWithSubstitutesPopup()}
      </div>
    </DynamicPopupWrapper>
  );
};

export default BomExplosionPopup;
