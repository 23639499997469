import React from 'react'
import { getLocalisedText } from '../../Translate/LanguageManager';
import Utility, { getFontSizeClass } from '../../Utilities/Utility';
import { HeaderColumn } from '../dktable/HeaderColumn';
import { ItemColumn } from '../dktable/ItemColumn';
import { TableOption } from '../dktable/TableOption';
import { getFontWeight, getNameToId, getSortedHeaderList, getTextAlign } from '../dktable/TableUtility';


interface Props {
    tableOption: TableOption
    data: any
    headerColumn: HeaderColumn[]
    documentType?: string
    rawData: any
    tableColumnOptions: any
}


interface States {
    selectedRow: number | undefined
    selectedColumn: number | undefined
}
export default class DKLabelTable extends React.Component<Props, States> {
    render() {
        return (
            <table className='doc-container' id={'dk_table_' + (this.props.documentType !== undefined ? this.props.documentType : '')}>
                <thead style={{
                    borderCollapse: 'collapse',
                    textAlign: 'left'
                }}>
                    <tr className='row-cell' id={'row_header_' + (this.props.documentType !== undefined ? this.props.documentType : '')}>
                    </tr>
                </thead>
                <tbody>
                    {this.renderData()}
                </tbody>
            </table>
        )
    }

    renderData() {
        var records = this.props.data
        if (records === undefined || records === null) {
            return (<tr></tr>)
        }

        if (records.length === 0) {
            return (<tr></tr>)
        }
        else if (records.length > 0) {
            var columnData = records.map((element: string | ItemColumn, index: number) => {
                return this.renderColumnRow(element, index)
            });
            return columnData
        }
        else {
            return (<tr></tr>)
        }
    }

    renderColumnRow(rowData: any, rowIndex: number) {
        if (rowData === undefined || rowData === null) {
            return
        }
        if (rowData.length === 0) {
            return
        }
        var rowRecords = rowData.map((element: string | ItemColumn, index: number) => {
            var headerDefinition = this.props.headerColumn[index]
            return (
                <tr
                    className='row-cell'
                    id={'row_cell_' + (this.props.documentType ? this.props.documentType : '') + '_' + rowIndex}
                    style={{
                        textAlign: 'left'
                    }}
                >
                    {this.renderHeaderColumn(headerDefinition, false)}
                    {this.renderColumnItem(element, headerDefinition, index, rowIndex)}
                </tr>
            )
        });
        return rowRecords
    }

    renderColumnItem(item: string | ItemColumn, headerDefinition: HeaderColumn, columnIndex: number, rowIndex: number) {
        if (this.props.headerColumn === undefined || this.props.headerColumn === null) {
            return
        }

        var itemColumnValue = item as ItemColumn
        if (itemColumnValue.name !== undefined) {
            if (this.isUseHeaderStyle()) {
                return this.renderPlainColumn(itemColumnValue.value, headerDefinition, rowIndex, columnIndex, itemColumnValue.name)
            }
            else {
                return this.renderCustomStyleColumn(itemColumnValue, headerDefinition, rowIndex, columnIndex)
            }
        }

        var stringValue = item as string
        if (stringValue !== undefined) {
            return this.renderPlainColumn(stringValue, headerDefinition, rowIndex, columnIndex)
        }

        return (<div></div>)
    }

    renderHeader() {
        var headerList = this.props.headerColumn
        //sort data if the index exist
        if (this.props.documentType !== undefined) {
            headerList = getSortedHeaderList(headerList, this.props.tableColumnOptions)
        }
        if (headerList === undefined || headerList === null) {
            return (<th></th>)
        }
        if (headerList.length === 0) {
            return (<th></th>)
        }
        return headerList.map((element, index: number) => {
            var isLastIndex = false
            return this.renderHeaderColumn(element, isLastIndex)
        });
    }

    renderHeaderColumn(header: HeaderColumn, isLastIndex: boolean) {
        if (header.columnOption.isShowColumn) {
            return (
                <th
                    className='cell'
                    id={'header_' + getNameToId(header.name)}
                    style={{
                        width: header.style.width,
                        height: header.style.height,
                        background: header.style.backgroundColor,
                        textAlign: getTextAlign
                        (header.style.alignment),
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? header.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? header.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? header.style.borderStyle : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: header.style.paddingTop,
                        paddingBottom: header.style.paddingBottom,
                        paddingLeft: header.style.paddingLeft,
                        paddingRight: header.style.paddingRight,
                        marginTop: header.style.marginTop,
                        marginBottom: header.style.marginBottom,
                        marginLeft: header.style.marginLeft,
                        marginRight: header.style.marginRight,
                        verticalAlign: header.style.verticalAlignment,
                    }}>
                    <div style={{
                        position: 'relative',
                        textAlign: getTextAlign(header.style.alignment),
                    }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                            color: header.style.fontColor,
                            fontStyle: header.style.fontStyle,
                            paddingLeft: 0,
                            fontSize: header.style.fontSize,
                            fontWeight: getFontWeight(header.style.fontWeight),
                        }}>{getLocalisedText(header.label)}</text>
                        {!isLastIndex &&
                            <div
                                // className='column-resize'
                                style={{
                                    height: this.getRowHeaderHeight(),
                                    marginRight: header.style.paddingRight ? '-' + header.style.paddingRight : undefined,
                                    marginTop: header.style.paddingTop ? '-' + header.style.paddingTop : undefined,
                                }}
                            >
                            </div>
                        }
                    </div>
                </th>
            )
        }
    }


    getRowHeaderHeight() {
        var height = '100%'
        if (this.props.documentType === undefined) {
            return height
        }

        var headerElement = document.getElementById('row_header_' + this.props.documentType)
        if (headerElement !== undefined && headerElement !== null) {
            var styles = window.getComputedStyle(headerElement)
            if (styles.height !== undefined) {
                height = styles.height
            }
        }

        return height
    }

    renderCustomStyleColumn(element: ItemColumn, header: HeaderColumn, rowIndex: number, columnIndex: number) {
        if (header === undefined || header === null) {
            return (<td></td>)
        }
        if (header.columnOption.isShowColumn) {
            return (
                <td
                    className='cell'
                    id={'column_' + getNameToId(element.name)}
                    onMouseEnter={() => this.setState({ selectedColumn: columnIndex })}
                    onMouseLeave={() => this.setState({ selectedColumn: undefined })}
                    style={{
                        // width: element.columnOption.isUsingHeaderWidth ? header.style.width : element.style.width,
                        height: element.style.height,
                        background: element.style.backgroundColor,
                        // textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? element.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? element.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? element.style.borderStyle : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: element.style.paddingTop,
                        paddingBottom: element.style.paddingBottom,
                        paddingLeft: element.style.paddingLeft,
                        paddingRight: element.style.paddingRight,
                        marginTop: element.style.marginTop,
                        marginBottom: element.style.marginBottom,
                        marginLeft: element.style.marginLeft,
                        marginRight: element.style.marginRight,
                        verticalAlign: element.style.verticalAlignment,
                    }}>
                    <div style={{
                        display: 'block',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()}
                            dangerouslySetInnerHTML={{ __html: element.value }}
                            style={{
                                textAlign: element.columnOption.isUsingHeaderAlignment ? getTextAlign(header.style.alignment) : getTextAlign(element.style.alignment),
                                color: element.style.fontColor,
                                fontStyle: element.style.fontStyle,
                                fontSize: element.style.fontSize,
                                fontWeight: getFontWeight(element.style.fontWeight),
                                paddingLeft: 0,
                                direction: Utility.getIsSaudiArabiaCountry(this.props.rawData) ? 'rtl' : 'ltr',
                            }} />
                    </div>
                </td>
            )
        }
    }

    renderPlainColumn(value: string, header: HeaderColumn, rowIndex: number, columnIndex: number, name?: string) {
        var headerColumn = this.getHeaderColumn(rowIndex)

        if (header === undefined || header === null) {
            return (<td></td>)
        }
        if (header.columnOption.isShowColumn) {
            var id = name === undefined ? getNameToId(value) : getNameToId(name)
            return (
                <td
                    className='cell'
                    id={'column_' + id}
                    onMouseEnter={() => this.setState({ selectedColumn: columnIndex })}
                    onMouseLeave={() => this.setState({ selectedColumn: undefined })}
                    style={{
                        // width: header.style.width,
                        height: header.style.height,
                        background: headerColumn ? headerColumn.style.backgroundColor : header.style.backgroundColor,
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderWidth : header.style.borderWidth) : undefined,
                        borderColor: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderColor : header.style.borderColor) : undefined,
                        borderStyle: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderStyle : header.style.borderStyle) : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: header.style.paddingTop,
                        paddingBottom: header.style.paddingBottom,
                        paddingLeft: header.style.paddingLeft,
                        paddingRight: header.style.paddingRight,
                        marginTop: header.style.marginTop,
                        marginBottom: header.style.marginBottom,
                        marginLeft: header.style.marginLeft,
                        marginRight: header.style.marginRight,
                        verticalAlign: header.style.verticalAlignment,
                    }}>
                    <div style={{
                        display: 'block',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // textAlign: getTextAlign(header.style.alignment),
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()} style={{
                            textAlign: headerColumn ? getTextAlign(headerColumn.style.alignment) : getTextAlign(header.style.alignment),
                            color: headerColumn ? headerColumn.style.fontColor : header.style.fontColor,
                            fontStyle: headerColumn ? headerColumn.style.fontStyle : header.style.fontStyle,
                            fontSize: headerColumn ? headerColumn.style.fontSize : header.style.fontSize,
                            fontWeight: headerColumn ? getFontWeight(headerColumn.style.fontWeight) : getFontWeight(header.style.fontWeight),
                            direction: Utility.getIsSaudiArabiaCountry(this.props.rawData) ? 'rtl' : 'ltr',
                        }}>{value}</div>
                    </div>
                </td>
            )
        }
    }

    getHeaderColumn(rowIndex: number): ItemColumn | undefined {
        var columnList = this.props.data[rowIndex].filter((x: ItemColumn) => x.isHeaderColumn)
        if (columnList.length > 0) {
            return columnList[0]
        }
        return undefined
    }


    isShowTable() {
        return this.props.tableOption.isShowTable ? this.props.tableOption.isShowTable : false
    }
    isUseHeaderStyle() {
        return this.props.tableOption.isUseHeaderStyle
    }

    isShowBorder() {
        return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
    }

}
