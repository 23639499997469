import AppManager from "../Manager/AppManager";
import Utility, { getIsPayslip } from '../Utilities/Utility';
import {ERROR_MESSAGES} from '../Constants/Constants';
import CustomFieldManager from "../Manager/CustomFieldManager";
import TemplateRepository from "./TemplateRepository";
import {getLocalisedText} from "../Translate/LanguageManager";
import ApiConstants from "./ApiConstants";
import { showAlert } from "../Components/AlertComponent/Alert";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import { AlertType } from "../Helper/types";
import PeopleCustomFieldManager from "../Manager/PeopleCustomFieldManager";
import PayslipUtility from "../Templates/PayslipTemplates/PayslipUtility";

export default class ApiManager {
    static parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static getApiRequestOptions(method, body = null) {
        var requestOptions = {
            method: method,
            credentials: "include",
            withCredentials: true,
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // "x-access-token": ApiConstants.ACCESS_TOKEN,
            },
        };
        if (method === "POST" || method === "PUT") {
            body = JSON.stringify(body);
            requestOptions = { ...requestOptions, body };
        }
        return requestOptions;
    }

    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static checkIfUserLoggedIn(presenter) {
        fetch(
            ApiConstants.URL.BASE + ApiConstants.URL.LOGIN_STATUS,
            ApiManager.getApiRequestOptions("GET")
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.accessToken) {
                    ApiManager.tokenReceived(data.accessToken);
                }
                presenter.loginStatusApiResponseReceived();
            })
            .catch((error) => {
                presenter.loginStatusApiResponseReceived();
            });
    }


    static tokenReceived(accessToken) {
        let token = ApiManager.parseJwt(accessToken);
        ApiConstants.ACCESS_TOKEN = accessToken;
        ApiConstants.TENANT_ID = token.tenantId;
        ApiConstants.TENANT_NAME = token.website;
        ApiConstants.USER_EMAIL = token.email;
        ApiConstants.TENANT_COUNTRY = token.taxResidency ? token.taxResidency :(token.countryCode || "");
        ApiConstants.TENANT_CURRENCY = token.currency ? token.currency : "";
        AppManager.userLoggedIn();
    }

    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static checkIfSessionExpired(error, needToShowAlert = true) {
        if (error.toString() === "TypeError: Failed to fetch") {
            showAlert(getLocalisedText('session_expired_title'), getLocalisedText('session_expired_message'), [{title: getLocalisedText('login'), className:"bg-blue text-white", onClick:()=>{
                AppManager.gotoLoginPage()
            }}])
            // alert("Your session is expired. Please login again.");
            // AppManager.gotoLoginPage();
        } else {
            if (needToShowAlert) {
                if(error && error.message !== null){
                    // alert(error.message);
                    showAlert("Error!", error.message)
                }else {
                    alert(error);
                }
            }
        }
    }
    static sendEmail1(presenter, formdata) {

        const requestData = new FormData();

        requestData.append("body", formdata.body);
        let emails = formdata.recipientEmail.split(',')
        for (let i = 0; i < emails.length ; i++) {
            if (!Utility.isValidEmail(emails[i].trim())) {
                presenter.emailSendWithStatue(false, getLocalisedText('email_address') + " " + emails[i].trim() + " " + getLocalisedText('is_not_valid'))
                return
            }
            requestData.append("recipientEmail", emails[i].trim());
        }

        if (Utility.isValidEmail(formdata.replyTo)) {
            requestData.append("replyTo", formdata.replyTo);
        }

        if (Utility.isValidEmail(formdata.sendCopy)) {
            requestData.append("sendCopy", formdata.sendCopy);
        }

        requestData.append("senderName", formdata.senderName);
        requestData.append("subject", formdata.subject);
        requestData.append("attachmentName", formdata.attachmentName);
        requestData.append("attachment", formdata.attachment)

        var requestOptions = {
            method: 'POST',
            body: requestData,
            redirect: 'follow'
        };

        fetch(ApiConstants.URL.BASE +
            ApiConstants.URL.SEND_EMAIL, requestOptions)
            .then(response => response.text())
            .then(data => {
                if ((data.Errors && data.Errors.length > 0) || data.code) {
                    presenter.emailSendWithStatue(false, ERROR_MESSAGES.EMAIL_API_ERROR)
                } else {
                    presenter.emailSendWithStatue(true, ERROR_MESSAGES.EMAIL_API_SUCCESS)
                }
            })
            .catch(error =>
                presenter.emailSendWithStatue(false, ERROR_MESSAGES.EMAIL_API_ERROR)
            );
    }

    static getCustomFields(presenter) {
        fetch(
            ApiConstants.URL.BASE + ApiConstants.URL.CUSTOM_FIELDS,
            ApiManager.getApiRequestOptions("GET")
        )
            .then((response) => response.json())
            .then((data) => {
                CustomFieldManager.setMasterCustomFields(data.content)
            })
            .catch((error) => {
                console.log(error)
                // reached here when error
            });
    }

    static getDocumentDetails(invoiceId, presenter) {
        fetch(
            ApiConstants.URL.BASE +
            ApiConstants.URL.GET_INVOICE +"/"+invoiceId,
            ApiManager.getApiRequestOptions("GET")
        )
            .then((response) => response.json())
            .then((data) => {
                if(data.error !== undefined && data.message !== undefined && data.error !== null && data.message !== null){
                    // alert(data.message)
                    showAlert(AlertType.standard,"Error!", data.message)
                    presenter.documentResponseFailure()
                }else
                if ((data.Errors && data.Errors.length > 0) || data.code) {
                    alert("getDocumentDetails() ERROR")
                } else {
                    presenter.documentResponseSuccess(data)
                }
            })
            .catch((error) => {
                presenter.documentResponseFailure()
                ApiManager.checkIfSessionExpired(error, true);
            });
    }


    static getTemplateSettingsFromRemote(type, presenter, dataFromAPI) {
        if (getIsPayslip(type)) {
            PayslipUtility.getPeopleCustomFields();
        }

        let templateRepo = new TemplateRepository()
        templateRepo.fetchTemplateSettings (type,
            (response) => {
                if (response.length > 0) {
                    //Template Setting Data found
                    var list = response.sort((a, b) => a.id - b.id)
                    var defaultTemplate = list.find(x => x.defaultTemplate !== null && x.defaultTemplate === true)
                    TemplateSettingsManager.setCustomTemplateList(list)
                    var customTemplateId = TemplateSettingsManager.getRemoteTemplateUID()
                    var data = list[list.length - 1]
                    if (defaultTemplate !== undefined) {
                        data = defaultTemplate
                    }
                    if (customTemplateId !== undefined && customTemplateId !== null) {
                        var newData = list.find(x => x.id === Number(customTemplateId))
                        if(newData) {
                            data = newData
                        }
                    }
                    if (data.templateData) {
                        TemplateSettingsManager.updateRemoteTemplateUID(data.id)
                        TemplateSettingsManager.setPageFormat(data.pageFormat)
                        presenter.templateSettingsResponse(data, dataFromAPI)
                    } else {
                        presenter.templateSettingsResponse(null, dataFromAPI)
                    }
                } else {
                    //Template Setting Data Not found
                    if(AppManager.getDesignInfo()) {
                        presenter.templateSettingsListCallback(null)
                    }
                    else {
                        presenter.templateSettingsResponse(null, dataFromAPI)
                    }
                }
            },
            (error) => {
                if (AppManager.getDesignInfo()) {
                    presenter.templateSettingsListCallback(null)
                }
                else {
                    presenter.templateSettingsResponse(null, dataFromAPI)
                }
            })
    }

    static getTemplateSettings(type, presenter) {
        if (getIsPayslip(type)) {
            PayslipUtility.getPeopleCustomFields();
        }

        let templateRepo = new TemplateRepository()
        templateRepo.fetchTemplateSettings(type,
            (response) => {
                if (response.length > 0) {
                    //Template Setting Data found
                    var list = response.sort((a, b) => a.id - b.id)
                    var defaultTemplate = list.find(x => x.defaultTemplate !== null && x.defaultTemplate === true)
                    TemplateSettingsManager.setCustomTemplateList(list)
                    var customTemplateId = TemplateSettingsManager.getRemoteTemplateUID()
                    var data = list[list.length - 1]
                    if (defaultTemplate !== undefined) {
                        data = defaultTemplate
                    }
                    if (customTemplateId !== undefined && customTemplateId !== null) {
                        var newData = list.find(x => x.id === Number(customTemplateId))
                        if (newData) {
                            data = newData
                        }
                    }

                    presenter.templateSettingsListCallback(list, data)
                } else {
                    presenter.templateSettingsListCallback(undefined, undefined)
                }
            },
            (error) => {
                presenter.templateSettingsListCallback(undefined)
            })
    }

    static async getPeopleCustomFields() {
        try {
            const response = await fetch(
                ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE_CUSTOM_FIELDS,
                ApiManager.getApiRequestOptions("GET")
            );
            const responseJson = await response.json();
            if (responseJson.customFields) {
                PeopleCustomFieldManager.setMasterCustomFields(responseJson.customFields)
            }
        } catch (error) {
            console.log(error)
        }
    }

    static async getPeopleSectionCustomFields() {
        try {
            const body = {
                "configNames": ["EMPLOYEE_CONFIG"]
            };

            const response = await fetch(
                ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE_SECTION_CUSTOM_FIELDS,
                ApiManager.getApiRequestOptions("POST", body)
            );
            const responseJson = await response.json();
            if (responseJson.fieldConfigs) {
                PeopleCustomFieldManager.setMasterSectionCustomFields(responseJson.fieldConfigs)
            }
        } catch (error) {
            console.log(error)
        }
    }
}
