import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager"
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { getDateFrom, getDateString, getIsInvoice, getIsSalesOrder } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";
export class BOMTableItem {
    lineNumber: string;
    code: string;
    name: string;
    description: string;
    quantity: string;
    uom: string;
    quantityWithUom: string;
    cost: number;
    productCustomField: string;
    isNested: boolean

    currency: string;
    rawItemValue: any;
    data: any;
    constructor(lineItem: any, data: any, currency: string) {
        this.isNested = false
        this.currency = currency
        this.lineNumber = lineItem.lineNumber
        this.code = lineItem.code
        this.name = this.getProductName(data, lineItem)
        this.description = this.getProductDescription(lineItem)
        this.quantity = this.getProductQty(lineItem)
        this.uom = this.getProductUOM(lineItem)
        this.quantityWithUom = this.getQuantityWithUom(lineItem)
        this.cost = lineItem.cost ? lineItem.cost : 0
        this.productCustomField = ''
        this.data = data
    }

    getProductName(data: any, lineItem: any): string {
        var name = ''
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    name = getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        if (lineItem.name !== undefined && lineItem.name !== null) {
            name = getLocalisedText(lineItem.name)
        }

        if(lineItem.isParent) {
            name = name + ' (Parent)'
        } 
        return name
    }

    getProductDescription(lineItem: any): string {
        var desc = ''
        if (lineItem.description !== undefined && lineItem.description !== null) {
            desc = getLocalisedText(lineItem.description) 
        }

        if(!this.isNested) {
            desc = desc + this.getProductType(lineItem)
        }
        desc = desc + this.getBOMMaterial(lineItem, '', 1)
        if (desc !== '') {
            desc = desc + '\n'
        }
        desc = desc + this.getProductCost(lineItem, 1)
        desc = desc + this.getProductOperations(lineItem, 1)
        return desc
    }

    getProductType(lineItem: any): string {
        var type = ''
        if (lineItem.produceProductType && lineItem.produceProductType !== null) {
            if (lineItem.produceProductType === 'SCRAP') {
                type = 'Type: SCRAP'
            }
            else if (lineItem.produceProductType === 'COPRODUCT' || lineItem.produceProductType === 'CO-PRODUCT') {
                type = 'Type: CO-PRODUCT'
            }
        }

        return type
    }

    getBOMMaterial(lineItem: any, str: string, level: number): string {
        var bomString = str
        var parentString = ''
        var childString = ''
        var newString = ''
        if (lineItem.bomMaterials && lineItem.bomMaterials !== null) {
            if (lineItem.bomMaterials.length > 0) {
                lineItem.bomMaterials.forEach((element: any) => {
                    // bomString = bomString + level
                    parentString = parentString + this.getBOMMaterialName(element, level)
                    parentString = parentString + this.getProductCost(element, level)
                    parentString = parentString + this.getProductOperations(element, level)

                    if (element.bomMaterials && element.bomMaterials !== null) {
                        if (element.bomMaterials) {
                            level = level + 1
                            childString = childString + this.getBOMMaterial(element, childString, level)
                        }
                    }
                });
            }
        }

        if (childString !== '') {
            newString = bomString + parentString + '\n' + childString
        }
        else {
            newString = bomString + parentString 
        }

        return newString
    }

    getBOMMaterialName(element: any, level: number): string {
        var materialName = ''
        if(element.name && element.name !== null) {
            materialName = this.getLevelIndicator(level) + '<b>' + element.name + '</b> \n'
        }

        if (element.name && element.name !== null) {
            if (element.produceProductType && element.produceProductType !== null) {
                if (element.produceProductType === 'SCRAP' ) {
                    materialName = this.getLevelIndicator(level) + '<b>' + element.name + '</b> (SCRAP) \n'
                }
                else if (element.produceProductType === 'NONE') {
                    materialName = this.getLevelIndicator(level) + '<b>' + element.name + '</b> (CO-PRODUCT) \n'
                }
            }
            
        }

        return materialName
    }

    getLevelIndicator(level: number): string {
        var indicator = ''

        for (let index = 0; index < level; index++) {
            indicator = indicator + '-'
            
        }
        return ' ' + indicator + ' '
    }

    getLevelSpacer(level: number): string {
        var spacer = ''

        for (let index = 1; index < level; index++) {
            spacer = spacer + '&nbsp;'

        }
        return spacer
    }

    getProductCost(lineItem: any, level: number): string {
        var costString = ''
        if (lineItem?.costList?.length > 0) {
            var name = ' (' + lineItem.name + ') '
            if(this.isNested) {
                costString = this.getLevelSpacer(level) + 'Additional Cost' + name + '\n'
            }
            else {
                costString = 'Additional Cost\n'
            }
            
            lineItem.costList.forEach((cost: any) => {
                costString = costString + this.getLevelIndicator(level) + cost.label + " : " + Utility.toCurrencyFormat(cost.price, this.currency) + "\n";
            });
        }

        // if(costString !== '') {
        //     costString = '\n' + costString
        // }
        return costString
    }

    getProductOperations(lineItem: any, level: number): string {
        var operationString = ''
        if (lineItem.operationList.length > 0) {
            var name = ' (' + lineItem.name + ') '
            if (this.isNested) {
                operationString = this.getLevelSpacer(level) + 'Operations' + name + '\n'
            }
            else {
                operationString = 'Operations\n'
            }
            lineItem.operationList.forEach((operation: any) => {
                operationString = operationString + this.getLevelIndicator(level) + operation.title
                if (operation.operationTime > 0) {
                    operationString = operationString + ", Time(Mins): " + operation.operationTime
                }
                if(operation.costPerHour > 0) {
                    operationString = operationString + ", Cost Per Hour: " + Utility.toCurrencyFormat(operation.costPerHour, this.currency)
                }
                if (operation.fixedRate > 0) {
                    operationString = operationString + ", Fixed Rate: " + Utility.toCurrencyFormat(operation.fixedRate, this.currency)
                }
                if (operation.totalCost > 0) {
                    operationString = operationString + ", Total Cost: " + Utility.toCurrencyFormat(operation.totalCost, this.currency)
                }


                operationString = operationString + "\n";
            });
        }

        // if (operationString !== '' && operationString) {
        //     operationString = '\n' + operationString
        // }
        return operationString
    }


    getProductUOM(lineItem: any): string {
        var uom = lineItem.uom ? lineItem.uom : ''

        if (TemplateSettingsManager.getIsAlterUOMSelected(undefined)) {
            if (lineItem.alterUOM !== undefined && lineItem.alterUOM !== null) {
                return uom + ' ' + lineItem.alterUOM
            }
        }
        return uom
    }

    getQuantityWithUom(lineItem: any): string {
        var quantityWithUom = ''
        
        quantityWithUom = this.getProductUOM(lineItem)
        if(quantityWithUom === '') {
            quantityWithUom = this.getProductQty(lineItem)
        }
        else {
            quantityWithUom = this.getProductQty(lineItem) + ' x ' + quantityWithUom 
        }

        return quantityWithUom
    }

    getProductQty(lineItem: any): string {
        return isNaN(parseFloat(lineItem.quantity)) ? '' : Utility.toDecimalFormat(parseFloat(lineItem.quantity), TemplateSettingsManager.getQuantityDecimalScale())
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        const isCogsColumn = getIsInvoice(this.data?.type) || getIsSalesOrder(this.data?.type);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var footerList = TableFooterUtility.getFooterListByType('product')
                if (isCogsColumn) {
                    var index = footerList.indexOf(TableColumnPopupType.totalAmount);
                    footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
                }
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.productNameWithDescription:
                return '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description);
            case TableColumnPopupType.quantity:
                return this.quantity
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.quantityWithUom:
                return this.quantityWithUom ? this.quantityWithUom : ''
            case TableColumnPopupType.cost:
                return Utility.toCurrencyFormat(this.cost, this.currency)
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        if (cfType === "DATE") {
                            value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                        } else {
                            value = field.value
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
        
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description),
            Utility.toDecimalFormat(parseFloat(this.quantity), TemplateSettingsManager.getQuantityDecimalScale()),
            this.uom ? this.uom : '',
            this.quantityWithUom,
            Utility.toCurrencyFormat(this.cost, this.currency),
            this.productCustomField,
        ]
    }
}
