export const getSelectedRRBForBatch = (
  copyOfGridData: any,
  productDetails: any,
  rowData: any,
  rowIndex: any
) => {
  const sameWHAsProduct =
    rowData.warehouseCode.code === productDetails?.inventory?.warehouseCode;
  const selectedRow = sameWHAsProduct
    ? {
        code: productDetails?.inventory?.rowCode,
        name: productDetails?.inventory?.rowName
      }
    : null;
  const selectedRack = sameWHAsProduct
    ? {
        code: productDetails?.inventory?.rackCode,
        name: productDetails?.inventory?.rackName
      }
    : null;
  const selectedBin = sameWHAsProduct
    ? {
        code: productDetails?.inventory?.binCode,
        name: productDetails?.inventory?.binName
      }
    : null;
  copyOfGridData[rowIndex]['row'] = selectedRow;
  copyOfGridData[rowIndex]['rack'] = selectedRack;
  copyOfGridData[rowIndex]['bin'] = selectedBin;
  return copyOfGridData;
};

export const hasDuplicateSerialBatchNumbers = (data: any) => {
  const uniqueNumbers = new Set();
  const serialBatchNumbers = data.map((item: any) => item?.serialBatchNumber);
  for (const number of serialBatchNumbers) {
    if (uniqueNumbers.has(number) && number !== null) {
      return true;
    }
    uniqueNumbers.add(number);
  }
  return false;
};
