import React, { useEffect, useRef, useState } from 'react';
import {
  LABEL_DOCUMENT_TYPE,
  LabelTemplateData,
  processWOLabelTemplate
} from './LabelTemplateHelper';
import { useAppSelector } from '../../Redux/Hooks';
import { selectUsersList } from '../../Redux/Slices/PermissionsSlice';
import useLabelTemplate from './useLabelTemplate';
import { useReactToPrint } from 'react-to-print';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import { POPUP_CLICK_TYPE, POPUP_TYPE } from '../../Constants/Constant';
import {
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import LabelTemplateView from './LabelTemplateView';
interface WOGenerateLabelPopupProps {
  onCancel: () => void;
  documentType: LABEL_DOCUMENT_TYPE;
  documentData: any;
  hideProductField?: boolean;
}

const WOGenerateLabelPopup: React.FC<WOGenerateLabelPopupProps> = ({
  onCancel,
  documentType,
  documentData,
  hideProductField
}) => {
  const [labelTemplateToRender, setLabelTemplateToRender] =
    useState<LabelTemplateData>();
  const [lineItems, setLineItems] = useState<any>([]);
  const [selectedLineItem, setSelectedLineItem] = useState<any>();
  const usersListData = useAppSelector(selectUsersList);
  const printRef = useRef<any>(null);

  useEffect(() => {
    if (documentData && documentType) {
      let itemList = [];
      switch (documentType) {
        case LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING:
          if (hideProductField) {
            itemList = [documentData];
          } else {
            itemList = documentData;
          }
          break;
      }
      if (documentType != LABEL_DOCUMENT_TYPE.WORK_ORDER_LABEL_PRINTTING) {
        if (itemList.length) {
          itemList.forEach(
            (item: any) => (item.productName = item?.product?.name)
          );
        }
      }
      setLineItems(itemList);
      if (itemList?.length > 0) {
        setSelectedLineItem(itemList[0]);
      }
    }
  }, [documentData]);

  const {
    isLoading,
    templateList,
    labelTemplate,
    setLabelTemplate,
    reloadLabelTemplate
  } = useLabelTemplate(documentType);

  useEffect(() => {
    if (labelTemplate && selectedLineItem) {
      onLabelTemplateChange();
    }
  }, [labelTemplate, selectedLineItem]);

  let onLabelTemplateChange = () => {
    if (labelTemplate) {
      const updatedLabelTemplate = processWOLabelTemplate({
        labelTemplate,
        documentData,
        selectedLineItem,
        documentType,
        usersListData
      });

      setLabelTemplateToRender(updatedLabelTemplate);
    }
  };

  const handleThermalPrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true
  });

  return (
    <PopupWrapper
      type={POPUP_TYPE.POPUP}
      title={`Print Label`}
      btnList={[
        {
          title: 'Cancel',
          class: 'bg-gray1 border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: 'Print',
          class: 'bg-app text-white',
          clickAction: POPUP_CLICK_TYPE.PRINT_DOCUMENT
        }
      ]}
      clickAction={({ type }: any) => {
        if (type === POPUP_CLICK_TYPE.CLOSE_POPUP) {
          onCancel();
        } else if (type === POPUP_CLICK_TYPE.PRINT_DOCUMENT) {
          handleThermalPrint();
        }
      }}
      height={'auto'}
      width={600}
      disableClickOutside={true}
    >
      <div
        className="parent-width p-s justify-content-between border-radius-m"
        style={{ minHeight: 250 }}
      >
        <div
          className="row mt-xs"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
            className={''}
            value={labelTemplate}
            formatter={(obj: any) => {
              return obj.templateName;
            }}
            title="Label Template"
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => setLabelTemplate(value)}
            dropdownConfig={{
              title: 'Select Template',
              allowSearch: true,
              searchableKey: 'templateName',
              style: { minWidth: 250 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: templateList,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj.templateName}`} />;
              }
            }}
          />
          {(hideProductField == null || hideProductField == false) && (
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12, minWidth: 120 }}
              className={''}
              value={selectedLineItem}
              formatter={(obj: any) => {
                return obj.serialBatchNumber;
              }}
              title="Batch"
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => setSelectedLineItem(value)}
              dropdownConfig={{
                title: 'Select Batch',
                allowSearch: true,
                searchableKey: 'serialBatchNumber',
                style: { minWidth: 250 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: lineItems,
                renderer: (index: any, obj: any) => {
                  return <DKLabel key={index} text={`${obj.serialBatchNumber}`} />;
                }
              }}
            />
          )}
        </div>
        {isLoading ? (
          <DKSpinner />
        ) : labelTemplateToRender ? (
          <div className="mt-3 flex justify-content-center" ref={printRef}>
            <LabelTemplateView
              labelTemplate={labelTemplateToRender}
              isReadOnly={true}
            />
          </div>
        ) : (
          <DKLabel
            text="No label template to print."
            className="mt-l"
            style={{ textAlign: 'center' }}
          />
        )}
      </div>
    </PopupWrapper>
  );
};

export default WOGenerateLabelPopup;
