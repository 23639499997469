import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class PackingListTableWidth {

    isIdVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isBarcodeVisible: boolean;
    isUnitVisible: boolean;
    isPackedQuantityVisible: boolean;

    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchQuantityVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;
    isSelectedRowVisible: boolean;
    isSelectedRackVisible: boolean;
    isSelectedBinVisible: boolean;
    isProductCustomFieldVisible: boolean;

    id: number;
    code: number;
    name: number;
    description: number;
    nameWithDescription: number;
    barcode: number;
    unit: number;
    packedQuantity: number;

    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    batchQuantity: number;
    manufacturingDate: number;
    expiryDate: number;
    selectedRow: number;
    selectedRack: number;
    selectedBin: number;
    productCustomField: number;

    originalId: number;
    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalBarcode: number;
    originalUnit: number;
    originalPackedQuantity: number;

    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalBatchQuantity: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;
    originalSelectedRow: number;
    originalSelectedRack: number;
    originalSelectedBin: number;
    originalProductCustomField: number;

    productCustomFieldList: any;

    constructor() {
        this.isIdVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = true
        this.isBarcodeVisible = true
        this.isUnitVisible = true
        this.isPackedQuantityVisible = true
        this.isSerialBatchVisible = true

        this.isSerialBatchVisible = true
        this.isSerialNumberVisible = true
        this.isBatchNumberVisible = true
        this.isBatchQuantityVisible = true
        this.isManufacturingDateVisible = true
        this.isExpiryDateVisible = true
        this.isSelectedRowVisible = false
        this.isSelectedRackVisible = false
        this.isSelectedBinVisible = false
        this.isProductCustomFieldVisible = true

        this.id = 10
        this.code = 10
        this.name = 10
        this.description = 10
        this.nameWithDescription = this.name + this.description
        this.barcode = 5
        this.unit = 10
        this.packedQuantity = 10

        //40
        this.serialBatch = 5
        this.serialNumber = 5
        this.batchNumber = 5
        this.batchQuantity = 5
        this.manufacturingDate = 5
        this.expiryDate = 5
        this.selectedRow = 0
        this.selectedRack = 0
        this.selectedBin = 0
        this.productCustomField = 5

        this.originalId = 10
        this.originalCode = 10
        this.originalName = 10
        this.originalDescription = 10
        this.originalNameWithDescription = this.originalName + this.originalDescription
        this.originalBarcode = 5
        this.originalUnit = 10
        this.originalPackedQuantity = 10

        this.originalSerialBatch = 5
        this.originalSerialNumber = 5
        this.originalBatchNumber = 5
        this.originalBatchQuantity = 5
        this.originalManufacturingDate = 5
        this.originalExpiryDate = 5
        this.originalSelectedRack = 0
        this.originalSelectedRow = 0
        this.originalSelectedBin = 0
        this.originalProductCustomField = 5

    }

    setIsShowWidth(columnData: any) {
        this.isIdVisible = getIsShowTableColumn(columnData, TableColumnPopupType.id)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        this.isBarcodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.barcode)
        this.isUnitVisible = getIsShowTableColumn(columnData, TableColumnPopupType.unit)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isPackedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.packedQuantity)

        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSerialNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialNumber)
        this.isBatchNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchNumber)
        this.isBatchQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchQuantity)
        this.isManufacturingDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.manufacturingDate)
        this.isExpiryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expiryDate)
        this.isSelectedRowVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedRow)
        this.isSelectedRackVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedRack)
        this.isSelectedBinVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedBin)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.id = this.originalId
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription
        this.barcode = this.originalBarcode
        this.unit = this.originalUnit
        this.serialBatch = this.originalSerialBatch
        this.packedQuantity = this.originalPackedQuantity

        this.serialBatch = this.originalSerialBatch
        this.serialNumber = this.originalSerialNumber
        this.batchNumber = this.originalBatchNumber
        this.batchQuantity = this.originalBatchQuantity
        this.manufacturingDate = this.originalManufacturingDate
        this.expiryDate = this.originalExpiryDate
        this.selectedRow = this.originalSelectedRow
        this.selectedRack = this.originalSelectedRack
        this.selectedBin = this.originalSelectedBin

        this.productCustomField = this.originalProductCustomField

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.id = 0
        this.code = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        this.barcode = 0
        this.unit = 0
        this.packedQuantity = 0

        this.serialBatch = 0
        this.serialNumber = 0
        this.batchNumber = 0
        this.batchQuantity = 0
        this.manufacturingDate = 0
        this.expiryDate = 0
        this.selectedRow = 0
        this.selectedRack = 0
        this.selectedBin = 0
        this.productCustomField = 0
        
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isIdVisible) {
            totalWidth += this.id;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isBarcodeVisible) {
            totalWidth += this.barcode;
        }
        if (this.isUnitVisible) {
            totalWidth += this.unit;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isPackedQuantityVisible) {
            totalWidth += this.packedQuantity;
        }

        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isBatchQuantityVisible) {
            totalWidth += this.batchQuantity;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }
        if (this.isSelectedRowVisible) {
            totalWidth += this.selectedRow;
        }
        if (this.isSelectedRackVisible) {
            totalWidth += this.selectedRack;
        }
        if (this.isSelectedBinVisible) {
            totalWidth += this.selectedBin;
        }
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isIdVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isBarcodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isUnitVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isPackedQuantityVisible) {
            totalSelectedElement += 1
        }

        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedRowVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedRackVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedBinVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isIdVisible) {
            this.id = this.originalId + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        if (this.isBarcodeVisible) {
            this.barcode = this.originalBarcode + additionalWidth
        }
        if (this.isUnitVisible) {
            this.unit = this.originalUnit + additionalWidth
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isPackedQuantityVisible) {
            this.packedQuantity = this.originalPackedQuantity + additionalWidth
        }


        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth
        }
        if (this.isBatchQuantityVisible) {
            this.batchQuantity = this.originalBatchQuantity + additionalWidth
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth
        }
        if (this.isSelectedRowVisible) {
            this.selectedRow = this.originalSelectedRow + additionalWidth
        }
        if (this.isSelectedRackVisible) {
            this.selectedRack = this.originalSelectedRack + additionalWidth
        }
        if (this.isSelectedBinVisible) {
            this.selectedBin = this.originalSelectedBin + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}