import { useEffect, useRef, useState } from 'react';
import {
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppSelector } from '../../Redux/Hooks';
import { selectTemplatePrintInfo } from '../../Redux/Slices/CommonDataSlice';
import { populateScondaryOverlayContainer } from '../DocumentForm/NewDocumentHelper';
import PopupWrapper from '../PopupWrapper';
import EmailComposer from './EmailComposer';
import EmailDocPreview from './EmailDocPreview';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';

interface EmailProps {
  documentType: any;
  data: any;
  emailTemplateConfig?: any;
  onEmailDialogClose?: (val: boolean) => void;
  hideDocPreview?: boolean;
  disableUserInput?: boolean;
}

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { createCoa: 'click' }
};

export default function Email(props: EmailProps) {
  const sendEmailRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [printUUID, setPrintUUID] = useState<string>();
  const [docDesignerOpened, setDocDesignerOpened] = useState(false);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const actionBtns: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Send',
      class: 'bg-button text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.EMAIL_DOCUMENT
    }
  ];

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const handleActions = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        // Avoid setting email status, if user closes the email dialog manually
        // if (props.onEmailDialogClose) {
        //   props.onEmailDialogClose(true);
        // }
        populateScondaryOverlayContainer(<></>);
        break;
      case POPUP_CLICK_TYPE.EMAIL_DOCUMENT:
        sendEmailRef.current.storeCallbacksRef.sendEmail();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.EMAIL_DOCUMENT:
        sendEmailRef.current.storeCallbacksRef.sendEmail = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (props.onEmailDialogClose) {
          props.onEmailDialogClose(true);
        }
        populateScondaryOverlayContainer(<></>);
        break;
    }
  };

  let inLineCSS = {};
  if (props?.hideDocPreview) {
    inLineCSS = { width: '100%' };
  }
  return (
    <PopupWrapper
      clickAction={handleActions}
      type={POPUP_TYPE.POPUP}
      title={'Email'}
      btnList={actionBtns}
      width={isDesktop ? '80%' : '90%'}
      height={'90%'}
    >
      <div
        className={
          'flex flex-row row-responsive items-start content-start overflow-hidden h-full'
        }
      >
        <div
          className={
            'flex flex-col overflow-hidden h-full mobile-full-grid-width'
          }
          style={inLineCSS}
        >
          <EmailComposer
            documentType={props.documentType}
            document={props.data}
            printUUID={printUUID}
            placeholderConfig={props.emailTemplateConfig}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            onDocDesignerOpen={(isOpened: boolean) =>
              setDocDesignerOpened(isOpened)
            }
            disableUserInput={props?.disableUserInput}
          />
        </div>
        {!props?.hideDocPreview && (
          <div
            className={
              'flex flex-col p-2 items-center overflow-y-auto h-full mobile-full-grid-width'
            }
          >
            <EmailDocPreview
              documentType={props.documentType}
              document={props.data}
              onDocumentPrintUUIDChange={(uuid: string) => setPrintUUID(uuid)}
              isDocDesignerOpen={docDesignerOpened}
            />
          </div>
        )}
      </div>
    </PopupWrapper>
  );
}
