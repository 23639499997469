import { getIsBooksApp } from "../Utilities/Utility";

export default class ApiConstants {
  static getBaseUrl() {
    if (getIsBooksApp()) {
      return process.env.REACT_APP_BASE_URL + "v1/";
    } else {
      return process.env.REACT_APP_BASE_URL;
    }
  }

  static URL = {
    BASE: ApiConstants.getBaseUrl(),
    LOGIN_REDIRECT: process.env.REACT_APP_LOGIN_URL,
    SIGN_UP: process.env.REACT_APP_SIGN_UP_URL,
    BASE_WITHOUT_VERSION: process.env.REACT_APP_BASE_URL_NO_VERSION,
    DOC_BUILDER: process.env.REACT_APP_DOC_BUILDER_URL,

    LOGIN_STATUS: "iam/auth/sign-in/login/status",

    CUSTOM_FIELDS: "dimension?limit=1000&page=0",
    CREATE_CUSTOM_FIELDS: "settings/custom-fields",

    FILE_UPLOAD: "file/upload",
    ALL_UPLOADED_IMAGES: "file/upload/list",

    GET_INVOICE: "template/print-info",
    SEND_EMAIL: "anonymous/document-designer/email",
    ATTACHMENT_UPLOAD: "attachment/file-upload",
    TEMPLATE_SETTINGS: "template/document",
    GET_ERP_TEMPLATE_SETTINGS: "template/document/ERP/",
    GET_PEOPLE_TEMPLATE_SETTINGS: "template/document/PEOPLE/",
    GET_MRP_TEMPLATE_SETTINGS: "template/document/MRP/",
    CREATE_SHARE_LINK: "template/print-info",
    GET_SHARE_LINK: "template/deskera/open/print-info/",
    GET_PRINT_INFO: "template/deskera/open/print-payload",
    COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
    PEOPLE_CUSTOM_FIELDS: "people/tenants/custom-fields?limit=1000&currentPage=1&orderBy.order=desc&orderBy.field=_id&module=employee_details",
    PEOPLE_SECTION_CUSTOM_FIELDS: "people/tenants/field-config",
  };

  static TENANT_ID = null;
  static TENANT_NAME = null;
  static ACCESS_TOKEN = null;
  static USER_EMAIL = null;
  static TENANT_COUNTRY = null;
  static TENANT_CURRENCY = null;
  static LOCALHOST_URL = "localhost:3000";
}
