import ApiConstants from '../Constants/ApiConstants';
import { REPORTS_TAB } from '../Constants/Enum';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import { saveAs } from 'file-saver';
import { RolePermissionUtil } from '../RolePermission/RolePermissionUtil';
export interface CustomReportsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
}

export const defaultConfig: CustomReportsAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 1
};

class CustomReportsService {
  static apiConfig: CustomReportsAPIConfig;
  static reportTabName: REPORTS_TAB = REPORTS_TAB.STANDARD;

  static getTablesData() {
    const finalEndpoint: string =
      ApiConstants.URL.REPORT.BUILDER.GET_TABLE_DATA;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static executeReport(data: any) {
    if (Utility.isEmpty(CustomReportsService.apiConfig)) {
      CustomReportsService.apiConfig = defaultConfig;
    }

    if (!Utility.isEmpty(data?.filters)) {
      data?.filters.forEach((filter: any) => {
        if (filter?.dataType === 'STRING') {
          if (filter?.value.includes("'")) {
            filter.value = filter.value.replace(/(?<!')'(?!')/g, "''");;
          }
        }
      });
    }
    const finalURL =
      ApiConstants.URL.REPORT.BUILDER.EXECUTE +
      `?page=${CustomReportsService.apiConfig.Page}&limit=${CustomReportsService.apiConfig.Limit}`;
    return http
      .post(finalURL, data)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  static executeReportWithWarehouseRole(data: any) {
    if (Utility.isEmpty(CustomReportsService.apiConfig)) {
      CustomReportsService.apiConfig = defaultConfig;
    }
    let finalURL =
      ApiConstants.URL.REPORT.BUILDER.EXECUTE +
      `?page=${CustomReportsService.apiConfig.Page}&limit=${CustomReportsService.apiConfig.Limit}&filterOnRolesFlg=true`;
    // if (!RolePermissionUtil.checkIsAdmin()) {
    //   finalURL =
    //     ApiConstants.URL.REPORT.BUILDER.EXECUTE +
    //     `?page=${CustomReportsService.apiConfig.Page}&limit=${CustomReportsService.apiConfig.Limit}&filterOnRolesFlg=true`;
    // }
    return http
      .post(finalURL, data)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static fetchReportData() {
    return http.get(ApiConstants.URL.REPORT.BUILDER.REPORT_BUILDER).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static saveReport(payload: any) {
    return http
      .post(ApiConstants.URL.REPORT.BUILDER.REPORT_BUILDER, payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static updateReport(payload: any, uid: any) {
    const endPoint = `${ApiConstants.URL.REPORT.BUILDER.REPORT_BUILDER}/${uid}`;
    return http.put(endPoint, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static deleteReport(reportId: string) {
    const finalURL =
      ApiConstants.URL.REPORT.BUILDER.REPORT_BUILDER + '/' + reportId;
    return http.delete(finalURL).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static sendEmail1(requestData: any) {
    let requestOptions = {
      method: 'POST',
      body: requestData,
      redirect: 'follow'
    };

    let url = 'report-engine/email';

    return http.post(url, requestData).then(
      (res) => {
        console.log('Email Sent Successfully', res);
        return Promise.resolve(res);
      },
      (err) => {
        console.log('send Email Failed', err);
        return Promise.reject(err);
      }
    );
  }

  /**
   * get and set method for tab index
   */
  static getActiveReportTab() {
    return this.reportTabName;
  }

  static setActiveReportTab(tabName: REPORTS_TAB) {
    this.reportTabName = tabName;
  }

  static exportCustomPositivePayReport(customPositivePayReport: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = customPositivePayReport?.fileName + '.csv';
    const endPoint = ApiConstants.URL.PAYMENT.CUSTOM_CHECK_EXPORT;
    return http
      .post(`${endPoint}`, customPositivePayReport, {
        responseType: 'blob'
      })
      .then((res: any) => {
        saveAs(res, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportReportsData(request: any, fileName: string) {
    return http
      .post(ApiConstants.URL.REPORT.BUILDER.EXPORT, request, {
        responseType: 'blob'
      })
      .then(
        (res: any) => {
          saveAs(res, fileName);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default CustomReportsService;
