import { BOOKS_DATE_FORMAT } from '../../Constants/Constant';
import { Store } from '../../Redux/Store';
import DateFormatService from '../../Services/DateFormat';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import Utility from '../../Utility/Utility';

export enum LABEL_DOCUMENT_TYPE {
  SALES_ORDER = 'SALES_ORDER',
  SALES_INVOICE = 'SALES_INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_INVOICE = 'PURCHASE_INVOICE',
  WORK_ORDER_LABEL_PRINTTING = 'WORK_ORDER_LABEL_PRINTTING'
}

export interface LabelCellItem {
  key: string;
  title: string;
  label: string;
  value: any;
  labelFontSize?: number;
  valueFontSize?: number;
  direction?: 'Horizontal' | 'Vertical';
  showBarcode?: boolean;
  showQRCode?: boolean;
  fixedPosition?: boolean;
  barcodePosition?: 'Top' | 'Bottom';
  leftMargin?: number;
  topMargin?: number;
  width?: number;
  type?: string;
  isProductField?: boolean;
  isCustomField?: boolean;
  labelStyle?: 'Normal' | 'Bold' | 'Italic' | 'Underline';
  valueStyle?: 'Normal' | 'Bold' | 'Italic' | 'Underline';
  styleAlignment?: 'Left' | 'Center' | 'Right';
  options?: any[];
  valueInternal?: any;
}

export interface LabelCell {
  width?: string;
  items: LabelCellItem[];
  rowIndex?: number;
  colIndex?: number;
}

export interface LabelRow {
  height?: string;
  columns: LabelCell[];
}

export const LabelCellItemList = [
  {
    key: 'shipFromAddress',
    title: 'Ship From'
  },
  {
    key: 'shipToAddress',
    title: 'Ship To'
  },
  {
    key: '',
    title: ''
  },
  {
    key: '',
    title: ''
  },
  {
    key: '',
    title: ''
  },
  {
    key: '',
    title: ''
  },
  {
    key: '',
    title: ''
  },
  {
    key: '',
    title: ''
  }
];

export interface LabelTemplateSettings {
  width: number;
  height?: number;
  barcodeType: string;
  barcodeHeight: number;
  xDimension: number;
  quietZone: number;
  rowHeight?: number;
}

export const defaultLabelTemplateSettings: LabelTemplateSettings = {
  width: 6,
  height: 4,
  barcodeType: 'CODE_128',
  barcodeHeight: 13,
  xDimension: 0.33,
  quietZone: 6.3,
  rowHeight: 1
};

export interface LabelTemplateData {
  appName: string;
  defaultTemplate: boolean;
  description: string;
  templateName: string;
  documentType: LABEL_DOCUMENT_TYPE;
  templateData: LabelRow[];
  labelSettings: LabelTemplateSettings;
  id?: number;
}

export interface DefaultCellItem {
  key: string;
  title: string;
  value: any;
  type?: string;
  isProductField?: boolean;
  isCustomField?: boolean;
  options?: any[];
}

export interface DefaultCellItemList {
  WORK_ORDER_LABEL_PRINTTING: DefaultCellItem[];
  ALL_ORG_TYPE: DefaultCellItem[];
  ALL_TYPE: DefaultCellItem[];
  SALES_ORDER: DefaultCellItem[];
  SALES_INVOICE: DefaultCellItem[];
  PURCHASE_ORDER: DefaultCellItem[];
  PURCHASE_INVOICE: DefaultCellItem[];
  QUOTATION: DefaultCellItem[];
  REQUISITION: DefaultCellItem[];
}

export const FIELD_TYPE = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  CUSTOM_TEXT: 'CUSTOM_TEXT',
  ADDRESS: 'ADDRESS',
  UOM: 'UOM',
  USER: 'USER',
  IMAGE: 'IMAGE',
  SELECT_BARCODE_QR_CODE: 'SELECT_BARCODE_QR_CODE'
};

export const getValue = (data: LabelCellItem, currency?: string) => {
  try {
    const tenantInfo = Store.getState().authInfo.currentTenantInfo?.data;

    if (data.type === FIELD_TYPE.ADDRESS) {
      return Utility.getFullAddress(data.value);
    } else if (data.type === FIELD_TYPE.CURRENCY) {
      return DocumentConfigUtility.amountFormatter(
        data.value,
        currency || tenantInfo?.currency
      );
    } else if (data.type === FIELD_TYPE.NUMBER) {
      return Utility.getFormattedNumber(
        Utility.roundOffToTenantDecimalScale(data.value)
      );
    } else if (data.type === FIELD_TYPE.DATE) {
      return (
        DateFormatService.getFormattedDateString(
          data.value,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ) ||
        DateFormatService.getFormattedDateString(
          data.value,
          BOOKS_DATE_FORMAT['DD/MM/YYYY']
        )
      );
    } else if (data.type === FIELD_TYPE.UOM) {
      let val = Utility.getUOMForStockUOMId(data.value);
      return val ? val.name : '';
    }
    return data.value;
  } catch (error) {
    console.error('Error while parsing data: ', error);
    return data.value;
  }
};

export const defaultCellItems: DefaultCellItemList = {
  WORK_ORDER_LABEL_PRINTTING: [
    {
      key: 'workOrderNumber',
      title: 'Work Order Number',
      value: 'WO-000001'
    },
    {
      key: 'workOrderDate',
      title: 'Work Order Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'contactName',
      title: 'Contact Name',
      value: 'Contact Name'
    },
    {
      key: 'contactDocumentSequenceCode',
      title: 'Contact Code',
      value: 'C-000001'
    },
    {
      key: 'productName',
      title: 'Product Name',
      value: 'Product 1'
    },
    {
      key: 'trackingMethod',
      title: 'Tracking Method',
      value: 'Batch Number'
    },
    {
      key: 'stockAvailable',
      title: 'Stock Available',
      value: 0
    },
    {
      key: 'batchAssigned',
      title: 'Batch Assigned',
      value: 0
    },
    {
      key: 'requiredQuantity',
      title: 'Required Quantity',
      value: '-'
    },
    {
      key: 'receiving',
      title: 'Receiving',
      value: 3
    },
    {
      key: 'toAssign',
      title: 'To Assign',
      value: 3
    },
    {
      key: 'warehouseName',
      title: 'Warehouse',
      value: 'Primary Warehouse'
    },
    {
      key: 'serialBatchNumber',
      title: 'Batch Number',
      value: 'T1'
    },
    {
      key: 'manufacturingDate',
      title: 'Manufactured Date',
      value: '15/01/2025',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'expiryDate',
      title: 'Expiry Date',
      value: '03/01/2026',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'quantityAssigned',
      title: 'Quantity Assigned',
      value: 0
    },
    {
      key: 'customText',
      title: 'Custom Text',
      value: 'Text Value',
      type: FIELD_TYPE.CUSTOM_TEXT
    },
    {
      key: 'image',
      title: 'Image',
      value: '',
      type: FIELD_TYPE.IMAGE
    },
    {
      key: 'selectBarcodeQrCode',
      title: 'Barcode / QR Code',
      value: [],
      type: FIELD_TYPE.SELECT_BARCODE_QR_CODE
    }
  ],
  ALL_ORG_TYPE: [
    {
      key: 'orgName',
      title: 'Organization Name',
      value: 'Organization Name',
      type: FIELD_TYPE.CUSTOM_TEXT
    },
    {
      key: 'orgEmail',
      title: 'Organization Email',
      value: 'Organization Email',
      type: FIELD_TYPE.CUSTOM_TEXT
    }
  ],
  ALL_TYPE: [
    {
      key: 'contact.name',
      title: 'Contact Name',
      value: 'Contact Name'
    },
    {
      key: 'contactDto.documentSequenceCode',
      title: 'Contact Code',
      value: 'C-000001'
    },
    {
      key: 'billTo',
      title: 'Bill To',
      value: {
        contactName: 'Contact Name',
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        country: 'IN',
        state: 'State Name',
        city: 'City Name',
        postalCode: '410001',
        preferred: true,
        placeOfSupply: null,
        destinationOfSupply: null
      },
      type: FIELD_TYPE.ADDRESS
    },
    {
      key: 'shipTo',
      title: 'Ship To',
      value: {
        contactName: 'Contact Name',
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        country: 'IN',
        state: 'State Name',
        city: 'City Name',
        postalCode: '410001',
        preferred: true,
        placeOfSupply: null,
        destinationOfSupply: null
      },
      type: FIELD_TYPE.ADDRESS
    },
    {
      key: 'shipFrom',
      title: 'Ship From',
      value: {
        contactName: 'Contact Name',
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        country: 'IN',
        state: 'State Name',
        city: 'City Name',
        postalCode: '410001',
        preferred: true,
        placeOfSupply: null,
        destinationOfSupply: null
      },
      type: FIELD_TYPE.ADDRESS
    },
    {
      key: 'totalAmount',
      title: 'Total Amount',
      value: '118.00',
      type: FIELD_TYPE.CURRENCY
    },
    {
      key: 'taxAmount',
      title: 'Tax Amount',
      value: 18,
      type: FIELD_TYPE.CURRENCY
    },
    {
      key: 'tcsAmount',
      title: 'TCS Amount',
      value: 0,
      type: FIELD_TYPE.CURRENCY
    },
    {
      key: 'product.name',
      title: 'Product Name',
      value: 'Product Name',
      isProductField: true
    },
    {
      key: 'product.documentSequenceCode',
      title: 'Product Code',
      value: 'P-000001',
      isProductField: true
    },
    {
      key: 'hsnOrSacCode',
      title: 'Product HSC/SAC',
      value: '0044',
      isProductField: true
    },
    {
      key: 'product.description',
      title: 'Product Description',
      value: 'Product Description',
      isProductField: true
    },
    {
      key: 'productQuantity',
      title: 'Product Qty',
      value: 1,
      type: FIELD_TYPE.NUMBER,
      isProductField: true
    },
    {
      key: 'product.stockUom',
      title: 'Product UOM',
      value: 2,
      type: FIELD_TYPE.UOM,
      isProductField: true
    },
    {
      key: 'unitPrice',
      title: 'Product Unit Price',
      value: 100,
      type: FIELD_TYPE.CURRENCY,
      isProductField: true
    },
    {
      key: 'taxAmount',
      title: 'Product Tax Amount',
      value: 18,
      type: FIELD_TYPE.CURRENCY,
      isProductField: true
    },
    {
      key: 'discount',
      title: 'Product Discount Amount',
      value: 0,
      type: FIELD_TYPE.CURRENCY,
      isProductField: true
    },
    {
      key: 'totalAmount',
      title: 'Product Total',
      value: 118,
      type: FIELD_TYPE.CURRENCY,
      isProductField: true
    },
    {
      key: 'customText',
      title: 'Custom Text',
      value: 'Text Value',
      type: FIELD_TYPE.CUSTOM_TEXT
    }
  ],
  SALES_ORDER: [
    {
      key: 'documentSequenceCode',
      title: 'Order Number',
      value: 'SO-000001'
    },
    {
      key: 'salesOrderDate',
      title: 'Order Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'customerOrderNumber',
      title: 'Customer Order No.',
      value: '000001'
    },
    {
      key: 'placeOfSupply',
      title: 'Place of Supply',
      value: 'State Name'
    }
  ],
  SALES_INVOICE: [
    {
      key: 'documentSequenceCode',
      title: 'Invoice Number',
      value: 'SI-000001'
    },
    {
      key: 'salesInvoiceDate',
      title: 'Invoice Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'customerOrderNumber',
      title: 'Customer Order No.',
      value: '000001'
    },
    {
      key: 'placeOfSupply',
      title: 'Place of Supply',
      value: 'State Name'
    }
  ],
  PURCHASE_ORDER: [
    {
      key: 'documentSequenceCode',
      title: 'Order Number',
      value: 'PO-000001'
    },
    {
      key: 'documentDate',
      title: 'Order Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    }
  ],
  PURCHASE_INVOICE: [
    {
      key: 'documentSequenceCode',
      title: 'Bill Number',
      value: 'PI-000001'
    },
    {
      key: 'purchaseInvoiceDate',
      title: 'Bill Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'supplierInvoiceNo',
      title: 'Supplier Inv No.',
      value: '000001'
    }
  ],
  QUOTATION: [
    {
      key: 'documentSequenceCode',
      title: 'Quote Number',
      value: 'Q-000001'
    },
    {
      key: 'documentDate',
      title: 'Quote Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    },
    {
      key: 'customerOrderNumber',
      title: 'Customer Order No.',
      value: '000001'
    },
    {
      key: 'placeOfSupply',
      title: 'Place of Supply',
      value: 'State Name'
    }
  ],
  REQUISITION: [
    {
      key: 'documentSequenceCode',
      title: 'Requisition Number',
      value: 'PRQ-000001'
    },
    {
      key: 'purchaseRequestDate',
      title: 'Requisition Date',
      value: '24-07-2023',
      type: FIELD_TYPE.DATE
    }
  ]
};

export const templateFormats = {
  SALES_ORDER: [
    {
      templateName: 'Container Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'CUSTOMER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: 'PACKING LIST# (9S)',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Container Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'SALES ORDER# (K)',
                  title: 'Order Id',
                  value: 'SO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Master Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'CUSTOMER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: 'PACKING LIST# (9S)',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Master Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'SALES ORDER# (K)',
                  title: 'Order Id',
                  value: 'SO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Supplier Direct',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '120',
                  fixedPosition: true,
                  topMargin: ''
                },
                {
                  key: 'customText',
                  label: 'SUPPLIER DIRECT',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '19',
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  leftMargin: '210',
                  topMargin: '10',
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1,
              width: '500'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: '(P) PART NO',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0,
              width: '75'
            },
            {
              items: [
                {
                  key: 'product.description',
                  label: 'PART DESCRIPTION',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: false,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1,
              width: ''
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: '(Q) QUANTITY',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: '(K) SALES ORDER',
                  title: 'Order Id',
                  value: 'SO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1,
              width: '95'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'contactDto.documentSequenceCode',
                  label: '(D) CUSTOMER NUMBER',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0,
              width: '90'
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: '(11K) PACKING LIST',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  fixedPosition: true,
                  leftMargin: '',
                  topMargin: ''
                }
              ],
              rowIndex: 3,
              colIndex: 1
            }
          ]
        }
      ]
    }
  ],
  SALES_INVOICE: [
    {
      templateName: 'Container Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'CUSTOMER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: 'PACKING LIST# (9S)',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Container Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'SALES INVOICE# (K)',
                  title: 'Invoice Id',
                  value: 'SI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Master Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'CUSTOMER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: 'PACKING LIST# (9S)',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Master Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'SALES INVOICE# (K)',
                  title: 'Invoice Id',
                  value: 'SI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Supplier Direct',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '120',
                  fixedPosition: true,
                  topMargin: ''
                },
                {
                  key: 'customText',
                  label: 'SUPPLIER DIRECT',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '19',
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  leftMargin: '210',
                  topMargin: '10',
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1,
              width: '500'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: '(P) PART NO',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0,
              width: '75'
            },
            {
              items: [
                {
                  key: 'product.description',
                  label: 'PART DESCRIPTION',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: false,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1,
              width: ''
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: '(Q) QUANTITY',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: '(K) SALES INVOICE',
                  title: 'Invoice Id',
                  value: 'SI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1,
              width: '95'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'contactDto.documentSequenceCode',
                  label: '(D) CUSTOMER NUMBER',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0,
              width: '90'
            },
            {
              items: [
                {
                  key: 'customerOrderNumber',
                  label: '(11K) PACKING LIST',
                  title: 'Customer Order No.',
                  value: '000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  fixedPosition: true,
                  leftMargin: '',
                  topMargin: ''
                }
              ],
              rowIndex: 3,
              colIndex: 1
            }
          ]
        }
      ]
    }
  ],
  PURCHASE_ORDER: [
    {
      templateName: 'Container Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'SUPPLIER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'PACKING LIST# (9S)',
                  title: 'Custom Text',
                  value: '11111111',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Container Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'PURCHASE ORDER# (K)',
                  title: 'Order Id',
                  value: 'PO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Master Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'SUPPLIER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'PACKING LIST# (9S)',
                  title: 'Custom Text',
                  value: '11111111',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Master Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'PURCHASE ORDER# (K)',
                  title: 'Order Id',
                  value: 'PO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Supplier Direct',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '120',
                  fixedPosition: true,
                  topMargin: ''
                },
                {
                  key: 'customText',
                  label: 'SUPPLIER DIRECT',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '19',
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  leftMargin: '210',
                  topMargin: '10',
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1,
              width: '500'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: '(P) PART NO',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0,
              width: '75'
            },
            {
              items: [
                {
                  key: 'product.description',
                  label: 'PART DESCRIPTION',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: false,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1,
              width: ''
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: '(Q) QUANTITY',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: '(K) PURCHASE ORDER',
                  title: 'Order Id',
                  value: 'PO-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1,
              width: '95'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'contactDto.documentSequenceCode',
                  label: '(D) DEALER NUMBER',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0,
              width: '90'
            },
            {
              items: [
                {
                  key: 'customText',
                  label: '(11K) PACKING LIST',
                  title: 'Custom Text',
                  value: '49003554',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  fixedPosition: true,
                  leftMargin: '',
                  topMargin: ''
                }
              ],
              rowIndex: 3,
              colIndex: 1
            }
          ]
        }
      ]
    }
  ],
  PURCHASE_INVOICE: [
    {
      templateName: 'Container Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'SUPPLIER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'PACKING LIST# (9S)',
                  title: 'Custom Text',
                  value: '11111111',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Container Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'PURCHASE INVOICE# (K)',
                  title: 'Invoice Id',
                  value: 'PI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Master Label',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false
                },
                {
                  key: 'contactDto.documentSequenceCode',
                  label: 'SUPPLIER#',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '14',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '150'
                }
              ],
              rowIndex: 0,
              colIndex: 1
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'PACKING LIST# (9S)',
                  title: 'Custom Text',
                  value: '11111111',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '225'
                }
              ],
              rowIndex: 0,
              colIndex: 2
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: 'PART NO (P)',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'customText',
                  label: 'Master Label',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '17',
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                },
                {
                  key: 'customText',
                  label: 'REV. LEVEL',
                  title: 'Custom Text',
                  value: 'A',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  topMargin: '35',
                  leftMargin: ''
                },
                {
                  key: 'product.description',
                  label: 'PART DESC',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: true,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: 'QUANTITY (Q)',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: 'PURCHASE INVOICE# (K)',
                  title: 'Invoice Id',
                  value: 'PI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'customText',
                  label: 'SERIAL NO (9S)',
                  title: 'Custom Text',
                  value: '7657567653765656',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0
            }
          ]
        }
      ]
    },
    {
      templateName: 'Supplier Direct',
      templateData: [
        {
          columns: [
            {
              items: [
                {
                  key: 'shipFrom',
                  label: 'FROM',
                  title: 'Ship From',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'shipTo',
                  label: 'TO',
                  title: 'Ship To',
                  value: {
                    contactName: 'Contact Name',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    country: 'IN',
                    state: 'State Name',
                    city: 'City Name',
                    postalCode: '410001',
                    preferred: true,
                    placeOfSupply: null,
                    destinationOfSupply: null
                  },
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'ADDRESS',
                  isCustomField: false,
                  isProductField: false,
                  width: '120',
                  fixedPosition: true,
                  topMargin: ''
                },
                {
                  key: 'customText',
                  label: 'SUPPLIER DIRECT',
                  title: 'Custom Text',
                  value: '',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: '19',
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  fixedPosition: true,
                  leftMargin: '210',
                  topMargin: '10',
                  width: ''
                }
              ],
              rowIndex: 0,
              colIndex: 1,
              width: '500'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'product.documentSequenceCode',
                  label: '(P) PART NO',
                  title: 'Product Code',
                  value: 'P-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 1,
              colIndex: 0,
              width: '75'
            },
            {
              items: [
                {
                  key: 'product.description',
                  label: 'PART DESCRIPTION',
                  title: 'Product Description',
                  value: 'Product Description',
                  barcodePosition: 'Bottom',
                  direction: 'Vertical',
                  labelFontSize: 12,
                  valueFontSize: '19',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: true,
                  fixedPosition: false,
                  leftMargin: '100',
                  topMargin: '35'
                }
              ],
              rowIndex: 1,
              colIndex: 1,
              width: ''
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'productQuantity',
                  label: '(Q) QUANTITY',
                  title: 'Product Qty',
                  value: 1,
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'NUMBER',
                  isCustomField: false,
                  isProductField: true,
                  showBarcode: true
                }
              ],
              rowIndex: 2,
              colIndex: 0,
              width: ''
            },
            {
              items: [
                {
                  key: 'documentSequenceCode',
                  label: '(K) PURCHASE INVOICE',
                  title: 'Invoice Id',
                  value: 'PI-000001',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: '17',
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  width: '350'
                }
              ],
              rowIndex: 2,
              colIndex: 1,
              width: '95'
            }
          ]
        },
        {
          columns: [
            {
              items: [
                {
                  key: 'contactDto.documentSequenceCode',
                  label: '(D) DEALER NUMBER',
                  title: 'Contact Code',
                  value: 'C-000001',
                  barcodePosition: 'Bottom',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true
                }
              ],
              rowIndex: 3,
              colIndex: 0,
              width: '90'
            },
            {
              items: [
                {
                  key: 'customText',
                  label: '(11K) PACKING LIST',
                  title: 'Custom Text',
                  value: '49003554',
                  barcodePosition: 'Top',
                  direction: 'Horizontal',
                  labelFontSize: 12,
                  valueFontSize: 12,
                  type: 'CUSTOM_TEXT',
                  isCustomField: false,
                  isProductField: false,
                  showBarcode: true,
                  fixedPosition: true,
                  leftMargin: '',
                  topMargin: ''
                }
              ],
              rowIndex: 3,
              colIndex: 1
            }
          ]
        }
      ]
    }
  ]
};

export const getStyleFromValue = (style: string | undefined) => {
  switch (style) {
    case 'Bold':
      return { fontWeight: 'bold' };
    case 'Italic':
      return { fontStyle: 'italic' };
    case 'Underline':
      return { textDecoration: 'underline' };
    default:
      return {};
  }
};

export const workOrderPrintingDataBuilder = (workOrderBatchData: any) => {
  const workOrderData = {
    workOrderNumber: workOrderBatchData?.workOrderNumber,
    workOrderDate: workOrderBatchData?.workOrderDate,
    contactName: workOrderBatchData?.contactName,
    contactCode: workOrderBatchData?.contactCode,
    productName: workOrderBatchData?.productName ?? '',
    trackingMethod: workOrderBatchData?.trackingMethod,
    stockAvailable: workOrderBatchData?.stockAvailable,
    batchAssigned: workOrderBatchData?.batchAssigned,
    requiredQuantity: workOrderBatchData?.batchSize,
    receiving: workOrderBatchData?.receiving,
    toAssign: workOrderBatchData?.toAssign,
    warehouseName: workOrderBatchData?.warehouseCode?.name ?? '',
    serialBatchNumber: workOrderBatchData?.serialBatchNumber,
    manufacturingDate: DateFormatService.getDateStrFromDate(
      new Date(workOrderBatchData?.manufacturingDate),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    expiryDate: DateFormatService.getDateStrFromDate(
      new Date(workOrderBatchData?.expiryDate),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    quantityAssigned: workOrderBatchData?.batchSize,
    customField: workOrderBatchData?.customField
  };
  return workOrderData;
};

export const LABEL_TYPE_IMAGE_ENTITY = {
  WORK_ORDER_LABEL_PRINTTING: 'WORK_ORDER_LABEL_PRINTTING'
};

interface ProcessLabelTemplateParams {
  labelTemplate: LabelTemplateData;
  documentData: any;
  selectedLineItem: any;
  documentType: string;
  usersListData: any[];
}

export const processWOLabelTemplate = ({
  labelTemplate,
  documentData,
  selectedLineItem,
  documentType,
  usersListData
}: ProcessLabelTemplateParams): LabelTemplateData => {
  const getValue = (obj: any, key: string[]): string => {
    if (key.length > 1) {
      const newObj = obj[key[0]];
      key.splice(0, 1);
      return newObj ? getValue(newObj, key) : '';
    }
    return obj[key[0]] || '';
  };

  const ignoreFields = [FIELD_TYPE.CUSTOM_TEXT, FIELD_TYPE.IMAGE];

  if (labelTemplate) {
    const updatedLabelTemplate = { ...labelTemplate };

    updatedLabelTemplate?.templateData.forEach((row: LabelRow) => {
      row.columns.forEach((col) => {
        col.items.forEach((item) => {
          if (!ignoreFields.includes(item?.type as string)) {
            if (item.type === FIELD_TYPE.SELECT_BARCODE_QR_CODE) {
              const documentKeys: string[] = Array.from(
                new Set(
                  item?.valueInternal
                    ?.split(',')
                    .map((key: any) => key.trim()) || []
                )
              );
              let value = '';

              for (const key of documentKeys) {
                value += getValue(selectedLineItem, key.split('.'));
              }
              item.value = value;
            } else if (item.isCustomField) {
              let customFieldType = '';
              if (item.title.includes('Product')) {
                customFieldType = 'PRODUCT';
              } else if (item.title.includes('Batch')) {
                customFieldType = 'BATCHSERIAL';
              } else if (item.title.includes('Work Order')) {
                customFieldType = 'WORKORDER';
              }

              let cfList: any[] = [];
              cfList = selectedLineItem.customField;
              item.value = null;
              if (cfList.length) {
                const cf = cfList.find(
                  (cfItem: any) =>
                    cfItem.code === item.key &&
                    cfItem.module === customFieldType
                );
                if (cf) {
                  item.value = cf.value;
                }
              }
            } else {
              item.value = getValue(selectedLineItem, item.key.split('.'));
            }

            if (item.type === FIELD_TYPE.USER && item.value) {
              const user = usersListData.find(
                (userItem) => userItem.iamUserId === parseInt(item.value, 10)
              );
              if (user) {
                item.value = `${user.firstName} ${user.lastName}`.trim();
              }
            }
          }
        });
      });
    });

    return updatedLabelTemplate;
  }

  return labelTemplate;
};
