import { DateFormat } from "../../Constants/Constants"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"

export default class PickListParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = this.getPayloadData(dataFromAPI).customFields
        CustomFieldManager.setCustomFields(this.getPayloadData(dataFromAPI).customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        var name = ''
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).warehouseName !== undefined && this.getPayloadData(dataFromAPI).warehouseName !== null) {
                name = getCapitalized(this.getPayloadData(dataFromAPI).warehouseName)
            }
        }
        templateDataToReturn.companyName = name

        //company address
        var fromAddressObj = undefined
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            var warehouseAddress = this.getPayloadData(dataFromAPI)
            var address = {
                address1: warehouseAddress.warehouseAdd1,
                address2: warehouseAddress.warehouseAdd2,
                city: warehouseAddress.warehouseCity,
                state: warehouseAddress.warehouseState,
                postalCode: warehouseAddress.warehouseZipCode,
                country: warehouseAddress.warehouseCountry,
            }
            fromAddressObj = new Address(undefined, address, undefined, undefined, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        templateDataToReturn.clientBillToAddress = ''

        // shipping to address
        templateDataToReturn.clientShipToAddress = ''

        //ref number
        var refNumber = ''
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).pickListNo !== undefined && this.getPayloadData(dataFromAPI).pickListNo !== null) {
                refNumber = this.getPayloadData(dataFromAPI).pickListNo
            }
        }
        templateDataToReturn.refNumber = refNumber

        //start date
        var date = ''
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).date !== undefined && this.getPayloadData(dataFromAPI).date !== null) {
                date = this.getPayloadData(dataFromAPI).date
            }
        }
        if (date !== '') {
            var rawDate = getDateString(getDateFrom(date, this.getPickPackShipDateFormat(dataFromAPI, templateDataToReturn.dateFormat), templateDataToReturn.dateFormat))
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(rawDate), templateDataToReturn.dateFormat)
        }
        else {
            templateDataToReturn.date = date
        }

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getPickItem(dataFromAPI, templateDataToReturn.dateFormat)

        //need to add handling to show company address if contains multiple warehouse 
        if (templateDataToReturn.lineItems.length > 0) {
            let warehouseCodes = [...new Set(templateDataToReturn.lineItems.map(x => x.warehouseName))]
            let multipleWarehouseCode = false
            if (warehouseCodes.length === 1) {
                multipleWarehouseCode = warehouseCodes[0].includes(',')
            }

            if (warehouseCodes.length > 1 || multipleWarehouseCode) {
                if (dataFromAPI.tenantInfo && dataFromAPI.tenantInfo !== null) {
                    templateDataToReturn.companyName = dataFromAPI.tenantInfo.name
                    const address = new Address(undefined, dataFromAPI.tenantInfo.address, undefined, undefined, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
                    templateDataToReturn.fromObj = address
                    templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)
                }
                
            }
        }


        return templateDataToReturn
    }

    static getPayloadData(data) {
        if (data.payload === undefined || data.payload === null) {
            return undefined
        }

        if (data.payload.length === 0) {
            return undefined
        }

        return data.payload[0]
    }

    static getPickPackShipDateFormat(data, dateFormat) {
        var defaultDateFormat = dateFormat
        if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
            if (data.tenantInfo.dateFormat !== undefined && data.tenantInfo.dateFormat !== null) {
                defaultDateFormat = data.tenantInfo.dateFormat.toLowerCase()
                if (defaultDateFormat === 'd mmm yyyy') {
                    defaultDateFormat = DateFormat.DDMMMYYYY
                }
            }
        }
        return defaultDateFormat
    }
}
