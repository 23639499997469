import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import AppManager from "../../../Manager/AppManager";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { getDateFrom, getDateString, getIsBill, getIsExpenseOrDeposit } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class AccountTableItem {
    name: string;
    description: string;
    tax: string;
    taxAmount: string;
    paymentAmount: string;
    amount: string;
    currency: string;
    rawItemValue: any;
    data:any;
    constructor(accountItem: any, currency: string, data?:any) {
        this.name = accountItem.name
        this.description = accountItem.description
        this.tax = accountItem.tax
        this.taxAmount = accountItem.taxAmount ? accountItem.taxAmount : '0.00'
        this.paymentAmount = accountItem.paymentAmount ? accountItem.paymentAmount : '0.00'
        this.amount = accountItem.amount ? accountItem.amount : '0.00'
        this.currency = currency
        this.rawItemValue = accountItem
        this.data = data
    }


    getTaxName(): string {
        if (this.rawItemValue.taxInfo !== undefined && this.rawItemValue.taxInfo !== null) {
            if (this.rawItemValue.taxInfo.name !== undefined && this.rawItemValue.taxInfo.name !== null) {
                return this.rawItemValue.taxInfo.name
            }
        }
        return ''
    }
    toString(documentType: string, tableConfig?: any) {
        var newList: any[] = []
        // var sortedHeader = getSortedHeaderType(tableConfig);
        var sortedHeader = getSortedHeader(tableConfig);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var docType = 'account'
                if (AppManager.getDocumentType() !== undefined) {
                    if (getIsBill(AppManager.getDocumentType())) {
                        docType = 'expenseBill'
                    }
                }
                var footerList = TableFooterUtility.getFooterListByType(docType)
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }

        if (getIsExpenseOrDeposit(documentType)) {
            newList = this.getDefaultDepositAndExpenseList()
        }
        else {
            newList = this.getDefaultCNAndDNList()
        }

        return newList
    }

    getValueByType(element: any) {
        switch (element.type) {
            case TableColumnPopupType.accountName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.amount, this.currency)
            case TableColumnPopupType.tax:
                return this.getTaxName()
            case TableColumnPopupType.taxAmount:
                return Utility.toCurrencyFormat(this.taxAmount, this.currency)
            case TableColumnPopupType.paymentAmount:
                return Utility.toCurrencyFormat(this.paymentAmount, this.currency)
            case TableColumnPopupType.accountCustomField:
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getAccountCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        if (cfType === "DATE") {
                            value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                        } else {
                            value = field.value
                        }
                    }
                    return value
                }
                return this.getCombineAccountCustomField()
            default:
                return ''
        }
    }

    getAccountCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getCombineAccountCustomField() {
        var val = ''
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.isCombinesAccountCustomField) {
                var productCustomFields = CustomFieldManager.getActiveAccountCustomFields()
                // getActiveAccountCustomFields
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getAccountCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getAccountCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (accountTableConfiguration.combinedAccountCustomField !== undefined
                            && accountTableConfiguration.combinedAccountCustomField !== null) {
                            if (accountTableConfiguration.combinedAccountCustomField.length > 0) {
                                accountTableConfiguration.combinedAccountCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getAccountCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getDefaultDepositAndExpenseList(){
        return [
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            Utility.toCurrencyFormat(this.amount, this.currency),
            this.getTaxName(),
            Utility.toCurrencyFormat(this.taxAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentAmount, this.currency),
        ]
    }

    getDefaultCNAndDNList() {
        return [
            getLocalisedText(this.name),
            Utility.toCurrencyFormat(this.amount, this.currency),
            this.getTaxName(),
            Utility.toCurrencyFormat(this.taxAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentAmount, this.currency),
        ]
    }
}
