import { COUNTRIES_LIST, DateFormat } from "../Constants/Constants"
import CustomFieldManager from "../Manager/CustomFieldManager"
import { getDateFrom, getDateString } from "../Utilities/Utility"

export default class Address {

    constructor(name, add, pan, gstin, contactNumber, contactCode, ownerType) {
        this.name = name
        this.contactNumber = contactNumber
        if (add !== undefined && add !== null) {
            this.address1 = add.address1
            this.address2 = add.address2
            this.city = add.city
            this.state = add.state
            this.stateCode = add.stateCode
            this.postalCode = add.postalCode
            this.country = add.country
            this.contactName = add.contactName
            this.emailAddress = add.emailId
            this.customFields = add.customFields

            if (Array.isArray(add.customFields)){
                add.customFields.forEach((cf) => {
                    if (CustomFieldManager.isDateCustomField(cf.id)) {
                        this[cf.id] = getDateString(getDateFrom(cf.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat());
                    } else {
                        this[cf.id] = cf.value
                    }
                });
            }
            this.contactNumber = add.mobileNumber ? add.mobileNumber : contactNumber

            //country code to country mapping
            if(add.country && add.country !== null) {
                if(add.country !== '') {
                    const mappedCountryName = COUNTRIES_LIST.find(x => x.code.toLowerCase() === add.country.toLowerCase())
                    if(mappedCountryName) {
                        this.country = mappedCountryName.name
                    }
                }
            }
        }

        this.pan = pan
        this.gstin = gstin
        
        this.contactCode = contactCode

        if (ownerType !== undefined) {
            this.ownerType = ownerType
        }
    }

    initPayslipAddress(add) {
        this.address1 = add.addressLine1
        this.address2 = add.addressLine2
        this.city = add.city
        this.state = add.state
        this.postalCode = add.zipCode
        this.country = add.country
    }
}
