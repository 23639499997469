import React, { useEffect, useState } from 'react';
import { DKLabel, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import { CustomFieldsHolder } from '../../../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import {
  DOCUMENT_MODE,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME
} from '../../../../../Constants/Constant';
import { useAppSelector } from '../../../../../Redux/Hooks';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import useDebounce from '../../../../../Hooks/useDebounce';
import {
  selectWorkOrderColumnConfig,
  selectWorkOrderColumnConfigTableId
} from '../../../../../Redux/Slices/MRP/WorkOrderSlice';

interface IWOCustomFieldProps {
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  customFields: any[];
  onCFUpdates: (cfList: any[]) => void;
  currentActiveTabIndex: number;
  activeTabWorkOrder: any;
}

const WOCustomField: React.FC<IWOCustomFieldProps> = (props) => {
  const columnConfig = useAppSelector(selectWorkOrderColumnConfig);
  const columnConfigTableId = useAppSelector(
    selectWorkOrderColumnConfigTableId
  );

  const unmodifiedCustomFieldList = deepClone(props.customFields);
  const [localCFList, setLocalCFList] = useState(props.customFields ?? []);
  const [cfSearchedText, setCFSearchedText] = useState<any>(undefined);
  const debouncedSearchTerm = useDebounce(cfSearchedText, 500);

  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== 'undefined' &&
      debouncedSearchTerm !== null
    ) {
      const filteredCFList = unmodifiedCustomFieldList?.filter((cf: any) =>
        cf?.label?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())
      );
      setLocalCFList(filteredCFList);
    } else {
      setLocalCFList(props?.customFields);
    }
  }, [debouncedSearchTerm, props.customFields]);

  const getValueOfCFList = (updatedCFParam: any, cfParam: any) => {
    if (
      typeof updatedCFParam?.value !== 'undefined' &&
      updatedCFParam?.value !== null
    ) {
      return updatedCFParam?.value;
    } else if (
      typeof cfParam?.value !== 'undefined' &&
      cfParam?.value !== null
    ) {
      return cfParam?.value;
    } else {
      return cfParam?.defaultValue;
    }
  };

  return (
    <div
      className={`column parent-width border-m border-radius-m p-l bg-white`}
      style={{ minHeight: 80, width: props?.isEditMode ? '49.5%' : '100%' }}
    >
      <div className="row justify-content-between">
        <DKLabel text="Custom Fields" className="fw-m text-app-color" />
        {unmodifiedCustomFieldList?.length > 10 && (
          <div style={{ width: 200 }}>
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              placeholder="Search custom fields..."
              type={INPUT_TYPE.TEXT}
              required={false}
              canValidate={false}
              value={cfSearchedText}
              onChange={(value: any) => {
                setCFSearchedText(value);
              }}
              className=""
              readOnly={props.isReadOnlyMode}
            />
          </div>
        )}
      </div>
      {/* {Utility.isEmpty(props.customFields) ? (
        <DKLabel
          text={`No custom fields found for this work order`}
          className={`mt-r text-gray mx-auto`}
        />
      ) : null} */}
      <CustomFieldsHolder
        moduleName={MODULES_NAME.MRP_WORK_ORDER}
        documentMode={
          props.isReadOnlyMode
            ? DOCUMENT_MODE.VIEW
            : props.isEditMode
            ? DOCUMENT_MODE.EDIT
            : DOCUMENT_MODE.NEW
        }
        columnConfig={columnConfig}
        columnConfigTableId={columnConfigTableId ?? ''}
        currentDoc={props.activeTabWorkOrder}
        onUpdate={(list: any) => {
          const updatedList = props?.customFields?.map((cf: any) => {
            const updatedCF = list?.find((c: any) => c?.id === cf?.id);
            if (updatedCF) {
              return {
                ...cf,
                formulaId: updatedCF?.formula?.id ?? cf?.formulaId,
                value: getValueOfCFList(updatedCF, cf)
              };
            } else {
              return cf;
            }
          });
          let newCFs: any[] = [];
          list?.forEach((newCF: any) => {
            const existingCF = props?.customFields?.find(
              (c: any) => c?.id === newCF?.id
            );
            if (!existingCF) {
              newCFs.push({
                code: newCF.code,
                formulaId: newCF?.formula?.id,
                id: newCF.id,
                label: newCF.label,
                shortName: newCF.shortName,
                value: newCF?.value ?? newCF?.defaultValue,
                module: newCF?.module
              });
            }
          });

          props.onCFUpdates([...updatedList, ...newCFs]);
        }}
        customFieldsList={localCFList}
        valueToTriggerLocalChange={props?.currentActiveTabIndex}
        isCFFiltered={cfSearchedText?.length > 0}
      />
    </div>
  );
};

export default WOCustomField;
