import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import { showAlert } from 'deskera-ui-library';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';

export interface ProductAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
  barcode?: string;
  SortDir?: string;
  Sort?: string;
  FetchAttachmentDetails?: boolean;
  ProductGroupId?: any;
  IsArchived?: any;
}

export const defaultConfig: ProductAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  type: '',
  QueryParam: '',
  Sort: '',
  SortDir: '',
  FetchAttachmentDetails: false,
  ProductGroupId: '',
  IsArchived: false
};
class ProductService {
  static apiConfig: ProductAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;
  static abortController: any = null;

  static getProductEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    if (
      typeof this.apiConfig.IsVariant !== 'undefined' &&
      this.apiConfig.IsVariant !== null &&
      !this.apiConfig.IsVariant
    ) {
      if (Utility.isEmpty(this.apiConfig.Query)) {
        this.apiConfig.Query = `isVariant=${this.apiConfig.IsVariant}`;
      } else if (!this.apiConfig.Query?.includes('isVariant=')) {
        this.apiConfig.Query =
          this.apiConfig.Query + `,isVariant=${this.apiConfig.IsVariant}`;
      }
    }
    let searchTerm = this.apiConfig.SearchTerm
      ? encodeURIComponent(this.apiConfig.SearchTerm)
      : '';
    let queryString: string = `?search=${searchTerm ?? ''}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? `&query=${this.apiConfig.Query}` : ''
    }${this.apiConfig.Sort ? `&sort=${this.apiConfig.Sort}` : ''}${
      this.apiConfig.SortDir ? `&sortDir=${this.apiConfig.SortDir}` : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    if (this.apiConfig.FetchAttachmentDetails) {
      queryString += `&fetchAttachmentDetails=${this.apiConfig.FetchAttachmentDetails}`;
    }
    if (this.apiConfig.ProductGroupId) {
      queryString += `&productGroupId=${this.apiConfig.ProductGroupId}`;
    }

    queryString = this.apiConfig.barcode
      ? queryString + '&barcode=' + this.apiConfig.barcode
      : queryString;
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );

    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      (countryCode !== '' ? `${countryCode}` : '') +
      queryString;
    return finalEndpoint;
  }

  static getProductsByPage(country = '', isVariant = false) {
    Utility.cancelRequest(ProductService.abortController);

    ProductService.abortController = Utility.createAbortController();
    return http
      .get(`${ProductService.getProductEndPoint()}`, {
        signal: ProductService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getVariantProductsByPage(country = '', isVariant = false) {
    return http
      .get(`${ProductService.getProductEndPoint()}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  // URL for fetching products and variants but not master product of variants
  static getProductsWithVariantsURL(searchTerm = '', query = '', params = '') {
    searchTerm = searchTerm ? encodeURIComponent(searchTerm) : '';
    let queryString: string = `?search=${searchTerm}&limit=25&query=${query}`;
    if (params) {
      queryString += `&productTransactionType=${params}`;
    }
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );
    const url: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      (countryCode !== '' ? `${countryCode}` : '') +
      queryString;
    return url;
  }

  static getProductsWithVariants(searchTerm = '', query = '', params = '') {
    const url = ProductService.getProductsWithVariantsURL(
      searchTerm,
      query,
      params
    );
    return http
      .get(`${url}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static changeProductStatus(status: boolean, payload: string[]) {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.CHANGE_PRODUCT_STATUS + status;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static archiveProduct(payload: any, isArchivedOn: any) {
    const finalEndPoint = !isArchivedOn
      ? ApiConstants.URL.PRODUCT.ARCHIVE_PRODUCT
      : ApiConstants.URL.PRODUCT.UNARCHIVE_PRODUCT;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createBuildAssembly(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRODUCT.CREATE_ASSEMBLY;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createProduct(data: any) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS + countryCode;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateProduct(data: any) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS + countryCode + '/' + data.id;

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteProduct(productIds: string[]) {
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS + countryCode;
    return http2
      .delete(finalEndPoint, { data: productIds })
      .then(
        (response: any) => {
          const res: any = response;
          if (res?.failure?.length > 0) {
            showAlert(
              'Error',
              'The product is already in use and cannot be deleted.'
            );
          } else {
            // showToast('Product deleted successfully', TOAST_TYPE.SUCCESS);
          }
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchWarehouses() {
    const finalEndPoint = ApiConstants.URL.ACCOUNTS.WAREHOUSES;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getSummary() {
    const finalEndPoint = ApiConstants.URL.PRODUCT.FETCH_SUMMARY;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getAttributes() {
    const finalEndPoint = ApiConstants.URL.PRODUCT.ATTRIBUTE;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static createAttribute(data: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.ATTRIBUTE;
    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createVariant(data: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.VARIANTS;
    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createVariantAttribute(data: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.ATTRIBUTE;
    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  /** @deprecated */
  static fetchWarehouseProducts() {
    const finalEndPoint = ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE_INVENTORY;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchWarehouseProductsByID(data: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE_INVENTORY;
    return http
      .post(`${finalEndPoint}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchWarehouseProductsByIDWithRejectedWh(data: any) {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE_INVENTORY +
      '?includeRejectedWarehouse=true';
    return http
      .post(`${finalEndPoint}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteVariantProduct(productId: number) {
    return http2
      .delete(ApiConstants.URL.PRODUCT.DELETE_VARIANT(productId))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchProductAdvancedTrackingWarehouse(
    productCode: any,
    enableQCWarehouse: any = false,
    includeRejectedWarehouse: any = false,
    checkBatchSerialCF: any = false
  ) {
    let queryString: string = `?productCode=${productCode}&isCombined=true&includeQC=${enableQCWarehouse}&includeRejectedWarehouse=${includeRejectedWarehouse}`;
    if (checkBatchSerialCF) {
      queryString = `?productCode=${productCode}&isCombined=true&includeQC=${enableQCWarehouse}&includeRejectedWarehouse=${includeRejectedWarehouse}&checkBatchSerialCF=${checkBatchSerialCF}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCT_ADVANCED_TRACKING_WAREHOUSE +
      queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchProductWarehouseCodes(productCode: any) {
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCT_WAREHOUSE_CODES;
    return http
      .post(`${finalEndpoint}`, productCode)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchProductAdvancedTrackingData(productCode: any) {
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_ADVANCED_TRACKING_DATA(productCode);
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAdvancedTrackingMetaData(queryParams: string = '') {
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.ADVANCED_TRACKING_META_DATA + queryParams;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchProductVariantData(productId: number) {
    return http
      .get(ApiConstants.URL.PRODUCT.GET_VARIANT(productId))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static checkProductUsedStatus(productId: number) {
    const query = `?productId=${productId}`;
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.VALIDATE_PRODUCT + query;
    return http
      .get(finalEndpoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static checkDefaultTaxCode(taxCodes: string[]) {
    const payload = { taxCodeList: taxCodes };
    return http
      .post(ApiConstants.URL.PRODUCT.DEFAULT_TAXCODE, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateInventorySettings(payload: any, tenantId: number) {
    return http
      .post(
        ApiConstants.URL.PRODUCT.UPDATE_INVENTORY_SETTING(tenantId),
        payload
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getBomDetails(productId: number) {
    return http
      .get(ApiConstants.URL.PRODUCT.getBomDetails(productId))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getBomDetailsWithQty(productId: number, qty: any) {
    return http
      .get(ApiConstants.URL.PRODUCT.getBomDetailsWithQty(productId, qty))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getBomDetailsWithQtyForJWO(productId: number, qty: any) {
    return http
      .get(ApiConstants.URL.PRODUCT.getBomDetailsWithQtyForJWO(productId, qty))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductsByProductIds(
    productIds: string[],
    productImage: boolean = false,
    onlyLatestBomVersion: boolean = true,
    attachmentDetails: boolean = false
  ) {
    let urlString =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCT_BY_PRODUCT_IDS +
      '?fetchImageUrls=' +
      productImage;
    if (attachmentDetails) {
      urlString += `&fetchAttachmentsDetails=${attachmentDetails}`;
    }
    // adding this only id false
    if (!onlyLatestBomVersion) {
      urlString = urlString + '&onlyLatestBomVersion=false';
    }
    return http
      .post(urlString, productIds)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductDetailsByIds(productIds: string[]) {
    return http
      .post(ApiConstants.URL.PRODUCT.FETCH_PRODUCT_DETAILS_BY_IDS, productIds)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  /** @deprecated */
  static getWarehousesProductEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let searchTerm = this.apiConfig.SearchTerm
      ? encodeURIComponent(this.apiConfig.SearchTerm)
      : '';
    let queryString: string = `?search=${searchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const { BASE } = ApiConstants.URL;
    const finalEndpoint: string = `${BASE}${ApiConstants.URL.PRODUCT.GET_WAREHOUSE_PRODUCTS_DATA}${queryString}`;
    return finalEndpoint;
  }

  /** @deprecated */
  static getWarehousesProductsData() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.PRODUCT.GET_WAREHOUSE_PRODUCTS_DATA;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getWarehousesProductsDataWithRejectedWh() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.GET_WAREHOUSE_PRODUCTS_DATA +
      '?includeRejectedWarehouse=true';
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getWarehousesProductsDataWithoutRole() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.PRODUCT.GET_WAREHOUSE_PRODUCTS_DATA;
    return http
      .get(`${finalEndPoint}?filterOnRolesFlg=false`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateTaxPreferencesForSG(request: any) {
    return http
      .patch(ApiConstants.URL.PRODUCT.PATCH_TAX_PREFERENCE_SG, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductReservedInfo(productIds: string[]) {
    return http
      .post(ApiConstants.URL.PRODUCT.RESERVED_QUANTITY_INFO, productIds)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAdhocBOMExplosionDetails(
    productCode: string,
    workOrderCode: string
  ) {
    return http2
      .get(
        ApiConstants.URL.PRODUCT.FETCH_ADHOC_BOM_EXLPOSION_PRODUCT_DETAILS(
          productCode,
          workOrderCode
        )
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchBOMExplosionDetails(productCode: string, bomMetaCode: string) {
    return http2
      .get(
        ApiConstants.URL.PRODUCT.FETCH_BOM_EXLPOSION_PRODUCT_DETAILS(
          productCode,
          bomMetaCode
        )
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static async fetchAdditionalProductsBOMExplosionDetails(
    payload: {
      productCode: string;
      bomMetaCode?: string;
      manufacturingLeadTimeFlag?: boolean;
    }[]
  ) {
    return http2
      .post(ApiConstants.URL.PRODUCT.FETCH_ADDITIONAL_BOM_EXPLOSIONS, payload)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static async fetchAdhocAdditionalProductsBOMExplosionDetails(
    payload: { productCode: string; workorderCode: string }[]
  ) {
    return http2
      .post(
        ApiConstants.URL.PRODUCT.FETCH_ADDITIONAL_ADHOC_BOM_EXPLOSIONS,
        payload
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getProductsByBarCode(barcode: any, isSellModule: boolean) {
    Utility.cancelRequest(ProductService.abortController);

    ProductService.abortController = Utility.createAbortController();

    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      '/barcode-generate?search=' +
      barcode +
      '&productTransactionType=' +
      (isSellModule ? 'SALES,BOTH' : 'PURCHASE,BOTH');

    return http
      .get(finalEndpoint, {
        signal: ProductService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getProductsByURL(url: any) {
    return http
      .get(`${url}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductDetailsForGenerateBulkBarcodes = (productIds: String[]) => {
    return http
      .post(
        ApiConstants.URL.PRODUCT.GET_DETAILS_FOR_BULK_GENERATE_BARCODE,
        productIds
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getProductUnassignedOpeningQuantity(productCode: string) {
    let URLString =
      ApiConstants.URL.BASE +
      ApiConstants.URL.PRODUCT.GET_UNASSIGNED_QUANTITY(productCode);
    return http
      .get(URLString)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductGroups = (params = {}) => {
    return http2
      .get(ApiConstants.URL.PRODUCT_GROUP.GET_ALL, { params })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static createProductGroup = (payload: any) => {
    return http2
      .post(ApiConstants.URL.PRODUCT_GROUP.CREATE, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static updateProductGroup = (payload: any, id: any) => {
    return http2
      .put(ApiConstants.URL.PRODUCT_GROUP.UPDATE(id), payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static deleteProductGroup = (id: any) => {
    return http2
      .delete(ApiConstants.URL.PRODUCT_GROUP.UPDATE(id))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static updateProductImages(
    productId: string,
    productImages: any,
    hasConsentForUpdate: boolean = false
  ) {
    const finalEndPoint: string =
      ApiConstants.URL.PRODUCT.PATCH_PRODUCT_IMAGES +
      '/' +
      productId +
      '?updatePrintInfo=' +
      hasConsentForUpdate;

    return http
      .patch(finalEndPoint, productImages)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getComponentGroupInfoWithProductCode = (payload: any) => {
    const endpoint = ApiConstants.URL.PRODUCT.PRODUCT_COMPONENT_GROUP_DETAILS;
    return http
      .post(endpoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getProductBreif(searchText: string): Promise<any[]> {
    return http
      .get(
        `${
          ApiConstants.URL.PRODUCT.FETCH_PRODUCT_BREIF_SEARCH +
          '?search=' +
          searchText +
          '&limit=100'
        }`
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchAdvanceTrackingStockTransfer(documentCode: any) {
    const queryString = [...documentCode];

    const finalEndpoint: string =
      ApiConstants.URL.STOCK_MANAGEMENT.STOCK_TRANSFER;
    return http
      .post(`${finalEndpoint}`, queryString)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchJWOStockTransfer(documentCode: any) {
    const finalEndpoint: string =
      ApiConstants.URL.STOCK_MANAGEMENT.JWO_STOCK_TRANSFER(documentCode);
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default ProductService;
