export const getUniqueRowsForSourceWHRRB = (formState: any) => {
  let uniqueRows = formState?.RRB?.source?.row?.data?.length
    ? formState?.RRB?.source?.row?.data
    : [];
  uniqueRows =
    Array.from(
      new Map(uniqueRows?.map((row: any) => [row?.code, row])).values()
    ) ?? [];
  return uniqueRows;
};

export const getUniqueRacksForSourceWHRRB = (formState: any) => {
  let uniqueRacks = formState?.RRB?.source?.rack?.data?.length
    ? formState?.RRB?.source?.rack?.data
    : [];

  if (formState?.RRB?.source?.row?.data?.length > 0) {
    uniqueRacks = uniqueRacks?.filter(
      (rack: any) => rack?.rowCode === formState?.RRB?.source?.row?.value?.code
    );
  }

  uniqueRacks =
    Array.from(
      new Map(uniqueRacks?.map((rack: any) => [rack?.code, rack])).values()
    ) ?? [];
  return uniqueRacks;
};

export const getUniqueBinsForSourceWHRRB = (formState: any) => {
  let uniqueBins = formState?.RRB?.source?.bin?.data?.length
    ? formState?.RRB?.source?.bin?.data
    : [];

  if (
    formState?.RRB?.source?.row?.data?.length > 0 &&
    formState?.RRB?.source?.rack?.data?.length > 0
  ) {
    uniqueBins = uniqueBins?.filter(
      (bin: any) =>
        bin?.rowCode === formState?.RRB?.source?.row?.value?.code &&
        bin?.rackCode === formState?.RRB?.source?.rack?.value?.code
    );
  }

  uniqueBins =
    Array.from(
      new Map(uniqueBins?.map((bin: any) => [bin?.code, bin])).values()
    ) ?? [];
  return uniqueBins;
};
